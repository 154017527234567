// React Core and Related Hooks
import React, { useState, useRef, useEffect, useMemo } from "react";

// External Libraries
import { useNavigate, useLocation } from "react-router-dom";
import $ from "jquery";
import { MdSearch } from "react-icons/md";
import { FiPrinter } from "react-icons/fi";
import Tooltip from "@mui/material/Tooltip";
import { CircularProgress } from "@material-ui/core";
import Form from "react-bootstrap/Form";
import Select from "react-select";

// Internal/Project-Specific Components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal";
import ComboBox from "Features/ComboBox";

// Utility and Generic Files
import FrmValidations from "FrmGeneric/FrmValidations";
import GenerateTAutoNo from "FrmGeneric/GenerateTAutoNo";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import BeamIssueSlip from "FrmGeneric/Invoice/BeamIssueSlip";

// Layout Components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

// Constants and Configurations
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant";
import ConfigConstants from "assets/Constants/config-constant";

// Miscellaneous Imports
import { renderToString } from "react-dom/server";


export default function FrmTBeamIssueSlipEntry() {

    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, UserName, FINANCIAL_SHORT_NAME, UserId } = configConstants;
    const { state } = useLocation();
    const { keyForViewUpdate, idList = 0, compType } = state || {};
    const [action_Label, setActionLabel] = useState('(Creation)');
    const [isLoading, setIsLoading] = useState(false);

    //For navigate
    const navigate = useNavigate();
    //UseRefs
    const validate = useRef();
    const comboDataAPiCall = useRef();
    const generateAutoNoAPiCall = useRef();
    const validateNumberDateInput = useRef();
    const setNoComboRef = useRef();
    const beamNoComboRef = useRef();
    const loomNoComboRef = useRef();
    const beamIssuerComboRef = useRef();
    const beamCutterComboRef = useRef();

    //combo states
    const [txt_beam_issue_no, setBeamIssueNo] = useState();
    const [cmb_set_no, setSetNo] = useState('')
    const [cmb_beam_issuer_id, setBeamIssuerId] = useState(UserId)
    const [cmb_beam_cutter_id, setBeamCutterId] = useState(UserId)
    const [cmb_beam_no, setBeamNo] = useState('')
    const [txt_beam_name, setBeamName] = useState('')
    const [cmb_loom_no, setLoomNo] = useState('')
    const [cmb_sort_no, setSortNo] = useState('')
    const [txt_process_charge, setProcessCharge] = useState('')
    const [txt_count, setCount] = useState('')
    const [txt_no_of_ends, setNoOfEnds] = useState('')
    const [txt_reed, setReed] = useState('')
    const [txt_pick, setPick] = useState('')
    const [txt_cut_beam_reason, setCutBeamReason] = useState("");
    const [txt_amount, setAmount] = useState('')
    const [txt_size_length, setSizeLength] = useState('')
    const [txt_remaining_meter, setRemainingMeter] = useState('')
    const [txt_beam_status, setBeamStatus] = useState("Full Length");
    const [status, setStatus] = useState(false);

    //Data Options
    const [setNoOptions, setSetNoOptions] = useState([]);
    const [beamWarpingOptions, setBeamWarpingOptions] = useState([]);
    const [processTypeOptions, setProcessTypeOptions] = useState([]);
    const [loomNoOptions, setLoomNoOptions] = useState([]);
    const [shiftOptions, setShiftOptions] = useState([]);
    const [employeeOptions, setEmployeeOptions] = useState([])
    const [printData, setPrintData] = useState([]);


    const todayDate = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const currentTime = () => {
        const now = new Date();
        const hours = String(now.getHours()).padStart(2, '0'); // 24-hour format
        const minutes = String(now.getMinutes()).padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    //Form Fields
    const [dt_beam_issue_date, setBeamIssueDate] = useState(todayDate);
    const [dt_beam_issue_time, setBeamIssueTime] = useState(currentTime);
    const [dt_cut_beam_date, setCutBeamDate] = useState(todayDate);
    const [dt_cut_beam_time, setCutBeamTime] = useState(currentTime);
    const [txt_shift, setShift] = useState('');
    const [txt_process_type, setProcessType] = useState('KN');
    const [txt_goods_return_quantity, setGoodsReturnQuantity] = useState('');
    const [txt_goods_return_boxes, setGoodsReturnBoxes] = useState('');
    const [txt_material_rate, setMaterialRate] = useState('');
    const [txt_goods_return_version, setGoodsReturnVersion] = useState(1);
    const [openIssueDetailsAccord, setOpenIssueDetailsAccord] = useState(true);

    // Function to handle input changes and update state variables
    const validateMaterialDetails = (event) => {
        const numCheck = /^\d{0,18}(?:\.\d{0,4})?$/;
        let indentItemsQtyinp = document.querySelector('#' + event.target.id);
        if (event.target.value === '' || event.target.value === null || !numCheck.test(event.target.value)) {
            indentItemsQtyinp.parentElement.dataset.tip = 'Please enter valid input...!';
            return false;
        } else {
            delete indentItemsQtyinp.parentElement.dataset.tip;
            return true;
        }
    }

    const clearAllDataTipsForMaterialRate = () => {
        const materialRateInputs = document.querySelectorAll(
            '.erp_table_td input[Headers="goods_return_rate"]'
        );
        materialRateInputs.forEach((input) => {
            delete input.parentElement.dataset.tip;
        });
    };



    useEffect(async () => {
        setIsLoading(true);
        try {
            await fillCombos();
            debugger
            ActionType()
            if (idList !== 0) {
                await FnCheckUpdateResponce();
            }
            else {
                let newBeamIssueNo = await generateBeamIssueNo();
                setBeamIssueNo(newBeamIssueNo)
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }, [])

    // delete popup
    const [show, setShow] = useState(false);
    const [issueNoOptions, setIssueNoOptions] = useState([]);
    const [cmb_product_material_id, setProductMaterialId] = useState('');

    //Error Msg
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');

    const handleCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
        if (sessionStorage.getItem('dataAddedByCombo') !== 'dataAddedByCombo') {
            navigate('/Transactions/TBeamIssueSlip/TBeamIssueSlipListing')
        }
    }

    const ActionType = async () => {
        switch (keyForViewUpdate) {
            case 'update':
                setActionLabel('(Update)')
                break;
            case 'view':
                setActionLabel('(View)')
                break;
            case 'cutbeams':
                setActionLabel('(Cut Beams)')
                break;
            default:
                setActionLabel('(Creation)')
                break;
        }
    };

    const fillCombos = async () => {
        try {
            setIsLoading(true)
            resetGlobalQuery();
            globalQuery.columns.push("DISTINCT(set_no)");
            globalQuery.table = "xtv_sizing_production_stock_details";
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "sized_beam_status", operator: "=", value: 'A' });
            globalQuery.conditions.push({ field: "property_group", operator: "=", value: 'PT' });
            globalQuery.orderBy = ['set_no DESC'];
            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then(setNoOpts => {
                    const setNos = [
                        { value: '', label: 'Select' },
                        ...setNoOpts.map((opt) => ({ ...opt, value: opt.set_no, label: opt.set_no })),
                    ]
                    setSetNoOptions(setNos);
                });

            resetGlobalQuery();
            globalQuery.columns.push("employee_id");
            globalQuery.columns.push("employee_name");
            globalQuery.table = "cm_employee";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "employee_type_group", operator: "!=", value: "Workers" });
            const getEmployeeApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            const empOptions = [
                { value: '', label: 'Select', },
                ...getEmployeeApiCall.map((empl) => ({ ...empl, value: empl.employee_id, label: empl.employee_name })),
            ]
            setEmployeeOptions(empOptions);

            if (keyForViewUpdate === "") {
                resetGlobalQuery();
                globalQuery.columns = ['property_name', 'property_value'];
                globalQuery.table = "am_properties"
                globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: 'LoomMachineNo' });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "property_group", operator: "=", value: "Available" });
                comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((loomNoOptions) => {
                    const loomOptions = [
                        ...loomNoOptions.map((loom) => ({ ...loom, value: loom.property_value, label: loom.property_name })),
                    ]
                    setLoomNoOptions(loomOptions);
                })
            }

            resetGlobalQuery();
            globalQuery.columns = ['property_value', 'property_name'];
            globalQuery.table = "am_properties"
            globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: 'LoomProcessType' });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((response) => {
                setProcessTypeOptions(response);
            })

            resetGlobalQuery();
            globalQuery.columns = ['property_name', 'property_id'];
            globalQuery.table = "am_properties"
            globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: 'ProductionShifts' });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((response) => {
                setShiftOptions(response);
            })

        } catch (error) {
            console.log("error: ", error)
        }
        finally {
            setIsLoading(false)
        }
    }




    const comboOnChange = async (key) => {
        switch (key) {

            case 'cmb_set_no':
                setIsLoading(true)
                let set_no = setNoComboRef.current;
                $('#error_cmb_set_no').hide();
                setSetNo(set_no)
                setBeamNo("");
                beamNoComboRef.current = null;
                setBeamWarpingOptions([{ value: '', label: 'Select' },])
                setNoOfEnds("");
                setSizeLength("");
                setCount("");
                setReed("");
                setPick("");
                setAmount("");
                if (set_no !== "") {
                    resetGlobalQuery();
                    globalQuery.columns = ["beam_no", "beam_inward_type"]
                    globalQuery.table = "xtv_sizing_production_stock_details"
                    globalQuery.conditions.push({ field: "set_no", operator: "=", value: set_no });
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    globalQuery.conditions.push({ field: "sized_beam_status", operator: "=", value: 'A' });
                    let beamNoList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    beamNoList = [
                        { value: '', label: 'Select' },
                        ...beamNoList.map((beam) => ({ ...beam, value: beam.beam_no, label: beam.beam_inward_type })),
                    ]
                    setBeamWarpingOptions(beamNoList);
                }
                setIsLoading(false)
                break;

            case 'cmb_beam_no':
                debugger
                document.getElementById("search_btn").addEventListener("click", function (event) {
                    if (event.isTrusted) {
                        setStatus(true);
                    }
                });
                setIsLoading(true)
                let beam_no = beamNoComboRef.current
                $('#error_cmb_beam_no').hide();
                let sort_no = $("#cmb_sort_no").val() === '' ? "sort_no" : $("#cmb_sort_no").val();
                setBeamNo(beam_no)
                try {
                    const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtWeavingBeamIssueMaster/FnGetBeamIssueSlipMasterData/${COMPANY_ID}/${cmb_set_no}/${beam_no.toString()}/${status}/${sort_no}`)
                    const response = await apiCall.json();
                    if (response.success === 1) {
                        let { BeamSizedMasterData } = response
                        console.log(BeamSizedMasterData)
                        if(!status){
                            setNoOfEnds(BeamSizedMasterData[0]['t_ends'])
                            setSizeLength(BeamSizedMasterData[0]['sizing_length'])
                            setCount(BeamSizedMasterData[0]['yarn_count'])
                            setSortNo(BeamSizedMasterData[0]['sort_no'])
                        }
                        BeamSizedMasterData.map((obj) => {

                            if (obj.product_parameter_name.includes("REED")) {
                                setReed(obj.product_parameter_value)
                            }
                            else if (obj.product_parameter_name.includes("PICK")) {
                                setPick(obj.product_parameter_value)
                            }
                            else if (obj.product_parameter_name.includes("RS")) {
                                setAmount(obj.product_parameter_value)
                            }
                            else if (obj.product_parameter_name.includes("TOTAL ENDS") && status){
                                setNoOfEnds(obj.product_parameter_value)
                            }
                        })
                    }
                } catch (error) {
                    console.log("error: ", error)
                } finally {
                    setStatus(false)
                    setIsLoading(false)
                }

                break;

            default:
                break;
        }
    }

    const FnCheckUpdateResponce = async () => {
        try {
            setIsLoading(true)
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtWeavingBeamIssueMaster/FnShowParticularRecordForUpdate/${COMPANY_ID}/${idList}`)
            const response = await apiCall.json();
            const { data } = response
            setPrintData(data)
            setBeamIssueNo(data.beam_issue_no)
            setSetNo(data.set_no)
            setBeamNo(data.beam_no)
            setBeamName(data.beam_name)
            setLoomNo(data.loom_no)
            setBeamIssueDate(data.beam_issue_date)
            setBeamIssueTime(data.beam_issue_time)
            setCount(data.count)
            setSizeLength(data.length)
            setPick(data.pick)
            setReed(data.reed)
            setAmount(data.rs)
            setShift(data.shift_name)
            setSortNo(data.sort_no)
            setNoOfEnds(data.t_ends)
            setProcessCharge(data.loom_process_charge)
            setProcessType(data.loom_process_type==="GAITING"?"GT":"KN")
            setBeamIssuerId(data.beam_issuer_id)
            if (keyForViewUpdate === "cutbeams") {
                setBeamStatus("Cut Beams")
            } else {
                setBeamStatus(data.loom_beam_status)
                setCutBeamDate(data.cut_beam_date)
                setCutBeamTime(data.cut_beam_time)
                setRemainingMeter(data.remaining_length)
                setCutBeamReason(data.cut_beam_reason)
                setBeamCutterId(data.beam_cutter_id)
            }

        } catch (error) {
            console.log("error: ", error)
        }
        finally {
            setIsLoading(false)
        }
    }
    const FnValidateCutBeamsFields = async () => {
        let cutBeamsIsValid = true
        if (cmb_beam_cutter_id==="") {
            $("#error_cmb_beam_cutter_id").text("Please Select Employee..!");
            $("#error_cmb_beam_cutter_id").show();
            $("#cmb_beam_cutter_id").focus();
            cutBeamsIsValid = false;
        }
        else if(txt_remaining_meter!==""){
            if(txt_remaining_meter>txt_size_length){
                $("#error_txt_remaining_meter").text("Remaining Meter Cannot be greater than Sized Meter!");
                $("#error_txt_remaining_meter").show();
                $("#txt_remaining_meter").focus();
                cutBeamsIsValid = false;
            }
        }
        else{
            cutBeamsIsValid = await validate.current.validateForm("CutBeamsFormId");
        }
        return cutBeamsIsValid
    }

    const FnValidateBeamIssue = async () => {
        let headerDataIsValid = true
        if (!headerDataIsValid) { return false; }
        if (cmb_set_no === "") {
            $("#error_cmb_set_no").text("Please fill this field...!");
            $("#error_cmb_set_no").show();
            $("#cmb_set_no").focus();
            return false;
        }

        // Check combo values for customer combo
        if (cmb_set_no !== "" && cmb_beam_no === "") {
            $("#error_cmb_beam_no").text("Please fill this field...!");
            $("#error_cmb_beam_no").show();
            $("#cmb_beam_no").focus();
            return false;
        }
        if (cmb_set_no !== "" && cmb_beam_no !== "" && cmb_loom_no === "") {
            $("#error_cmb_loom_no").text("Please fill this field...!");
            $("#error_cmb_loom_no").show();
            $("#cmb_loom_no").focus();
            return false;
        }

        if ($('#dt_beam_issue_date').val() !== "") {
            const selectedDate = $('#dt_beam_issue_date').val()
            const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
            if (selectedDate > today && selectedDate !== "") {
                $("#error_dt_beam_issue_date").text("Invalid Date...!");
                $("#error_dt_beam_issue_date").show();
                $("#dt_beam_issue_date").focus();
                return false;
            }
            if (cmb_set_no !== "" && cmb_beam_no !== "" && cmb_loom_no !== "") {
                headerDataIsValid = await validate.current.validateForm("BeamIssueSlipFormId");
            }
        }

        return headerDataIsValid;
    }
    const generateBeamIssueNo = async () => {
        const autoNoApiCall = await generateAutoNoAPiCall.current.generateTAutoNo("xt_weaving_beam_issue_master", "beam_issue_no", "SB", "BI", "5");
        setBeamIssueNo(autoNoApiCall);
        return autoNoApiCall;
    };

    const FnAddUpdateRecord = async () => {
        try {
            let checkIsValidate = true;
            if (keyForViewUpdate === "") {
                checkIsValidate = await FnValidateBeamIssue();
            }
            if (keyForViewUpdate === "cutbeams") {
                checkIsValidate = await FnValidateCutBeamsFields();
            }

            let lastBeamIssueNo = txt_beam_issue_no;
            setIsLoading(true)
            let json = { 'BeamIssueMaster': {}, 'commonIds': {} }

            if (checkIsValidate === true) {
                const masterData = {
                    beam_issue_no: lastBeamIssueNo,
                    weaving_beam_issue_master_id: idList,
                    beam_issue_no: txt_beam_issue_no,
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    financial_year: FINANCIAL_SHORT_NAME,
                    set_no: cmb_set_no,
                    beam_no: cmb_beam_no,
                    loom_no: cmb_loom_no,
                    beam_issue_date: dt_beam_issue_date,
                    shift_name: txt_shift,
                    beam_issue_time: dt_beam_issue_time,
                    loom_process_type: txt_process_type==="GT"?"GAITING":"KNOTTING",
                    loom_process_charge: txt_process_charge,
                    sort_no: cmb_sort_no,
                    count: txt_count,
                    t_ends: txt_no_of_ends,
                    reed: txt_reed !== "" ? txt_reed : 0,
                    pick: txt_pick !== "" ? txt_pick : 0,
                    rs: txt_amount,
                    length: txt_size_length,
                    created_by: UserName,
                    modified_by: UserName,
                    loom_beam_status: txt_beam_status,
                    beam_issuer_id: cmb_beam_issuer_id
                }
                if (keyForViewUpdate === 'cutbeams') {
                    masterData.remaining_length = txt_remaining_meter;
                    masterData.cut_beam_reason = txt_cut_beam_reason
                    masterData.cut_beam_time = dt_cut_beam_time
                    masterData.cut_beam_date = dt_cut_beam_date
                    masterData.beam_cutter_id = cmb_beam_cutter_id
                }
                console.log(masterData)
                json.BeamIssueMaster = masterData
                json.commonIds.company_id = COMPANY_ID
                json.commonIds.keyviewupdate = keyForViewUpdate === "" ? "add" : keyForViewUpdate
                const formData = new FormData();
                formData.append(`BeamIssueMasterData`, JSON.stringify(json))
                const requestOptions = {
                    method: 'POST',
                    body: formData
                };
                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtWeavingBeamIssueMaster/FnAddUpdateRecord`, requestOptions)
                const response = await apicall.json();
                if (response.error !== "") {
                    console.log("response error: ", response.error);
                    setErrMsg(response.error)
                    setShowErrorMsgModal(true)

                } else {
                    setSuccMsg(response.message);
                    setShowSuccessMsgModal(true);
                }
                setIsLoading(false)
            }
        } catch (error) {
            console.log("error: ", error)
        } finally {
            setIsLoading(false)
        }

    };

    //-------------------------------------------End Details section --------------------------------------------------------------------------
    const validateNo = (key) => {
        const numCheck = /^[0-9]*\.?[0-9]*$/; // Regex to validate numbers (including decimals)

        switch (key) {
            case 'txt_process_charge': {
                const processChargeValue = $('#txt_process_charge').val();
                if (numCheck.test(processChargeValue)) {
                    $('#error_txt_process_charge').text('').hide(); // Hide the error message
                    setProcessCharge(processChargeValue); // Set the value if valid
                } else {
                    $('#error_txt_process_charge').text('Enter a numeric value').show(); // Show error message
                }
                break;
            }

            case 'txt_remaining_meter': {
                const remainingMeterVal = $('#txt_remaining_meter').val();
                if (numCheck.test(remainingMeterVal)) {
                    setRemainingMeter(remainingMeterVal);
                    $('#error_txt_remaining_meter').text('').hide();
                } else {
                    $('#error_txt_remaining_meter').text('Enter a numeric value').show(); // Show error message
                }
                break;
            }

            default:
                break;
        }
    };
    const validateFields = () => {
        validate.current.validateFieldsOnChange('BeamIssueSlipFormId')
    }


    //************************************************Print functionality****************************************** */


    const handlePrint = async (openPrintModal) => {
        if (openPrintModal) {
            navigate('/Invoice', { state: { invoiceData: printData, navigationLink: '/Transactions/TBeamIssueSlip/TBeamIssueSlipEntry',title:"Beam Issue Slip", invoiceType: 'BIS', idList: idList, keyForViewUpdate: keyForViewUpdate, compType: compType } });
        } else {
            return renderToString(<BeamIssueSlip invoiceContent={printData} />);
        }
    };



    return (
        <>
            <ComboBox ref={comboDataAPiCall} />
            <GenerateTAutoNo ref={generateAutoNoAPiCall} />
            <FrmValidations ref={validate} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />
            <DashboardLayout>
                <>
                    {isLoading ?
                        <div className="spinner-overlay"  >
                            <div className="spinner-container">
                                <CircularProgress color="primary" />
                                <span>Loading...</span>
                            </div>
                        </div> :
                        ''}
                    {/* <form id="BeamIssueSlipFormId"> */}
                    <div className='card p-1'>
                        <div className='card-header text-center py-0'>
                            <label className='erp-form-label-lg text-center'>Beam Issue Slip {action_Label}</label>
                        </div>
                        <form id="BeamIssueSlipFormId">
                            <div className="row p-1">
                                {/* //first column */}
                                <div className="col-sm-6 erp_form_col_div">

                                    <div className='row'>
                                        <div className="col-sm-4 col-12">
                                            <Form.Label className="erp-form-label">Beam Issue No <span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className="col-sm-8">
                                            <Form.Control type="text" id="txt_beam_issue_no" className="erp_input_field" value={txt_beam_issue_no} disabled />
                                            <MDTypography variant="button" id="error_txt_beam_issue_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>


                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Set No<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col-sm-8'>
                                            {keyForViewUpdate === "" ?
                                                <Select ref={setNoComboRef}
                                                    options={setNoOptions}
                                                    inputId="cmb_set_no" // Provide the ID for the input box
                                                    value={setNoOptions.find(option => option.value == cmb_set_no)}
                                                    onChange={(selectedOpt) => {
                                                        setNoComboRef.current = selectedOpt.value;
                                                        comboOnChange('cmb_set_no')
                                                        $("#error_cmb_set_no").hide();
                                                    }}
                                                    placeholder="Search for a Set No..."
                                                    className="form-search-custom"
                                                    classNamePrefix="custom-select" // Add custom prefix for class names
                                                    isDisabled={['view', 'cutbeams'].includes(keyForViewUpdate)}
                                                    styles={{
                                                        option: (provided, state) => ({
                                                            ...provided,
                                                            fontSize: '12px' // Adjust the font size as per your requirement
                                                        }),
                                                        singleValue: (provided, state) => ({
                                                            ...provided,
                                                            fontSize: '12px' // Adjust the font size as per your requirement
                                                        }),
                                                        input: (provided, state) => ({
                                                            ...provided,
                                                            fontSize: '12px' // Adjust the font size as per your requirement
                                                        })
                                                    }}
                                                /> : <Form.Control type="text" id="txt_beam_issue_no" className="erp_input_field  col-sm-8" value={cmb_set_no} disabled />}
                                            <MDTypography variant="button" id="error_cmb_set_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Beam No<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col-sm-8'>
                                            {keyForViewUpdate === "" ?
                                                <Select ref={beamNoComboRef}
                                                    options={beamWarpingOptions}
                                                    inputId="cmb_beam_no" // Provide the ID for the input box
                                                    value={beamWarpingOptions.find(option => option.value == cmb_beam_no)}
                                                    onChange={(selectedOpt) => {
                                                        beamNoComboRef.current = selectedOpt.value;
                                                        comboOnChange('cmb_beam_no')
                                                        $("#error_cmb_beam_no").hide();
                                                    }}
                                                    placeholder="Search for a Beam No..."
                                                    className="form-search-custom"
                                                    classNamePrefix="custom-select" // Add custom prefix for class names
                                                    // isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                                                    styles={{
                                                        option: (provided, state) => ({
                                                            ...provided,
                                                            fontSize: '12px' // Adjust the font size as per your requirement
                                                        }),
                                                        singleValue: (provided, state) => ({
                                                            ...provided,
                                                            fontSize: '12px' // Adjust the font size as per your requirement
                                                        }),
                                                        input: (provided, state) => ({
                                                            ...provided,
                                                            fontSize: '12px' // Adjust the font size as per your requirement
                                                        })
                                                    }}
                                                /> : <Form.Control type="text" id="txt_beam_issue_no" className="erp_input_field  col-sm-8" value={txt_beam_name} disabled />
                                            }
                                            <MDTypography variant="button" id="error_cmb_beam_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-4 col-12">
                                            <Form.Label className="erp-form-label">Loom No<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col-sm-8 col-12'>
                                            {keyForViewUpdate === "" ? <Select ref={loomNoComboRef}
                                                options={loomNoOptions}
                                                inputId="cmb_loom_no" // Provide the ID for the input box
                                                value={loomNoOptions.find(option => option.value == cmb_loom_no)}
                                                onChange={(selectedOpt) => {
                                                    setLoomNo(selectedOpt.value);
                                                    loomNoComboRef.current = selectedOpt.value;
                                                    comboOnChange('cmb_loom_no')
                                                    $('#error_cmb_loom_no').hide()
                                                }}
                                                placeholder="Search for a Loom No..."
                                                className="form-search-custom"
                                                classNamePrefix="custom-select" // Add custom prefix for class names
                                                // isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                                                styles={{
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    })
                                                }}
                                            /> : <Form.Control type="text" id="txt_beam_issue_no" className="erp_input_field  col-sm-8" value={cmb_loom_no} disabled />
                                            }
                                            <MDTypography variant="button" id="error_cmb_loom_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>


                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <Form.Label className="erp-form-label">Sort No<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className={keyForViewUpdate === "" ? "col-sm-7" : "col-sm-8"}>
                                            <Form.Control type="text" id="cmb_sort_no" className="erp_input_field  col-sm-8" value={cmb_sort_no} onChange={(e) => { setSortNo(e.target.value); }} disabled={keyForViewUpdate !== ""} />
                                            <MDTypography variant="button" id="error_cmb_sort_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                        {keyForViewUpdate === "" && (
                                            <div className='col'>
                                                <Tooltip title="Find Sort No" placement="top">
                                                    <MDTypography className="erp-view-btn">
                                                        <MdSearch
                                                            id="search_btn"
                                                            size={24}
                                                            onClick={() => comboOnChange('cmb_beam_no')}
                                                            className="erp-delete-btn"
                                                            color="purple"
                                                        />
                                                    </MDTypography>
                                                </Tooltip>
                                            </div>
                                        )}

                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label">Count<span className="required"></span> </Form.Label>
                                        </div>
                                        <div className='col-sm-8 col-12'>
                                            <Form.Control type="text" id="txt_count" className="erp_input_field" value={txt_count} disabled optional='optional' />
                                            <MDTypography variant="button" id="error_txt_count" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>


                                    <div className='row'>
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label">Reed<span className="required"></span> </Form.Label>
                                        </div>
                                        <div className='col-sm-8 col-12'>
                                            <Form.Control type="text" id="txt_reed" className="erp_input_field" value={txt_reed} optional='optional' disabled />

                                        </div>
                                    </div>


                                    <div className='row'>
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label">Pick<span className="required"></span> </Form.Label>
                                        </div>
                                        <div className='col-sm-8 col-12'>
                                            <Form.Control type="text" id="txt_pick" className="erp_input_field" value={txt_pick} optional='optional' disabled />

                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-4 col-12">
                                            <Form.Label className="erp-form-label">Beam Loom Status</Form.Label>
                                        </div>
                                        <div className="col-sm-8 col-12">
                                            <Form>
                                                <div className="erp_form_radio">
                                                    <div className="fCheck">
                                                        <Form.Check
                                                            className="erp_radio_button"
                                                            label="Full length"
                                                            type="radio"
                                                            value="Full Length"
                                                            name="loom_beam_status"
                                                            checked={txt_beam_status === "Full Length"} onClick={(e) => { setBeamStatus(e.target.value); }}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                    <div className="sCheck">
                                                        <Form.Check
                                                            className="erp_radio_button"
                                                            label="Cut Beams"
                                                            value="Cut Beams"
                                                            type="radio"
                                                            name="loom_beam_status"
                                                            checked={txt_beam_status === "Cut Beams"} onClick={(e) => { setBeamStatus(e.target.value); }}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>

                                
                                </div>

                                {/*2nd Column*/}
                                <div className="col-sm-6 erp_form_col_div">

                                    <div className='row'>
                                        <div className="col-sm-4 col-12">
                                            <Form.Label className="erp-form-label">Date & time <span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className="col-sm-8 col-12">
                                            <div className="row">
                                                <div className="col-6 ">
                                                    <Form.Control type="date" id='dt_beam_issue_date'
                                                        className="erp_input_field"
                                                        value={dt_beam_issue_date}
                                                        onChange={(event) => {
                                                            setBeamIssueDate(event.target.value)
                                                            validateFields()
                                                        }}
                                                        disabled={['view', 'cutbeams'].includes(keyForViewUpdate)} />
                                                    <MDTypography variant="button" id="error_dt_beam_issue_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>

                                                    </MDTypography>
                                                </div>
                                                <div className="col-6">
                                                    <Form.Control type="time" id="dt_beam_issue_time"
                                                        className="erp_input_field"
                                                        value={dt_beam_issue_time}
                                                        onChange={(event) => { setBeamIssueTime(event.target.value); validateFields() }}
                                                        disabled={['view', 'cutbeams'].includes(keyForViewUpdate)} />
                                                    <MDTypography variant="button" id="error_dt_beam_issue_time" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-4 col-12">
                                            <Form.Label className="erp-form-label">Shift<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col-sm-8 col-12'>
                                            <select id="txt_shift" className="form-select form-select-sm" disabled={['view', 'cutbeams'].includes(keyForViewUpdate)} placeholder="Search for Shift" value={txt_shift} onChange={(e) => { setShift(e.target.value); validateFields() }}>
                                                <option value="">Select</option>
                                                {shiftOptions?.map(shift => (
                                                    <option value={shift.property_id}>{shift.property_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_txt_shift" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>


                                    <div className='row'>
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label">Process Type<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col-sm-8 col-12'>
                                            <select id="cmb_process_type"
                                                className="form-select form-select-sm"
                                                value={txt_process_type}
                                                placeholder="Search for Process Type"
                                                onChange={(e) => { setProcessType(e.target.value); validateFields(); }}
                                                // optional='optional'
                                                disabled={['view', 'cutbeams'].includes(keyForViewUpdate)}>
                                                {processTypeOptions.length !== 0 ? (
                                                    <>
                                                        {processTypeOptions?.map(opt => (
                                                            <option value={opt.property_value}>{opt.property_name}</option>
                                                        ))}
                                                    </>
                                                ) : null}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_process_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>


                                    <div className='row'>
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label">Process Charge<span className="required"></span></Form.Label>
                                        </div>
                                        <div className='col-sm-8 col-12'>
                                            <Form.Control type="text" id="txt_process_charge" className="erp_input_field number"
                                                disabled={['view', 'cutbeams'].includes(keyForViewUpdate)}
                                                value={txt_process_charge}
                                                placeholder="Enter Process Charge"
                                                optional="optional"
                                                onChange={(event) => {
                                                    setProcessCharge(event.target.value);
                                                    validateFields()
                                                    validateNo("txt_process_charge")
                                                }}
                                            />
                                            <MDTypography variant="button" id="error_txt_process_charge" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label">No Of Ends<span className="required"></span> </Form.Label>
                                        </div>
                                        <div className='col-sm-8 col-12'>
                                            <Form.Control type="text" id="txt_no_of_ends" className="erp_input_field" value={txt_no_of_ends} disabled optional="optional" />
                                            <MDTypography variant="button" id="error_txt_no_of_ends" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>


                                    <div className='row'>
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label">RS<span className="required"></span> </Form.Label>
                                        </div>
                                        <div className='col-sm-8 col-12'>
                                            <Form.Control type="text" id="txt_amount" className="erp_input_field" value={txt_amount} disabled optional='optional' />
                                            <MDTypography variant="button" id="error_txt_amount" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>


                                    <div className='row'>
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label">Sized Meter<span className="required"></span> </Form.Label>
                                        </div>
                                        <div className='col-sm-8 col-12'>
                                            <Form.Control type="text" id="txt_size_length" className="erp_input_field" value={txt_size_length} disabled optional='optional' />
                                            <MDTypography variant="button" id="error_txt_size_length" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>


                                    <div className={`row`}>
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label">Beam Issuer Name<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col-sm-8 col-10 mb-1'>
                                            <Select ref={beamIssuerComboRef}
                                                options={employeeOptions}
                                                isDisabled={['view', 'cutbeams'].includes(keyForViewUpdate)}
                                                inputId="cmb_beam_issuer_id"
                                                value={employeeOptions.find(option => option.value == cmb_beam_issuer_id)}
                                                onChange={(selectedOpt) => {
                                                    setBeamIssuerId(selectedOpt.value)
                                                    beamIssuerComboRef.current = selectedOpt;
                                                    comboOnChange('cmb_beam_issuer_id');
                                                    validateFields();
                                                }}
                                                placeholder="Search for Employee by..."
                                                className="form-search-custom"
                                                classNamePrefix="custom-select" // Add custom prefix for class names
                                                styles={{
                                                    option: (provided, state) => ({
                                                        ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                    })
                                                }}
                                            />
                                            <MDTypography variant="button" id="error_cmb_beam_issuer_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </form>
                    </div>
                    {txt_beam_status === "Cut Beams" && <div className='card p-1'>

                        <div className="card-header py-0 main_heading mb-0">
                            <label className="erp-form-label-md-lg">Cut Beams</label>
                        </div>

                        <div className="card-body p-0">
                            <form id="CutBeamsFormId">
                                <div className="row p-1">
                                    {/* first column */}
                                    <div className="col-sm-6 erp_form_col_div">

                                        <div className='row'>
                                            <div className="col-sm-4 col-12">
                                                <Form.Label className="erp-form-label">Cut Beam Date & time <span className="required">*</span> </Form.Label>
                                            </div>
                                            <div className="col">
                                                <div className="row">
                                                    <div className="col-6 ">
                                                        <Form.Control type="date" id='dt_cut_beam_date'
                                                            className="erp_input_field"
                                                            value={dt_cut_beam_date}
                                                            onChange={(event) => {
                                                                setCutBeamDate(event.target.value)
                                                                validateFields()
                                                            }}
                                                            disabled={['view'].includes(keyForViewUpdate)}
                                                        />
                                                        <MDTypography variant="button" id="error_dt_cut_beam_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>

                                                        </MDTypography>
                                                    </div>
                                                    <div className="col-6">
                                                        <Form.Control type="time" id="dt_cut_beam_time"
                                                            className="erp_input_field"
                                                            value={dt_cut_beam_time}
                                                            onChange={(event) => {
                                                                setCutBeamTime(event.target.value);
                                                                validateFields()
                                                            }}
                                                            disabled={['view'].includes(keyForViewUpdate)}
                                                        />
                                                        <MDTypography variant="button" id="error_dt_cut_beam_time" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                        </MDTypography>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <div className='row'>
                                            <div className='col-sm-4 col-12'>
                                                <Form.Label className="erp-form-label">Remaining Meter<span className="required">*</span> </Form.Label>
                                            </div>
                                            <div className='col'>
                                                <Form.Control type="text" id="txt_remaining_meter"
                                                    className="erp_input_field number"
                                                    value={txt_remaining_meter}
                                                    disabled={['view', "update"].includes(keyForViewUpdate)}
                                                    onChange={(e) => {
                                                        setRemainingMeter(e.target.value);
                                                        validateNo("txt_remaining_meter")
                                                    }}
                                                />
                                                <MDTypography variant="button" id="error_txt_remaining_meter" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                    </div>

                                    {/* 2nd column */}
                                    <div className="col-sm-6 erp_form_col_div">

                                        <div className={`row`}>
                                            <div className='col-sm-4 col-12'>
                                                <Form.Label className="erp-form-label">Beam Cutter Name<span className="required">*</span></Form.Label>
                                            </div>
                                            <div className='col-sm-8 col-10 mb-1'>
                                                <Select ref={beamCutterComboRef}
                                                    options={employeeOptions}
                                                    isDisabled={['view'].includes(keyForViewUpdate)}
                                                    inputId="cmb_beam_cutter_id"
                                                    value={employeeOptions.find(option => option.value == cmb_beam_cutter_id)}
                                                    onChange={(selectedOpt) => {
                                                        setBeamCutterId(selectedOpt.value)
                                                        beamCutterComboRef.current = selectedOpt;
                                                        comboOnChange('cmb_beam_cutter_id');
                                                        validateFields();
                                                    }}
                                                    placeholder="Search for Employee by..."
                                                    className="form-search-custom"
                                                    classNamePrefix="custom-select" // Add custom prefix for class names
                                                    styles={{
                                                        option: (provided, state) => ({
                                                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                        }),
                                                        singleValue: (provided, state) => ({
                                                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                        }),
                                                        input: (provided, state) => ({
                                                            ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                        })
                                                    }}
                                                />
                                                <MDTypography variant="button" id="error_cmb_beam_cutter_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className='col-sm-4 col-12'>
                                                <Form.Label className="erp-form-label">Cut Beam Reason<span className="required"></span> </Form.Label>
                                            </div>
                                            <div className='col'>
                                                <Form.Control type="textarea" id="txt_cut_beam_reason"
                                                    className="erp_input_field"
                                                    value={txt_cut_beam_reason}
                                                    disabled={['view', "update"].includes(keyForViewUpdate)}
                                                    onChange={(e) => setCutBeamReason(e.target.value)}
                                                    optional="optional" />
                                                <MDTypography variant="button" id="error_txt_cut_beam_reason" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>}


                    <div className='erp_frm_Btns'>
                        <MDButton type="button"
                            onClick={() => {
                                const path = '/Transactions/TBeamIssueSlip/TBeamIssueSlipListing';
                                navigate(path);
                            }}
                            className="erp-gb-button erp_MLeft_btn" variant="button" fontWeight="regular" >Back</MDButton> &nbsp;
                        <MDButton type="button" onClick={FnAddUpdateRecord} className={`erp-gb-button ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button" fontWeight="regular" >{keyForViewUpdate === "approve" ? "Approve" : keyForViewUpdate === "" ? "Save" : keyForViewUpdate === "cutbeams" ? "Cut Beams" : "Update"}
                        </MDButton>
                        <MDButton className={`erp-gb-button ${keyForViewUpdate === 'view' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" onClick={() => handlePrint(true)}>Print <FiPrinter className="erp-download-icon-btn" /></MDButton> &nbsp;
                    </div>

                    <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
                    <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />
                    <hr />
                    {/* </form> */}
                </>
            </DashboardLayout >
        </>
    )
}