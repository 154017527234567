import React, { useState, useRef, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import $, { error } from 'jquery';

// Imports React bootstrap
import Tooltip from "@mui/material/Tooltip";
import Form from 'react-bootstrap/Form';
import { Accordion, Modal, Table, Button } from "react-bootstrap";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { FiDownload } from "react-icons/fi";

// React icons
import { MdRefresh, MdDelete } from "react-icons/md";
import { RxCrossCircled } from "react-icons/rx";

//File Imports
import FrmValidations from "FrmGeneric/FrmValidations";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal"
import ComboBox from "Features/ComboBox";
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ConfigConstants from "assets/Constants/config-constant";
import GenerateTAutoNo from "FrmGeneric/GenerateTAutoNo";
import ExcelExport from "Features/Exports/ExcelExport";
import DocumentF from "Features/Document";
import FrmMEmployeesEntry from "Masters/MEmployee/MEmployeeEntry/components/FrmMEmployeesEntry";
import { CircularProgress } from "@material-ui/core";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import ConfirmationModal from "components/Modals/ConfirmationModal";
import { Link } from "react-router-dom";
import FrmDepartmentEntry from "Masters/MDepartment/FrmDepartmentEntry";
import FrmMProductTypeEntry from "Masters/MProductType/FrmProductEntry";
import ProductListProductTypeWise from "FrmGeneric/ProductListProductTypeWise";
import SearchItemFilter from "FrmGeneric/SearchItemFilter";
import MCostCenterEntry from "Masters/MFMCostCenter/MCostCenterEntry/Index";
import MaterialIssueSlip from 'FrmGeneric/Invoice/MaterialIssueSlip';

// Import for the searchable combo box.
import Select from 'react-select';
import { renderToString } from "react-dom/server";

export default function FrmMaterialRequisitionEntry() {
    const configConstants = ConfigConstants();
    const { FINANCIAL_SHORT_NAME, COMPANY_ID, COMPANY_BRANCH_ID, UserId, UserName, DEPARTMENT_ID } = configConstants;

    const { state } = useLocation();
    const { idList, keyForViewUpdate, compType, pageheading, modules_forms_id, requestfor } = state || {};
    const [isLoading, setIsLoading] = useState(false);
    const [isApprove, setIsApprove] = useState(false)
    const [IsView, setIsView] = useState(false)
    const [openIssueDetailsAccord, setOpenIssueDetailsAccord] = useState(true);
    let docGroup = "Indent Material Issue"

    // For navigate
    const navigate = useNavigate();

    // UseRefs
    const validate = useRef();
    const comboDataAPiCall = useRef();
    const generateAutoNoAPiCall = useRef();
    const exlsExp = useRef();
    const validateNumberDateInput = useRef();
    const navigator = useNavigate();

    let fin_year = "";
    let issueVersion = "";

    //combo options
    const [materialtypeOptions, setmaterialtypeOptions] = useState([])
    const [godownsList, setGodownsList] = useState([]);
    const [customerOptions, setCustomerOptions] = useState([])
    const [departmentIdOPtions, setDepartmentIdOptions] = useState([])
    const [subDepartmentOPtions, setSubDepartmentOptions] = useState([])
    const [requisitionOptions, setRequisitionOptions] = useState([])
    const [employeeOptions, setEmployeeOptions] = useState([])
    const [issueByOptions, setIssueByOptions] = useState([])
    const [customerOrdOpts, setCustomerOrdOpts] = useState([]);
    const [cost_centerOptions, setCostCenterOptions] = useState([]);
    const [profit_centerOptions, setProfitCenterOptions] = useState([]);
    const [issueGroupTypeOptions, setIssueGroupTypeOptions] = useState([]);
    // const [indentNOsOptions, setIndentNOsOptions] = useState([])

    const [materialIssueDetailsData, setMaterialIssueDetailsData] = useState([])


    // for today date formatted
    const todayDate = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    //Form Fields
    const [issue_master_transaction_id, setIssueMasterTransaction_id] = useState(0);
    const [cmb_issue_group_type, setIssue_group_type] = useState('');
    const [cmb_material_type, setmaterialType] = useState('');
    const [txt_material_issue_no, setMaterialIssueNo] = useState(0);
    const [txt_material_issue_version, setmaterialissueversion] = useState(1);
    const [txt_issued_by, setissued_by] = useState('');
    const [chk_issue_source, setIssueSource] = useState('I')
    const [cmb_customer, setCustomerId] = useState('');
    const [cmb_customer_order_no, setCustomerOrderNo] = useState('')
    const [customer_order_date, setCustomerOrderDate] = useState('');
    const [cmb_department, setDepartmentId] = useState(keyForViewUpdate === "" ? DEPARTMENT_ID : "");
    const [cmb_sub_department, setSubDepartmentId] = useState('')
    const [cmb_requisition_by, setRequisitionBy] = useState(UserId);
    const [cmb_approved_by_id, setApprovedById] = useState('');
    const [cmb_accepted_by, setAcceptedById] = useState('');
    const [txt_remark, setRemark] = useState('');
    const [chk_isactive, setIsActive] = useState(true);
    const [chk_Issue_status, setIssueStatus] = useState("P");
    const [cmb_cost_center, setCostCenter] = useState("");
    const [cmb_indent_no, setIndentNo] = useState("");

    const currentDate = new Date().toISOString().split('T')[0];
    // const [txt_indent_date, setIndentDate] = useState(todayDate);

    const [txt_schedule_date, setScheduleDate] = useState(todayDate);
    const [dt_requisition_date, setRequisitionDate] = useState(todayDate);
    const [dt_approved_date, setApproved_date] = useState("");
    const [dt_material_issue_date, setmaterialissueDate] = useState(todayDate);
    const [dt_accepted_date, setAcceptedDate] = useState("");


    useEffect(async () => {
        setIsLoading(true);
        try {
            FnSetFieldUserAccess();
            removeSessions('onload');
            await fillComobos();
            if (idList !== "") {
                await FnCheckUpdateResponce();
            }
            FnSetFieldUserAccess();
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
        // Close indent order no dropdown 
        $(document).on('mouseup', function (e) {
            var container = $("#indent-order-ul");
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                container.hide();
            }
        });
        // Close customer order no dropdown 
        $(document).on('mouseup', function (e) {
            var container = $(".erp_dropdown-content");
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                container.hide();
            }
        });
    }, [])
    // delete popup
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    //Error Msg
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    // Confirmation Modal fields
    const [showConfirmationModal, setShowConfiemationModal] = useState(false)

    const [messageForConfirmation, setMessageForConfirmation] = useState(false)
    const closeConfirmationModal = async (key) => {
        switch (key) {
            case 'Exit':
                setShowSuccessMsgModal(false);
                break;
            default:
                break;
        }
        moveToListing();
    }

    const [showAddRecIndentIssueModal, setshowAddRecIndentIssueModal] = useState(false);
    const [modalHeaderName, setHeaderName] = useState('')
    // Document Form
    const [showDocumentForm, setShowDocumentForm] = useState(false);
    const handleCloseDocumentForm = async () => {
        await showDocumentRecords();
        setShowDocumentForm(false)
    };
    const viewDocumentForm = () => {
        if (keyForViewUpdate !== 'approve' && keyForViewUpdate !== '') {
            setShowDocumentForm(true);
        }
    }
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');
    const [modalOrderDetails, setModalOrderDetails] = useState('')

    // doc list
    const [docData, setDocumentData] = useState([]);

    // Show ADD record Modals
    const closeRecordAddIndentIssueModal = async () => {
        switch (modalHeaderName) {
            case 'Requested By':
                comboOnChange('departmentId')
                break;
            case 'Department':
                resetGlobalQuery();
                globalQuery.columns.push("field_id");
                globalQuery.columns.push("field_name");
                globalQuery.table = "cmv_department";
                globalQuery.conditions.push({ field: "department_type", operator: "=", value: 'M' });
                globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, COMPANY_ID] });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const departmentIdApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                setDepartmentIdOptions(departmentIdApiCall)
                break;

            case 'Material Type':
                resetGlobalQuery();
                globalQuery.columns.push("field_id");
                globalQuery.columns.push("field_name");
                globalQuery.columns.push("product_type_short_name");
                globalQuery.table = "smv_product_type"
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "product_type_group", operator: "IN", values: ["RM", "FG"] });
                comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                    .then(getProductType => {
                        setmaterialtypeOptions(getProductType)
                        console.log("Product Type List: ", getProductType);
                    });

                break;
            case 'Sub Department':
                resetGlobalQuery();
                globalQuery.columns.push("field_id");
                globalQuery.columns.push("field_name");
                globalQuery.table = "cmv_department"
                globalQuery.conditions.push({ field: "department_type", operator: "=", value: 'S' });
                globalQuery.conditions.push({ field: "parent_department_id", operator: "=", value: cmb_department });
                globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, COMPANY_ID] });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const subDepartmentApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                setSubDepartmentOptions(subDepartmentApiCall)
                break;
            case 'Cost Center':
                try {
                    resetGlobalQuery();
                    globalQuery.columns = ['field_id', 'field_name', 'cost_center_short_name'];
                    globalQuery.table = "fmv_cost_center";
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    const profitCenterList = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                    // setCostCenterOptions(profitCenterList);
                    //  ** Changes for the Searchable Combo
                    const costCenterOpts = [
                        { value: '', label: 'Select', centerShortName: '' },
                        { value: '0', label: 'Add New Record+', centerShortName: '' },
                        // ...profitCenterList.map(({ field_id, field_name, cost_center_short_name }) => ({ value: field_id, label: `[${cost_center_short_name}] ${field_name}`, centerShortName: cost_center_short_name })),
                        ...profitCenterList.map((costCenter) => ({ ...costCenter, value: costCenter.field_id, label: `[${costCenter.cost_center_short_name}] ${costCenter.field_name}`, centerShortName: costCenter.cost_center_short_name, })),
                    ];
                    setCostCenterOptions(costCenterOpts);

                } catch (error) {
                    console.log("error : ", error)
                }
                break;

            case 'Profit Center':
                try {
                    resetGlobalQuery();
                    globalQuery.columns = ['field_id', 'field_name', 'profit_center_short_name'];
                    globalQuery.table = "fmv_profit_center";
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    const profitCenterList = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
                    // setCostCenterOptions(profitCenterList);
                    //  ** Changes for the Searchable Combo
                    const costCenterOpts = [
                        { value: '', label: 'Select', centerShortName: '' },
                        { value: '0', label: 'Add New Record+', centerShortName: '' },
                        ...profitCenterList.map((costCenter) => ({ ...costCenter, value: costCenter.field_id, label: `[${costCenter.profit_center_short_name}] ${costCenter.field_name}`, centerShortName: costCenter.profit_center_short_name, })),
                    ];
                    setProfitCenterOptions(costCenterOpts);

                } catch (error) {
                    console.log("error : ", error)
                }
                break;

            default:
                break;
        }
        setshowAddRecIndentIssueModal(false);

    }
    const deleteRecords = async () => {
        try {
            const method = { method: 'DELETE' }
            const deleteApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/StIndentIssueMaster/FnDeleteRecord/${txt_material_issue_version}/${COMPANY_ID}/${UserName}?issue_no=${txt_material_issue_no}`, method)
            const responce = await deleteApiCall.json();
            console.log("indent issue Delete entry: ", responce);
            if (responce.success == '1') {
                setShow(false)
                moveToListing();
            }
        } catch (error) {
            console.log("error: ", error)
        }
    }


    // -------------------------------End Common terms -------------------------------------------------------------------------

    const fillComobos = async () => {
        try {

            resetGlobalQuery();
            globalQuery.columns = ["property_name", "property_value", "field_id"];
            globalQuery.table = "amv_properties";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: "IssueGroupTypes" });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then(getIssueGroupType => {
                    setIssueGroupTypeOptions(getIssueGroupType)
                    console.log("Issue Group Type List: ", getIssueGroupType);
                });

            resetGlobalQuery();
            globalQuery.columns.push("field_id");
            globalQuery.columns.push("field_name");
            globalQuery.columns.push("product_type_short_name");
            globalQuery.table = "smv_product_type";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            // globalQuery.conditions.push({ field: "product_type_group", operator: "IN", values: ["RM", "FG"] });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then(getProductType => {
                    setmaterialtypeOptions(getProductType)
                    console.log("Product Type List: ", getProductType);
                });

            // godownsList list for details table
            const getGodownsList = await comboDataAPiCall.current.fillMasterData("cmv_godown", "", "");
            setGodownsList(getGodownsList)

            comboDataAPiCall.current.fillMasterData("cmv_customer", "", "")
                .then(getCustomersorderno => {
                    //  ** Changes for the Searchable Combo
                    const options = [
                        { value: '', label: 'Select', },
                        { value: '0', label: 'Add New Record+' },
                        ...getCustomersorderno.map((customer) => ({ ...customer, value: customer.field_id, label: customer.field_name, })),
                    ];
                    setCustomerOptions(options);
                    console.log("Customers order no List: ", getCustomersorderno);
                });

            resetGlobalQuery();
            globalQuery.columns.push("field_id");
            globalQuery.columns.push("field_name");
            globalQuery.table = "cmv_department";
            globalQuery.conditions.push({ field: "department_type", operator: "=", value: 'M' });
            globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, COMPANY_ID] });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const getdepartmentId = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setDepartmentIdOptions(getdepartmentId)
            if (keyForViewUpdate === '') {
                await comboOnChange('departmentId');
            }
            // // Indent no List
            // resetGlobalQuery();
            // globalQuery.columns = ['indent_no', 'indent_master_id'];
            // globalQuery.table = "stv_indent_master_summary";
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            // globalQuery.conditions.push({ field: "grn_status", operator: "IN", values: ["G", "IG"] });
            // globalQuery.conditions.push({ field: "issue_status", operator: "IN", values: ['P', 'I'], });
            // comboDataAPiCall.current.fillFiltersCombo(globalQuery)
            //     .then((getIndentNoList) => {
            //         setIndentNOsOptions(getIndentNoList);
            //     })

            resetGlobalQuery();
            globalQuery.columns.push("field_id");
            globalQuery.columns.push("field_name");
            globalQuery.table = "cmv_employee";
            globalQuery.conditions.push({ field: "employee_type", operator: "!=", value: "Worker" });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const getEmployee = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
            setEmployeeOptions(getEmployee)


            resetGlobalQuery();
            globalQuery.columns.push("user_id as field_id");
            globalQuery.columns.push("user_name as field_name");
            globalQuery.table = "amv_modules_forms_user_access";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "modules_forms_id", operator: "=", value: modules_forms_id });
            globalQuery.conditions.push({ field: "special_access", operator: "=", value: 1 });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then(getApproveEmpAccessList => {
                    setIssueByOptions(getApproveEmpAccessList);
                    console.log("Approve Access Emp List: ", getApproveEmpAccessList);
                });

            // Costcenter List
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'cost_center_short_name'];
            globalQuery.table = "fmv_cost_center";
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then((getCostCenterList) => {
                    const costCenterOpts = [
                        { value: '', label: 'Select', centerShortName: '' },
                        { value: '0', label: 'Add New Record+', centerShortName: '' },
                        ...getCostCenterList.map((costCenter) => ({ ...costCenter, value: costCenter.field_id, label: `[${costCenter.cost_center_short_name}] ${costCenter.field_name}`, centerShortName: costCenter.cost_center_short_name, })),
                    ];
                    setCostCenterOptions(costCenterOpts);
                })

            //Profit Center List
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'profit_center_short_name'];
            globalQuery.table = "fmv_profit_center";
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const profitCenterList = await comboDataAPiCall.current.removeCatcheFillCombo(globalQuery);
            // setCostCenterOptions(profitCenterList);
            //  ** Changes for the Searchable Combo
            const costCenterOpts = [
                { value: '', label: 'Select', centerShortName: '' },
                { value: '0', label: 'Add New Record+', centerShortName: '' },
                ...profitCenterList.map((costCenter) => ({ ...costCenter, value: costCenter.field_id, label: `[${costCenter.profit_center_short_name}] ${costCenter.field_name}`, centerShortName: costCenter.profit_center_short_name, })),
            ];
            setProfitCenterOptions(costCenterOpts);


            //get Requisition by name on load
            resetGlobalQuery();
            globalQuery.columns.push("field_id");
            globalQuery.columns.push("field_name");
            globalQuery.table = "cmv_employee";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "employee_type", operator: "!=", value: "Worker" });
            const identedbyIdApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            const indentedByOpts = [
                { value: '', label: 'Select', },
                ...identedbyIdApiCall.map((empl) => ({ ...empl, value: empl.field_id, label: empl.field_name })),
            ];
            setRequisitionOptions(indentedByOpts);
        } catch (error) {
            console.log("error: ", error)
        }
    }
    const FnSetFieldUserAccess = () => {
        if (keyForViewUpdate != '') {
            var saveBtn = document.getElementById('save-btn');
            switch (keyForViewUpdate) {
                case 'view':
                    $('input[type="checkbox"]').prop('readonly', true);
                    setIsView(true)
                    $('#submain_heading').append('');
                    $('#submain_heading').text('(View)');
                    $("input[type=radio]").attr('disabled', true);
                    $("form").find("input,textarea,select").attr("disabled", "disabled");
                    $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                    $('#clearbutton-id').hide();
                    $('#save-btn').hide();
                    $('#cmb_customer_order_no').attr('disabled', true);
                    $('#add-material').attr('disabled', 'disabled');
                    $("table").find('input[id^="indent_quantity_"]').attr("disabled", "disabled");
                    break;

                case 'issue':
                    setIsView(true)
                    $('#submain_heading').append('');
                    $('#submain_heading').text('(Issue)');
                    $("input[type=radio]").attr('disabled', true);
                    $("form").find("input,textarea,select").attr("disabled", "disabled");
                    $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                    $('.issueField').prop('disabled', false);
                    $('table .issueField').removeAttr("disabled");
                    $('#clearbutton-id').hide();
                    $('#save-btn').hide();
                    $('#cmb_customer_order_no').attr('disabled', true);
                    $('#add-material').attr('disabled', 'disabled');
                    $("table").find('input[id^="indent_quantity_"]').attr("disabled", "disabled");
                    break;

                case 'update':
                    $('#submain_heading').append('');
                    $('#cmb_material_type').attr('disabled', true);
                    $('#submain_heading').text('(Modification)');
                    $('#txt_indent_date').attr('disabled', true);
                    $('input[name="chk_issue_source"]').prop('disabled', true);
                    $('#cmb_customer_order_no').attr('disabled', false);
                    $('#cmb_customer').attr('disabled', true);
                    if (chk_issue_source !== 'S') {
                        $('#cmb_customer_order_no').attr('disabled', true);
                    }
                    setIsApprove(false)
                    $('#clearbutton-id').hide();
                    saveBtn.textContent = 'Update';
                    break;

                case 'delete':
                    $('input[type="checkbox"]').prop('readonly', true);
                    setIsView(true)
                    $('#submain_heading').append('');
                    $('#submain_heading').text('(Delete)');
                    $("input[type=radio]").attr('disabled', true);
                    $("form").find("input,textarea,select").attr("disabled", "disabled");
                    $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                    $('#clearbutton-id').hide();
                    $('#viewdocument-id').hide();
                    $('#save-btn').hide();
                    $('#cmb_customer_order_no').attr('disabled', true);
                    $('#add-material').attr('disabled', 'disabled');
                    $("table").find('input[id^="indent_quantity_"]').attr("disabled", "disabled");
                    break;

                case 'approve':
                    $('input[type="checkbox"]').prop('readonly', true);
                    setIsView(true)
                    $('#submain_heading').append('');
                    $('#submain_heading').text('(Approval)');
                    saveBtn.textContent = 'Approve';
                    $("form").find("input,textarea,select").attr("disabled", "disabled");
                    $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                    // $("form").find('date#dt_approved_date').removeAttr("disabled");
                    $("form").find('textarea#txt_remark').removeAttr("disabled");
                    $('#cmb_customer_order_no').attr('disabled', true);
                    $('#dt_approved_date').attr('disabled', false);
                    $('#dt_approved_date').attr('disabled', false);
                    // if (chk_indent_source === 'S') {
                    //     $('#add-material').attr('disabled', 'disabled');
                    // }
                    $('table .approveField').removeAttr("disabled");
                    $('#clearbutton-id').hide();
                    $('#viewdocument-id').hide();
                    break;
                default :  break;
            }
        }
    }


    const FnCheckUpdateResponce = async () => {

        try {
            if (idList !== '') {
                fin_year = idList.financial_year;
                issueVersion = idList.issue_version;
                setMaterialIssueNo(idList.issue_no)
                const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/StIndentIssueMaster/FnShowAllDetailsAndMastermodelRecords/${issueVersion}/${fin_year}/${COMPANY_ID}?issue_no=${idList.issue_no}`)
                const responce = await apiCall.json();
                console.log('indent Issue data: ', responce);
                let UpdatedIssueMasterdata = responce.IndentIssueMasterRecord;
                if (UpdatedIssueMasterdata !== null && UpdatedIssueMasterdata !== "") {
                    let issueStatus = UpdatedIssueMasterdata.issue_status;
                    setIssueMasterTransaction_id(UpdatedIssueMasterdata.issue_master_transaction_id)
                    setIssue_group_type(UpdatedIssueMasterdata.issue_group_type)
                    setmaterialType(UpdatedIssueMasterdata.indent_issue_type_id)
                    setmaterialissueDate(UpdatedIssueMasterdata.issue_date)
                    setmaterialissueversion(UpdatedIssueMasterdata.issue_version)
                    setIssueSource(UpdatedIssueMasterdata.issue_source)
                    setCustomerId(UpdatedIssueMasterdata.customer_id)
                    await comboOnChange('customer')
                    setCustomerOrderNo(UpdatedIssueMasterdata.customer_order_no)
                    setCustomerOrderDate(UpdatedIssueMasterdata.customer_order_date)
                    setDepartmentId(UpdatedIssueMasterdata.department_id)
                    await comboOnChange('departmentId')
                    setSubDepartmentId(UpdatedIssueMasterdata.sub_department_id)
                    await comboOnChange('subDepartment');
                    setScheduleDate(UpdatedIssueMasterdata.expected_schedule_date)
                    setRequisitionDate(UpdatedIssueMasterdata.requisition_date)
                    setRequisitionBy(UpdatedIssueMasterdata.requisition_by_id)
                    setRemark(UpdatedIssueMasterdata.remark)
                    setIsActive(UpdatedIssueMasterdata.is_active)

                    if (keyForViewUpdate === 'approve') {
                        setIssueStatus('A'); //approve status while approve
                        setApprovedById(UserId)
                        setApproved_date(todayDate)
                    } else if (keyForViewUpdate === 'issue') {
                        setIssueStatus('MI'); //approve status while approve
                        setissued_by(UserId)
                        setApprovedById(UpdatedIssueMasterdata.approved_by_id)
                        setApproved_date(UpdatedIssueMasterdata.approved_date)
                    } else {
                        setAcceptedById(UpdatedIssueMasterdata.accepted_by_id)
                        setIssueStatus(UpdatedIssueMasterdata.issue_status)
                        setApprovedById(UpdatedIssueMasterdata.approved_by_id)
                        setApproved_date(UpdatedIssueMasterdata.approved_date)
                        setissued_by(UpdatedIssueMasterdata.issued_by_id)
                    }

                    if (responce.IndentIssueDetailsRecords != null) {

                        let issueDetails = await Promise.all(responce.IndentIssueDetailsRecords.map(async item => {
                            let accepted_qty = 0;
                            let accepted_weight = 0;
                            let indentNoList = [];
                            if (item.indent_no !== null) {
                                indentNoList.push({ indent_no: item.indent_no });
                            }
                            resetGlobalQuery();
                            globalQuery.columns.push('sum(product_material_issue_quantity) as accepted_quantity');
                            globalQuery.columns.push('sum(product_material_issue_weight) as accepted_weight');
                            globalQuery.table = "stv_indent_material_issue_details";
                            globalQuery.conditions.push({ field: "product_material_id", operator: "=", value: item.product_material_id, });
                            globalQuery.conditions.push({ field: "department_id", operator: "=", value: UpdatedIssueMasterdata.department_id, });
                            globalQuery.conditions.push({ field: "sub_department_id", operator: "=", value: UpdatedIssueMasterdata.sub_department_id, });
                            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
                            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
                            const grnAcceptedApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                            console.log("grnAcceptedApiCall", grnAcceptedApiCall);
                            if (grnAcceptedApiCall.length !== 0) {
                                accepted_qty = grnAcceptedApiCall[0].accepted_quantity || 0;
                                accepted_weight = grnAcceptedApiCall[0].accepted_weight || 0;
                            }

                            if (item.indent_no === undefined || item.indent_no === null || item.indent_no === "") {
                                item.product_material_stock_quantity = item.product_material_stock_quantity;
                                item.product_material_stock_weight = item.product_material_stock_weight;
                                // - item.reserve_quantity;
                                // - item.reserve_weight
                            }
                            // Customizing item based on keyForViewUpdate
                            const customizedItem = {
                                ...item,
                                indentNoList: indentNoList,
                                prev_issue_quantity: keyForViewUpdate === 'issue' ? item.product_material_issue_quantity : 0,
                                prev_issue_weight: keyForViewUpdate === 'issue' ? item.product_material_issue_weight : 0,
                                accepted_qty: accepted_qty,
                                accepted_weight: accepted_weight,
                                product_id: item.product_material_id,
                            };
                            if (keyForViewUpdate === 'approve') {
                                customizedItem.product_material_approved_quantity = item.product_material_indent_quantity;
                                customizedItem.product_material_approved_weight = item.product_material_indent_weight;
                                customizedItem.issue_item_status = "A";
                            } else if (keyForViewUpdate === 'issue') {
                                let approve_qty = parseFloat(item.product_material_approved_quantity) - parseFloat(item.product_material_issue_quantity);
                                customizedItem.issue_item_status = "MI";

                                //to show issue qty 
                                if (approve_qty > parseFloat(item.product_material_stock_quantity)) {
                                    customizedItem.product_material_issue_quantity = parseFloat(item.product_material_stock_quantity);
                                } else if (approve_qty === parseFloat(item.product_material_stock_quantity)) {
                                    customizedItem.product_material_issue_quantity = approve_qty;
                                } else {
                                    customizedItem.product_material_issue_quantity = approve_qty;
                                }
                                // Calculate issue quantity weight
                                customizedItem.product_material_issue_weight = customizedItem.product_material_issue_quantity !== 0 ?
                                    customizedItem.product_material_issue_quantity * item.product_std_weight : 0;
                                customizedItem.issue_item_status = "MI";

                            }
                            return customizedItem;
                        }));
                        // const indentNos = issueDetails.map(detail => detail.indent_no);
                        // const uniqueIndentNos = [...new Set(indentNos)];
                        // If there's only one unique indent_no, then it's the common one
                        // const commonIndentNo = uniqueIndentNos.length === 1 ? uniqueIndentNos[0] : "";
                        // console.log(commonIndentNo);
                        // setIndentNo(commonIndentNo)
                        setMaterialIssueDetailsData(issueDetails);

                    }
                }
            }
        } catch (error) {
            console.log("error: ", error)
        }
    }
    // const fnLoadIndentNOs = async (subDepartmentId) => {
    //     try {
    //         resetGlobalQuery();
    //         globalQuery.columns = [" Distinct(indent_no) "];
    //         globalQuery.table = "stv_indent_master_summary";
    //         globalQuery.conditions.push({ field: "sub_department_id", operator: "=", value: subDepartmentId });
    //         globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0', });
    //         globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
    //         globalQuery.conditions.push({ field: "grn_status", operator: "IN", values: ["G", "IG"] });
    //         globalQuery.conditions.push({ field: "issue_status", operator: "IN", values: ['P', 'I'], });
    //         globalQuery.orderBy = ["indent_master_id desc"];
    //         if (chk_issue_source === 'S') {
    //             globalQuery.conditions.push({ field: "customer_order_no", operator: "=", value: cmb_customer_order_no })
    //             globalQuery.conditions.push({ field: "customer_id", operator: "=", value: cmb_customer })
    //         }

    //         const indentNoList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
    //         const indentNOoptions = [
    //             { value: '', label: 'Select', },
    //             ...indentNoList.map((indent) => ({ ...indent, value: indent.indent_no, label: indent.indent_no })),
    //         ];
    //         // setIndentNOsOptions(indentNOoptions);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    const comboOnChange = async (key) => {
        switch (key) {
            case 'customer':
                let customerVal = customerComboRef.current.value || '';
                if (customerVal === '0') {
                    const newTab = window.open('/Masters/Customer', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                } else if (customerVal !== "" & customerVal !== "0") {
                    resetGlobalQuery();
                    globalQuery.columns.push("customer_order_no");
                    globalQuery.columns.push("customer_order_Date");
                    globalQuery.table = "mtv_sales_order_master_trading_summary"
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    globalQuery.conditions.push({ field: "customer_id", operator: "=", value: customerVal });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });

                    const getCustomerOrdersApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    setCustomerOrdOpts(getCustomerOrdersApiCall);
                    setMaterialIssueDetailsData([])
                    $("#error_cmb_customer").hide();

                } else {
                    setCustomerOrdOpts([]);
                    setMaterialIssueDetailsData([])

                }
                break;

            case 'CustomerOrder':
                if ($('#cmb_customer_order_no').val() != null && $('#cmb_customer_order_no').val() != '') {
                    let selectedOption = $('#cmb_customer_order_no').find('option:selected');
                    let selectedOrderDate = selectedOption.attr('ord_date');
                    setCustomerOrderDate(selectedOrderDate);
                } else {
                    setCustomerOrderDate('');
                    $('.showData').hide();
                }
                break;
            case 'departmentId':
                var departmentIdVal = document.getElementById('cmb_department').value;
                setDepartmentId(departmentIdVal)
                if (departmentIdVal === "0") {
                    setHeaderName('Department')
                    setshowAddRecIndentIssueModal(true)
                    setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
                }
                if (departmentIdVal !== "" && departmentIdVal !== "0") {
                    resetGlobalQuery();
                    globalQuery.columns.push("field_id");
                    globalQuery.columns.push("field_name");
                    globalQuery.table = "cmv_department"
                    globalQuery.conditions.push({ field: "department_type", operator: "=", value: 'S' });
                    globalQuery.conditions.push({ field: "parent_department_id", operator: "=", value: departmentIdVal });
                    globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, COMPANY_ID] });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    const subDepartmentApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    setSubDepartmentOptions(subDepartmentApiCall)
                    setSubDepartmentId('')
                    if (departmentIdVal !== "") {
                        $('#error_cmb_department').hide();
                    }
                } else {
                    setSubDepartmentOptions([])
                    setSubDepartmentId('')
                }
                break;
            case 'subDepartment':
                var subdepartmentIdVal = document.getElementById('cmb_sub_department').value;
                setSubDepartmentId(subdepartmentIdVal)
                if (subdepartmentIdVal === "0") {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Sub Department')
                    setshowAddRecIndentIssueModal(true)
                    setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
                }
                if (subdepartmentIdVal !== "" && subdepartmentIdVal !== "0") {
                    // load indent nos on sub-department  
                    // await fnLoadIndentNOs(subdepartmentIdVal)
                    if (subdepartmentIdVal !== "") {
                        $('#error_cmb_sub_department').hide();
                    }
                }

                break;
            case 'requisition_by':
                var requisition_by = requesitionByComboRef.current.value;
                setRequisitionBy(requisition_by)
                if (requisition_by === "0") {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Requested By')
                    const newTab = window.open('/Masters/Employees', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                }
                if (requisition_by !== "" && requisition_by !== "0") {
                    $('#error_cmb_requisition_by').hide();
                }
                break;
            case 'SO_source':
                setMaterialIssueDetailsData([])
                break;
            case 'Internal_source':
                setCustomerId('')
                setCustomerOrderNo("")
                setCustomerOrderDate('')
                setMaterialIssueDetailsData([])
                break;

            case 'materialTypeId':
                var materialTypeId = document.getElementById('cmb_material_type').value;
                if (materialTypeId === "0") {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Material Type')
                    setshowAddRecIndentIssueModal(true)
                    setTimeout(() => { $(".erp_top_Form").css({ "padding-top": "0px" }); }, 200)
                }
                setmaterialType(materialTypeId)
                if (materialTypeId !== "0" && materialTypeId !== "") {
                    $('#error_cmb_material_type').hide();
                    await generateIndentIssueNo();
                } else {
                    setMaterialIssueNo("0")
                }
                break;
            case 'cost_center':
                let costcenter = costCenterComboRef.current.value || '';
                $('#error_cmb_cost_center').hide();
                if (costcenter === '0') {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Cost Center')
                    setshowAddRecIndentIssueModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").eq(0).css("padding-top", "0px");
                    }, 200)
                }
                break;
            // case 'indent_no':
            //     let selected_indent_no = indentNosComboRef.current.value;
            //     if (selected_indent_no !== "") {
            //         // await showIndentDetails();
            //         const indentObj = indentNOsOptions.find(item => item.indent_no === selected_indent_no);
            //         if (indentObj) {
            //             setRequisitionBy(indentObj.indented_by_id);
            //             requesitionByComboRef.current.value = indentObj.indented_by_id;
            //         }
            //     } else {
            //         setMaterialIssueDetailsData([])
            //     }
            //     break;
            default:
                break;
        }
    }

    const displayRecordIndentIssueComponent = () => {
        switch (modalHeaderName) {
            case 'Requested By':
                return <FrmMEmployeesEntry />;

            case 'Department':
                return <FrmDepartmentEntry />;

            case 'Material Type':
                return <FrmMProductTypeEntry />;

            case 'Sub Department':
                const props = { btn_disabled: true, departmentType: "S", parent_department: cmb_department, parentDepIs_disable: true, deptTypeIs_disable: true };
                return <FrmDepartmentEntry {...props} />;

            case 'Cost Center':
                return <MCostCenterEntry btn_disabled={true} />;
            default:
                return null;
        }
    }

    const generateIndentIssueNo = async () => {
        const ptShortName = materialtypeOptions.find(item => item.field_id === parseInt($('#cmb_material_type').val()));
        const productTypeId = $('#cmb_material_type option:selected').val();
        const autoNoApiCall = await generateAutoNoAPiCall.current.generateTAutoNo("st_indent_material_issue_master", "issue_no", ptShortName.product_type_short_name, "MI", "5", "indent_issue_type_id", productTypeId);
        setMaterialIssueNo(autoNoApiCall);
        return autoNoApiCall;
    };

    //--------------------------------------------------------Start Details section----------------------------------------------------------------------

    // product filter columns
    let indentRequiredCols = ['st.indent_details_id', 'st.product_material_id','st.product_material_code', 'st.product_rm_name', 'st.product_rm_tech_spect', 'st.product_rm_std_weight', 'st.product_rm_stock_unit_name', 'st.indent_type_id', 'st.product_material_issue_quantity as product_material_indent_issue_quantity',
        'st.product_material_issue_weight as product_material_indent_issue_weight', "st.remark", 'st.product_material_stock_quantity', 'st.product_material_stock_weight',
        "st.product_material_grn_accepted_quantity", "st.product_material_grn_accepted_weight", "st.product_material_return_quantity", "st.product_material_return_weight", 'st.product_material_unit_id', 'st.lead_time',
        "rm_fg_sr.godown_id", "rm_fg_sr.godown_section_id", "rm_fg_sr.godown_section_beans_id", 'rm_fg_sr.godown_name', 'rm_fg_sr.godown_section_name', 'rm_fg_sr.godown_section_beans_name'
    ];
    // Fn for show indent summary modal details
    const showIndentDetails = async () => {
        try {
            setIsLoading(true)
            let selected_indent_no = indentNosComboRef.current.value;

            if (selected_indent_no !== "") {
                resetGlobalQuery();
                globalQuery.columns = indentRequiredCols;
                globalQuery.table = "stv_indent_details st";

                globalQuery.joins = [{
                    "table": "smv_product_rm_fg_sr rm_fg_sr",
                    "type": "LEFT",
                    "on": [
                        {
                            "left": "st.product_material_id",
                            "right": "rm_fg_sr.product_material_id"
                        },
                    ]
                }]
                globalQuery.joins = [{
                    "table": "sm_product_rm_stock_summary sm",
                    "type": "LEFT",
                    "on": [
                        {
                            "left": "st.product_material_id",
                            "right": "sm.product_rm_id"
                        },
                    ]
                }]
                globalQuery.conditions.push({ field: "st.indent_no", operator: "=", value: selected_indent_no, });
                globalQuery.conditions.push({ field: "st.grn_item_status", operator: "IN", values: ['G', 'IG'], });
                globalQuery.conditions.push({ field: "st.issue_item_status", operator: "IN", values: ['P', 'I'], });
                globalQuery.conditions.push({ field: "st.is_delete", operator: "=", value: 0, });
                const IndentDetailsList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                console.log("IndentDetailsList: ", IndentDetailsList);
                if (IndentDetailsList?.length !== 0) {

                    displayindentissueDetailsData(IndentDetailsList);
                }
            } else {
                setMaterialIssueDetailsData([])
            }
        } catch (error) {
            console.log(error);
        }
    }


    const displayindentissueDetailsData = async (IndentDetailsList) => {
        try {
            if (IndentDetailsList.length !== 0) {
                setIsLoading(true)
                const issueListing = IndentDetailsList.map(FnupdateIndentDetailsRow);
                const productMaterialIds = issueListing.map(item => item.product_material_id);

                resetGlobalQuery();
                globalQuery.columns.push('sum(product_material_issue_quantity) as accepted_quantity');
                globalQuery.columns.push('sum(product_material_issue_weight) as accepted_weight');
                globalQuery.table = "stv_indent_material_issue_details";
                globalQuery.conditions.push({ field: "product_material_id", operator: "IN", values: productMaterialIds, });
                globalQuery.conditions.push({ field: "department_id", operator: "=", value: cmb_department, });
                globalQuery.conditions.push({ field: "sub_department_id", operator: "=", value: cmb_sub_department, });
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
                const getdepartmentStock = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                console.log("getdepartmentStock", getdepartmentStock);

                if (getdepartmentStock.length !== 0) {
                    // Update issueListing array with accepted_quantity and accepted_weight
                    issueListing.forEach(material => {
                        const materialwisefound = getdepartmentStock.find(item => item.product_material_id === material.product_material_id);
                        if (materialwisefound) {
                            material.accepted_qty = materialwisefound.accepted_quantity || 0;
                            material.accepted_weight = materialwisefound.accepted_weight || 0;
                        } else {
                            material.accepted_qty = 0;
                            material.accepted_weight = 0;
                        }
                        material.indent_no = indentNosComboRef.current.value;
                    });
                }
                setMaterialIssueDetailsData(() => { return issueListing; });
            } else {
                setMaterialIssueDetailsData([]);
            }
            setIsLoading(false)
        } catch (error) {
            console.error("Error in indent materials: ", error);
            setMaterialIssueDetailsData([]);
            setIsLoading(false)
        }

    };

    const removeSessions = (reloadData) => {
        sessionStorage.removeItem(`comboFilterData`)
        sessionStorage.removeItem('isComboFilterExist')
        sessionStorage.removeItem('smv_product_type');
        sessionStorage.removeItem('smv_product_material_type');
        sessionStorage.removeItem('smv_product_material_grade');
        sessionStorage.removeItem('smv_product_material_shape');
        if (reloadData === 'onload') {
            sessionStorage.removeItem('filteredMaterialData')
        }
        for (let removeItemIndex = 1; removeItemIndex <= 5; removeItemIndex++) {
            sessionStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex + 'Name');
        }

        localStorage.removeItem('smv_product_material_type');
        localStorage.removeItem('smv_product_material_grade');
        localStorage.removeItem('smv_product_material_shape');
        localStorage.removeItem('smv_product_type');

        localStorage.removeItem('smv_product_material_typeName');
        localStorage.removeItem('smv_product_material_gradeName');
        localStorage.removeItem('smv_product_material_shapeName');
        localStorage.removeItem('smv_product_typeName');
    }

    const categoryDisplay = () => {

        const catCount = sessionStorage.getItem('compRMCatCount')
        for (let catCountIndex = 1; catCountIndex <= catCount; catCountIndex++) {
            $('.cat' + catCountIndex + 'Id').show();
        }
    }

    // product filter columns
    let requiredCols = ['product_id', 'product_name', 'product_tech_spect', 'product_std_weight', 'product_unit_name', 'product_material_indent_quantity', 'product_material_indent_weight',
        "product_material_approved_quantity", "product_material_approved_weight", "product_material_rejected_quantity", "product_material_rejected_weight",
        "approval_remark", "issue_item_status", 'product_material_grade_name', 'product_make_name', 'stock_quantity', 'stock_weight', "reserve_quantity",
        "reserve_weight", "product_material_receipt_quantity", "product_material_receipt_weight", "product_type_id",
        "product_material_return_quantity", "product_material_return_weight", 'product_unit_id', 'product_lead_time', 'godown_id', 'godown_section_id', 'godown_section_beans_id', 'godown_name', 'godown_section_name', 'godown_section_beans_name'
    ];

    const handleSearchMaterials = async () => {
        const getData = JSON.parse(sessionStorage.getItem('filteredMaterialData')) || [];
        if (getData.length !== 0) {
            try {
                setIsLoading(true)
                const prevArray = [...materialIssueDetailsData];
                const newMaterialsPromises = getData
                    .filter(item => !prevArray.some(prevItem => prevItem.product_id === item.product_id))
                    .map(FnupdateDetailsRowKeys);
                const newMaterials = await Promise.all(newMaterialsPromises);

                resetGlobalQuery();
                globalQuery.columns.push('sum(product_material_issue_quantity) as accepted_quantity');
                globalQuery.columns.push('sum(product_material_issue_weight) as accepted_weight');
                globalQuery.table = "stv_indent_material_issue_details";
                globalQuery.conditions.push({ field: "product_material_id", operator: "=", value: newMaterials[0].product_material_id, });
                globalQuery.conditions.push({ field: "department_id", operator: "=", value: cmb_department, });
                globalQuery.conditions.push({ field: "sub_department_id", operator: "=", value: cmb_sub_department, });
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
                const getdepartmentStock = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                console.log("getdepartmentStock", getdepartmentStock);

                if (getdepartmentStock.length !== 0) {
                    var accepted_qty = getdepartmentStock[0].accepted_quantity;
                    var accepted_weight = getdepartmentStock[0].accepted_weight;

                    newMaterials[0].accepted_qty = accepted_qty || 0;
                    newMaterials[0].accepted_weight = accepted_weight || 0;
                }
                // Compute the updated array before setting the state
                let updatedArray = [];
                if (newMaterials[0].product_material_stock_quantity > 0) {
                    updatedArray = [...prevArray, ...newMaterials];
                } else {
                    updatedArray = [...prevArray];
                    if (newMaterials[0].reserve_quantity > 0) {
                        resetGlobalQuery();
                        globalQuery.columns.push('indent_no');
                        globalQuery.table = "st_indent_details";
                        globalQuery.conditions.push({
                            field: "product_material_id",
                            operator: "=",
                            value: newMaterials[0].product_material_id,
                        });
                        globalQuery.conditions.push({
                            field: "issue_item_status",
                            operator: "IN",
                            values: ['P', 'I'],
                        });
                        globalQuery.conditions.push({
                            field: "grn_item_status",
                            operator: "IN",
                            values: ['IG', 'G'],
                        });
                        globalQuery.conditions.push({
                            field: "company_id",
                            operator: "=",
                            value: COMPANY_ID,
                        });
                        globalQuery.conditions.push({
                            field: "is_delete",
                            operator: "=",
                            value: 0,
                        });

                        const getReservedIndentNOs = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                        console.log("Reserved Indent NOS", getReservedIndentNOs);

                        setErrMsg(
                            <>
                                Stock quantity of <b>{newMaterials[0].product_material_name}</b> in reserved.
                                <br />
                                {getReservedIndentNOs
                                    .sort((a, b) => a.indent_no - b.indent_no)
                                    .map((item, index) => (
                                        <span key={index}>
                                            {index + 1}. <b>{item.indent_no}</b>
                                            <br />
                                        </span>
                                    ))}
                            </>
                        );
                        setShowErrorMsgModal(true);
                    } else {
                        setErrMsg(`Stock Quantity Of ${newMaterials[0].product_material_name} is 0 ...!`);
                        setShowErrorMsgModal(true);
                    }
                }

                // Update the material issue details state
                setMaterialIssueDetailsData(updatedArray);
                sessionStorage.setItem('filteredMaterialData', JSON.stringify(updatedArray));

                // setMaterialIssueDetailsData(() => {
                //     let updatedArray = [];
                //     if (newMaterials.length > 0) {

                //         if (newMaterials[0].product_material_stock_quantity > 0) {
                //             updatedArray = [...prevArray, ...newMaterials];
                //         } else {
                //             updatedArray = [...prevArray];
                //             
                //             if (newMaterials[0].reserve_quantity > 0) {
                //                 resetGlobalQuery();
                //                 globalQuery.columns.push('indent_no');
                //                 globalQuery.table = "st_indent_details";
                //                 globalQuery.conditions.push({ field: "product_material_id", operator: "=", value: newMaterials[0].product_material_id, });
                //                 globalQuery.conditions.push({ field: "issue_item_status", operator: "IN", values: ['P', 'I'], });
                //                 // globalQuery.conditions.push({ field: "sub_department_id", operator: "=", value: cmb_sub_department, });
                //                 globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
                //                 globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
                //                 const getReservedIndentNOs = Promise.all(comboDataAPiCall.current.fillFiltersCombo(globalQuery));
                //                 console.log("Reserved Indent NOS", getReservedIndentNOs);

                //                 setErrMsg(`Stock Quantity Of ${newMaterials[0].product_material_name} has Stock In Reserved ...!
                //                         ${getReservedIndentNOs
                //                         .sort((a, b) => a.indent_no - b.indent_no) // Sorting the array by indent_no
                //                         .map((item, index) => `${index + 1}. ${item.indent_no}`)
                //                         .join('\n')}  // Joining with line breaks for better formatting
                //                     `);
                //             } else {
                //                 setErrMsg(`Stock Quantity Of ${newMaterials[0].product_material_name} is 0 ...!`);
                //             }


                //             setShowErrorMsgModal(true);
                //         }
                //     } else {
                //         updatedArray = [...prevArray];
                //     }
                //     sessionStorage.setItem('filteredMaterialData', JSON.stringify(updatedArray));
                //     return updatedArray;
                // });
                sessionStorage.setItem('isComboFilterExist', false);
                var LocalArr = JSON.parse(localStorage.getItem('localStorageKeyArr'));
                console.log('LocalArr: ', LocalArr);
                if (LocalArr != null) {
                    for (let localArrIndex = 0; localArrIndex < LocalArr.length; localArrIndex++) {
                        localStorage.removeItem(LocalArr[localArrIndex]);
                    }
                }
            } catch (error) {
                console.error("Error in handleSearchMaterials:", error);
                setMaterialIssueDetailsData([]);
                sessionStorage.setItem('filteredMaterialData', JSON.stringify([]));
                setIsLoading(false)
            } finally {
                setIsLoading(false)
            }
        } else {
            setMaterialIssueDetailsData([]);
            sessionStorage.setItem('filteredMaterialData', JSON.stringify([]));
        }

        removeSessions();
        setTimeout(() => { categoryDisplay(); }, 300);
    };
    const FnupdateDetailsRowKeys = async (materialItem) => {
        materialItem.indentNoList = []; // user requisition by is right to add material against indent of his department
        resetGlobalQuery();
        globalQuery.columns = ['Distinct(st.indent_no)'];
        globalQuery.table = "st_indent_details st";
        globalQuery.conditions.push({ field: "st.company_id", operator: "=", value: COMPANY_ID });
        globalQuery.conditions.push({ field: "st.is_delete", operator: "=", value: 0 });
        globalQuery.conditions.push({ field: "st.grn_item_status", operator: "IN", values: ["G", "IG"] });
        globalQuery.conditions.push({ field: "st.issue_item_status", operator: "IN", values: ['P', 'I'], });
        globalQuery.conditions.push({ field: "ms.department_id", operator: "=", value: cmb_department });
        globalQuery.conditions.push({ field: "ms.sub_department_id", operator: "=", value: cmb_sub_department });
        globalQuery.conditions.push({ field: "st.product_material_id", operator: "=", value: materialItem.product_id });
        globalQuery.joins.push({
            "table": "st_indent_master ms",
            "type": "LEFT",
            "on": [
                {
                    "left": "st.company_id",
                    "right": "ms.company_id"
                },
                {
                    "left": "st.indent_master_id",
                    "right": "ms.indent_master_id"
                },
            ]
        })

        // let product_stock_quantity = materialItem.stock_quantity;
        // let product_stock_weight = materialItem.stock_weight;
        // let reserve_quantity = 0;
        // let reserve_weight = 0;
        try {
            // if (cmb_indent_no === "") {
            //     resetGlobalQuery();
            //     globalQuery.columns = ['reserve_quantity', 'reserve_weight', 'product_rm_id'];
            //     globalQuery.table = "smv_product_rm_stock_summary";
            //     globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            //     globalQuery.conditions.push({ field: "product_rm_id", operator: "=", value: materialItem.product_id });
            //     if (materialItem.product_type_id !== 2) {
            //         globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            //     }
            //     const getIndentResered = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            //     reserve_quantity = getIndentResered[0].reserve_quantity || 0;
            //     reserve_weight = getIndentResered[0].reserve_weight || 0;

            //     // product_stock_quantity = product_stock_quantity - reserve_quantity;
            //     // product_stock_weight = product_stock_weight - reserve_weight;
            //     console.log("product_stock_quantity - reserve_quantity - : ", materialItem.stock_quantity);
            //     console.log("product_stock_weight - reserve_weight - : ", materialItem.stock_weight);
            //     console.log("  reserve_quantity - : ", reserve_quantity);
            //     console.log("  reserve_weight - : ", reserve_quantity);

            // }

            const getIndentNoList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            materialItem.indentNoList = getIndentNoList;

            const newMaterial = {
                ...materialItem,
                product_tech_spect: materialItem.product_tech_spect,
                product_material_id: materialItem.product_id,
                product_material_name: materialItem.product_name,
                product_material_unit_name: materialItem.product_unit_name,
                product_material_unit_id: materialItem.product_unit_id,
                issue_item_status: 'P',
                issue_remark: '',
                product_material_indent_quantity: '1',
                product_material_indent_weight: materialItem.product_std_weight,
                product_material_issue_quantity: 0,
                product_material_issue_weight: 0,
                product_material_issue_return_quantity: 0,
                product_material_issue_return_weight: 0,
                product_material_stock_quantity: materialItem.stock_quantity,
                product_material_stock_weight: materialItem.stock_weight,
                max_indent_quantity: 0,
                indent_details_id: 0,
                goods_receipt_no: 0
            };
            return newMaterial;
        } catch (error) {
            console.error("Error in FnupdateDetailsRowKeys:", error);
            throw error;
        }
    };
    const FnupdateIndentDetailsRow = (materialItem) => {
        try {

            let requisitionqty = materialItem.product_material_grn_accepted_quantity - materialItem.product_material_indent_issue_quantity;
            let requisitionweight = materialItem.product_material_grn_accepted_quantity - materialItem.product_material_indent_issue_weight;

            // let product_material_stock_quantity = materialItem.indent_type_id === 2 ? materialItem.product_Rawmaterial_stock_quantity : materialItem.product_material_stock_quantity;
            // let product_material_stock_weight = materialItem.indent_type_id === 2 ? materialItem.product_Rawmaterial_stock_weight : materialItem.product_material_stock_weight;

            let product_material_stock_quantity = materialItem.product_material_stock_quantity;
            let product_material_stock_weight = materialItem.product_material_stock_weight;

            // Ensure requisition quantity does not exceed available stock quantity
            if (requisitionqty > parseFloat(product_material_stock_quantity)) {
                requisitionqty = parseFloat(product_material_stock_quantity);
            }

            // Calculate issue weight only if requisition quantity is not zero
            if (requisitionqty !== 0) {
                requisitionweight = requisitionqty * materialItem.product_rm_std_weight;
            }
            const newMaterial = {
                ...materialItem,
                product_tech_spect: materialItem.product_rm_tech_spect,
                product_material_code: materialItem.product_material_code,
                product_material_name: materialItem.product_rm_name,
                product_material_unit_name: materialItem.product_rm_stock_unit_name,
                issue_item_status: 'P',
                issue_remark: '',
                product_material_indent_quantity: requisitionqty,
                product_material_indent_weight: requisitionweight,
                product_material_issue_quantity: 0,
                product_material_issue_weight: 0,
                product_material_issue_return_quantity: 0,
                product_material_issue_return_weight: 0,
                product_material_approved_quantity: 0,
                product_material_approved_weight: 0,
                product_material_rejected_quantity: 0,
                product_material_rejected_weight: 0,
                product_material_stock_quantity: product_material_stock_quantity,
                product_material_stock_weight: product_material_stock_weight,
                max_indent_quantity: requisitionqty,
                product_lead_time: materialItem.lead_time,
                product_std_weight: materialItem.product_rm_std_weight,

            };
            console.log('newMaterial: ', newMaterial);
            return newMaterial;
        } catch (error) {
            console.error("Error in FnupdateDetailsRowKeys:", error);
            throw error;
        }
    };

    // Function to handle input changes and update state variables
    const validateMaterialDetails = (event) => {
        const numCheck = /^\d{0,18}(?:\.\d{0,4})?$/;
        let indentItemsQtyinp = document.querySelector('#' + event.target.id);
        if (event.target.value === '' || event.target.value === null || !numCheck.test(event.target.value)) {
            indentItemsQtyinp.parentElement.dataset.tip = 'Please enter valid input...!';
            return false;
        } else {
            delete indentItemsQtyinp.parentElement.dataset.tip;
            return true;
        }
    }
    // Function to handle input changes and update state variables
    const handleIssueDetailsInputChange = async (currentRowData, event, arrayIndex, param) => {
        
        console.log('event : - ', event.value);
        let clickedColName;
        let issueItemsQtyinp;
        if (param === 'cost_center') {
            clickedColName = 'cost_center_id';
        } else if (param === 'profit_center') {
            clickedColName = 'profit_center_id';
        } else {
            clickedColName = event.target.getAttribute('Headers');               // Get the column name where event triggered.
            issueItemsQtyinp = document.querySelector('#' + event.target.id);
        }

        let prev_product_material_receipt_quantity = currentRowData.product_material_receipt_quantity;
        const stockQty = currentRowData.product_material_stock_quantity

        let generateroutingcode = () => {
            let profitcentertext = profit_centerOptions.find(option => option.value === currentRowData.profit_center_id)?.profit_center_short_name;
            let costcentertext = cost_centerOptions.find(option => option.value === currentRowData.cost_center_id)?.cost_center_short_name;

            // Check if the texts are not empty or zero
            if (profitcentertext !== '0' && profitcentertext !== '' && profitcentertext !== undefined && costcentertext !== '0' && costcentertext !== '' && costcentertext !== undefined) {
                currentRowData['routing_code'] = profitcentertext + "-" + costcentertext;
            }
        };

        switch (clickedColName) {
            case 'product_material_issue_quantity':
                const approveQty = parseInt(currentRowData.product_material_approved_quantity)
                if (event.target.value.trim() !== "") {
                    delete issueItemsQtyinp.parentElement.dataset.tip;
                    currentRowData.issue_item_status = "MI";
                    if (stockQty >= approveQty) {
                        if (approveQty < event.target.value) {
                            issueItemsQtyinp.parentElement.dataset.tip = 'Issue quantity cant exceed Approved quantity...!';
                            currentRowData[clickedColName] = '';
                            fnCalculateItemQtyandWeight(currentRowData)
                        } else {
                            delete issueItemsQtyinp.parentElement.dataset.tip;
                            currentRowData[clickedColName] = validateNumberDateInput.current.decimalNumber(event.target.value, 4);
                            fnCalculateItemQtyandWeight(currentRowData)
                        }
                    }
                    else if (approveQty >= stockQty) {
                        if (stockQty < event.target.value) {
                            issueItemsQtyinp.parentElement.dataset.tip = 'Issue quantity cant exceed stock quantity...!';
                            currentRowData[clickedColName] = '';
                            fnCalculateItemQtyandWeight(currentRowData)
                        } else {
                            delete issueItemsQtyinp.parentElement.dataset.tip;
                            currentRowData[clickedColName] = validateNumberDateInput.current.decimalNumber(event.target.value, 4);
                            fnCalculateItemQtyandWeight(currentRowData)
                        }
                    }
                } else {
                    currentRowData[clickedColName] = '';
                    currentRowData.issue_item_status = "P";
                }
                break;
            // case 'godown_id':

            // break;
            // case 'godown_id':

            //     let product_material_id = currentRowData.product_material_id;
            //     let selectedGodownIDs = '';
            //     let checkedLenght = 0;
            //     $(`input:checkbox[name=chk_godown_id_${product_material_id}]:checked`).each(function () {
            //         let godownID = parseInt($(this).val());
            //         if (selectedGodownIDs !== '') {
            //             selectedGodownIDs += ',';
            //         }
            //         selectedGodownIDs += godownID;
            //         checkedLenght += 1;
            //     })
            //     currentRowData[clickedColName] = selectedGodownIDs;
            //     delete event.target.parentElement.parentElement.parentElement.dataset.tip
            //     $(`#godown_selectedCount_id_${product_material_id}`).text(``);
            //     $(`#godown_selectedCount_id_${product_material_id}`).text(`${checkedLenght} selected`);
            //     break;
            case 'godown_section_beans_id':
                let product_material_id = currentRowData.product_material_id;
                let selectedGodownIDs = '';
                let checkedLenght = 0;
                $(`input:checkbox[name=chk_godown_section_beans_id_${product_material_id}]:checked`).each(function () {
                    let godownID = parseInt($(this).val());
                    if (selectedGodownIDs !== '') {
                        selectedGodownIDs += ',';
                    }
                    selectedGodownIDs += godownID;
                    checkedLenght += 1;
                })
                currentRowData[clickedColName] = selectedGodownIDs;
                delete event.target.parentElement.parentElement.parentElement.dataset.tip
                $(`#godown_selectedCount_id_${product_material_id}`).text(``);
                $(`#godown_selectedCount_id_${product_material_id}`).text(`${checkedLenght} selected`);
                break;
            case 'issue_item_status':
                if (event.target.value !== '') {
                    currentRowData[clickedColName] = event.target.value;
                    delete issueItemsQtyinp.parentElement.dataset.tip;
                }
                break;
            case 'cost_center_id':
                if (event.value !== '') {
                    currentRowData[clickedColName] = event.value;
                    generateroutingcode();
                    document.getElementById(`cost_center_id_${currentRowData.product_material_id}`).removeAttribute('data-tip');

                    // delete issueItemsQtyinp.parentElement.dataset.tip;
                    if (event.value === '0') {
                        sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                        setHeaderName('Cost Center')
                        setshowAddRecIndentIssueModal(true)
                        setTimeout(() => {
                            $(".erp_top_Form").eq(0).css("padding-top", "0px");
                        }, 200)
                    }
                } else {
                    issueItemsQtyinp.parentElement.dataset.tip = 'Please select cost center...!';
                    currentRowData[clickedColName] = '';
                }
                break;
            case 'profit_center_id':
                if (event.value !== '') {
                    currentRowData[clickedColName] = event.value;
                    generateroutingcode();
                    document.getElementById(`profit_center_id_${currentRowData.product_material_id}`).removeAttribute('data-tip');
                    // delete issueItemsQtyinp.parentElement.dataset.tip;
                    if (event.value === '0') {
                        sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                        setHeaderName('Cost Center')
                        setshowAddRecIndentIssueModal(true)
                        setTimeout(() => {
                            $(".erp_top_Form").eq(0).css("padding-top", "0px");
                        }, 200)
                    }
                } else {
                    issueItemsQtyinp.parentElement.dataset.tip = 'Please select profit center...!';
                    currentRowData[clickedColName] = '';
                }
                break;
            case 'issue_remark':
                currentRowData[clickedColName] = event.target.value;
                break;
            case 'product_material_indent_quantity':
                if (validateMaterialDetails(event)) {
                    let material_qty = event.target.value;
                    if (stockQty >= material_qty) {
                        currentRowData[clickedColName] = material_qty;
                        let product_std_wgt = currentRowData['product_std_weight']
                        let product_material_wgt = material_qty * product_std_wgt;
                        currentRowData['product_material_indent_weight'] = validateNumberDateInput.current.decimalNumber(product_material_wgt, 4);
                        delete issueItemsQtyinp.parentElement.dataset.tip;
                    } else {
                        issueItemsQtyinp.parentElement.dataset.tip = 'Requisition quantity cant exceed stock quantity...!';
                        currentRowData[clickedColName] = '';
                    }
                } else {
                    currentRowData[clickedColName] = '';
                }
                break;
            case 'product_material_approved_quantity':
                if (validateMaterialDetails(event)) {
                    let product_app_qty = event.target.value;
                    let material_qty = currentRowData.product_material_indent_quantity;
                    let product_rejct_qty = (material_qty - product_app_qty);
                    let product_std_wgt = currentRowData['product_std_weight']
                    let product_approved_wgt = product_app_qty * product_std_wgt;
                    let product_rejct_wgt = product_rejct_qty * product_std_wgt;

                    currentRowData['product_material_approved_weight'] = validateNumberDateInput.current.decimalNumber(product_approved_wgt, 4)
                    currentRowData['product_material_rejected_weight'] = validateNumberDateInput.current.decimalNumber(product_rejct_wgt, 4)
                    let indentItemsQtyinp = document.querySelector('#' + event.target.id);

                    if (product_app_qty > material_qty) {
                        indentItemsQtyinp.parentElement.dataset.tip = `Approve qauntity not be greater than ${material_qty}...!`;
                        return false;
                    } else {
                        currentRowData[clickedColName] = product_app_qty;
                    }
                    if (product_rejct_qty > 0) {
                        currentRowData['product_material_rejected_quantity'] = product_rejct_qty
                        // var $rejectionRemark = $(`#approval_remark_${currentRowData.product_id}`);
                        // if ($rejectionRemark.val() === "") {
                        //     $rejectionRemark.prop('disabled', false);
                        //     if (event._reactName === 'onBlur') {
                        //         $rejectionRemark.focus();
                        //     }

                        // }
                    } else {
                        currentRowData['product_material_rejected_quantity'] = 0
                        delete indentItemsQtyinp.parentElement.dataset.tip;
                    }
                } else {
                    currentRowData[clickedColName] = '';
                }
                break;
            case 'product_material_receipt_quantity':
                if (event.target.value <= currentRowData.product_material_issue_quantity) {
                    currentRowData[clickedColName] = validateNumberDateInput.current.decimalNumber(event.target.value, 4);
                    currentRowData['product_material_receipt_weight'] = currentRowData[clickedColName] * currentRowData.product_std_weight;
                    currentRowData['product_material_issue_return_quantity'] = currentRowData.product_material_issue_quantity - currentRowData.product_material_receipt_quantity;
                    currentRowData['product_material_issue_return_weight'] = currentRowData['product_material_issue_return_quantity'] * currentRowData.product_std_weight;
                    currentRowData['issue_item_status'] = "AC";
                    delete issueItemsQtyinp.parentElement.dataset.tip;
                } else if (isNaN(event.target.value)) {
                    currentRowData[clickedColName] = "";
                } else {
                    currentRowData[clickedColName] = prev_product_material_receipt_quantity;
                    issueItemsQtyinp.parentElement.dataset.tip = 'Enter Accept Quantity less than or equal to Issue Quantity';
                }
                break;
            case 'indent_no':
                currentRowData[clickedColName] = event.target.value;
                if (event.target.value !== "") {
                    // Indent no List
                    resetGlobalQuery();
                    globalQuery.columns = ['st.indent_no', 'st.indent_details_id', "st.product_material_approved_quantity",
                        "st.product_material_approved_weight", "st.product_material_issue_quantity", "st.product_material_issue_weight",
                        "st.product_material_grn_accepted_quantity", "st.product_material_grn_accepted_weight", "grn.goods_receipt_no"];
                    globalQuery.table = "st_indent_details st";
                    globalQuery.joins.push({
                        "table": "ptv_goods_receipt_details grn",
                        "type": "LEFT",
                        "on": [
                            {
                                "left": "grn.product_material_id",
                                "right": "st.product_material_id"
                            },
                            {
                                "left": "grn.company_id",
                                "right": "st.company_id"
                            },
                            {
                                "left": "grn.indent_no",
                                "right": "st.indent_no"
                            },
                        ]
                    })
                    globalQuery.conditions.push({ field: "st.company_id", operator: "=", value: COMPANY_ID });
                    globalQuery.conditions.push({ field: "st.is_delete", operator: "=", value: 0 });
                    globalQuery.conditions.push({ field: "st.issue_item_status", operator: "IN", values: ["P", "I"] });
                    globalQuery.conditions.push({ field: "st.grn_item_status", operator: "IN", values: ["G", "IG"] });
                    globalQuery.conditions.push({ field: "st.indent_no", operator: "=", value: event.target.value });
                    globalQuery.conditions.push({ field: "st.product_material_id", operator: "=", value: currentRowData.product_material_id });
                    const getIndentNoList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                    if (getIndentNoList.length !== 0) {
                        currentRowData.indent_details_id = getIndentNoList[0].indent_details_id;
                        currentRowData.product_material_indent_quantity = getIndentNoList[0].product_material_grn_accepted_quantity - getIndentNoList[0].product_material_issue_quantity;
                        currentRowData.product_material_indent_weight = getIndentNoList[0].product_material_grn_accepted_weight - getIndentNoList[0].product_material_issue_weight;
                        currentRowData.max_indent_quantity = currentRowData.product_material_indent_quantity;
                        currentRowData.goods_receipt_no = getIndentNoList[0].goods_receipt_no;
                    }
                } else {
                    currentRowData.indent_details_id = 0;
                    currentRowData.product_material_indent_quantity = 0;
                    currentRowData.product_material_indent_weight = 0;
                    currentRowData.max_indent_quantity = 0;
                    currentRowData.goods_receipt_no = "";
                }

                break;
            default:
                break;
        }



        const detailData = [...materialIssueDetailsData]
        detailData[arrayIndex] = currentRowData
        setMaterialIssueDetailsData(detailData);

    };


    // ------------------------------Qty and Amout Calculation-------------------------------------------------------------------------------------------
    const fnCalculateItemQtyandWeight = (currentRowData) => {
        let issueWeight = 0;

        const indentstdweight = currentRowData.product_std_weight
        const issueQty = currentRowData.product_material_issue_quantity
        //to calculate issue weight
        if (issueQty != '') {
            issueWeight = indentstdweight * issueQty
            currentRowData['product_material_issue_weight'] = validateNumberDateInput.current.decimalNumber(issueWeight.toString(), 4);
        } else {
            currentRowData['product_material_issue_weight'] = 0;
        }

        return currentRowData;
    }


    function scrollToTableRow() {
        var keywords = $("#txt-detail-data-highliter").val();
        var table = document.getElementById('indent-details-tbl');
        var rows = table.getElementsByTagName('tr');

        for (var trNum = 1; trNum < rows.length; trNum++) {
            var row = rows[trNum];
            if (row.innerHTML.toLowerCase().indexOf(keywords.toLowerCase()) !== -1) {
                var w = $(window);
                var row = $('#indent-details-tbl').find('tr').eq(trNum);

                if (row.length) {
                    w.scrollTop(row.offset().top - (w.height() / 2));
                }

                $(row).find('td').each(function () {
                    $(this).addClass('highlight');
                });
                removeOtherTrHighlightClass(trNum)
                break;
            } else {
                $(row).find('td').each(function () {
                    $(this).removeClass('highlight');
                });
            }
        }
    }

    const removeOtherTrHighlightClass = (rowNum) => {
        var table = document.getElementById('indent-details-tbl');
        var rows = table.getElementsByTagName('tr');
        for (var trNum = 1; trNum < rows.length; trNum++) {
            var row = rows[trNum];
            if (trNum !== rowNum) {
                $(row).find('td').each(function () {
                    $(this).removeClass('highlight');
                });
            }
        }
    }
    //-------------------------------------------End Details section --------------------------------------------------------------------------
    const validateFields = () => {
        validate.current.validateFieldsOnChange('material_issue_form_id')
    }
    // ---------------------fn for save indent ----------------------------------------------------------------
    const addIssueslip = async () => {
        
        try {
            let checkIsValidate = true;
            if (keyForViewUpdate !== "view" && keyForViewUpdate !== "delete") {
                checkIsValidate = await FnValidateIssueOrder();
            }
            if (checkIsValidate === true) {
                setIsLoading(true)
                let lastIndentissueNo = txt_material_issue_no;
                if (keyForViewUpdate === "") {
                    lastIndentissueNo = await generateIndentIssueNo();
                }

                let json = { 'TransHeaderData': {}, 'TransDetailData': [], 'commonIds': {} }

                // TransHeaderData
                const indentMasterFormData = {
                    issue_master_transaction_id: keyForViewUpdate !== "" ? issue_master_transaction_id : 0,
                    company_branch_id: COMPANY_BRANCH_ID,
                    financial_year: FINANCIAL_SHORT_NAME,
                    issue_no: lastIndentissueNo,
                    issue_date: dt_material_issue_date,
                    issue_version: txt_material_issue_version,
                    issue_source: chk_issue_source,
                    customer_id: cmb_customer,
                    customer_order_no: cmb_customer_order_no,
                    customer_order_date: customer_order_date,
                    department_id: cmb_department,
                    sub_department_id: cmb_sub_department,
                    requisition_by_id: cmb_requisition_by,
                    requisition_date: dt_requisition_date,
                    approved_by_id: cmb_approved_by_id,
                    approved_date: dt_approved_date,
                    accepted_by_id: cmb_accepted_by,
                    accepted_date: dt_accepted_date,
                    cost_center_id: cmb_cost_center,
                    expected_schedule_date: txt_schedule_date,
                    issue_status: chk_Issue_status,
                    issued_by_id: txt_issued_by,
                    remark: txt_remark,
                    is_active: chk_isactive,
                    created_by: UserName,
                    modified_by: idList !== "" ? UserName : null,
                    company_id: COMPANY_ID,
                    indent_issue_type_id: cmb_material_type,
                    indent_issue_type: $('#cmb_material_type').find(":selected").text(),
                    issue_group_type: cmb_issue_group_type,
                    set_no: 0,
                    sales_type: '',
                    godown_issuer_id: ''
                }
                json.TransHeaderData = indentMasterFormData;


                let isValid = true; // Flag to track validity

                for (let issueItem = 0; issueItem < materialIssueDetailsData.length; issueItem++) {
                    const indentIssueDetailsRow = materialIssueDetailsData[issueItem];

                    // Check if profit_center_id is null or 0
                    if (!indentIssueDetailsRow.profit_center_id || indentIssueDetailsRow.profit_center_id === 0) {
                        isValid = false; // Set flag to false
                        // You can add a class or modify the DOM element directly if needed
                        document.getElementById(`profit_center_id_${indentIssueDetailsRow.product_material_id}`).classList.add('required-field');
                        document.getElementById(`profit_center_id_${indentIssueDetailsRow.product_material_id}`).setAttribute('data-tip', 'Please select atleast one Profit Center');
                        return isValid; // Exit the loop if a validation fails
                    }

                    // Check if cost_center_id is null or 0
                    if (!indentIssueDetailsRow.cost_center_id || indentIssueDetailsRow.cost_center_id === 0) {
                        isValid = false; // Set flag to false

                        // You can add a class or modify the DOM element directly if needed
                        document.getElementById(`cost_center_id_${indentIssueDetailsRow.product_material_id}`).classList.add('required-field');
                        document.getElementById(`cost_center_id_${indentIssueDetailsRow.product_material_id}`).setAttribute('data-tip', 'Please select atleast one Cost Center');

                        return isValid; // Exit the loop if a validation fails
                    }

                    // if (!isValid) {
                    //     return false;
                    // }

                    // if (indentIssueDetailsRow.product_material_issue_quantity > 0) {
                    const IndentIssueRow = {};
                    IndentIssueRow['company_id'] = COMPANY_ID
                    IndentIssueRow['company_branch_id'] = COMPANY_BRANCH_ID
                    IndentIssueRow['financial_year'] = FINANCIAL_SHORT_NAME
                    IndentIssueRow['issue_details_transaction_id'] = keyForViewUpdate !== "" ? indentIssueDetailsRow.issue_details_transaction_id : 0
                    IndentIssueRow['financial_year'] = FINANCIAL_SHORT_NAME
                    IndentIssueRow['issue_no'] = lastIndentissueNo
                    IndentIssueRow['issue_date'] = dt_material_issue_date
                    IndentIssueRow['issue_version'] = txt_material_issue_version
                    IndentIssueRow['indent_no'] = indentIssueDetailsRow.indent_no
                    IndentIssueRow['indent_date'] = indentIssueDetailsRow.indent_date
                    IndentIssueRow['indent_version'] = indentIssueDetailsRow.indent_version
                    IndentIssueRow['product_material_id'] = indentIssueDetailsRow.product_material_id
                    IndentIssueRow['product_material_unit_id'] = indentIssueDetailsRow.product_material_unit_id
                    IndentIssueRow['product_material_indent_quantity'] = indentIssueDetailsRow.product_material_indent_quantity
                    IndentIssueRow['product_material_indent_weight'] = indentIssueDetailsRow.product_material_indent_weight
                    IndentIssueRow['product_material_approved_quantity'] = indentIssueDetailsRow.product_material_approved_quantity
                    IndentIssueRow['product_material_approved_weight'] = indentIssueDetailsRow.product_material_approved_weight
                    IndentIssueRow['product_material_rejected_quantity'] = indentIssueDetailsRow.product_material_rejected_quantity
                    IndentIssueRow['product_material_rejected_weight'] = indentIssueDetailsRow.product_material_rejected_weight
                    IndentIssueRow['product_material_issue_quantity'] = indentIssueDetailsRow.product_material_issue_quantity
                    IndentIssueRow['product_material_issue_weight'] = indentIssueDetailsRow.product_material_issue_weight
                    IndentIssueRow['product_material_receipt_quantity'] = indentIssueDetailsRow.product_material_receipt_quantity
                    IndentIssueRow['product_material_receipt_weight'] = indentIssueDetailsRow.product_material_receipt_weight
                    IndentIssueRow['product_material_issue_return_quantity'] = indentIssueDetailsRow.product_material_issue_return_quantity
                    IndentIssueRow['product_material_issue_return_weight'] = indentIssueDetailsRow.product_material_issue_return_weight
                    IndentIssueRow['product_std_weight'] = indentIssueDetailsRow.product_std_weight
                    IndentIssueRow['issue_item_status'] = indentIssueDetailsRow.issue_item_status
                    IndentIssueRow['indented_by_id'] = indentIssueDetailsRow.indented_by_id
                    IndentIssueRow['issue_remark'] = indentIssueDetailsRow.issue_remark
                    IndentIssueRow['issue_master_transaction_id'] = keyForViewUpdate !== "" ? issue_master_transaction_id : 0
                    IndentIssueRow['indent_details_id'] = indentIssueDetailsRow.indent_details_id
                    IndentIssueRow['customer_order_no'] = ''
                    IndentIssueRow['issue_batch_no'] = indentIssueDetailsRow.issue_batch_no
                    IndentIssueRow['created_by'] = keyForViewUpdate === "" ? UserName : indentIssueDetailsRow.created_by
                    IndentIssueRow['modified_by'] = keyForViewUpdate !== "" ? UserName : null
                    IndentIssueRow['prev_issue_quantity'] = indentIssueDetailsRow.prev_issue_quantity
                    IndentIssueRow['prev_issue_weight'] = indentIssueDetailsRow.prev_issue_weight
                    IndentIssueRow['godown_id'] = indentIssueDetailsRow.godown_id
                    IndentIssueRow['godown_section_id'] = indentIssueDetailsRow.godown_section_id
                    IndentIssueRow['godown_section_beans_id'] = indentIssueDetailsRow.godown_section_beans_id
                    IndentIssueRow['cost_center_id'] = indentIssueDetailsRow.cost_center_id
                    IndentIssueRow['profit_center_id'] = indentIssueDetailsRow.profit_center_id
                    IndentIssueRow['routing_code'] = indentIssueDetailsRow.routing_code
                    IndentIssueRow['product_material_issue_boxes'] = 0
                    IndentIssueRow['goods_receipt_no'] = indentIssueDetailsRow.goods_receipt_no
                    IndentIssueRow['creel_no'] = ''
                    IndentIssueRow['cone_per_wt'] = 0
                    IndentIssueRow['set_no'] = ''
                    IndentIssueRow['issue_requisition_type'] = "M"
                    json.TransDetailData.push(IndentIssueRow);

                    // }
                }
                console.log(json.TransDetailData);

                // Common Ids
                json.commonIds.company_id = COMPANY_ID
                json.commonIds.issue_no = keyForViewUpdate === '' ? lastIndentissueNo : txt_material_issue_no
                json.commonIds.financial_year = FINANCIAL_SHORT_NAME
                console.log(json);
                const formData = new FormData();
                formData.append(`StIndentMaterialIssueData`, JSON.stringify(json))
                const requestOptions = {
                    method: 'POST',
                    body: formData
                };
                const acceptFlag = keyForViewUpdate === "issue" ? "issue" : "addOrUpdate";

                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/StIndentIssueMaster/FnAddUpdateRecord/${acceptFlag}`, requestOptions)
                const responce = await apicall.json();

                if (responce.success === "0") {
                    $('#save-btn').prop("disable", true);
                    $('#issue-btn').prop("disable", true);
                    setErrMsg(responce.error)
                    setShowErrorMsgModal(true)

                } else {
                    if (responce.data.hasOwnProperty('issue_no') && responce.data.hasOwnProperty('issue_version')) {
                        setModalOrderDetails(`Issue No: ${responce.data.issue_no} and Version: ${responce.data.issue_version}`)
                    }
                    //  setIndentMasterId(responce.data.indent_master_id)
                    // setIndentno(responce.data.indent_no)
                    // setIndentDate(responce.data.indent_date)
                    setSuccMsg(responce.message)
                    setShowSuccessMsgModal(true);
                }
                setIsLoading(false)
            }
        } catch (error) {
            console.log("error: ", error)
        } finally {
            setIsLoading(false)
        }
    }



    const FnValidateIssueOrder = async () => {

        let headerDataIsValid = await validate.current.validateForm("material_issue_form_id");
        if (!headerDataIsValid) { return false; }

        // Check combo values for customer combo
        let customerVal = cmb_customer || '';
        if (chk_issue_source === 'S' && (customerVal === "" || customerVal === '0')) {
            $("#error_cmb_customer").text("Please fill this field...!");
            $("#error_cmb_customer").show();
            $("#cmb_customer").focus();
            return false;
        }
        let requisition_by = cmb_requisition_by || '';
        if (requisition_by === "" || requisition_by === '0') {
            $("#error_cmb_requisition_by").text("Please fill this field...!");
            $("#error_cmb_requisition_by").show();
            $("#cmb_requisition_by").focus();
            return false;
        }
        let issueDetailsIsValid = false;
        let addedIssuematerial = $('#issue-details-tbl tbody tr').length;
        if (addedIssuematerial <= 0 && keyForViewUpdate !== 'view') {
            setErrMsg('Please Add atleast one Issued Detail.');
            setShowErrorMsgModal(true);
            return issueDetailsIsValid;
        } else {
            const tableRows = $('#issue-details-tbl tbody tr');
            issueDetailsIsValid = true;
            tableRows.each(function () {
                // if (keyForViewUpdate === "") {
                //     const cost_center = parseInt($(this).find('select[id^="cost_center_id_"]').val());
                //     if (isNaN(cost_center) || cost_center === "" || cost_center === 0) {
                //         $(this).find(`select[id^='cost_center_id_']`).parent().attr('data-tip', 'Please Select Cost Center..!');
                //         $(this).find(`select[id^='cost_center_id_']`).focus();
                //         setOpenIssueDetailsAccord(true)
                //         issueDetailsIsValid = false;
                //         return false;
                //     }
                // }
                // else 
                if (keyForViewUpdate === "") {
                    const requisition_quantity = parseFloat($(this).find('input[id^="product_material_indent_quantity_"]').val());
                    if (isNaN(requisition_quantity) || requisition_quantity === "" || requisition_quantity === 0) {
                        $(this).find(`input[id^='product_material_indent_quantity_']`).parent().attr('data-tip', 'Please enter a valid Quantity...!');
                        $(this).find(`input[id^='product_material_indent_quantity_']`).focus();
                        setOpenIssueDetailsAccord(true)
                        issueDetailsIsValid = false;
                        return false;
                    }
                }
                else if (keyForViewUpdate === "issue") {
                    const issueQty = parseFloat($(this).find('input[id^="product_material_issue_quantity_"]').val());
                    if (isNaN(issueQty) || issueQty === "") {
                        $(this).find(`input[id^='product_material_issue_quantity_']`).parent().attr('data-tip', 'Please enter a valid Quantity...!');
                        $(this).find(`input[id^='product_material_issue_quantity_']`).focus();
                        setOpenIssueDetailsAccord(true)
                        issueDetailsIsValid = false;
                        return false;
                    }
                }
            });
            return issueDetailsIsValid;
        }
    }



    const FnClearFormFields = async () => {

        setmaterialType('')
        setMaterialIssueNo('0')
        setIssueSource('I')
        setCustomerId('');
        setmaterialissueversion('1');
        setScheduleDate(todayDate);
        setRequisitionDate(todayDate)
        setDepartmentId(DEPARTMENT_ID);
        setSubDepartmentId('')
        setAcceptedById('');
        setIssueStatus("P");
        setissued_by(UserName);
        setRemark('');
        setIsActive(true);
        setmaterialissueDate(todayDate);
        setIndentNo("")
        costCenterComboRef.current.value = '';
        customerComboRef.current.value = '';
        indentNosComboRef.current.value = '';
        setCostCenter('')
        setRequisitionBy('')
        sessionStorage.removeItem('filteredMaterialData');
        sessionStorage.removeItem('requiredCols');
        setMaterialIssueDetailsData([]);
    }

    const moveToListing = () => {
        let Path = compType === 'Register' ? '/Transactions/TMaterialRequisition/FrmMaterialRequisitionLIsting/reg' : `/Transactions/TMaterialRequisition/FrmMaterialRequisitionLIsting`;
        Path = `${requestfor ? `${Path}/${requestfor}` : `${Path}`}`;
        navigate(Path);
    }

    const refreshData = async (key) => {

        switch (key) {

            case 'get_employee':
                resetGlobalQuery();
                globalQuery.columns.push("field_id");
                globalQuery.columns.push("field_name");
                globalQuery.table = "cmv_employee"
                // globalQuery.conditions.push({ field: "department_id", operator: "=", value: cmb_department });
                // globalQuery.conditions.push({ field: "sub_department_id", operator: "=", value: cmb_sub_department });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                globalQuery.conditions.push({ field: "employee_type", operator: "!=", value: "Worker" });
                const getEmployeesApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                //  ** Changes for the Searchable Combo
                const indentedByOpts = [
                    { value: '', label: 'Select', },
                    ...getEmployeesApiCall.map((empl) => ({ ...empl, value: empl.field_id, label: empl.field_name })),
                ];
                setRequisitionOptions(indentedByOpts);
                break;
            case 'get_customer':
                const customerOrderIdApiCall = await comboDataAPiCall.current.fillMasterData("cmv_customer", "", "")
                // setCustomerOptions(customerOrderIdApiCall)
                //  ** Changes for the Searchable Combo
                const options = [
                    { value: '', label: 'Select' },
                    { value: '0', label: 'Add New Record+' },
                    // ...customerOrderIdApiCall.map(({ field_id, field_name }) => ({ value: field_id, label: field_name })),
                    ...customerOrderIdApiCall.map((customer) => ({ ...customer, value: customer.field_id, label: customer.field_name, })),
                ];
                setCustomerOptions(options);
                break;
            default:
                break;
        }
    }




    $('body').on('click', '.RemoveTrBtn', function () {
        var $tr = $(this).closest('tr');
        $tr.remove();
    });

    $(document).on('click', '.erp_input_field.text-end.remove0', function () {
        if ($(this).val() === "0") {
            $(this).val("");
        }
    });

    $(document).on('mouseup mousedown', function (e) {
        let inputBoxes = $(".erp_input_field.text-end.remove0");
        if (e.target.className !== "erp_input_field text-end remove0") {
            inputBoxes.each(function () {
                if ($(this).val() === "") {
                    $(this).val("0");
                }
            });
        }
    });
    // Get godown List material wise
    // const FnshowGodownsForMaterial = (issueItem, product_material_id) => {
    //     if (issueItem.godownOptions.dataArray.length !== 0) {
    //         let listItems = issueItem.godownOptions.dataArray.map((item, index) => {
    //             return (
    //                 // checked={item.godown_id == 2 ? true : false} fo main godown
    //                 <label class="checkbox">
    //                     <input type="checkbox" value={item.godown_id} id={`chk_godown_id_${product_material_id}_${item.godown_id}`} Headers='godown_id' className="issueField"
    //                         name={`chk_godown_id_${product_material_id}`} closing_balance_quantity={item.closing_balance_quantity} onChange={(e) => { handleIssueDetailsInputChange(issueItem, e, Index); }} />
    //                     <span className="pl-2">{`${item.godown_name} Balance: ${item.closing_balance_quantity}`}</span></label>
    //             );
    //         });
    //         // $(`#godown_selectedCount_id_${product_material_id}`).text(`0 selected`);
    //         return listItems;
    //     } else {
    //         return (
    //             <div>
    //                 <span className="erp_validation text-center" fontWeight="regular" color="error"> No Records Found... </span>
    //             </div>
    //         );
    //     }
    // }
    // Get godown List material wise
    const FnshowGodownsForMaterial = (issueItem, product_material_id) => {
        if (issueItem.godownSectionBeansOptions.length !== 0) {
            let listItems = issueItem.godownSectionBeansOptions.map((item, index) => {
                return (
                    // checked={item.godown_id == 2 ? true : false} fo main godown
                    <label class="checkbox">
                        <input type="checkbox" value={item.godown_section_beans_id} id={`chk_godown_section_beans_id_${product_material_id}_${item.godown_section_beans_id}`} Headers='godown_section_beans_id' className="issueField"
                            name={`chk_godown_section_beans_id_${product_material_id}`} closing_balance_quantity={item.closing_balance_quantity} onChange={(e) => { handleIssueDetailsInputChange(issueItem, e); }} />
                        {/* <span className="pl-2">{` ${item.godown_section_beans_name}  Balance: ${item.closing_balance_quantity}`}</span> */}
                        <span className="pl-2">
                            {` ${item.godown_section_beans_name}  `}
                            <strong>Balance: </strong>{`${item.closing_balance_quantity}`}
                        </span>
                    </label>
                );
            });
            // $(`#godown_selectedCount_id_${product_material_id}`).text(`0 selected`);
            return listItems;
        } else {
            return (
                <div>
                    <span className="erp_validation text-center" fontWeight="regular" color="error"> No Records Found... </span>
                </div>
            );
        }
    }
    function FnToggleDropdown(opendropdown, indent_details_id) {
        switch (opendropdown) {
            case 'displayIndentNos':
                var element = document.getElementById(`indent_no_${indent_details_id}`);
                if (element.style.display === 'none' || element.style.display === '') {
                    element.style.display = 'block';
                } else {
                    element.style.display = 'none';
                }
                break;
            case 'displayGodownList':

                // var element = document.getElementById(`godown_id_${indent_details_id}`);
                var element = document.getElementById(`godown_section_beans_id_${indent_details_id}`);
                if (element.style.display === 'none' || element.style.display === '') {
                    element.style.display = 'block';
                } else {
                    element.style.display = 'none';
                }
                break;
            default:
                break;
        }

    }

    $(document).on('click', '.remove_input_zero', function () {
        if ($(this).val() === "0") {
            $(this).val("")
        }
    });
    $(document).on('mouseup mousedown', function (e) {
        let inputBoxes = $(".remove_input_zero");
        inputBoxes.each(function () {
            if ($(this).val() === "") {
                $(this).val("0");
            }
        });
    });

    // fn to remove material
    const removeMaterial = (indexToRemove, setStateCallback) => {
        if (indexToRemove === undefined || indexToRemove < 0 || indexToRemove >= materialIssueDetailsData.length) {
            console.error("Invalid index provided.");
            return;
        }
        if (keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
            // Create a new array excluding the element at indexToRemove
            const updatedDetailsData = materialIssueDetailsData.filter((_, index) => index !== indexToRemove);

            // Update state using the provided callback
            setStateCallback(updatedDetailsData);

            // Store the updated data in session storage
            sessionStorage.setItem('filteredMaterialData', JSON.stringify(updatedDetailsData));
        } else {
            console.error("Cannot remove material in 'approve' or 'view' mode.");
        }
    }

    var deptStockQty = $('#cmb_department').find(":selected").text() + " Stock Quantity";
    var deptStockWeight = $('#cmb_department').find(":selected").text() + " Stock Weight";

    const renderDetailsTable = useMemo(() => {
        
        return <>
            <Table className="erp_table erp_table_scroll" id='issue-details-tbl' responsive bordered striped>
                <thead className="erp_table_head">
                    <tr>
                        <th className={`erp_table_th  ${keyForViewUpdate === "" || keyForViewUpdate === "update" ? 'display' : 'd-none'}`}>Action</th>
                        <th className="erp_table_th">Sr.no</th>
                        <th className="erp_table_th">Material Code</th>
                        <th className="erp_table_th">Material Name</th>
                        <th className="erp_table_th">Material unit</th>
                        <th className="erp_table_th">Store Stock Quantity</th>
                        <th className="erp_table_th">Reserve Stock Quantity</th>
                        <th className="erp_table_th">Requisition Quantity</th>
                        <th className="erp_table_th">Approved Quantity</th>
                        <th className={`erp_table_th ${keyForViewUpdate === "issue" ? 'display' : 'd-none'}`}>Previous Issue Quantity</th>
                        <th className="erp_table_th">Issue Quantity</th>
                        {/* <th className={`erp_table_th ${keyForViewUpdate === "issue" || (keyForViewUpdate === "view" && chk_Issue_status === "MI") ? 'display' : 'd-none'}`} style={{ textAlign: 'center' }}>Material Godown</th>
                        <th className={`erp_table_th ${keyForViewUpdate === "issue" || (keyForViewUpdate === "view" && chk_Issue_status === "MI") ? 'display' : 'd-none'}`} style={{ textAlign: 'center' }}>Godown Section Beans</th> */}
                        <th className={`erp_table_th`} style={{ textAlign: 'center' }}>Material Godown</th>
                        <th className={`erp_table_th `} style={{ textAlign: 'center' }}>Godown Section Beans</th>
                        <th className="erp_table_th">Issue Remark</th>
                        <th className="erp_table_th">Profit Center</th>
                        <th className="erp_table_th">Cost Center</th>
                        <th className="erp_table_th">Routing Code</th>
                        <th className={`erp_table_th`} style={{ paddingRight: '40px' }} >Issue Item Status</th>
                        <th className={`erp_table_th `} style={{ textAlign: 'center', paddingRight: "190px" }}>Indent No</th>
                        <th className="erp_table_th"> {deptStockQty}</th>
                        <th className="erp_table_th">Rejected Quantity</th>
                        <th className="erp_table_th">Stock Weight</th>
                        <th className="erp_table_th">{deptStockWeight}</th>
                        <th className="erp_table_th">Requisition Weight</th>
                        <th className="erp_table_th">Approved Weight</th>
                        <th className="erp_table_th">Rejected Weight</th>
                        <th className={`erp_table_th ${keyForViewUpdate === "issue" ? 'display' : 'd-none'}`}>Previous Issue Weight</th>
                        <th className="erp_table_th">Issue Weight</th>
                        <th className="erp_table_th">Std Weight</th>
                        <th className="erp_table_th">Lead Days</th>
                    </tr>
                </thead>
                <tbody>
                    {materialIssueDetailsData.map((issueItem, Index) =>
                        <tr rowindex={Index} className="sticky-column">
                            <td className={`erp_table_td  ${keyForViewUpdate === '' || keyForViewUpdate === 'update' ? 'display' : 'd-none'}`}><div style={{ display: "flex" }}>
                                <Tooltip title="Delete" placement="top">
                                    <MDTypography className="erp-view-btn">
                                        <MdDelete className="erp-delete-btn" id={`delete_btn_${Index}`} onClick={() => removeMaterial(Index, setMaterialIssueDetailsData)} />
                                    </MDTypography>
                                </Tooltip>
                            </div>
                            </td>
                            <td className="erp_table_td text-end sticky-column">{Index + 1}</td>
                            <td className={`erp_table_td sticky-column`}>{issueItem.product_material_code}</td>
                            <td className={`erp_table_td sticky-column`}>{issueItem.product_material_name}</td>
                            <td className={`erp_table_td sticky-column`}>{issueItem.product_material_unit_name}</td>

                            <td className={`erp_table_td text-end sticky-column`}>{issueItem.product_material_stock_quantity}</td>
                            <td className={`erp_table_td text-end sticky-column`}>{issueItem.reserve_quantity}</td>

                            <td className="erp_table_td text-end">
                                {keyForViewUpdate === '' || keyForViewUpdate === 'update' ?
                                    <input type="text" id={`product_material_indent_quantity_${issueItem.product_material_id}`} className="remove_input_zero erp_input_field text-end remove0 mb-0" max_indent_quantity={issueItem.max_indent_quantity}
                                        value={issueItem.product_material_indent_quantity} onChange={(e) => { handleIssueDetailsInputChange(issueItem, e, Index); }} Headers='product_material_indent_quantity' />
                                    :
                                    issueItem.product_material_indent_quantity
                                }
                            </td>
                            <td className="erp_table_td text-end">
                                {keyForViewUpdate === 'approve' ?
                                    <input type="text" id={`product_material_approved_quantity_${issueItem.product_material_id}`} className="remove_input_zero erp_input_field text-end remove0 mb-0 approveField"
                                        value={issueItem.product_material_approved_quantity} onChange={(e) => { handleIssueDetailsInputChange(issueItem, e, Index); }} Headers='product_material_approved_quantity' />
                                    :
                                    issueItem.product_material_approved_quantity
                                }
                            </td>
                            <td className={`erp_table_td text-end ${keyForViewUpdate === "issue" ? 'display' : 'd-none'}`}>{issueItem.prev_issue_quantity}</td>
                            <td className="erp_table_td text-end">
                                {keyForViewUpdate === 'issue' ?
                                    <input type="text" id={`product_material_issue_quantity_${issueItem.product_material_id}`} className="remove_input_zero erp_input_field text-end remove0 mb-0 issueField"
                                        value={issueItem.product_material_issue_quantity} onChange={(e) => { handleIssueDetailsInputChange(issueItem, e, Index); }} Headers='product_material_issue_quantity' />
                                    :
                                    issueItem.product_material_issue_quantity
                                }
                            </td>
                            {/* {keyForViewUpdate === "issue" || (keyForViewUpdate === "view" && chk_Issue_status === "MI") ? */}
                            <td className={`erp_table_td `}>
                                {issueItem.godown_name}
                            </td>
                            {/* : null
                            } */}
                            {/* {keyForViewUpdate === "issue" || (keyForViewUpdate === "view" && chk_Issue_status === "MI") ? */}
                            <td className={`erp_table_td`}>
                                {issueItem.godown_section_beans_name}
                            </td>
                            {/* :
                                null
                            } */}
                            <td className="erp_table_td">
                                {keyForViewUpdate === 'issue' ?
                                    <input type="text" id={`issue_remark_${issueItem.product_material_id}`} className="erp_input_field mb-0 issueField"
                                        value={issueItem.issue_remark} onChange={(e) => { handleIssueDetailsInputChange(issueItem, e, Index); }} Headers='issue_remark' />
                                    :
                                    issueItem.issue_remark
                                }
                            </td>
                            <td className="erp_table_td wide-td" >

                                {/* Use react-select for searchable select box */}
                                <Select
                                    id={`profit_center_id_${issueItem.product_material_id}`}
                                    value={profit_centerOptions.find(option => option.value === parseInt(issueItem.profit_center_id))}
                                    onChange={(selectedOption) => handleIssueDetailsInputChange(issueItem, selectedOption, Index, 'profit_center')}
                                    options={profit_centerOptions}
                                    isDisabled={keyForViewUpdate !== "" && keyForViewUpdate !== "update" && keyForViewUpdate !== "issue"}
                                    className="form-search-custom"
                                    classNamePrefix="custom-select" // Add custom prefix for class names
                                    styles={{
                                        option: (provided, state) => ({
                                            ...provided,
                                            fontSize: '12px' // Adjust the font size as per your requirement
                                        }),
                                        singleValue: (provided, state) => ({
                                            ...provided,
                                            fontSize: '12px' // Adjust the font size as per your requirement
                                        }),
                                        input: (provided, state) => ({
                                            ...provided,
                                            fontSize: '12px' // Adjust the font size as per your requirement
                                        }),
                                        menu: (provided, state) => ({
                                            ...provided,
                                            maxHeight: '150px', // Adjust height for scrollbar to appear
                                            overflowY: 'auto'   // Enable vertical scrollbar
                                        }),
                                        menuList: (provided, state) => ({
                                            ...provided,
                                            maxHeight: '150px', // Limit the menu list height
                                            overflowY: 'auto',  // Enable vertical scrolling
                                            paddingRight: '10px', // Ensures padding space for scrollbar
                                        })
                                    }}
                                />
                            </td>

                            <td className="erp_table_td wide-td" >
                                {/* Use react-select for searchable select box */}
                                <Select
                                    id={`cost_center_id_${issueItem.product_material_id}`}
                                    value={cost_centerOptions.find(option => option.value === issueItem.cost_center_id)}
                                    onChange={(selectedOption) => handleIssueDetailsInputChange(issueItem, selectedOption, Index, 'cost_center')}
                                    options={cost_centerOptions}
                                    isDisabled={keyForViewUpdate !== "" && keyForViewUpdate !== "update" && keyForViewUpdate !== "issue"}
                                    className="form-search-custom"
                                    classNamePrefix="custom-select" // Add custom prefix for class names
                                    styles={{
                                        option: (provided, state) => ({
                                            ...provided,
                                            fontSize: '12px' // Adjust the font size as per your requirement
                                        }),
                                        singleValue: (provided, state) => ({
                                            ...provided,
                                            fontSize: '12px' // Adjust the font size as per your requirement
                                        }),
                                        input: (provided, state) => ({
                                            ...provided,
                                            fontSize: '12px' // Adjust the font size as per your requirement
                                        })
                                    }}
                                />
                            </td>
                            <td className="erp_table_td">{issueItem.routing_code}</td>
                            <td className="erp_table_td">
                                <select id={`issue_item_status_${issueItem.product_material_id}`} disabled value={issueItem.issue_item_status}
                                    className="form-select form-select-sm mb-1" Headers='issue_item_status' onChange={(e) => { handleIssueDetailsInputChange(issueItem, e, Index); }}>
                                    <option value="P">Pending</option>
                                    <option value="A">Approved</option>
                                    <option value="I">Partial Issue</option>
                                    <option value="MI">Material Issued</option>
                                    <option value="C">Completed</option>
                                    <option value="AC">Accepted</option>
                                </select>
                            </td>
                            <td className="erp_table_td ">
                                {keyForViewUpdate === '' || keyForViewUpdate === 'update' ?
                                    <select id={`indent_no_id_${issueItem.product_material_id}`} className="form-select form-select-sm" value={issueItem.indent_no} product_material_id={`${issueItem.product_material_id}`} Headers='indent_no'
                                        onChange={(e) => { handleIssueDetailsInputChange(issueItem, e, Index); }} >
                                        <option value="">Select</option>
                                        {issueItem.indentNoList.length !== 0 ?
                                            <>
                                                {issueItem.indentNoList?.map(item => (
                                                    <option value={item.indent_no}>{item.indent_no}</option>

                                                ))}
                                            </>
                                            : ''
                                        }
                                    </select> :
                                    issueItem.indent_no
                                }
                            </td>
                            <td className={`erp_table_td text-end sticky-column`}>{issueItem.accepted_qty}</td>
                            <td className="erp_table_td text-end">{issueItem.product_material_rejected_quantity}</td>
                            <td className="erp_table_td text-end">{issueItem.product_material_stock_weight}</td>
                            <td className="erp_table_td text-end">{issueItem.accepted_weight}</td>
                            <td className="erp_table_td text-end">{issueItem.product_material_indent_weight}</td>
                            <td className="erp_table_td text-end">{issueItem.product_material_approved_weight}</td>
                            <td className="erp_table_td text-end">{issueItem.product_material_rejected_weight}</td>
                            <td className={`erp_table_td text-end ${keyForViewUpdate === "issue" ? 'display' : 'd-none'}`}>{issueItem.prev_issue_weight}</td>
                            <td className={`erp_table_td text-end `}>{issueItem.product_material_issue_weight}</td>
                            <td className="erp_table_td text-end">{issueItem.product_std_weight}</td>
                            <td className="erp_table_td text-end">{issueItem.product_lead_time}</td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </>
    }, [materialIssueDetailsData, keyForViewUpdate])

    //---------------------------------------------------- Accordian data load starts --------------------------------------------------------------------------------
    const FnLoadAccordionData = async (eventKey) => {

        switch (eventKey) {

            case 'documentList':
                await showDocumentRecords();
                break;
            default:
                break;
        }
        if (keyForViewUpdate === "view" || keyForViewUpdate === "approve" || keyForViewUpdate === "delete") {
            $('input[type="checkbox"]').prop('disabled', true);
        }
    }
    //---------------------------------------------------- Accordian data load Ends --------------------------------------------------------------------------------
    // Fn for get document list
    const showDocumentRecords = async () => {
        try {
            if (COMPANY_ID !== null) {
                const res = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/api/Documents/FnShowParticularRecord?document_group=${docGroup}&group_id=${txt_material_issue_no !== 0 ? txt_material_issue_no.replaceAll('/', '_') : null}`
                );
                const resp = await res.json();
                if (resp.content.length > 0) {
                    setDocumentData(resp.content);
                }
            }

        } catch (error) {
            console.log("error: ", error)
        }
    }

    // Fn for get document table
    const renderDocumentTable = useMemo(() => {
        return <>
            <Table className="erp_table " id='document-table' responsive bordered striped>
                <thead className="erp_table_head">
                    <tr>
                        <th className="erp_table_th">Group Id</th>
                        <th className="erp_table_th">Document Group</th>
                        <th className="erp_table_th">Document Name</th>
                        <th className="erp_table_th">File Name</th>
                        <th className="erp_table_th">Registration No</th>
                        <th className="erp_table_th">Registration Date</th>
                        <th className="erp_table_th">Renewal Date</th>
                        <th className="erp_table_th">Document Path</th>
                        <th className="erp_table_th">Remark</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        docData.map((docItem, Index) =>
                            <tr rowIndex={Index}>
                                <td className="erp_table_td"> {docItem.group_id} </td>
                                <td className="erp_table_td">{docItem.document_group}</td>
                                <td className="erp_table_td">{docItem.document_name}</td>
                                <td className="erp_table_td">
                                    <MDTypography
                                        component="label"
                                        className="erp-form-label"
                                        variant="button"
                                        id="logoFile"
                                        fontWeight="regular"
                                        color="info"
                                        onClick={() => fetchDocument(docItem)}
                                        style={{ cursor: 'pointer', color: '#0000FF' }} // Change cursor to pointer to indicate clickable
                                        onMouseEnter={(e) => e.target.style.color = '#0000FF'} // Change color on hover
                                        onMouseLeave={(e) => e.target.style.color = '#0000FF'} // Restore original color when not ho
                                    >
                                        {docItem.file_name}
                                    </MDTypography>
                                </td>
                                <td className="erp_table_td ">{docItem.document_registration_no}</td>
                                <td className="erp_table_td ">{validateNumberDateInput.current.formatDateToDDMMYYYY(docItem.document_registration_date)}</td>
                                <td className="erp_table_td ">{validateNumberDateInput.current.formatDateToDDMMYYYY(docItem.document_renewal_date)}</td>
                                <td className="erp_table_td ">{docItem.document_path}</td>
                                <td className="erp_table_td ">{docItem.remark}</td>
                                {/* <td className="erp_table_td ">
                                    <MDTypography component="label" className="erp-form-label" variant="button" id="logoFile" fontWeight="regular" color="info" >
                                        <Link to="#" onClick={() => { fetchDocument(docItem) }}>
                                            {docItem.file_name}
                                        </Link></MDTypography>
                                </td> */}

                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </>
    }, [docData]);



    // Fn for get document download
    const fetchDocument = async (docItem) => {
        try {
            const formData = new FormData()
            const data = {
                document_group: docItem.documentGroup,
                group_id: docItem.group_id,
                document_path: docItem.document_path
            }
            formData.append(`getFile`, JSON.stringify(data))
            const requestOptions = {
                method: 'POST',
                body: formData
            };

            const getDocApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/Documents/FnGetDocument`, requestOptions)
            const blob = await getDocApiCall.blob()

            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${docItem.file_name}`,);
            document.body.appendChild(link);

            link.click();
            link.parentNode.removeChild(link);


        } catch (error) {
            console.log("error: ", error)
        }
    }

    // ---------------------------------------------------------------------------------------------------------------------------
    //Purchase Requisition Print Start
    const requisitionIssueContent = {
        company: {
            company: '',
            company_branch: '',
        },
        // customerdetails: {
        //     name: '',
        //     address: '',
        //     state: '',
        //     GSTIN: '',
        //     pan_no: '',
        //     contact: '',
        // },
        // customerDetails: {
        //     customer: '',
        //     address: '',
        //     state: '',
        //     GSTIN: '',
        //     pan_no: '',
        //     contact: '',
        //     email: ''
        // },
        issuedetails: {
            issue_no: '',
            issue_date: '',
            issue_version: '',
            issue_type: '',
        },
        header: {
            issue_group_type: '',
            department_name: '',
            sub_department_name: '',
            requisition_by_name: '',
            issued_by_name: '',
            customer_name: '',
            customer_order_no: '',
            customer_order_date: '',
            approved_by_name: '',
            approved_date: '',
            requisition_date: '',
            remark: '',
        },
        footer: {
            requisition_by_name: '',
            issued_by_name: '',
            approved_by_name: '',



            sumofStockQuantity: '',
            sumIndentQuantity: '',
            sumofApprovedQuantity: '',
            sumofRejectedQuantity: '',
            sumofIssuedQuantity: '',

        },
        items: [],
    };
    const printIssueSlip = async (openPrintModal) => {


        // Define Issue within the printIssueSlip function
        const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/StIndentIssueMaster/FnShowAllDetailsAndMastermodelRecords/${idList.issue_version}/${idList.financial_year}/${COMPANY_ID}?issue_no=${idList.issue_no}`)
        const response = await apiCall.json();
        console.log("Response : ", response);
        let IndentIssueMasterRecord = response.IndentIssueMasterRecord;
        let IndentIssueDetailsRecords = response.IndentIssueDetailsRecords;

        //Issue details
        requisitionIssueContent.issuedetails.issue_no = IndentIssueMasterRecord.issue_no
        requisitionIssueContent.issuedetails.issue_date = validateNumberDateInput.current.formatDateToDDMMYYYY(IndentIssueMasterRecord.issue_date)
        requisitionIssueContent.issuedetails.issue_version = IndentIssueMasterRecord.issue_version
        requisitionIssueContent.issuedetails.issue_type = $('#cmb_material_type').find(":selected").text()

        //Company details
        requisitionIssueContent.company.company = IndentIssueDetailsRecords[0].company_name
        requisitionIssueContent.company.company_branch = IndentIssueDetailsRecords[0].company_branch_name

        // //Customer details
        // requisitionIssueContent.customerDetails.customer = IndentIssueMasterRecord.customer_name
        // requisitionIssueContent.customerDetails.address = IndentIssueMasterRecord.cust_branch_address1
        // requisitionIssueContent.customerDetails.state = IndentIssueMasterRecord.customer_state_name
        // requisitionIssueContent.customerDetails.contact = IndentIssueMasterRecord.cust_branch_phone_no
        // requisitionIssueContent.customerDetails.email = IndentIssueMasterRecord.cust_branch_EmailId

        // header Details
        requisitionIssueContent.header.issue_group_type = IndentIssueMasterRecord.issue_group_type
        requisitionIssueContent.header.department_name = IndentIssueMasterRecord.department_name
        requisitionIssueContent.header.sub_department_name = IndentIssueMasterRecord.sub_department_name
        // requisitionIssueContent.header.cost_center_name = IndentIssueMasterRecord.cost_center_name
        requisitionIssueContent.header.approved_by_name = IndentIssueMasterRecord.approved_by_name
        requisitionIssueContent.header.approved_date = validateNumberDateInput.current.formatDateToDDMMYYYY(IndentIssueMasterRecord.approved_date)
        requisitionIssueContent.header.issued_by_name = IndentIssueMasterRecord.issued_by_name
        requisitionIssueContent.header.remark = IndentIssueMasterRecord.remark
        requisitionIssueContent.header.requisition_by_name = IndentIssueMasterRecord.requisition_by_name


        //footer details
        requisitionIssueContent.footer.requisition_by_name = IndentIssueMasterRecord.requisition_by_name
        requisitionIssueContent.header.requisition_date = validateNumberDateInput.current.formatDateToDDMMYYYY(IndentIssueMasterRecord.requisition_date)

        requisitionIssueContent.footer.approved_by_name = IndentIssueMasterRecord.approved_by_name
        requisitionIssueContent.footer.issued_by_name = IndentIssueMasterRecord.issued_by_name

        // let sumStockQuantity = 0;
        let sumIndentQuantity = 0;
        let sumofApprovedQuantity = 0;
        // let sumofRejectedQuantity = 0;
        let sumofIssuedQuantity = 0;

        for (let index = 0; index < IndentIssueDetailsRecords.length; index++) {
            const element = IndentIssueDetailsRecords[index];

            // Add product_material_stock_quantity to the sum
            sumIndentQuantity += element.product_material_indent_quantity;
            sumofApprovedQuantity += element.product_material_approved_quantity;
            sumofIssuedQuantity += element.product_material_issue_quantity;

            const detailsData = {
                sr_no: index + 1,
                product_material_code: element.product_material_code,
                product_material_name: element.product_material_name === undefined || element.product_material_name === null ? "" : element.product_material_name,
                material_tech_spect: element.product_rm_tech_spect === undefined || element.product_rm_tech_spect === null ? "" : element.product_rm_tech_spect,
                product_make_name: element.product_make_name === 'NA' || element.product_make_name === undefined || element.product_make_name === null ? "" : element.product_make_name,
                product_material_stock_quantity: element.product_material_stock_quantity,
                unit: element.product_material_unit_name,
                product_lead_time: element.product_lead_time,
                product_material_indent_quantity: element.product_material_indent_quantity,
                product_material_approved_quantity: element.product_material_approved_quantity,
                product_material_issue_quantity: element.product_material_issue_quantity,
                cost_center_name: element.cost_center_name
            }
            requisitionIssueContent.items.push(detailsData)
            console.log(detailsData);
        }

        // Assign calculated values to footer
        requisitionIssueContent.footer.sumIndentQuantity = sumIndentQuantity;
        requisitionIssueContent.footer.sumofApprovedQuantity = sumofApprovedQuantity;
        requisitionIssueContent.footer.sumofIssuedQuantity = sumofIssuedQuantity;

        // console.log('Sum of product_material_stock_quantity:', sumStockQuantity);
        console.log('Sum of sumIndentQuantity:', sumIndentQuantity);
        console.log('Sum of sumofApprovedQuantity:', sumofApprovedQuantity);
        // console.log('Sum of sumofRejectedQuantity:', sumofRejectedQuantity);
        console.log('Sum of sumofIssuedQuantity:', sumofIssuedQuantity);

        if (openPrintModal) {
            if (parseFloat(IndentIssueDetailsRecords.length) <= 10) {
                navigator('/Invoice', { state: { invoiceData: requisitionIssueContent, navigationLink: '/Transactions/TMaterialRequisition/FrmMaterialRequisitionEntry', invoiceType: 'IR', title: 'Requisition/Issue', idList: idList, keyForViewUpdate: keyForViewUpdate, requestfor: requestfor } });
            } else {
                navigator('/Invoice', { state: { invoiceData: requisitionIssueContent, navigationLink: '/Transactions/TMaterialRequisition/FrmMaterialRequisitionEntry', invoiceType: 'MI', title: 'Requisition/Issue', idList: idList, keyForViewUpdate: keyForViewUpdate, requestfor: requestfor } });
            }
        } else {
            // return the html code.
            return renderToString(<MaterialIssueSlip invoiceContent={requisitionIssueContent} />);
        }
    };

    //---------------------------------------------------------Print Functionality End----------------------------------------------

    const requestTypeHeading = () => {
        switch (requestfor) {
            case 'MR':
                return 'Material Requisition Slip';
            case 'MI':
                return 'Material Issue Slip';
        }
    }

    // ------------------------ Changes for the Searchable Combos. --------------------------
    let customerComboRef = useRef(null);
    let costCenterComboRef = useRef(null);
    let requesitionByComboRef = useRef(null);
    let indentNosComboRef = useRef(null);


    return (
        <>
            <ComboBox ref={comboDataAPiCall} />
            <ExcelExport ref={exlsExp} />
            <GenerateTAutoNo ref={generateAutoNoAPiCall} />
            <FrmValidations ref={validate} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />
            <DashboardLayout>
                <>
                    {isLoading ?
                        <div className="spinner-overlay"  >
                            <div className="spinner-container">
                                <CircularProgress color="primary" />
                                <span>Loading...</span>
                            </div>
                        </div> :
                        ''}

                    <div className='card p-1'>
                        <div className='card-header text-center py-0'>
                            <label className='erp-form-label-lg main_heding'> Material Issue{requestTypeHeading()} <span id="submain_heading">(Creation)</span></label>
                        </div>
                        <form id="material_issue_form_id">
                            <div className="row p-1">
                                {/* //first column */}
                                <div className="col-sm-6 erp_form_col_div">
                                    <div className='row'>
                                        <div className='col-sm-3 col-12'>
                                            <Form.Label className="erp-form-label">Issue Group Type<span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col-sm-9 col-12'>
                                            <select id="cmb_issue_group_type" className="form-select form-select-sm" value={cmb_issue_group_type} onChange={(e) => { setIssue_group_type(e.target.value); validateFields(); }}>
                                                <option value="">Select</option>
                                                {/* <option value="0">Add New Record+</option> */}
                                                {issueGroupTypeOptions.length !== 0 ? (
                                                    <>
                                                        {issueGroupTypeOptions.map(materialTypeId => (
                                                            <option key={materialTypeId.field_id} value={materialTypeId.property_value}>
                                                                {materialTypeId.property_name}
                                                            </option>
                                                        ))}
                                                    </>
                                                ) : null}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_issue_group_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-3 col-12'>
                                            <Form.Label className="erp-form-label">Material Issue Type<span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col-sm-9 col-12'>
                                            <select id="cmb_material_type" className="form-select form-select-sm" value={cmb_material_type} onChange={() => { comboOnChange('materialTypeId'); validateFields(); }}  >
                                                <option value="">Select</option>
                                                <option value="0">Add New Record+</option>
                                                {materialtypeOptions.length !== 0 ? (
                                                    <>
                                                        {materialtypeOptions.map(materialTypeId => (
                                                            <option key={materialTypeId.field_id} value={materialTypeId.field_id}>
                                                                {materialTypeId.field_name}
                                                            </option>
                                                        ))}
                                                    </>
                                                ) : null}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_material_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-sm-3 col-12">
                                            <Form.Label className="erp-form-label">Material Issue No, Version & Date <span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className="col-sm-9 col-12">
                                            <div className="row">
                                                <div className="col-12 col-md-6 pe-md-0">
                                                    <Form.Control type="text" id="txt_material_issue_no" className="erp_input_field" value={txt_material_issue_no} disabled />
                                                    <MDTypography variant="button" id="error_txt_material_issue_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                </div>
                                                <div className="col-12 col-md-2 pt-md-0 pt-3 pe-md-0">
                                                    <Form.Control type="text" id='txt_material_issue_version' className="erp_input_field text-end" value={txt_material_issue_version} disabled />
                                                    <MDTypography variant="button" id="error_txt_material_issue_version" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                                </div>
                                                <div className="col-12 col-md-4 pt-md-0 pt-3">
                                                    <Form.Control type="date" id='dt_material_issue_date' className="erp_input_field" value={dt_material_issue_date} onChange={e => { setmaterialissueDate(e.target.value); validateFields(); }} min={currentDate} />
                                                    <MDTypography variant="button" id="error_dt_material_issue_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Issue Source <span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className="col">
                                            <div className="erp_form_radio">
                                                <div className="fCheck">
                                                    <Form.Check
                                                        className="erp_radio_button"
                                                        label="Internal"
                                                        type="radio"
                                                        lbl="Internal"
                                                        value="I"
                                                        name="chk_issue_source"
                                                        checked={chk_issue_source === "I"} disabled
                                                        onChange={() => { setIssueSource("I"); comboOnChange('Internal_source') }}
                                                    />
                                                </div>
                                                <div className="sCheck">
                                                    <Form.Check
                                                        className="erp_radio_button"
                                                        label="So Based"
                                                        type="radio"
                                                        lbl="So Based"
                                                        value="S"
                                                        name="chk_issue_source" disabled
                                                        checked={chk_issue_source === "S"}
                                                        onChange={() => {
                                                            setIssueSource("S"); comboOnChange('SO_source')
                                                        }}
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className={`row`}>
                                        <div className='col-sm-3 col-12'>
                                            <Form.Label className="erp-form-label">Customer {chk_issue_source === 'S' ? <span className="required">*</span> : null}</Form.Label>
                                        </div>
                                        <div className={`col-sm-${keyForViewUpdate === 'view' ? '9' : '8'} col-10 mb-1`}>
                                            <Select ref={customerComboRef}
                                                options={customerOptions}
                                                isDisabled={['view', 'approve', 'update'].includes(keyForViewUpdate) || chk_issue_source !== 'S'}
                                                inputId="cmb_customer" // Provide the ID for the input box 
                                                value={customerOptions.find(option => option.value === cmb_customer)}
                                                onChange={(selectedOpt) => {
                                                    setCustomerId(selectedOpt.value);
                                                    customerComboRef.current = selectedOpt;
                                                    comboOnChange('customer');
                                                    validateFields();
                                                }}
                                                placeholder="Search for a customer..."
                                                className="form-search-custom"
                                                classNamePrefix="custom-select" // Add custom prefix for class names
                                                {...(chk_issue_source !== 'S' && { optional: 'optional' })}
                                                styles={{
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    })
                                                }}
                                            />
                                            <MDTypography variant="button" id="error_cmb_customer" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                        <div className={`col-sm-1 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'} col-2 refreshIconCol`}>
                                            <Tooltip title="Refresh" placement="top">
                                                <MDTypography className={`${keyForViewUpdate === 'view' || keyForViewUpdate === 'delete' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`} >
                                                    <MdRefresh style={{ color: 'black' }} onClick={() => { if (keyForViewUpdate !== 'approve' || keyForViewUpdate !== 'view') { refreshData('get_customer') } }} />
                                                </MDTypography>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-3 col-12'>
                                            <Form.Label className="erp-form-label">Customer Order No & Order on{chk_issue_source === 'S' ? <span className="required">*</span> : null}</Form.Label>
                                        </div>
                                        <div className="col-5 pe-md-0">
                                            <select id="cmb_customer_order_no" className="form-select form-select-sm" value={cmb_customer_order_no} onChange={(e) => { setCustomerOrderNo(e.target.value); comboOnChange('CustomerOrder'); validateFields() }} {...(chk_issue_source !== 'S' && { optional: 'optional' })} disabled={chk_issue_source !== 'S'}>
                                                <option value="">Select</option>
                                                {customerOrdOpts.length !== 0 ?
                                                    <>
                                                        {customerOrdOpts?.map(order => (
                                                            <option value={order.customer_order_no} ord_date={order.customer_order_Date}  >{order.customer_order_no}</option>
                                                        ))}
                                                    </>
                                                    : ''
                                                }
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_customer_order_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                        <div className="col-sm-4">
                                            <Form.Control type="date" id='customer_order_date' className="erp_input_field" value={customer_order_date} optional='optional' disabled />
                                            <MDTypography variant="button" id="error_customer_order_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Schedule Date<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control type="date" id='txt_schedule_date' className="erp_input_field" value={txt_schedule_date} onChange={(e) => { setScheduleDate(e.target.value); validateFields(); }} min={currentDate} />
                                            <MDTypography variant="button" id="error_txt_schedule_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className={`row ${(keyForViewUpdate === "view" && chk_Issue_status !== "P") || chk_Issue_status !== "P" ? 'display' : 'd-none'}`}>
                                        <div className='col-sm-3 col-12'>
                                            <Form.Label className="erp-form-label">Approved By & Date</Form.Label>
                                        </div>
                                        <div className={`col-sm-5 col-12`}>
                                            <select id="cmb_approved_by_id" className="form-select form-select-sm " value={cmb_approved_by_id} optional='optional'
                                                onChange={(e) => { validateFields(); setApprovedById(e.target.value) }} disabled>
                                                <option value="">Select</option>
                                                {employeeOptions.length !== 0 ? (
                                                    <>
                                                        {employeeOptions?.map(acceptedById => (
                                                            <option value={acceptedById.field_id}>{acceptedById.field_name}</option>
                                                        ))}
                                                    </>
                                                ) : null}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_approved_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                        <div className={`col-sm-4 col-12`}>
                                            <Form.Control type="date" id='dt_approved_date' className="erp_input_field" value={dt_approved_date} onChange={e => { setApproved_date(e.target.value); validateFields(); }} min={currentDate} optional='optional' disabled={keyForViewUpdate === 'approve' ? false : true} />
                                            <MDTypography variant="button" id="error_dt_approved_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    {/* <div className={`row ${(keyForViewUpdate === "view" && chk_Issue_status !== "P") || chk_Issue_status !== "P" ? 'display' : 'd-none'}`}>
                                        <div className='col-sm-3 col-12'>
                                            <Form.Label className="erp-form-label">Approved Date</Form.Label>
                                        </div>
                                        <div className={`col-sm-${keyForViewUpdate === 'view' ? '9' : '8'} col-10`}>
                                            <Form.Control type="date" id='dt_approved_date' className="erp_input_field" value={dt_approved_date} onChange={e => { setApproved_date(e.target.value); validateFields(); }} min={currentDate} optional='optional' disabled ={keyForViewUpdate === 'approve' ? false : true}/>
                                            <MDTypography variant="button" id="error_dt_approved_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div> */}

                                </div>
                                {/* //second column */}
                                <div className="col-sm-6 erp_form_col_div">

                                    <div className='row'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Department <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_department" className="form-select form-select-sm" value={cmb_department} onChange={() => { comboOnChange('departmentId'); validateFields(); }} >
                                                <option value="">Select</option>
                                                <option value="0">Add New Record+</option>
                                                {
                                                    departmentIdOPtions.length !== 0 ? (
                                                        <>
                                                            {departmentIdOPtions?.map(departmentId => (
                                                                <option value={departmentId.field_id}>{departmentId.field_name}</option>
                                                            ))}
                                                        </>
                                                    ) : null
                                                }
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_department" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-3'>
                                            <Form.Label className="erp-form-label">Sub Department <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="cmb_sub_department" className="form-select form-select-sm" value={cmb_sub_department} onChange={() => { comboOnChange('subDepartment'); validateFields(); }}>
                                                <option value="">Select</option>
                                                <option value="0">Add New Record+</option>
                                                {subDepartmentOPtions?.map(departmentId => (
                                                    <option value={departmentId.field_id}>{departmentId.field_name}</option>
                                                ))}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_sub_department" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    {/* <div className={`row `}>
                                        <div className='col-sm-3 col-12'>
                                            <Form.Label className="erp-form-label">Indent No</Form.Label>
                                        </div>
                                        <div className={`col-sm-${keyForViewUpdate === 'view' ? '9' : '8'} col-10`}>
                                            {keyForViewUpdate === '' ?
                                                <>
                                                    <Select ref={indentNosComboRef}
                                                        options={indentNOsOptions}
                                                        isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                                                        inputId="cmb_indent_no"
                                                        value={indentNOsOptions.find(option => option.value === cmb_indent_no) || null}
                                                        onChange={(selectedOpt) => {
                                                            setIndentNo(selectedOpt.value);
                                                            indentNosComboRef.current = selectedOpt;
                                                            comboOnChange('indent_no');
                                                            validateFields();
                                                        }}
                                                        placeholder="Search for Indent No..."
                                                        className="form-search-custom"
                                                        classNamePrefix="custom-select" // Add custom prefix for class names
                                                        styles={{
                                                            option: (provided, state) => ({
                                                                ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                            }),
                                                            singleValue: (provided, state) => ({
                                                                ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                            }),
                                                            input: (provided, state) => ({
                                                                ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                            })
                                                        }}
                                                    />
                                                    <MDTypography variant="button" id="error_cmb_indent_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </>
                                                :
                                                <Form.Control type="text" id='cmb_indent_no' className="erp_input_field" value={cmb_indent_no} optional='optional' disabled />
                                            }
                                        </div>
                                    </div> */}
                                    <div className='row'>
                                        <div className='col-sm-3 col-12'>
                                            <Form.Label className="erp-form-label">Requisition By & Date<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className={`col-sm-5 col-10`}>
                                            <Select ref={requesitionByComboRef}
                                                options={requisitionOptions}
                                                isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                                                inputId="cmb_requisition_by" // Provide the ID for the input box
                                                value={requisitionOptions.find(option => option.value === cmb_requisition_by) || null}
                                                onChange={(selectedOpt) => {
                                                    setRequisitionBy(selectedOpt.value);
                                                    requesitionByComboRef.current = selectedOpt;
                                                    comboOnChange('requisition_by');
                                                    validateFields();
                                                }}
                                                placeholder="Search for Reuesition by..."
                                                className="form-search-custom"
                                                classNamePrefix="custom-select" // Add custom prefix for class names
                                                styles={{
                                                    option: (provided, state) => ({
                                                        ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                    })
                                                }}
                                            />
                                            <MDTypography variant="button" id="error_cmb_requisition_by" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                        <div className={`col-sm-4 col-10`}>
                                            <Form.Control type="date" id='dt_requisition_date' className="erp_input_field" value={dt_requisition_date} onChange={e => { setRequisitionDate(e.target.value); validateFields(); }} />
                                            <MDTypography variant="button" id="error_dt_requisition_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                    {/* <div className='row'>
                                        <div className='col-sm-3 col-12'>
                                            <Form.Label className="erp-form-label">Requisition Date</Form.Label>
                                        </div>
                                        <div className={`col-sm-${keyForViewUpdate === 'view' ? '9' : '8'} col-10`}>
                                            <Form.Control type="date" id='dt_requisition_date' className="erp_input_field" value={dt_requisition_date} onChange={e => { setRequisitionDate(e.target.value); validateFields(); }} />
                                            <MDTypography variant="button" id="error_dt_requisition_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div> */}
                                    {chk_Issue_status === "AC" ?
                                        <>
                                            <div className='row'>
                                                <div className='col-sm-3 col-12'>
                                                    <Form.Label className="erp-form-label">Accepted By  </Form.Label>
                                                </div>
                                                <div className={`col-sm-${keyForViewUpdate === 'view' ? '9' : '8'} col-10`}>
                                                    <select id="cmb_accepted_by" className="form-select form-select-sm " value={cmb_accepted_by} optional="optional"
                                                        onChange={() => {
                                                            //  comboOnChange('acceptedById'); 
                                                            validateFields();
                                                        }} disabled>
                                                        <option value="">Select</option>
                                                        {employeeOptions.length !== 0 ? (
                                                            <>
                                                                {employeeOptions?.map(acceptedById => (
                                                                    <option value={acceptedById.field_id}>{acceptedById.field_name}</option>
                                                                ))}
                                                            </>
                                                        ) : null}
                                                    </select>
                                                    <MDTypography variant="button" id="error_cmb_accepted_by" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                            <div className={`row`}>
                                                <div className='col-sm-3 col-12'>
                                                    <Form.Label className="erp-form-label">Accepted Date</Form.Label>
                                                </div>
                                                <div className={`col-sm-${keyForViewUpdate === 'view' ? '9' : '8'} col-10`}>
                                                    <Form.Control type="date" id='dt_accepted_date' className="erp_input_field" value={dt_accepted_date} onChange={e => { setAcceptedDate(e.target.value); validateFields(); }} min={currentDate} optional='optional' />
                                                    <MDTypography variant="button" id="error_dt_accepted_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                        </>
                                        : null
                                    }

                                    <div className={`row ${(keyForViewUpdate === "issue") || (keyForViewUpdate === "view" && (chk_Issue_status.includes("MI", "I", "AC"))) ? 'display' : 'd-none'}`}>
                                        <div className='col-sm-3 col-12'>
                                            <Form.Label className="erp-form-label">Issued By <span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col">
                                            {/* <Form.Control type="text" id="txt_issued_by" className="erp_input_field" value={txt_issued_by} disabled  optional="optional"/> */}
                                            <select id="txt_issued_by" className="form-select form-select-sm " value={txt_issued_by} optional="optional"
                                                onChange={(e) => { validateFields(); setissued_by(e.target.value) }} disabled>
                                                <option value="">Select</option>
                                                {/* <option value="0">Add New Record+</option> */}
                                                {issueByOptions.length !== 0 ? (
                                                    <>
                                                        {issueByOptions?.map(approvedById => (
                                                            <option value={approvedById.field_id}>{approvedById.field_name}</option>
                                                        ))}
                                                    </>
                                                ) : null}
                                            </select>
                                            <MDTypography variant="button" id="error_txt_issued_by" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Issue Status </Form.Label>
                                        </div>
                                        <div className='col'>
                                            <select id="chk_Issue_status " className="form-select form-select-sm" value={chk_Issue_status} onChange={(e) => { setIssueStatus(e.target.value); }} disabled >
                                                <option value="P">Pending</option>
                                                <option value="A">Approved</option>
                                                <option value="C">Completed</option>
                                                <option value="I">Partial Issue</option>
                                                <option value="AC">Accepted</option>
                                                <option value="MI">Material Issue</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Remark</Form.Label>
                                        </div>
                                        <div className="col">
                                            <Form.Control as="textarea" id='txt_remark' className="erp_input_field" value={txt_remark} onChange={e => setRemark(e.target.value)} optional='optional' maxLength={1000} />
                                            <MDTypography variant="button" id="error_txt_remark" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Form.Label className="erp-form-label">Active</Form.Label>
                                        </div>
                                        <div className="col">

                                            <div className="erp_form_radio">
                                                <div className="fCheck"> <Form.Check className="erp_radio_button" label="Yes" lbl="Yes" type="radio" value="true" name="chk_isactive" checked={chk_isactive === true} onClick={() => { setIsActive(true); }} /> </div>
                                                <div className="sCheck"> <Form.Check className="erp_radio_button" label="No" lbl="No" type="radio" value="false" name="chk_isactive" checked={chk_isactive === false} onClick={() => { setIsActive(false); }} /> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <hr />

                    <Accordion defaultActiveKey="0" activeKey={openIssueDetailsAccord ? '0' : null}>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header onClick={(e) => setOpenIssueDetailsAccord(!openIssueDetailsAccord)} className="erp-form-label-md"> Material Issue Details</Accordion.Header>
                            <Accordion.Body>
                                <div className="row">
                                    <div className="col-md-4 col-12">
                                        <div className={`row ${(keyForViewUpdate === '' || keyForViewUpdate === 'update') && cmb_indent_no === "" ? 'display' : 'd-none'}`}>
                                            <div className="col-md-8 col-12">
                                                <SearchItemFilter getSearchData={handleSearchMaterials} formIdForValidate="material_issue_form_id" requiredCols={requiredCols} godownId={'2'} isMainGodown={true} product_type_id={cmb_material_type} />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="erp-Mt-10">
                                    {materialIssueDetailsData.length !== 0 ?
                                        <>
                                            {renderDetailsTable}
                                        </> :
                                        <div className='row  text-center'>
                                            <div className="col-12 ">
                                                <span className="erp_validation text-center" fontWeight="regular" color="error"> No Records Found... </span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion >

                    {keyForViewUpdate === 'view' ?
                        <>
                            <hr />
                            <Accordion defaultActiveKey="0" onSelect={FnLoadAccordionData}>
                                <Accordion.Item eventKey="documentList">
                                    <Accordion.Header className="erp-form-label-md p-0">Document List</Accordion.Header>
                                    <Accordion.Body>
                                        {docData.length !== 0 ? (
                                            renderDocumentTable
                                        ) : (
                                            <div className='row text-center'>
                                                <div className="col-12">
                                                    <span className="erp_validation text-center" fontWeight="regular" color="error">
                                                        No Records Found...
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </>
                        : null
                    }
                </>

                <div className="text-center mb-5">
                    <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => moveToListing()}>Back</MDButton>
                    <MDButton type="button" id="save-btn" className="erp-gb-button ms-2" variant="button" onClick={() => { addIssueslip() }}
                        fontWeight="regular">Save</MDButton>
                    <MDButton type="button" className="erp-gb-button erp_MLeft_btn" variant="button" id='clearbutton-id' onClick={FnClearFormFields} fontWeight="regular" disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'update' || keyForViewUpdate === 'approve' ? 'disabled' : ''}>Clear</MDButton>
                    <MDButton type="button" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'delete' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" onClick={() => setShow(true)}>Delete</MDButton>
                    <MDButton type="button" variant="button" onClick={() => { addIssueslip('MI') }} id="issue-btn"
                        className={`erp-gb-button erp_MLeft_btn ${keyForViewUpdate === 'issue' ? 'display' : 'd-none'}`} fontWeight="regular" >Issue</MDButton>
                    <MDButton className={`erp-gb-button erp_MLeft_btn ${keyForViewUpdate !== '' && keyForViewUpdate !== 'view' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" id='viewdocument-id' onClick={viewDocumentForm} >Upload Document</MDButton>&nbsp;

                    <MDButton className={`erp-gb-button erp_MLeft_btn ${keyForViewUpdate === 'view' ? chk_Issue_status !== 'P' ? 'display' : 'd-none' : 'd-none'}`} variant="button" fontWeight="regular" id="printbtn_id"
                        onClick={() => { printIssueSlip(true); }} disabled={chk_Issue_status !== "P" ? '' : 'disabled'} >Print<FiDownload className="erp-download-icon-btn" /></MDButton>
                </div >

                {/* Document modal */}
                <Modal size="lg" className='erp_document_Form' show={showDocumentForm} onHide={handleCloseDocumentForm} backdrop="static" keyboard={false} centered>
                    <Modal.Header>
                        <Modal.Title className='erp_modal_title'>Document Form</Modal.Title>
                        <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseDocumentForm}></button></span>
                    </Modal.Header>
                    <Modal.Body>
                        <DocumentF group_id={txt_material_issue_no !== 0 ? txt_material_issue_no.replaceAll('/', '_') : null} document_group={docGroup} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" className="btn erp-gb-button" onClick={handleCloseDocumentForm}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
                <ConfirmationModal close={() => closeConfirmationModal('Exit')} confirmation={() => closeConfirmationModal('Export&Exit')} show={[showConfirmationModal, succMsg, modalOrderDetails, messageForConfirmation]} />
                <SuccessModal handleCloseSuccessModal={() => closeConfirmationModal('Exit')} show={[showSuccessMsgModal, succMsg, modalOrderDetails]} />
                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg, modalOrderDetails]} />

                <Modal size="lg" show={showAddRecIndentIssueModal} onHide={closeRecordAddIndentIssueModal} backdrop="static" keyboard={false} centered >
                    <Modal.Body className='erp_city_modal_body'>
                        <div className='row'>
                            <div className='col-12 align-self-end'><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={closeRecordAddIndentIssueModal}></button></div>
                        </div>
                        {displayRecordIndentIssueComponent()}

                    </Modal.Body>
                </Modal >
                {/* --------------------------------------------------------------------------------------- */}

                {/* Delete Modal */}
                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
                    <span><button type="button" class="erp-modal-close btn-close" aria-label="Close" onClick={handleClose}></button></span>
                    <Modal.Body className='erp_modal_body'>
                        <span className='erp_modal_delete_icon'><RxCrossCircled /></span>
                        <h6>Are you sure?</h6>
                        <div className="erp-form-label">Do you wish to delete this record ?</div>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-center'>
                        <Button variant="success" className='erp-gb-button' onClick={handleClose}>
                            Cancel
                        </Button>&nbsp;
                        <Button variant="danger" className='erp-gb-button' onClick={deleteRecords}>Delete</Button>
                    </Modal.Footer>
                </Modal>
            </DashboardLayout >
        </>
    )
}