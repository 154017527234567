import React, { useState, useEffect, useRef, useMemo } from "react";

// Material Dashboard 2 PRO React components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Imports React bootstrap & stylling required
import { CircularProgress } from "@material-ui/core";
import { Form, Table, Card } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'

// Import for the searchable combo box.
import Select from 'react-select';

// Generic Component's & Functions Import
import ConfigConstants from "assets/Constants/config-constant";
import { globalQuery, resetGlobalQuery, } from "assets/Constants/config-constant"
import ComboBox from "Features/ComboBox";
import ExcelExport from "Features/Exports/ExcelExport";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal";
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { useNavigate } from "react-router-dom";
import ReactDOMServer from 'react-dom/server';

function MissedPunchReport() {
    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, UserName, COMPANY_NAME, COMPANY_ADDRESS, UserId, DEPARTMENT_ID } = configConstants;
    const navigate = useNavigate();

    // UseRefs Hooks.
    const comboDataFunc = useRef();
    const exlsExp = useRef();

    // UseRefs Hooks For Searchable combos.
    let employeeTypesComboRef = useRef(null);
    let shiftsComboRef = useRef(null);
    // let cmb_sub_department_id_ref = useRef(null);

    // Helping hooks;
    const [isLoading, setIsLoading] = useState(false);

    // combo's options hooks;
    const defaultOptions = { value: '0', label: 'All', two_day_shift: '', field_name: 'All' };
    const [employeeTypesOpts, setEmployeeTypesOpts] = useState([]);
    const [shiftOpts, setShiftOpts] = useState([defaultOptions]);
    // const [subDepartmentOpts, setSubDepartmentOpts] = useState([]);
    const immidiatePunchFromDate = new Date().toISOString().split('T')[0];
    const immidiatePunchToDate = new Date().toISOString().split('T')[0];


    // Shift management data hooks;
    const [employeePunchingDetails, setEmployeePunchingDetails] = useState([]);
    const [updatedRecords, setUpdatedRecords] = useState([]); // Track only updated records

    const [employeeShiftHeaders, setEmployeeShiftHeaders] = useState([]);

    // Input field's Hooks;
    const [cmb_employee_type, setEmployeeType] = useState('');
    const [cmb_shift, setShift] = useState('0');
    const [dt_att_from_date, setAttFromDate] = useState('');
    const [dt_att_to_date, setAttToDate] = useState('');
    // const [subDepartmentId, setSubDepartmentId] = useState(0);
    const [chk_punch_type, setPunchType] = useState(true);
    const [departmentOpts, setDepartmentOpts] = useState([]);
    const [subDepartmentOpts, setSubDepartmentOpts] = useState([]);
    const [cmb_departmentId, setDepartmentId] = useState(0);
    const [allDepartmentsList, setAllDepartmentsList] = useState([]);
    const [cmb_sub_department_id, setSubDepartmentId] = useState(0);


    const [lock_date, setLockDate] = useState('');
    const [lock_status, setLockStatus] = useState('');
    const [lockMonthName, setLockMonthName] = useState('');
    const [showSaveButton, setShowSaveButton] = useState(true);

    // Error Msg HANDLING
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    let cmb_department_id_ref = useRef(null);
    let subDepartmentComboRef = useRef(null);
    // Success Msg HANDLING
    const handleCloseSuccessModal = () => { setShowSuccessMsgModal(false); }
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');
    const printComponentRef = useRef();
    const [isPrinting, setIsPrinting] = useState(false);
    const promiseResolveRef = useRef(null);

    useEffect(() => {
        const loadDataOnload = async () => {
            if (shiftsComboRef)
                shiftsComboRef.current = defaultOptions;
            setIsLoading(true);
            await FnFillComobos();
            setIsLoading(false);
        }
        loadDataOnload();
    }, [])


    const isDateEnabled = (date) => {
        if (!lock_status) return true; // If lock_status is false, enable all dates
        if (!lock_date) return true; // If lock_date is not set, enable all dates

        const lockDate = new Date(lock_date);
        const lockYear = lockDate.getFullYear();
        const lockMonth = lockDate.getMonth(); // 0 = Jan, 1 = Feb, ..., 11 = Dec

        const selectedYear = date.getFullYear();
        const selectedMonth = date.getMonth(); // 0 = Jan, 1 = Feb, ..., 11 = Dec
        if (selectedYear > lockYear || (selectedYear === lockYear && selectedMonth >= lockMonth)) {
            return true;
        }
        return false;
    };
    const FnFillComobos = async () => {
        try {
            resetGlobalQuery();
            globalQuery.columns = ["lock_date", "lock_status", "hrpayroll_settings_id"]
            globalQuery.table = "hm_hrpayroll_settings";
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            const lockData = await comboDataFunc.current.fillFiltersCombo(globalQuery);
            setLockDate(lockData[0].lock_date);
            setLockStatus(lockData[0].lock_status);
            let lockDate = new Date(lockData[0].lock_date);
            const lockMonth = lockDate.getMonth(); // 0 = January, 1 = February, ..., 11 = December

            // Array of month names
            const monthNames = [
                "January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];

            // Get the name of the locked month
            const lockMonthName = monthNames[lockMonth];

            // Calculate the next month, wrapping around to January if it's December
            const prevMonth = lockMonth === 0 ? 11 : lockMonth - 1; // If January (0), set to December (11)

            const prevMonthName = monthNames[prevMonth];
            setLockMonthName(prevMonthName);
            console.log(prevMonthName);

            // Load employee types
            resetGlobalQuery();
            globalQuery.columns = ["field_id", "field_name"];
            globalQuery.table = "amv_properties"
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: 'EmployeeTypeGroup' });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(rcvdEmplTypes => {
                    const emplTypes = [
                        { value: '', label: 'Select', field_id: '', field_name: '', },
                        ...rcvdEmplTypes.map((emplType) => ({ ...emplType, value: emplType.field_id, label: emplType.field_name, })),
                    ];
                    setEmployeeTypesOpts(emplTypes);
                });

            // Load employee types
            // resetGlobalQuery();
            // globalQuery.columns = ["department_id", "department_name"];
            // globalQuery.table = "cm_department"
            // globalQuery.conditions.push({ field: "department_type", operator: "=", value: 'S' });
            // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            // globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, parseInt(COMPANY_ID)] });
            // comboDataFunc.current.fillFiltersCombo(globalQuery)
            //     .then(rcvdDepartment => {
            //         const departments = [
            //             // { value: '', label: 'Select', department_id: '', department_name: '', },
            //             { value: 0, label: 'All', department_id: '', department_name: 'All' },
            //             ...rcvdDepartment.map((dept) => ({ ...dept, value: dept.department_id, label: dept.department_name, })),
            //         ];
            //         setSubDepartmentOpts(departments);
            //     });

            // Load Department & Sub-Department
            resetGlobalQuery();
            globalQuery.columns = ["department_id", "parent_department_id", "department_type", "department_name", "department_group"];
            globalQuery.table = "cm_department"
            globalQuery.conditions.push({ field: "company_id", operator: "IN", values: [0, parseInt(COMPANY_ID)] });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(rcvdDepts => {
                    const { mainDepartments, subDepartments } = rcvdDepts.reduce((acc, department) => {
                        if (department.department_type === "M") {
                            acc.mainDepartments.push({
                                ...department,
                                label: department.department_name,
                                value: department.department_id,
                            });
                        } else if (department.department_type === "S") {
                            acc.subDepartments.push({
                                ...department,
                                label: department.department_name,
                                value: department.department_id,
                            });
                        }
                        return acc;
                    }, { mainDepartments: [], subDepartments: [] });
                    setAllDepartmentsList([...mainDepartments, ...subDepartments]);

                    mainDepartments.unshift({ department_id: 0, value: 'All', label: 'All' });
                    setDepartmentOpts(mainDepartments);
                    // subDepartments.unshift({ value: 'All', label: 'All' });
                    // setSubDepartmentOpts(subDepartments);
                });

        } catch (error) {
            console.log("error on filling combos: ", error)
            navigator('/Error')
        }
    }

    const FnCombosOnChange = (key, value) => {
        try {
            switch (key) {
                case 'EmployeeType':
                    const employee_type = employeeTypesComboRef.current.value;

                    if (employee_type !== '') {
                        //Hide the error msg
                        let errorElement = document.getElementById("error_cmb_employee_type");
                        errorElement.style.display = "none";

                        // Load shifts
                        resetGlobalQuery();
                        globalQuery.columns = ['field_id', 'field_name', 'two_day_shift', 'start_time', 'end_time']
                        globalQuery.table = "cmv_shift"
                        globalQuery.conditions = [
                            { field: "employee_type_group", operator: "=", value: employeeTypesComboRef.current.field_name },
                        ];
                        comboDataFunc.current.fillFiltersCombo(globalQuery).then((workingShifts) => {
                            const shiftsList = [
                                { value: '0', label: 'All', two_day_shift: '', field_name: 'All' },
                                ...workingShifts.map((shifts) => ({
                                    ...shifts,
                                    value: shifts.field_id,
                                    label: shifts.field_name + ` [${shifts.start_time} - ${shifts.end_time}]`,   // Without Am/Pm
                                    two_day_shift: shifts.two_day_shift
                                })),
                            ];
                            setShiftOpts(shiftsList);
                        });
                    }

                    setShift('0')
                    if (shiftsComboRef.current) shiftsComboRef.current = defaultOptions;

                    break;
                case 'AttFromDate':
                    setShowSaveButton(isDateEnabled(value));
                    if (value !== '')
                        document.getElementById('error_dt_att_from_date').style.display = 'none';

                    setAttFromDate(value);
                    break;
                case 'AttToDate':
                    setShowSaveButton(isDateEnabled(value));
                    if (value !== '')
                        document.getElementById('error_dt_att_to_date').style.display = 'none';

                    setAttToDate(value);
                    break;
                case 'Shift':
                    const shift = shiftsComboRef.current.value
                    if (shift !== '')
                        document.getElementById('error_cmb_shift').style.display = 'none';
                    break;
                case "Department":
                    subDepartmentComboRef.current = null;
                    setSubDepartmentId(0);
                    let selectedDepartment = cmb_department_id_ref.current.department_id;
                    const subDepartmentList = allDepartmentsList.filter(department =>
                        (!selectedDepartment || department.parent_department_id === selectedDepartment) && department.department_type === "S"
                    );
                    subDepartmentList.unshift({ department_id: 0, value: 'All', label: 'All' });
                    setSubDepartmentOpts(subDepartmentList);

                    break;
                case "subDepartment":
                    setEmployeePunchingDetails([]);
                    let selectedDepTS = cmb_department_id_ref.current.department_id;
                    const subDepartmentLists = allDepartmentsList.filter(department =>
                        (!selectedDepTS || department.parent_department_id === selectedDepTS) && department.department_type === "S"
                    );
                    subDepartmentLists.unshift({ department_id: '', value: 'All', label: 'All' });
                    setSubDepartmentOpts(subDepartmentLists);

                    break;
                default:
                    break;
            }

            setEmployeePunchingDetails([]) // clear employee punching details on any field change

        } catch (error) {
            console.log('error', error);
        }
    }

    const validateShiftManagementDetails = () => {
        if (cmb_employee_type === '') {
            let errorElement = document.getElementById("error_cmb_employee_type");
            errorElement.textContent = "Please fill this field...!";
            errorElement.style.display = "block";
            document.getElementById("cmb_employee_type").focus();
            return false;
        }

        if (cmb_shift === '') {
            let errorElement = document.getElementById("error_cmb_shift");
            errorElement.textContent = "Please fill this field...!";
            errorElement.style.display = "block";
            document.getElementById("cmb_shift").focus();
            return false;
        }

        if (dt_att_from_date === '' || dt_att_from_date === null || dt_att_from_date === undefined) {
            let errorElement = document.getElementById("error_dt_att_from_date");
            errorElement.textContent = "Please fill this field...!";
            errorElement.style.display = "block";
            document.getElementById("dt_att_from_date").focus();
            return false;
        }

        return true;
    }


    // fn to farmat date into string
    const FnFormatDate = (date) => {
        if (date !== null && date !== "") {
            return new Date(date).toLocaleDateString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
            }).split('/').reverse().join('-'); // dd-mm-yyyy format
        }
    };


    const FnHoursOnChange = (rowItem, event) => {
        debugger
        const arrayIndex = parseInt(
            event.target.parentElement.parentElement.getAttribute("rowIndex")
        );
        let clickedColName = event.target.getAttribute("Headers");

        // Parse shift start and end times
        let [shiftStart, shiftEnd] = rowItem.shift_start_end_time.split("-");
        const [shiftStartHours, shiftStartMinutes] = shiftStart
            .split(":")
            .map(Number);
        const [shiftEndHours, shiftEndMinutes] = shiftEnd.split(":").map(Number);
        const shiftStartTime =
            shiftStartHours * 60 +
            shiftStartMinutes -
            (rowItem?.shift_grace_hours_min || 0) * 60;
        const shiftEndTime =
            shiftEndHours * 60 +
            shiftEndMinutes +
            (rowItem?.shift_grace_hours_max || 0) * 60;

        // Convert the shift times back to hours and minutes
        const adjustedShiftStartHours = Math.floor(shiftStartTime / 60);
        const adjustedShiftStartMinutes = shiftStartTime % 60;
        const adjustedShiftEndHours = Math.floor(shiftEndTime / 60);
        const adjustedShiftEndMinutes = shiftEndTime % 60;

        // Format the adjusted times as HH:mm
        const adjustedShiftStart = `${adjustedShiftStartHours
            .toString()
            .padStart(2, "0")}:${adjustedShiftStartMinutes
                .toString()
                .padStart(2, "0")}`;
        const adjustedShiftEnd = `${adjustedShiftEndHours
            .toString()
            .padStart(2, "0")}:${adjustedShiftEndMinutes
                .toString()
                .padStart(2, "0")}`;

        // Function to convert time in HH:MM format to total minutes since midnight
        const timeToMinutes = (time) => {
            const [hours, minutes] = time.split(":").map(Number);
            return hours * 60 + minutes;
        };

        // Determine if it's a night shift (crosses midnight)
        const isNightShift = rowItem.two_day_shift;

        switch (clickedColName) {
            case "min_att_time":
                const minInputTime = event.target.value;
                const inputMinutesMin = timeToMinutes(minInputTime);
                rowItem[clickedColName] = minInputTime;

                // Convert input time to total minutes
                if (event._reactName === "onBlur") {
                    if (
                        !isNightShift &&
                        inputMinutesMin >= shiftStartTime &&
                        inputMinutesMin <= shiftEndTime
                    ) {
                        rowItem[clickedColName] = minInputTime;
                        // Set punch_type to "In" when updating min_att_time
                        rowItem.punch_type = "In";
                    } else if (isNightShift && inputMinutesMin >= shiftStartTime) {
                        rowItem[clickedColName] = minInputTime;
                        // Set punch_type to "In" when updating min_att_time
                        rowItem.punch_type = "In";
                    } else if (rowItem[clickedColName] !== "") {
                        alert(
                            `Time must be between ${adjustedShiftStart} and ${adjustedShiftEnd}`
                        );
                        rowItem[clickedColName] = "";
                        rowItem.worked_hours = "";
                    }
                }
                break;
            case "max_att_time":
                const inputTime = event.target.value;
                const inputMinutes = timeToMinutes(inputTime);
                rowItem[clickedColName] = inputTime;

                // Convert input time to total minutes
                if (event._reactName === "onBlur") {
                    if (isNightShift && inputMinutes <= shiftEndTime) {
                        rowItem[clickedColName] = inputTime;
                        rowItem[clickedColName] = inputTime;
                        // Set punch_type to "Out" when updating max_att_time
                        rowItem.punch_type = "Out";
                    } else if (
                        !isNightShift &&
                        inputMinutes >= shiftStartTime &&
                        inputMinutes <= shiftEndTime
                    ) {
                        rowItem[clickedColName] = inputTime;
                        // Set punch_type to "Out" when updating max_att_time
                        rowItem.punch_type = "Out";
                    } else if (rowItem[clickedColName] !== "") {
                        alert(
                            `Time must be between ${adjustedShiftStart} and ${adjustedShiftEnd}`
                        );
                        rowItem[clickedColName] = "";
                        rowItem.worked_hours = "";
                    }
                }

                break;
            default:
                break;
        }
        // Calculate worked hours if both min_att_time and max_att_time are available
        if (rowItem.min_att_time && rowItem.max_att_time) {
            const minMinutes = timeToMinutes(rowItem.min_att_time);
            const maxMinutes = timeToMinutes(rowItem.max_att_time);

            let workedMinutes = maxMinutes - minMinutes;

            if (workedMinutes < 0) {
                // Handle case where max time is earlier than min time (e.g., across midnight)
                workedMinutes += 24 * 60; // Add 24 hours to convert negative to positive
            }

            const workedHours = Math.floor(workedMinutes / 60);
            const remainingMinutes = workedMinutes % 60;

            // Format worked hours as HH:MM
            const formatTime = (hours, minutes) => {
                const formattedHours = String(hours).padStart(2, "0");
                const formattedMinutes = String(minutes).padStart(2, "0");
                return `${formattedHours}:${formattedMinutes}`;
            };

            rowItem.worked_hours = formatTime(workedHours, remainingMinutes);
        }

        rowItem["attendance_flag"] = "M";
        const tblRows = [...employeePunchingDetails];
        tblRows[arrayIndex] = rowItem;
        setEmployeePunchingDetails(tblRows);

        setUpdatedRecords((prevRecords) => {
            // Create a copy of the previous records array
            let updatedRecords = [...prevRecords]; // Change const to let

            // Check if the rowItem already exists in updatedRecords
            const existingIndex = updatedRecords.findIndex((record) =>
                record.employee_id === rowItem.employee_id &&
                record.attendance_date === rowItem.attendance_date
            );

            // Check if min_att_time and max_att_time are not null and not empty strings
            const isIntimeOuttimeValid =
                rowItem.min_att_time !== null && rowItem.min_att_time !== "" &&
                rowItem.max_att_time !== null && rowItem.max_att_time !== "";
            if (existingIndex > -1) {
                // If it exists, update the record in place
                // updatedRecords[existingIndex] = { ...updatedRecords[existingIndex], ...rowItem };
                if (existingIndex > -1) {
                    // If it exists, create a new record to update
                    const updatedRecord = { ...updatedRecords[existingIndex], ...rowItem };

                    // Check if either min_att_time or max_att_time is empty
                    if (updatedRecord.min_att_time === "" || updatedRecord.max_att_time === "") {
                        // Remove the record from updatedRecords
                        updatedRecords = updatedRecords.filter((_, index) => index !== existingIndex);
                    } else {
                        // Update the record in place if both times are valid
                        updatedRecords[existingIndex] = updatedRecord;
                    }
                }
            } else if (isIntimeOuttimeValid) {
                // If it does not exist, add the new rowItem to the array
                updatedRecords.push(rowItem);
            }

            // Return the updated array to setUpdatedRecords
            return updatedRecords;
        });

    };


    const saveMissPunchCorrection = async () => {
        debugger
        setIsLoading(true);
        try {
            let json = { 'MisspunchAllData': [], 'commonIds': {} }

            let updatedEmployeePunchingDetails = [];

            for (let index = 0; index < updatedRecords.length; index++) {
                const element = updatedRecords[index];
                const data = {
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    created_by: UserName,
                    misspunch_correction_id: element.misspunch_correction_id ?? 0,
                    punch_code: element.old_employee_code,
                    att_date_time: element.attendance_date,
                    out_time: element.max_att_time,
                    in_time: element.min_att_time,
                    punch_type: element.punch_type,
                    employee_code: element.employee_code,
                };
                updatedEmployeePunchingDetails.push(data);
            }

            json.MisspunchAllData = updatedEmployeePunchingDetails
            json.commonIds.company_id = COMPANY_ID
            console.log(json);

            const formData = new FormData();
            formData.append(`MisspunchCorrection`, JSON.stringify(json))
            const requestOptions = {
                method: 'POST',
                body: formData
            };
            const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/shiftManagement/FnAddUpdateMissPunchRecord`, requestOptions)
            const resp = await apicall.json();

            if (resp.success === 0) {
                setErrMsg(resp.error);
                setShowErrorMsgModal(true);
            } else {
                setSuccMsg(resp.message);
                setShowSuccessMsgModal(true);
                setUpdatedRecords([]);
                setEmployeePunchingDetails([]);
            }

        } catch (error) {
            console.error("Error saving miss punch correction:", error);
        } finally {
            setIsLoading(false);
        }
    };



    const FnShowAttendanceDetails = async () => {
        debugger
        setIsLoading(true)
        try {
            let selectedDepartment = cmb_department_id_ref.current?.department_id || null;
            let selectedSubDepartment = subDepartmentComboRef.current?.department_id || null;
            if (selectedDepartment === null) {
                setDepartmentId('All')
                FnCombosOnChange("subDepartment");
            }

            if (selectedSubDepartment === null) {
                setSubDepartmentId('All')
            }
            // Validate required fields
            const validate = validateShiftManagementDetails();

            if (validate) {
                const data = {
                    company_id: COMPANY_ID,
                    employee_id: UserId,
                    employee_type_group: employeeTypesComboRef.current.field_name,
                    shift_name: shiftsComboRef.current.field_name,
                    two_day_shift: shiftsComboRef.current.two_day_shift,
                    attendance_from_date: FnFormatDate(dt_att_from_date),
                    attendance_to_date: FnFormatDate(dt_att_to_date),
                    department_id: cmb_department_id_ref.current.department_id,
                    sub_department_id: subDepartmentComboRef.current?.department_id
                }

                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data)
                }
                const getEmployeeDetails = await fetch(`${process.env.REACT_APP_BASE_URL}/api/shiftManagement/FnShowEmployeesMissPunchDetails`, requestOptions);
                const response = await getEmployeeDetails.json();
                console.log("response of attendance for misspunch", response);

                if (response.success === 1) {
                    // const missedPunches = response.content
                    //     .filter(item => item.max_att_time === null || item.min_att_time === null)
                    //     .map(item => ({
                    //         ...item,
                    //         min_att_time: item.min_att_time === null ? null : item.min_att_time,
                    //         max_att_time: item.max_att_time === null ? null : item.max_att_time
                    //     }))
                    //     .sort((a, b) => {
                    //         if (a.department_name < b.department_name) return -1;
                    //         if (a.department_name > b.department_name) return 1;
                    //         if (a.employee_code < b.employee_code) return -1;
                    //         if (a.employee_code > b.employee_code) return 1;
                    //         return 0;
                    //     });

                    setEmployeePunchingDetails(response.content);

                    const desiredColumns = [
                        'sr_no',
                        'employee_name',
                        'department_name',
                        'sub_department_name',
                        'employee_code',
                        'shift_name',
                        'attendance_date',
                        'min_att_time',
                        'max_att_time'
                    ];

                    // Filter response.columns to include only desired columns
                    const filteredColumns = response.columns.filter(column =>
                        desiredColumns.includes(column.accessor)
                    );
                    setEmployeeShiftHeaders(filteredColumns);
                } else {
                    setEmployeePunchingDetails([]);
                    setErrMsg(response.error);
                    setShowErrorMsgModal(true);
                }
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            setIsLoading(false)
        }
    }

    const FnExportShiftWisePunchDetails = async () => {
        debugger
        try {
            setIsLoading(true)
            if (employeePunchingDetails.length !== 0) {
                let jsonToExportExcel = { 'allData': {}, 'columns': [], 'filtrKeyValue': {}, 'headings': {}, 'key': 'bomExport', 'editable_cols': [] } // , 25 for remark;

                let filtrKeyValue = {};

                filtrKeyValue['0'] = "Employee Type : " + employeeTypesComboRef.current.field_name
                filtrKeyValue['1'] = "Shift : " + shiftsComboRef.current.field_name
                filtrKeyValue['2'] = "From Date : " + FnFormatDate(dt_att_from_date)
                filtrKeyValue['3'] = "To Date : " + FnFormatDate(dt_att_to_date)
                // if (subDepartmentId !== 0)
                // filtrKeyValue['3'] = "Department Name : " + cmb_department_id_ref.current.department_name
                let departmentName = cmb_department_id_ref.current.department_name;
                if (departmentName === '' || departmentName === 0 || departmentName === undefined) {
                    departmentName = 'All';
                }

                filtrKeyValue['4'] = "Department Name : " + departmentName;
                let subdepartmentName = subDepartmentComboRef.current?.label || '';
                if (subdepartmentName === '' || subdepartmentName === 0 || subdepartmentName === undefined) {
                    subdepartmentName = 'All';
                }

                filtrKeyValue['5'] = "SubDepartment Name : " + subdepartmentName;
                jsonToExportExcel['filtrKeyValue'] = filtrKeyValue;

                // set columns name.
                // const srNoHeader = { Headers: 'Sr No', accessor: 'sr_no' };
                // jsonToExportExcel.columns = [srNoHeader, ...employeeShiftHeaders];

                jsonToExportExcel.columns = [
                    { Headers: 'Sr No', accessor: 'sr_no' },
                    { Headers: 'Department Name', accessor: 'department_name' },
                    { Headers: 'Sub Department Name', accessor: 'sub_department_name' },
                    { Headers: 'Employee Name', accessor: 'employee_name' },
                    { Headers: 'Machine Employee Code', accessor: 'machine_employee_code' },
                    { Headers: 'Shift Name', accessor: 'shift_name' },
                    { Headers: 'Attendance Date', accessor: 'attendance_date' },
                    { Headers: 'In Time', accessor: 'min_att_time' },
                    { Headers: 'Out Time', accessor: 'max_att_time' }
                ]


                const updatedEmployeePunchingDetails = [...employeePunchingDetails];

                // Assign updated objects to jsonToExportExcel['allData'] at specific index
                updatedEmployeePunchingDetails.forEach((punchDetails, index) => {
                    let shift_name = '';
                    if (punchDetails.shift_name !== undefined && punchDetails.shift_start_end_time !== undefined) {
                        shift_name = `${punchDetails.shift_name} ${punchDetails.shift_start_end_time}`;
                    }
                    const updatedObj = {
                        ...punchDetails,
                        sr_no: index + 1,
                        shift_name: shift_name
                    };
                    jsonToExportExcel['allData'][index] = updatedObj;
                });


                jsonToExportExcel['headings']['ReportName'] = "Missed Punch Report"
                jsonToExportExcel['headings']['CompanyName'] = COMPANY_NAME
                jsonToExportExcel['headings']['CompanyAddress'] = COMPANY_ADDRESS

                await exlsExp.current.excel(jsonToExportExcel, employeeTypesComboRef.current.field_name + '-' + "Missed Punch Report" + '-' + FnFormatDate(dt_att_from_date) + '-' + FnFormatDate(dt_att_to_date))
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            setIsLoading(false)
        }
    }


    const goBack = () => {
        navigate("/Dashboard")
    }


    // ********* PrintOut Functionalities Starts. ****************************************
    useEffect(() => {
        if (isPrinting && promiseResolveRef.current) {
            promiseResolveRef.current();
        }
    }, [isPrinting]);

    const FnPrintImmediatePunch = useReactToPrint({
        content: () => {
            debugger
            const headerHtml = printOutHeader(); // Call function to get header HTML

            const contentWrapper = document.createElement('div');
            contentWrapper.className = 'printable-content';
            contentWrapper.innerHTML = headerHtml; // Add generated header HTML

            // Append the main content to the wrapper
            const mainContentClone = printComponentRef.current.cloneNode(true);
            // Remove the print button from the cloned content

            contentWrapper.appendChild(mainContentClone);

            return contentWrapper;
        },
        onBeforePrint: () => {
            // Apply the desired font size before printing
            if (printComponentRef.current) {
                const table = printComponentRef.current.querySelector('.erp_table');
                if (table) {
                    table.style.fontSize = '7px'; // Set your desired font size
                }
            }
        },
        onBeforeGetContent: () => {
            debugger
            return new Promise((resolve) => {
                promiseResolveRef.current = resolve;
                setIsPrinting(true);
            });
        },
        onAfterPrint: () => {
            // Reset the Promise resolve so we can print again
            promiseResolveRef.current = null;
            setIsPrinting(false);
        },
        documentTitle: "Missed Punch Report"
    });
    function formatDate(date) {
        const d = new Date(date);
        const day = d.getDate().toString().padStart(2, '0');
        const month = (d.getMonth() + 1).toString().padStart(2, '0');
        const year = d.getFullYear();
        return `${day}-${month}-${year}`;
    }

    const printOutHeader = () => {
        return ReactDOMServer.renderToString(
            <>
                <div className='px-0 border border-dark'> {/* border border-dark */}
                    <div className='row mb-0 p-1'>
                        <div className="col-sm-1">
                            <img src={DakshabhiLogo} alt="master card" width="170" height="auto" mt={1} />
                        </div>
                        <div className="col-sm-11">
                            <div className='erp-invoice-print-label text-center'>
                                <span className='erp-invoice-print-label-lg'>{COMPANY_NAME}</span><br />
                            </div>
                            <div className='erp-invoice-print-label-lg text-center'>
                                Missed Punch Report
                                {
                                    employeeTypesComboRef.current?.label !== 'All' && employeeTypesComboRef.current?.label !== '' && (
                                        ` (${employeeTypesComboRef.current?.label})`
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className='px-0 border border-dark'> {/* border border-dark */}
                    <div className='row mb-0 p-1'></div>
                    <div className="row" style={{ marginLeft: '-7px' }}>
                        <div className="col-sm-6">
                            <dt className="erp-invoice-print-label-md-lg">Employee Type : <span className="erp-invoice-print-label">{
                                employeeTypesComboRef.current?.label !== 'All' && employeeTypesComboRef.current?.label !== '' && (
                                    `${employeeTypesComboRef.current?.label}`
                                )
                            }</span></dt>

                        </div>
                        <div className="col-sm-6">
                            <dt className="erp-invoice-print-label-md-lg">
                                Department Name: <span className="erp-invoice-print-label">{cmb_department_id_ref.current?.label === 'Select' || cmb_department_id_ref.current?.label === undefined || cmb_department_id_ref.current?.label === '' ? 'All' : cmb_department_id_ref.current?.label}
                                </span></dt>

                        </div>
                    </div>
                    <div className="row" style={{ marginLeft: '-7px' }}>
                        <div className="col-sm-6">
                            <dt className="erp-invoice-print-label-md-lg">Shift :<span className="erp-invoice-print-label"> {
                                // shiftsComboRef.current?.label !== 'All' && shiftsComboRef.current?.label !== '' && (
                                `${shiftsComboRef.current?.label}`
                                // )
                            }</span></dt>
                        </div>
                        <div className="col-sm-6">
                            <dt className="erp-invoice-print-label-md-lg">
                                Sub Department Name: <span className="erp-invoice-print-label">{subDepartmentComboRef.current?.label === 'Select' || subDepartmentComboRef.current?.label === undefined || subDepartmentComboRef.current?.label === '' ? 'All' : subDepartmentComboRef.current?.label}
                                </span></dt>

                        </div>
                    </div>
                    <div className="row" style={{ marginLeft: '-7px' }}>
                        <div className="col-sm-6">
                            <dt className="erp-invoice-print-label-md-lg">From Date : <span className="erp-invoice-print-label">{formatDate(dt_att_from_date)}</span></dt>
                        </div>

                        <div className="col-sm-6">

                            <dt className="erp-invoice-print-label-md-lg">To Date : <span className="erp-invoice-print-label">{formatDate(dt_att_to_date)}</span></dt>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const FnRenderReportTbl = useMemo(() => {
        if (employeePunchingDetails.length === 0) { return null; }

        return (
            <>
                <style>
                    {`
                                    @media print {
                                .print-content {
                                    padding: 0;
                                    box-sizing: border-box;
                                }
                                @page {
                                    margin: 0;
                                    size: A4;
                                    margin: 2%;
                                    border: 2px solid black;
                                }
                                html, body {
                                    width: 100%;
                                    height: 100%;
                                }
                                body {
                                    margin: 0;
                                    padding: 0;
                                }
                                }
                            .erp_table {
                                font-size: 3px !important; /* Default font size */
                            }
                                .erp-invoice-print-label-md-lg , .erp-invoice-print-label{
                                font-size: 14px !important;

                                }
                            .erp_table_th, .erp_table_td {
                                border: 1px solid black;
                                padding-top: 2px !important;
                                padding-bottom: 2px !important;
                            }
                        `}
                </style>


                <Table className="erp_table" id='PunchDtlsTbl' style={{ whiteSpace: 'normal' }} responsive striped>
                    <thead className="erp_table_head table-secondary text-center" style={{ whiteSpace: 'normal' }} >
                        <tr style={{ whiteSpace: 'normal' }}>
                            <th className="erp_table_th align-middle" >Sr. No.</th>
                            <th className="erp_table_th align-middle col-2" >Department Name</th>
                            <th className="erp_table_th align-middle col-2" >Sub Department Name</th>
                            <th className="erp_table_th align-middle col-3">Employee Name</th>
                            <th className="erp_table_th align-middle col-1">Code</th>
                            <th className="erp_table_th align-middle col-1">Shift</th>
                            <th className="erp_table_th align-middle col-1">Attendance Date</th>
                            <th className="erp_table_th align-middle col-1">In Time</th>
                            <th className="erp_table_th align-middle col-1">Out Time</th>

                        </tr>
                    </thead>

                    <tbody>
                        {
                            employeePunchingDetails.map((item, index) => (
                                <tr key={index} style={{ height: "25px" }}>
                                    <td className="erp_table_td">{index + 1}</td>
                                    <td className="erp_table_td">{item.department_name}</td>
                                    <td className="erp_table_td">{item.sub_department_name}</td>
                                    <td className="erp_table_td">{item.employee_name}</td>
                                    <td className="erp_table_td">{item.old_employee_code}</td>
                                    <td className="erp_table_td">{item.shift_name + ' [' + item.shift_start_end_time + ']'}</td>
                                    <td className="erp_table_td text-center">{item.attendance_date}</td>
                                    {isPrinting === true ? (
                                        <>
                                            <td className="erp_table_td text-center">{item.min_att_time}</td>
                                            <td className="erp_table_td text-center">{item.max_att_time}</td>
                                        </>
                                    ) : (
                                        <>

                                            <td className="erp_table_td text-center">
                                                <input
                                                    type="time"
                                                    placeholder="HH:MM"
                                                    value={item?.min_att_time !== null ? item?.min_att_time : ''}
                                                    className="erp_input_field mb-0"
                                                    Headers="min_att_time"
                                                    onChange={(event) => FnHoursOnChange(item, event)}
                                                    onBlur={(event) => FnHoursOnChange(item, event)}
                                                    disabled={item.editableIn != "Yes" || (item.employee_id === parseInt(UserId) && item.reporting_to != parseInt(UserId))}
                                                    style={{
                                                        backgroundColor: item.punching_type === "In" ? "#FFFF00" :
                                                            item.punching_type === "IN_approve" ? "#90EE90" : "inherit",  // Dark red background for "Out"
                                                        color: item.punching_type === "In" ? "#000000" : "inherit" // White for "Out", Green (#008000) for others
                                                    }}
                                                />
                                            </td>
                                            <td className="erp_table_td text-center">
                                                <input
                                                    type="time"
                                                    placeholder="HH:MM"
                                                    value={item?.max_att_time !== null ? item?.max_att_time : ''}
                                                    className="erp_input_field mb-0"
                                                    Headers="max_att_time"
                                                    onChange={(event) => FnHoursOnChange(item, event)}
                                                    onBlur={(event) => FnHoursOnChange(item, event)}
                                                    // disabled={item.editableOut != "Yes" || item.employee_id === parseInt(UserId)}
                                                    disabled={item.editableOut != "Yes" || (item.employee_id === parseInt(UserId) && item.reporting_to != parseInt(UserId))}

                                                    style={{
                                                        backgroundColor: item.punching_type === "Out" ? "#FFFF00" :
                                                            item.punching_type === "OUT_approve" ? "#90EE90" : "inherit", // Dark red background for "Out"
                                                        color: item.punching_type === "Out" ? "#000000" : "inherit" // White for "Out", Green (#008000) for others
                                                    }}
                                                />
                                            </td>

                                        </>
                                    )}
                                </tr>
                            ))
                        }

                    </tbody>
                </Table>
            </>
        )
    }, [employeePunchingDetails, isPrinting]);

    // ********* PrintOut Functionalities Ends. ****************************************
    return (
        <>
            <ComboBox ref={comboDataFunc} />
            <ExcelExport ref={exlsExp} />

            <DashboardLayout>
                {isLoading ?
                    <div className="spinner-overlay"  >
                        <div className="spinner-container">
                            <CircularProgress color="primary" />
                            <span>Loading...</span>
                        </div>
                    </div> :
                    null}

                {/* Card Starts*/}
                <div className="card mt-4">
                    <div className="card-header py-0 main_heding">
                        <label className="erp-form-label-md-lg">Missed Punch Report</label>
                    </div>


                    {/* Card's Body Starts*/}
                    <div className="card-body">
                        <div className="row">
                            {/* First Column Starts*/}
                            <div className="col-sm-4 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label-md"> Employee Type <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className='col-12 col-sm-8'>
                                        <Select ref={employeeTypesComboRef}
                                            options={employeeTypesOpts}
                                            inputId="cmb_employee_type" // Provide the ID for the input box
                                            value={employeeTypesOpts.find(option => option.value === cmb_employee_type)}
                                            onChange={(selectedOpt) => {
                                                setEmployeeType(selectedOpt.value);
                                                employeeTypesComboRef.current = selectedOpt;
                                                FnCombosOnChange('EmployeeType')
                                            }}
                                            placeholder="Search for a employee type..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select" // Add custom prefix for class names
                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_employee_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                                {/* </div> */}
                                {/* First Column Ends*/}

                                {/* Second Column Starts*/}
                                {/* <div className="col-sm-3 erp_form_col_div"> */}
                                <div className="row">
                                    <div className="col-sm-4"> <Form.Label className="erp-form-label-md"> Shift </Form.Label> </div>
                                    <div className='col'>
                                        <Select ref={shiftsComboRef}
                                            options={shiftOpts}
                                            inputId="cmb_shift" // Provide the ID for the input box
                                            value={shiftOpts.find(option => option.value === cmb_shift)}
                                            onChange={(selectedOpt) => {
                                                setShift(selectedOpt.value);
                                                shiftsComboRef.current = selectedOpt;
                                                FnCombosOnChange('Shift')
                                            }}
                                            placeholder="Search for a shift..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select" // Add custom prefix for class names
                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_shift" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>

                            </div>
                            {/* Second Column Ends*/}
                            {/* Second Column Starts*/}
                            <div className="col-sm-4 erp_form_col_div">
                                <div className="row">
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label-md">Department</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <Select
                                            ref={cmb_department_id_ref}
                                            options={departmentOpts}
                                            // isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                                            value={departmentOpts.length > 1 ? departmentOpts.find(option => option.value === cmb_departmentId) : null}
                                            onChange={(selectedOpt) => {
                                                setDepartmentId(selectedOpt.value);
                                                cmb_department_id_ref.current = selectedOpt;
                                                FnCombosOnChange("Department");
                                            }}
                                            placeholder="Search for a Sub-Department..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select" // Add custom prefix for class names
                                            // disabled={['view', 'approve'].includes(keyForViewUpdate)}
                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label-md"> Sub-Department </Form.Label> </div>
                                    <div className='col'>
                                        <Select ref={subDepartmentComboRef}
                                            options={subDepartmentOpts}
                                            inputId="cmb_sub_department_id"
                                            value={subDepartmentOpts.find(option => option.value == cmb_sub_department_id) || null}
                                            onChange={(selectedOpt) => {
                                                setSubDepartmentId(selectedOpt.value);
                                                subDepartmentComboRef.current = selectedOpt;
                                                FnCombosOnChange("subDepartment");
                                                // FnValidateFields();
                                            }}
                                            placeholder="Search for a sub-department..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_sub_department_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                </div>




                            </div>
                            {/* First Third Column Starts*/}
                            <div className="col-sm-4 erp_form_col_div">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label-md">From Date <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        <DatePicker
                                            selected={dt_att_from_date}
                                            id="dt_att_from_date"
                                            onChange={(date) => FnCombosOnChange('AttFromDate', date)}
                                            dateFormat="dd-MM-yyyy"
                                            placeholderText="dd-mm-yyyy"
                                            className="erp_input_field optional"
                                            maxDate={immidiatePunchFromDate}

                                        />
                                        <MDTypography variant="button" id="error_dt_att_from_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} />
                                    </div>
                                </div>


                                {/* <div className="col-sm-4 erp_form_col_div"> */}
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label-md">To Date
                                        </Form.Label>
                                    </div>
                                    <div className="col">
                                        <DatePicker
                                            selected={dt_att_to_date}
                                            id="dt_att_to_date"
                                            onChange={(date) => FnCombosOnChange('AttToDate', date)}
                                            dateFormat="dd-MM-yyyy"
                                            placeholderText="dd-mm-yyyy"
                                            className="erp_input_field optional"
                                            maxDate={immidiatePunchToDate}
                                        />
                                        <MDTypography variant="button" id="error_dt_att_to_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} />
                                    </div>
                                </div>
                                {/* </div> */}
                            </div>
                            {/* First Third Column Ends*/}

                        </div>



                        <div className="row mt-1">
                            <div className="col-md-7 text-end">
                                <MDButton type="button" className="erp-gb-button" onClick={FnShowAttendanceDetails} variant="button" fontWeight="regular" >Show</MDButton>
                                <MDButton type="button" className="ms-2 erp-gb-button" onClick={FnExportShiftWisePunchDetails} variant="button" fontWeight="regular" disabled={employeePunchingDetails.length === 0}>Export To Excel</MDButton>
                            </div>
                            <div className="col-md-5 text-end">
                                {(UserId === 1 || UserId === "1" || DEPARTMENT_ID === 11 || DEPARTMENT_ID === "11") && (
                                    <span className="erp-form-label me-2 text-end" style={{ color: "red", marginLeft: "-10%", marginTop: "10px" }}>
                                        {lock_status
                                            ? `Attendance & Salary before ${lockMonthName} are locked`
                                            : `Attendance & Salary are unlocked`}
                                    </span>
                                )}
                            </div>
                        </div>

                        <hr />


                        <div className="row px-lg-2 d-block">
                            <div className="col-lg-12 col-12 erp_form_col_div">
                                <div className="card">
                                    <div className="card-header py-0 main_heding mb-0">
                                        <label className="erp-form-label-md-lg"> Employee's Missed Punching Details </label>
                                    </div>
                                    {/* Card's Body Starts*/}
                                    <div className="card-body p-0">

                                        <div className="row print-content" ref={printComponentRef} >
                                            {FnRenderReportTbl}

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <hr />
                        <div className="row text-center mt-3">
                            <div className=''>

                                <MDButton type="button" id="back-btn" variant="button" fontWeight="regular"
                                    className={`erp-gb-button ms-2`}
                                    onClick={(e) => { goBack(); }}  > Back </MDButton>

                                {
                                    employeePunchingDetails.length === 0
                                        ? null
                                        : <>
                                            {showSaveButton && (
                                                <MDButton type="submit" id="save_Button" className="erp-gb-button ms-2" variant="button"
                                                    onClick={() => saveMissPunchCorrection()} fontWeight="regular">
                                                    Save
                                                </MDButton>
                                            )}
                                            {/* <MDButton type="submit" id="save_Button" className={`erp-gb-button ms-2`} variant="button"
                                                onClick={() => saveMissPunchCorrection()} fontWeight="regular">Save</MDButton> */}
                                            <MDButton type="button" variant="button" fontWeight="regular"
                                                className="erp-gb-button ms-2 print-button"

                                                onClick={(e) => { FnPrintImmediatePunch(); }} >Print</MDButton>
                                        </>
                                }
                            </div>
                        </div>

                    </div>
                    {/* Card's Body Ends*/}


                </div>
                {/* Card Ends*/}
            </DashboardLayout>

            <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
            <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />

        </>
    )
}

export default MissedPunchReport


