import React, { useState, useRef, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import $ from 'jquery';

// Imports React bootstrap
import Tooltip from "@mui/material/Tooltip";
import Form from 'react-bootstrap/Form';
import { Accordion, Modal, Table, Button } from "react-bootstrap";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// React icons
import { MdDelete } from "react-icons/md";
import { RxCrossCircled } from "react-icons/rx";


//File Imports
import FrmValidations from "FrmGeneric/FrmValidations";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal"
import ComboBox from "Features/ComboBox";
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ConfigConstants from "assets/Constants/config-constant";
import GenerateTAutoNo from "FrmGeneric/GenerateTAutoNo";
import ExcelExport from "Features/Exports/ExcelExport";
import { CircularProgress } from "@material-ui/core";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";

import SearchItemFilter from "FrmGeneric/SearchItemFilter";

// Import for the searchable combo box.
import Select from 'react-select';

function FrmMaterialGatePassEntry() {
    const configConstants = ConfigConstants();
    const { FINANCIAL_SHORT_NAME, COMPANY_ID, COMPANY_BRANCH_ID, UserId, UserName } = configConstants;


    const { state } = useLocation();
    const { idList, keyForViewUpdate, compType } = state || {};
    const [isLoading, setIsLoading] = useState(false);
    const [openIssueDetailsAccord, setOpenIssueDetailsAccord] = useState(true);
    let docGroup = "Material Gate Pass"

    // For navigate
    const navigate = useNavigate();

    // UseRefs
    const validate = useRef();
    const comboDataAPiCall = useRef();
    const generateAutoNoAPiCall = useRef();
    const exlsExp = useRef();
    const validateNumberDateInput = useRef();

    // for today date formatted
    const todayDate = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    const currentDate = new Date().toISOString().split('T')[0];

    //form combo options
    const [productTypeOptions, setProducttypeOptions] = useState([])
    const [employeeOptions, setEmployeeOptions] = useState([])
    const [cmb_checker_by_id, setCheckerById] = useState(UserId)
    const [cmb_approved_by_id, setApprovedById] = useState(UserId)
    const [cmb_gate_pass_status, setGatePassStatus] = useState('P')
    const [cmb_approved_by_name, setApprovedByName] = useState(UserName)
    const [dt_approve_date, setApproveDate] = useState(todayDate())
    const [cmb_checker_by_name, setCheckerByName] = useState(UserName)

    //Form Fields
    const [cmb_product_type, setProductType] = useState('');
    const [cmb_product_type_name, setProductTypeName] = useState('');
    const [materialId, setMaterialId] = useState('')
    const [txt_gate_pass_no, setMaterialGatePassNo] = useState('')
    const [dt_gate_pass_date, setGatePassDate] = useState(todayDate)
    const [material_gate_pass_master_id, setTransferIssueMasterTransaction_id] = useState(idList.material_gate_pass_master_id);
    const [cmb_from_company_id, setFromCompanyId] = useState();
    const [materialGatePassDetailsData, setMaterialGatePassDetailsData] = useState([])
    const [material_type, setMaterialType] = useState('R');
    const [txt_service_provider_add, setServiceProviderAdd] = useState('');
    const [txt_vehicle_no, setVehicleNo] = useState('');
    const [txt_service_provider_name, setServiceProviderName] = useState('');

    let empApprovedComboRef = useRef(null);
    let empCheckerComboRef = useRef(null);
    // let productTypeComboRef = useRef(null);

    const [actionType, setActionType] = useState("");
    const [actionLabel, setActionLabel] = useState("Save");




    useEffect(async () => {
        setIsLoading(true);
        try {
            await ActionType();
            removeSessions('onload');
            await fillCombos();
            if (idList !== '' || idList !== undefined) {
                await FnCheckUpdateResponse();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }, [])
    // delete popup
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    //Error Msg
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');
    const [modalOrderDetails, setModalOrderDetails] = useState('')

    const closeConfirmationModal = async () => {
        setShowSuccessMsgModal(false)
        moveToListing();
    };


    const ActionType = async () => {
        switch (keyForViewUpdate) {
            case "update":
                // setIsApprove(false);
                setActionType("(Modification)");
                setActionLabel("Update");
                break;
            case "view":
                // setIsApprove(false);
                setActionType("(View)");
                $("input[type=radio]").attr("disabled", true);
                $("form").find("input,textarea,select").attr("disabled", "disabled");
                $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                break;
            case "approve":
                setActionType("(Approve)");
                setActionLabel("Approve");
                setGatePassStatus("A")
                $("input[type=radio]").attr("disabled", true);
                $("form").find("input,textarea,select").attr("disabled", "disabled");
                $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                // setIsApprove(true);
                break;
            case "return":
                // setIsApprove(false);
                setActionType("(Return)");
                setActionLabel("Return");
                setGatePassStatus("R")
                $("input[type=radio]").attr("disabled", true);
                $("form").find("input,textarea,select").attr("disabled", "disabled");
                $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                break;
            default:
                setActionType("(Creation)");
                break;
        }
    };

    // -------------------------------End Common terms -------------------------------------------------------------------------

    const fillCombos = async () => {
        try {
            console.log(`COMPANYID:${COMPANY_ID}`)
            setFromCompanyId(parseInt(COMPANY_ID))
            resetGlobalQuery();
            globalQuery.columns.push("field_id");
            globalQuery.columns.push("field_name");
            globalQuery.columns.push("product_type_short_name");
            globalQuery.table = "smv_product_type"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            // globalQuery.conditions.push({ field: "product_type_group", operator: "IN", values: ["RM", "FG"] });
            const prodTypeOpts = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
            console.log(prodTypeOpts)
            const options = [
                { value: '', label: 'Select',short_name:"" },
                ...prodTypeOpts.map((prodType) => ({ ...prodType, value: prodType.field_id, label: prodType.field_name,short_name: prodType.product_type_short_name })),
            ];
            setProducttypeOptions(options)

            resetGlobalQuery();
            globalQuery.columns.push("employee_id");
            globalQuery.columns.push("employee_name");
            globalQuery.table = "cm_employee";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "is_active", operator: "=", value: 1 });
            globalQuery.conditions.push({ field: "employee_type", operator: "!=", value: "Worker" });
            const requestedbyIdApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            const employeeOptions = [
                // { value: '', label: 'Select', },
                ...requestedbyIdApiCall.map((empl) => ({ ...empl, value: empl.employee_id, label: empl.employee_name })),
            ];
            setEmployeeOptions(employeeOptions);
            setIsLoading(false)

        }
        catch (error) {
            console.log("error: ", error)
        }
    }
    // const FnSetFieldUserAccess = () => {
    //     if (keyForViewUpdate != '') {
    //         switch (keyForViewUpdate) {
    //             case 'view':
    //                 $('input[type="checkbox"]').prop('readonly', true);
    //                 $("input[type=radio]").attr('disabled', true);
    //                 $("form").find("input,textarea,select").attr("disabled", "disabled");
    //                 $("table").find("input,button,textarea,select").attr("disabled", "disabled");
    //                 $("table").find('input[id^="indent_quantity_"]').attr("disabled", "disabled");
    //                 break;

    //             case 'delete':
    //                 $('input[type="checkbox"]').prop('readonly', true);
    //                 $("input[type=radio]").attr('disabled', true);
    //                 $("form").find("input,textarea,select").attr("disabled", "disabled");
    //                 $("table").find("input,button,textarea,select").attr("disabled", "disabled");
    //                 $('#viewdocument-id').hide();
    //                 $("table").find('input[id^="indent_quantity_"]').attr("disabled", "disabled");
    //                 break;

    //             default:
    //                 break;
    //         }
    //     }
    // }

    //fn to get po auto no
    const GenerateGatePassNo = async () => {
        const ptShortName = productTypeOptions.find((item) => item.value === parseInt($("#cmb_product_type").val()));
        const autoNoApiCall = await generateAutoNoAPiCall.current.generateTAutoNo("pt_material_gate_pass_master", "gate_pass_no", ptShortName.product_type_short_name, 'GP', "5");
            setMaterialGatePassNo(autoNoApiCall);
        return autoNoApiCall;
    };
    //-------------------------------------- Combo changes ------------------------------------------------------------
    const comboOnChange = async (key) => {
        debugger
        switch (key) {
            case 'ApprovedBy':
                debugger
                var employeeID = empApprovedComboRef.current?.employee_id;
                $("#error_cmb_approved_by_id").hide();
                if (employeeID !== "") {
                    // var employeeCode = $('#cmb_employee_id option:selected').attr('employeeCode');
                    setApprovedById(empApprovedComboRef.current.employee_id);
                    setApprovedByName(empApprovedComboRef.current.employee_name);

                } else {
                    setApprovedById('');
                    setApprovedByName('');
                }
                break;
            case 'CheckerBy':
                debugger
                var employeeID = empCheckerComboRef.current?.employee_id;
                $("#error_cmb_checker_by_id").hide();
                if (employeeID !== "") {
                    // var employeeCode = $('#cmb_employee_id option:selected').attr('employeeCode');
                    setCheckerById(empCheckerComboRef.current.employee_id);
                    setCheckerByName(empCheckerComboRef.current.employee_name);
                } else {
                    setCheckerById('');
                    setApprovedByName('');

                }
                break;
            case 'cmb_product_type':
                $("#error_cmb_product_type").hide();
                let productTypeId = $('#cmb_product_type option:selected').val()
                let prodTypeName=  $('#cmb_product_type option:selected').text()
                if (productTypeId !== "") {
                    setProductType(productTypeId);
                    setProductTypeName(prodTypeName);
                    setMaterialGatePassDetailsData([])
                }
                if (keyForViewUpdate === "add") {
                    GenerateGatePassNo()
                }

                break;

            default:
                break;
        }
    }


    //--------------------------------------------------------Start Details section----------------------------------------------------------------------

    const removeSessions = (reloadData) => {
        sessionStorage.removeItem(`comboFilterData`)
        sessionStorage.removeItem('isComboFilterExist')
        sessionStorage.removeItem('smv_product_type');
        sessionStorage.removeItem('smv_product_material_type');
        sessionStorage.removeItem('smv_product_material_grade');
        sessionStorage.removeItem('smv_product_material_shape');
        if (reloadData === 'onload') {
            sessionStorage.removeItem('filteredMaterialData')
        }
        for (let removeItemIndex = 1; removeItemIndex <= 5; removeItemIndex++) {
            sessionStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex + 'Name');
        }

        localStorage.removeItem('smv_product_material_type');
        localStorage.removeItem('smv_product_material_grade');
        localStorage.removeItem('smv_product_material_shape');
        localStorage.removeItem('smv_product_type');

        localStorage.removeItem('smv_product_material_typeName');
        localStorage.removeItem('smv_product_material_gradeName');
        localStorage.removeItem('smv_product_material_shapeName');
        localStorage.removeItem('smv_product_typeName');
    }

    const moveToListing = () => {
        const Path = compType === 'Register' ? '/Transactions/TPurchaseOrder/MaterialGatePass/FrmMaterialGatePassListing/reg' : `/Transactions/TPurchaseOrder/MaterialGatePass/FrmMaterialGatePassListing`;
        navigate(Path);
    }


    // $(document).on('click', '.remove_input_zero', function () {
    //     if ($(this).val() === "0") {
    //         $(this).val("")
    //     }
    // });
    $(document).on('mouseup mousedown', function (e) {
        let inputBoxes = $(".remove_input_zero");
        inputBoxes.each(function () {
            if ($(this).val() === "") {
                $(this).val("0");
            }
        });
    });



    //---------------------------------------------------- Accordian data load starts --------------------------------------------------------------------------------

    //---------------------------------------------------- Accordian data load Ends --------------------------------------------------------------------------------


    let requiredCols = ['product_material_name',
        'product_material_id',
        'product_code',
        'stock_quantity',
        'stock_weight',
        'product_material_std_weight',

    ];

    const fnSearchMaterialData = async () => {
        try {
            debugger
            setIsLoading(true)
            const getData = JSON.parse(sessionStorage.getItem('filteredMaterialData')) || [];
            if (getData.length !== 0) {
                let material_id = getData[0].product_material_id;
                setMaterialId(material_id)
                setMaterialGatePassDetailsData((prev) => {
                    const existingMaterialIds = prev.map((item) => item.product_material_id);

                    // Filter out materials that already exist
                    const newMaterials = getData.filter(
                        (item) => !existingMaterialIds.includes(item.product_material_id)
                    );

                    // Add only new materials
                    return [
                        ...prev,
                        ...newMaterials.map((item) => ({
                            ...item,
                            outward_quantity: 0,
                            outward_weight: 0,
                            inward_quantity: 0,
                            inward_weight: 0,
                            rate: 0,
                            gate_pass_item_status: 'P',
                        })),
                    ];
                });

                sessionStorage.removeItem('filteredMaterialData');
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error);
        }
    }
    const handleIssueDetailsInputChange = (currentRowData, event) => {
        const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'), 10);
        const focusedInputField = document.querySelector(`#${event.target.id}`);
        const clickedColName = event.target.getAttribute('Headers');
        let { stock_quantity, product_material_std_weight, stock_weight } = currentRowData;
        let enteredValue = event.target.value;

        // Ensure entered value is numeric or default to 0

        switch (clickedColName) {
            case 'outward_quantity':
                debugger;
                if (enteredValue !== "") {
                    currentRowData[clickedColName] = enteredValue;
                    delete focusedInputField.parentElement.dataset.tip;

                    // const parsedValue = parseInt(enteredValue, 10);
                    // const parsedStockQuantity = parseInt(stock_quantity, 10);

                    // if (parsedValue === 0) {
                    //     focusedInputField.parentElement.dataset.tip = `Cannot be 0...!`;
                    //     return false;
                    // } else if (parsedValue > parsedStockQuantity) {
                    //     focusedInputField.parentElement.dataset.tip = `Cannot be greater than ${stock_quantity}...!`;
                    //     return false;
                    // } else {
                    //     currentRowData[clickedColName] = enteredValue;
                    //     currentRowData.closing_balance_quantity = parsedStockQuantity - parsedValue;
                    //     if (currentRowData.stock_weight !== 0) {
                    //         currentRowData.closing_balance_weight = currentRowData.stock_weight * currentRowData.outward_weight;
                    //         currentRowData.outward_weight = currentRowData.product_material_std_weight * parsedValue;
                    //         currentRowData.closing_balance_weight = currentRowData.stock_weight - currentRowData.outward_weight;
                    //     } else {
                    //         currentRowData.closing_balance_weight = 0;
                    //         currentRowData.outward_weight = 0;
                    //         currentRowData.closing_balance_weight = 0;
                    //     }

                    //     // Remove tooltip if no errors
                    //     delete focusedInputField.parentElement.dataset.tip;
                    // }
                } else {
                    currentRowData[clickedColName] = enteredValue;
                    focusedInputField.parentElement.dataset.tip = `Please Enter Quantity...!`;
                }
                break;



            case 'inward_quantity':
                if (keyForViewUpdate === "return") {
                    // Validate inward_quantity is not greater than outward_quantity
                    const outwardQuantity = parseFloat(currentRowData.outward_quantity || 0);
                    const inwardQuantity = parseFloat(enteredValue || 0);
                    delete focusedInputField.parentElement.dataset.tip;
                    if (inwardQuantity > outwardQuantity) {
                        focusedInputField.parentElement.dataset.tip = `Inward Quantity cannot be greater than Outward Quantity (${outwardQuantity})...!`;
                        return false; // Stop execution if validation fails
                    }
                    delete focusedInputField.parentElement.dataset.tip;
                    // If validation passes, update the current row data
                    currentRowData[clickedColName] = enteredValue;
                }
                break;
            case 'outward_weight':
            case 'inward_weight':
            case 'rate':
                {
                    currentRowData[clickedColName] = enteredValue;
                    break;
                }
            case 'remark': {
                currentRowData[clickedColName] = event.target.value;
                break;
            }
            case 'gate_pass_item_status': {
                currentRowData[clickedColName] = event.target.value;
                break;
            }
            default:
                return; // Exit if the column name doesn't match any case
        }

        // Update the material transfer details data
        setMaterialGatePassDetailsData((prevArr) => {
            const updatedArr = [...prevArr];
            updatedArr[arrayIndex] = currentRowData;
            return updatedArr;
        });
    };


    const deleteRecord = (Index, action) => {
        setMaterialGatePassDetailsData((prevDetailsData) => prevDetailsData.filter((data, i) => i !== Index))
    }

    //details table
    const renderDetailsTable = useMemo(() => {
        return <>
            <Table className="erp_table erp_table_scroll" id='gatepass-details-tbl' responsive bordered striped>
                <thead className="erp_table_head">
                    <tr>

                        {(keyForViewUpdate === "add" || keyForViewUpdate === "update") && <th className="erp_table_th">Action</th>}
                        <th className="erp_table_th">Sr.no</th>
                        <th className="erp_table_th">Material Code</th>
                        <th className="erp_table_th">Material Name</th>
                        <th className="erp_table_th">OutWard Quantity</th>
                        <th className="erp_table_th">OutWard Weight</th>
                        {(keyForViewUpdate === "return" || keyForViewUpdate === "view") && (
                            <>
                                <th className="erp_table_th">Inward Quantity</th>
                                <th className="erp_table_th">Inward Weight</th>
                            </>
                        )}
                        <th className="erp_table_th">Status</th>
                        <th className="erp_table_th">Rate</th>
                        <th className="erp_table_th">Remark</th>

                    </tr>
                </thead>
                <tbody>
                    {materialGatePassDetailsData.map((issueItem, Index) =>
                        <tr rowindex={Index} className="sticky-column">
                            {(keyForViewUpdate === "add" || keyForViewUpdate === "update") &&
                                <td className="erp_table_td" style={{ verticalAlign: "middle" }} ><Tooltip title="Delete" placement="top">
                                    <MDTypography className="erp-view-btn" >
                                        <MdDelete sty className={`erp-delete-btn`} style={{ display: "flex", margin: "auto" }} onClick={() => deleteRecord(Index)} />
                                    </MDTypography></Tooltip>
                                </td>}

                            <td className="erp_table_td text-end sticky-column">{Index + 1}</td>
                            <td className="erp_table_td "> {issueItem.product_code}</td>
                            <td className="erp_table_td "> {issueItem.product_material_name}</td>
                            <td className="erp_table_td text-end">
                                {keyForViewUpdate === 'add' || keyForViewUpdate === 'update' ?
                                    <input type="text" id={`outward_quantity_${issueItem.product_material_id}`} className="remove_input_zero erp_input_field text-end remove0 mb-0 issueField"
                                        value={issueItem.outward_quantity} onChange={(e) => { handleIssueDetailsInputChange(issueItem, e); }} Headers='outward_quantity' />
                                    :
                                    issueItem.outward_quantity
                                }
                            </td>
                            <td className="erp_table_td text-end">
                                {keyForViewUpdate === 'add' || keyForViewUpdate === 'update' ?
                                    <input type="text" id={`outward_weight_${issueItem.product_material_id}`} className="remove_input_zero erp_input_field text-end remove0 mb-0 issueField"
                                        value={issueItem.outward_weight} onChange={(e) => { handleIssueDetailsInputChange(issueItem, e); }} Headers='outward_weight' />
                                    :
                                    issueItem.outward_weight
                                }
                            </td>
                            {(keyForViewUpdate === "return" || keyForViewUpdate === "view") && (
                                <>
                                    <td className="erp_table_td text-end">
                                        {keyForViewUpdate === 'return' ?
                                            <input type="text" id={`inward_quantity_${issueItem.product_material_id}`} className="remove_input_zero erp_input_field text-end remove0 mb-0 issueField"
                                                value={issueItem.inward_quantity} onChange={(e) => { handleIssueDetailsInputChange(issueItem, e); }} Headers='inward_quantity' />
                                            :
                                            issueItem.inward_quantity
                                        }
                                    </td>
                                    <td className="erp_table_td text-end">
                                        {keyForViewUpdate === 'return' ?
                                            <input type="text" id={`inward_weight_${issueItem.product_material_id}`} className="remove_input_zero erp_input_field text-end remove0 mb-0 issueField"
                                                value={issueItem.inward_weight} onChange={(e) => { handleIssueDetailsInputChange(issueItem, e); }} Headers='inward_weight' />
                                            :
                                            issueItem.inward_weight
                                        }
                                    </td>
                                </>
                            )}
                            <td className="erp_table_td ">
                                <select rowIndex={`${issueItem}`} id={`gate_pass_item_status_${issueItem.product_material_id}`} disabled class="form-select form-select-sm erp_input_field_table_txt m-0"
                                    onChange={(e) => { handleIssueDetailsInputChange(issueItem, e); }} value={issueItem.gate_pass_item_status} >
                                    <option value="P">Pending</option>
                                    <option value="A">Approved</option>
                                    <option value="R">Return</option>
                                    <option value="I">Partial-Return</option>
                                </select>
                            </td>
                            <td className="erp_table_td text-end">
                                {keyForViewUpdate === 'add' || keyForViewUpdate === 'update' ?
                                    <input type="text" id={`rate_${issueItem.product_material_id}`} className="remove_input_zero erp_input_field text-end remove0 mb-0 issueField"
                                        value={issueItem.rate} onChange={(e) => { handleIssueDetailsInputChange(issueItem, e); }} Headers='rate' />
                                    :
                                    issueItem.rate
                                }
                            </td>
                            <td className="erp_table_td ">
                                {keyForViewUpdate === 'add' || keyForViewUpdate === 'update' ?
                                    <Form.Control as="textarea" id={`remark${issueItem.product_material_id}`} value={issueItem.remark}
                                        Headers="remark" onInput={(e) => { handleIssueDetailsInputChange(issueItem, e) }}
                                        className="erp_input_field mb-0" maxLength="1000" />
                                    :
                                    issueItem.remark
                                }
                            </td>

                        </tr>
                    )}
                </tbody>
            </Table>
        </>

    }, [materialGatePassDetailsData])

    //-------------------------------- validate before save ------------------------------------------------------

    const validateFields = async () => {
        debugger
        let prodTypeId = $('#cmb_product_type option:selected').val();
        if (prodTypeId === "" || prodTypeId === "0") {
            $("#error_cmb_product_type").text("Please fill this field...!");
            $("#error_cmb_product_type").show();
            $("#cmb_product_type").focus();
            return false;
        }
        else {
            validate.current.validateFieldsOnChange('gate_pass_form_id')
        }
    }

    const FnValidateGatePass = async () => {
        debugger
        let checkName = cmb_checker_by_name;
        let prodType = cmb_product_type;

        if (prodType === "" || prodType === "0") {
            $("#error_cmb_product_type").text("Please fill this field...!");
            $("#error_cmb_product_type").show();
            $("#cmb_product_type").focus();
            // productTypeComboRef.current.focus()
            return;
        }
        if (checkName === "" || checkName === "0") {
            $("#error_cmb_checker_by_id").text("Please fill this field...!");
            $("#error_cmb_checker_by_id").show();
            $("#cmb_checker_by_id").focus();
            // empCheckerComboRef.current.focus()
            return;
        }

        let headerDataIsValid = await validate.current.validateForm("gate_pass_form_id");
        if (!headerDataIsValid) { return false; }
        let issueDetailsIsValid = true;
        let addedIssuematerial = $('#gatepass-details-tbl tbody tr').length;
        if (addedIssuematerial <= 0 && keyForViewUpdate !== 'view') {
            setErrMsg('Please Add Atleast One Material Detail.');
            setShowErrorMsgModal(true);
            return issueDetailsIsValid = false;
        }
        else {
            const tableRows = $('#gatepass-details-tbl tbody tr');
            issueDetailsIsValid = true;
            tableRows.each(function () {
                if (keyForViewUpdate === "add") {
                    const issueQty = parseFloat($(this).find('input[id^="outward_quantity_"]').val()) || 0;
                    if (isNaN(issueQty) || issueQty === "" || issueQty === "0" || issueQty === 0) {
                        $(this).find(`input[id^='outward_quantity_']`).parent().attr('data-tip', 'Quantity cannot be 0...!');
                        $(this).find(`input[id^='outward_quantity_']`).focus();
                        setOpenIssueDetailsAccord(true)
                        issueDetailsIsValid = false;
                        return false;
                    }
                }
            });
            return issueDetailsIsValid;
        }
    }

    //-------------------------- Save Json ------------------------------------------------------------------

    const addMaterialGatePass = async () => {
        try {
            debugger
            let checkIsValidate = true;
            if (keyForViewUpdate !== "view" && keyForViewUpdate !== "delete") {
                // let validateSearchableCombos = await FnChkIsValidFilters();
                let validateSelectBoxes = await FnValidateGatePass();
                checkIsValidate = validateSelectBoxes
            }
            if (checkIsValidate === true) {
                setIsLoading(true)
                let lastGatePassNo = txt_gate_pass_no

                let json = { 'TransMaterialGatePassMasterDetails': {}, 'TransMaterialGatePassDetails': [], 'commonIds': {} }

                const transferMasterFormData = {
                    material_gate_pass_master_id: keyForViewUpdate !== "add" ? material_gate_pass_master_id : 0,
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    gate_pass_no: lastGatePassNo,
                    checker_by_id: cmb_checker_by_id,
                    financial_year: FINANCIAL_SHORT_NAME,
                    gate_pass_date: dt_gate_pass_date,
                    vehicle_no: txt_vehicle_no,
                    product_type_name: cmb_product_type_name,
                    product_type_id: cmb_product_type,
                    material_type: material_type,
                    service_provider_name: txt_service_provider_name,
                    service_provider_add: txt_service_provider_add,
                    checker_by_name: empCheckerComboRef.current.employee_name ?? UserName,
                    // approved_by_id: cmb_approved_by_id,
                    // approved_by_name: empApprovedComboRef.current.employee_name ?? UserName,
                    approved_by_id: keyForViewUpdate === "add" || keyForViewUpdate === "update"
                        ? null
                        : cmb_approved_by_id,
                    approved_by_name: keyForViewUpdate === "add" || keyForViewUpdate === "update"
                        ? null
                        : (empApprovedComboRef.current?.employee_name ?? UserName),
                    gate_pass_status: cmb_gate_pass_status,
                    created_by: UserName,
                    created_on: todayDate(),
                    modified_by: UserName,
                    modified_on: todayDate(),
                    modified_by: idList !== "" ? UserName : null,

                }
                json.TransMaterialGatePassMasterDetails = transferMasterFormData;

                for (let issueItem = 0; issueItem < materialGatePassDetailsData.length; issueItem++) {
                    const gatePassDetailsRow = materialGatePassDetailsData[issueItem];

                    const MaterialtransferRow = {};
                    MaterialtransferRow['material_gate_pass_details_id'] = keyForViewUpdate !== "add" ? gatePassDetailsRow.material_gate_pass_details_id : 0
                    MaterialtransferRow['material_gate_pass_master_id'] = keyForViewUpdate !== "add" ? material_gate_pass_master_id : 0
                    MaterialtransferRow['company_id'] = COMPANY_ID
                    MaterialtransferRow['company_branch_id'] = COMPANY_BRANCH_ID
                    MaterialtransferRow['financial_year'] = FINANCIAL_SHORT_NAME
                    MaterialtransferRow['gate_pass_no'] = lastGatePassNo
                    MaterialtransferRow['gate_pass_date'] = dt_gate_pass_date
                    MaterialtransferRow['product_material_id'] = gatePassDetailsRow.product_material_id
                    MaterialtransferRow['material_code'] = gatePassDetailsRow.product_code
                    MaterialtransferRow['material_name'] = gatePassDetailsRow.product_material_name
                    MaterialtransferRow['outward_quantity'] = gatePassDetailsRow.outward_quantity
                    MaterialtransferRow['outward_weight'] = gatePassDetailsRow.outward_weight
                    MaterialtransferRow['inward_quantity'] = gatePassDetailsRow.inward_quantity
                    MaterialtransferRow['inward_weight'] = gatePassDetailsRow.inward_weight
                    MaterialtransferRow['gate_pass_item_status'] = gatePassDetailsRow.gate_pass_item_status
                    MaterialtransferRow['product_type_id'] = cmb_product_type
                    MaterialtransferRow['product_type_name'] = cmb_product_type_name
                    MaterialtransferRow['rate'] = gatePassDetailsRow.rate
                    MaterialtransferRow['remark'] = gatePassDetailsRow.remark
                    MaterialtransferRow['created_by'] = UserName
                    MaterialtransferRow['created_on'] = todayDate()
                    MaterialtransferRow['modified_by'] = UserName
                    MaterialtransferRow['modified_on'] = todayDate()

                    json.TransMaterialGatePassDetails.push(MaterialtransferRow);
                }
                // Common Ids
                debugger
                json.commonIds.company_id = COMPANY_ID
                json.commonIds.keyForViewUpdate = keyForViewUpdate
                json.commonIds.transfer_no = keyForViewUpdate === 'add' ? lastGatePassNo : txt_gate_pass_no
                json.commonIds.financial_year = FINANCIAL_SHORT_NAME
                const formData = new FormData();
                formData.append(`MaterialGatePassData`, JSON.stringify(json))
                const requestOptions = {
                    method: 'POST',
                    body: formData
                };


                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MaterialGatePass/FnAddUpdateRecord`, requestOptions);
                const responce = await apicall.json();

                console.log("response error: ", responce.data);
                if (responce.error === 0) {
                    console.log("response error: ", responce.error);
                    setErrMsg(responce.error)
                    setShowErrorMsgModal(true)

                } else {
                    setSuccMsg(responce.message);
                    setShowSuccessMsgModal(true);
                }
                setIsLoading(false)
            }
        } catch (error) {
            console.log("error: ", error)
        } finally {
            setIsLoading(false)
        }

    }
    // ------------------------------------------- set update data ----------------------------------------------------


    const FnCheckUpdateResponse = async () => {
        debugger
        try {
            if (idList !== '') {
                setMaterialGatePassNo(idList.gate_pass_no)
                const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MaterialGatePass/FnShowParticularRecord/${parseInt(idList.material_gate_pass_master_id)}/${COMPANY_ID}`);
                const responce = await apiCall.json();
                console.log('Material gate pass  data: ', responce);

                let gatePassMasterdata = responce.MaterialGatePassMasterDetails;
                if (gatePassMasterdata !== null && gatePassMasterdata !== "") {
                    setMaterialGatePassNo(gatePassMasterdata.gate_pass_no)
                    setProductType(gatePassMasterdata.product_type_id)
                    // productTypeComboRef.current.field_name = gatePassMasterdata.product_type_name
                    setServiceProviderName(gatePassMasterdata.service_provider_name)
                    setGatePassDate(gatePassMasterdata.gate_pass_date)
                    setMaterialType(gatePassMasterdata.material_type)
                    setServiceProviderAdd(gatePassMasterdata.service_provider_add)
                    let gatePassStatus = '';

                    switch (keyForViewUpdate) {
                        case 'return':
                            gatePassStatus = 'R'
                            if (gatePassMasterdata.gate_pass_status === "I") {
                                gatePassStatus = 'I'
                            }
                            break;
                        case 'approve':
                            gatePassStatus = 'A'
                            break;

                        default:
                            gatePassStatus = gatePassMasterdata.gate_pass_status
                            break;
                    }
                    if (gatePassMasterdata.gate_pass_status === "A") {
                        comboOnChange('ApprovedBy');
                        setApprovedById(gatePassMasterdata.approved_by_id)
                        empApprovedComboRef.current.employee_id = gatePassMasterdata.approved_by_id
                        empApprovedComboRef.current.employee_name = gatePassMasterdata.approved_by_name
                        setApprovedByName(gatePassMasterdata.approved_by_name)
                        setApproveDate(gatePassMasterdata.approved_date)
                    }
                    setGatePassStatus(gatePassStatus)
                    comboOnChange('CheckerBy');
                    setCheckerById(gatePassMasterdata.checker_by_id)
                    setCheckerByName(gatePassMasterdata.checker_by_name)
                    empCheckerComboRef.current.employee_id = gatePassMasterdata.checker_by_id
                    empCheckerComboRef.current.employee_name = gatePassMasterdata.checker_by_name
                    setVehicleNo(gatePassMasterdata.vehicle_no)
                }
                // if (responce.gatePassDetailsData !== null && responce.gatePassDetailsData !== "") {
                //     setMaterialGatePassDetailsData(responce.gatePassDetailsData)
                // }
                if (responce.MaterialGatePassDetails !== null && responce.MaterialGatePassDetails !== "") {
                    const transformedData = responce.MaterialGatePassDetails.map((item) => ({
                        ...item,
                        product_code: item.material_code,
                        product_material_name: item.material_name,
                        gate_pass_item_status: keyForViewUpdate === "approve" ? "A" : item.gate_pass_item_status,

                    }));
                    setMaterialGatePassDetailsData(transformedData);
                }

            }
        } catch (error) {
            console.log(error);
        }
    }






    // ------------------------ Changes for the Searchable Combos. --------------------------

    return (
        <>
            <ComboBox ref={comboDataAPiCall} />
            <ExcelExport ref={exlsExp} />
            <GenerateTAutoNo ref={generateAutoNoAPiCall} />
            <FrmValidations ref={validate} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />
            <DashboardLayout>
                <>
                    {isLoading ?
                        <div className="spinner-overlay"  >
                            <div className="spinner-container">
                                <CircularProgress color="primary" />
                                <span>Loading...</span>
                            </div>
                        </div> :
                        ''}

                    <div className='card p-1'>
                        <div className='card-header text-center py-0'>
                            <label className='erp-form-label-lg main_heding'> Material Gate Pass{actionType}</label>
                        </div>
                        <form id="gate_pass_form_id">
                            <div className="row p-1">
                                {/* //first column */}
                                <div className="col-sm-6 erp_form_col_div">
                                    <div className='row'>
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label">Product Type<span className="required">*</span> </Form.Label>
                                        </div>

                                        <div className='col-sm-8 col-12'>
                                            <Form.Control as="select"
                                                id='cmb_product_type'
                                                className="erp_input_field"
                                                value={cmb_product_type}
                                                Disabled={keyForViewUpdate !== "add" ? true : false}
                                                style={{ paddingBottom: "0px" }}
                                                onChange={(e) => {
                                                    setProductType(e.target.value)
                                                    comboOnChange("cmb_product_type")
                                                    validateFields();
                                                }}>
                                                {productTypeOptions.map((typeOpt) => <option shortName={typeOpt.product_type_short_name} value={typeOpt.value}>{typeOpt.label}</option>)}
                                            </Form.Control>
                                            <MDTypography variant="button" id="error_cmb_product_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>

                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label">Gate Pass No& Date<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col-sm-8 col-12'>
                                            <div className="row">
                                                <div className='col-12 col-md-6 pe-md-0'>
                                                    <Form.Control type="text" id="txt_gate_pass_no" className="erp_input_field" value={txt_gate_pass_no} optional='optional' disabled />
                                                </div>

                                                <div className="col-12 col-md-6 pt-md-0 pt-3">
                                                    <Form.Control type="date" id='dt_gate_pass_date'
                                                        className="erp_input_field" value={dt_gate_pass_date}
                                                        onChange={e => {
                                                            setGatePassDate(e.target.value);
                                                            validateFields();
                                                        }}
                                                        min={currentDate}
                                                        Disabled={keyForViewUpdate === "add" || keyForViewUpdate === "update" ? false : true}
                                                    />
                                                    <MDTypography variant="button" id="error_dt_gate_pass_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4 col-12">
                                            <Form.Label className="erp-form-label"> Material Type<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className="col-sm-8 col-12">
                                            <div className="erp_form_radio">
                                                <div className="fCheck">
                                                    <Form.Check className="erp_radio_button" label="Returnable" type="radio" value="R" name="leaveType"
                                                        checked={material_type === 'R'} defaultChecked onClick={() => { setMaterialType('R'); }} />
                                                </div>
                                                <div className="sCheck">
                                                    <Form.Check className="erp_radio_button" label="Non-Returnable" value="NR" type="radio" name="leaveType"
                                                        checked={material_type === 'NR'} onClick={() => { setMaterialType('NR'); }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label">Checker Name<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col-sm-8 col-12 mb-1'>
                                            <Select ref={empCheckerComboRef}
                                                options={employeeOptions}
                                                isDisabled={!["add","update"].includes(keyForViewUpdate)}
                                                inputId="cmb_checker_by_id" // Provide the ID for the input box
                                                value={employeeOptions.find(option => option.value == cmb_checker_by_id)}
                                                onChange={(selectedOpt) => {
                                                    setCheckerById(selectedOpt.value);
                                                    empCheckerComboRef.current = selectedOpt;
                                                    validateFields();
                                                    comboOnChange('CheckerBy');
                                                }}
                                                placeholder="Search for a employee..."
                                                className="form-search-custom"
                                                classNamePrefix="custom-select"
                                                styles={{
                                                    option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                    singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                    input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                                }}
                                            />

                                            <MDTypography variant="button" id="error_cmb_checker_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>

                                    </div>


                                    {!(keyForViewUpdate === "add" || keyForViewUpdate === "update") &&
                                        <div className="row">
                                            <div className='col-sm-4 col-12'>
                                                <Form.Label className="erp-form-label">Approve Date<span className="required">*</span></Form.Label>
                                            </div>
                                            <div className="col-sm-8 col-12">
                                                <Form.Control type="date" id='dt_gate_pass_date'
                                                    className="erp_input_field" value={dt_approve_date}
                                                    Disabled={true}
                                                />
                                                <MDTypography variant="button" id="error_dt_gate_pass_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>}
                                </div>

                                {/* //second column */}
                                <div className="col-sm-6 erp_form_col_div">
                                    <div className='row'>
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label">Vehicle No.</Form.Label>
                                        </div>
                                        <div className="col-sm-8 col-10">
                                            <Form.Control type="text" id='txt_vehicle_no' className="erp_input_field" value={txt_vehicle_no} onChange={(e) => { setVehicleNo(e.target.value); validateFields(); }} optional="optional" maxlength="500" />
                                            <MDTypography variant="button" id="error_txt_vehicle_no" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>

                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label">M/S</Form.Label>
                                        </div>
                                        <div className="col-sm-8 col-10">
                                            <Form.Control type="text" id='txt_service_provider_name' className="erp_input_field" value={txt_service_provider_name} onChange={(e) => { setServiceProviderName(e.target.value); validateFields(); }} optional="optional" maxlength="500" />
                                            <MDTypography variant="button" id="error_txt_service_provider_name" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label">Address</Form.Label>
                                        </div>
                                        <div className="col-sm-8 col-10">

                                            <Form.Control type="text" id='txt_service_provider_add' className="erp_input_field" value={txt_service_provider_add} onChange={(e) => { setServiceProviderAdd(e.target.value); validateFields(); }} optional="optional" maxlength="500" />
                                            <MDTypography variant="button" id="error_txt_service_provider_add" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>

                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-4 col-12'>
                                            <Form.Label className="erp-form-label">Gate Pass Status</Form.Label>
                                        </div>
                                        <div className="col-sm-8 col-10">
                                            <Form.Control as="select"
                                                id='cmb_gate_pass_status'
                                                className="erp_input_field"
                                                value={cmb_gate_pass_status}
                                                Disabled='true'
                                                style={{ paddingBottom: "0px" }}
                                                onChange={(e) => setGatePassStatus(e.target.value)}>
                                                <option value="P">Pending</option>
                                                <option value="A">Approved</option>
                                                <option value="I">Partial-Return</option>
                                                <option value="R">Return</option>
                                            </Form.Control>
                                            <MDTypography
                                                variant="button"
                                                id="error_cmb_gate_pass_status"
                                                className="erp_validation error-msg"
                                                fontWeight="regular"
                                                color="error"
                                                style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    {!(keyForViewUpdate === "add" || keyForViewUpdate === "update") && (
                                        <div className='row'>
                                            <div className='col-sm-4 col-12'>
                                                <Form.Label className="erp-form-label">Approved By<span className="required">*</span></Form.Label>
                                            </div>
                                            <div className='col-sm-8 col-10 mb-1'>
                                                <Select ref={empApprovedComboRef}
                                                    options={employeeOptions}
                                                    isDisabled={!['add'].includes(keyForViewUpdate)}
                                                    inputId="cmb_approved_by_id" // Provide the ID for the input box
                                                    value={employeeOptions.find(option => option.value == cmb_approved_by_id)}
                                                    onChange={(selectedOpt) => {
                                                        setApprovedById(selectedOpt.value);
                                                        empApprovedComboRef.current = selectedOpt;
                                                        validateFields();
                                                        comboOnChange('ApprovedBy');
                                                    }}
                                                    placeholder="Search for a employee..."
                                                    className="form-search-custom"
                                                    classNamePrefix="custom-select"
                                                    styles={{
                                                        option: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                        singleValue: (provided, state) => ({ ...provided, fontSize: '12px' }),
                                                        input: (provided, state) => ({ ...provided, fontSize: '12px' })
                                                    }}
                                                />
                                                <MDTypography variant="button" id="error_cmb_approved_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>

                                        </div>
                                    )}


                                </div>
                            </div>
                        </form>
                    </div>
                    <hr />

                    <Accordion defaultActiveKey="0" activeKey={openIssueDetailsAccord ? '0' : null}>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header onClick={(e) => setOpenIssueDetailsAccord(!openIssueDetailsAccord)} className="erp-form-label-md"> Material Details</Accordion.Header>
                            <Accordion.Body>
                                <div className={`row ${keyForViewUpdate === "add" || keyForViewUpdate === "update" ? 'display' : 'd-none'}`}>
                                    <div className="col-sm-4 col-12">
                                        <div className={`row`}>
                                            <div className="col-md-8 col-12">
                                                <SearchItemFilter getSearchData={fnSearchMaterialData} onChange={validateFields} formIdForValidate="gate_pass_form_id" requiredCols={requiredCols} company_id={COMPANY_ID} product_type_id={cmb_product_type} godownId={"2"} />
                                                <MDTypography variant="button" id="error_txt_product_material_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="erp-Mt-10">
                                    {materialGatePassDetailsData.length !== 0 ?
                                        <>
                                            {renderDetailsTable}
                                        </> :
                                        <div className='row  text-center'>
                                            <div className="col-12 ">
                                                <span className="erp_validation text-center" fontWeight="regular" color="error"> No Records Found... </span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion >


                </>

                <div className="text-center mb-5">
                    <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => moveToListing()}>Back</MDButton>
                    <MDButton type="button" id="save-btn" className={`erp-gb-button ms-2 ${keyForViewUpdate != 'view' ? 'display' : 'd-none'}`} variant="button" onClick={() => { addMaterialGatePass() }}
                        fontWeight="regular"> {actionLabel}</MDButton>
                    {/* <MDButton type="button" variant="button" onClick={() => { addMaterialGatePass('MI') }} id="issue-btn"
                        className={`erp-gb-button erp_MLeft_btn ${keyForViewUpdate === 'issue' ? 'display' : 'd-none'}`} fontWeight="regular" >Issue</MDButton>
                    <MDButton type="button" variant="button" onClick={() => { addMaterialGatePass('R') }} id="return-btn"
                        className={`erp-gb-button erp_MLeft_btn ${keyForViewUpdate === 'return' ? 'display' : 'd-none'}`} fontWeight="regular" >Return</MDButton> */}

                </div >

                <SuccessModal handleCloseSuccessModal={() => closeConfirmationModal()} show={[showSuccessMsgModal, succMsg, modalOrderDetails]} />
                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg, modalOrderDetails]} />

                {/* <Modal size="lg" show={showAddRecIndentIssueModal} onHide={closeRecordAddIndentIssueModal} backdrop="static" keyboard={false} centered >
                    <Modal.Body className='erp_city_modal_body'>
                        <div className='row'>
                            <div className='col-12 align-self-end'><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={closeRecordAddIndentIssueModal}></button></div>
                        </div>
                        {displayRecordIndentIssueComponent()}

                    </Modal.Body>
                </Modal > */}

                {/* Delete Modal */}
                {/* <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
                    <span><button type="button" class="erp-modal-close btn-close" aria-label="Close" onClick={handleClose}></button></span>
                    <Modal.Body className='erp_modal_body'>
                        <span className='erp_modal_delete_icon'><RxCrossCircled /></span>
                        <h6>Are you sure?</h6>
                        <div className="erp-form-label">Do you wish to delete this record ?</div>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-center'>
                        <Button variant="success" className='erp-gb-button' onClick={handleClose}>
                            Cancel
                        </Button>&nbsp;
                        <Button variant="danger" className='erp-gb-button' onClick={deleteRecords}>Delete</Button>
                    </Modal.Footer>
                </Modal> */}

            </DashboardLayout >
        </>
    )
}

export default FrmMaterialGatePassEntry;