import React from 'react';
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'
import ConfigConstants from "assets/Constants/config-constant";

const PurchaseRequisition = React.forwardRef(({ invoiceContent }, ref) => {
    // const configConstants = ConfigConstants();
    // const { AFTER_DECIMAL_PLACES } = configConstants;
    let AFTER_DECIMAL_PLACES = 2;


    const data = invoiceContent.items
    function numberToWordsWithCurrency(number) {
        // Check if the input number is NA, null, or an empty string
        if (number === null || number === "NA" || number === "" || number === undefined) {
            return "-";
        }

        const ones = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
        const tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];

        // Function to convert the integer part of the number to words
        function convertIntegerToWords(integer) {
            if (integer === 0) {
                return "";
            }

            if (integer < 20) {
                return ones[integer];
            }

            if (integer < 100) {
                const ten = Math.floor(integer / 10);
                const remainder = integer % 10;
                return tens[ten] + (remainder > 0 ? ` ${ones[remainder]}` : "");
            }

            if (integer < 1000) {
                const hundred = Math.floor(integer / 100);
                const remainder = integer % 100;
                return ones[hundred] + " Hundred" + (remainder > 0 ? ` and ${convertIntegerToWords(remainder)}` : "");
            }

            if (integer < 1000000) {
                const thousand = Math.floor(integer / 1000);
                const remainder = integer % 1000;
                return convertIntegerToWords(thousand) + " Thousand" + (remainder > 0 ? ` ${convertIntegerToWords(remainder)}` : "");
            }
            if (integer < 1000000000) {
                const million = Math.floor(integer / 1000000);
                const remainder = integer % 1000000;
                return convertIntegerToWords(million) + " Million" + (remainder > 0 ? ` ${convertIntegerToWords(remainder)}` : "");
            }

            return "Number too large to convert";
        }

        // Function to convert the decimal part of the number to words
        function convertDecimalToWords(decimal) {
            const tensValue = Math.floor(decimal / 10);
            const onesValue = decimal % 10;
            let words = "";

            if (tensValue > 0) {
                words += tens[tensValue];
            }

            if (onesValue > 0) {
                words += ` ${ones[onesValue]}`;
            }

            return words;
        }

        // Split the number into integer and decimal parts
        const integerPart = Math.floor(number);
        const decimalPart = Math.round((number - integerPart) * 100); // Convert the decimal to an integer

        // Convert the integer part to words
        let words = convertIntegerToWords(integerPart);

        // Add currency
        if (words) {
            words += " Rupees";
        }

        // Add the decimal part to the words
        if (decimalPart > 0) {
            words += ` and ${convertDecimalToWords(decimalPart)} Paise`;
        }

        return words || "Zero Rupees"; // If the input is 0, return "Zero Rupees"
    }


    const StockQuantity = invoiceContent.footer.sumofStockQuantity;
    const IndentQuantity = invoiceContent.footer.sumIndentQuantity;
    const ApprovedQuantity = invoiceContent.footer.sumofApprovedQuantity;

    console.log("StockQuantity: ", StockQuantity);
    console.log("IndentQuantity: ", IndentQuantity);
    console.log("ApprovedQuantity: ", ApprovedQuantity);

    // const StockQuantityInWords = numberToWordsWithCurrency(StockQuantity);
    // const IndentQuantityInWords = numberToWordsWithCurrency(IndentQuantity);
    // const ApprovedQuantityInWords = numberToWordsWithCurrency(ApprovedQuantity);



    const invoiceFooter = (
        <div className="invoice-footer">
            <div className='row mt-4'>
                <div className='col'>
                    <div className='align-bottom-center'>
                        <dt className="erp-invoice-print-label-md-lg">{invoiceContent.footer.indented_by_name}</dt>
                    </div>
                </div>

                <div className='col '> {/* This offsets the third column */}
                    <div className='align-bottom-center'>
                        <dt className="erp-invoice-print-label-md-lg">{invoiceContent.footer.approved_by_name}</dt>
                    </div>
                </div>

                <div className='col'>
                    <div className='align-bottom-center'>
                        <dt className="erp-invoice-print-label-md-lg"> </dt>
                    </div>
                </div>

                <div className='col'>
                    <div className='align-bottom-center'>
                        <dt className="erp-invoice-print-label-md-lg"> </dt>
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col'>
                    <div className='text-center'>
                        <dt className="erp-invoice-print-label-md-lg">Requested By</dt>
                    </div>
                </div>

                <div className='col '> {/* This offsets the third column */}
                    <div className='text-center'>
                        <dt className="erp-invoice-print-label-md-lg">Approved By</dt>
                    </div>
                </div>

                <div className='col'>
                    <div className='text-center'>
                        <dt className="erp-invoice-print-label-md-lg">Issued By</dt>
                    </div>
                </div>

                <div className='col'>
                    <div className='text-center'>
                        <dt className="erp-invoice-print-label-md-lg">Store Manager</dt>
                    </div>
                </div>
            </div>

        </div>
    );

    return (
        <>
            {/* <div className="container"> */}

            <div className="row">
                <div className="col-12">
                    <div className="container-invoice">
                        <div id="content">
                            <div className="invoice p-0">

                                {/* <!--BILL HEADER--> */}
                                <div className="row" style={{ padding: '0px 15px 0px' }}>
                                    <div className='bordered border-bottom-0 px-0'>
                                        <div className='row mb-0 p-1'>
                                            <div className="col-sm-2">
                                                <img src={DakshabhiLogo} alt="master card" width="150" height="auto" mt={1} />
                                            </div>
                                            <div className="col-sm-8">
                                                <div className='erp-invoice-print-label text-center'>
                                                    <span className='erp-invoice-print-label-lg'>{invoiceContent.company.company}</span><br />
                                                    <span className='erp-invoice-print-label-md'>({invoiceContent.company.company_branch})</span>
                                                </div>
                                                <div className='erp-invoice-print-label-lg text-center mb-0'>
                                                    Purchase Indent
                                                </div>
                                            </div>
                                            <div className="col-sm-2 pt-3 ">
                                                <span className='erp-invoice-print-label-md me-1 '>Department Copy</span><br />
                                            </div>
                                        </div>

                                        <div className='row border-top border-dark p-0 m-0'>
                                            <div className='col-sm-6'>
                                                <dt className='erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}> Indent No. & version : {invoiceContent.indentdetails.indent_no} & {invoiceContent.indentdetails.indent_version}</dt>
                                            </div>

                                            <div className='col-sm-6'>
                                                <dt className='erp-invoice-print-label-md-lg text-end' style={{ fontSize: '10px' }}> Indent Date : {invoiceContent.indentdetails.indent_date}</dt>
                                            </div>
                                        </div>
                                        <div className='row border-top border-dark p-0 m-0'>
                                            <div className="row p-0 m-0">

                                                <div className="col-sm-12">
                                                    <dl className="row mb-0 pb-0">
                                                        <dl className="col-sm-6 mb-0">
                                                            <dl className="row mb-0 pb-0">

                                                                <dt className='col-sm-4 mt-1 erp-invoice-print-label-md-lg'>Indent Type Group :</dt>
                                                                <dd className='col-sm-8 mt-1 erp-invoice-print-label-md-lg'> {invoiceContent.header.indent_source}</dd>

                                                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Indent By :</dt>
                                                                <dd className='col-sm-8 erp-invoice-print-label-md-lg'> {invoiceContent.header.indented_by}</dd>

                                                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Department :</dt>
                                                                <dd className='col-sm-8 erp-invoice-print-label-md-lg'> {invoiceContent.header.department_name}</dd>


                                                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Approved By :</dt>
                                                                <dd className='col-sm-8 erp-invoice-print-label-md-lg'> {invoiceContent.header.approved_by_name}({invoiceContent.header.approved_date}) </dd>

                                                                {/* <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Approved Date :</dt>
                                                                <dd className='col-sm-8 erp-invoice-print-label-md-lg'> {invoiceContent.header.approved_date}</dd> */}

                                                                {/* {
                                                                    invoiceContent.header.indent_source !== 'Direct' ? <>

                                                                        <div class="row p-0 m-0">
                                                                            <dl class="row m-0 p-0">
                                                                                <dt class="col-sm-4  erp-invoice-print-label-md-lg">Customer :</dt>
                                                                                <dd class="col-sm-8 erp-invoice-print-label-md-lg">{invoiceContent.customerDetails.customer}</dd>
                                                                            </dl>
                                                                        </div>
                                                                    </> : null
                                                                } */}
                                                            </dl>
                                                        </dl>
                                                        <dl className="col-sm-6 mb-0">
                                                            <dl className="row mb-0 pb-0">

                                                                {
                                                                    invoiceContent.header.indent_source !== 'Direct' ? <>
                                                                        <div class="row p-0 m-0">
                                                                            <dt class="col-sm-4 mt-1 erp-invoice-print-label-md-lg">Customer :</dt>
                                                                            <dd class="col-sm-8 mt-1 erp-invoice-print-label-md-lg">{invoiceContent.customerDetails.customer}</dd>

                                                                            <dt class="col-sm-4 erp-invoice-print-label-md-lg">Cust Order No. &amp; Date :</dt>
                                                                            <dd class="col-sm-8 erp-invoice-print-label-md-lg">{invoiceContent.header.customer_order_no} &nbsp; &nbsp;&nbsp;{invoiceContent.header.customer_order_date}</dd>
                                                                        </div>
                                                                    </> : null
                                                                }


                                                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Approved Remark :</dt>
                                                                <dd className='col-sm-8 erp-invoice-print-label-md-lg'> {invoiceContent.header.remark}</dd>
                                                            </dl>
                                                        </dl>
                                                    </dl>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>

                                {/* <!--PARCEL DETAILS--> */}
                                <div className="row" style={{ padding: '0px 15px 15px' }}>
                                    <div className="col-12 ">
                                        <div className="row">
                                            <div className="col-12 px-0" >
                                                <div className="row p-0">
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered border border-dark m-0 border-end-0 border-start-0" id='invoiceTable' >
                                                            <thead className='erp_table_border'>
                                                                <tr>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2} >Sr No.</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2} >Material Code</th>
                                                                    <th className="erp_invoice_table_th text-center" style={{ whiteSpace: 'normal', width: '130px' }} rowspan={2}>Raw Material Name(FG Name,Technical Spect.,Oem Part Code,
                                                                        <br /> Our Part Code,Make Name, Grade Name )</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Stock Qty.</th>
                                                                    {/* <th className="erp_invoice_table_th text-center" rowspan={2}>Lead time (Days)</th> */}
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2} style={{ whiteSpace: 'normal', width: '80px' }}>
                                                                        Lead Time <br /> (Days)
                                                                    </th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Unit</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Indent Qty.</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Approved Qty.</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Rejected Qty.</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody id='detailstableId'>
                                                                {
                                                                    data.map((item, index) =>
                                                                        <>
                                                                            <tr className={`tblBodyTrHt-${index}`}>
                                                                                <td className="erp_invoice_table_td text-center">{item.sr_no}</td>
                                                                                <td className="erp_invoice_table_td text-center">{item.product_material_code}</td>
                                                                                {/* <td className="erp_invoice_table_td" style={{ width: '40%' }}>{item.product_rm_name}<span>({item.product_parent_fg_name},{item.material_tech_spect},{item.product_rm_oem_part_code},{item.product_rm_our_part_code},{item.product_make_name},{item.product_material_grade_name})</span></td> */}
                                                                                <td className="erp_invoice_table_td" style={{ width: '40%' }}>{item.product_rm_name}
                                                                                    <span>
                                                                                        {
                                                                                            item.product_parent_fg_name === '' && item.material_tech_spect === '' && item.product_rm_oem_part_code === '' && item.product_rm_our_part_code === '' && item.product_make_name === '' && item.product_material_grade_name === ''
                                                                                                ? ''
                                                                                                : <>
                                                                                                    ({
                                                                                                        [item.product_parent_fg_name, item.material_tech_spect, item.product_rm_oem_part_code, item.product_rm_our_part_code, item.product_make_name, item.product_material_grade_name].filter(str => str !== "" && str !== "NA" && str !== null && str !== undefined).join(", ")})
                                                                                                </>
                                                                                        }
                                                                                    </span>
                                                                                </td>
                                                                                <td className="erp_invoice_table_td text-end">{item.product_material_stock_quantity}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.lead_time}</td>
                                                                                <td className="erp_invoice_table_td">{item.unit}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.indent_quantity}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.product_material_approved_quantity}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.product_material_rejected_quantity}</td>
                                                                            </tr>

                                                                            {
                                                                                data.length - 1 === index ? <>
                                                                                    <tr id='footerTr'>
                                                                                        <td className="erp_invoice_table_th">Total</td>
                                                                                        <td className="erp_invoice_table_td text-end"></td>
                                                                                        <td className="erp_invoice_table_td text-end">{parseFloat(invoiceContent.footer.sumofStockQuantity).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                                                        <td className="erp_invoice_table_td text-end"></td>
                                                                                        <td className="erp_invoice_table_td text-end"></td>
                                                                                        <td className="erp_invoice_table_td text-end">{parseFloat(invoiceContent.footer.sumIndentQuantity).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                                                        <td className="erp_invoice_table_td text-end">{parseFloat(invoiceContent.footer.sumofApprovedQuantity).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                                                        <td className="erp_invoice_table_td text-end">{parseFloat(invoiceContent.footer.sumofRejectedQuantity).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td colSpan={12}>
                                                                                            {/* Your invoice footer content */}
                                                                                            {invoiceFooter}
                                                                                        </td>
                                                                                    </tr>

                                                                                </> : null
                                                                            }

                                                                        </>
                                                                    )
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="col-12">
                    <div className="container-invoice">
                        <div id="content">
                            <div className="invoice p-0">
                                {/* <!--BILL HEADER--> */}
                                <div className="row" style={{ padding: '0px 15px 0px' }}>
                                    <div className='bordered border-bottom-0 px-0'>
                                        <div className='row mb-0 p-1'>
                                            <div className="col-sm-2">
                                                <img src={DakshabhiLogo} alt="master card" width="150" height="auto" mt={1} />
                                            </div>
                                            <div className="col-sm-8">
                                                <div className='erp-invoice-print-label text-center'>
                                                    <span className='erp-invoice-print-label-lg'>{invoiceContent.company.company}</span><br />
                                                    <span className='erp-invoice-print-label-md'>({invoiceContent.company.company_branch})</span>
                                                </div>
                                                <div className='erp-invoice-print-label-lg text-center'>
                                                    Purchase Indent
                                                </div>
                                            </div>
                                            <div className="col-sm-2 pt-3 ">
                                                <span className='erp-invoice-print-label-md ps-4'>Store Copy</span><br />
                                            </div>
                                        </div>

                                        <div className='row border-top border-dark p-0 m-0'>
                                            <div className='col-sm-6'>
                                                <dt className='erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}> Indent No. & version : {invoiceContent.indentdetails.indent_no} & {invoiceContent.indentdetails.indent_version}</dt>
                                            </div>

                                            <div className='col-sm-6'>
                                                <dt className='erp-invoice-print-label-md-lg text-end' style={{ fontSize: '10px' }}> Indent Date : {invoiceContent.indentdetails.indent_date}</dt>
                                            </div>
                                        </div>
                                        <div className='row border-top border-dark p-0 m-0'>
                                            <div className="row p-0 m-0">

                                                <div className="col-sm-12">
                                                    <dl className="row mb-0 pb-0">
                                                        <dl className="col-sm-6 mb-0">
                                                            <dl className="row mb-0 pb-0">

                                                                <dt className='col-sm-4 mt-1 erp-invoice-print-label-md-lg'>Indent Type Group :</dt>
                                                                <dd className='col-sm-8 mt-1 erp-invoice-print-label-md-lg'> {invoiceContent.header.indent_source}</dd>

                                                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Indent By :</dt>
                                                                <dd className='col-sm-8 erp-invoice-print-label-md-lg'> {invoiceContent.header.indented_by}</dd>

                                                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Department :</dt>
                                                                <dd className='col-sm-8 erp-invoice-print-label-md-lg'> {invoiceContent.header.department_name}</dd>

                                                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Sub-Department :</dt>
                                                                <dd className='col-sm-8 erp-invoice-print-label-md-lg'> {invoiceContent.header.sub_department_name}</dd>

                                                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Approved By :</dt>
                                                                <dd className='col-sm-8 erp-invoice-print-label-md-lg'> {invoiceContent.header.approved_by_name}({invoiceContent.header.approved_date}) </dd>

                                                                {/* <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Approved Date :</dt>
                                                                <dd className='col-sm-8 erp-invoice-print-label-md-lg'> {invoiceContent.header.approved_date}</dd> */}

                                                                {/* {
                                                                    invoiceContent.header.indent_source !== 'Direct' ? <>

                                                                        <div class="row p-0 m-0">
                                                                            <dl class="row m-0 p-0">
                                                                                <dt class="col-sm-4  erp-invoice-print-label-md-lg">Customer :</dt>
                                                                                <dd class="col-sm-8 erp-invoice-print-label-md-lg">{invoiceContent.customerDetails.customer}</dd>
                                                                            </dl>
                                                                        </div>
                                                                    </> : null
                                                                } */}
                                                            </dl>
                                                        </dl>
                                                        <dl className="col-sm-6 mb-0">
                                                            <dl className="row mb-0 pb-0">

                                                                {
                                                                    invoiceContent.header.indent_source !== 'Direct' ? <>
                                                                        <div class="row p-0 m-0">
                                                                            <dt class="col-sm-4 mt-1 erp-invoice-print-label-md-lg">Customer :</dt>
                                                                            <dd class="col-sm-8 mt-1 erp-invoice-print-label-md-lg">{invoiceContent.customerDetails.customer}</dd>

                                                                            <dt class="col-sm-4 erp-invoice-print-label-md-lg">Cust Order No. &amp; Date :</dt>
                                                                            <dd class="col-sm-8 erp-invoice-print-label-md-lg">{invoiceContent.header.customer_order_no} &nbsp; &nbsp;&nbsp;{invoiceContent.header.customer_order_date}</dd>
                                                                        </div>
                                                                    </> : null
                                                                }



                                                                <dt className='col-sm-4 erp-invoice-print-label-md-lg'>Approved Remark :</dt>
                                                                <dd className='col-sm-8 erp-invoice-print-label-md-lg'> {invoiceContent.header.remark}</dd>
                                                            </dl>
                                                        </dl>
                                                    </dl>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>

                                {/* <!--PARCEL DETAILS--> */}
                                <div className="row" style={{ padding: '0px 15px 15px' }}>
                                    <div className="col-12 ">
                                        <div className="row">
                                            <div className="col-12 px-0" >
                                                <div className="row p-0">
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered border border-dark m-0 border-end-0 border-start-0" id='invoiceTable' >
                                                            <thead className='erp_table_border'>
                                                                <tr>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2} >Sr No.</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2} >Material Code</th>
                                                                    <th className="erp_invoice_table_th text-center" style={{ whiteSpace: 'normal', width: '130px' }} rowspan={2}>Raw Material Name(FG Name,Technical Spect.,Oem Part Code,
                                                                        <br /> Our Part Code,Make Name, Grade Name )</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Stock Qty.</th>
                                                                    {/* <th className="erp_invoice_table_th text-center" rowspan={2}>Lead time (Days)</th> */}
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2} style={{ whiteSpace: 'normal', width: '80px' }}>
                                                                        Lead Time <br /> (Days)
                                                                    </th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Unit</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Indent Qty.</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Approved Qty.</th>
                                                                    <th className="erp_invoice_table_th text-center" rowspan={2}>Rejected Qty.</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody id='detailstableId'>
                                                                {
                                                                    data.map((item, index) =>
                                                                        <>
                                                                            <tr className={`tblBodyTrHt-${index}`}>
                                                                                <td className="erp_invoice_table_td text-center">{item.sr_no}</td>
                                                                                <td className="erp_invoice_table_td text-center">{item.product_material_code}</td>
                                                                                {/* <td className="erp_invoice_table_td" style={{ width: '40%' }}>{item.product_rm_name}<span>({item.product_parent_fg_name},{item.material_tech_spect},{item.product_rm_oem_part_code},{item.product_rm_our_part_code},{item.product_make_name},{item.product_material_grade_name})</span></td> */}
                                                                                <td className="erp_invoice_table_td" style={{ width: '40%' }}>{item.product_rm_name}
                                                                                    <span>
                                                                                        {
                                                                                            item.product_parent_fg_name === '' && item.material_tech_spect === '' && item.product_rm_oem_part_code === '' && item.product_rm_our_part_code === '' && item.product_make_name === '' && item.product_material_grade_name === ''
                                                                                                ? ''
                                                                                                : <>
                                                                                                    ({
                                                                                                        [item.product_parent_fg_name, item.material_tech_spect, item.product_rm_oem_part_code, item.product_rm_our_part_code, item.product_make_name, item.product_material_grade_name].filter(str => str !== "" && str !== "NA" && str !== null && str !== undefined).join(", ")})
                                                                                                </>
                                                                                        }
                                                                                    </span>
                                                                                </td>
                                                                                <td className="erp_invoice_table_td text-end">{item.product_material_stock_quantity}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.lead_time}</td>
                                                                                <td className="erp_invoice_table_td">{item.unit}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.indent_quantity}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.product_material_approved_quantity}</td>
                                                                                <td className="erp_invoice_table_td text-end">{item.product_material_rejected_quantity}</td>
                                                                            </tr>

                                                                            {
                                                                                data.length - 1 === index ? <>
                                                                                    <tr id='footerTr'>
                                                                                        <td className="erp_invoice_table_th">Total</td>
                                                                                        <td className="erp_invoice_table_td text-end"></td>
                                                                                        <td className="erp_invoice_table_td text-end">{parseFloat(invoiceContent.footer.sumofStockQuantity).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                                                        <td className="erp_invoice_table_td text-end"></td>
                                                                                        <td className="erp_invoice_table_td text-end"></td>
                                                                                        <td className="erp_invoice_table_td text-end">{parseFloat(invoiceContent.footer.sumIndentQuantity).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                                                        <td className="erp_invoice_table_td text-end">{parseFloat(invoiceContent.footer.sumofApprovedQuantity).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                                                        <td className="erp_invoice_table_td text-end">{parseFloat(invoiceContent.footer.sumofRejectedQuantity).toFixed(AFTER_DECIMAL_PLACES)}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td colSpan={12}>
                                                                                            {/* Your invoice footer content */}
                                                                                            {invoiceFooter}
                                                                                        </td>
                                                                                    </tr>

                                                                                </> : null
                                                                            }

                                                                        </>
                                                                    )
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
})


export default PurchaseRequisition;

