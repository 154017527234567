import React, { useState } from 'react';
import { useEffect, useRef, useMemo } from 'react';
import MDButton from "components/MDButton";
import MDTypography from 'components/MDTypography';
import { Table } from "react-bootstrap";
import { Form } from 'react-bootstrap';
import ComboBox from 'Features/ComboBox';
import ConfigConstants from "assets/Constants/config-constant";
import Select from 'react-select';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import { CircularProgress } from "@material-ui/core";
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"



function FrmFabricProductionReport() {

    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_NAME, COMPANY_ADDRESS } = configConstants;

    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const formatToDDMMYYYY = (date) => {
        const [year, month, day] = date.split('-');
        return `${day}-${month}-${year}`;
    };


    const allColumns = [
        { label: 'Insp. Date', value: 'inspection_production_date' },
        { label: 'Checker Name', value: 'production_operator_name' },
        { label: 'Beam No', value: 'sizing_beam_no' },
        { label: 'Sort No', value: 'sort_no' },
        { label: 'Set No', value: 'inspection_production_set_no' },
        { label: 'Total Ends', value: 'total_ends' },
        { label: 'Loom No', value: 'machine_id' },
        { label: 'Roll No', value: 'roll_no' },
        { label: 'Width', value: 'width' },
        { label: 'Pick', value: 'product_pick' },
        { label: 'Looms Mtr', value: 'product_in_meter' },
        { label: 'Difference', value: 'difference' },
        { label: 'Inspection Mtr', value: 'inspection_mtr' },
        { label: 'Weight', value: 'weight' },
        { label: 'Average', value: 'average' },
        { label: 'Dispatch Qty', value: 'dispatch_quantity' },
        { label: 'Dispatch Wt', value: 'dispatch_weight' },
        { label: 'Challan Dt', value: 'dispatch_date' },
        { label: 'Status', value: 'stock_status' }
    ];

    const [selectedColumns, setSelectedColumns] = useState(allColumns);

    const handleColumnChange = (selectedOptions) => {
        let hideColumns = "";

        allColumns.forEach((column) => {
            if (!selectedOptions.some(option => option.value === column.value)) {
                hideColumns += `.${column.value}, `;
            }
        });

        hideColumns = hideColumns.trim().slice(0, -1);

        $('table th, table td').show();

        if (hideColumns) {
            $(hideColumns).hide();
        }

        // Update the selected columns state
        setSelectedColumns(selectedOptions);
    };


    //useRef Hooks
    const comboDataAPiCall = useRef();
    const navigate = useNavigate();
    const set_no_ref = useRef(null);
    const sort_no_ref = useRef(null);
    const roll_no_ref = useRef(null);

    //Hooks for Filters
    const [to_stock_date, SetToStockDt] = useState(today());
    const [from_stock_date, SetFromStockDt] = useState(today());
    const [isLoading, setIsLoading] = useState(false);
    const [cmb_set_no, setSetNo] = useState();
    const [cmb_sort_no, setSortNo] = useState();
    const [cmb_roll_no, setRollNo] = useState();


    const [setNoOptions, SetSetNoOPtions] = useState([]);
    const [sortNoOptions, SetSortNoOptions] = useState([]);
    const [rollNoOptions, setRollNoOptions] = useState([]);

    const [fabricProductionData, setFabricProductionData] = useState([]);

    useEffect(async () => {
        const loadDataOnload = async () => {
            await fillCOmbos();
        }
        loadDataOnload();

    }, []);

    const fillCOmbos = async () => {
        debugger
        try {
            $('table th, table td').show();
            setSelectedColumns(allColumns)
            setSetNo('');
            set_no_ref.current = '';
            setSortNo('');
            sort_no_ref.current = '';
            setRollNo('');
            roll_no_ref.current = '';
            setIsLoading(true);

            resetGlobalQuery();
            globalQuery.columns.push('inspection_production_set_no', 'sort_no', 'roll_no');
            globalQuery.table = "xt_weaving_production_inspection_details";
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            // globalQuery.conditions.push({ field: "stock_status", operator: "IN", value: ['A', 'D', 'P'] });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const SetSortRollNoApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);

            // Set Set No Options
            const setNoList = [{ value: '', label: 'Select', isDisabled: true }];
            const uniqueSetNos = new Set(setNoList.map(item => item.value));
            SetSortRollNoApiCall.forEach(setNo => {
                const value = setNo['inspection_production_set_no'];
                if (!uniqueSetNos.has(value)) {
                    setNoList.push({ value: value, label: value });
                    uniqueSetNos.add(value);
                }
            });
            SetSetNoOPtions(setNoList);

            // Set Sort No Options
            const sortNoList = [{ value: '', label: 'Select', isDisabled: true }];
            const uniqueSortNos = new Set(sortNoList.map(item => item.value));
            SetSortRollNoApiCall.forEach(setNo => {
                const value = setNo['sort_no'];
                if (!uniqueSortNos.has(value)) {
                    sortNoList.push({ value: value, label: value });
                    uniqueSortNos.add(value);
                }
            });
            SetSortNoOptions(sortNoList); // Corrected to use SetSortNoOptions

            // Set Roll No Options
            const rollNoList = [{ value: '', label: 'Select', isDisabled: true }];
            const uniqueRollNos = new Set(rollNoList.map(item => item.value));
            SetSortRollNoApiCall.forEach(setNo => {
                const value = setNo['roll_no']; // Assuming 'roll_no' is the correct key
                if (!uniqueRollNos.has(value)) {
                    rollNoList.push({ value: value, label: value });
                    uniqueRollNos.add(value);
                }
            });
            setRollNoOptions(rollNoList);



            await FnGetFabricProductionData();

        } catch (error) {

        } finally {
            setIsLoading(false)
        }

    }


    const FnGetFabricProductionData = async () => {
        debugger
        try {
            setIsLoading(true);
            $('table th, table td').show();
            let from_date = $("#from_stock_date").val();
            let to_date = $("#to_stock_date").val();
            let dt_today_date = today()
            let isValid = true;
            if (to_date > dt_today_date) {
                $('#error_to_stock_date').text("To Date cannot be greater than Today").show();
                isValid = false;
            }
            else if (from_date > to_date) {
                $('#error_to_stock_date').text("From Date cannot be greater than To Date").show();
                isValid = false;
            }
            else {
                $('#error_to_stock_date').hide();
            }
            if (isValid) {
                let set_no = set_no_ref.current.value !== undefined ? set_no_ref.current.value : 'set_no';
                let sort_no = sort_no_ref.current.value !== undefined ? sort_no_ref.current.value : 'sort_no';
                let roll_no = roll_no_ref.current.value !== undefined ? roll_no_ref.current.value : 'roll_no';
                const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtWeavingProductionInspectionMaster/FnGetFabricProductionData/${from_date}/${to_date}/${parseInt(COMPANY_ID)}/${set_no}/${sort_no}/${roll_no}`);
                const updateRes = await apiCall.json();

                if (updateRes.length > 0) {
                    console.log(updateRes)
                    setFabricProductionData(updateRes);
                } else {
                    setFabricProductionData([]);
                }
            }
        } catch {

        } finally {
            setIsLoading(false)
        }
    }

    const renderFabricStockProductionTbl = useMemo(() => {
        return (
            <Table>
                <thead className="erp_table_head">
                    <tr>
                        <th className="erp_table_th" style={{ width: "40px" }}>Sr No.</th>
                        <th className="erp_table_th inspection_production_date" style={{ width: "80px" }}>Insp. Date</th>
                        <th className="erp_table_th production_operator_name" style={{ width: "70px" }}>Checker Name</th>
                        <th className="erp_table_th sizing_beam_no" style={{ width: "70px" }}>Beam No.</th>
                        <th className="erp_table_th sort_no" style={{ width: "60px" }}>Sort No.</th>
                        <th className="erp_table_th inspection_production_set_no" style={{ width: "60px" }}>Set No.</th>
                        <th className="erp_table_th total_ends" style={{ width: "75px" }}>Total Ends</th>
                        <th className="erp_table_th machine_id" style={{ width: "110px" }}>Loom No.</th>
                        <th className="erp_table_th roll_no" style={{ width: "80px" }}>Roll No.</th>
                        <th className="erp_table_th width" style={{ width: "80px" }}>Width</th>
                        <th className="erp_table_th product_pick" style={{ width: "80px" }}>Pick</th>
                        <th className="erp_table_th product_in_meter" style={{ width: "80px" }}>Looms Mtr.</th>
                        <th className="erp_table_th difference" style={{ width: "80px" }}>Difference</th>
                        <th className="erp_table_th inspection_mtr" style={{ width: "80px" }}>Insp. Mtr.</th>
                        <th className="erp_table_th weight" style={{ width: "80px" }}>Weight</th>
                        <th className="erp_table_th average" style={{ width: "80px" }}>Average</th>
                        <th className="erp_table_th dispatch_quantity" style={{ width: "80px" }}>Dispatch Qty.</th>
                        <th className="erp_table_th dispatch_weight" style={{ width: "80px" }}>Dispatch Wt.</th>
                        <th className="erp_table_th dispatch_date" style={{ width: "80px" }}>Challan Dt.</th>
                        <th className="erp_table_th inspection_production_status" style={{ width: "130px" }}>Status</th>
                    </tr>
                </thead>


                {fabricProductionData && fabricProductionData.length > 0 ? (
                    fabricProductionData.map((sizedBeamDetails, indexOfItem) => (
                        <tr key={indexOfItem} rowindex={indexOfItem}>
                            <td className="erp_table_td">{indexOfItem + 1}</td>
                            <td className="erp_table_td inspection_production_date">{sizedBeamDetails.inspection_production_date}</td>
                            <td className="erp_table_td production_operator_name">{sizedBeamDetails.production_operator_name}</td>
                            <td className="erp_table_td sizing_beam_no">{sizedBeamDetails.sizing_beam_no}</td>
                            <td className="erp_table_td sort_no">{sizedBeamDetails.sort_no}</td>
                            <td className="erp_table_td inspection_production_set_no">{sizedBeamDetails.inspection_production_set_no}</td>
                            <td className="erp_table_td total_ends">{sizedBeamDetails.total_ends}</td>
                            <td className="erp_table_td machine_id">{sizedBeamDetails.machine_id}</td>
                            <td className="erp_table_td roll_no">{sizedBeamDetails.roll_no}</td>
                            <td className="erp_table_td width">{sizedBeamDetails.width}</td>
                            <td className="erp_table_td product_pick">{sizedBeamDetails.product_pick}</td>
                            <td className="erp_table_td product_in_meter">{sizedBeamDetails.product_in_meter}</td>
                            <td className="erp_table_td difference">{sizedBeamDetails.difference}</td>
                            <td className="erp_table_td inspection_mtr">{sizedBeamDetails.inspection_mtr}</td>
                            <td className="erp_table_td weight">{sizedBeamDetails.weight}</td>
                            <td className="erp_table_td average">{sizedBeamDetails.average}</td>
                            <td className="erp_table_td dispatch_quantity">{sizedBeamDetails.dispatch_quantity}</td>
                            <td className="erp_table_td dispatch_weight">{sizedBeamDetails.dispatch_weight}</td>
                            <td className="erp_table_td dispatch_date">{sizedBeamDetails.dispatch_date}</td>
                            <td className="erp_table_td inspection_production_status">
                                <select className="erp_input_field_table_txt form-select form-select-sm mb-0" value={sizedBeamDetails.stock_status} disabled>
                                    <option value="A">In-Stock</option>
                                    <option value="P">Pending</option>
                                    <option value="D">Dispatched</option>
                                </select>
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan="20" className="erp_table_td" style={{ textAlign: 'center' }}>No Data Found</td>
                    </tr>
                )}

            </Table>
        )
    }, [fabricProductionData])

    const addFilter = (key, value) => {
        debugger
        try {
            setIsLoading(true)


        } catch (error) {

        } finally {
            setIsLoading(false)
        }
    }


    const FnExportToExcel = async (product_id = '') => {
        // Means No Data For Export. 
        setIsLoading(true);
        if (fabricProductionData && Object.keys(fabricProductionData).length === 0) { return null; }
        try {
            let commonIds = {};
            commonIds.company_id = COMPANY_ID
            commonIds.COMPANY_NAME = COMPANY_NAME
            commonIds.COMPANY_ADDRESS = COMPANY_ADDRESS
            commonIds.from_date = from_stock_date
            commonIds.to_date = to_stock_date
            commonIds.selectedColumns = selectedColumns
            commonIds.set_no = set_no_ref.current.value !== undefined ? set_no_ref.current.value : 'set_no';
            commonIds.sort_no = sort_no_ref.current.value !== undefined ? sort_no_ref.current.value : 'sort_no';
            commonIds.roll_no = roll_no_ref.current.value !== undefined ? roll_no_ref.current.value : 'roll_no';
            commonIds.ReportName = "Fabric Production Report"
            commonIds.SheetName = "Fabric Production"
            console.log(selectedColumns)
            const formData = new FormData();
            formData.append(`commonIds`, JSON.stringify(commonIds))
            const requestOptions = {
                method: 'POST',
                body: formData
            };
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtWeavingProductionInspectionMaster/GetFabricProductionReportExport`, requestOptions)
            console.log(response)

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const blob = await response.blob();
            // Create a URL for the blob object
            const url = window.URL.createObjectURL(new Blob([blob]));
            // Create a link element to download the file
            const link = document.createElement('a');
            link.href = url;
            const today = new Date();
            const formattedDate = `${today.getDate().toString().padStart(2, '0')}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getFullYear()}`;
            // Set the name of the file
            link.setAttribute('download', `${commonIds.ReportName}_${formattedDate}.xlsx`); // Set your preferred file name
            // Append the link to the document body
            document.body.appendChild(link);
            // Trigger the download by simulating a click
            link.click();
            // Clean up by removing the link from the document
            document.body.removeChild(link);
            console.log("File downloaded successfully");

        } catch (error) {
            console.log(error);

        } finally {
            setIsLoading(false);
        }

    }

    return (
        <>
            <DashboardLayout>
                <ComboBox ref={comboDataAPiCall} />

                {isLoading ?
                    <div className="spinner-overlay"  >
                        <div className="spinner-container">
                            <CircularProgress color="primary" />
                            <span>Loading...</span>
                        </div>
                    </div> :
                    ''}

                <div className='card mt-4'>
                    <div className='card-header text-center py-0'>
                        <label className='erp-form-label-lg text-center'>Fabric Production Report</label>
                    </div>
                    <div className="card-body">
                        <div className="row">

                            <div className="col-sm-3 erp_form_col_div ">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">From Date  </Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Form.Control type="date" id="from_stock_date" className="erp_input_field" value={from_stock_date} onChange={e => { SetFromStockDt(e.target.value) }} />
                                        <MDTypography variant="button" id="error_from_stock_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} />
                                    </div>

                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Set No <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Select
                                            ref={set_no_ref}
                                            options={setNoOptions}

                                            inputId="cmb_set_no"
                                            value={setNoOptions.length > 0 ? setNoOptions.find(option => option.value === cmb_set_no) : null}

                                            onChange={(selectedOpt) => {
                                                set_no_ref.current = selectedOpt;
                                                setSetNo(selectedOpt.value)
                                            }}
                                            placeholder="Search Set No..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                })
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Roll No <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Select
                                            ref={roll_no_ref}
                                            options={rollNoOptions}
                                            inputId="cmb_roll_no"
                                            value={rollNoOptions.length > 0 ? rollNoOptions.find(option => option.value === cmb_roll_no) : null}

                                            onChange={(selectedOpt) => {
                                                roll_no_ref.current = selectedOpt;
                                                setRollNo(selectedOpt.value)
                                            }}
                                            placeholder="Search Roll No..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-3 erp_form_col_div ">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">To Date  </Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Form.Control
                                            type="date"
                                            id="to_stock_date"
                                            className="erp_input_field"
                                            value={to_stock_date}
                                            max={new Date().toISOString().split("T")[0]} // Sets today's date as the maximum selectable date
                                            onChange={e => { SetToStockDt(e.target.value); }}
                                        />
                                        <MDTypography variant="button" id="error_to_stock_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }} />

                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Sort No <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Select
                                            ref={sort_no_ref}
                                            options={sortNoOptions}
                                            inputId="cmb_sort_no"
                                            value={sortNoOptions.length > 0 ? sortNoOptions.find(option => option.value === cmb_sort_no) : null}

                                            onChange={(selectedOpt) => {
                                                sort_no_ref.current = selectedOpt;
                                                setSortNo(selectedOpt.value)
                                            }}
                                            placeholder="Search Sort No..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                })
                                            }}
                                        />
                                    </div>
                                </div>



                            </div>


                            <div className="col-sm-6 erp_form_col_div ">
                                <div className="row">
                                    <div className="col-sm-2 ">
                                        <Form.Label className="erp-form-label">Filtered Columns </Form.Label>
                                    </div>
                                    <div className="col-sm-10">
                                        <Select
                                            options={allColumns}
                                            isMulti
                                            value={selectedColumns}
                                            onChange={handleColumnChange}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    minHeight: '30px',
                                                    height: 'auto',
                                                    fontSize: '10px',
                                                    overflow: 'hidden', // Prevent overflow
                                                }),
                                                valueContainer: (provided) => ({
                                                    ...provided,
                                                    padding: '0px 6px',
                                                    display: 'flex',
                                                    flexWrap: 'wrap', // Ensure options wrap inside the container
                                                }),
                                                multiValue: (provided) => ({
                                                    ...provided,
                                                    margin: '2px 2px', // Adjust margin for multi-value chips
                                                    backgroundColor: '#e0e0e0', // Adjust background color for visibility
                                                }),
                                                multiValueLabel: (provided) => ({
                                                    ...provided,
                                                    fontSize: '10px', // Consistent font size for multi-value labels
                                                }),
                                                multiValueRemove: (provided) => ({
                                                    ...provided,
                                                    fontSize: '10px',
                                                }),
                                                indicatorsContainer: (provided) => ({
                                                    ...provided,
                                                    height: 'auto',
                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    zIndex: 100, // Ensure dropdown is above other elements
                                                }),
                                                option: (provided) => ({
                                                    ...provided,
                                                    fontSize: '12px',
                                                    padding: '4px 8px',
                                                }),
                                            }}
                                        />


                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="row"> */}
                        <div className='row py-1'>
                            <div className='col-sm-5'></div>
                            <div className='col-sm-1'>
                                <MDButton type="button" className="erp-gb-button ms-1" onClick={() => { FnGetFabricProductionData() }} variant="button"
                                    fontWeight="regular">Show Data</MDButton>
                            </div>
                            <div className='col-sm-1'>
                                <MDButton type="button" className="erp-gb-button ms-1" onClick={() => { fillCOmbos() }} variant="button"
                                    fontWeight="regular">Clear Filters</MDButton>
                            </div>
                            <div className="col-sm-5 text-end">
                                <MDButton type="button" variant="button" fontWeight="regular" className="erp-gb-button"
                                    onClick={(e) => { FnExportToExcel(); }}
                                >Export Excel</MDButton>
                            </div>
                        </div>
                        {/* </div> */}
                        {/* 
                    <hr /> */}

                        <div className="col-sm-12 px-lg-2 d-block mt-2">
                            <div className="col-lg-12 col-12 erp_form_col_div">
                                <div className="card">
                                    <div className="card-header py-0 main_heading mb-0 text-center">
                                        <label className="erp-form-label-md-lg">Fabric Production Details</label>
                                    </div>
                                    <div className="card-body p-0">{renderFabricStockProductionTbl}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card-footer pb-2 text-center">
                    <MDButton type="button" className="erp-gb-button ms-1" onClick={() => {
                        const path = '/Masters';
                        navigate(path);
                    }} variant="button"
                        fontWeight="regular">Back</MDButton>
                </div >

            </DashboardLayout>
        </>
    )
}

export default FrmFabricProductionReport
