import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import $ from 'jquery';
import { useNavigate } from "react-router-dom";
import MDTypography from 'components/MDTypography';
import ComboBox from 'Features/ComboBox';
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ConfigConstants from "assets/Constants/config-constant";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import ValidateNumberDateInput from 'FrmGeneric/ValidateNumberDateInput';
import FrmValidations from 'FrmGeneric/FrmValidations';
// Imports React bootstrap & stylling required
import { CircularProgress } from "@material-ui/core";
import { Form, Table, Card } from "react-bootstrap";
import MDButton from "components/MDButton";
import ExcelExport from "Features/Exports/ExcelExport";
import Select from 'react-select';
import MaterialSearchBar from "FrmGeneric/MaterialSearchBar";
import ReactPaginate from "react-paginate";

function YarnClosingBalanceReport() {

    const configConstants = ConfigConstants();
    const { COMPANY_NAME, COMPANY_ID, AFTER_DECIMAL_PLACES, COMPANY_ADDRESS } = configConstants;

    const navigate = useNavigate();

    //Current date
    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    const currentDate = new Date().toISOString().split("T")[0];
    //set date to today

    const [dt_to_date, setToDt] = useState(today());
    const [category1Options, setCategory1Options] = useState([]);
    const [cmb_product_category1_id, setProduct_category1_id] = useState(1);
    const [cmb_godown_id, setGodownID] = useState(2);
    const [cmb_godown_section_id, setGodownSectionID] = useState("All");
    const [cmb_product_category2_id, setProduct_category2_id] = useState("All");
    const [search_material_id, setSearchMaterialId] = useState('');
    const [by_material, setByMaterial] = useState(false);

    //Options States
    const [godownOptions, setGodownOptions] = useState([]);
    const [godownSectionOptions, setGodownSectionOptions] = useState([]);
    const [productTypeOptions, setProductTypeOptions] = useState([]);
    const [cmb_product_type_id, setProduct_type_id] = useState(12);
    const [category2Options, setCategory2Options] = useState([]);

    let productTypeComboRef = useRef(null);
    let category1ComboRef = useRef(null);
    let godownComboRef = useRef(null);
    let godownSectionComboRef = useRef(null);
    let category2ComboRef = useRef(null);


    //Ref Hooks
    const validate = useRef();
    const comboDataAPiCall = useRef();
    const validateNumberDateInput = useRef();

    // Helping hooks;
    const [isLoading, setIsLoading] = useState(false);
    // Ref Hooks For Print-Out.    
    const printComponentRef = useRef();
    const exlsExp = useRef();

    //data hook
    const [yarnStockReportData, setYarnStockReportData] = useState([])
    const [yarnTotalStockReport, setYarnTotalStockReport]= useState()

    // Pagination Variables 
    const pageEntriesOptions = [
        { label: "5", value: 5 },
        { label: "10", value: 10 },
        { label: "50", value: 50 },
        { label: "100", value: 100 },
        { label: "500", value: 500 },
        // { label: "All", value: 0 },
    ]
    const [entriesPerPage, setEntriesPerPage] = useState(pageEntriesOptions[2].value);
    const [pageCount, setpageCount] = useState(0);
    const [PageCurrent, setcurrentPage] = useState(0);

    //Returning to Dashboard
    const goBack = () => { navigate("/Dashboard") }

    useEffect(() => {
        const loadFiltersCombo = async () => {
            setIsLoading(true)
            await loadCombos();
            // await FnShowRawMaterialData(0 , entriesPerPage);
            setIsLoading(false)
        }
        loadFiltersCombo();
    }, [])


    function convertToDDMMYYYY(dateString) {
        // Assuming the input is in the format YYYY-MM-DD
        const [year, month, day] = dateString.split('-');
        // Return the date in DD-MM-YYYY format
        return `${day}-${month}-${year}`;
    }


    // ****-------Get Cost Center Data Function--------------***
    const loadCombos = async () => {
        try {
            //load product type options
            resetGlobalQuery();
            globalQuery.columns = ['product_type_id', 'product_type_name', 'product_type_short_name'];
            globalQuery.table = "sm_product_type";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const productTypeList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            const typesOpts = [
                { value: 'All', label: 'All', centerShortName: '' },
                ...productTypeList.map((productType) => ({ ...productType, value: productType.product_type_id, label: `${productType.product_type_name}` })),
            ];
            setProductTypeOptions(typesOpts);
            // const selectedOption = typesOpts.find(item => item.product_type_short_name === requestfor);
            // productTypeComboRef.current = selectedOption;

            resetGlobalQuery();
            globalQuery.columns = ['godown_id', 'godown_name'];
            globalQuery.table = "cm_godown";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const godownList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            const godownOpts = [
                { value: 'All', label: 'All', centerShortName: '' },
                ...godownList.map((godown) => ({ ...godown, value: godown.godown_id, label: `${godown.godown_name}` })),
            ];
            console.log(godownOpts)
            setGodownOptions(godownOpts);


            resetGlobalQuery();
            globalQuery.columns = ['product_category1_id', 'product_category1_name'];
            globalQuery.table = "sm_product_category1";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "product_type_id", operator: "=", value: cmb_product_type_id });
            const Category1List = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            const category1Opts = [
                { value: 'All', label: 'All', centerShortName: '' },
                ...Category1List.map((category1) => ({ ...category1, value: category1.product_category1_id, label: `${category1.product_category1_name}` })),
            ];
            setCategory1Options(category1Opts);
            setProduct_category1_id(1)

            resetGlobalQuery();
            ;
            globalQuery.columns = ['product_category2_id', 'product_category2_name'];
            globalQuery.table = "sm_product_category2";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "product_category1_id", operator: "=", value: cmb_product_category1_id });
            const Category2List = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            const category2Opts = [
                { value: 'All', label: 'All' },
                ...Category2List.map((category2) => ({ ...category2, value: category2.product_category2_id, label: `${category2.product_category2_name}` })),
            ];
            setCategory2Options(category2Opts);
            setProduct_category2_id(5)

        } catch (error) {
            console.log("error : ", error)
        }
    }

    const comboOnChange = async (key) => {

        try {
            switch (key) {
                case 'godownId':
                    
                    let godown_Id = godownComboRef.current.value;
                    if (godown_Id != 'All') {
                        resetGlobalQuery();
                        globalQuery.columns = ['godown_section_id', 'godown_section_name'];
                        globalQuery.table = "cm_godown_section";
                        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                        globalQuery.conditions.push({ field: "godown_id", operator: "=", value: godown_Id });
                        const GodownSectionList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                        const GodownSectionOpts = [
                            { value: 'All', label: 'All' },
                            ...GodownSectionList.map((GodownSection) => ({ ...GodownSection, value: GodownSection.godown_section_id, label: `${GodownSection.godown_section_name}` })),
                        ];
                        setGodownSectionOptions(GodownSectionOpts);
                        setGodownSectionID('All')
                    } else {
                        const sectionOpts = [
                            { value: 'All', label: 'All' }
                        ];
                        setGodownSectionID(sectionOpts);
                        setGodownSectionID('All')
                    }
                    break;
                default:
                    break;
            }
        } catch (error) {
            console.log(error);

        }
    }

    // ****-------show data Function --------------***
    const FnShowRawMaterialData = async (page, pageSize, product_id = '') => {
        try {
            
            setIsLoading(true)
            let commonIds = {};
            // Common Ids 
            commonIds.company_id = COMPANY_ID
            commonIds.to_date = dt_to_date
            commonIds.product_type_id = cmb_product_type_id.toString();
            commonIds.category1_id = cmb_product_category1_id.toString();
            commonIds.category2_id = cmb_product_category2_id.toString();
            commonIds.product_id = product_id === '' ? search_material_id : product_id;
            commonIds.godown_id = cmb_godown_id.toString();
            commonIds.godown_section_id = cmb_godown_section_id.toString();
            const formData = new FormData();
            formData.append(`commonIds`, JSON.stringify(commonIds))
            const requestOptions = {
                method: 'POST',
                body: formData
            };
            
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/StockReport/getYarnStockReport/${page}/${pageSize}`, requestOptions)
            const responce = await apiCall.json();
            
            if (responce.success = 1) {
                const total = responce.total_count;
                setpageCount(parseInt(pageSize) !== 0 ? Math.ceil(total / pageSize) : 1);
                setYarnStockReportData(responce.storeStockReportData)
                setYarnTotalStockReport(responce.totalYarnStockData)
            }

        } catch (error) {
            console.log(error);
            setIsLoading(false)
        } finally {
            setIsLoading(false)
            // setYarnStockReportData([])
        }
    }

    //Export Functionality 
    const FnExportToExcel = async () => {
        // Means No Data For Export. 
        if (yarnStockReportData && Object.keys(yarnStockReportData).length === 0) { return null; }

        setIsLoading(true);
        try {
            let commonIds = {};
            // Common Ids 
            
            commonIds.company_id = COMPANY_ID
            commonIds.from_date = dt_to_date
            commonIds.to_date = dt_to_date
            commonIds.product_type_id = cmb_product_type_id.toString();
            commonIds.category1_id = cmb_product_category1_id.toString();
            commonIds.category2_id = cmb_product_category2_id.toString();
            commonIds.product_id = search_material_id;
            commonIds.godown_id = cmb_godown_id.toString();
            commonIds.godown_section_id = cmb_godown_section_id.toString();
            commonIds.COMPANY_NAME = COMPANY_NAME
            commonIds.COMPANY_ADDRESS = COMPANY_ADDRESS
            commonIds.ReportName = "Yarn Stock Report"
            commonIds.SheetName = "Yarn Stock"

            const formData = new FormData();
            formData.append(`commonIds`, JSON.stringify(commonIds))
            const requestOptions = {
                method: 'POST',
                body: formData
            };

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/StockReport/getYarnStockReportToExport`, requestOptions)
            // Ensure the response is ok (status 200)
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            // Get the response as a blob (binary large object)
            const blob = await response.blob();
            // Create a URL for the blob object
            const url = window.URL.createObjectURL(new Blob([blob]));
            // Create a link element to download the file
            const link = document.createElement('a');
            link.href = url;
            // Set the name of the file
            // link.setAttribute('download', 'Yarn_Report.xlsx'); // Set your preferred file name
            const today = new Date();
            const formattedDate = `${today.getDate().toString().padStart(2, '0')}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getFullYear()}`;
            link.setAttribute('download', `${commonIds.ReportName}_${formattedDate}.xlsx`); // Set your preferred file name
            document.body.appendChild(link);
            // Trigger the download by simulating a click
            link.click();
            // Clean up by removing the link from the document
            document.body.removeChild(link);
        } catch (error) {
            console.log(error);

        } finally {
            setIsLoading(false);
        }
    }
    // ********* ExportToExcel Functionalities Ends. ****************************************


    // product filter columns
    let requiredCols = ['product_id', 'product_name', 'product_code'];

    const getMaterialBySearch = async (selectedData) => {
        if (selectedData !== null) {
            try {
                setIsLoading(true)
                let { product_id, product_name, product_code } = selectedData[0];
                let material_name = product_name;
                let material_code = product_code;
                let material_id = product_id;
                
                if (by_material) {
                    setSearchMaterialId(product_id)
                    await FnShowRawMaterialData(PageCurrent, entriesPerPage, product_id);
                    $('#error_selectMaterialRecords_id').hide();;
                } else {
                    $('#error_selectMaterialRecords_id').text('Please fill checkbox first').show();
                }
            } catch (error) {
                console.error("Error in Search Material: ", error);
                setSearchMaterialId('')
                setIsLoading(false)
            } finally {
                setIsLoading(false)
            }
        } else {
            setYarnStockReportData([]);
        }
    }

    const handlePageCountClick = async () => {
        let count = document.getElementById("page_entries_id").value;
        setEntriesPerPage(count)
        setcurrentPage(0)
        await FnShowRawMaterialData(0, count);
        // await fetchFilteredData(0, count, selectedColumns.length !== 0 ? selectedColumns : availableColumns);
    }

    const handlePageClick = async (pageNo) => {
        
        let currentPage = pageNo.selected;
        setcurrentPage(currentPage);
        let offset = currentPage * entriesPerPage;
        await FnShowRawMaterialData(offset, entriesPerPage);
    }
    
    function decimalNumber(decimalValue, afterDecimal = AFTER_DECIMAL_PLACES) {
        // Check if decimalValue is null, undefined, or an empty string
        if (decimalValue === null || decimalValue === undefined || decimalValue === '') {
            return '';
        }

        // Ensure decimalValue is a string for length check
        const decimalStr = String(decimalValue);

        if (decimalStr[decimalStr.length - 1] === '.') {
            return decimalStr;
        } else {
            let num = parseFloat(decimalStr);
            if (isNaN(num)) {
                return ''; // Return empty string if parsing fails
            }
            let roundedNum = Math.round(num * (10 ** afterDecimal)) / (10 ** afterDecimal);
            return roundedNum;
        }
    }

    return (
        <>
            <DashboardLayout>
                <ComboBox ref={comboDataAPiCall} />
                <FrmValidations ref={validate} />
                <ValidateNumberDateInput ref={validateNumberDateInput} />
                <ExcelExport ref={exlsExp} />

                {isLoading ?
                    <div className="spinner-overlay"  >
                        <div className="spinner-container">
                            <CircularProgress color="primary" />
                            <span>Loading...</span>
                        </div>
                    </div> :
                    ''}

                <div className='card mt-4'>
                    <div className='card-header text-center py-0'>
                        <label className='erp-form-label-lg text-center'>Yarn Stock Report</label>
                    </div>

                    {/* Card's Body Starts*/}
                    <div className="card-body">
                        <div className='row p-1'>
                            <div className='col-sm-4 erp_form_col_div'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Product Type<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Select
                                            ref={productTypeComboRef}
                                            inputId="cmb_product_type_id"
                                            value={productTypeOptions.find(option => option.value === cmb_product_type_id) || null}
                                            options={productTypeOptions}
                                            onChange={(selectedOption) => {
                                                setProduct_type_id(selectedOption.value)
                                                productTypeComboRef.current = selectedOption;
                                                // comboOnChange('category1');
                                            }}
                                            placeholder="Search for product type..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            isDisabled={true}

                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                })
                                            }}
                                        />

                                        <MDTypography variant="button" id="error_cmb_product_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>

                                </div>
                            </div>

                            <div className='col-sm-4 erp_form_col_div'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Godown Name:</Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Select
                                            ref={godownComboRef}
                                            inputId="cmb_godown_id"
                                            value={godownOptions.find(option => option.value === cmb_godown_id) || null}
                                            options={godownOptions}
                                            onChange={(selectedOption) => {
                                                setGodownID(selectedOption.value)
                                                godownComboRef.current = selectedOption;
                                                comboOnChange('godownId');
                                            }
                                            }
                                            placeholder="Search for Category1..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"

                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                })
                                            }}
                                        />

                                        <MDTypography variant="button" id="error_cmb_godown_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-4 erp_form_col_div'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Date<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Form.Control type="date" id='dt_to_date'
                                            className="erp_input_field"
                                            value={dt_to_date}
                                            disabled={true}
                                        />
                                        <MDTypography variant="button" id="error_dt_to_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='row p-1'>
                            {/* Categories COMBO BOX*/}
                            <div className='col-sm-4 erp_form_col_div'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Category 1:</Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Select
                                            ref={category1ComboRef}
                                            inputId="cmb_product_category1_id"
                                            value={category1Options.find(option => option.value === cmb_product_category1_id) || null}
                                            options={category1Options}
                                            isDisabled={true}
                                            onChange={(selectedOption) => {
                                                setProduct_category1_id(selectedOption.value)
                                                category1ComboRef.current = selectedOption;
                                                comboOnChange('category1');
                                            }}
                                            placeholder="Search for Category1..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"

                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                })
                                            }}
                                        />

                                        <MDTypography variant="button" id="error_cmb_product_category1_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-4 erp_form_col_div'>
                            <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Category 2:</Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Select
                                            ref={category2ComboRef}
                                            inputId="cmb_product_category2_id"
                                            value={category2Options.find(option => option.value === cmb_product_category2_id) || null}
                                            options={category2Options}
                                            onChange={(selectedOption) => {
                                                setProduct_category2_id(selectedOption.value)
                                                category2ComboRef.current = selectedOption;
                                                comboOnChange('category2');
                                            }
                                            }
                                            placeholder="Search for Category2..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"

                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_product_category2_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                                {/* <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Godown Section Name:</Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Select
                                            ref={godownSectionComboRef}
                                            inputId="cmb_godown_section_id"
                                            value={godownSectionOptions.find(option => option.value === cmb_godown_section_id) || null}
                                            options={godownSectionOptions}
                                            onChange={(selectedOption) => {
                                                setGodownSectionID(selectedOption.value)
                                                godownSectionComboRef.current = selectedOption;
                                                comboOnChange('godownSectionId');
                                            }
                                            }
                                            placeholder="Search for Godown Section..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"

                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                })
                                            }}
                                        />

                                        <MDTypography variant="button" id="error_cmb_godown_section_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div> */}
                            </div>

                            <div className='col-sm-4 erp_form_col_div'>
                                <div className={`row`}>
                                    <div className="col-sm-4">
                                        <input type='checkbox' class="me-1" name="selectMaterialRecords" id="selectMaterialRecords" onChange={(e) => { e.target.checked ? setByMaterial(true) : setByMaterial(false); setSearchMaterialId('') }} /> <label class="erp-form-label pb-1">Search By Material </label>
                                    </div>
                                    <div className="col-sm-6">
                                        <MaterialSearchBar getSearchData={getMaterialBySearch} formIdForValidate="" requiredCols={requiredCols} product_type_id={cmb_product_type_id} product_category1_id={cmb_product_category1_id == 'All' ? 0 : cmb_product_category1_id} product_category2_id={cmb_product_category2_id == 'All' ? 0 : cmb_product_category2_id} godownId= {2} />
                                        <MDTypography variant="button" id="error_selectMaterialRecords_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                              
                            </div>
                            <div className='col-sm-4 erp_form_col_div'>
                         
                            </div>



                            <div className="row justify-content-center mt-2">
                                <div className="col text-center">
                                    <MDButton type="button" id="show-data-btn" className="erp-gb-button" onClick={(e) => { FnShowRawMaterialData(0, entriesPerPage); }} variant="button" fontWeight="regular">show Data</MDButton>
                                </div>
                            </div>
                        </div>
                        {
                            !yarnStockReportData || yarnStockReportData.length === 0 
                                ? <> <hr /> <Card id="NoRcrdId" > <Card.Body>No records found...</Card.Body> </Card> </>
                                :
                                <>
                                    <hr />
                                    <div className="row py-1">
                                        <div className="col-sm-6 page_entries">
                                            <MDTypography component="label" className="erp-form-label-md" variant="button" fontWeight="regular" color="dark" textTransform="capitalize">Entries per page</MDTypography>
                                            <Form.Select onChange={handlePageCountClick} value={entriesPerPage} className="erp_page_select erp_form_control" id="page_entries_id" >
                                                {pageEntriesOptions.map(pageEntriesOptions => (
                                                    <option value={pageEntriesOptions.value}>{pageEntriesOptions.label}</option>

                                                ))}
                                            </Form.Select>
                                        </div>
                                        <div className="col-sm-6 text-end">
                                            <MDButton type="button" variant="button" fontWeight="regular" className="erp-gb-button"
                                                onClick={(e) => { FnExportToExcel(); }}
                                            >Export Excel</MDButton>

                                        </div>
                                    </div>
                                    <div className="row px-lg-2 d-block  mt-1">
                                        <div className="card p-0">
                                            {/* Card's Body Starts*/}
                                            <div className="card-body p-0 print-content" ref={printComponentRef}>
                                                <StoreReportTbl
                                                    yarnTotalStockReport = {yarnTotalStockReport}
                                                    yarnStockReportData={yarnStockReportData}
                                                    validateNumberDateInput={validateNumberDateInput}
                                                    decimalNumber={decimalNumber}
                                                    dt_to_date={convertToDDMMYYYY(dt_to_date)}
                                                />

                                            </div>
                                        </div>
                                        {pageCount !== 1 ?
                                            <ReactPaginate
                                                className='erp_pagination'
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                pageCount={pageCount}
                                                onPageChange={handlePageClick}
                                                containerClassName={"pagination justify-content-center"}
                                                pageClassName={"page-item"}
                                                pageLinkClassName={"page-link erp-gb-button"}
                                                previousClassName={"page-item"}
                                                previousLinkClassName={"page-link erp-gb-button"}
                                                nextClassName={"page-item"}
                                                nextLinkClassName={"page-link erp-gb-button"}
                                                breakClassName={"page-item"}
                                                breakLinkClassName={"page-link"}
                                                activeClassName={"active"} /> : null}
                                    </div>
                                </>
                        }

                        <hr />
                        <div className="row text-center mt-3">
                            <div className=''>
                                <MDButton type="button" id="back-btn" variant="button" fontWeight="regular"
                                    className={`erp-gb-button float-center`}
                                    onClick={(e) => { goBack(); }}  > Back </MDButton>
                            </div>
                        </div>
                    </div>
                    {/* Card's Body Ends*/}
                </div>
                {/* Card Ends*/}


            </DashboardLayout>
        </>
    )
}

export default YarnClosingBalanceReport



export const StoreReportTbl = ({
    yarnTotalStockReport,
    yarnStockReportData,
    validateNumberDateInput,
    decimalNumber,
    dt_to_date
}) => {

    return (
        <>
            <style>
                {`
                @media print {
                    .print-content {
                        padding: 0;
                        box-sizing: border-box;
                    }
                    @page {
                        margin: 0;
                        size: legal landscape;
                        margin: 1%;
                    }
                    html, body {
                        width: 100%;
                        height: 100%;
                    }
                    body {
                        margin: 0;
                        padding: 0;
                    }
                    .scrollable {
                        overflow: visible !important; /* Remove scrolling for print */
                    }
                    .erp_table {
                        table-layout: auto !important; /* Expand table for printing */
                        font-size: 7px !important; /* Adjust font size for print */
                        width: 100% !important; /* Ensure full width table */
                    }
                }
                .scrollable {
                    max-height: 500px;
                    overflow-y: auto;
                    overflow-x: auto;
                }

                .erp_table_th, .erp_table_td {
                    white-space: normal; /* Allow text to wrap */
                    word-wrap: break-word; /* Break long words if needed */
                }

                .sticky-footer {
                position: sticky;
                bottom: 0;
                background-color: #f9f9f9; /* Prevent transparency issues */
                font-weight: bold; /* Optional styling */
                }

                `}

            </style>

            <div className="scrollable">
                <Table
                    id="issueReportTableId"
                    className="erp_table"
                    style={{ whiteSpace: 'normal', tableLayout: 'auto' }}
                >
                    <thead className="erp_table_head table-secondary text-center">
                        <tr style={{ whiteSpace: 'normal' }}>
                            <th className="erp_table_th align-middle" rowSpan={2}>Sr. No</th>
                            <th className="erp_table_th align-middle" rowSpan={2}>Material Code</th>
                            <th className="erp_table_th align-middle" rowSpan={2}>Count</th>
                            <th className="erp_table_th align-middle" rowSpan={2}>Lot No.</th>
                            <th className="erp_table_th align-middle" rowSpan={2}>Party Name</th>
                            <th className="erp_table_th align-middle" rowSpan={2}>Godown Name</th>
                            <th className="erp_table_th align-middle" colSpan={3}>
                                Closing Stock As On <br /> {dt_to_date}
                            </th>
                        </tr>
                        <tr style={{ whiteSpace: 'normal' }}>
                            <th className="erp_table_th align-middle">Boxes</th>
                            <th className="erp_table_th align-middle">Cones</th>
                            <th className="erp_table_th align-middle">Kgs.</th>
                        </tr>
                    </thead>
                    <tbody>
                        <React.Fragment>
                            {yarnStockReportData.map((row, index) => (
                                <tr key={index} rowindex={index}>
                                    <td className="erp_table_td text-end">{index + 1}</td>
                                    <td className="erp_table_td">{row.material_code}</td>
                                    <td className="erp_table_td">{row.material_name}</td>
                                    <td className="erp_table_td">{row.lot_no}</td>
                                    <td className="erp_table_td">{row.supp_name}</td>
                                    <td className="erp_table_td">{row.godown_name}</td>
                                    <td className="erp_table_td text-end">{row.closing_no_of_boxes}</td>
                                    <td className="erp_table_td text-end">{row.closing_balance_quantity}</td>
                                    <td className="erp_table_td text-end">{row.closing_total_box_weight}</td>
                                </tr>
                            ))}
                             {yarnStockReportData.length!==0 && <tr className="table-light sticky-footer">
                                <th className="erp_table_td align-middle" colSpan="6">
                                    <strong>Totals:</strong>
                                </th>
                                <th className="erp_table_th text-end">
                                    {yarnTotalStockReport?.closing_no_of_boxes}
                                </th>
                                <th className="erp_table_th text-end">
                                    {yarnTotalStockReport?.closing_balance_quantity}
                                    
                                </th>
                                <th className="erp_table_th text-end">
                                    {yarnTotalStockReport?.closing_total_box_weight.toFixed(2)}
                                </th>
                            </tr>}
                        </React.Fragment>
                    </tbody>
                </Table>

            </div>
        </>
    );
};
