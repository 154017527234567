import React, { useEffect, useRef, useMemo, useLayoutEffect } from 'react'
import { useState } from 'react'
import $ from 'jquery';
import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { Form, Tab, Table, Tabs, Card } from 'react-bootstrap';
import ComboBox from 'Features/ComboBox';
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ConfigConstants from "assets/Constants/config-constant";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import ValidateNumberDateInput from 'FrmGeneric/ValidateNumberDateInput';
import FrmValidations from 'FrmGeneric/FrmValidations';
import { useLocation, useNavigate } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import GenerateMaterialId from 'FrmGeneric/GenerateMaterialId/GenerateMaterialId';
import { IoAddCircleOutline, IoRemoveCircleOutline } from 'react-icons/io5';
// Import for the searchable combo box.

function FrmWastageEntry() {

    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, UserName, FINANCIAL_SHORT_NAME } = configConstants;


    const { state } = useLocation();
    const { productionSetNo = 0, keyForViewUpdate = 'Add', compType, requestfor } = state || {};

    //Current date
    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    //useRef Hooks
    const comboDataAPiCall = useRef();

    // const combobox = useRef();
    const generateAutoNoAPiCall = useRef();
    const navigate = useNavigate();
    const validateNumberDateInput = useRef();
    const validate = useRef();

    const [actionType, setActionType] = useState(keyForViewUpdate === 'view' ? '(View)' : keyForViewUpdate === 'update' ? '(Update)' : '');
    const [labelForWastage, setLabelForWastage] = useState(keyForViewUpdate === 'Add' ? 'Save' : 'Update');
    const [isLoading, setIsLoading] = useState(false);

    //Warping Production  details comboboxes
    const [setNoOptions, setSetNoOptions] = useState([]);
    const [prodsectionOptions, setProdSectionOptions] = useState([]);
    const [prodsubsectionOptions, setProdSubSectionOptions] = useState([]);
    const [shiftOptions, setShiftOptions] = useState([]);



    ///Shiftwise Machine Stoppage Entry Hooks
    const [cmb_set_no, setProductionSetNo] = useState(productionSetNo);
    const [weaving_stoppage_id, setWeavingStoppageID] = useState();
    const [cmb_section_id, setProdSection] = useState(17);
    const [cmb_sub_section_id, setProdSubSection] = useState();

    //Shiftwise Machine Wastage Entry Combobox
    const [prodWastageOptions, setProdWastageOptions] = useState([]);
    const [godownNameOptions, setGodownNameOptions] = useState([]);

    //Error Msg
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    // Success Msg HANDLING
    const handleCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
        if (sessionStorage.getItem('dataAddedByCombo') !== 'dataAddedByCombo') {
            navigate(`/Transactions/TWeavingStoppageWastageProduction/FrmWastageListing`)
        }
    }

    let answr = 1;
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');

    useEffect(() => {
        const loadDataOnload = async () => {
            setIsLoading(true)
            await fillComboBox();
            if (!['', NaN].includes(cmb_set_no)) {
                await FnCheckUpdateRes();
            }

            setIsLoading(false)
        }
        loadDataOnload();
    }, [answr]);

    const wastageEmptyObj = {
        production_date: today(),
        production_wastage_types_id: '',
        wastage_quantity: 0,
        godown_name: '',
        shift: ''
    }
    const [wastageDetails, setWastageDetails] = useState([{ ...wastageEmptyObj }]);

    const fillComboBox = async () => {
        try {
            //Shift Options
            const shiftsApiCall = await comboDataAPiCall.current.fillComboBox('ProductionShifts')
            setShiftOptions(shiftsApiCall);
            let shift_Names = [];
            shiftsApiCall.map((shift) => {
                shift_Names.push(shift.property_name)
            })

            //Set No's Approved in Warping Production
            resetGlobalQuery();
            globalQuery.columns.push('set_no');
            globalQuery.table = "xtv_warping_production_order";
            globalQuery.conditions.push({ field: "warping_order_status", operator: "=", value: 'A', });
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
            globalQuery.orderBy = ['set_no DESC']
            const SetNoAPICall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setSetNoOptions(SetNoAPICall);

            ////Production Section
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name'];
            globalQuery.table = "xmv_production_section"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((getWarpingProdSecCall) => {
                setProdSectionOptions(getWarpingProdSecCall);
                setProdSection('18');
                comboOnChange('cmb_section_id');
            })
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'production_wastage_types_name']
            globalQuery.table = "xmv_production_wastage_types"
            globalQuery.conditions.push({ field: "section_id", operator: "=", value: 18 });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((wastageTypes) => {
                setProdWastageOptions(wastageTypes)
            })
            
            

        } catch (error) {
            console.log('error: ', error);
            navigate('/Error')

        } finally {
            setIsLoading(false);
        }
    }

    const comboOnChange = async (key) => {
        debugger
        try {
            setIsLoading(true);
            switch (key) {

                case 'cmb_section_id':
                    const prod_section_Id = $('#cmb_section_id').val();
                    ///// Production sub section Arr[]
                    resetGlobalQuery();
                    globalQuery.columns.push("field_id", 'field_name', 'production_sub_section_short_name');
                    globalQuery.table = "xmv_production_sub_section"
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    globalQuery.conditions.push({ field: "production_section_id", operator: "=", value: prod_section_Id });
                    let getProdSubSectionApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                    setProdSubSectionOptions(getProdSubSectionApiCall);

                    ////Godown Options
                    resetGlobalQuery();
                    globalQuery.columns = ['field_id', 'field_name', 'godown_name', 'godown_id']
                    globalQuery.table = "cmv_godown"
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    // globalQuery.conditions.push({ field: "production_sub_section_id", operator: "=", value: prodsubSectionId });
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    let godownTypeApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    setGodownNameOptions(godownTypeApiCall)

                    break;

                case 'cmb_sub_section_id':
                        const prodsubSectionId = $('#cmb_sub_section_id').val();
                    //     const prodsection_Id = $('#cmb_section_id').val();

                    //     resetGlobalQuery();
                    //     globalQuery.columns.push("field_id", 'field_name');
                    //     globalQuery.table = "cmv_machine"
                    //     globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    //     globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    //     globalQuery.conditions.push({ field: "section_id", operator: "=", value: prodsection_Id });
                    //     globalQuery.conditions.push({ field: "sub_section_id", operator: "=", value: prodsubSectionId });
                    //     comboDataAPiCall.current.fillFiltersCombo(globalQuery).then(getProdSubSectionApiCall => {
                    //         setMachineNameOption(getProdSubSectionApiCall)
                    //         console.log("sub section list: ", getProdSubSectionApiCall);
                    //     });


                    resetGlobalQuery();
                    globalQuery.columns = ['field_id', 'field_name', 'production_wastage_types_name']
                    globalQuery.table = "xmv_production_wastage_types"
                    globalQuery.conditions.push({ field: "section_id", operator: "=", value: 18 });
                    globalQuery.conditions.push({ field: "sub_section_id", operator: "=", value: prodsubSectionId });
                    // comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((wastageTypes) => {
                    //     setProdWastageOptions(wastageTypes)
                    // })
                    let wastageTypes = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    setProdWastageOptions(wastageTypes)

                    break;
            }
        } catch (error) {
            console.log('error: ', error);
            navigate('/Error')

        } finally {
            setIsLoading(false);
        }
    }

    const FnCheckUpdateRes = async () => {
        debugger
        try {
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtWeavingProductionWastage/FnShowParticularRecordForUpdate/${cmb_set_no}/${COMPANY_ID}`)
            const responce = await apiCall.json();
            let WeavingProductionWastageRecords = responce.WeavingProductionWastageRecords;


            setProductionSetNo(WeavingProductionWastageRecords[0]['production_set_no']);
            setProdSection(WeavingProductionWastageRecords[0]['section_id'])
            await comboOnChange('cmb_section_id');
            setProdSubSection(WeavingProductionWastageRecords[0]['sub_section_id']);

            console.log('Required Data :' + WeavingProductionWastageRecords);
            setWastageDetails(WeavingProductionWastageRecords);
        } catch (error) {

        } finally {

        }
    }

    var validateWastageTbl = () => {
        debugger
        try {
            let wastageDetailsData = [...wastageDetails];
            let validation = true;

            for (let i = 0; i < wastageDetailsData.length; i++) {
                let data = wastageDetailsData[i];

                for (let key in data) {
                    let tabledataId = $("#" + key + `_${i}`);

                    if (tabledataId.is(':visible') && (['', 0, '0', null].includes(tabledataId.val()))) {
                        if (tabledataId.attr('type') === 'text') {
                            tabledataId.parent().attr('data-tip', 'Please Enter proper data...!');
                        } else if (tabledataId.is('select')) {
                            tabledataId.parent().attr('data-tip', 'Please Select atleast one...!');
                        } else if (tabledataId.attr('type') === 'time') {
                            tabledataId.parent().attr('data-tip', 'Please Select Time...!');
                        }

                        tabledataId.focus();
                        validation = false;
                        break;
                    }
                }

                if (!validation) {
                    break; // Exit outer loop if validation failed
                }
            }

            return validation; // Return the overall validation status
        } catch (error) {
            console.error("Validation error:", error); // Log any errors
            return false; // Return false if an error occurs
        }
    }

    const FnManageWastageDetailsTbl = (action, rowIndex) => {
        try {
            let wastageDetailsData = [...wastageDetails];

            if (action === 'add') {
                if (validateWastageTbl() === true) {
                    // Add a new empty object at the beginning of the array
                    setWastageDetails([...wastageDetailsData, wastageEmptyObj]);
                }
            } else {
                // Remove the specified row
                wastageDetailsData.splice(rowIndex, 1);
                if (wastageDetailsData.length > 0) {
                    setWastageDetails(wastageDetailsData);
                } else {
                    // If the last row is removed, add an empty object to keep the array non-empty
                    setWastageDetails([wastageDetailsData]);
                }
            }
        } catch (error) {

        }
    }


    const renderMachineWastageTable = useMemo(() => {
        return <Table className='erp_table' id="machinewastagetableId" responsive bordered striped>
            <thead className="erp_table_head">
                <tr>
                    <td className={`erp_table_th ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>Action</td>
                    <td className="erp_table_th" style={{ width: "auto" }} >Production Date</td>
                    <td className="erp_table_th " style={{ width: "300px" }}>Shift</td>
                    <td className="erp_table_th " style={{ width: "150px" }}>Wastage Type</td>
                    <td className="erp_table_th " style={{ width: "300px" }}>Wastage Quantity</td>
                    <td className="erp_table_th " style={{ width: "300px" }}>Godown</td>


                </tr>
            </thead>
            <tbody>
                {wastageDetails.length > 0 ? (
                    wastageDetails?.map((details, indexOfItem) => (
                        <tr rowIndex={indexOfItem}>
                            <td className={`erp_table_th ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>
                                <IoAddCircleOutline className='erp_trAdd_icon' onClick={() => FnManageWastageDetailsTbl('add', indexOfItem)} />
                                <IoRemoveCircleOutline className='erp_trRemove_icon' onClick={() => FnManageWastageDetailsTbl('remove', indexOfItem)} />
                            </td>

                            <td className="erp_table_td">
                                <input type="date" className="erp_input_field mb-0"
                                    Headers="production_date" id={`production_date_${indexOfItem}`} disabled={keyForViewUpdate === 'view'}
                                    value={details.production_date} onChange={(e) => updateWastageDetails("production_date", details, e)} />
                            </td>

                            <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                                <select id={`shift_${indexOfItem}`} className="form-select form-select-sm" value={details.shift} onChange={(e) => { updateWastageDetails('shift', details, e) }} disabled={keyForViewUpdate === 'view'}>
                                    <option value="">Select</option>
                                    {shiftOptions.length !== 0 ? (
                                        <>
                                            {shiftOptions?.map(shift => (
                                                <option value={shift.field_name}>{shift.field_name}</option>
                                            ))}
                                        </>
                                    ) : null
                                    }
                                </select>
                            </td>

                            <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                                <select id={`production_wastage_types_id_${indexOfItem}`} disabled={keyForViewUpdate === 'view'} className="form-select form-select-sm" value={details.production_wastage_types_id} onChange={(e) => updateWastageDetails('production_wastage_types_id', details, e)}>
                                    <option value="">Select</option>
                                    {prodWastageOptions.length !== 0 ? (
                                        <>
                                            {prodWastageOptions?.map(wasteoption => (
                                                <option value={wasteoption.field_id}>{wasteoption.field_name}</option>
                                            ))}
                                        </>
                                    ) : null
                                    }
                                </select>
                            </td>

                            <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                                <input
                                    type="text" disabled={keyForViewUpdate === 'view'}
                                    className="erp_input_field_table_txt remove0 mb-0 text-end"
                                    id={`wastage_quantity_${indexOfItem}`}
                                    style={{ backgroundColor: '#AFE1AF' }}
                                    value={details.wastage_quantity} onChange={(e) => updateWastageDetails('wastage_quantity', details, e)}
                                />
                            </td>

                            <td key={indexOfItem} className="erp_table_td" id={`${details}{indexOfItem}`}>
                                <select id={`godown_id_${indexOfItem}`} value={details.godown_id} className="erp_input_field_table_txt mb-0" Headers="godown_id" onChange={(e) => { updateWastageDetails('godown_id', details, e); }}
                                    style={{ width: 'auto' }} disabled={keyForViewUpdate === 'view'}>
                                    <option value="">Select</option>
                                    {godownNameOptions?.map(godownType => (
                                        <option value={godownType.godown_id}>{godownType.godown_name}</option>
                                    ))}
                                </select>
                            </td>

                        </tr>
                    ))) : (
                    <tr>
                        <td className="erp_table_td" colSpan="10">
                            No records found...
                        </td>
                    </tr>
                )}
            </tbody>
        </Table>

    }, [wastageDetails, prodsubsectionOptions,prodWastageOptions]);

    const updateWastageDetails = (columnName, currentMaterial, event) => {
        try {
            debugger
            let wastageDetailsData = [...wastageDetails];
            const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'));

            $("#" + event.target.id).parent().removeAttr('data-tip');
            currentMaterial[columnName] = event.target.value;

            wastageDetailsData[arrayIndex] = currentMaterial;
            setWastageDetails(wastageDetailsData);
        } catch (error) {

        }
    }


    const validateFormFields = () => {
        validate.current.validateFieldsOnChange('wastageFormId');
    }


    const FnSaveWastageEntry = async () => {
        debugger;
        try {
            setIsLoading(true);
            let validateFrom = await validate.current.validateForm('wastageFormId');
            if (validateFrom === true) {
                if (validateWastageTbl() === true) {
                    const json = { 'TransWVProdWastageData': [], 'commonIds': { 'company_id': COMPANY_ID, 'production_set_no': cmb_set_no } }

                    for (let index = 0; index < wastageDetails.length; index++) {

                        let wastageElement = wastageDetails[index];
                        const wastageObj = {
                            company_id: COMPANY_ID,
                            company_branch_id: COMPANY_BRANCH_ID,
                            financial_year: FINANCIAL_SHORT_NAME,
                            section_id: cmb_section_id,
                            sub_section_id: cmb_sub_section_id,
                            production_set_no: cmb_set_no,
                            production_date: wastageElement.production_date,
                            weaving_production_wastage_id: wastageElement.weaving_production_wastage_id,
                            production_wastage_types_id: wastageElement.production_wastage_types_id,
                            wastage_quantity: wastageElement.wastage_quantity,
                            godown_id: wastageElement.godown_id,
                            shift: wastageElement.shift,
                            created_by: UserName,
                            modified_by: UserName
                        }

                        json.TransWVProdWastageData.push(wastageObj);
                    }

                    const formData = new FormData()
                    formData.append('WeavingProductionWatageData', JSON.stringify(json))
                    const forwardData = {
                        method: 'POST',
                        body: formData,
                    }
                    const getWarpingDetails = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtWeavingProductionWastage/FnAddUpdateRecord`, forwardData)
                    const response = await getWarpingDetails.json();
                    if (response.success === 0) {
                        setErrMsg(response.error)
                        setShowErrorMsgModal(true)
                    } else {
                        setSuccMsg(response.message)
                        setShowSuccessMsgModal(true)
                    }
                }
            }

        } catch (error) {

        } finally {
            setIsLoading(false);
        }
    }

    const requestTypeHeading = () => {
        switch (requestfor) {
          case 'WVWP':
            return '(Warping)';
          case 'WVSZ':
            return '(Sizing)';
          case 'WVWV':
            return '(Weaving)';
          
        }
      }


    return (
        <>
            <DashboardLayout>
                <ComboBox ref={comboDataAPiCall} />
                <ValidateNumberDateInput ref={ValidateNumberDateInput} />
                <GenerateMaterialId ref={generateAutoNoAPiCall} />
                <FrmValidations ref={validate} />

                {isLoading ?
                    <div className="spinner-overlay"  >
                        <div className="spinner-container">
                            <CircularProgress color="primary" />
                            <span>Loading...</span>
                        </div>
                    </div> :
                    ''}

                {/*.........................Shiftwise Machine Stoppage Entry............................... */}
                <div className="col-lg-12 col-12 erp_form_col_div">
                    <div className="col-lg-12 col-12 erp_form_col_div">
                        <div className="card">

                            <div className='card-header text-center py-0'>
                                <label className='erp-form-label-lg text-center'>Wastage Entry {requestTypeHeading()}{actionType}</label>
                            </div>
                            {/* Card's Body Starts*/}
                            <div className="card-body p-0">
                                <form id='wastageFormId'>
                                    <div className='row p-1'>
                                        <div className='col-sm-4 erp_form_col_div'>
                                            <div className='row'>
                                                <div className='col-sm-5'>
                                                    <Form.Label className="erp-form-label">Set No.<span className="required">*</span> </Form.Label>
                                                </div>
                                                <div className="col-sm-6">
                                                    <select id="cmb_machine_no" className="form-select form-select-sm" value={cmb_set_no} disabled={keyForViewUpdate === 'view'} onChange={(e) => { setProductionSetNo(e.target.value); validateFormFields(); }}>
                                                        <option value="">Select</option>
                                                        {setNoOptions.length !== 0 ? (
                                                            <>
                                                                {setNoOptions?.map(setno => (
                                                                    <option value={setno.set_no}>{setno.set_no}</option>
                                                                ))}
                                                            </>
                                                        ) : null}
                                                    </select>
                                                    <MDTypography variant="button" id="error_cmb_machine_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                        </div>


                                        <div className='col-sm-4 erp_form_col_div'>
                                            <div className='row'>
                                                <div className='col-sm-4'>
                                                    <Form.Label className="erp-form-label">Section <span className="required">*</span> </Form.Label>
                                                </div>
                                                <div className="col-sm-6">
                                                    <select id="cmb_section_id" className="form-select form-select-sm" value={cmb_section_id} onChange={(e) => { setProdSection(e.target.value); validateFormFields(); }} disabled>
                                                        <option value="">Select</option>
                                                        {prodsectionOptions.length !== 0 ? (
                                                            <>
                                                                {prodsectionOptions?.map(production => (
                                                                    <option value={production.field_id}>{production.field_name}</option>
                                                                ))}
                                                            </>
                                                        ) : null}
                                                    </select>
                                                    <MDTypography variant="button" id="error_cmb_section_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-sm-4 erp_form_col_div'>
                                            <div className='row'>
                                                <div className='col-sm-4'>
                                                    <Form.Label className="erp-form-label">Sub Section <span className="required">*</span> </Form.Label>
                                                </div>
                                                <div className="col-sm-6">
                                                    <select id="cmb_sub_section_id" className="form-select form-select-sm" value={cmb_sub_section_id} disabled={keyForViewUpdate === 'view'} onChange={(e) => { setProdSubSection(e.target.value); comboOnChange('cmb_sub_section_id'); validateFormFields(); }}>
                                                        <option value="">Select</option>
                                                        {prodsubsectionOptions.length !== 0 ? (
                                                            <>
                                                                {prodsubsectionOptions?.map(subproduction => (
                                                                    <option key={subproduction.field_id} shortName={subproduction.production_sub_section_short_name} value={subproduction.field_id}>
                                                                        {subproduction.field_name}
                                                                    </option>
                                                                ))}
                                                            </>
                                                        ) : null}
                                                    </select>
                                                    <MDTypography variant="button" id="error_cmb_sub_section_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </form>

                                <div className='row p-1'>

                                    {/* Machine wise Wastage Details table */}
                                    <div className='col erp_form_col_div'>
                                        {renderMachineWastageTable}
                                    </div>

                                    <div className='row'>
                                        <div className='col'>
                                            <div className={`erp_frm_Btns  mt-2 mb-2 `}>
                                                <MDButton type="button" id="action_Button" className={`erp-gb-button ms-2 disable-button`} variant="button" onClick={() => handleCloseSuccessModal()} fontWeight="regular">Back</MDButton>
                                                <MDButton type="button" id="action_Button" className={`erp-gb-button ms-2 disable-button ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`} variant="button" onClick={() => FnSaveWastageEntry()} fontWeight="regular">{labelForWastage}</MDButton>
                                            </div >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />

            </DashboardLayout>
        </>
    )
}


export default FrmWastageEntry
