import React, { useEffect, useRef, useMemo } from 'react'
import { useState } from 'react'
import ReactDOMServer from 'react-dom/server';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import $ from 'jquery';
import { useNavigate, useParams } from "react-router-dom";
import MDTypography from 'components/MDTypography';
import ComboBox from 'Features/ComboBox';
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ConfigConstants from "assets/Constants/config-constant";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import ValidateNumberDateInput from 'FrmGeneric/ValidateNumberDateInput';
import FrmValidations from 'FrmGeneric/FrmValidations';
// Imports React bootstrap & stylling required
import { CircularProgress } from "@material-ui/core";
import { Form, Table, Card } from "react-bootstrap";
import MDButton from "components/MDButton";
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'
import ExcelExport from "Features/Exports/ExcelExport";
import Select from 'react-select';




function FrmIssueReportEntry() {
    const configConstants = ConfigConstants();
    const { COMPANY_NAME, COMPANY_ID, COMPANY_BRANCH_ID, COMPANY_ADDRESS, UserName, FINANCIAL_SHORT_NAME } = configConstants;
    const { requestfor } = useParams();
    const navigate = useNavigate();

    //Current date
    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const [dt_from_date, setFromDt] = useState(today);
    const [dt_to_date, setToDt] = useState(today);

    //Ref Hooks
    const validate = useRef();
    const comboDataAPiCall = useRef();
    const validateNumberDateInput = useRef();

    // Helping hooks;
    const [isLoading, setIsLoading] = useState(false);
    // Ref Hooks For Print-Out.    
    const printComponentRef = useRef();
    const [isPrinting, setIsPrinting] = useState(false);
    const promiseResolveRef = useRef(null);
    const exlsExp = useRef();

    //data hook
    const [issueReportGroupedData, setIssueReportGroupedData] = useState([])
    const [issueReportData, setIssueReportData] = useState([])
    const [reportType, setReportType] = useState('summary');

    //Hooks for Cost centre search combo;;
    let costCenterComboRef = useRef(null);
    let productTypeComboRef = useRef(null);
    const [cost_centerOptions, setCostCenterOptions] = useState([]);
    const [product_typeOptions, setProductTypeOptions] = useState([]);
    const [cmb_cost_center, setCostCenter] = useState("All");
    const [product_tpe, setProductType] = useState("");


    useEffect(() => {
        const loadIssueReport = async () => {
            setIsLoading(true)
            loadCombobox();
            FnShowIssueDetailsData();
            setIsLoading(false)
        }
        loadIssueReport()
    }, [reportType, requestfor])

    // ***----------- Helping Functions.
    const goBack = () => { navigate("/Dashboard") }



    // Get Current DateTime
    const FnGetCurrentDateTime = () => {
        let currentDate = new Date(); // Get the current date and time
        // Format the current date and time as "DD-MM-YYYY HH:mm:ss"
        let formattedDateTime = `${String(currentDate.getDate()).padStart(2, '0')}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${currentDate.getFullYear()} ${String(currentDate.getHours()).padStart(2, '0')}:${String(currentDate.getMinutes()).padStart(2, '0')}:${String(currentDate.getSeconds()).padStart(2, '0')}`;
        return formattedDateTime;
    }
    // ****-------Validation Functions Starts--------------***
    const FnChkIsValidFilters = () => {
        const fromDate = new Date($("#dt_from_date").val());
        const toDate = new Date($("#dt_to_date").val());

        let isValid = true;

        if (!dt_from_date) {
            $('#error_dt_from_date').text('From Date is required.').show();
            isValid = false;
        } else {
            $('#error_dt_from_date').hide();
        }
        if (!dt_to_date) {
            $('#error_dt_to_date').text('To Date is required.').show();
            isValid = false;
        } else {
            $('#error_dt_to_date').hide();
        }
        if (dt_from_date && dt_to_date && fromDate > toDate) {
            $('#error_dt_from_date').text('From Date cannot be greater than To Date.');
            $('#error_dt_from_date').show();
            isValid = false;
        } else if (dt_from_date && dt_to_date) {
            $('#error_dt_from_date').hide();
        }
        return isValid;
    };

    // ****-------Validation Functions Ends--------------***

    // ****-------Get Cost Center Data Function--------------***
    const loadCombobox = () => {
        try {
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'product_type_short_name'];
            globalQuery.table = "smv_product_type";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                .then(productTypeList => {
                    const productTypeOpts = [
                        //   { value: 'All', label: 'All', centerShortName: '' },
                        ...productTypeList.map((productType) => ({ ...productType, value: productType.field_id, label: productType.field_name, product_type_short_name: productType.product_type_short_name, })),
                    ];
                    setProductTypeOptions(productTypeOpts);
                    if (productTypeOpts.length !== 0) {
                        const productType = productTypeOpts.find((item) => item.product_type_short_name === requestfor);
                        if (productType) {
                            setProductType(productType.field_id);
                        }
                    }

                });




            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'cost_center_short_name'];
            globalQuery.table = "fmv_cost_center";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            comboDataAPiCall.current.removeCatcheFillCombo(globalQuery)
                .then(costCenterList => {
                    const costCenterOpts = [
                        { value: 'All', label: 'All', centerShortName: '' },
                        ...costCenterList.map((costcenter) => ({ ...costcenter, value: costcenter.field_id, label: `[${costcenter.cost_center_short_name}] ${costcenter.field_name}`, centerShortName: costcenter.cost_center_short_name, })),
                    ];
                    setCostCenterOptions(costCenterOpts);
                });

        } catch (error) {
            console.log("error : ", error)
        }
    }

    // ****-------show data Function --------------***
    const FnShowIssueDetailsData = async () => {
        try {
            let isValidFilters = FnChkIsValidFilters();
            if (!isValidFilters) { return false; }
            setIsLoading(true)
            let commonIds = {};
            // Common Ids 
            commonIds.company_id = COMPANY_ID
            commonIds.from_date = dt_from_date
            commonIds.to_date = dt_to_date
            commonIds.cost_center_id = (cmb_cost_center).toString();
            commonIds.productTypeId = (product_tpe).toString();
            //ok
            console.log(commonIds);
            const formData = new FormData();
            formData.append(`commonIds`, JSON.stringify(commonIds))
            const requestOptions = {
                method: 'POST',
                body: formData
            };

            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/IsuueReport/getStoresIssueReport/${reportType}`, requestOptions)
            const responce = await apiCall.json();
            console.log("stock Issue Report : ", responce);
            if (responce.success === 1) {
                debugger
                // Get the issueReportData
                const issueReportData = responce.issueReportData;

                // Create an object to group data by cost_center_name
                const groupedData = {};

                // Loop through the data and group by cost_center_name
                issueReportData.forEach(item => {
                    const { cost_center_name } = item;

                    // If cost_center_name doesn't exist in the groupedData, initialize it
                    if (!groupedData[cost_center_name]) {
                        groupedData[cost_center_name] = {
                            rows: []
                        };
                    }

                    // Push the current item to the respective cost center's rows array
                    groupedData[cost_center_name].rows.push(item);

                });
                console.log(groupedData);
                setIssueReportData(groupedData);
            }


        } catch (error) {
            console.log(error);
            setIsLoading(false)
        } finally {
            setIsLoading(false)
        }
    }

    // ********* PrintOut Functionalities Starts. ****************************************
    useEffect(() => {
        if (isPrinting && promiseResolveRef.current) {
            promiseResolveRef.current();
        }
    }, [isPrinting]);

    const FnPrintIssueReportSheet = useReactToPrint({

        content: () => {
            debugger
            const headerHtml = printOutHeader(); // Call function to get header HTML

            const contentWrapper = document.createElement('div');
            contentWrapper.className = 'printable-content';
            contentWrapper.innerHTML = headerHtml; // Add generated header HTML

            // Append the main content to the wrapper
            const mainContentClone = printComponentRef.current.cloneNode(true);
            contentWrapper.appendChild(mainContentClone);

            return contentWrapper;
        },
        onBeforePrint: () => {
            // Apply the desired font size before printing
            if (printComponentRef.current) {
                const table = printComponentRef.current.querySelector('.erp_table');
                if (table) {
                    table.style.fontSize = '7px'; // Set your desired font size
                }
            }
        },
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                promiseResolveRef.current = resolve;
                setIsPrinting(true);
            });
        },
        onAfterPrint: () => {
            // Reset the Promise resolve so we can print again
            promiseResolveRef.current = null;
            setIsPrinting(false);
        },
        documentTitle: `Issue Report (${dt_from_date} to ${dt_to_date})`
    });

    const printOutHeader = () => {
        return ReactDOMServer.renderToString(
            <>
                <div className='px-0 border border-dark'> {/* border border-dark */}
                    <div className='row mb-0 p-1'>
                        <div className="col-sm-1">
                            <img src={DakshabhiLogo} alt="master card" width="210" height="80" mt={1} />
                        </div>
                        <div className="col-sm-11">
                            <div className='erp-invoice-print-label text-center'>
                                <span className='erp-invoice-print-label-lg'>{COMPANY_NAME}</span><br />
                            </div>
                            <div className='erp-invoice-print-label-lg text-center'>
                                Issue Report
                                {
                                    `(${validateNumberDateInput.current.formatDateToDDMMYYYY(dt_from_date)} to ${validateNumberDateInput.current.formatDateToDDMMYYYY(dt_to_date)})`
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };
    // ********* PrintOut Functionalities Ends. ****************************************

    // ********* ExportToExcel Functionalities Starts. ****************************************
    const addExcelFilter = (label, key, value, existingFilters) => {
        if (label !== '' && label !== 'All') { existingFilters[key] = `${value}: ${label}`; }
    };

    const FnExportToExcel = async () => {

        setIsLoading(true);
        try {
            let commonIds = {};
            // Common Ids 
            debugger
            commonIds.company_id = COMPANY_ID
            commonIds.from_date = dt_from_date
            commonIds.to_date = dt_to_date
            commonIds.productTypeId = product_tpe.toString()
            commonIds.cost_center_id = cmb_cost_center
            commonIds.reportType = reportType === 'summary' ? 'summary' : 'details';
            commonIds.COMPANY_NAME = COMPANY_NAME
            commonIds.COMPANY_ADDRESS = COMPANY_ADDRESS
            commonIds.ReportName = `Issue Report ${requestfor==="RM"?"General Stores & Spares":"Raw Material"}`
            commonIds.SheetName = "Issue Report"
            const formData = new FormData();
            formData.append(`commonIds`, JSON.stringify(commonIds))
            const requestOptions = {
                method: 'POST',
                body: formData
            };

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/IsuueReport/getExportIssueReport`, requestOptions)
            // Ensure the response is ok (status 200)
            debugger
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            // Get the response as a blob (binary large object)
            const blob = await response.blob();
            // Create a URL for the blob object
            const url = window.URL.createObjectURL(new Blob([blob]));
            // Create a link element to download the file
            const link = document.createElement('a');
            link.href = url;
            const today = new Date();
            const formattedDate = `${today.getDate().toString().padStart(2, '0')}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getFullYear()}`;
            link.setAttribute('download', `${commonIds.ReportName}_${reportType}_${formattedDate}.xlsx`);
            document.body.appendChild(link);
            // Trigger the download by simulating a click
            link.click();
            // Clean up by removing the link from the document
            document.body.removeChild(link);
            console.log("File downloaded successfully");

        } catch (error) {
            console.log(error);

        } finally {
            setIsLoading(false);
        }

    }
    // ********* ExportToExcel Functionalities Ends. ****************************************


    return (
        <>
            <DashboardLayout>
                <ComboBox ref={comboDataAPiCall} />
                <FrmValidations ref={validate} />
                <ValidateNumberDateInput ref={validateNumberDateInput} />
                <ExcelExport ref={exlsExp} />

                {isLoading ?
                    <div className="spinner-overlay"  >
                        <div className="spinner-container">
                            <CircularProgress color="primary" />
                            <span>Loading...</span>
                        </div>
                    </div> :
                    ''}

                <div className='card mt-4'>
                    <div className='card-header text-center py-0'>
                        <label className='erp-form-label-lg text-center'>Issue Report ({requestfor==="RM"?"General Stores & Spares":"Raw Material"})</label>
                    </div>

                    {/* Card's Body Starts*/}
                    <div className="card-body">
                        <div className='row p-1'>
                            <div className="erp_form_radio">
                                <div className="fCheck">
                                    <Form.Check
                                        className="erp_radio_button erp-form-label-md"
                                        label="Summary"
                                        type="radio"
                                        value="summary"
                                        name="summaryOrDetails"
                                        onChange={(e) => setReportType(e.target.value)}
                                        defaultChecked
                                    />
                                </div>
                                <div className="sCheck">
                                    <Form.Check
                                        className="erp_radio_button erp-form-label-md"
                                        label="Details"
                                        value="details"
                                        type="radio"
                                        onChange={(e) => setReportType(e.target.value)}
                                        name="summaryOrDetails"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='row p-1'>
                            <div className='col-sm-4 erp_form_col_div'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">From Date <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Form.Control type="date" id='dt_from_date' className="erp_input_field" value={dt_from_date} onChange={(e) => { setFromDt(e.target.value); FnChkIsValidFilters(); }} />
                                        <MDTypography variant="button" id="error_dt_from_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                            </div>

                            <div className='col-sm-4 erp_form_col_div'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">To Date <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Form.Control type="date" id='dt_to_date' className="erp_input_field" value={dt_to_date} onChange={(e) => { setToDt(e.target.value); FnChkIsValidFilters(); }} />
                                        <MDTypography variant="button" id="error_dt_to_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row p-1'>
                            <div className='col-sm-4 erp_form_col_div'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Product Type:</Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Select
                                            ref={productTypeComboRef}
                                            inputId="cmb_cost_center"
                                            value={product_typeOptions.find(option => option.value === product_tpe) || null}
                                            options={product_typeOptions}
                                            onChange={(selectedOption) => {
                                                setProductType(selectedOption.value)
                                                productTypeComboRef.current = selectedOption;
                                            }}
                                            placeholder="Search for productType..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"
                                            isDisabled={true}
                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                })
                                            }}
                                        />

                                        <MDTypography variant="button" id="error_cmb_product_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                            </div>
                            {/* COST CENTRE COMBO BOX*/}
                            <div className='col-sm-4 erp_form_col_div'>

                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Cost Center:</Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Select
                                            ref={costCenterComboRef}
                                            inputId="cmb_cost_center"
                                            value={cost_centerOptions.find(option => option.value === cmb_cost_center) || null}
                                            options={cost_centerOptions}
                                            onChange={(selectedOption) => {
                                                setCostCenter(selectedOption.value)
                                                costCenterComboRef.current = selectedOption;
                                            }
                                            }
                                            placeholder="Search for Cost Center..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"

                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                })
                                            }}
                                        />

                                        <MDTypography variant="button" id="error_cmb_cost_center" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                            </div>

                        </div>


                        <div className="row justify-content-center mt-2">
                            <div className="col text-center">
                                <MDButton type="button" id="show-data-btn" className="erp-gb-button" onClick={(e) => { FnShowIssueDetailsData() }} variant="button" fontWeight="regular">show Data</MDButton>
                            </div>
                        </div>
                        {
                            issueReportData && Object.keys(issueReportData).length === 0
                                ? <> <hr /> <Card id="NoRcrdId" > <Card.Body>No records found...</Card.Body> </Card> </>
                                : <>
                                    <hr />
                                    <div className="row py-1">
                                        <div className="text-end">
                                            <MDButton type="button" variant="button" fontWeight="regular" className="erp-gb-button"
                                                onClick={(e) => { FnExportToExcel(); }}
                                            >Export Excel</MDButton>

                                            <MDButton type="button" variant="button" fontWeight="regular" className="erp-gb-button ms-1"
                                                onClick={(e) => { FnPrintIssueReportSheet(); }}
                                            >Print</MDButton>
                                        </div>
                                    </div>
                                    <div className="row px-lg-2 d-block">
                                        <div className="card p-0">
                                            {/* Card's Body Starts*/}
                                            <div className="card-body p-0 print-content" ref={printComponentRef}>
                                                <StoreIssueReportTbl
                                                    issueReportData={issueReportData}
                                                    validateNumberDateInput={validateNumberDateInput}
                                                    reportType={reportType}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                        }

                        <hr />
                        <div className="row text-center mt-3">
                            <div className=''>
                                <MDButton type="button" id="back-btn" variant="button" fontWeight="regular"
                                    className={`erp-gb-button float-center`}
                                    onClick={(e) => { goBack(); }}  > Back </MDButton>

                                {
                                    issueReportData && Object.keys(issueReportData).length === 0
                                        ? null
                                        : <>
                                            <MDButton type="button" variant="button" fontWeight="regular"
                                                className="erp-gb-button ms-2"
                                                onClick={(e) => { FnPrintIssueReportSheet(); }} >Print</MDButton>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                    {/* Card's Body Ends*/}
                </div>
                {/* Card Ends*/}


            </DashboardLayout>
        </>
    )
}

export default FrmIssueReportEntry




export const StoreIssueReportTbl = ({
    issueReportData,
    validateNumberDateInput,
    reportType
}) => {

    return (
        <>
            <style>
                {`
                    @media print {
                        .print-content {
                            padding: 0;
                            box-sizing: border-box;
                        }
                        @page {
                            margin: 0;
                            size: legal landscape;
                            margin: 1%;
                            border: 2px solid black;
                        }
                        html, body {
                            width: 100%;
                            height: 100%;
                        }
                        body {
                            margin: 0;
                            padding: 0;
                        }
                    }
                    .erp_table {
                        font-size: 3px !important; /* Default font size */
                    }
                    .erp_table_th, .erp_table_td {
                        border: 1px solid black;
                        padding-top: 2px !important;
                        padding-bottom: 2px !important;
                    }
                `}
            </style>

            <Table id="issueReportTableId" className="erp_table" style={{ whiteSpace: 'normal' }}>
                <thead className="erp_table_head table-secondary text-center">
                    <tr style={{ whiteSpace: 'normal' }}>
                        <th className={`erp_table_th align-middle `} style={{ width: "70px" }}>Sr. No</th>
                        <th className={`erp_table_th align-middle ${reportType === 'summary' ? 'd-none' : 'display'}`} style={{ width: "250px" }}>Department Name</th>
                        <th className={`erp_table_th align-middle `} style={{ width: "250px" }}>Sub-Department Name</th>
                        <th className={`erp_table_th align-middle ${reportType === 'summary' ? 'd-none' : 'display'}`} style={{ width: "180px" }}>Profit Centre</th>
                        <th className="erp_table_th align-middle" style={{ width: "180px" }}>Cost Center</th>
                        <th className="erp_table_th align-middle" style={{ width: "250px" }}>Category1 Name</th>
                        <th className="erp_table_th align-middle" style={{ width: "250px" }}>Category2 Name</th>
                        <th className={`erp_table_th align-middle ${reportType === 'summary' ? 'd-none' : 'display'}`} style={{ width: "180px" }}>Material Code</th>
                        <th className={`erp_table_th align-middle ${reportType === 'summary' ? 'd-none' : 'display'}`} style={{ width: '360px' }}>Material Name</th>
                        <th className={`erp_table_th align-middle ${reportType === 'summary' ? 'd-none' : 'display'}`} style={{ width: "180px" }}>Unit</th>
                        <th className="erp_table_th align-middle" style={{ width: "180px" }}>Issued Qty</th>
                        <th className="erp_table_th align-middle" style={{ width: "180px" }}>Rate</th>
                        <th className="erp_table_th align-middle" style={{ width: "180px" }}>Value</th>
                        <th className={`erp_table_th align-middle ${reportType === 'summary' ? 'd-none' : 'display'}`} style={{ width: "180px" }}>Issue No</th>
                        <th className={`erp_table_th align-middle ${reportType === 'summary' ? 'd-none' : 'display'}`} style={{ width: "180px" }}>Issue Date</th>
                    </tr>
                </thead>
                <tbody>
                    {/* {
                        issueReportData.map((stockDetail, index) => (
                            <tr rowindex={index}>
                                <td className="erp_table_td text-end" > {index + 1} </td>
                                <td className="erp_table_td "> {stockDetail.profit_center_short_name} </td>
                                <td className="erp_table_td "> {stockDetail.cost_center_name} </td>
                                <td className="erp_table_td" style={{ whiteSpace: 'normal' }}> {stockDetail.product_category1_name} </td>
                                <td className="erp_table_td"> {stockDetail.material_code} </td>
                                <td className="erp_table_td" style={{ whiteSpace: 'normal' }}> {stockDetail.material_name} </td>
                                <td className="erp_table_td"> {stockDetail.material_unit_name} </td>
                                <td className="erp_table_td text-end"> {stockDetail.issue_quantity} </td>
                                <td className="erp_table_td text-end"> {stockDetail.batch_rate} </td>
                                <td className="erp_table_td text-end"> {stockDetail.material_value} </td>
                                <td className="erp_table_td "> {stockDetail.issue_no} </td>
                                <td className="erp_table_td "> {validateNumberDateInput.current.formatDateToDDMMYYYY(stockDetail.issue_date)} </td>
                            </tr>
                        ))
                    }
                    <tr className='table-light'>
                        <td className="erp_table_td align-middle" colSpan='7'><strong>Totals: </strong> </td>
                        <th className="erp_table_th text-end" style={{ width: '70px' }}>
                            {issueReportData.reduce((sum, summary) => sum + summary.issue_quantity, 0)}
                        </th>
                        <th className="erp_table_th text-end" style={{ width: '70px' }}>
                            {issueReportData.reduce((sum, summary) => sum + summary.batch_rate, 0).toFixed(2)}
                        </th>
                        <th className="erp_table_th text-end" style={{ width: '70px' }}>
                            {issueReportData.reduce((sum, summary) => sum + summary.material_value, 0).toFixed(2)}
                        </th>
                        <td className="erp_table_td" ></td>
                        <td className="erp_table_td" ></td>
                    </tr> */}


                    {Object.keys(issueReportData).map((costCenterName) => {
                        const { rows, total } = issueReportData[costCenterName];
                        return (
                            <React.Fragment key={costCenterName}>
                                {/* Render rows for each cost center */}
                                {rows.map((row, index) => (
                                    <tr key={index}>
                                        <td className="erp_table_td" > {index + 1} </td>
                                        <td className={`erp_table_td  ${reportType === 'summary' ? 'd-none' : 'display'}`}> {row.department_name} </td>
                                        <td className={`erp_table_td  `}> {row.sub_department_name} </td>
                                        <td className={`erp_table_td ${reportType === 'summary' ? 'd-none' : 'display'}`}> {row.profit_center_short_name} </td>
                                        <td className="erp_table_td "> {row.cost_center_name} </td>
                                        <td className={`erp_table_td `} style={{ whiteSpace: 'normal' }}> {row.product_category1_name} </td>
                                        <td className={`erp_table_td `} style={{ whiteSpace: 'normal' }}> {row.product_category2_name} </td>
                                        <td className={`erp_table_td ${reportType === 'summary' ? 'd-none' : 'display'}`}> {row.material_code} </td>
                                        <td className={`erp_table_td ${reportType === 'summary' ? 'd-none' : 'display'}`} style={{ whiteSpace: 'normal' }}> {row.material_name} </td>
                                        <td className={`erp_table_td ${reportType === 'summary' ? 'd-none' : 'display'}`}> {row.material_unit_name} </td>
                                        <td className="erp_table_td text-end"> {row.issue_quantity} </td>
                                        <td className="erp_table_td text-end">
                                            {validateNumberDateInput.current.decimalNumber(
                                                row.batch_rate,
                                                4
                                            )}</td>
                                        <td className="erp_table_td text-end"> {row.material_value} </td>
                                        <td className={`erp_table_td ${reportType === 'summary' ? 'd-none' : 'display'}`}> {row.issue_no} </td>
                                        <td className={`erp_table_td ${reportType === 'summary' ? 'd-none' : 'display'}`}> {validateNumberDateInput.current.formatDateToDDMMYYYY(row.issue_date)} </td>
                                    </tr>
                                ))}

                                {/* Total row */}
                                <tr className='table-light'>
                                    <td className={`erp_table_td align-middle `} colSpan={reportType === 'summary' ? 5 : 10} ><strong>Totals: </strong> </td>
                                    <th className="erp_table_th text-end" style={{ width: '70px' }}>
                                        {rows.reduce((sum, summary) => sum + summary.issue_quantity, 0)}
                                    </th>
                                    <th className="erp_table_th text-end" style={{ width: '70px' }}>
                                        {validateNumberDateInput.current.decimalNumber(
                                            rows.reduce((sum, summary) => sum + summary.batch_rate, 0).toFixed(2),
                                            4
                                        )}
                                    </th>

                                    <th className="erp_table_th text-end" style={{ width: '70px' }}>
                                        {rows.reduce((sum, summary) => sum + summary.material_value, 0).toFixed(2)}
                                    </th>
                                    <td className={`erp_table_td ${reportType === 'summary' ? 'd-none' : 'display'}`}></td>
                                    <td className={`erp_table_td ${reportType === 'summary' ? 'd-none' : 'display'}`}></td>
                                </tr>
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </Table>
        </>
    );
};