import React from 'react';
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'
import ConfigConstants from "assets/Constants/config-constant";
import { isArray } from 'jquery';


const SizedYarnDispatch = React.forwardRef(({ invoiceContent }, ref) => {
    // const configConstants = ConfigConstants();
    // const { AFTER_DECIMAL_PLACES } = configConstants;/
    let AFTER_DECIMAL_PLACES = 2;

    const itemData = invoiceContent.company;
    const sizedYarnDipatchDetails = invoiceContent.sizedYarnDetailsData;
    let dispatch_payment_terms = "[Immediate, 1 Week, 36 days credit, AGENTS DELIVERY]";
    let paymentTerms = "";

    // Check if dispatch_payment_terms is an array or a string
    let dispatchPaymentTermsArray;
    
    if (Array.isArray(invoiceContent.footer.dispatch_payment_terms)) {
        // If it's already an array, assign it directly
        dispatchPaymentTermsArray = invoiceContent.footer.dispatch_payment_terms;
    } else if (typeof invoiceContent.footer.dispatch_payment_terms === 'string') {
        // If it's a string, convert it into an array
        dispatchPaymentTermsArray = invoiceContent.footer.dispatch_payment_terms
            .replace(/^\[|\]$/g, "") // Remove the square brackets
            .split(",") // Split by comma
            .map(item => item.trim()); // Trim extra spaces
    } else {
        // Handle the case where it's neither an array nor a string
        dispatchPaymentTermsArray = []; // or handle it as needed
    }
    
    // Function to process and join the terms
    let dispatchPaymentTerms = () => {
        // Reset paymentTerms for each call
        paymentTerms = ""; 
    
        dispatchPaymentTermsArray.forEach((element, index) => {
            paymentTerms += element + (index !== dispatchPaymentTermsArray.length - 1 ? ", " : "");
        });
        
        return paymentTerms;
    };

    const invoiceHeader = (
        <>
            <div className='bordered border-bottom-0 px-0'>
                {/* Company Data */}
                <div className='row mb-0 p-2'>
                    <div className="col-sm-3 text-center text-sm-start">
                        <img src={DakshabhiLogo} alt="master card" width="170" height="auto" mt={1} />
                    </div>
                    <div className="col-sm-9 transformed">
                        <div className='erp-invoice-print-label-lg text-center'>
                            {itemData.company}
                        </div>
                        <div className="row">
                            <div className="text-center">
                                <div className='erp-invoice-print-label text-center'>
                                    <div className='erp-invoice-print-label-md-lg'>
                                        <span className='erp-invoice-print-label-md-lg'>{itemData.company_address} ({itemData.company_branch}) {itemData.state_name}-{itemData.company_pincode} </span>
                                    </div>

                                    <div className='erp-invoice-print-label-md-lg'>
                                        <span className='erp-invoice-print-label-md-lg'>WebSite: <a href={itemData.company_website}>{itemData.company_website}</a> || Email: {itemData.company_emailId} ||  Cell:{itemData.company_cell_no} </span>
                                    </div>

                                    <div className='erp-invoice-print-label-md-lg'>
                                        <span className='erp-invoice-print-label-md-lg'>GSTN No: {itemData.company_gst_no} || PAN No:{itemData.company_pan_no} </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col'>
                            <dl className="erp-invoice-print-label-md-lg text-center" style={{ fontSize: "20px" }}>
                                <dt>Dispatch Sized Yarn</dt>
                            </dl>
                        </div>

                    </div>
                </div>


                <div className='row border-top border-dark p-0 m-0'>
                    <div className='col-sm-6'>
                        <dl className="erp-invoice-print-label-md-lg" style={{ fontSize: "10px" }}>
                            <dt>Challan No. & version: {invoiceContent.dispatchDetails.dispatch_challan_no} & {invoiceContent.dispatchDetails.dispatch_challan_version}</dt>
                        </dl>

                    </div>
                    <div className='col-sm-6'>
                        <dl className="erp-invoice-print-label-md-lg" style={{ fontSize: "10px" }}>
                            <dt>Challan Date: {invoiceContent.dispatchDetails.dispatch_challan_date}</dt>
                        </dl>
                    </div>

                </div>


                <div className='row p-0 m-0'>
                    <div className='col-sm-6'>
                        <dl className="erp-invoice-print-label-md-lg" style={{ fontSize: "10px" }}>
                            <dt>Challan Type: {invoiceContent.dispatchDetails.dispatch_challan_type}</dt>
                        </dl>
                    </div>

                    <div className='col-sm-6'>
                        <dl className="erp-invoice-print-label-md-lg" style={{ fontSize: "10px" }}>
                            <dt>Customer Order No: {invoiceContent.dispatchDetails.customer_order_no}</dt>
                        </dl>
                    </div>
                </div>

            </div>

            <div className='bordered border-bottom-0 px-0'>
                <div className="row p-0 m-0">
                    {/* Customer Details */}

                    <div className="col-sm-6 pe-2" style={{ borderRight: "2px solid #000" }}>
                        <dt className='erp-invoice-print-label-md-lg' style={{ fontSize: "10px" }}>Bill To Details:</dt>
                        <div className='erp-invoice-print-label-md-lg'>
                            {invoiceContent.customerDetails.customer}
                        </div>
                        <div className="erp-invoice-print-label-md-lg">
                            {invoiceContent.customerDetails.address}
                        </div>
                        <dl className="row pb-0 mb-0">
                            <dt class="col-sm-1 erp-invoice-print-label-md-lg" style={{ fontSize: "10px" }}>State:</dt>
                            <dt class="col-sm-3 erp-invoice-print-label-md-lg"> {invoiceContent.customerDetails.state}</dt>
                            <dd class="col-sm-1 erp-invoice-print-label-md-lg" style={{ fontSize: "10px" }}>City:</dd>
                            <dt class="col-sm-3 erp-invoice-print-label-md-lg">{invoiceContent.customerDetails.customer_city}</dt>
                        </dl>
                    </div>

                    <div className="col-sm-6 ps-3">
                        <dt className='erp-invoice-print-label-md-lg' style={{ fontSize: "10px" }}>Shipped To Details:</dt>
                        <div className='erp-invoice-print-label-md-lg'>
                            {invoiceContent.consigneeDetails.consignee_name}
                        </div>
                        <div className="erp-invoice-print-label-md-lg">
                            {invoiceContent.consigneeDetails.address}
                        </div>
                        <dl className="row pb-0 mb-0">
                            <dt class="col-sm-1 erp-invoice-print-label-md-lg" style={{ fontSize: "10px" }}>State:</dt>
                            <dt class="col-sm-3 erp-invoice-print-label-md-lg"> {invoiceContent.consigneeDetails.state}</dt>
                            <dd class="col-sm-1 erp-invoice-print-label-md-lg" style={{ fontSize: "10px" }}>City:</dd>
                            <dt class="col-sm-3 erp-invoice-print-label-md-lg">{invoiceContent.consigneeDetails.consignee_city_name}</dt>
                        </dl>
                    </div>


                </div>
            </div>


            <div className='bordered border-bottom-0 px-0'>
                <div className="row p-0 m-0">
                    {/* Customer Details */}

                    <div className="col-sm-4">
                        <dt className='erp-invoice-print-label-md-lg'> <span style={{ fontSize: "10px" }}>Yarn Count :</span>{invoiceContent.footer.product_material_name}</dt>
                        <dt className='erp-invoice-print-label-md-lg'> <span style={{ fontSize: "10px" }}>Total Ends :</span>{invoiceContent.footer.total_ends}</dt>
                        <dt className='erp-invoice-print-label-md-lg'> <span style={{ fontSize: "10px" }}>Set No :</span>{invoiceContent.footer.set_no}</dt>
                        <dt className='erp-invoice-print-label-md-lg'> <span style={{ fontSize: "10px" }}>Weight :</span>{invoiceContent.footer.weight}</dt>
                        <dt className='erp-invoice-print-label-md-lg'> <span style={{ fontSize: "10px" }}>Rate :</span>{invoiceContent.footer.rate}</dt>
                        <dt className='erp-invoice-print-label-md-lg'> <span style={{ fontSize: "10px" }}>Payment Terms :</span>{dispatchPaymentTerms()}</dt>

                    </div>

                    <dl className="col-sm-4">
                        <dt className='erp-invoice-print-label-md-lg'> <span style={{ fontSize: "10px" }}>Yarn Bill Number :</span>{invoiceContent.footer.yarn_bill_no}</dt>
                        <dt className='erp-invoice-print-label-md-lg'> <span style={{ fontSize: "10px" }}>Driver Name :</span>{invoiceContent.footer.driver_name}</dt>
                        <dt className='erp-invoice-print-label-md-lg'> <span style={{ fontSize: "10px" }}>Vehicle Number :</span>{invoiceContent.footer.vehicle_no}</dt>
                    </dl>


                    <div className="col-sm-4">
                        <dt className='erp-invoice-print-label-md-lg'> <span style={{ fontSize: "10px" }}>Agent Name :</span>{invoiceContent.agentDetails.agent_name}</dt>
                        <dt className='erp-invoice-print-label-md-lg'> <span style={{ fontSize: "10px" }}>Agent Address :</span>{invoiceContent.agentDetails.agent_address1}</dt>
                    </div>


                </div>
            </div>
        </>
    )

    const invoiceFooter = (
        <div className="invoice-footer">
            <div className='row'>
                <div className='col-sm-6'>
                    <div className='text-center'>
                        <dt className="erp-invoice-print-label-md-lg" style={{ fontSize: "10px" }}>For {invoiceContent.customerDetails.customer}</dt>
                    </div>
                    <div className='align-bottom-center mt-3'>
                        <dt className="erp-invoice-print-label-md-lg" style={{ fontSize: "10px" }}>Buyer's Signature</dt>
                    </div>
                </div>
                <div className='col-sm-6'>
                    <div className='text-center'>
                        <dt className="erp-invoice-print-label-md-lg" style={{ fontSize: "10px" }}>For {invoiceContent.footer.approved_by}</dt>
                    </div>
                    <div className='align-bottom-center mt-3'>
                        <dt className="erp-invoice-print-label-md-lg" style={{ fontSize: "10px" }}>Authorised Signatory</dt>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <>
            {/* <div className="container"> */}

            <div className="row">
                <div className="col-12">
                    <div className="container-invoice">
                        <div id="content">
                            <div className="invoice p-0">

                                {/* <!--BILL HEADER--> */}
                                <div className="row" style={{ padding: '0px 15px 0px' }}>
                                    {invoiceHeader}
                                </div>
                                {/* <!--PARCEL DETAILS--> */}
                                <div className="row" style={{ padding: '0px 15px 15px' }}>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-12 px-0" >
                                                <div className="row p-0">
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered border border-dark m-0 border-end-0 border-start-0" id='invoiceTable' >
                                                            {sizedYarnDipatchDetails.length !== 0 && (
                                                                <thead>
                                                                    <tr>
                                                                        <th className="erp_invoice_table_th text-center col-1">Sr No.</th>
                                                                        <th className="erp_invoice_table_th text-center col-3" >Beam Name</th>
                                                                        <th className="erp_invoice_table_th text-center col-1" >Length</th>
                                                                        <th className="erp_invoice_table_th text-center col-1" >Gross Wt.</th>
                                                                        <th className="erp_invoice_table_th text-center col-1" >Tare Wt.</th>
                                                                        <th className="erp_invoice_table_th text-center col-1" >Net Wt.</th>
                                                                        <th className="erp_invoice_table_th text-center col-3" colSpan={3}>Beam Party</th>
                                                                    </tr>
                                                                </thead>
                                                            )}
                                                            <tbody id="detailstableId">
                                                                {sizedYarnDipatchDetails.map((item, index) => (
                                                                    <tr key={index} className={`tblBodyTrHt-${index}`}>
                                                                        <td className="erp_invoice_table_td text-center">{index + 1}</td>
                                                                        <td className="erp_invoice_table_td">{item.beam_inward_type}</td>
                                                                        <td className="erp_invoice_table_td text-end">{item.sizing_length}</td>
                                                                        <td className="erp_invoice_table_td text-end">{item.gross_weight}</td>
                                                                        <td className="erp_invoice_table_td text-end">{item.tare_weight}</td>
                                                                        <td className="erp_invoice_table_td text-end">{item.net_weight}</td>
                                                                        <td className="erp_invoice_table_td text-end">{item.customer_name}</td>
                                                                    </tr>
                                                                ))}
                                                                <tr>
                                                                    <td colSpan={12}>
                                                                        {/* Your invoice footer content */}
                                                                        {invoiceFooter}
                                                                    </td>
                                                                </tr>
                                                            </tbody>

                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >


        </>
    );
})

export default SizedYarnDispatch;
