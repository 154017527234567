import React from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'

const BeamIssueSlip = React.forwardRef(({ invoiceContent }, ref) => {

    const invoiceFooter = (
        <div className="invoice-footer">
            <div className='row mt-0    '>
                <div className='col text-center'>
                    <div className='align-bottom-center'>
                        <dt className="erp-invoice-print-label-md-lg me-2">Authorised By</dt>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <>
            <div className="row">
                <div className="col-12 pt-2">
                    <div className="container-invoice">
                        <div id="content">
                            <div className="invoice p-0">
                                <div className="row" style={{ padding: '0px 15px 0px' }}>
                                    <div className='bordered border-bottom-0 px-0' >

                                        <div className='row mb-0 p-1'>
                                            <div className="col-sm-2">
                                                <img src={DakshabhiLogo} alt="master card" width="170" height="auto" mt={1} />
                                            </div>
                                            <div className="col-sm-10">
                                                <div className='row'>
                                                    <div className="col-sm-10">
                                                        <div className='erp-invoice-print-label text-center'>
                                                            <span className='erp-invoice-print-label-lg'>{invoiceContent.company_name}</span><br />

                                                            <span className='erp-invoice-print-label-md'>({invoiceContent.company_branch_name})</span>
                                                        </div>
                                                        <div className='erp-invoice-print-label-lg text-center'>
                                                            Beam Issue Slip
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>

                                        <div className='row border-top border-dark p-0 m-0'>
                                            <div className='col-sm-5'>
                                                <dt className='erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}> Beam Issue No. : {invoiceContent.beam_issue_no} </dt>
                                            </div>
                                            <div className='col-sm-3'>
                                                <dt className='erp-invoice-print-label-md-lg' style={{ fontSize: '10px' }}> Loom Boom Status. : <b>{invoiceContent.loom_beam_status}</b></dt>
                                            </div>
                                            <div className='col-sm-4'>
                                                <dt className='erp-invoice-print-label-md-lg text-end' style={{ fontSize: '10px' }}> Issue Date & Time : {invoiceContent.beam_issue_date} / {invoiceContent.beam_issue_time} </dt>
                                            </div>
                                        </div>
                                        <div className='row border-top border-bottom border-dark p-0 m-0'>
                                            <div className="row p-0 m-0">
                                                <div className="col-sm-12">
                                                    <dl className="row mb-0 pb-0">
                                                        <dl className="col-sm-6 mb-0">
                                                            <dl className="row mb-0 pb-0">

                                                                <dt className='col-sm-4 erp-invoice-print-label-md-lg p-1' style={{ fontSize: '10px' }}>Set No :</dt>
                                                                <dd className='col-sm-8 erp-invoice-print-label-md-lg p-1' style={{ fontSize: '9px' }}> {invoiceContent.set_no} </dd>

                                                                <dt className="col-sm-4 erp-invoice-print-label-md-lg p-1" style={{ fontSize: "10px" }}>Beam No :</dt>
                                                                <dd className="col-sm-8 erp-invoice-print-label-md-lg p-1" style={{ fontSize: "9px" }}> {invoiceContent.beam_name}</dd>

                                                                <dt className="col-sm-4 erp-invoice-print-label-md-lg p-1" style={{ fontSize: "10px" }}>Loom No :</dt>
                                                                <dd className="col-sm-8 erp-invoice-print-label-md-lg p-1" style={{ fontSize: "9px" }}> {invoiceContent.loom_no}</dd>

                                                                <dt className="col-sm-4 erp-invoice-print-label-md-lg p-1" style={{ fontSize: "10px" }}>Sort No :</dt>
                                                                <dd className="col-sm-8 erp-invoice-print-label-md-lg p-1" style={{ fontSize: "9px" }}> {invoiceContent.sort_no}</dd>

                                                                <dt className="col-sm-4 erp-invoice-print-label-md-lg p-1" style={{ fontSize: "10px" }}>Count :</dt>
                                                                <dd className="col-sm-8 erp-invoice-print-label-md-lg p-1" style={{ fontSize: "9px" }}>{invoiceContent.count}</dd>

                                                                <dt className="col-sm-4 erp-invoice-print-label-md-lg p-1" style={{ fontSize: "10px" }}>Reed :</dt>
                                                                <dd className="col-sm-8 erp-invoice-print-label-md-lg p-1" style={{ fontSize: "9px" }}> {invoiceContent.reed}</dd>

                                                                <dt className="col-sm-4 erp-invoice-print-label-md-lg p-1" style={{ fontSize: "10px" }}>Pick :</dt>
                                                                <dd className="col-sm-8 erp-invoice-print-label-md-lg p-1" style={{ fontSize: "9px" }}> {invoiceContent.pick}</dd>


                                                                {invoiceContent.loom_beam_status === "Cut Beams" && <>
                                                                    <dt className="col-sm-4 erp-invoice-print-label-md-lg p-1" style={{ fontSize: "10px" }}>Cut Beam Date & Time :</dt>
                                                                    <dd className="col-sm-8 erp-invoice-print-label-md-lg p-1" style={{ fontSize: "9px" }}>{`${invoiceContent.cut_beam_date} / ${invoiceContent.cut_beam_time}`}</dd>

                                                                    <dt className="col-sm-4 erp-invoice-print-label-md-lg p-1" style={{ fontSize: "10px" }}>Remaining Meter :</dt>
                                                                    <dd className="col-sm-8 erp-invoice-print-label-md-lg p-1" style={{ fontSize: "9px" }}>{invoiceContent.remaining_length}</dd>
                                                                </>}
                                                            </dl>
                                                        </dl>
                                                        <dl className="col-sm-6 mb-0">
                                                            <dl className="row mb-0 pb-0">

                                                                <dt className="col-sm-4 erp-invoice-print-label-md-lg p-1" style={{ fontSize: "10px" }}>Shift :</dt>
                                                                <dd className="col-sm-8 erp-invoice-print-label-md-lg p-1" style={{ fontSize: "9px" }}>{invoiceContent.shift}</dd>

                                                                <dt className="col-sm-4 erp-invoice-print-label-md-lg p-1" style={{ fontSize: "10px" }}>Process Type :</dt>
                                                                <dd className="col-sm-8 erp-invoice-print-label-md-lg p-1" style={{ fontSize: "9px" }}>{invoiceContent.loom_process_type}</dd>

                                                                <dt className="col-sm-4 erp-invoice-print-label-md-lg p-1" style={{ fontSize: "10px" }}>Process Charge :</dt>
                                                                <dd className="col-sm-8 erp-invoice-print-label-md-lg p-1" style={{ fontSize: "9px" }}>{invoiceContent.loom_process_charge}</dd>

                                                                <dt className="col-sm-4 erp-invoice-print-label-md-lg p-1" style={{ fontSize: "10px" }}>No of Ends :</dt>
                                                                <dd className="col-sm-8 erp-invoice-print-label-md-lg p-1" style={{ fontSize: "9px" }}> {invoiceContent.t_ends}</dd>

                                                                <dt className="col-sm-4 erp-invoice-print-label-md-lg p-1" style={{ fontSize: "10px" }}>RS :</dt>
                                                                <dd className="col-sm-8 erp-invoice-print-label-md-lg p-1" style={{ fontSize: "9px" }}>{invoiceContent.rs}</dd>

                                                                <dt className="col-sm-4 erp-invoice-print-label-md-lg p-1" style={{ fontSize: "10px" }}>Sized Meter :</dt>
                                                                <dd className="col-sm-8 erp-invoice-print-label-md-lg p-1" style={{ fontSize: "9px" }}>{invoiceContent.length}</dd>


                                                                <dt className="col-sm-4 erp-invoice-print-label-md-lg p-1" style={{ fontSize: "10px" }}>Beam Issuer Name : </dt>
                                                                <dd className="col-sm-8 erp-invoice-print-label-md-lg p-1" style={{ fontSize: "9px" }}>{invoiceContent.beam_issuer_name}</dd>

                                                                {invoiceContent.loom_beam_status === "Cut Beams" && <>

                                                                    <dt className="col-sm-4 erp-invoice-print-label-md-lg p-1" style={{ fontSize: "10px" }}>Beam Cutter Name : </dt>
                                                                    <dd className="col-sm-8 erp-invoice-print-label-md-lg p-1" style={{ fontSize: "9px" }}>{invoiceContent.beam_cutter_name}</dd>

                                                                    <dt className="col-sm-4 erp-invoice-print-label-md-lg p-1" style={{ fontSize: "10px" }}>Cut Beam Reason : </dt>
                                                                    <dd className="col-sm-8 erp-invoice-print-label-md-lg p-1" style={{ fontSize: "9px" }}>{invoiceContent.cut_beam_reason}</dd>

                                                                </>}

                                                            </dl>
                                                        </dl>
                                                    </dl>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        </>
    );

})

export default BeamIssueSlip