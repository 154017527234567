import React, { useEffect, useRef, useMemo } from "react";
import { useState } from "react";
import $ from "jquery";
import MDTypography from "components/MDTypography";
import Accordion from "react-bootstrap/Accordion";
import { IoAddCircleOutline, IoRemoveCircleOutline } from "react-icons/io5";

import MDButton from "components/MDButton";
import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import { Tab, Table, Tabs, Form, Card } from "react-bootstrap";
import ComboBox from "Features/ComboBox";
import {
  globalQuery,
  resetGlobalQuery,
} from "assets/Constants/config-constant";
import ConfigConstants from "assets/Constants/config-constant";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import { MdDelete, MdModeEdit } from "react-icons/md";
import FrmValidations from "FrmGeneric/FrmValidations";
import { useLocation, useNavigate } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import GenerateMaterialId from "FrmGeneric/GenerateMaterialId/GenerateMaterialId";
import MDBox from "components/MDBox";
import { Grid } from "@mui/material";
import FrmWInspDetailsEntry from "./component/FrmWInspDetailsEntry";
import FrmWInspMaterialMovementEntry from "./component/FrmWInspMaterialMovementEntry";

import Select from "react-select";
import { position } from "stylis";
import zIndex from "@material-ui/core/styles/zIndex";

function getSteps() {
  return ["Weaving Inspection Details", "Material Movement"];
}
function FrmTWeavingInspectionEntry(props) {
  const configConstants = ConfigConstants();
  const {
    COMPANY_ID,
    COMPANY_BRANCH_ID,
    UserName,
    FINANCIAL_SHORT_NAME,
    UserId,
  } = configConstants;
  const { state } = useLocation();
  const {
    weavingInspectionMasterId = 0,
    keyForViewUpdate,
    compType,
  } = state || {};

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const handleNext = () => {
    setActiveStep(parseInt(activeStep) + 1);
  };
  const handleBack = () => setActiveStep(parseInt(activeStep) - 1);
  //Current date
  const today = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  //useRef Hooks
  const selectRef = useRef(null);
  const comboDataAPiCall = useRef();
  const cmb_prod_supervisor_id_ref = useRef();
  const generateAutoNoAPiCall = useRef();
  const navigate = useNavigate();
  const validateNumberDateInput = useRef();
  const validate = useRef();
  const [actionType, setActionType] = useState("");
  const [actionLabel, setActionLabel] = useState("Save");
  const [isLoading, setIsLoading] = useState(false);
  const [isApprove, setIsApprove] = useState(false);
  //Inspection Production master hooks
  const [
    txt_weaving_production_inspection_master_id,
    setweavingProductionInspectionMasterId,
  ] = useState(weavingInspectionMasterId);
  const [cmb_set_no, setSetNo] = useState();
  let set_no_ref = useRef(null);
  const [aprovalbyId, setAprovalbyId] = useState();
  const [cmb_aproved_by, setAprovalUserName] = useState();
  const [dt_approval_date, setAprovalDate] = useState(today);
  const [dt_inspection_production_date, setProdPlanDate] = useState(today);
  const [txt_prod_month, setProdMonth] = useState();
  const [txt_prod_year, setProdYear] = useState();
  const [cmb_plant_id, setProdPlantName] = useState("");
  const [txt_Weaving_inspection_Status, setWeavingInspectionStatus] =
    useState("P");
  const [cmb_section_id, setProdSection] = useState(17);
  const [cmb_sub_section_id, setProdSubSection] = useState(26);
  const [cmb_inspection_production_code, setWeavingInspectionCode] = useState();
  const [cmb_prod_supervisor_id, setProdSupervisor] = useState();

  //Inspection Production  details comboboxes
  const [weavingInspectionData, setWeavingInspectionData] = useState([]);
  const [inspectionMaterialData, setInspectionMaterialData] = useState([]);
  const [setNoOptions, setSetNoOptions] = useState([]);
  // const [plantOptions, setPlantOptions] = useState([]);
  const [prodsectionOptions, setProdSectionOptions] = useState([]);
  const [prodsubsectionOptions, setProdSubSectionOptions] = useState([]);
  const [shiftOptions, setShiftOptions] = useState([]);
  const [machineOperatorOptions, setMachineOperatorOptions] = useState([]);
  const [shiftNames, setShiftNames] = useState([]);
  ///Inspection Production Hooks
  const [dataAgainstSetNo, setDataAgainstSetNo] = useState([]);
  const [sortNoOption, setSortNoOptions] = useState([]);

  const [prevdataindexforWastage, setPrevDataIndexForWastage] = useState();
  const [godownNameOptions, setGodownNameOptions] = useState([]);
  const [cmb_godown_id, setGodownId] = useState([]);

  ///Shiftwise Machine Wastage Entry Hooks
  const [cmb_prod_wastage_type, setProdWastageType] = useState();
  const [txt_act_wastages, setActWastage] = useState();
  const [cmb_shift_wastage, setShiftWastage] = useState();
  const [labelForWastage, setLabelForWastage] = useState("Add");
  const [txt_wastage_quantity, setWastageQuantity] = useState();

  ///Shiftwise Machine Wastage Entry Combobox
  const [prodWastageOptions, setProdWastageOptions] = useState([]);
  const [wastageDetails, setWastageDetails] = useState([]);
  const [godownData, setGodownData] = useState([]);

  const [allBeamNoList, setAllBeamNos] = useState([]);

  //Error Msg
  const handleCloseErrModal = () => setShowErrorMsgModal(false);
  const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  // Success Msg HANDLING
  const handleCloseSuccessModal = () => {
    setShowSuccessMsgModal(false);
    navigate(`/Transactions/TWeavingInspection/FrmTWeavingInspectionListing`);
  };

  const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
  const [succMsg, setSuccMsg] = useState("");

  //Weaving Inspection Blank Object
  const inspectionBlankObject = {
    company_id: COMPANY_ID,
    company_branch_id: COMPANY_BRANCH_ID,
    inspection_production_date: today(),
    inspection_production_set_no: 0,
    shift: 0,
    sort_no: 0,
    production_operator_id: 0,
    actual_count: 0,
    machine_id: 0,
    product_pick: 0,
    sizing_beam_no: 0,
    weight: 0,
    status_remark: "",
    inspection_production_status: "P",
    product_material_name: "",
    roll_no: 0,
    product_in_meter: 0,
    inspection_mtr: 0,
    width: 0,
    difference: 0,
    average: 0,
    godown_id: 4,
    created_by: UserName,
  };

  useEffect(() => {
    const loadDataOnload = async () => {
      setIsLoading(true);
      await ActionType();
      await fillComboBox();
      setMonthYear();

      if (keyForViewUpdate === "Add") {
        fillAutoNo();
      }

      if (weavingInspectionMasterId !== 0) {
        await FnCheckUpdateResponce();
      }
      setIsLoading(false);
    };
    loadDataOnload();
  }, []);

  const ActionType = async () => {
    switch (keyForViewUpdate) {
      case "update":
        setIsApprove(false);
        setActionType("(Modification)");
        setActionLabel("Update");
        await validate.current.readOnly("weavingInspection");
        break;
      case "view":
        setIsApprove(false);
        setActionType("(View)");
        await validate.current.readOnly("weavingInspection");
        await validate.current.readOnly("wastageFormId");
        $("input[type=radio]").attr("disabled", true);
        break;
      case "approve":
        setActionType("(Approve)");
        setActionLabel("Approve");
        $("form").find("textarea,select").attr("disabled", "disabled");
        $("table")
          .find("input,button,textarea,select")
          .attr("disabled", "disabled");
        $("table .approveField").removeAttr("disabled");
        $("input[type=radio]").attr("disabled", true);
        setIsApprove(true);
        break;
      default:
        setActionType("(Creation)");
        break;
    }
  };

  /// function for update inspectio data
  const FnCheckUpdateResponce = async () => {
    debugger;
    try {
      const apiCall = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/XtWeavingProductionInspectionMaster/FnShowParticularRecordForUpdate/${weavingInspectionMasterId}/${COMPANY_ID}`
      );
      const updateRes = await apiCall.json();
      const inspectionDetailData =
        updateRes.WeavingProductionInspectionDetailsRecords;
      const masterdata = updateRes.WeavingProductionInspectionMasterModelRecord;
      const wastageData = updateRes.WeavingProductionInspectionWastageRecords;
      // const materialData = updateRes.WeavingProductionInspectionMaterialRecords
      console.log(inspectionDetailData);

      setweavingProductionInspectionMasterId(
        masterdata.weavingInspectionMasterId
      );
      // let insp_Shifts = [];
      // inspectionDetailData.forEach((item, index) => {
      //   insp_Shifts.push(item.shift);
      // });

      setSetNo(masterdata.inspection_production_set_no);
      set_no_ref.current.value = masterdata.inspection_production_set_no;
      if (keyForViewUpdate !== "approve") {
        comboOnChange("cmb_set_no");
      }

      setProdPlanDate(masterdata.inspection_production_date);
      setProdMonth(masterdata.prod_month);
      setWeavingInspectionCode(masterdata.inspection_production_code);
      setProdYear(masterdata.prod_year);
      setProdPlantName(masterdata.plant_id);
      setProdSection(masterdata.section_id);
      // comboOnChange('cmb_section_id');
      setProdSubSection(masterdata.sub_section_id);

      setProdSupervisor(masterdata.production_supervisor_id);

      // setWeavingInspectionData(inspectionDetailData)
      if (keyForViewUpdate === "approve") {
        const inspectionDetails = inspectionDetailData.map((data) => ({
          ...data,
          inspection_production_status: "A",
        }));
        setWeavingInspectionData(inspectionDetails);
      } else {
        setWeavingInspectionData(inspectionDetailData);
      }

      setWastageDetails(wastageData);
    } catch (error) {
      console.log("error: ", error);
      navigate("/Error");
    }
  };
  // $(document).on('click', '.erp_input_field_table_txt.remove0', function () {
  //   if ($(this).val() === "0") {
  //     $(this).val("")
  //   }
  // });
  // $(document).on('mouseup mousedown', function (e) {
  //   let inputBoxes = $(".erp_input_field_table_txt.remove0");
  //   inputBoxes.each(function () {
  //     if ($(this).val() === "") {
  //       $(this).val("0");
  //     }
  //   });
  // });

  /// function for Generate weaving Inspection Code
  const fillAutoNo = async () => {
    try {
      const productionDate = $("#dt_inspection_production_date").val();
      const [year, month, day] = productionDate.split("-");
      const formatted_date = `${day}${month}${year.slice(2)}`;
      comboOnChange("cmb_section_id");
      const subSectionVal = $("#cmb_sub_section_id").val();
      const shortName = $("#cmb_sub_section_id option:selected").attr(
        "shortName"
      );
      const weavingInspectionCode =
        await generateAutoNoAPiCall.current.generateProductionCode({
          entity_name: "xt_weaving_production_inspection_master",
          sub_section: subSectionVal,
          sub_section_short_name: shortName,
          field_name: "inspection_production_code",
          trans_no_length: "4",
          transaction_date: formatted_date,
          company_id: COMPANY_ID,
        });
      setWeavingInspectionCode(weavingInspectionCode);
    } catch (error) {
      console.log("error: ", error);
      navigate("/Error");
    }
  };

  /// function for combo on change
  const comboOnChange = async (key) => {
    debugger;
    try {
      switch (key) {
        case "cmb_section_id":
          const prod_section_Id = $("#cmb_section_id").val();
          ///// Production sub section Arr[]
          resetGlobalQuery();
          globalQuery.columns.push(
            "field_id",
            "field_name",
            "production_sub_section_short_type"
          );
          globalQuery.table = "xmv_production_sub_section";
          globalQuery.conditions.push({
            field: "is_delete",
            operator: "=",
            value: 0,
          });
          globalQuery.conditions.push({
            field: "production_section_id",
            operator: "=",
            value: prod_section_Id,
          });
          let getProdSubSectionApiCall =
            await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
          setProdSubSectionOptions(getProdSubSectionApiCall);
          setProdSubSection("27");

          // const prodsubSectionId = $('#cmb_sub_section_id').val();

          // resetGlobalQuery();
          // globalQuery.columns.push("field_id", 'field_name');
          // globalQuery.table = "cmv_machine"
          // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
          // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
          // globalQuery.conditions.push({ field: "section_id", operator: "=", value: prod_section_Id });
          // globalQuery.conditions.push({ field: "sub_section_id", operator: "=", value: prodsubSectionId });
          // comboDataAPiCall.current.fillFiltersCombo(globalQuery).then(getProdSubSectionApiCall => {
          //   setMachineNameOption(getProdSubSectionApiCall)
          //   console.log("sub section list: ", getProdSubSectionApiCall);
          // });

          // ////Employees in Warping Production
          // resetGlobalQuery();
          // globalQuery.columns.push("field_id", 'field_name');
          // globalQuery.table = "cmv_employee"
          // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
          // globalQuery.conditions.push({ field: "department_id", operator: "=", value: 29 });
          // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
          // comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((getEmployeeApiCall) => {
          //   const prodSupervisorList = [
          //     { value: '', label: 'Select' },
          //     ...getEmployeeApiCall.map((reporting) => ({ ...reporting, value: reporting.field_id, label: reporting.field_name, })),
          //   ];
          //   setMachineOperatorOptions(prodSupervisorList);
          // })
          break;

        case "cmb_plant_id":
          let plantId = $("#cmb_plant_id").val();
          setProdPlantName(plantId);
          break;
        case "cmb_set_no":
          let setNo = set_no_ref.current.value;

          const masterDataAPICall = await fetch(
            `${process.env.REACT_APP_BASE_URL}/api/XtWeavingProductionInspectionMaster/FnGetInspectionMasterData/${setNo}/${COMPANY_ID}`
          );
          const updateRes = await masterDataAPICall.json();

          const dataAgainstSetNo = updateRes.InspectionMasterData;
          setDataAgainstSetNo(dataAgainstSetNo);

          let sortNos = [];
          let uniqueSortNos = new Set();

          dataAgainstSetNo.forEach((data) => {
            if (!uniqueSortNos.has(data["sort_no"])) {
              uniqueSortNos.add(data["sort_no"]);
              sortNos.push({ sort_no: data["sort_no"] });
            }
          });

          setSortNoOptions(sortNos);

          break;
      }
    } catch (error) {
      console.log("error: ", error);
      navigate("/Error");
    }
  };
  const fillComboBox = async () => {
    debugger
    try {
      // comboDataAPiCall.current.fillMasterData("cmv_plant", "", "").then((getProdPlantApiCall) => {
      //   setPlantOptions(getProdPlantApiCall);
      // })

      ////Production Section
      resetGlobalQuery();
      globalQuery.columns = ["field_id", "field_name"];
      globalQuery.table = "xmv_production_section";
      globalQuery.conditions.push({
        field: "is_delete",
        operator: "=",
        value: 0,
      });
      comboDataAPiCall.current
        .fillFiltersCombo(globalQuery)
        .then((getWarpingProdSecCall) => {
          setProdSectionOptions(getWarpingProdSecCall);
          setProdSection("18");
          // if (keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
          comboOnChange("cmb_section_id");
          // }
        });

      const shiftsApiCall = await comboDataAPiCall.current.fillComboBox(
        "ProductionShifts"
      );
      setShiftOptions(shiftsApiCall);
      let shift_Names = [];
      shiftsApiCall.map((shift) => {
        shift_Names.push(shift.property_name);
      });
      setShiftNames(shift_Names);

      ////Employees in Warping Production
      resetGlobalQuery();
      globalQuery.columns.push("field_id", "field_name");
      globalQuery.table = "cmv_employee";
      globalQuery.conditions.push({
        field: "is_delete",
        operator: "=",
        value: 0,
      });
      globalQuery.conditions.push({
        field: "department_id",
        operator: "=",
        value: 23,
      });
      globalQuery.conditions.push({
        field: "company_id",
        operator: "=",
        value: COMPANY_ID,
      });
      let getEmployeeApiCall = await comboDataAPiCall.current.fillFiltersCombo(
        globalQuery
      );
      const prodSupervisorList = [
        { value: "", label: "Select" },
        ...getEmployeeApiCall.map((reporting) => ({
          ...reporting,
          value: reporting.field_id,
          label: reporting.field_name,
        })),
      ];
      setMachineOperatorOptions(prodSupervisorList);

      // employees in aproved by & date
      resetGlobalQuery();
      globalQuery.columns.push("employee_name", "employee_id");
      globalQuery.table = "cm_employee";
      if(UserId !== 1){
        globalQuery.conditions.push({field: "is_delete", operator: "=", value: 0});
        globalQuery.conditions.push({field: "company_id", operator: "=", value: parseInt(COMPANY_ID)});
      }
      globalQuery.conditions.push({field: "employee_id",operator: "=",value: parseInt(UserId)});
      
      let getAprovedCallApi = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);

      if(getAprovedCallApi.length > 0){
        const setEmpList = [...getAprovedCallApi.map((emp) => ({value: emp.employee_id,label: emp.employee_name}))];
        setAprovalbyId(setEmpList[0].value);
        setAprovalUserName(setEmpList[0].label);
  
      }


      resetGlobalQuery();
      globalQuery.columns = [
        "godown_id",
        "godown_section_id",
        "godown_section_beans_id",
      ];
      globalQuery.table = "cm_department";
      globalQuery.conditions.push({
        field: "company_id",
        operator: "=",
        value: COMPANY_ID,
      });
      globalQuery.conditions.push({
        field: "godown_id",
        operator: "=",
        value: 4,
      });

      globalQuery.conditions.push({
        field: "is_delete",
        operator: "=",
        value: 0,
      });
      comboDataAPiCall.current
        .removeCatcheFillCombo(globalQuery)
        .then((godownDataApiCall) => {
          setGodownData(godownDataApiCall);
        });

      resetGlobalQuery();
      globalQuery.columns = [
        "field_id",
        "field_name",
        "production_wastage_types_name",
      ];
      globalQuery.table = "xmv_production_wastage_types";
      globalQuery.conditions.push({
        field: "is_delete",
        operator: "=",
        value: 0,
      });
      comboDataAPiCall.current
        .removeCatcheFillCombo(globalQuery)
        .then((godownTypeApiCall) => {
          setProdWastageOptions(godownTypeApiCall);
        });

      resetGlobalQuery();
      globalQuery.columns = ["field_id", "field_name", "godown_name"];
      globalQuery.table = "cmv_godown";
      globalQuery.conditions.push({
        field: "is_delete",
        operator: "=",
        value: 0,
      });
      globalQuery.conditions.push({
        field: "company_id",
        operator: "=",
        value: COMPANY_ID,
      });
      comboDataAPiCall.current
        .fillFiltersCombo(globalQuery)
        .then((godownTypeApiCall) => {
          setGodownNameOptions(godownTypeApiCall);
        });

      resetGlobalQuery();
      globalQuery.columns.push("DISTINCT set_no");
      globalQuery.table = "xt_weaving_production_loom_details";
      globalQuery.conditions.push({
        field: "company_id",
        operator: "=",
        value: COMPANY_ID,
      });
      globalQuery.conditions.push({
        field: "is_delete",
        operator: "=",
        value: 0,
      });
      const SetNoApiCall = await comboDataAPiCall.current.fillFiltersCombo(
        globalQuery
      );
      const setNoList = [
        { value: "", label: "Select" },
        ...SetNoApiCall.map((setNo) => ({
          value: setNo.set_no,
          label: setNo.set_no,
        })),
      ];
      setSetNoOptions(setNoList);

      setWeavingInspectionData([inspectionBlankObject]);
    } catch (error) {
      console.log("error: ", error);
      navigate("/Error");
    }
  };

  const setMonthYear = () => {
    let prod_Plan_Dt = $("#dt_inspection_production_date").val();
    if (prod_Plan_Dt) {
      setProdPlanDate(prod_Plan_Dt);
      let dateComponents = prod_Plan_Dt.split("-");
      setProdMonth(dateComponents[1]);
      setProdYear(dateComponents[0]);
    }
  };

  const FnValidateInspectionTbl = () => {
    debugger;
    let validate = true;
    let inspectionData = weavingInspectionData;
    const keys = [
      "shift",
      "production_operator_id",
      "sort_no",
      "sizing_beam_no",
      "inspection_mtr",
      "weight",
      "roll_no",
    ];

    inspectionData.forEach((data, i) => {
      keys.forEach((key) => {
        if (data.hasOwnProperty(key)) {
          let tabletdId = $(`#${key}_${i}`);

          if (key !== "production_operator_id") {
            if (tabletdId.is(":visible") && ["", 0].includes(tabletdId.val())) {
              let tipMessage =
                tabletdId.attr("type") === "text" ||
                  tabletdId.attr("type") === "remark"
                  ? "Please Enter proper data...!"
                  : "Please Select at least one...!";

              tabletdId.parent().attr("data-tip", tipMessage);
              tabletdId.focus();
              validate = false;
              return; // Exit loop early on validation failure
            }
          } else if (data["production_operator_id"] === 0) {
            tabletdId.addClass("required-field");
            tabletdId.attr("data-tip", "Please select operator");
            validate = false;
            return; // Exit loop early on validation failure
          }
        }
      });

      if (!validate) return; // Stop further validation if any check fails
    });

    return validate;
  };

  const FnAddRemoveRows = (action, index) => {
    let inspectionData = weavingInspectionData;
    switch (action) {
      case "Add":
        if (FnValidateInspectionTbl() === true) {
          setWeavingInspectionData([...inspectionData, inspectionBlankObject]);
        }
        break;
      case "Remove":
        if (inspectionData.length > 0) {
          inspectionData.splice(index, 1);
          setWeavingInspectionData([...inspectionData]);
        }
        break;
    }
  };

  const renderWeavingInspectionDetails = useMemo(() => {
    if (!weavingInspectionData) {
      return null;
    }

    return (
      <Table
        id="weavingInspectionTbl"
        className={`erp_table ${weavingInspectionData.length !== 0 ? "display" : "d-none"
          }`}
        responsive
        bordered
        striped
      >
        <thead className="erp_table_head">
          <tr>
            <th
              className={`erp_table_th ${keyForViewUpdate === "view" || keyForViewUpdate === "approve"
                ? "d-none"
                : "display"
                }`}
            >
              {" "}
              Action
            </th>
            <th className="erp_table_th">Sr no .</th>
            <th className="erp_table_th">Date</th>
            <th className="erp_table_th">Shift</th>
            <th className="erp_table_th">Checker Name</th>
            <th className="erp_table_th">Sort No</th>
            <th className="erp_table_th">Beam No</th>
            <th className="erp_table_th">Loom No</th>
            <th className="erp_table_th">Product Name</th>
            <th className="erp_table_th">Style</th>

            <th className="erp_table_th">Width</th>
            <th className="erp_table_th">Pick</th>
            <th className="erp_table_th">Loom Mtr</th>
            <th className="erp_table_th">Insp.Mtr</th>
            <th className="erp_table_th">Difference</th>
            <th className="erp_table_th">Weight</th>
            <th className="erp_table_th">Roll No</th>
            <th className="erp_table_th">Average</th>
            <th className="erp_table_th">Godown</th>
            {keyForViewUpdate === "approve" ? (
              <th className="erp_table_th">Weaving Insp.Status</th>
            ) : (
              ""
            )}
            <th className="erp_table_th">Status Remark</th>
          </tr>
        </thead>
        <tbody>
          {weavingInspectionData.map((item, index) => (
            <tr
              rowindex={index}
              id={`inspectionTableRow_` + index}
              className="sticky-column"
            >
              <td
                className={`erp_table_th ${keyForViewUpdate === "view" || keyForViewUpdate === "approve"
                  ? "d-none"
                  : "display"
                  }`}
              >
                <IoRemoveCircleOutline
                  className="erp_trRemove_icon"
                  onClick={() => FnAddRemoveRows("Remove", index)}
                />
                <IoAddCircleOutline
                  className="erp_trAdd_icon"
                  onClick={() => FnAddRemoveRows("Add", index)}
                />
              </td>

              <td className="erp_table_td">{index + 1}</td>
              <td className="erp_table_td">
                {keyForViewUpdate !== "approve" &&
                  keyForViewUpdate !== "view" ? (
                  <>
                    <input
                      type="date"
                      className="erp_input_field mb-0"
                      disabled={
                        item.inspection_production_status_desc === "Approved"
                      }
                      Headers="inspection_production_date"
                      id={`inspection_production_date_${index}`}
                      value={item.inspection_production_date}
                      onChange={(e) =>
                        updatedWeavingInspectionDetails(
                          "inspection_production_date",
                          item,
                          e,
                          index
                        )
                      }
                    />
                  </>
                ) : (
                  item.inspection_production_date
                )}
              </td>
              <td className={`erp_table_td `}>
                {keyForViewUpdate !== "approve" &&
                  keyForViewUpdate !== "view" ? (
                  <select
                    id={`shift_${index}`}
                    value={item.shift}
                    onChange={(e) =>
                      updatedWeavingInspectionDetails("shift", item, e, index)
                    }
                    className="erp_input_field_table_txt mb-0"
                    Headers="shift"
                    disabled={
                      item.inspection_production_status_desc === "Approved"
                    }
                  >
                    <option value="">Select</option>
                    {shiftOptions?.map((shift) => (
                      <option value={shift.field_name}>
                        {shift.field_name}
                      </option>
                    ))}
                  </select>
                ) : (
                  item.shift
                )}
              </td>
              <td className="erp_table_td">
                {keyForViewUpdate !== "approve" &&
                  keyForViewUpdate !== "view" ? (
                  <Select
                    ref={selectRef}
                    options={machineOperatorOptions}
                    isDisabled={["view", "approve"].includes(keyForViewUpdate)}
                    value={machineOperatorOptions.find(
                      (option) =>
                        option.field_id === item.production_operator_id
                    )}
                    inputId={`production_operator_id_${index}`}
                    id={`production_operator_id_${index}`}
                    onChange={(selectedOpt) => {
                      selectRef.current = selectedOpt;
                      updatedWeavingInspectionDetails(
                        "production_operator_id",
                        item,
                        selectedOpt,
                        index
                      );
                    }}
                    placeholder="Search Operator here..."
                    className="form-search-custom"
                    classNamePrefix="custom-select"
                    styles={{
                      option: (provided, state) => ({
                        ...provided,
                        width: "200px", // Set the width of the option box
                        fontSize: "12px", // Adjust the font size as per your requirement
                      }),
                      menu: (provided, state) => ({
                        ...provided,
                        width: "200px", // Set the width of the menu box
                        zIndex: 1000, // Ensure this is higher than the table's zIndex
                      }),
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 1000, // Ensure this is higher than the table's zIndex
                      }),
                      singleValue: (provided, state) => ({
                        ...provided,
                        fontSize: "12px", // Adjust the font size as per your requirement
                      }),
                      input: (provided, state) => ({
                        ...provided,
                        width: "160px",
                        fontSize: "12px", // Adjust the font size as per your requirement
                      }),
                    }}
                    menuPortalTarget={document.body}
                  />
                ) : (
                  item.production_operator_name
                )}
              </td>

              <td className="erp_table_td">
                {keyForViewUpdate !== "approve" &&
                  keyForViewUpdate !== "view" ? (
                  <select
                    id={`sort_no_${index}`}
                    value={item.sort_no}
                    className="erp_input_field_table_txt mb-0"
                    onChange={(e) =>
                      updatedWeavingInspectionDetails("sort_no", item, e, index)
                    }
                    headers="sort_no"
                    style={{ width: "100px" }}
                  >
                    <option value="">Select</option>
                    {sortNoOption.map((data, idx) => (
                      <option key={idx} value={data.sort_no}>
                        {data.sort_no}
                      </option>
                    ))}
                  </select>
                ) : (
                  item.sort_no
                )}
              </td>

              <td className="erp_table_td">
                {keyForViewUpdate !== "approve" &&
                  keyForViewUpdate !== "view" ? (
                  <select
                    id={`sizing_beam_no_${index}`}
                    value={item.sizing_beam_no}
                    className="erp_input_field_table_txt mb-0"
                    Headers="sizing_beam_no"
                    style={{ width: "100px" }}
                    onChange={(e) =>
                      updatedWeavingInspectionDetails(
                        "sizing_beam_no",
                        item,
                        e,
                        index
                      )
                    }
                  >
                    <option value="">Select</option>
                    {dataAgainstSetNo?.map((data) => (
                      <option value={data.sizing_beam_no}>
                        {data.sizing_beam_no}
                      </option>
                    ))}
                  </select>
                ) : (
                  item.sizing_beam_no
                )}
              </td>

              <td className="erp_table_td">
                {keyForViewUpdate !== "approve" &&
                  keyForViewUpdate !== "view" ? (
                  <span
                    className="erp_input_field_table_txt mb-0"
                    id={`machine_id_${index}`}
                  >
                    {item.machine_id}
                  </span>
                ) : (
                  item.machine_id
                )}
              </td>

              <td className="erp_table_td">
                {keyForViewUpdate !== "approve" &&
                  keyForViewUpdate !== "view" ? (
                  <span
                    className="erp_input_field_table_txt mb-0"
                    style={{ width: "auto" }}
                    id={`product_material_name_${index}`}
                  >
                    {item.product_material_name}
                  </span>
                ) : (
                  item.product_material_name
                )}
              </td>
              <td className="erp_table_td"> {item.style}</td>

              <td className="erp_table_td">
                {keyForViewUpdate !== "approve" &&
                  keyForViewUpdate !== "view" ? (
                  <span
                    className="erp_input_field_table_txt mb-0"
                    style={{ width: "80px" }}
                    id={`width_${index}`}
                  >
                    {item.width}
                  </span>
                ) : (
                  item.width
                )}
              </td>

              <td className="erp_table_td">
                {keyForViewUpdate !== "view" ? (
                  <>
                    <input
                      type="text"
                      className="erp_input_field_table_txt mb-0 remove0 text-end"
                      id={`product_pick_${index}`}
                      value={item.product_pick}
                      style={{ width: "80px" }}
                      disabled
                    />
                  </>
                ) : (
                  item.product_pick
                )}
              </td>

              <td className="erp_table_td">
                {keyForViewUpdate !== "approve" &&
                  keyForViewUpdate !== "view" ? (
                  <span
                    className="erp_input_field_table_txt mb-0"
                    style={{ width: "80px" }}
                    id={`product_in_meter_${index}`}
                  >
                    {item.product_in_meter}
                  </span>
                ) : (
                  item.product_in_meter
                )}
              </td>
              <td className="erp_table_td">
                {keyForViewUpdate !== "view" ? (
                  <>
                    <input
                      type="text"
                      className="erp_input_field_table_txt mb-0 remove0 text-end"
                      id={`inspection_mtr_${index}`}
                      value={item.inspection_mtr}
                      style={{ backgroundColor: "#AFE1AF", width: "80px" }}
                      disabled={
                        item.inspection_production_status_desc === "Approved" ||
                        keyForViewUpdate === "approve"
                      }
                      onChange={(e) =>
                        updatedWeavingInspectionDetails(
                          "inspection_mtr",
                          item,
                          e,
                          index
                        )
                      }
                    />
                  </>
                ) : (
                  item.inspection_mtr
                )}
              </td>

              <td className="erp_table_td">
                {keyForViewUpdate !== "approve" &&
                  keyForViewUpdate !== "view" ? (
                  <span
                    className="erp_input_field_table_txt mb-0 text-end"
                    id={`difference_${index}`}
                    style={{ backgroundColor: "#e9ecef", width: "80px" }}
                  >
                    {item.difference}
                  </span>
                ) : (
                  item.difference
                )}
              </td>
              <td className="erp_table_td">
                {keyForViewUpdate !== "view" ? (
                  <>
                    <input
                      type="text"
                      className="erp_input_field_table_txt remove0 mb-0 text-end"
                      id={`weight_${index}`}
                      value={item.weight}
                      style={{ backgroundColor: "#AFE1AF", width: "80px" }}
                      disabled={
                        item.inspection_production_status_desc === "Approved" ||
                        keyForViewUpdate === "approve"
                      }
                      onChange={(e) =>
                        updatedWeavingInspectionDetails(
                          "weight",
                          item,
                          e,
                          index
                        )
                      }
                    />
                  </>
                ) : (
                  item.weight
                )}
              </td>
              <td className="erp_table_td">
                {keyForViewUpdate !== "view" ? (
                  <><input type="text" className="erp_input_field_table_txt remove0 text-end mb-0" id={`roll_no_${index}`}
                    style={{ backgroundColor: "#AFE1AF", width: "100px" }} value={item.roll_no}
                    disabled={item.inspection_production_status_desc === "Approved" || keyForViewUpdate === "approve"}
                    onBlur={(e) => updatedWeavingInspectionDetails("roll_no", item, e, index)}
                    onChange={(e) => updatedWeavingInspectionDetails("roll_no", item, e, index)} /></>
                ) : (item.roll_no)}
              </td>

              <td className="erp_table_td">
                {keyForViewUpdate !== "approve" &&
                  keyForViewUpdate !== "view" ? (
                  <span
                    className="erp_input_field_table_txt mb-0 text-end"
                    id={`average_${index}`}
                    style={{ backgroundColor: "#e9ecef", width: "80px" }}
                  >
                    {item.average}
                  </span>
                ) : (
                  item.average
                )}
              </td>
              <td className={`erp_table_td `}>
                {keyForViewUpdate !== "view" ? (
                  <select
                    id={`godown_id_${index}`}
                    value={item.godown_id}
                    className="erp_input_field_table_txt mb-0"
                    Headers="godown_id"
                    onChange={(e) => {
                      updatedWeavingInspectionDetails(
                        "godown_id",
                        item,
                        e,
                        index
                      );
                    }}
                    disabled
                  >
                    <option value="">Select</option>
                    {godownNameOptions?.map((godownType) => (
                      <option value={godownType.field_id}>
                        {godownType.godown_name}
                      </option>
                    ))}
                  </select>
                ) : (
                  item.godown_name
                )}
              </td>

              {keyForViewUpdate === "approve" ? (
                <td className="erp_table_td">
                  <select
                    id={`inspection_production_status_${index}`}
                    className="erp_input_field_table_txt form-select form-select-sm mb-0"
                    value={item.inspection_production_status} disabled
                    onChange={(e) => {
                      updatedWeavingInspectionDetails(
                        "inspection_production_status",
                        item,
                        e,
                        index
                      );
                    }}
                    Headers="inspection_production_status"
                  >
                    <option value="P">Pending</option>
                    <option value="A">Approved</option>
                    <option value="R">Rejected</option>
                    <option value="I">Partial Issue</option>
                    <option value="C">Completed</option>
                    <option value="X">Canceled</option>
                    <option value="PD">Partial Dispatch</option>
                    <option value="D">Dispatch Done</option>
                    <option value="DG">Dispatch Note Generated</option>
                    <option value="Z">PreeClosed</option>
                  </select>
                </td>
              ) : (
                ""
              )}

              <td className="erp_table_td">
                {keyForViewUpdate === "approve" ? (
                  <input
                    type="text"
                    id={`status_remark_${index}`}
                    disabled={!isApprove}
                    className="erp_input_field_table_txt mb-0"
                    value={item.status_remark}
                    onChange={(e) => {
                      updatedWeavingInspectionDetails(
                        "status_remark",
                        item,
                        e,
                        index
                      );
                    }}
                  />
                ) : (
                  item.status_remark
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }, [weavingInspectionData, dataAgainstSetNo, sortNoOption]);

  const updatedWeavingInspectionDetails = async (
    propertyName,
    currentMaterial,
    event,
    index
  ) => {
    try {
      debugger;
      let enteredValue;
      if (propertyName === "production_operator_id") {
        enteredValue = event.field_id;
      } else {
        enteredValue = event.target.value === "" ? 0 : event.target.value;
      }

      let arrayIndex = index;

      const bottomDetailsData = [...weavingInspectionData];
      const originalRowData = { ...bottomDetailsData[arrayIndex] };

      switch (propertyName) {
        case "sort_no":
          delete event.target.parentElement.dataset.tip;
          currentMaterial[propertyName] = enteredValue;
          currentMaterial["sizing_beam_no"] = 0;
          currentMaterial["style"] = "";
          currentMaterial["machine_id"] = 0;
          currentMaterial["product_material_id"] = "";
          currentMaterial["product_material_name"] = "";
          currentMaterial["product_in_meter"] = 0;
          currentMaterial["product_pick"] = 0;
          currentMaterial["width"] = 0;
          break;
        case "sizing_beam_no":
          delete event.target.parentElement.dataset.tip;
          currentMaterial[propertyName] = enteredValue;
          let matchedData = dataAgainstSetNo.find(
            (data) =>
              data["sort_no"] === currentMaterial["sort_no"] &&
              data["sizing_beam_no"] === currentMaterial["sizing_beam_no"]
          );
          if (matchedData) {
            currentMaterial["style"] = matchedData["style"];
            currentMaterial["machine_id"] = matchedData["machine_id"];
            currentMaterial["product_material_name"] =
              matchedData["product_fg_name"];
            currentMaterial["product_material_id"] =
              matchedData["product_fg_id"];
            currentMaterial["product_in_meter"] =
              matchedData["product_in_meter"];
            currentMaterial["product_pick"] = matchedData["prodcut_1000pick"];
            currentMaterial["width"] = matchedData["width"];
          } else {
            currentMaterial["style"] = "";
            currentMaterial["machine_id"] = 0;
            currentMaterial["product_material_name"] = "";
            currentMaterial["product_material_id"] = "";
            currentMaterial["product_in_meter"] = 0;
            currentMaterial["product_pick"] = 0;
            currentMaterial["width"] = 0;
          }
          break;

        case "inspection_mtr":
          if (event._reactName === "onChange") {
            delete event.target.parentElement.dataset.tip;
            // if (event.target.value !== '' && parseFloat(event.target.value) !== 0) {
            if (
              parseFloat(currentMaterial.product_in_meter) >
              parseFloat(enteredValue)
            ) {
              currentMaterial[propertyName] = enteredValue;
              fnCalculateWeight(currentMaterial);
            } else {
              event.target.parentElement.dataset.tip =
                "Inspection Mtr cant exceed Loom Mtr...!";
            }
            // }
          }

          break;
        case "weight":
          if (event._reactName === "onChange") {
            delete event.target.parentElement.dataset.tip;
            // if (event.target.value !== '' && parseInt(event.target.value) !== 0) {
            currentMaterial[propertyName] = enteredValue;
            fnCalculateWeight(currentMaterial);
            // }
          }
          break;

        case "production_operator_id":
          $(`#production_operator_id_${arrayIndex}`).removeAttr("data-tip");
          currentMaterial[propertyName] = enteredValue;
          break;

        case "inspection_production_date":
          currentMaterial[propertyName] = event.target.value;
          break;

        case "roll_no":
          currentMaterial[propertyName] = parseInt(enteredValue)
          if (event._reactName === 'onBlur') {
            resetGlobalQuery();
            globalQuery.columns.push('roll_no');
            globalQuery.table = "xt_weaving_production_inspection_details";
            globalQuery.conditions.push({ field: "roll_no", operator: "=", value: parseInt(enteredValue) });
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            let rollNoAPICall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            if (rollNoAPICall.length > 0) {
              event.target.parentElement.dataset.tip = "Roll No Already Exixts !"
              $("#approveBtn").attr('disabled', true)
            } else {
              delete event.target.parentElement.dataset.tip;
              $("#approveBtn").attr('disabled', false)
            }
          }
          break;

        case "inspection_production_status":
        case "shift":
        case "status_remark":
          currentMaterial[propertyName] = event.target.value;
          delete event.target.parentElement.dataset.tip;
          break;

        default:
          break;
      }

      bottomDetailsData[arrayIndex] = currentMaterial;
      setWeavingInspectionData(bottomDetailsData);
    } catch {
    } finally {
    }
  };

  const fnCalculateWeight = (currentMaterial) => {
    let loom_meter = parseFloat(currentMaterial.product_in_meter);
    let inspection_meter = parseFloat(currentMaterial.inspection_mtr);
    let weight = parseFloat(currentMaterial.weight);
    let difference = 0;

    //Calculating Difference
    if (loom_meter != "" || inspection_meter != "") {
      difference = loom_meter - inspection_meter;
      currentMaterial["difference"] =
        validateNumberDateInput.current.decimalNumber(difference, 4);
    } else {
      currentMaterial["difference"] = difference;
    }

    //Calculating Average
    if (weight != "" || inspection_meter != "") {
      let average = weight / inspection_meter;
      let calculationAverage = average * 1000;
      currentMaterial["average"] = isFinite(
        validateNumberDateInput.current.decimalNumber(calculationAverage, 4)
      )
        ? validateNumberDateInput.current.decimalNumber(calculationAverage, 4)
        : 0;
    } else {
      currentMaterial["average"] = 0;
    }
    return currentMaterial;
  };

  // ------------------------------------------Add (save) functionality ---------------------------------------------------------------

  const addWeavingInspection = async (e) => {
    debugger;
    try {
      setIsLoading(true);

      if (true) {
        let lastestStatus = txt_Weaving_inspection_Status;

        // if (keyForViewUpdate === 'approve') {
        //   const allApprovedWeaving = weavingInspectionData.every(item => item.inspection_production_status === 'A' || item.inspection_production_status === 'PD' || item.inspection_production_status === 'D');
        //   const allApprovedMaterial = inspectionMaterialData.every(item => item.material_status === 'A');
        //   const anyApproved = weavingInspectionData.some(item => item.inspection_production_status === 'A') || inspectionMaterialData.some(item => item.material_status === 'A');
        //   const allPending = weavingInspectionData.every(item => item.inspection_production_status === 'P') && inspectionMaterialData.every(item => item.material_status === 'P');
        //   const anyRejected = weavingInspectionData.some(item => item.inspection_production_status === 'R') || inspectionMaterialData.some(item => item.material_status === 'R');

        //   if (anyRejected) {
        //     lastestStatus = 'R';
        //     setActionLabel('Rejected');
        //   } else if (allApprovedWeaving && allApprovedMaterial) {
        //     lastestStatus = 'A';
        //     setActionLabel('Approved');
        //   } else if (anyApproved) {
        //     lastestStatus = 'I';
        //     setActionLabel('Partially Approved');
        //   } else if (allPending) {
        //     lastestStatus = 'P';
        //     setActionLabel('Save');
        //   } else {
        //     setWeavingInspectionStatus('p');
        //   }
        // }

        const json = {
          TransHeaderData: {},
          TransDetailData: [],
          TransProdWastageData: [],
          TransMaterialData: [],
          commonIds: {
            company_id: COMPANY_ID,
            weaving_production_inspection_master_id: weavingInspectionMasterId,
          },
        };

        const data = {
          company_branch_id: COMPANY_BRANCH_ID,
          company_id: COMPANY_ID,
          financial_year: FINANCIAL_SHORT_NAME,
          weaving_production_inspection_master_id: weavingInspectionMasterId,
          inspection_production_set_no: set_no_ref.current.value,
          inspection_production_code: cmb_inspection_production_code,
          inspection_production_date: dt_inspection_production_date,
          prod_month: txt_prod_month,
          prod_year: txt_prod_year,
          plant_id: 0,
          // production_supervisor_id: parseInt(cmb_prod_supervisor_id),
          section_id: cmb_section_id,
          sub_section_id: cmb_sub_section_id,
          created_by: UserName,
          modified_by: weavingInspectionMasterId === 0 ? null : UserName
        };

        if (keyForViewUpdate === "approve") {
          data["inspection_production_master_status"] = "A";
          data["approved_date"] = dt_approval_date;
          data["approved_by_id"] = aprovalbyId;
        } else {
          data["inspection_production_master_status"] = "P";
        }

        for (let index = 0; index < wastageDetails.length; index++) {
          const wastageDataElement = wastageDetails[index];
          const wastageData = {
            company_branch_id: COMPANY_BRANCH_ID,
            company_id: COMPANY_ID,
            financial_year: FINANCIAL_SHORT_NAME,
            weaving_production_inspection_master_id: weavingInspectionMasterId,
            inspection_production_code: cmb_inspection_production_code,
            inspection_production_date: dt_inspection_production_date,

            prod_month: txt_prod_month,
            prod_year: txt_prod_year,
            plant_id: 0,
            section_id: cmb_section_id,
            sub_section_id: cmb_sub_section_id,
            created_by: UserName,
            modified_by: weavingInspectionMasterId === 0 ? null : UserName,
            wastage_quantity: wastageDataElement.wastage_quantity,
            godown_id: wastageDataElement.godown_id,
            production_wastage_types_id:
              wastageDataElement.production_wastage_types_id,
            production_wastage_types_type:
              wastageDataElement.production_wastage_types_type,
            shift: wastageDataElement.shift,
          };
          json.TransProdWastageData.push(wastageData);
        }

        for (let index = 0; index < weavingInspectionData.length; index++) {
          const element = weavingInspectionData[index];
          const weavingInspectionAddData = {
            company_branch_id: COMPANY_BRANCH_ID,
            financial_year: FINANCIAL_SHORT_NAME,
            company_id: COMPANY_ID,
            weaving_production_inspection_master_id: weavingInspectionMasterId,
            weaving_production_inspection_details_id:
              element.weaving_production_inspection_details_id,
            inspection_production_date: element.inspection_production_date,
            prod_month: txt_prod_month,
            prod_year: txt_prod_year,
            inspection_production_code: cmb_inspection_production_code,
            plant_id: cmb_plant_id,
            // production_supervisor_id: parseInt(cmb_prod_supervisor_id),
            section_id: cmb_section_id,
            sub_section_id: cmb_sub_section_id,
            status_remark: element.status_remark,
            modified_by: weavingInspectionMasterId === 0 ? null : UserName,
            inspection_production_set_no: set_no_ref.current.value,
            machine_id: element.machine_id,
            roll_no: element.roll_no,
            product_pick: element.product_pick,
            production_operator_id: element.production_operator_id,
            sizing_beam_no: element.sizing_beam_no,
            // dispatch_quantity: element.dispatch_quantity,
            created_by: element.created_by,
            product_material_id: element.product_material_id,
            product_material_name: element.product_material_name,
            average: element.average,
            difference: element.difference,
            inspection_production_status: element.inspection_production_status,
            sort_no: element.sort_no,
            style: element.style,
            width: element.width,
            shift: element.shift,
            weight: element.weight,
            product_in_meter: element.product_in_meter,
            godown_id: element.godown_id,
            godown_section_id: godownData[0]["godown_section_id"],
            godown_section_beans_id: godownData[0]["godown_section_beans_id"],
            inspection_mtr: element.inspection_mtr,
            inspection_order_no: cmb_inspection_production_code,
            dispatch_quantity: 0,
            dispatch_weight: 0,
            stock_status: "P",
            stock_status_description: "Pending"
          };

          if (keyForViewUpdate === "approve") {
            weavingInspectionAddData["approved_date"] = dt_approval_date;
            weavingInspectionAddData["stock_status"] = "A";
            weavingInspectionAddData["stock_status_description"] = "In-Stock";
          }
          json.TransDetailData.push(weavingInspectionAddData);
        }

        // if (inspectionMaterialData.length > 0) {
        //   let lastIndexOfMaterialMovement = inspectionMaterialData.length - 1
        //   if (inspectionMaterialData[lastIndexOfMaterialMovement].inspection_production_set_no !== 0) {

        //     for (let index = 0; index < inspectionMaterialData.length; index++) {
        //       const materialDataElement = inspectionMaterialData[index];
        //       const materialMovementData = {
        //         company_branch_id: COMPANY_BRANCH_ID,
        //         company_id: COMPANY_ID,
        //         financial_year: FINANCIAL_SHORT_NAME,
        //         weaving_production_inspection_master_id: 0,
        //         weaving_production_inspection_details_id: 0,
        //         inspection_production_date: materialDataElement.inspection_production_date,
        //         inspection_production_code: cmb_inspection_production_code,
        //         plant_id: cmb_plant_id,
        //         prod_month: txt_prod_month,
        //         prod_year: txt_prod_year,
        //         shift: materialDataElement.shift,
        //         section_id: cmb_section_id,
        //         sub_section_id: cmb_sub_section_id,
        //         weaving_production_inspection_master_id: weavingInspectionMasterId,
        //         weaving_production_inspection_material_id: materialDataElement.weaving_production_inspection_material_id,
        //         inspection_production_order_no: materialDataElement.inspection_production_order_no,
        //         inspection_production_set_no: materialDataElement.inspection_production_set_no,
        //         product_material_id: materialDataElement.product_material_id,
        //         product_material_unit_id: materialDataElement.product_material_unit_id,
        //         consumption_quantity: materialDataElement.consumption_quantity,
        //         product_material_quantity: materialDataElement.product_material_quantity,
        //         material_status: materialDataElement.material_status,
        //         material_status_remark: materialDataElement.material_status_remark,
        //         consumptionQtyInfo: materialDataElement.consumptionQtyInfo,
        //         created_by: UserName,
        //         modified_by: weavingInspectionMasterId === 0 ? null : UserName,

        //       };
        //       json.TransMaterialData.push(materialMovementData)
        //     }
        //   }
        // }

        json.TransHeaderData = data;
        console.log("inspection data", json);
        const formData = new FormData();
        formData.append(
          "WeavingProductionInspectionData",
          JSON.stringify(json)
        );
        const forwardData = {
          method: "POST",
          body: formData,
        };
        const agentApiCall = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/XtWeavingProductionInspectionMaster/FnAddUpdateRecord`,
          forwardData
        );
        const response = await agentApiCall.json();
        if (response.success === 0) {
          setErrMsg(response.error);
          setShowErrorMsgModal(true);
        } else {
          setSuccMsg(response.message);
          setShowSuccessMsgModal(true);
        }
      }
    } catch (error) {
      console.log("error", error);
      // navigate('/Error')
    } finally {
      setIsLoading(false);
    }
  };

  const clearFormFields = (form_type) => {
    switch (form_type) {
      case "wastage":
        setProdWastageType("");
        setWastageQuantity("");
        setGodownId("");
        setShiftWastage("");
        setLabelForWastage("Add");
        $("#wastageFormId input, #wastageFormId select").val("");
        break;
    }
  };
  const validateFields = () => {
    validate.current.validateFieldsOnChange("weavingInspection");

    validate.current.validateFieldsOnChange("wastageFormId");
  };

  /////////////////////////////////////////////////////////////Machine Wastage Details///////////////////////////////////////////////////////////
  const FnUpdateWastageReason = async (rowData, rowIndex, action) => {
    if (action === "update") {
      setLabelForWastage("Update");
    }
    const machineWastageDetails = [...wastageDetails];
    switch (action) {
      case "add":
        const validateWastageReason = await validate.current.validateForm(
          "wastageFormId"
        );
        if (validateWastageReason === true) {
          const findIdtoAdd = machineWastageDetails.some(
            (wastage_Details) =>
              wastage_Details.production_wastage_types_id ===
              cmb_prod_wastage_type &&
              wastage_Details.godown_id === cmb_godown_id &&
              wastage_Details.wastage_quantity === txt_wastage_quantity &&
              wastage_Details.shift === cmb_shift_wastage
          );
          if (findIdtoAdd) {
            $("#error_cmb_prod_wastage_type")
              .text("Wastage Reason already exists...")
              .css({ display: "block", "margin-top": "8px" });
          } else {
            $("#error_cmb_prod_wastage_type").hide();

            ///To Update Row
            const rowNeedToBeUpdated = machineWastageDetails.find(
              (wastage_Details, rowIndex) =>
                prevdataindexforWastage === rowIndex
            );

            if (rowNeedToBeUpdated) {
              rowNeedToBeUpdated.production_wastage_types_id =
                cmb_prod_wastage_type;
              rowNeedToBeUpdated.production_wastage_types_type = $(
                "#cmb_prod_wastage_type option:selected"
              ).text();
              rowNeedToBeUpdated.godown_name = $(
                "#cmb_godown_id option:selected"
              ).text();
              rowNeedToBeUpdated.godown_id = cmb_godown_id;
              rowNeedToBeUpdated.wastage_quantity = txt_wastage_quantity;
              rowNeedToBeUpdated.godown_name = $(
                "#cmb_godown_id option:selected"
              ).text();
              rowNeedToBeUpdated.shift = cmb_shift_wastage;
              setPrevDataIndexForWastage("");
            }

            if (!rowNeedToBeUpdated) {
              const newWastageDetail = {
                company_id: COMPANY_ID,
                company_branch_id: COMPANY_BRANCH_ID,
                financial_year: FINANCIAL_SHORT_NAME,
                inspection_production_date: dt_inspection_production_date,
                prod_month: txt_prod_month,
                inspection_production_code: cmb_inspection_production_code,
                prod_year: txt_prod_year,
                plant_id: cmb_plant_id,
                section_id: cmb_section_id,
                sub_section_id: cmb_sub_section_id,
                production_wastage_types_id: cmb_prod_wastage_type,
                production_wastage_types_type: $(
                  "#cmb_prod_wastage_type option:selected"
                ).text(),
                actual_wastage_production_kg: txt_act_wastages,
                wastage_quantity: txt_wastage_quantity,
                godown_id: cmb_godown_id,
                godown_name: $("#cmb_godown_id option:selected").text(),
                shift: cmb_shift_wastage,
              };
              machineWastageDetails.push(newWastageDetail);
            }
            clearFormFields("wastage");
            setLabelForWastage("Add");
          }
        }

        break;
      case "update":
        setProdWastageType(rowData.production_wastage_types_id);
        setWastageQuantity(rowData.wastage_quantity);
        setGodownId(rowData.godown_id);
        setShiftWastage(rowData.shift);
        setPrevDataIndexForWastage(rowIndex);

        break;
      case "remove":
        if (rowIndex !== -1) {
          machineWastageDetails.splice(rowIndex, 1);
        }
        clearFormFields("wastage");
        break;
    }
    setWastageDetails(machineWastageDetails);
  };

  const calculateMachineWastage = async (value) => {
    const wastage_Type = $("#cmb_prod_wastage_type option:selected").val();
    setProdWastageType(wastage_Type);
  };

  const renderMachineWastageTable = useMemo(() => {
    return (
      <Table
        id="machinewastagetableId"
        style={{ display: "block", width: "600px", overflow: "auto" }}
        bordered
        striped
      >
        <thead className="erp_table_head">
          <tr>
            <td
              className={`erp_table_th ${keyForViewUpdate === "view" || keyForViewUpdate === "approve"
                ? "d-none"
                : "display"
                }`}
            >
              Action
            </td>
            <td className="erp_table_th " style={{ width: "150px" }}>
              Wastage Type
            </td>

            <td className="erp_table_th " style={{ width: "300px" }}>
              Wastage Quantity
            </td>
            <td className="erp_table_th " style={{ width: "150px" }}>
              Godown
            </td>
            <td className="erp_table_th " style={{ width: "150px" }}>
              Shift
            </td>
          </tr>
        </thead>
        <tbody>
          {wastageDetails.length > 0 ? (
            wastageDetails?.map((details, indexOfItem) => (
              <tr key={indexOfItem}>
                <td
                  className={`erp_table_th ${keyForViewUpdate === "view" ||
                    keyForViewUpdate === "approve"
                    ? "d-none"
                    : "display"
                    }`}
                >
                  <MdModeEdit
                    className="erp-edit-btn"
                    onClick={(e) =>
                      FnUpdateWastageReason(details, indexOfItem, "update")
                    }
                  />
                  <MdDelete
                    className="erp-delete-btn"
                    onClick={(e) =>
                      FnUpdateWastageReason(details, indexOfItem, "remove")
                    }
                  />
                </td>
                <td
                  key={indexOfItem}
                  className="erp_table_td"
                  id={`${details}{indexOfItem}`}
                >
                  {details.production_wastage_types_type}
                </td>

                <td
                  key={indexOfItem}
                  className="erp_table_td"
                  id={`${details}{indexOfItem}`}
                >
                  {details.wastage_quantity}
                </td>
                <td
                  key={indexOfItem}
                  className="erp_table_td"
                  id={`${details}{indexOfItem}`}
                >
                  {details.godown_name}
                </td>
                <td
                  key={indexOfItem}
                  className="erp_table_td"
                  id={`${details}{indexOfItem}`}
                >
                  {details.shift}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="10" className="erp_table_td">
                No records found...
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    );
  }, [wastageDetails]);

  return (
    <>
      <DashboardLayout>
        <ComboBox ref={comboDataAPiCall} />
        <ValidateNumberDateInput ref={validateNumberDateInput} />
        <GenerateMaterialId ref={generateAutoNoAPiCall} />

        <FrmValidations ref={validate} />

        {isLoading ? (
          <div className="spinner-overlay">
            <div className="spinner-container">
              <CircularProgress color="primary" />
              <span>Loading...</span>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="card p-1">
          <div className="card-header text-center py-0">
            <label className="erp-form-label-lg text-center">
              Weaving Inspection{actionType}{" "}
            </label>
          </div>

          <form id="weavingInspection">
            <div className="row p-1">
              {/* 1 st Column */}
              <div className="col-sm-4 erp_form_col_div">
                <div className="row">
                  <div className="col-sm-5">
                    <Form.Label className="erp-form-label">
                      Set No <span className="required">*</span>{" "}
                    </Form.Label>
                  </div>
                  <div className="col-sm-6">
                    <Select
                      ref={set_no_ref}
                      options={setNoOptions}
                      isDisabled={["view", "approve"].includes(
                        keyForViewUpdate
                      )}
                      inputId="cmb_prod_supervisor_id"
                      value={
                        setNoOptions.length > 1
                          ? setNoOptions.find(
                            (option) => option.value === cmb_set_no
                          )
                          : null
                      }
                      onChange={(selectedOpt) => {
                        set_no_ref.current = selectedOpt;
                        comboOnChange("cmb_set_no");
                      }}
                      placeholder="Search Set No..."
                      className="form-search-custom"
                      classNamePrefix="custom-select" // Add custom prefix for class names
                      disabled={["view", "approve"].includes(keyForViewUpdate)}
                      styles={{
                        option: (provided, state) => ({
                          ...provided,
                          fontSize: "12px", // Adjust the font size as per your requirement
                        }),
                        singleValue: (provided, state) => ({
                          ...provided,
                          fontSize: "12px", // Adjust the font size as per your requirement
                        }),
                        input: (provided, state) => ({
                          ...provided,
                          fontSize: "12px", // Adjust the font size as per your requirement
                        }),
                      }}
                    />
                    <MDTypography
                      variant="button"
                      id="error_cmb_set_no"
                      className="erp_validation"
                      fontWeight="regular"
                      color="error"
                      style={{ display: "none" }}
                    ></MDTypography>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-5">
                    <Form.Label className="erp-form-label">
                      Month & Year <span className="required">*</span>{" "}
                    </Form.Label>
                  </div>
                  <div className="col-sm-6 d-flex">
                    <Form.Control
                      type="text"
                      id="txt_prod_month"
                      className="erp_input_field"
                      value={txt_prod_month}
                      disabled
                    />
                    <Form.Control
                      type="text"
                      id="txt_prod_year"
                      className="erp_input_field ms-1"
                      value={txt_prod_year}
                      disabled
                    />
                  </div>
                </div>

                {/* <div className="row">
                  <div className="col-sm-5">
                    <Form.Label className="erp-form-label">Insp.Plant Name <span className="required">*</span></Form.Label>
                  </div>
                  <div className="col-sm-6">
                    <select id="cmb_plant_id" className="form-select form-select-sm" value={cmb_plant_id} onChange={(e) => { comboOnChange('cmb_plant_id'); validateFields(); }}>
                      <option value="" disabled>Select</option>
                      {plantOptions.length !== 0 ? (
                        <>{plantOptions?.map(plant => (
                          <option value={plant.field_id}>{plant.field_name}</option>
                        ))} </>
                      ) : null
                      }
                    </select>
                    <MDTypography variant="button" id="error_cmb_plant_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div> */}

                <div className="row">
                  <div className="col-sm-5">
                    <Form.Label className="erp-form-label">
                      Insp.Section <span className="required">*</span>{" "}
                    </Form.Label>
                  </div>
                  <div className="col-sm-6">
                    <select
                      id="cmb_section_id"
                      className="form-select form-select-sm"
                      value={cmb_section_id}
                      onChange={(e) => {
                        setProdSection(e.target.value);
                        comboOnChange("cmb_section_id");
                        validateFields();
                      }}
                    >
                      <option value="">Select</option>
                      {prodsectionOptions.length !== 0 ? (
                        <>
                          {prodsectionOptions?.map((production) => (
                            <option value={production.field_id}>
                              {production.field_name}
                            </option>
                          ))}
                        </>
                      ) : null}
                    </select>
                    <MDTypography
                      variant="button"
                      id="error_cmb_section_id"
                      className="erp_validation"
                      fontWeight="regular"
                      color="error"
                      style={{ display: "none" }}
                    ></MDTypography>
                  </div>
                </div>
              </div>

              <div className="col-sm-4 erp_form_col_div">
                <div className="row">
                  <div className="col-sm-5">
                    <Form.Label className="erp-form-label">
                      Insp. Date <span className="required">*</span>
                    </Form.Label>
                  </div>
                  <div className="col-sm-6">
                    <Form.Control
                      type="date"
                      id="dt_inspection_production_date"
                      className="erp_input_field"
                      value={dt_inspection_production_date}
                      disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'}
                      onChange={(e) => {
                        setProdPlanDate(e.target.value);
                        setMonthYear();
                        fillAutoNo();
                        validateFields();
                      }}
                    />
                    <MDTypography
                      variant="button"
                      id="error_dt_inspection_production_date"
                      className="erp_validation error-msg"
                      fontWeight="regular"
                      color="error"
                      style={{ display: "none" }}
                    ></MDTypography>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-5">
                    <Form.Label className="erp-form-label">
                      Insp.Code<span className="required">*</span>{" "}
                    </Form.Label>
                  </div>
                  <div className="col-sm-6">
                    <Form.Control
                      type="text"
                      className="erp_input_field"
                      id="cmb_inspection_production_code"
                      maxLength="5"
                      value={cmb_inspection_production_code}
                      disabled
                      onInput={() => validateFields()}
                    />
                    <MDTypography
                      variant="button"
                      id="error_cmb_inspection_production_code"
                      className="erp_validation error-msg"
                      fontWeight="regular"
                      color="error"
                      style={{ display: "none" }}
                    ></MDTypography>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-5">
                    <Form.Label className="erp-form-label">
                      Insp.Sub Section <span className="required">*</span>{" "}
                    </Form.Label>
                  </div>
                  <div className="col-sm-6">
                    <select
                      id="cmb_sub_section_id"
                      className="form-select form-select-sm"
                      value={cmb_sub_section_id}
                      onChange={(e) => {
                        setProdSubSection(e.target.value);
                        fillAutoNo();
                        validateFields();
                      }}
                    >
                      <option value="">Select</option>
                      {prodsubsectionOptions.length !== 0 ? (
                        <>
                          {prodsubsectionOptions?.map((subproduction) => (
                            <option
                              key={subproduction.field_id}
                              shortName={
                                subproduction.production_sub_section_short_type
                              }
                              value={subproduction.field_id}
                            >
                              {subproduction.field_name}
                            </option>
                          ))}
                        </>
                      ) : null}
                    </select>
                    <MDTypography
                      variant="button"
                      id="error_cmb_sub_section_id"
                      className="erp_validation"
                      fontWeight="regular"
                      color="error"
                      style={{ display: "none" }}
                    ></MDTypography>
                  </div>
                </div>

                {/* <div className='row'>
                  <div className='col-sm-5'>
                    <Form.Label className="erp-form-label">Insp.supervisor <span className="required">*</span> </Form.Label>
                  </div>
                  <div className="col-sm-6">
                    <Select ref={cmb_prod_supervisor_id_ref}
                      options={machineOperatorOptions}
                      isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                      inputId="cmb_prod_supervisor_id" // Provide the ID for the input box
                      value={machineOperatorOptions.length > 1 ? machineOperatorOptions.find(option => option.value === cmb_prod_supervisor_id) : null}
                      onChange={(selectedOpt) => {
                        setProdSupervisor(selectedOpt.value);
                        cmb_prod_supervisor_id_ref.current = selectedOpt;
                      }}
                      placeholder="Search Supervisor here..."
                      className="form-search-custom"
                      classNamePrefix="custom-select" // Add custom prefix for class names
                      disabled={['view', 'approve'].includes(keyForViewUpdate)}
                      styles={{
                        option: (provided, state) => ({
                          ...provided,
                          fontSize: '12px' // Adjust the font size as per your requirement
                        }),
                        singleValue: (provided, state) => ({
                          ...provided,
                          fontSize: '12px' // Adjust the font size as per your requirement
                        }),
                        input: (provided, state) => ({
                          ...provided,
                          fontSize: '12px' // Adjust the font size as per your requirement
                        })
                      }}
                    />
                    
                    <MDTypography variant="button" id="error_cmb_prod_supervisor_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                    </MDTypography>
                  </div>
                </div> */}
              </div>

              {keyForViewUpdate === 'approve' || (keyForViewUpdate === 'view' && cmb_aproved_by !== '') ? <div className="col-sm-4 erp_form_col_div">
                <div className="row">
                  <div className="col-sm-4">
                    <Form.Label className="erp-form-label">
                      Approved By & Date <span className="required">*</span>{" "}
                    </Form.Label>
                  </div>
                  <div className="col-sm-8 d-flex">
                    <div style={{ flex: "2" }}>
                      <Form.Control
                        type="text"
                        id="cmb_aproved_by"
                        className="erp_input_field"
                        value={cmb_aproved_by}
                        disabled
                      />
                    </div>
                    <div style={{ flex: "1" }}>
                      <Form.Control
                        type="date"
                        id="dt_approval_date"
                        className="erp_input_field"
                        value={dt_approval_date}
                        onChange={(e) => setAprovalDate(e.target.value)}
                        disabled={keyForViewUpdate === 'view'}
                      />
                    </div>
                  </div>
                </div>
              </div> : ''}

            </div>
          </form>

          <Accordion defaultActiveKey="1">
            <Accordion.Item eventKey="1">
              <Accordion.Header className="erp-form-label-md">
                Warping Production Details
              </Accordion.Header>
              <Accordion.Body>
                <div className="row mt-1">
                  <label className="erp-form-label-md-lg">
                    Production Details
                  </label>
                </div>
                {renderWeavingInspectionDetails}
                <hr />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          {/*..............Shiftwise Machine Wastage Entry............................... */}
          <div className="col-lg-12 col-12 erp_form_col_div">
            <div className="card">
              <div className="card-header py-0 main_heding mb-0">
                <label className="erp-form-label-md-lg">
                  Shiftwise Machine Wastage Entry
                </label>
              </div>
              {/* Card's Body Starts*/}
              <div className="card-body p-0">
                <form id="wastageFormId">
                  <div className="row p-1">
                    <div className="col-sm-4 erp_form_col_div">
                      <div className="row">
                        <div className="col-sm-5">
                          <Form.Label className="erp-form-label">
                            Prod. Wastage Type
                            <span className="required">*</span>{" "}
                          </Form.Label>
                        </div>
                        <div className="col-sm-6">
                          <select
                            id="cmb_prod_wastage_type"
                            className="form-select form-select-sm"
                            value={cmb_prod_wastage_type}
                            onChange={(e) => {
                              calculateMachineWastage("");
                              validateFields();
                            }}
                          >
                            <option value="">Select</option>
                            {prodWastageOptions.length !== 0 ? (
                              <>
                                {prodWastageOptions?.map((machine) => (
                                  <option value={machine.field_id}>
                                    {machine.field_name}
                                  </option>
                                ))}
                              </>
                            ) : null}
                          </select>
                          <MDTypography
                            variant="button"
                            id="error_cmb_prod_wastage_type"
                            className="erp_validation"
                            fontWeight="regular"
                            color="error"
                            style={{ display: "none" }}
                          ></MDTypography>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-5">
                          <Form.Label className="erp-form-label">
                            Wastage Quantity<span className="required">*</span>{" "}
                          </Form.Label>
                        </div>
                        <div className="col-sm-6">
                          <Form.Control
                            type="text"
                            disabled={keyForViewUpdate === 'view'}
                            id="txt_wastage_quantity"
                            className="erp_input_field"
                            value={txt_wastage_quantity}
                            onChange={(e) => {
                              setWastageQuantity(
                                validateNumberDateInput.current.decimalNumber(
                                  e.target.value,
                                  4
                                )
                              );
                              validateFields();
                            }}
                          />
                          <MDTypography
                            variant="button"
                            id="error_txt_wastage_quantity"
                            className="erp_validation"
                            fontWeight="regular"
                            color="error"
                            style={{ display: "none" }}
                          ></MDTypography>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-5">
                          <Form.Label className="erp-form-label">
                            Godown<span className="required">*</span>{" "}
                          </Form.Label>
                        </div>
                        <div className="col-sm-6">
                          <select
                            id="cmb_godown_id"
                            className="form-select form-select-sm"
                            value={cmb_godown_id}
                            onChange={(e) => {
                              setGodownId(e.target.value);
                              validateFields();
                            }}
                          >
                            <option value="">Select</option>
                            {godownNameOptions.length !== 0 ? (
                              <>
                                {godownNameOptions?.map((godownType) => (
                                  <option value={godownType.field_id}>
                                    {godownType.godown_name}
                                  </option>
                                ))}
                              </>
                            ) : null}
                          </select>
                          <MDTypography
                            variant="button"
                            id="error_cmb_godown_id"
                            className="erp_validation"
                            fontWeight="regular"
                            color="error"
                            style={{ display: "none" }}
                          ></MDTypography>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-5">
                          <Form.Label className="erp-form-label">
                            Shift <span className="required">*</span>{" "}
                          </Form.Label>
                        </div>
                        <div className="col-sm-6">
                          <select
                            id="cmb_shift_wastage"
                            className="form-select form-select-sm"
                            value={cmb_shift_wastage}
                            onChange={(e) => {
                              setShiftWastage(e.target.value);
                              validateFields();
                            }}
                          >
                            <option value="">Select</option>
                            {shiftOptions.length !== 0 ? (
                              <>
                                {shiftOptions?.map((shift) => (
                                  <option value={shift.field_name}>
                                    {shift.field_name}
                                  </option>
                                ))}
                              </>
                            ) : null}
                          </select>
                          <MDTypography
                            variant="button"
                            id="error_cmb_shift"
                            className="erp_validation"
                            fontWeight="regular"
                            color="error"
                            style={{ display: "none" }}
                          ></MDTypography>
                        </div>
                      </div>

                      <div
                        className={`erp_frm_Btns  mt-2 mb-2 ${keyForViewUpdate === "view" ||
                          keyForViewUpdate === "approve"
                          ? "d-none"
                          : "display"
                          }`}
                      >
                        <MDButton
                          type="button"
                          id="action_Button"
                          className="erp-gb-button ms-2 disable-button"
                          variant="button"
                          onClick={() => FnUpdateWastageReason("", "", "add")}
                          fontWeight="regular"
                        >
                          {labelForWastage}
                        </MDButton>
                        <MDButton
                          type="button"
                          className="erp-gb-button ms-2 disable-button"
                          variant="button"
                          id="back_Button"
                          onClick={(e) => clearFormFields("wastage")}
                          fontWeight="regular"
                        >
                          Clear
                        </MDButton>
                      </div>
                    </div>

                    <div className="col-sm-6 erp_form_col_div">
                      <div className="row">{renderMachineWastageTable}</div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="card-footer mb-5 py-0 text-center">
            <MDButton
              type="button"
              className="erp-gb-button"
              onClick={() => {
                const path =
                  compType === "Register"
                    ? "/Transactions/TWeavingInspection/FrmTWeavingInspectionListing/reg"
                    : "/Transactions/TWeavingInspection/FrmTWeavingInspectionListing";
                navigate(path);
              }}
              variant="button"
              fontWeight="regular"
            >
              Back
            </MDButton>
            <MDButton
              type="submit"
              id="approveBtn"
              onClick={(e) => addWeavingInspection(e)}
              className={`erp-gb-button ms-2 ${keyForViewUpdate === "view" ? "d-none" : "display"
                }`}
              variant="button"
              fontWeight="regular"
            >
              {actionLabel}
            </MDButton>
          </div>
        </div>
        <SuccessModal
          handleCloseSuccessModal={() => handleCloseSuccessModal()}
          show={[showSuccessMsgModal, succMsg]}
        />
        <ErrorModal
          handleCloseErrModal={() => handleCloseErrModal()}
          show={[showErrorMsgModal, errMsg]}
        />
      </DashboardLayout>
    </>
  );
}

export default FrmTWeavingInspectionEntry;
