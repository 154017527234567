import React, { useState, useRef, useEffect, useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import $ from 'jquery';

// Imports React bootstrap
import Form from 'react-bootstrap/Form';
import { Accordion, Button, Modal, Table } from "react-bootstrap";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
//File Imports
import FrmValidations from "FrmGeneric/FrmValidations";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal"
import ComboBox from "Features/ComboBox";
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import GenerateTAutoNo from "FrmGeneric/GenerateTAutoNo";
import ExcelExport from "Features/Exports/ExcelExport";
import DocumentF from "Features/Document";
import FrmCity from "FrmGeneric/MCity/FrmCity";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";
import FrmMProductTypeEntry from "Masters/MProductType/FrmProductEntry";
import { FiDownload, FiPrinter } from "react-icons/fi";
import { MdDelete, MdRefresh } from "react-icons/md";
import ConfigConstants from "assets/Constants/config-constant";
import { CircularProgress } from "@material-ui/core";
import InfoModal from "components/Modals/InfoModal";
import ProductListProductTypeWise from "FrmGeneric/ProductListProductTypeWise";
import { RxCheckCircled, RxCrossCircled } from "react-icons/rx";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SearchItemFilter from "FrmGeneric/SearchItemFilter";
import { AiOutlineSchedule } from "react-icons/ai";
import Tooltip from "@mui/material/Tooltip";
import Select from 'react-select';


export default function TFabricDispatchNoteEntry() {
    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    const currentDate = new Date().toISOString().split('T')[0];

    // Select box
    let expanded = false;
    let docGroup = "Trading Dispatch Advisory"

    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, UserName, FINANCIAL_SHORT_NAME, COMPANY_NAME, UserId } = configConstants;

    const { state } = useLocation()

    //changes by tushar
    const { idList, keyForViewUpdate, compType = 'Transactions', modules_forms_id, deletionKey = false } = state || {};

    // UseRefs
    const validate = useRef();
    const comboDataFunc = useRef();
    const generateAutoNoAPiCall = useRef();
    const importFile = useRef(null)
    const exlsExp = useRef();
    const validateNumberDateInput = useRef();

    const [isApprove, setIsApprove] = useState(false);
    const [actionLabel, setActionLabel] = useState('Save')
    const [actionType, setActionType] = useState('')
    const [isLoading, setIsLoading] = useState(false);

    //accordian access hooks 

    const [visibleAccordions, setAccordianAccessControl] = useState([]);
    const [isTaxableAccordion, setIsTaxableAccordian] = useState(true);

    // Product Type Properties Table Data
    const [productTypePropertiesData, setProductTypePropertiesData] = useState([]);



    const ActionType = async () => {
        switch (keyForViewUpdate) {
            case 'approve':
                setActionType('(Approval)');
                setActionLabel('Approve')
                setIsApprove(true)
                await validate.current.readOnly("dispatchnoteId");
                await validate.current.readOnly("GatePassFrmId");
                $('#txt_note_Remark').removeAttr('readOnly')
                break;
            case 'update':
                $('#txt_dispatch_date').prop('disabled', true);
                $('#cmb_customer_id').prop('disabled', true);
                setActionType('(Modification)');
                setActionLabel('Update')
                break;
            case 'view':
                if (deletionKey === true) {
                    setActionType('(Deletion)');
                } else {
                    setActionType('(View)');
                }
                await validate.current.readOnly("dispatchnoteId");
                await validate.current.readOnly("GatePassFrmId");
                break;
            default:
                setActionType('(Creation)');
                break;
        }

    };

    const [dispatch_schedule_version, setDispVersion] = useState(idList !== null ? idList.dispatch_schedule_version : 1)
    const [txt_dispatch_schedule_no, setDispScheduleNo] = useState(idList !== null ? idList.dispatch_schedule_no : '')
    const [dispatch_schedule_master_transaction_id, setdispatchscheduledetailstransactionId] = useState(idList !== null ? idList.dispatch_schedule_master_transaction_id : 0);

    // Form Fields
    const [checkedCustomerOrderNoLength, setCheckedCustomerOrderNoLength] = useState(0)
    const [txt_actual_weight, settxt_actual_weight] = useState(0);
    const [txt_actual_quantity, settxt_actual_quantity] = useState(0);
    const [cmb_customer_state_id, setCustomerState] = useState('');
    const [cmb_customer_city_id, setCustomerCity] = useState('');
    const [rb_dispatch_order_creation_type, setDispOrdCreationType] = useState("S");
    const [txt_remark, setRemark] = useState('');
    const [txt_total_weight, settxt_Total_Weight] = useState(0)
    const [txt_total_quantity, settxt_total_quantity] = useState(0)
    const [txt_dispatch_date, setDispatchDate] = useState(today);

    //Form Fields
    const [cmb_dispatch_schedule_type, setDispatchScheduleType] = useState('');
    const [cmb_supervisor, setSupervisor] = useState('');
    const [cmb_customer_id, setCustomerId] = useState('');
    const [cmb_count_id, setCountId] = useState('');

    // gate pass fields
    const [txt_gate_pass_no, setGatePassNo] = useState("");
    const [dt_gate_pass_date, setGatePassDate] = useState(currentDate);
    const [txt_vehicale_no, setVehicaleNo] = useState("");
    const [txt_net_weight, setNetWeight] = useState("0");
    const [txt_container_no, setContainerNo] = useState("");
    const [txt_seal_no, setSealNo] = useState("");
    const [txt_driver_name, setDriverName] = useState("");
    const [txt_driver_contact_no, setDriverContactNo] = useState("");
    const [dt_vehicle_reporting_time, setVehicleReportingTime] = useState("");
    const [dt_vehicle_loading_started_time, setVehicleLoadingStartedTime] = useState("");
    const [dt_vehicle_loading_finish_time, setVehicleLoadingFinishTime] = useState("");

    // consignee details form fields
    const [cmb_consignee_id, setConsigneeId] = useState('');
    const [cmb_consignee_state_id, setConsigneeState] = useState('');
    const [cmb_consignee_city_id, setConsigneeCity] = useState('');
    const [cmb_approved_by_id, setApprovedById] = useState(UserId);
    const [dt_approved_date, setApproveDate] = useState(today);
    const [txt_note_Remark, setNoteRemark] = useState('');
    const [txt_other_terms_conditions, setothercondition] = useState('');
    const [rb_is_active, setIsActive] = useState(true)

    // Combo box arrays
    const [stateOpts, setStateOpts] = useState([]);
    const [custContactDetails, setCustContactDetails] = useState([]);
    const [custContactDetailsCols, setCustContactDetailsCols] = useState([]);
    const [consigneeList, setConsigneeList] = useState([]);
    const [cmb_dispatch_note_status, setDispatchNoteStatus] = useState('P');
    const [customerList, setCustomerList] = useState([]);
    const [customerCityOpts, setCustomerCityOpts] = useState([]);
    const [consigneeCityOpts, setConsigneeCityOpts] = useState([]);
    const [approveByOpts, setApproved] = useState([]);
    const [customerOrderNoList, setCustomerOrderNoList] = useState([])
    const [storedDispatchDetailsData, setStoredDispatchDetailsData] = useState([])
    const [cmb_supervisorList, setSupervisorList] = useState([])
    const [dispatchScheduleTypeList, setDispatchScheduleTypeList] = useState([])
    // const [packingSummaryList, setPackingSummaryList] = useState([])
    // const [PackingDetailsList, setPackingDetailsList] = useState([])

    const [inspectionSummatryList, setInspectionSummaryList] = useState([])
    const [inspectionDetailsList, setInspectionDetailsList] = useState([])


    const [DispatchMasterJson, setDispatchMasterJson] = useState([]);
    const [dispatchDetailsData, setDispatchDetailsData] = useState([]);

    // For Uploaded Document Table
    const [uploadedDocData, setUploadedDocData] = useState([]);

    // Fn to get Inspection modal with items
    const [showInspectionDetailsModal, setShowInspectionDetailsModal] = useState(false);
    const [currentInspectionMtrlForModal, setCurrentInspectionMtrlForModal] = useState(null);

    //Error Msg
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    // Success Msg
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');
    const [modalOrderDetails, setModalOrderDetails] = useState('')

    // Info Modal
    const [showInfoMsgModal, setShowInfoMsgModal] = useState(false);
    const [infoMsg, setInfoMsg] = useState('');
    const handleCloseInfoModal = () => setShowInfoMsgModal(false);

    //Delete Popup modal.
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const FnCloseDeleteModal = () => {
        setShowDeleteModal(false)
        // moveToListing();
    };

    // For confirmation Modal.
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const FnCloseConfirmationModal = () => {
        setShowConfirmationModal(false)
        moveToListing();
    };

    //Show another component in modal
    const [showAddRecModal, setShowAddRecModal] = useState(false);
    const [modalHeaderName, setHeaderName] = useState('')
    let supervisorComboRef = useRef(null);
    const handleCloseRecModal = async () => {
        switch (modalHeaderName) {
            case 'Customer City':
                comboOnChange('CustomerState');
                break;
            case 'Consignee City':
                comboOnChange('ConsigneeState');
                break;
            case 'Dispatch Schedule Type':
                resetGlobalQuery();
                globalQuery.columns.push("field_id");
                globalQuery.columns.push("field_name");
                globalQuery.columns.push("product_type_short_name");
                globalQuery.table = "smv_product_type"
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });

                const product_Types = await comboDataFunc.current.fillFiltersCombo(globalQuery)
                setDispatchScheduleTypeList(product_Types)
                break;
            default:
                break;

        }
        setShowAddRecModal(false);
        sessionStorage.removeItem('dataAddedByCombo')
        setTimeout(() => {
            $(".erp_top_Form").css({ "padding-top": "110px" });
        }, 200)

    }

    // For navigate
    const navigate = useNavigate();

    // Document Form
    const [showDocumentForm, setShowDocumentForm] = useState(false);
    const FnCloseDocumentUploadModal = async () => {
        setShowDocumentForm(false)
        await showDocumentRecords();
    };
    const viewDocumentForm = () => setShowDocumentForm(true);
    const [showBomFilterForm, setShowBomFilterForm] = useState(false);
    const colNames = rb_dispatch_order_creation_type === 'M'
        ?
        // [ 'product_rm_name', 'product_rm_tech_spect', 'product_rm_std_weight', 'product_rm_stock_unit_name',
        //     'lead_time', "stock_quantity", "stock_weight", "expected_dispatch_quantity", "expected_dispatch_weight",
        //     "actual_dispatch_quantity", "actual_dispatch_weight", "product_rm_our_part_code", 'product_category1_name',
        //     'product_category2_name', 'product_category3_name', 'product_category4_name', 'product_category5_name',
        //     'product_material_type_name', 'product_material_grade_name', 'product_make_name', 'product_rm_id',
        //     'product_rm_stock_unit_id', 'product_rm_packing_id' ]
        ['product_name', 'product_type_name', 'product_tech_spect', 'product_std_weight', 'product_unit_name',
            'product_lead_time', "stock_quantity", "stock_weight", "expected_dispatch_quantity", "expected_dispatch_weight",
            "actual_dispatch_quantity", "actual_dispatch_weight", "product_our_part_code",
            // 'product_category1_name', 'product_category2_name', 'product_category3_name', 'product_category4_name', 'product_category5_name',
            'product_type_name', 'product_material_grade_name', 'product_make_name', 'product_id', 'product_unit_id', 'product_packing_id']
        : ['sr_no', "customer_order_no", "product_material_print_name", "product_material_tech_spect",
            "batch_no", "so_sr_no", "material_schedule_date", "material_quantity",
            "material_weight", "dispatched_quantity", "dispatched_weight", "stock_quantity",
            "stock_weight", "expected_dispatch_quantity", "expected_dispatch_weight", "actual_dispatch_quantity",
            "actual_dispatch_weight", "product_material_our_part_code", "product_material_unit_id",
            "product_material_packing_id", "dispatch_return_quantity", "dispatch_return_weight",
            "pending_quantity", "pending_weight", "delayed_days",
            "invoice_quantity", "invoice_weight", "dispatch_schedule_item_status",];

    // Use colNames in your component as needed

    const viewBomFilterForm = async () => {
        let scheduleType = document.getElementById('cmb_dispatch_schedule_type').value
        if (scheduleType === '0' || scheduleType === '') {
            $('#error_cmb_dispatch_schedule_type').text('Please select schedule type!..')
            $("#cmb_dispatch_schedule_type").focus();
            $('#error_cmb_dispatch_schedule_type').show()
            return;
        }
        sessionStorage.setItem('requiredCols', JSON.stringify(colNames));
        setShowBomFilterForm(true);
    }

    // Success Msg HANDLING
    const handleCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
        moveToListing();
    }

    useEffect(() => {
        setIsLoading(true);
        const loadDataOnload = async () => {
            // const isTaxable = await FnCheckAccordianAccess();
            ActionType()
            await fillComobos();
            if (idList !== null) {
                await FnCheckUpdateResponce();
            }
            setIsLoading(false);
        }
        loadDataOnload()

        // Close customer order no dropdown
        $(document).on('mouseup', function (e) {
            var container = $("#customer-order-ul");
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                container.hide();
            }
        });
    }, [])

    const FnCloseBomFilter = () => {
        setShowBomFilterForm(false)
        const addedMaterialsForDispatch = JSON.parse(sessionStorage.getItem('filteredMaterialData')) || [];
        // const getData = JSON.parse(sessionStorage.getItem('bomfilteredData'))
        if (addedMaterialsForDispatch.length > 0) {
            setIsLoading(true)
            FnConvert2DispSchDetails('Filter2DispSchDetails', addedMaterialsForDispatch);
            setIsLoading(false)
        }
        sessionStorage.setItem('isComboFilterExist', false)

        // Remove Bom Filter Session
        let LocalArr = JSON.parse(localStorage.getItem(`localStorageKeyArr`)) || [];
        if (LocalArr.length > 0) {
            for (let localArrIndex = 0; localArrIndex < LocalArr.length; localArrIndex++) {
                localStorage.removeItem(LocalArr[localArrIndex])
            }
        }
    };

    // Function to convert all objects to the dispSche-details keys object.
    const FnConvert2DispSchDetails = async (convertFrom, recievedData) => {
        let currentDate = new Date();
        let materialIds = [];
        let getAvlStockQty = [];
        switch (convertFrom) {
            case 'Filter2DispSchDetails':
                let todayDate = today();
                let convertedDataFilter2DispSchDetails = recievedData
                    .filter(dispSchDetailFromFilter => {
                        return !dispatchDetailsData.some(dispatchDetail => dispatchDetail.product_id === dispSchDetailFromFilter.product_id);
                    })
                    .map((dispSchDetailFromFilter, index) => {
                        const {
                            product_type_group,
                            product_type_name: product_type,
                            product_name: product_material_name,
                            product_our_part_code: product_material_our_part_code,
                            product_tech_spect: product_material_tech_spect,
                            product_unit_name: product_material_unit_name,
                            product_packing_name: product_packing_name,
                            product_std_weight: product_material_std_weight,
                            product_id: product_material_id,
                            product_unit_id: product_material_unit_id,
                            product_packing_id: product_material_packing_id,
                            ...rest
                        } = dispSchDetailFromFilter;

                        let keysUpdatedForDispSchDetail = {
                            product_type_group, product_type, product_material_name, product_material_tech_spect, product_material_our_part_code,
                            product_material_unit_name, product_packing_name, product_material_std_weight,
                            product_material_id, product_material_unit_id, product_material_packing_id,
                            product_material_print_name: product_material_name,
                            ...rest
                        };

                        // add the new keys which are not received from the filter popup.
                        keysUpdatedForDispSchDetail.product_id = product_material_id
                        keysUpdatedForDispSchDetail.product_our_part_code = product_material_our_part_code
                        keysUpdatedForDispSchDetail.dispatch_return_quantity = 0
                        keysUpdatedForDispSchDetail.dispatch_return_weight = 0
                        keysUpdatedForDispSchDetail.pending_quantity = 0
                        keysUpdatedForDispSchDetail.pending_weight = 0
                        keysUpdatedForDispSchDetail.delayed_days = 0
                        keysUpdatedForDispSchDetail.invoice_quantity = 0
                        keysUpdatedForDispSchDetail.invoice_weight = 0
                        keysUpdatedForDispSchDetail.dispatch_schedule_item_status = 'P'
                        keysUpdatedForDispSchDetail.dispatch_schedule_remark = '';
                        keysUpdatedForDispSchDetail.expected_schedule_date = today();
                        return keysUpdatedForDispSchDetail;
                    });
                let updatedMaterialDetail = [...dispatchDetailsData, ...convertedDataFilter2DispSchDetails];
                sessionStorage.setItem('filteredMaterialData', JSON.stringify(updatedMaterialDetail));
                setDispatchDetailsData(updatedMaterialDetail);

                let updatedArrayIds = updatedMaterialDetail.map((detailsItem) => detailsItem.product_id);
                if (updatedArrayIds.length !== 0) {
                    const formattedIds = updatedArrayIds.map((id, index) => `${id}`);
                    resetGlobalQuery();
                    globalQuery.columns.push("*");
                    globalQuery.table = "smv_product_dynamic_parameters"
                    // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    globalQuery.conditions.push({ field: "product_id", operator: "IN", values: formattedIds });
                    const getProductProperties = await comboDataFunc.current.removeCatcheFillCombo(globalQuery);
                    await fnGetProductProperties(getProductProperties);
                }

                return updatedMaterialDetail;

            case 'SalesOrder2DispSchDetails':
                let srNoForSalesOrderBased = 1;
                let expectedSOMaterialScheduleDate;
                let convertedDataSalesOrder2DispSchDetails = [];
                // Iterate the loop on received data and change the keys.
                recievedData?.map((challanDetailFromSalesOrder, index) => {
                    let dispatchQty = parseFloat(challanDetailFromSalesOrder.material_quantity) - parseFloat(challanDetailFromSalesOrder.previous_dispatch_quantity);
                    let dispatchWt = parseFloat(challanDetailFromSalesOrder.material_weight) - parseFloat(challanDetailFromSalesOrder.previous_dispatch_weight);
                    // let dispatchWt = parseFloat(dispatchQty) * parseFloat(challanDetailFromSalesOrder.product_rm_std_weight)
                    // Calculate the delayed days.
                    expectedSOMaterialScheduleDate = new Date(challanDetailFromSalesOrder.material_schedule_date);  // expected date.
                    let delayedDays = Math.floor((currentDate - expectedSOMaterialScheduleDate) / (1000 * 60 * 60 * 24));

                    let requiredJSONForDispSchMaterial = {
                        sr_no: srNoForSalesOrderBased,
                        so_sr_no: challanDetailFromSalesOrder.so_sr_no,
                        customer_order_no: challanDetailFromSalesOrder.customer_order_no,
                        customer_order_Date: challanDetailFromSalesOrder.customer_order_Date,
                        sales_order_no: challanDetailFromSalesOrder.sales_order_no,
                        sales_order_version: challanDetailFromSalesOrder.sales_order_version,
                        sales_order_Date: challanDetailFromSalesOrder.sales_order_date,
                        product_type_group: challanDetailFromSalesOrder.product_type_group,
                        product_material_id: challanDetailFromSalesOrder.product_material_id,
                        product_material_name: challanDetailFromSalesOrder.product_material_name,
                        product_material_print_name: challanDetailFromSalesOrder.product_material_print_name,
                        product_material_tech_spect: challanDetailFromSalesOrder.product_material_tech_spect,
                        product_material_unit_id: challanDetailFromSalesOrder.product_material_unit_id,
                        product_material_unit_name: challanDetailFromSalesOrder.product_material_stock_unit_name,
                        product_material_packing_id: challanDetailFromSalesOrder.product_material_packing_id,
                        product_packing_name: challanDetailFromSalesOrder.product_material_packing_name,
                        hsn_sac_id: challanDetailFromSalesOrder.product_material_hsn_code_id,
                        product_material_hsn_sac_code: challanDetailFromSalesOrder.product_material_hsn_sac_code,
                        hsn_sac_percent: challanDetailFromSalesOrder.product_material_hsn_sac_rate,
                        product_material_std_weight: challanDetailFromSalesOrder.product_material_std_weight,
                        available_stock_qty: 0,
                        max_dispatch_quantity: validateNumberDateInput.current.decimalNumber(JSON.stringify(dispatchQty), 4),
                        dispatch_quantity: validateNumberDateInput.current.decimalNumber(JSON.stringify(dispatchQty), 4),
                        dispatch_weight: validateNumberDateInput.current.decimalNumber(JSON.stringify(dispatchWt), 4),
                        // item_rate: validateNumberDateInput.current.decimalNumber(JSON.stringify(singleMaterialRate), 4),
                        item_rate: challanDetailFromSalesOrder.so_rate,
                        item_amount: validateNumberDateInput.current.decimalNumber((challanDetailFromSalesOrder.so_rate * parseFloat(dispatchQty)).toString(), 4),
                        item_discount_percent: 0,          // Not applicable for sales-order-based
                        item_discount_amount: 0,          // Not applicable for sales-order-based
                        item_freight_amount: 0,
                        expected_schedule_date: challanDetailFromSalesOrder.material_schedule_date,
                        delayed_days: delayedDays,
                        dispatch_return_quantity: 0,
                        dispatch_return_weight: 0,
                        pending_quantity: 0,
                        pending_weight: 0,
                        invoice_quantity: 0,
                        invoice_weight: 0,
                        pree_close_flag: 'false',
                        dispatch_challan_item_status: "P",
                        dispatch_challan_remark: '',
                        remark: '',
                        production_count_id: challanDetailFromSalesOrder.production_count_id,
                        production_count_name: challanDetailFromSalesOrder.production_count_name,
                    }

                    // send material-item for calculation.
                    convertedDataSalesOrder2DispSchDetails.push(requiredJSONForDispSchMaterial);
                    srNoForSalesOrderBased++;
                });

                if (convertedDataSalesOrder2DispSchDetails.length > 0) {
                    // Get the available stock qty.
                    convertedDataSalesOrder2DispSchDetails = await FnUpdateStockAvailability(convertedDataSalesOrder2DispSchDetails, false);
                }

                setDispatchDetailsData(convertedDataSalesOrder2DispSchDetails);
                return convertedDataSalesOrder2DispSchDetails;

            default:
                break;
        }

    }

    const fillComobos = async () => {
        try {

            // const getsetApprovedCall = await comboDataFunc.current.fillMasterData("cmv_employee", "", "");
            // setApproved(getsetApprovedCall);
            // resetGlobalQuery();
            // globalQuery.columns = ["field_id", "field_name"];
            // globalQuery.table = "cmv_employee"
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            // comboDataFunc.current.fillFiltersCombo(globalQuery)
            //     .then(employeeList => {
            //         setApproved(employeeList);
            //     });
            resetGlobalQuery();
            globalQuery.columns.push("user_id as field_id");
            globalQuery.columns.push("user_name as field_name");
            globalQuery.table = "amv_modules_forms_user_access";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "modules_forms_id", operator: "=", value: modules_forms_id });
            globalQuery.conditions.push({ field: "approve_access", operator: "=", value: 1 });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(getApproveEmpAccessList => {
                    setApproved(getApproveEmpAccessList);
                    console.log("Approve Access Emp List: ", getApproveEmpAccessList);
                });


            // const getStatesApiCall = await comboDataFunc.current.fillMasterData("cmv_state", "is_delete", "0");
            // setStateOpts(getStatesApiCall);
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'state_code'];
            globalQuery.table = "cmv_state"
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(stateList => {
                    setStateOpts(stateList);
                });

            resetGlobalQuery();
            globalQuery.columns = ["field_id", "field_name", 'product_type_short_name'];
            globalQuery.table = "smv_product_type"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "product_type_group", operator: "IN", values: ["WV"] });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(productTypeList => {
                    setDispatchScheduleTypeList(productTypeList);
                });

            resetGlobalQuery();
            globalQuery.columns = ["field_id", "field_name", "is_sez", "cust_branch_gst_no", 'cust_branch_type']
            globalQuery.table = "cmv_customer_summary"
            // globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(customerList => {
                    setCustomerList(customerList);
                    const consignees = customerList.filter(customer => customer.cust_branch_type === "Consignee");
                    setConsigneeList(consignees);
                });

            // const employeeList = await comboDataFunc.current.fillMasterData("cmv_employee", "department_id", "2")
            // setSupervisorList(employeeList)
            // comboDataFunc.current.fillMasterData("cmv_employee", "department_id", "2")
            //     .then(employeeList => {
            //         setSupervisorList(employeeList);
            //     });
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name', 'reporting_to', 'email_id1']
            globalQuery.table = "cmv_employee"
            // globalQuery.conditions.push({ field: "department_id", operator: "=", value: '2' });
            globalQuery.conditions.push({ field: "employee_type", operator: "!=", value: "Worker" });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataFunc.current.fillFiltersCombo(globalQuery)
                .then(supervisorsOpts => {
                    const supervisorList = [
                        { value: '', label: 'Select', field_id: '', field_name: '' },
                        { value: '0', label: 'Add New Record+', field_id: '', field_name: '' },
                        ...supervisorsOpts.map((supervisor) => ({ ...supervisor, value: supervisor.field_id, label: supervisor.field_name })),
                    ];

                    setSupervisorList(supervisorList);

                });

        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }

    const FnCheckUpdateResponce = async () => {
        try {
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtDispatchScheduleDetails/FnShowAllDetailsAndMastermodelRecords/${idList.dispatch_schedule_version}/${idList.financial_year}/${COMPANY_ID}?dispatch_schedule_no=${idList.dispatch_schedule_no}`)
            const response = await apiCall.json();
            let dispatchMasterData = response.DispatchScheduleMasterRecord;
            let dispatchDetailsData = response.DispatchScheduleDetailsRecords;
            let dispatchPackingDetails = response.DispatchSchedulePackingDetailsRecord || [];
            let dispatchInspectionDtls = response.DispatchScheduleInspectionDetailsRecord || [];
            let dispatchProductParams = response.ProductDynamicParametersRecord || [];
            await fnGetProductProperties(dispatchProductParams)
            if (dispatchMasterData !== null) {
                setDispatchMasterJson(dispatchMasterData);
                setDispatchScheduleType(dispatchMasterData.dispatch_schedule_type_id);
                setDispOrdCreationType(dispatchMasterData.dispatch_schedule_creation_type)
                setDispScheduleNo(dispatchMasterData.dispatch_schedule_no)
                setDispVersion(dispatchMasterData.dispatch_schedule_version);

                setCustomerId(dispatchMasterData.customer_id);
                let custContactData = await FnGetCustomersContactPersonsDetails(dispatchMasterData.customer_id);
                // Set the checked checkboxes first.
                const customerContactPersonsIds = dispatchMasterData.customer_contacts_ids.split(":").map(Number);
                const rearrangedContactsRows = [
                    ...custContactData.filter(contact => customerContactPersonsIds.includes(contact.customer_contact_id)),
                    ...custContactData.filter(contact => !customerContactPersonsIds.includes(contact.customer_contact_id)),
                ];
                setCustContactDetails(rearrangedContactsRows);
                if (dispatchMasterData.dispatch_schedule_creation_type === 'S') {
                    // Get customer order nos
                    resetGlobalQuery();
                    globalQuery.columns = ['DISTINCT(customer_order_no)', 'customer_contacts_ids'];
                    globalQuery.table = "mtv_sales_order_master_trading_summary"
                    globalQuery.conditions.push({ field: "sales_order_status", operator: "NOT IN", values: ['X', 'R', 'P', 'C'] });
                    globalQuery.conditions.push({ field: "customer_id", operator: "=", value: dispatchMasterData.customer_id });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    globalQuery.conditions.push({ field: "sales_order_type_id", operator: "=", value: document.getElementById('cmb_dispatch_schedule_type').value });
                    globalQuery.orderBy = ["sales_order_master_transaction_id desc"];
                    const customer_order_no_list = await comboDataFunc.current.removeCatcheFillCombo(globalQuery);
                    let listItems = customer_order_no_list.map(renderCustomerOrderNoCheckbox);
                    setCustomerOrderNoList(listItems);
                }
                setCustomerState(dispatchMasterData.customer_state_id);
                await comboOnChange('CustomerState')
                setCustomerCity(dispatchMasterData.customer_city_id);

                setConsigneeId(dispatchMasterData.consignee_id);
                setConsigneeState(dispatchMasterData.consignee_state_id);
                await comboOnChange('ConsigneeState')
                setConsigneeCity(dispatchMasterData.consignee_city_id)
                setDispatchDate(dispatchMasterData.dispatch_schedule_date);
                setSupervisor(dispatchMasterData.dispatch_supervisor_id);
                supervisorComboRef.current.value = dispatchMasterData.dispatch_supervisor_id;
                supervisorComboRef.current.label = dispatchMasterData.dispatch_supervisor_name;

                if (keyForViewUpdate === 'view' || isApprove) {
                    setApprovedById(dispatchMasterData.approved_by_id);
                    setApproveDate(dispatchMasterData.approved_date);
                }

                setDispatchNoteStatus(dispatchMasterData.dispatch_note_status);
                setNoteRemark(dispatchMasterData.dispatch_note_remark)
                setothercondition(dispatchMasterData.other_terms_conditions)
                setRemark(dispatchMasterData.remark);
                setIsActive(dispatchMasterData.is_active);
                setGatePassNo(dispatchMasterData.gate_pass_no)
                setGatePassDate(dispatchMasterData.gate_pass_date)
                setVehicaleNo(dispatchMasterData.vehical_no)
                setContainerNo(dispatchMasterData.container_no)
                setSealNo(dispatchMasterData.seal_no)
                setNetWeight(dispatchMasterData.net_weight)
                setDriverName(dispatchMasterData.driver_name)
                setDriverContactNo(dispatchMasterData.driver_contact_no)
                setVehicleReportingTime(dispatchMasterData.vehicle_reporting_time)
                setVehicleLoadingStartedTime(dispatchMasterData.vehicle_loading_started_time)
                setVehicleLoadingFinishTime(dispatchMasterData.vehicle_loading_finish_time)

                if (dispatchMasterData.dispatch_schedule_creation_type === 'S') {
                    resetGlobalQuery();
                    globalQuery.columns.push("DISTINCT (customer_order_no)");
                    globalQuery.table = "mt_dispatch_schedule_details_trading"
                    globalQuery.conditions.push({ field: "dispatch_schedule_no", operator: "=", value: txt_dispatch_schedule_no });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    const customerOrderNos = await comboDataFunc.current.removeCatcheFillCombo(globalQuery);

                    // Loop through each checkbox with the name "customerOrderNoCheckBox"
                    $("input:checkbox[name=customerOrderNoCheckBox]").each(function () {
                        const checkboxValue = $(this).val();
                        const orderNoIndex = customerOrderNos.findIndex((item) => item.customer_order_no === checkboxValue)
                        if (orderNoIndex !== -1) {
                            $(this).prop('checked', true);
                            $(this).prop('disabled', 'disabled');
                        }
                    });
                    setCheckedCustomerOrderNoLength(customerOrderNos.length);
                    await ShowDispatchNoteDetails(dispatchDetailsData);
                    // if (keyForViewUpdate !== 'view' && keyForViewUpdate !== 'appove') {
                    //     await ShowDispatchNoteDetails(dispatchDetailsData);
                    // } else {
                    //     setDispatchDetailsData(dispatchDetailsData)
                    //     checkDispactDetails(dispatchDetailsData, dispatchMasterData.dispatch_schedule_creation_type)
                    // }
                } else {
                    dispatchDetailsData = dispatchDetailsData.map(item => {
                        // Create a new object with the updated key name
                        const newItem = {
                            ...item,
                            product_name: item.product_material_print_name,
                            product_material_id: item.product_material_id,
                            product_id: item.product_material_id,
                            actual_dispatch_quantity: item.actual_dispatch_quantity === 0 && !['view', 'approve'].includes(keyForViewUpdate) ? item.expected_dispatch_quantity : item.actual_dispatch_quantity,
                            actual_dispatch_weight: item.actual_dispatch_weight === 0 && !['view', 'approve'].includes(keyForViewUpdate) ? item.expected_dispatch_weight : item.actual_dispatch_weight,
                        };
                        return newItem;
                    })
                    setDispatchDetailsData(dispatchDetailsData)
                    if (keyForViewUpdate !== 'view') { totalDispatchQuantity(); }
                    sessionStorage.setItem('filteredMaterialData', JSON.stringify(dispatchDetailsData));
                }
                setStoredDispatchDetailsData(dispatchDetailsData);
                // setPackingDetailsList(dispatchPackingDetails);

                dispatchInspectionDtls.forEach(function (inspectionMtrl) {
                    inspectionMtrl.set_no = inspectionMtrl.inspection_production_set_no;
                })
                setInspectionDetailsList(dispatchInspectionDtls);


                if (keyForViewUpdate === 'view') {
                    settxt_total_quantity(dispatchMasterData.total_quantity)
                    settxt_Total_Weight(dispatchMasterData.total_weight)
                    settxt_actual_quantity(dispatchMasterData.actual_quantity);
                    settxt_actual_weight(dispatchMasterData.actual_weight);
                }

                // set customer selected-contact-persons chk boxes.
                customerContactPersonsIds.forEach(function (contactId, index) {
                    $('#selectCustContactPerson_' + customerContactPersonsIds[index]).prop('checked', true);
                });
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }

    function renderCustomerOrderNoCheckbox(item, index) {
        if (index === 0) {
            return (
                <>
                    <li className="item cust_order_no">
                        <span className="checkbox">
                            <input
                                type="checkbox"
                                id="all_cust_order_no_id"
                                value={index}
                                className="erp_radio_button filterCheckBox"
                                onChange={(event) => checkCustomerOrderNo(event.target.value)}
                                optional='optional'
                                {...(keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? { disabled: 'disabled' } : {})}
                            />
                        </span>
                        <span className="item-text">All</span>
                    </li>
                    <li className="item cust_order_no">
                        <span className="checkbox">
                            <input
                                type="checkbox"
                                name="customerOrderNoCheckBox"
                                value={item.customer_order_no}
                                id={`filterCheck-${item.customer_order_no}`}
                                className="erp_radio_button customerOrderNoCheckBox"
                                onChange={(event) => checkCustomerOrderNo(event.target.value)}
                                optional='optional'
                                {...(keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? { disabled: 'disabled' } : {})}
                            />
                        </span>
                        <span className="item-text">{item.customer_order_no}</span>
                    </li>
                </>
            );
        }
        return (
            <li className="item cust_order_no">
                <span className="checkbox">
                    <input
                        type="checkbox"
                        fieldName={item.customer_order_no}
                        name="customerOrderNoCheckBox"
                        value={item.customer_order_no}
                        id={`filterCheck-${item.customer_order_no}`}
                        className="erp_radio_button customerOrderNoCheckBox"
                        onChange={(event) => checkCustomerOrderNo(event.target.value)}
                        {...(keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? { disabled: 'disabled' } : {})}
                    />
                </span>
                <span className="item-text">{item.customer_order_no}</span>
            </li>
        );
    }

    const comboOnChange = async (key) => {
        switch (key) {
            case 'dispatchScheduleType':
                let dispatchScheduleType = parseInt($('#cmb_dispatch_schedule_type').val());
                setDispatchScheduleType(dispatchScheduleType)
                if (dispatchScheduleType !== 0 && !isNaN(dispatchScheduleType)) {
                    $('#error_cmb_dispatch_schedule_type').hide();
                    await generateDispatchScheduleNo();
                    let dispatchType = document.querySelector('input[name="rb_dispatch_order_creation_type"]:checked').value;
                    if (dispatchType === 'S') {
                        // Get customer order nos
                        if (cmb_customer_id !== "" && cmb_customer_id !== "0") {
                            resetGlobalQuery();
                            globalQuery.columns.push("DISTINCT(customer_order_no)");
                            globalQuery.columns.push("customer_contacts_ids");
                            globalQuery.table = "mtv_sales_order_master_trading_summary"
                            globalQuery.conditions.push({ field: "sales_order_status", operator: "NOT IN", values: ['X', 'R', 'P'] });
                            globalQuery.conditions.push({ field: "customer_id", operator: "=", value: document.getElementById("cmb_customer_id").value });
                            globalQuery.conditions.push({ field: "sales_order_type_id", operator: "=", value: document.getElementById("cmb_dispatch_schedule_type").value });
                            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                            globalQuery.orderBy = ["sales_order_master_transaction_id desc"];
                            const customer_order_no_list = await comboDataFunc.current.removeCatcheFillCombo(globalQuery);
                            let listItems = customer_order_no_list.map(renderCustomerOrderNoCheckbox);
                            setCustomerOrderNoList(listItems)
                            $('#all_cust_order_no_id').prop('checked', false);
                            $('.customerOrderNoCheckBox').prop('checked', false);

                            if (customer_order_no_list.length <= 0) {
                                $('#error_customer_orders_list').show();
                                $('#error_customer_orders_list').text('No customer orders are available...!');
                            } else {
                                $('#error_customer_orders_list').hide();
                            }

                            $('.customerOrderNoCheckBox').prop('checked', false);
                            setCheckedCustomerOrderNoLength(0);
                            setDispatchDetailsData([]);
                            setProductTypePropertiesData([]);
                            if (!isApprove) {
                                totalDispatchQuantity()
                            }
                        }
                    }
                }
                if (dispatchScheduleType === 0) {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Dispatch Schedule Type')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").css("padding-top", "0px");
                    }, 100)
                }
                break;

            case 'CreationType':
                let dispatchType = document.querySelector('input[name="rb_dispatch_order_creation_type"]:checked').value;
                let selectedCust = parseInt($('#cmb_customer_id').val());
                if (dispatchType === 'S' && selectedCust !== 0 && !isNaN(selectedCust)) {
                    // Get customer order nos
                    resetGlobalQuery();
                    globalQuery.columns.push("DISTINCT(customer_order_no)");
                    globalQuery.columns.push("customer_contacts_ids");
                    globalQuery.table = "mtv_sales_order_master_trading_summary"
                    globalQuery.conditions.push({ field: "sales_order_status", operator: "NOT IN", values: ['X', 'R', 'P'] });
                    globalQuery.conditions.push({ field: "customer_id", operator: "=", value: selectedCust });
                    globalQuery.conditions.push({ field: "sales_order_type_id", operator: "=", value: document.getElementById("cmb_dispatch_schedule_type").value });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    globalQuery.orderBy = ["sales_order_master_transaction_id desc"];
                    const customer_order_no_list = await comboDataFunc.current.removeCatcheFillCombo(globalQuery);
                    let listItems = customer_order_no_list.map(renderCustomerOrderNoCheckbox);
                    setCustomerOrderNoList(listItems)
                    $('#all_cust_order_no_id').prop('checked', false);
                    $('.customerOrderNoCheckBox').prop('checked', false);
                }
                setDispatchDetailsData([]);
                setProductTypePropertiesData([]);
                break;

            case 'Customer':
                let selectedCustomer = parseInt($('#cmb_customer_id').val());
                setCustomerId(selectedCustomer)
                if (selectedCustomer !== 0 && !isNaN(selectedCustomer)) {
                    let dispatchType = document.querySelector('input[name="rb_dispatch_order_creation_type"]:checked').value;
                    if (dispatchType === 'S') {
                        // Get customer order nos
                        resetGlobalQuery();
                        globalQuery.columns.push("DISTINCT(customer_order_no)");
                        globalQuery.columns.push("customer_contacts_ids");
                        globalQuery.table = "mtv_sales_order_master_trading_summary"
                        globalQuery.conditions.push({ field: "sales_order_status", operator: "NOT IN", values: ['X', 'R', 'P'] });
                        globalQuery.conditions.push({ field: "customer_id", operator: "=", value: selectedCustomer });
                        globalQuery.conditions.push({ field: "sales_order_type_id", operator: "=", value: document.getElementById("cmb_dispatch_schedule_type").value });
                        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                        globalQuery.orderBy = ["sales_order_master_transaction_id desc"];
                        const customer_order_no_list = await comboDataFunc.current.removeCatcheFillCombo(globalQuery);
                        let listItems = customer_order_no_list.map(renderCustomerOrderNoCheckbox);
                        setCustomerOrderNoList(listItems)
                        $('#all_cust_order_no_id').prop('checked', false);
                        $('.customerOrderNoCheckBox').prop('checked', false);

                        if (customer_order_no_list.length <= 0) {
                            $('#error_customer_orders_list').show();
                            $('#error_customer_orders_list').text('No customer orders are available...!');
                        } else {
                            $('#error_customer_orders_list').hide();
                        }

                        $('.customerOrderNoCheckBox').prop('checked', false);
                        setCheckedCustomerOrderNoLength(0);
                        setDispatchDetailsData([]);
                        if (!isApprove) {
                            totalDispatchQuantity()
                        }
                    }


                    // Get customer city & state
                    resetGlobalQuery();
                    globalQuery.columns = ["cust_branch_state_id", "cust_branch_city_id"];
                    globalQuery.table = "cmv_customer_summary";
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    globalQuery.conditions.push({ field: "customer_id", operator: "=", value: selectedCustomer });
                    const getCustomerDetailsApiCall = await comboDataFunc.current.fillFiltersCombo(globalQuery);
                    if (getCustomerDetailsApiCall.length > 0) {
                        setCustomerState(getCustomerDetailsApiCall[0].cust_branch_state_id);
                        await comboOnChange('CustomerState');
                        setCustomerCity(getCustomerDetailsApiCall[0].cust_branch_city_id);
                    } else {
                        setCustomerState('');
                        setCustomerCity('');
                        setCustomerCityOpts([]);
                    }

                    await FnGetCustomersContactPersonsDetails(selectedCustomer);
                    $('#error_customer_contact_persons').hide();
                } else {
                    setCustomerState('');
                    setCustomerCity('');
                    setCustomerCityOpts([]);
                    setCustomerOrderNoList([])
                    setDispatchDetailsData([])
                }

                if (selectedCustomer === 0) {
                    const newTab = window.open('/Masters/Customer', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                }
                setDispatchDetailsData([]);
                setProductTypePropertiesData([]);
                break;

            case 'CustomerState':
                let customerState = parseInt($('#cmb_customer_state_id').val());
                setCustomerState(customerState)
                if (customerState !== 0 && !isNaN(customerState)) {
                    const getCustomerCities = await comboDataFunc.current.fillMasterDataWithOperator("cmv_city", "state_id", "=", JSON.stringify(customerState));
                    setCustomerCityOpts(getCustomerCities);
                    if (getCustomerCities.length < 0) { setCustomerCity(''); }
                } else {
                    setCustomerCity('');
                    setCustomerCityOpts([]);
                }
                break;

            case 'CustomerCity':
                let customerCity = parseInt($('#cmb_customer_city_id').val());
                setCustomerCity(customerCity)
                if (customerCity !== 0 && !isNaN(customerCity)) {
                    $('#error_cmb_customer_city_id').hide()
                }
                if (customerCity === 0) {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Customer City')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").css("padding-top", "0px");
                    }, 100)
                }
                break;

            case 'Consignee':
                let selectedConsigneeId = parseInt($('#cmb_consignee_id').val());
                setConsigneeId(selectedConsigneeId)
                if (selectedConsigneeId !== 0 && !isNaN(selectedConsigneeId)) {
                    $('#error_cmb_consignee_id').hide()
                    resetGlobalQuery();
                    globalQuery.columns.push("cust_branch_state_id");
                    globalQuery.columns.push("cust_branch_city_id");
                    globalQuery.table = "cmv_customer_summary"
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    globalQuery.conditions.push({ field: "customer_id", operator: "=", value: selectedConsigneeId });
                    const getConsineeDetailsApiCall = await comboDataFunc.current.fillFiltersCombo(globalQuery);
                    if (getConsineeDetailsApiCall.length > 0) {
                        setConsigneeState(getConsineeDetailsApiCall[0].cust_branch_state_id);
                        await comboOnChange('ConsigneeState');
                        setConsigneeCity(getConsineeDetailsApiCall[0].cust_branch_city_id);
                    } else {
                        setConsigneeState('');
                        setConsigneeCity('');
                        setConsigneeCityOpts([]);
                    }
                } else {
                    setConsigneeState('');
                    setConsigneeCity('');
                    setConsigneeCityOpts([]);
                }

                if (selectedConsigneeId === 0) {
                    const newTab = window.open('/Masters/Customer', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                }
                break;

            case 'ConsigneeState':
                let consigneeState = parseInt($('#cmb_consignee_state_id').val());
                setConsigneeState(consigneeState)
                if (consigneeState !== 0 && !isNaN(consigneeState)) {
                    $('#error_cmb_consignee_state_id').hide()
                    const getConsigneeCitiesApiCall = await comboDataFunc.current.fillMasterDataWithOperator("cmv_city", "state_id", "=", JSON.stringify(consigneeState));
                    setConsigneeCityOpts(getConsigneeCitiesApiCall);
                } else {
                    setConsigneeCityOpts([]);
                    setConsigneeCity('');
                }
                break;

            case 'ConsigneeCity':
                let consigneeCity = parseInt($('#cmb_consignee_city_id').val());
                setConsigneeCity(consigneeCity)
                if (consigneeCity !== 0 && !isNaN(consigneeCity)) {
                    $('#error_cmb_consignee_state_id').hide()
                }
                if (consigneeCity === 0) {
                    sessionStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                    setHeaderName('Consignee City')
                    setShowAddRecModal(true)
                    setTimeout(() => {
                        $(".erp_top_Form").css("padding-top", "0px");
                    }, 100)
                }
                break;

            case 'cmb_supervisor':
                let supervisor = supervisorComboRef.current.value;
                setSupervisor(supervisor)

                if (supervisor !== 0 && !isNaN(supervisor)) {
                    $('#error_cmb_supervisor').hide()
                }
                if (supervisor === 0) {
                    const newTab = window.open('/Masters/Employees', '_blank');
                    if (newTab) {
                        newTab.focus();
                    }
                }
                break;
            default:
                break;
        }
    }

    // For update the combobox data after add new record.
    const FnRefreshComboData = async (key) => {
        if (keyForViewUpdate === 'view') {
            return false;
        }
        switch (key) {
            case 'UpdateConsignees':
                resetGlobalQuery();
                globalQuery.columns = ['field_id', 'field_name', 'is_sez', "cust_branch_gst_no", 'cust_branch_type'];
                globalQuery.table = "cmv_customer_summary"
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                globalQuery.conditions.push({ field: "cust_branch_type", operator: "=", value: 'Consignee' });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const getUpdatedConsigneesData = await comboDataFunc.current.removeCatcheFillCombo(globalQuery)
                setConsigneeList(getUpdatedConsigneesData);
                break;

            case 'UpdateCustomers':
                resetGlobalQuery();
                globalQuery.columns = ["field_id", "field_name", "is_sez", "cust_branch_gst_no", 'cust_branch_type']
                globalQuery.table = "cmv_customer_summary"
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                const getUpdatedCustomerData = await comboDataFunc.current.removeCatcheFillCombo(globalQuery)
                setCustomerList(getUpdatedCustomerData);
                break;

            case 'UpdateEmployees':
                resetGlobalQuery();
                globalQuery.columns = ['field_id', 'field_name', 'reporting_to', 'email_id1']
                globalQuery.table = "cmv_employee"
                // globalQuery.conditions.push({ field: "department_id", operator: "=", value: '2' });
                globalQuery.conditions.push({ field: "employee_type", operator: "!=", value: "Worker" });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                // const supervisorsOpts = await comboDataFunc.current.removeCatcheFillCombo(globalQuery);
                // setSupervisorList(supervisorsOpts)
                comboDataFunc.current.fillFiltersCombo(globalQuery)
                    .then(supervisorsOpts => {
                        const supervisorList = [
                            { value: '', label: 'Select', field_id: '', field_name: '' },
                            { value: '0', label: 'Add New Record+', field_id: '', field_name: '' },
                            ...supervisorsOpts.map((supervisor) => ({ ...supervisor, value: supervisor.field_id, label: supervisor.field_name })),
                        ];

                        setSupervisorList(supervisorList);

                    });
                break;

            default:
                break;
        }

    }

    const displayRecordComponent = () => {
        switch (modalHeaderName) {
            case 'Customer City':
                return <FrmCity btn_disabled={true} />;
            case 'Consignee City':
                return <FrmCity btn_disabled={true} />;
            case 'Dispatch Schedule Type':
                return <FrmMProductTypeEntry btn_disabled={true} />;
            default:
                return null;
        }
    }

    const FnGetCustomersContactPersonsDetailsOld = async (selectedCustomer, dispatchType) => {
        try {
            if (selectedCustomer !== 0 && !isNaN(selectedCustomer)) {
                resetGlobalQuery();

                globalQuery.columns = ["customer_id", "customer_branch_name", "cust_branch_id", "customer_contact_id",
                    "cust_contact_person", "cust_contact_no", "cust_alternate_contact", "cust_email_id", "cust_alternate_EmailId"];

                globalQuery.table = "cmv_customer_contacts"
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "customer_id", operator: "=", value: parseInt(selectedCustomer) });
                const getContactDtlsApiCall = await comboDataFunc.current.fillFiltersCombo(globalQuery);

                if (getContactDtlsApiCall.length !== 0) {
                    let column = [];
                    let columnHeads = Object.keys(getContactDtlsApiCall[0]);
                    for (let colKey = 0; colKey < columnHeads.length; colKey++) {
                        if (colKey === 0) {
                            column.push({
                                Headers: <div className="col-1"><label>Action</label></div>,
                                accessor: "Action",
                                Cell: row => (
                                    <div className="text-center">
                                        <input type='checkbox' className="option selectCustContactPerson" name="selectCustContactPerson" id={`selectCustContactPerson_${row.original.customer_contact_id}`} contact_personId={row.original.customer_contact_id} value={row.original.customer_contact_id} disabled />
                                    </div>
                                ),
                            });
                        }
                        if (!columnHeads[colKey].includes('customer_id') && !columnHeads[colKey].includes('customer_contact_id') && !columnHeads[colKey].includes('cust_branch_id')) {
                            column.push({ Headers: columnHeads[colKey], accessor: columnHeads[colKey] });
                        }
                    }
                    setCustContactDetailsCols(column);
                    setCustContactDetails(getContactDtlsApiCall);

                    if (dispatchType === 'S') {
                        resetGlobalQuery();
                        globalQuery.columns.push("customer_contacts_ids");
                        globalQuery.table = "mtv_sales_order_master_trading_summary"
                        globalQuery.conditions.push({ field: "customer_id", operator: "=", value: selectedCustomer });
                        const existingContactDetails = await comboDataFunc.current.fillFiltersCombo(globalQuery);
                        if (existingContactDetails.length > 0) {
                            for (const details of existingContactDetails) {
                                const element = details.customer_contacts_ids;

                                if (element !== '') {
                                    const contactIdsArray = element.split(":");

                                    for (const contactId of contactIdsArray) {
                                        const selector = `#selectCustContactPerson_${contactId}`;
                                        $(selector).prop('checked', true);
                                    }
                                }
                            }

                        }
                    }

                } else {
                    setCustContactDetailsCols([]);
                    setCustContactDetails([]);
                }
            }
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }
    const FnGetCustomersContactPersonsDetails = async (customerId) => {
        $('.selectCustContactPerson').prop('checked', false);
        $('#selectAllCustContact').prop('checked', false);
        try {
            if (customerId !== null && customerId !== undefined && customerId !== '' && customerId !== NaN) {
                resetGlobalQuery();
                globalQuery.columns = ["customer_id", "customer_branch_name", "cust_branch_id", "customer_contact_id", "cust_contact_person", "cust_contact_no", "cust_alternate_contact", "cust_email_id", "cust_alternate_EmailId"]
                globalQuery.table = "cmv_customer_contacts"
                globalQuery.conditions.push({ field: "company_id", operator: "=", value: parseInt(COMPANY_ID) });
                globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                globalQuery.conditions.push({ field: "customer_id", operator: "=", value: parseInt(customerId) });
                const getContactDtlsApiCall = await comboDataFunc.current.removeCatcheFillCombo(globalQuery);
                setCustContactDetails(getContactDtlsApiCall);

                // if (rb_dispatch_order_creation_type === 'S') {
                //     resetGlobalQuery();
                //     globalQuery.columns.push("customer_contacts_ids");
                //     globalQuery.table = "mtv_sales_order_master_trading_summary"
                //     globalQuery.conditions.push({ field: "customer_id", operator: "=", value: customerId });
                //     const customerContDetails = await comboDataFunc.current.removeCatcheFillCombo(globalQuery);

                //     if (customerContDetails.length > 0) {
                //         const customerContactPersonsIds = customerContDetails[0].customer_contacts_ids.split(":").map(Number);
                //         const rearrangedContactsRows = [
                //             ...getContactDtlsApiCall.filter(contact => customerContactPersonsIds.includes(contact.customer_contact_id)),
                //             ...getContactDtlsApiCall.filter(contact => !customerContactPersonsIds.includes(contact.customer_contact_id)),
                //         ];
                //         setCustContactDetails(rearrangedContactsRows);
                //         setTimeout(() => {
                //             customerContactPersonsIds.forEach(function (contactId, index) {
                //                 $('#selectCustContactPerson_' + customerContactPersonsIds[index]).prop('checked', true);
                //             });
                //         }, 600);
                //     }
                // } else {
                //     setCustContactDetails(getContactDtlsApiCall);
                // }

                return getContactDtlsApiCall;
            } else {
                setCustContactDetails([]);
                return [];
            }
        } catch (error) {
            console.log("error: ", error)
            // navigate('/Error')
        }
        // Onchange event listener for the customer contact Selection
        $('.selectCustContactPerson').on('change', function () {
            FnCheckBoxesSelection('PartiallyCustContactSelection');
        });
    }

    const checkDispactDetails = (dispatchDetailsData, creation_type) => {
        // Partially select dispatch detals
        const checkboxes = document.querySelectorAll('.checkdispatchnoteDetails');
        const transactionIdsSet = new Set(dispatchDetailsData.map(item => creation_type === 'S' ? item.sales_order_details_transaction_id : item.sales_order_details_transaction_id));

        checkboxes.forEach(checkbox => {
            const transactionId = checkbox.value;
            if (transactionIdsSet.has(parseInt(transactionId))) {
                checkbox.checked = true;
            }
        });

        //Check Partiallly selected all or not if selected then check the check all radio of dispatch details
        partiallySelectedDispatch();
        totalDispatchQuantity();
    }

    const selectAllCustomerOrderNo = async (key) => {
        // let savedDtlsAgainstCustOrd = [...new Set(dispatchDetailsData.filter(item => item.dispatch_schedule_details_transaction_id !== 0).map(item => item.customer_order_no))];
        const fliterCheckbox = document.getElementsByName('customerOrderNoCheckBox');
        if (key === 'check') {
            for (let chkAllCheckbox of fliterCheckbox) {
                document.getElementById(`${chkAllCheckbox.id}`).checked = true;
            }
        } else {
            for (let unchkAllCheckbox of fliterCheckbox) {
                if (!unchkAllCheckbox.hasAttribute('disabled')) {
                    document.getElementById(`${unchkAllCheckbox.id}`).checked = false;
                }
            }
        }
    }

    const generateDispatchScheduleNo = async (product_typeShortName) => {
        const ptShortName = product_typeShortName === undefined ? $('#cmb_dispatch_schedule_type option:selected').attr('shortname') : product_typeShortName;
        const productTypeId = parseInt($('#cmb_dispatch_schedule_type').val());
        const autoNoApiCall = await generateAutoNoAPiCall.current.generateTAutoNo(
            "mt_dispatch_schedule_master_trading",
            "dispatch_schedule_no",
            ptShortName,
            "DN",
            "5", "dispatch_schedule_type_id", productTypeId
        );

        setDispScheduleNo(autoNoApiCall);
        return autoNoApiCall;
    };


    const updateDispatchDetailsTblRow = async (rowData, event) => {
        let clickedColName = event.target.getAttribute('Headers');
        let enteredValue = parseInt(event.target.value);
        if (isNaN(enteredValue)) {
            enteredValue = 0;
        }

        switch (clickedColName) {
            case 'expected_dispatch_quantity':
                if (rb_dispatch_order_creation_type === 'S') {
                    // if (enteredValue > (rowData.material_quantity - rowData.dispatched_quantity - rowData.prev_disp_note_qty)) {
                    if (enteredValue > (rowData.material_quantity - rowData.prev_disp_note_qty)) {
                        let actualvalidation = document.querySelector(`#${event.target.id}`);
                        // actualvalidation.parentElement.dataset.tip = `Cannot be greater than ${rowData.material_quantity - rowData.dispatched_quantity - rowData.prev_disp_note_qty}...!`;
                        // actualvalidation.parentElement.dataset.tip = `Cannot be greater than ${rowData.material_quantity - rowData.prev_disp_note_qty}...!`;
                        actualvalidation.parentElement.dataset.tip = `Cannot be greater than order quantity or stock quantity...!`;
                        return false;
                    } else {
                        rowData[clickedColName] = enteredValue;
                        rowData.expected_dispatch_weight = validateNumberDateInput.current.decimalNumber(JSON.stringify(enteredValue * rowData.product_material_std_weight), 4);
                        delete event.target.parentElement.dataset.tip;
                    }
                    $(`#checkdispatchnoteDetails_${rowData.sales_order_details_transaction_id}`).prop('checked', true);

                } else {
                    if (enteredValue > rowData.stock_quantity) {
                        let actualvalidation = document.querySelector(`#${event.target.id}`);
                        actualvalidation.parentElement.dataset.tip = `Cannot be greater than stock quantity...!`;
                        return false;
                    } else {
                        rowData[clickedColName] = enteredValue;
                        rowData.expected_dispatch_weight = validateNumberDateInput.current.decimalNumber(JSON.stringify(enteredValue * rowData.product_material_std_weight
                        ), 4);
                        delete event.target.parentElement.dataset.tip;
                    }
                }
                break;

            case 'expected_dispatch_weight':
                rowData[clickedColName] = validateNumberDateInput.current.decimalNumber(event.target.value, 4);
                break;

            case 'actual_dispatch_quantity':
                if (enteredValue > rowData.expected_dispatch_quantity) {
                    let actualvalidation = document.querySelector(`#${event.target.id}`);
                    actualvalidation.parentElement.dataset.tip = `Cannot be greater than ${rowData.expected_dispatch_quantity}...!`;
                    return false;
                } else {
                    rowData[clickedColName] = enteredValue;
                    rowData.actual_dispatch_weight = validateNumberDateInput.current.decimalNumber(JSON.stringify(enteredValue * rowData.product_material_std_weight), 4);
                    delete event.target.parentElement.dataset.tip;

                    if (rb_dispatch_order_creation_type === 'S') {
                        let updatedInspectionDtls = [];
                        let currentMatInspectionDtls = inspectionDetailsList.filter(item => parseInt(item.sales_order_details_transaction_id) === parseInt(rowData.sales_order_details_transaction_id));
                        let otherInspectionDtls = inspectionDetailsList.filter(item => parseInt(item.sales_order_details_transaction_id) !== parseInt(rowData.sales_order_details_transaction_id));

                        if (currentMatInspectionDtls.length > 0) {
                            let qtyToDisp = enteredValue;
                            currentMatInspectionDtls.forEach(item => {
                                if (item.inspection_mtr >= qtyToDisp) {
                                    item.dispatch_quantity = qtyToDisp;
                                    qtyToDisp = 0;
                                } else {
                                    item.dispatch_quantity = item.inspection_mtr;
                                    qtyToDisp -= item.inspection_mtr;
                                }
                            });
                        }
                        // updatedInspectionDtls = [...otherInspectionDtls, ...currentMatInspectionDtls].sort((a, b) => a.product_material_id - b.product_material_id);
                        updatedInspectionDtls = [...otherInspectionDtls, ...currentMatInspectionDtls]
                        setInspectionDetailsList(updatedInspectionDtls)
                    }

                }
                break;

            case 'actual_dispatch_weight':
                rowData[clickedColName] = validateNumberDateInput.current.decimalNumber(event.target.value, 4);
                break;

            case 'batch_no':
                rowData[clickedColName] = event.target.value;
                break;

            case 'dispatch_schedule_remark':
                rowData[clickedColName] = event.target.value;
                break;

            default:
                // Handle default case if needed
                break;
        }

        // if (isApprove) {
        //     if (clickedColName === 'dispatch_schedule_item_status') {
        //         rowData[clickedColName] = event.target.value;
        //     } else if (clickedColName === 'dispatch_schedule_remark') {
        //         rowData[clickedColName] = event.target.value;
        //     }
        // }
        const detailData = [...dispatchDetailsData]
        const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowindex'))
        detailData[arrayIndex] = rowData
        setDispatchDetailsData(detailData);
        if (!isApprove) {
            totalDispatchQuantity()
        }
    }

    const getMaterialValues = (material_id) => {
        const expectedQty = parseInt($(`#expected_dispatch_quantity_${material_id}`).val());
        const expectedWt = parseFloat($(`#expected_dispatch_weight_${material_id}`).val());
        const actualQtyValue = parseInt($(`#actual_dispatch_quantity_${material_id}`).val());
        const actualWtValue = parseFloat($(`#actual_dispatch_weight_${material_id}`).val());
        return { expectedQty, expectedWt, actualQtyValue, actualWtValue };
    };

    const totalDispatchQuantity = () => {
        if (keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve') {
            let dispatch_qty = 0;
            let dispatch_wt = 0;
            let actualdispatch_qty = 0;
            let actualdispatch_wt = 0;

            const processMaterial = (material_id) => {
                const { expectedQty, expectedWt, actualQtyValue, actualWtValue } = getMaterialValues(material_id);

                if (actualQtyValue && !isNaN(actualQtyValue)) {
                    actualdispatch_qty += actualQtyValue;
                }

                if (actualWtValue && !isNaN(actualWtValue)) {
                    actualdispatch_wt += actualWtValue;
                }

                if (expectedQty && !isNaN(expectedQty)) {
                    dispatch_qty += expectedQty;
                }
                dispatch_wt += expectedWt;
            };

            if (document.querySelector('input[name="rb_dispatch_order_creation_type"]:checked').value === 'S') {
                $("input:checkbox[name=checkDispatch]:checked").each(function () {
                    // const material_id = parseInt($(this).val());
                    const material_id = $(this).val();
                    processMaterial(material_id);
                });
            } else {
                const table = document.getElementById('dispatchDetailsTbl');
                if (table) {
                    const tbody = table.querySelector('tbody');
                    const tbodyRows = tbody.getElementsByTagName('tr');
                    for (let i = 0; i < tbodyRows.length; i++) {
                        const row = tbodyRows[i];
                        // const material_id = parseInt(row.getAttribute('materialIds'));
                        const material_id = row.getAttribute('materialIds');
                        processMaterial(material_id);
                    }
                }
            }

            settxt_total_quantity(dispatch_qty);
            settxt_Total_Weight(validateNumberDateInput.current.decimalNumber(JSON.stringify(dispatch_wt), 4));
            settxt_actual_quantity(actualdispatch_qty);
            settxt_actual_weight(validateNumberDateInput.current.decimalNumber(JSON.stringify(actualdispatch_wt), 4));
        }
    };



    const FnGetCustomerContactPersonIds = () => {
        let customerContactPersonsIds = '';
        const checkboxes = $('.selectCustContactPerson:checked');
        checkboxes.each(function () {
            customerContactPersonsIds += $(this).val() + ":";
        });
        return customerContactPersonsIds.replace(/:$/, '');
    }

    const moveToListing = () => {
        sessionStorage.removeItem('dispatch_note_no')
        sessionStorage.removeItem('bomfilteredData')
        let Path = compType === 'Register' ? '/Transactions/TDispatchNote/TFabricDispatchNoteListing/reg' : `/Transactions/TDispatchNote/TFabricDispatchNoteListing`;
        navigate(Path);
    }


    async function ShowDispatchNoteDetails(storedDispatchDetailsData) {
        try {
            setIsLoading(true);
            // await FnCheckCustomerOrderNoApproval();

            let json = { 'cust_order_nos': [] }
            $("input:checkbox[name=customerOrderNoCheckBox]:checked").each(function () {
                json.cust_order_nos.push($(this).val())
            })
            if (json.cust_order_nos.length > 0) {
                const formData = new FormData();
                formData.append('customerOrderNos', JSON.stringify(json))
                const requestOptions = {
                    method: 'POST',
                    body: formData
                };
                let apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtDispatchScheduleDetails/FnShowDispatchScheduleDetailsTradingRecords/${dispatch_schedule_version}/${COMPANY_ID}?dispatch_schedule_no=${dispatch_schedule_master_transaction_id === 0 ? "0" : txt_dispatch_schedule_no}`, requestOptions)
                const response = await apicall.json()
                if (response.data.length !== 0) {
                    await modifyData(response, json.cust_order_nos, storedDispatchDetailsData);
                    if (response.productParametersData.length !== 0) {
                        if (keyForViewUpdate != "view") {
                            await fnGetProductProperties(response.productParametersData)
                        }
                    }
                } else {
                    setDispatchDetailsData([]);
                }

                if (keyForViewUpdate === '') {
                    // Load the customer's contacts.
                    resetGlobalQuery();
                    // SELECT customer_contacts_ids  from mtv_sales_order_master_trading_summary msomts WHERE customer_order_no = 'CustomerOrd-0057' and is_delete = 0;
                    globalQuery.columns = ['customer_contacts_ids'];
                    globalQuery.table = "mtv_sales_order_master_trading_summary"
                    // globalQuery.conditions.push({ field: "customer_order_no", operator: "IN", values: json.cust_order_nos[0] });
                    globalQuery.conditions.push({ field: "customer_order_no", operator: "=", value: json.cust_order_nos[0] });
                    globalQuery.conditions.push({ field: "customer_id", operator: "=", value: cmb_customer_id });
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    globalQuery.orderBy = ["sales_order_master_transaction_id desc"];
                    const getCustContactPersons = await comboDataFunc.current.removeCatcheFillCombo(globalQuery);
                    if (getCustContactPersons.length > 0) {
                        const customerContactPersonsIds = getCustContactPersons[0].customer_contacts_ids.split(":").map(Number);
                        // Rearrange the rows.
                        if (custContactDetails.length > 0) {
                            const rearrangedContactsRows = [
                                ...custContactDetails.filter(contact => customerContactPersonsIds.includes(contact.customer_contact_id)),
                                ...custContactDetails.filter(contact => !customerContactPersonsIds.includes(contact.customer_contact_id)),
                            ];
                            setCustContactDetails(rearrangedContactsRows);
                        }
                        customerContactPersonsIds.forEach(function (contactId, index) {
                            $('#selectCustContactPerson_' + customerContactPersonsIds[index]).prop('checked', true);
                        });
                    }
                }
            } else {
                setDispatchDetailsData([]);
                setProductTypePropertiesData([]);
                setInspectionDetailsList([]);
            }
            setIsLoading(false);
        } catch (error) {
            console.log('Error in loading so data: ', error)
            navigate('/Error')
        }
    }

    async function modifyData(response, cust_order_nos, storedDispatchDetailsData) {

        let currentDate = new Date();
        let expectedMaterialScheduleDate;
        let availableStockDetails = await FnUpdateStockAvailability(response.data, false);
        let groupedData = []; // Define groupedData in an accessible scope

        // Update the old-dispatch-note generated qty.
        let custOrdNos = [];
        $("input:checkbox[name=customerOrderNoCheckBox]:checked").each(function () {
            custOrdNos.push($(this).val())
        })

        resetGlobalQuery();
        globalQuery.columns = ['product_material_id', 'sales_order_details_transaction_id', 'actual_dispatch_quantity', 'actual_dispatch_weight', 'expected_dispatch_quantity', 'expected_dispatch_weight'];
        globalQuery.table = "mt_dispatch_schedule_details_trading"
        globalQuery.conditions.push({ field: "customer_order_no", operator: "IN", values: custOrdNos });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
        const oldDispNoteData = await comboDataFunc.current.removeCatcheFillCombo(globalQuery);
        if (oldDispNoteData.length > 0) {
            groupedData = Array.from(oldDispNoteData.reduce((map, material) => {
                const { sales_order_details_transaction_id, actual_dispatch_quantity, actual_dispatch_weight, expected_dispatch_quantity, expected_dispatch_weight, product_material_id } = material;
                const existing = map.get(sales_order_details_transaction_id);
                if (existing) {
                    existing.expected_dispatch_quantity += expected_dispatch_quantity;
                    existing.expected_dispatch_weight += expected_dispatch_weight;
                    existing.prev_sum_actual_disp_qty += actual_dispatch_quantity;
                    existing.prev_sum_actual_disp_wt += actual_dispatch_weight;
                } else {
                    map.set(sales_order_details_transaction_id, { sales_order_details_transaction_id, prev_sum_actual_disp_qty: actual_dispatch_quantity, prev_sum_actual_disp_wt: actual_dispatch_weight, expected_dispatch_quantity, expected_dispatch_weight, product_material_id });
                }
                return map;
            }, new Map()).values());
        }

        if (dispatch_schedule_master_transaction_id === 0) {
            setDispatchDetailsData((prevArray) => [
                ...prevArray.filter(item => cust_order_nos.includes(item.customer_order_no)),
                ...response.data
                    .filter(item => cust_order_nos.includes(item.customer_order_no) && !prevArray.some(prevItem => prevItem.customer_order_no === item.customer_order_no))
                    .map((item) => {
                        // Set the available stock Qty, Wt.
                        let currentMaterial = availableStockDetails.find(material => material.product_rm_id === item.product_material_id);
                        item.stock_quantity = currentMaterial?.closing_balance_quantity || 0;
                        item.stock_weight = currentMaterial?.closing_balance_weight || 0;

                        // Set the previous dispatch note generated qty.
                        let oldDispNTData = groupedData.find(material => material.sales_order_details_transaction_id === item.sales_order_details_transaction_id);
                        if (oldDispNTData) {
                            let dispQty = validateNumberDateInput.current.decimalNumber(JSON.stringify(oldDispNTData.prev_sum_actual_disp_qty), 4) || 0
                            let dispWt = validateNumberDateInput.current.decimalNumber(JSON.stringify(oldDispNTData.prev_sum_actual_disp_wt), 4) || 0
                            item.prev_disp_note_qty = parseFloat(dispQty);
                            item.prev_disp_note_wt = parseFloat(dispWt);
                        } else {
                            item.prev_disp_note_qty = 0
                            item.prev_disp_note_wt = 0
                        }

                        //  // For deduct the previous dispatch-challan-quantity deduction.
                        // item.dispatched_quantity = item.previous_dispatch_quantity
                        // item.dispatched_weight = item.previous_dispatch_weight
                        // const expectedDispatchQuantity = item.material_quantity - item.previous_dispatch_quantity - item.prev_disp_note_qty;    
                        // const expectedDispatchWt = item.material_weight - item.previous_dispatch_weight - item.prev_disp_note_wt;
                        let expQty = validateNumberDateInput.current.decimalNumber(JSON.stringify(item.material_quantity - item.prev_disp_note_qty), 4) || 0
                        let expWt = validateNumberDateInput.current.decimalNumber(JSON.stringify(item.material_weight - item.prev_disp_note_wt), 4) || 0
                        const expectedDispatchQuantity = parseFloat(expQty);
                        const expectedDispatchWt = parseFloat(expWt);
                        if (expectedDispatchQuantity > parseFloat(item.stock_quantity)) {
                            expQty = parseFloat(item.stock_quantity);
                            expWt = validateNumberDateInput.current.decimalNumber(JSON.stringify(expQty - item.product_material_std_weight), 4) || 0
                        }

                        if (expectedDispatchQuantity > 0) { // Add this condition
                            expectedMaterialScheduleDate = new Date(item.material_schedule_date);  // expected date.
                            let delayedDays = Math.floor((currentDate - expectedMaterialScheduleDate) / (1000 * 60 * 60 * 24));
                            return {
                                ...item,
                                dispatch_schedule_details_transaction_id: 0,
                                // expected_dispatch_quantity: expectedDispatchQuantity,
                                // expected_dispatch_weight: validateNumberDateInput.current.decimalNumber(JSON.stringify(expectedDispatchWt), 4) || 0,
                                expected_dispatch_quantity: expQty,
                                expected_dispatch_weight: validateNumberDateInput.current.decimalNumber(JSON.stringify(expWt), 4) || 0,
                                dispatch_schedule_item_status: 'P',
                                product_material_std_weight: item.product_material_std_weight,
                                expected_schedule_date: item.material_schedule_date,
                                delayed_days: delayedDays,
                                actual_dispatch_quantity: 0,
                                actual_dispatch_weight: 0,
                                dispatch_return_quantity: 0,
                                dispatch_return_weight: 0,
                                pending_quantity: 0,
                                pending_weight: 0,
                                invoice_quantity: 0,
                                invoice_weight: 0,
                                lot_no: '',
                                count_no: '',
                                set_no: '',
                                roll_no: '',
                            };
                        } else {
                            return null; // Don't add this item to the array
                        }
                    })
                    .filter(Boolean) // Remove null entries from the array
            ]);
        } else {
            setDispatchDetailsData((prevArray) => [
                ...prevArray.filter(item => cust_order_nos.includes(item.customer_order_no)),
                ...response.data.filter(item =>
                    cust_order_nos.includes(item.customer_order_no) &&
                    !prevArray.some(prevItem => prevItem.customer_order_no === item.customer_order_no)
                ).map(item => {
                    expectedMaterialScheduleDate = new Date(item.material_schedule_date);  // expected date.
                    let delayedDays = Math.floor((currentDate - expectedMaterialScheduleDate) / (1000 * 60 * 60 * 24));

                    const newItem = { ...item, };
                    // Check if the condition is met before applying calculations
                    let currentMaterial = availableStockDetails.find(material => material.product_rm_id === item.product_material_id);
                    newItem.stock_quantity = currentMaterial?.closing_balance_quantity || 0;
                    newItem.stock_weight = currentMaterial?.closing_balance_weight || 0;

                    // Set the previous dispatch note generated qty.
                    let oldDispNTData = groupedData.find(material => material.sales_order_details_transaction_id === item.sales_order_details_transaction_id);
                    if (oldDispNTData) {
                        let dispQty = validateNumberDateInput.current.decimalNumber(JSON.stringify(oldDispNTData.prev_sum_actual_disp_qty), 4) || 0;
                        let dispWt = validateNumberDateInput.current.decimalNumber(JSON.stringify(oldDispNTData.prev_sum_actual_disp_wt), 4) || 0;
                        newItem.prev_disp_note_qty = parseFloat(dispQty);
                        newItem.prev_disp_note_wt = parseFloat(dispWt);
                    } else {
                        newItem.prev_disp_note_qty = 0
                        newItem.prev_disp_note_wt = 0
                    }

                    // Check if the condition is met before applying calculations
                    if (!storedDispatchDetailsData.some(storedItem => storedItem.sales_order_details_transaction_id === item.sales_order_details_transaction_id)) {
                        newItem.dispatch_schedule_details_transaction_id = 0;
                        let expQty = validateNumberDateInput.current.decimalNumber(JSON.stringify(item.material_quantity - newItem.prev_disp_note_qty), 4) || 0;
                        let expWt = validateNumberDateInput.current.decimalNumber(JSON.stringify(item.material_weight - newItem.prev_disp_note_wt), 4) || 0;
                        // Means there is not available quantity to make the dispatch note.
                        // if (parseFloat(expQty) <= 0) {
                        //     return null;
                        // }
                        // Stock validation
                        if (parseFloat(expQty) > parseFloat(newItem.stock_quantity)) {
                            expQty = parseFloat(newItem.stock_quantity);
                            expWt = validateNumberDateInput.current.decimalNumber(JSON.stringify(expQty - newItem.product_material_std_weight), 4) || 0
                        }

                        //  // For deduct the previous dispatch-challan-quantity deduction.
                        // newItem.dispatched_quantity : item.previous_dispatch_quantity,
                        // newItem.dispatched_weight : item.previous_dispatch_weight,
                        // newItem.expected_dispatch_quantity = item.material_quantity - item.previous_dispatch_quantity;
                        // newItem.expected_dispatch_weight = item.material_weight - item.previous_dispatch_weight;
                        newItem.expected_dispatch_quantity = parseFloat(expQty);
                        newItem.expected_dispatch_weight = parseFloat(expWt);
                        newItem.expected_schedule_date = item.material_schedule_date;
                        newItem.delayed_days = delayedDays;
                        newItem.actual_dispatch_quantity = 0;
                        newItem.actual_dispatch_weight = 0;
                        newItem.dispatch_return_quantity = 0;
                        newItem.dispatch_return_weight = 0;
                        newItem.pending_quantity = 0;
                        newItem.pending_weight = 0;
                        newItem.invoice_quantity = 0;
                        newItem.invoice_weight = 0;
                        newItem.lot_no = '';
                        newItem.count_no = '';
                        newItem.set_no = '';
                        newItem.roll_no = '';
                    } else {
                        let storedItem = storedDispatchDetailsData.find(storedItem => storedItem.sales_order_details_transaction_id === item.sales_order_details_transaction_id)
                        newItem.dispatch_schedule_details_transaction_id = storedItem.dispatch_schedule_details_transaction_id;
                        newItem.dispatch_schedule_item_status = storedItem.dispatch_schedule_item_status;
                        newItem.dispatch_schedule_remark = storedItem.dispatch_schedule_remark;
                        newItem.expected_dispatch_quantity = storedItem.expected_dispatch_quantity;
                        newItem.expected_dispatch_weight = storedItem.expected_dispatch_weight;
                        newItem.actual_dispatch_quantity = storedItem.actual_dispatch_quantity === 0 && !['view', 'approve'].includes(keyForViewUpdate) ? storedItem.expected_dispatch_quantity : storedItem.actual_dispatch_quantity;
                        newItem.actual_dispatch_weight = storedItem.actual_dispatch_weight === 0 && !['view', 'approve'].includes(keyForViewUpdate) ? storedItem.expected_dispatch_weight : storedItem.actual_dispatch_weight;
                        newItem.expected_schedule_date = item.material_schedule_date;
                        newItem.delayed_days = delayedDays
                        newItem.batch_no = storedItem.batch_no

                        newItem.dispatch_return_quantity = storedItem.dispatch_return_quantity;
                        newItem.dispatch_return_weight = storedItem.dispatch_return_weight;
                        newItem.pending_quantity = storedItem.pending_quantity;
                        newItem.pending_weight = storedItem.pending_weight;
                        newItem.invoice_quantity = storedItem.invoice_quantity;
                        newItem.invoice_weight = storedItem.invoice_weight;
                        // From prev_disp_note_qty remove the current material actural qty.
                        // newItem.prev_disp_note_qty = oldDispNTData.prev_sum_actual_disp_qty - newItem.actual_dispatch_quantity;
                        // newItem.prev_disp_note_wt = oldDispNTData.prev_sum_actual_disp_wt - newItem.actual_dispatch_weight;
                        newItem.lot_no = storedItem.lot_no;
                        newItem.count_no = storedItem.count_no;
                        newItem.set_no = storedItem.set_no;
                        newItem.roll_no = storedItem.roll_no;
                    }
                    return newItem;
                })
                    .filter(Boolean) // Remove null entries from the array
            ]);
        }
        try {
            checkDispactDetails(storedDispatchDetailsData, rb_dispatch_order_creation_type)
            if (!['view', 'approve'].includes(keyForViewUpdate)) {
                totalDispatchQuantity()
            }
        } catch (error) {
            console.log('Error: ', error)
        }
    }

    const FnUpdateStockAvailability = async (materialDetailsData, setInTable) => {
        let uniqueMaterialIds = [...new Set(materialDetailsData.map(material => material.product_material_id))];

        // Get the available stock qty.
        resetGlobalQuery();
        // globalQuery.columns = ["product_rm_id", "closing_balance_quantity", 'closing_balance_weight']
        globalQuery.columns = [
            "product_rm_id",
            "SUM(closing_balance_quantity) AS closing_balance_quantity",
            "SUM(closing_balance_weight) AS closing_balance_weight"
        ];
        globalQuery.table = "sm_product_rm_stock_details"
        globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
        globalQuery.conditions.push({ field: "day_closed", operator: "=", value: '0' });
        globalQuery.conditions.push({ field: "product_rm_id", operator: "IN", values: [...new Set(uniqueMaterialIds)] });
        globalQuery.groupBy = ['product_rm_id'];
        let getAvlStockQty = await comboDataFunc.current.removeCatcheFillCombo(globalQuery);
        // get the available stock-qty and set in details.
        if (setInTable) {
            let materialDetailsUpdatedData = materialDetailsData.map(material => {
                const stock = getAvlStockQty.find(stock => stock.product_rm_id === material.product_material_id);
                return stock ? { ...material, available_stock_qty: stock.closing_balance_quantity } : material;
            });
            setDispatchDetailsData(materialDetailsUpdatedData);
            return materialDetailsUpdatedData;

        } else {
            return getAvlStockQty;
        }
    }

    // Hide show customer order no's
    const showContsCheckboxes = () => {
        var checkboxes = document.getElementById("customer-order-ul");
        const items = document.querySelectorAll(".cust_order_no");
        if (!expanded) {
            if (items.length !== 0) {
                checkboxes.style.display = "block";
                expanded = true;
            }
        } else {
            checkboxes.style.display = "none";
            expanded = false;
        }
    }

    function toggleDispatchDetailsChkBoxes() {
        const selectAll = document.getElementById('checkdispatchnoteDetails').checked
        if (selectAll) {
            const dispatchChkBoxes = document.querySelectorAll('input[name=checkDispatch]');
            for (let checkbox of dispatchChkBoxes) {
                checkbox.checked = true;
            }
        } else {
            const uncheckDispatchChkBoxes = document.querySelectorAll('input[name=checkDispatch]:checked');
            for (let checkbox of uncheckDispatchChkBoxes) {
                let dispDtlId = parseInt(checkbox.getAttribute('dispDtlId'));
                if (dispDtlId === 0) {
                    checkbox.checked = false;
                }
            }
        }

        totalDispatchQuantity()
    }

    const partiallySelectedDispatch = () => {
        //Check Partiallly selected all or not if selected then check the check all radio of dispatch details
        const totalChkBoxes = document.querySelectorAll('input[name=checkDispatch]').length;
        const totalChkBoxesChked = document.querySelectorAll('input[name=checkDispatch]:checked').length;
        if (totalChkBoxes === totalChkBoxesChked) {
            document.getElementById('checkdispatchnoteDetails').checked = true;
        } else if (totalChkBoxes > totalChkBoxesChked) {
            document.getElementById('checkdispatchnoteDetails').checked = false;
        }
    }

    const checkCustomerOrderNo = async (customer_order_no) => {
        setIsLoading(true);
        if (parseInt(customer_order_no) === 0) {
            if ($("#all_cust_order_no_id").is(":checked") === false) {
                await selectAllCustomerOrderNo('uncheck');
            } else {
                await selectAllCustomerOrderNo('check');
            }
        }

        await FnCheckCustomerOrderNoApproval()

        //Check Partiallly selected all or not if selected then check the check all radio of dispatch details
        const totalChkBoxes = document.querySelectorAll('input[name=customerOrderNoCheckBox]').length;
        const totalChkBoxesChked = document.querySelectorAll('input[name=customerOrderNoCheckBox]:checked').length;
        if (totalChkBoxes === totalChkBoxesChked) {
            document.getElementById('all_cust_order_no_id').checked = true;
        } else if (totalChkBoxes > totalChkBoxesChked) {
            document.getElementById('all_cust_order_no_id').checked = false;
        }

        setCheckedCustomerOrderNoLength(totalChkBoxesChked)
        setIsLoading(false);
    }

    const FnCheckCustomerOrderNoApproval = async () => {
        try {
            let customer_order_nos = []
            $("input:checkbox[name=customerOrderNoCheckBox]:checked").each(function () {
                customer_order_nos.push($(this).val())
            })
            resetGlobalQuery();
            globalQuery.columns = ["DISTINCT (dispatch_schedule_no)", "customer_order_no", "dispatch_note_status"]
            globalQuery.table = "mtv_dispatch_schedule_details_trading"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "customer_order_no", operator: "IN", values: customer_order_nos });
            const getScheduleNosForCheckApproval = await comboDataFunc.current.fillFiltersCombo(globalQuery);

            let nonApprovedOrderNos = [];
            // Check if all customer_order_nos are present in getScheduleNosForCheckApproval
            const allCustomerOrderNosPresent = customer_order_nos.map(orderNo => {
                const isPresent = getScheduleNosForCheckApproval.some(schedule => schedule.customer_order_no === orderNo && schedule.dispatch_note_status === 'A')
                if (isPresent) {
                    document.getElementById(`filterCheck-${orderNo}`).checked = false;
                    nonApprovedOrderNos.push(orderNo)
                }
            });

            let custOrderNos = nonApprovedOrderNos + ""
            if (custOrderNos.length !== 0) {
                setInfoMsg("To create new Dispatch Advisory, Please Dispatch the approved Dispatch Advisory for the following customer order numbers: " + custOrderNos)
                setShowInfoMsgModal(true)
            }
        } catch (error) {
            console.log("error: ", error);
            navigate('/Error')
        }
    }

    const FnValidateDispatchNote = async () => {

        // Validate Header Form
        const checkIsValidate = await validate.current.validateForm("dispatchnoteId");
        if (!checkIsValidate) { return false; }


        let supervisor = supervisorComboRef.current.value;

        if (supervisor === '') {
            $('#error_cmb_supervisor').text('Please select Dispatch Supervisor');
            $('#error_cmb_supervisor').show();
            $('#error_cmb_supervisor').focus();
            return false;
        } else {
            $('#error_cmb_supervisor').hide();
        }
        // Dispatch details checked or not
        let selectedDispatchDetails = null;

        const checkboxSelector = 'input[type="checkbox"][name="checkDispatch"]:checked';
        const rowSelector = '#dispatchDetailsTbl tbody tr';

        if (rb_dispatch_order_creation_type === 'S') {
            const dispatchSelectedMaterials = document.querySelectorAll(checkboxSelector).length;
            if (dispatchSelectedMaterials < 1) {
                setErrMsg('Please select at least one material to dispatch!...');
                setShowErrorMsgModal(true);
                return false;
            }
            selectedDispatchDetails = document.querySelectorAll(`${rowSelector} .checkdispatchnoteDetails:checked`);
        } else {
            selectedDispatchDetails = document.querySelectorAll(rowSelector);
            if (dispatchDetailsData.length === 0) {
                setErrMsg('Please add at least one material to dispatch!...');
                setShowErrorMsgModal(true);
                return false;
            }
        }

        // Validate the inspection Details.
        if (rb_dispatch_order_creation_type === 'S') {
            let inspectionDtls = [...inspectionDetailsList]
            // let pkgDtls = [...PackingDetailsList];

            if (inspectionDtls.length === 0) {
                setErrMsg('Please select Dispatch Inspection details for material...!');
                setShowErrorMsgModal(true);
                return false;
            }
            let materilWiseInspectionSum = {};
            inspectionDtls.forEach(function (pkgMaterial) {
                if (!materilWiseInspectionSum.hasOwnProperty(pkgMaterial.sales_order_details_transaction_id)) {
                    materilWiseInspectionSum[pkgMaterial.sales_order_details_transaction_id] = 0;
                }
                materilWiseInspectionSum[pkgMaterial.sales_order_details_transaction_id] += parseFloat(pkgMaterial.dispatch_quantity);
            });

            let matCheckboxes = $("input:checkbox[name=checkDispatch]:checked");
            let isValidInspectionDtls = true;
            for (const checkbox of matCheckboxes) {
                let dispDtlId = parseInt(checkbox.getAttribute('dispDtlId'));

                const dispDtl = dispatchDetailsData.find(item => item.sales_order_details_transaction_id === parseInt($(checkbox).val()));
                // Check current material's sum object will be present or not?
                if (materilWiseInspectionSum.hasOwnProperty(dispDtl.sales_order_details_transaction_id)) {
                    if (materilWiseInspectionSum[dispDtl.sales_order_details_transaction_id] !== parseFloat(dispDtl.expected_dispatch_quantity) && dispDtlId === 0) {
                        setErrMsg(`Please select the proper inspection details for materials Cust. Order No: ${dispDtl.customer_order_no} and material name : ${dispDtl.product_material_print_name}!...`);
                        setShowErrorMsgModal(true);
                        isValidInspectionDtls = false;
                        break; // Break out of the loop
                    } else if (materilWiseInspectionSum[dispDtl.sales_order_details_transaction_id] !== parseFloat(dispDtl.actual_dispatch_quantity) && dispDtlId !== 0) {
                        setErrMsg(`Please select the proper inspection details for materials Cust. Order No: ${dispDtl.customer_order_no} and material name : ${dispDtl.product_material_print_name}!...`);
                        setShowErrorMsgModal(true);
                        isValidInspectionDtls = false;
                        break; // Break out of the loop
                    }

                    // if (materilWiseInspectionSum[dispDtl.sales_order_details_transaction_id] !== parseFloat(dispDtl.expected_dispatch_quantity)) {
                    //     setErrMsg(`Please select the proper inspection details for materials Cust. Order No: ${dispDtl.customer_order_no} and material name : ${dispDtl.product_material_print_name}!...`);
                    //     setShowErrorMsgModal(true);
                    //     isValidInspectionDtls = false;
                    //     break; // Break out of the loop
                    // }
                } else {
                    setErrMsg(`Please select the proper inspection details for materials Cust. Order No: ${dispDtl.customer_order_no} and material name : ${dispDtl.product_material_print_name}!...`);
                    setShowErrorMsgModal(true);
                    isValidInspectionDtls = false;
                    break; // Break out of the loop
                }
            }
            if (!isValidInspectionDtls) { return false; }
        }


        let dispatchDetailsIsValid = true;
        selectedDispatchDetails.forEach(function (dispatchDetail) {
            let currentTblRow = dispatchDetail.parentElement.parentElement.parentElement;
            let expectedDispQty = currentTblRow.querySelector('input[id^="expected_dispatch_quantity_"]').value;
            // let expectedDispWt = parseFloat(currentTblRow.querySelector('input[id^="expected_dispatch_weight_"]').value);
            let actualDispQty = currentTblRow.querySelector('input[id^="actual_dispatch_quantity_"]').value;
            // let actualDispWt = currentTblRow.querySelector('input[id^="actual_dispatch_weight_"]').value;

            if (expectedDispQty === '' || isNaN(expectedDispQty) || expectedDispQty === '0') {
                currentTblRow.querySelector('input[id^="expected_dispatch_quantity_"]').parentElement.dataset.tip = 'Please enter valid dispatch quantity...!';
                currentTblRow.querySelector('input[id^="expected_dispatch_quantity_"]').focus();
                dispatchDetailsIsValid = false;
                return;
            }
            // else if (isNaN(expectedDispWt) || expectedDispWt === '' || expectedDispWt === 0) {
            //     currentTblRow.querySelector('input[id^="expected_dispatch_weight_"]').parentElement.dataset.tip = 'Please enter valid dispatch weight...!';
            //     currentTblRow.querySelector('input[id^="expected_dispatch_weight_"]').focus();
            //     dispatchDetailsIsValid = false;
            //     return;
            // }

            if (keyForViewUpdate === 'update') {
                if (actualDispQty === '' || isNaN(actualDispQty) || actualDispQty === '0') {
                    currentTblRow.querySelector('input[id^="actual_dispatch_quantity_"]').parentElement.dataset.tip = 'Please enter valid actual dispatch quantity...!';
                    currentTblRow.querySelector('input[id^="actual_dispatch_quantity_"]').focus();
                    dispatchDetailsIsValid = false;
                    return;
                }
                // else if (isNaN(actualDispWt) || actualDispWt === '' || actualDispWt === '0') {
                //     currentTblRow.querySelector('input[id^="actual_dispatch_weight_"]').parentElement.dataset.tip = 'Please enter valid actual dispatch weight...!';
                //     currentTblRow.querySelector('input[id^="actual_dispatch_weight_"]').focus();
                //     dispatchDetailsIsValid = false;
                //     return;
                // }
            }
        });
        return dispatchDetailsIsValid;
    }

    const createDispatchNote = async (dispatchStatus) => {
        try {
            setIsLoading(true);
            let checkIsValidate = true;
            let latestDispatchNo = txt_dispatch_schedule_no;
            if (!isApprove) {
                checkIsValidate = await FnValidateDispatchNote();
            }
            if (checkIsValidate) {
                let json = { 'TransHeaderData': {}, 'TransDetailData': [], 'TransProductParametersData': [], 'TransPackingDetailData': [], 'TransInspectionDetailsData': [], 'commonIds': {} }
                let customercontactsids = FnGetCustomerContactPersonIds();
                if (dispatch_schedule_master_transaction_id === 0)
                    latestDispatchNo = await generateDispatchScheduleNo();
                // TransHeaderData
                const dispatchMasterFormData = {
                    dispatch_schedule_master_transaction_id: dispatch_schedule_master_transaction_id,
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    financial_year: FINANCIAL_SHORT_NAME,
                    dispatch_schedule_type_id: cmb_dispatch_schedule_type,
                    dispatch_schedule_type: $('#cmb_dispatch_schedule_type').find(":selected").text(),
                    dispatch_schedule_creation_type: rb_dispatch_order_creation_type,
                    dispatch_schedule_no: latestDispatchNo,
                    dispatch_schedule_date: txt_dispatch_date,
                    dispatch_schedule_version: parseInt(dispatch_schedule_version),
                    customer_id: cmb_customer_id,
                    customer_contacts_ids: customercontactsids,
                    customer_state_id: cmb_customer_state_id,
                    customer_city_id: cmb_customer_city_id,
                    consignee_state_id: cmb_consignee_state_id,
                    consignee_id: cmb_consignee_id,
                    consignee_city_id: cmb_consignee_city_id,
                    dispatch_supervisor_id: cmb_supervisor,
                    total_quantity: txt_total_quantity,
                    total_weight: txt_total_weight,
                    actual_quantity: txt_actual_quantity,
                    actual_weight: txt_actual_weight,
                    // Below Code Commented because Prashant Sir told there is no required the approval.
                    // approved_by_id: isApprove ? cmb_approved_by_id : null,
                    // approved_date: isApprove ? dt_approved_date : null,
                    // dispatch_note_status: dispatchStatus === 'R' || dispatchStatus === 'A' ? dispatchStatus : 'P',   // Commented because sir told approval not required.
                    approved_by_id: cmb_approved_by_id,
                    approved_date: dt_approved_date,
                    dispatch_note_status: 'A',
                    dispatch_note_remark: txt_note_Remark,
                    other_terms_conditions: txt_other_terms_conditions,
                    remark: txt_remark,
                    is_active: rb_is_active,
                    created_by: UserName,
                    modified_by: dispatch_schedule_master_transaction_id !== 0 ? UserName : null,
                    gate_pass_no: txt_gate_pass_no,
                    gate_pass_date: dt_gate_pass_date,
                    vehical_no: txt_vehicale_no,
                    container_no: txt_container_no,
                    seal_no: txt_seal_no,
                    net_weight: txt_net_weight,
                    driver_name: txt_driver_name,
                    driver_contact_no: txt_driver_contact_no,
                    vehicle_reporting_time: dt_vehicle_reporting_time,
                    vehicle_loading_started_time: dt_vehicle_loading_started_time,
                    vehicle_loading_finish_time: dt_vehicle_loading_finish_time,
                }
                json.TransHeaderData = dispatchMasterFormData;

                if (rb_dispatch_order_creation_type === 'S') {
                    $("input:checkbox[name=checkDispatch]:checked").each(function (index) {
                        const bomfilteredDataElement = dispatchDetailsData.find(item => rb_dispatch_order_creation_type === 'S' ? item.sales_order_details_transaction_id === parseInt($(this).val()) : item.product_material_id === $(this).val());
                        let crJson = FnProcessBomfilteredDataElement(bomfilteredDataElement, latestDispatchNo, dispatchStatus);
                        crJson.sr_no = index + 1;
                        crJson.lot_no = bomfilteredDataElement.lot_no;
                        crJson.count_no = bomfilteredDataElement.count_no;
                        crJson.set_no = bomfilteredDataElement.set_no;
                        crJson.roll_no = bomfilteredDataElement.roll_no;
                        json.TransDetailData.push(crJson);
                    });
                } else {
                    dispatchDetailsData.forEach((bomfilteredDataElement, index) => {
                        let crJson = FnProcessBomfilteredDataElement(bomfilteredDataElement, latestDispatchNo, dispatchStatus);
                        crJson.sr_no = index + 1;
                        crJson.so_sr_no = 1;
                        crJson.lot_no = bomfilteredDataElement.lot_no;
                        crJson.count_no = bomfilteredDataElement.count_no;
                        json.TransDetailData.push(crJson);
                    });
                }

                // // Trans Product Properties Data
                if (rb_dispatch_order_creation_type === 'S') {
                    $("input:checkbox[name=checkDispatch]:checked").each(function (index) {
                        let custOrdNo = $(this).attr("custOrdNo");
                        let matId = $(this).attr("matId");

                        const currentMaterialProdDynamicProps = productTypePropertiesData.filter(prop => prop.product_id === matId);
                        // currentMaterialProdDynamicProps.forEach(prop => {
                        for (let index = 0; index < currentMaterialProdDynamicProps.length; index++) {
                            const prop = currentMaterialProdDynamicProps[index];
                            const parameterObj = {
                                company_id: COMPANY_ID,
                                company_branch_id: COMPANY_BRANCH_ID,
                                dispatch_schedule_master_transaction_id: dispatch_schedule_master_transaction_id,
                                dispatch_schedule_parameter_id: 0,
                                product_parameter_id: prop.product_parameter_id,
                                dispatch_schedule_no: txt_dispatch_schedule_no,
                                dispatch_schedule_date: txt_dispatch_date,
                                dispatch_schedule_version: dispatch_schedule_version,
                                customer_order_no: prop.customer_order_no,
                                product_id: prop.product_id,
                                product_type_id: cmb_dispatch_schedule_type,
                                product_parameter_name: prop.product_parameter_name,
                                product_parameter_value: prop.product_parameter_value,
                                product_parameter_actual_value: prop.product_parameter_actual_value,
                                product_parameter_sufix: prop.product_parameter_sufix,
                                product_parameter_prefix: prop.product_parameter_prefix,
                                product_parameter_calculation_type: prop.product_parameter_calculation_type,
                                product_parameter_from_value: prop.product_parameter_from_value,
                                product_parameter_to_value: prop.product_parameter_to_value,
                                product_parameter_formula: prop.product_parameter_formula,
                                remark: prop.remark,
                                created_by: keyForViewUpdate === "" ? UserName : prop.created_by,
                                modified_by: keyForViewUpdate === "update" ? UserName : null
                            }
                            json.TransProductParametersData.push(parameterObj)
                        }
                    });
                } else {
                    for (let index = 0; index < productTypePropertiesData.length; index++) {
                        const parameterDetailsRow = productTypePropertiesData[index];
                        const parameterObj = {
                            company_id: COMPANY_ID,
                            company_branch_id: COMPANY_BRANCH_ID,
                            dispatch_schedule_master_transaction_id: dispatch_schedule_master_transaction_id,
                            dispatch_schedule_parameter_id: 0,
                            product_parameter_id: parameterDetailsRow.product_parameter_id,
                            dispatch_schedule_no: txt_dispatch_schedule_no,
                            dispatch_schedule_date: txt_dispatch_date,
                            dispatch_schedule_version: dispatch_schedule_version,
                            customer_order_no: parameterDetailsRow.customer_order_no,
                            product_id: parameterDetailsRow.product_id,
                            product_type_id: cmb_dispatch_schedule_type,
                            product_parameter_name: parameterDetailsRow.product_parameter_name,
                            product_parameter_value: parameterDetailsRow.product_parameter_value,
                            product_parameter_actual_value: parameterDetailsRow.product_parameter_actual_value,
                            product_parameter_sufix: parameterDetailsRow.product_parameter_sufix,
                            product_parameter_prefix: parameterDetailsRow.product_parameter_prefix,
                            product_parameter_calculation_type: parameterDetailsRow.product_parameter_calculation_type,
                            product_parameter_from_value: parameterDetailsRow.product_parameter_from_value,
                            product_parameter_to_value: parameterDetailsRow.product_parameter_to_value,
                            product_parameter_formula: parameterDetailsRow.product_parameter_formula,
                            remark: parameterDetailsRow.remark,
                            created_by: keyForViewUpdate === "" ? UserName : parameterDetailsRow.created_by,
                            modified_by: keyForViewUpdate === "update" ? UserName : null
                        }
                        json.TransProductParametersData.push(parameterObj)
                    }
                }

                if (rb_dispatch_order_creation_type === 'S') {
                    let inspectionDtls = [...inspectionDetailsList]
                    let MatIds = [];
                    $("input:checkbox[name=checkDispatch]:checked").each(function (index) {
                        let matId = $(this).attr("matId");
                        if (matId) {
                            MatIds.push(matId);
                        }
                    });
                    // Filter PackingDetailsList based on MatIds
                    inspectionDtls = inspectionDtls.filter(function (inspectionDtls) {
                        return MatIds.includes(inspectionDtls.product_material_id);
                    });

                    // const groupedAndSummedBySOID = groupByAndSum(inspectionDtls, 'sales_order_details_transaction_id', 'dispatch_quantity');
                    // for (let index = 0; index < json.TransDetailData.length; index++) {
                    //     const dispatchMtrl = json.TransDetailData[index];
                    //     const soID = dispatchMtrl.sales_order_details_transaction_id
                    //     const inspGroup = groupedAndSummedBySOID[soID];

                    //     // Means actual quantity will be set. and at a time of update
                    //     if (parseFloat(dispatchMtrl.actual_dispatch_quantity) > 0 && keyForViewUpdate === 'update') {
                    //         // Means actual qty and expected qty is not same;
                    //         if (parseFloat(dispatchMtrl.expected_dispatch_quantity) !== parseFloat(dispatchMtrl.actual_dispatch_quantity)) {
                    //             let quantityToDispatch = parseFloat(dispatchMtrl.actual_dispatch_quantity)
                    //             let dispatchedQuantity = 0;

                    //             for (let i = 0; i < inspGroup.items.length; i++) {
                    //                 const item = inspGroup.items[i];
                    //                 if (dispatchedQuantity < quantityToDispatch) {
                    //                     var remainingQuantity = quantityToDispatch - dispatchedQuantity;

                    //                     if (item.dispatch_quantity <= remainingQuantity) {
                    //                         dispatchedQuantity += item.dispatch_quantity;
                    //                         let inpectionDtl = {
                    //                             company_id: COMPANY_ID,
                    //                             company_branch_id: COMPANY_BRANCH_ID,
                    //                             financial_year: FINANCIAL_SHORT_NAME,
                    //                             dispatch_schedule_no: txt_dispatch_schedule_no,
                    //                             dispatch_schedule_date: txt_dispatch_date,
                    //                             dispatch_schedule_version: dispatch_schedule_version,
                    //                             dispatch_schedule_inspection_details_transaction_id: item.dispatch_schedule_inspection_details_transaction_id,
                    //                             dispatch_schedule_master_transaction_id: dispatch_schedule_master_transaction_id,
                    //                             sales_order_no: item.sales_order_no,
                    //                             sales_order_Date: item.sales_order_Date,
                    //                             sales_order_version: item.sales_order_version,
                    //                             customer_id: cmb_customer_id,
                    //                             customer_order_no: item.customer_order_no,
                    //                             customer_order_Date: item.customer_order_Date,
                    //                             sales_order_details_transaction_id: item.sales_order_details_transaction_id,
                    //                             product_material_id: item.product_material_id,
                    //                             product_material_unit_id: item.product_material_stock_unit_id,
                    //                             inspection_production_code: item.inspection_production_code,
                    //                             inspection_order_no: item.inspection_order_no,
                    //                             inspection_production_date: item.inspection_production_date,
                    //                             weaving_production_inspection_details_id: item.weaving_production_inspection_details_id,
                    //                             inspection_production_set_no: item.inspection_production_set_no,
                    //                             inspection_mtr: item.inspection_mtr,
                    //                             dispatch_quantity: item.dispatch_quantity,
                    //                             sort_no: item.sort_no,
                    //                             roll_no: item.roll_no,
                    //                             godown_id: item.godown_id,
                    //                             created_by: UserName,
                    //                             modified_by: UserName
                    //                         }
                    //                         json.TransInspectionDetailsData.push(inpectionDtl);

                    //                     } else {
                    //                         dispatchedQuantity += remainingQuantity;

                    //                         let inpectionDtl = {
                    //                             company_id: COMPANY_ID,
                    //                             company_branch_id: COMPANY_BRANCH_ID,
                    //                             financial_year: FINANCIAL_SHORT_NAME,
                    //                             dispatch_schedule_no: txt_dispatch_schedule_no,
                    //                             dispatch_schedule_date: txt_dispatch_date,
                    //                             dispatch_schedule_version: dispatch_schedule_version,
                    //                             dispatch_schedule_inspection_details_transaction_id: item.dispatch_schedule_inspection_details_transaction_id,
                    //                             dispatch_schedule_master_transaction_id: dispatch_schedule_master_transaction_id,
                    //                             sales_order_no: item.sales_order_no,
                    //                             sales_order_Date: item.sales_order_Date,
                    //                             sales_order_version: item.sales_order_version,
                    //                             customer_id: cmb_customer_id,
                    //                             customer_order_no: item.customer_order_no,
                    //                             customer_order_Date: item.customer_order_Date,
                    //                             sales_order_details_transaction_id: item.sales_order_details_transaction_id,
                    //                             product_material_id: item.product_material_id,
                    //                             product_material_unit_id: item.product_material_stock_unit_id,
                    //                             inspection_production_code: item.inspection_production_code,
                    //                             inspection_order_no: item.inspection_order_no,
                    //                             inspection_production_date: item.inspection_production_date,
                    //                             weaving_production_inspection_details_id: item.weaving_production_inspection_details_id,
                    //                             inspection_production_set_no: item.inspection_production_set_no,
                    //                             inspection_mtr: item.inspection_mtr,
                    //                             dispatch_quantity: remainingQuantity, // item.dispatch_quantity,
                    //                             sort_no: item.sort_no,
                    //                             roll_no: item.roll_no,
                    //                             godown_id: item.godown_id,
                    //                             created_by: UserName,
                    //                             modified_by: UserName
                    //                         }
                    //                         json.TransInspectionDetailsData.push(inpectionDtl);
                    //                     }
                    //                 } else {
                    //                     let inpectionDtl = {
                    //                         company_id: COMPANY_ID,
                    //                         company_branch_id: COMPANY_BRANCH_ID,
                    //                         financial_year: FINANCIAL_SHORT_NAME,
                    //                         dispatch_schedule_no: txt_dispatch_schedule_no,
                    //                         dispatch_schedule_date: txt_dispatch_date,
                    //                         dispatch_schedule_version: dispatch_schedule_version,
                    //                         dispatch_schedule_inspection_details_transaction_id: item.dispatch_schedule_inspection_details_transaction_id,
                    //                         dispatch_schedule_master_transaction_id: dispatch_schedule_master_transaction_id,
                    //                         sales_order_no: item.sales_order_no,
                    //                         sales_order_Date: item.sales_order_Date,
                    //                         sales_order_version: item.sales_order_version,
                    //                         customer_id: cmb_customer_id,
                    //                         customer_order_no: item.customer_order_no,
                    //                         customer_order_Date: item.customer_order_Date,
                    //                         sales_order_details_transaction_id: item.sales_order_details_transaction_id,
                    //                         product_material_id: item.product_material_id,
                    //                         product_material_unit_id: item.product_material_stock_unit_id,
                    //                         inspection_production_code: item.inspection_production_code,
                    //                         inspection_order_no: item.inspection_order_no,
                    //                         inspection_production_date: item.inspection_production_date,
                    //                         weaving_production_inspection_details_id: item.weaving_production_inspection_details_id,
                    //                         inspection_production_set_no: item.inspection_production_set_no,
                    //                         inspection_mtr: item.inspection_mtr,
                    //                         dispatch_quantity: remainingQuantity, // item.dispatch_quantity,
                    //                         sort_no: item.sort_no,
                    //                         roll_no: item.roll_no,
                    //                         godown_id: item.godown_id,
                    //                         created_by: UserName,
                    //                         modified_by: UserName
                    //                     }
                    //                     json.TransInspectionDetailsData.push(inpectionDtl);
                    //                 }
                    //             }
                    //         } else {
                    //             inspGroup.items.forEach(item => {
                    //                 let inpectionDtl = {
                    //                     company_id: COMPANY_ID,
                    //                     company_branch_id: COMPANY_BRANCH_ID,
                    //                     financial_year: FINANCIAL_SHORT_NAME,
                    //                     dispatch_schedule_no: txt_dispatch_schedule_no,
                    //                     dispatch_schedule_date: txt_dispatch_date,
                    //                     dispatch_schedule_version: dispatch_schedule_version,
                    //                     dispatch_schedule_inspection_details_transaction_id: item.dispatch_schedule_inspection_details_transaction_id,
                    //                     dispatch_schedule_master_transaction_id: dispatch_schedule_master_transaction_id,
                    //                     sales_order_no: item.sales_order_no,
                    //                     sales_order_Date: item.sales_order_Date,
                    //                     sales_order_version: item.sales_order_version,
                    //                     customer_id: cmb_customer_id,
                    //                     customer_order_no: item.customer_order_no,
                    //                     customer_order_Date: item.customer_order_Date,
                    //                     sales_order_details_transaction_id: item.sales_order_details_transaction_id,
                    //                     product_material_id: item.product_material_id,
                    //                     product_material_unit_id: item.product_material_stock_unit_id,
                    //                     inspection_production_code: item.inspection_production_code,
                    //                     inspection_order_no: item.inspection_order_no,
                    //                     inspection_production_date: item.inspection_production_date,
                    //                     weaving_production_inspection_details_id: item.weaving_production_inspection_details_id,
                    //                     inspection_production_set_no: item.inspection_production_set_no,
                    //                     inspection_mtr: item.inspection_mtr,
                    //                     dispatch_quantity: item.dispatch_quantity,
                    //                     sort_no: item.sort_no,
                    //                     roll_no: item.roll_no,
                    //                     godown_id: item.godown_id,
                    //                     created_by: UserName,
                    //                     modified_by: UserName
                    //                 }
                    //                 json.TransInspectionDetailsData.push(inpectionDtl);
                    //             });
                    //         }
                    //     } else {
                    //         inspGroup.items.forEach(item => {
                    //             let inpectionDtl = {
                    //                 company_id: COMPANY_ID,
                    //                 company_branch_id: COMPANY_BRANCH_ID,
                    //                 financial_year: FINANCIAL_SHORT_NAME,
                    //                 dispatch_schedule_no: txt_dispatch_schedule_no,
                    //                 dispatch_schedule_date: txt_dispatch_date,
                    //                 dispatch_schedule_version: dispatch_schedule_version,
                    //                 dispatch_schedule_inspection_details_transaction_id: item.dispatch_schedule_inspection_details_transaction_id,
                    //                 dispatch_schedule_master_transaction_id: dispatch_schedule_master_transaction_id,
                    //                 sales_order_no: item.sales_order_no,
                    //                 sales_order_Date: item.sales_order_Date,
                    //                 sales_order_version: item.sales_order_version,
                    //                 customer_id: cmb_customer_id,
                    //                 customer_order_no: item.customer_order_no,
                    //                 customer_order_Date: item.customer_order_Date,
                    //                 sales_order_details_transaction_id: item.sales_order_details_transaction_id,
                    //                 product_material_id: item.product_material_id,
                    //                 product_material_unit_id: item.product_material_stock_unit_id,
                    //                 inspection_production_code: item.inspection_production_code,
                    //                 inspection_order_no: item.inspection_order_no,
                    //                 inspection_production_date: item.inspection_production_date,
                    //                 weaving_production_inspection_details_id: item.weaving_production_inspection_details_id,
                    //                 inspection_production_set_no: item.inspection_production_set_no,
                    //                 inspection_mtr: item.inspection_mtr,
                    //                 dispatch_quantity: item.dispatch_quantity,
                    //                 sort_no: item.sort_no,
                    //                 roll_no: item.roll_no,
                    //                 godown_id: item.godown_id,
                    //                 created_by: UserName,
                    //                 modified_by: UserName
                    //             }
                    //             json.TransInspectionDetailsData.push(inpectionDtl);
                    //         });
                    //     }
                    // }


                    let inspectionDetailsJsonArray = inspectionDtls?.map((inspectionItem, Index) => ({
                        company_id: COMPANY_ID,
                        company_branch_id: COMPANY_BRANCH_ID,
                        financial_year: FINANCIAL_SHORT_NAME,
                        dispatch_schedule_no: txt_dispatch_schedule_no,
                        dispatch_schedule_date: txt_dispatch_date,
                        dispatch_schedule_version: dispatch_schedule_version,
                        dispatch_schedule_inspection_details_transaction_id: inspectionItem.dispatch_schedule_inspection_details_transaction_id,
                        dispatch_schedule_master_transaction_id: dispatch_schedule_master_transaction_id,
                        sales_order_no: inspectionItem.sales_order_no,
                        sales_order_Date: inspectionItem.sales_order_Date,
                        sales_order_version: inspectionItem.sales_order_version,
                        customer_id: cmb_customer_id,
                        customer_order_no: inspectionItem.customer_order_no,
                        customer_order_Date: inspectionItem.customer_order_Date,
                        sales_order_details_transaction_id: inspectionItem.sales_order_details_transaction_id,
                        product_material_id: inspectionItem.product_material_id,
                        product_material_unit_id: inspectionItem.product_material_stock_unit_id,
                        inspection_production_code: inspectionItem.inspection_production_code,
                        inspection_order_no: inspectionItem.inspection_order_no,
                        inspection_production_date: inspectionItem.inspection_production_date,
                        weaving_production_inspection_details_id: inspectionItem.weaving_production_inspection_details_id,
                        inspection_production_set_no: inspectionItem.inspection_production_set_no,
                        inspection_mtr: inspectionItem.inspection_mtr,
                        dispatch_quantity: inspectionItem.dispatch_quantity,
                        sort_no: inspectionItem.sort_no,
                        roll_no: inspectionItem.roll_no,
                        godown_id: inspectionItem.godown_id,
                        created_by: UserName,
                        modified_by: UserName
                    }), [])
                    json.TransInspectionDetailsData = inspectionDetailsJsonArray; //Add Inspection List for dispatch
                }


                //Common Ids
                json.commonIds.company_id = COMPANY_ID
                json.commonIds.company_branch_id = COMPANY_BRANCH_ID
                json.commonIds.dispatch_schedule_no = latestDispatchNo
                json.commonIds.dispatch_schedule_date = txt_dispatch_date
                json.commonIds.financial_year = FINANCIAL_SHORT_NAME

                console.log('Data: ', json);

                const formData = new FormData();
                formData.append(`MtDispatchScheduleData`, JSON.stringify(json))
                const requestOptions = { method: 'POST', body: formData };
                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtDispatchScheduleDetails/FnAddUpdateRecord`, requestOptions)
                const response = await apicall.json();

                if (response.success === "0") {
                    setIsLoading(false);
                    setErrMsg(response.error)
                    setShowErrorMsgModal(true)
                } else {
                    const evitCache = comboDataFunc.current.evitCache();
                    setdispatchscheduledetailstransactionId(response.data.dispatch_schedule_master_transaction_id)
                    setSuccMsg(response.message)
                    if (response.data.hasOwnProperty('dispatch_schedule_no')) {
                        setModalOrderDetails(`Dispatch note No: ${response.data.dispatch_schedule_no}`)
                        setDispScheduleNo(response.data.dispatch_schedule_no);
                        setDispVersion(response.data.dispatch_schedule_version);
                    }
                    setShowConfirmationModal(true);
                    setIsLoading(false);
                }
                setIsLoading(false);
            }
            setIsLoading(false)
        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }

    // Function to group JSON array by a specific field and calculate sum of another field
    function groupByAndSum(array, groupByKey, sumByKey) {
        return array.reduce((result, currentValue) => {
            const key = currentValue[groupByKey];
            result[key] = result[key] || { sum: 0, items: [] };
            result[key].sum += currentValue[sumByKey];
            result[key].items.push(currentValue);
            return result;
        }, {});
    }

    const FnProcessBomfilteredDataElement = (bomfilteredDataElement, latestDispatchNo, dispatchStatus) => {
        let crJson = {};

        crJson['dispatch_schedule_details_transaction_id'] = (dispatch_schedule_master_transaction_id !== 0) ? (bomfilteredDataElement.dispatch_schedule_details_transaction_id || 0) : 0;
        crJson['company_id'] = COMPANY_ID;
        crJson['company_branch_id'] = COMPANY_BRANCH_ID;
        crJson['financial_year'] = FINANCIAL_SHORT_NAME;
        crJson['customer_order_Date'] = (rb_dispatch_order_creation_type === 'S') ? bomfilteredDataElement.customer_order_Date : null;
        crJson['dispatch_schedule_type_id'] = cmb_dispatch_schedule_type;
        crJson['sales_order_details_transaction_id'] = (rb_dispatch_order_creation_type === 'S') ? bomfilteredDataElement.sales_order_details_transaction_id : 0;
        crJson['dispatch_schedule_type'] = $('#cmb_dispatch_schedule_type').find(":selected").text();
        crJson['dispatch_schedule_creation_type'] = rb_dispatch_order_creation_type;
        crJson['dispatch_schedule_date'] = txt_dispatch_date;
        crJson['customer_order_no'] = (rb_dispatch_order_creation_type === 'S') ? bomfilteredDataElement.customer_order_no : null;
        crJson['sales_order_no'] = (rb_dispatch_order_creation_type === 'S') ? bomfilteredDataElement.sales_order_no : null;
        crJson['dispatch_schedule_no'] = (dispatch_schedule_master_transaction_id === 0) ? latestDispatchNo : txt_dispatch_schedule_no;
        crJson['product_material_unit_id'] = bomfilteredDataElement.product_material_unit_id;
        crJson['product_material_packing_id'] = bomfilteredDataElement.product_material_packing_id;
        crJson['sr_no'] = bomfilteredDataElement.sr_no;
        crJson['product_material_print_name'] = bomfilteredDataElement.product_material_print_name;
        crJson['product_material_tech_spect'] = bomfilteredDataElement.product_material_tech_spect;
        crJson['so_sr_no'] = bomfilteredDataElement.so_sr_no;
        crJson['product_material_id'] = bomfilteredDataElement.product_material_id;
        crJson['expected_dispatch_quantity'] = bomfilteredDataElement.expected_dispatch_quantity;
        crJson['expected_schedule_date'] = bomfilteredDataElement.expected_schedule_date;
        crJson['dispatch_schedule_remark'] = bomfilteredDataElement.dispatch_schedule_remark;
        crJson.actual_dispatch_quantity = bomfilteredDataElement.actual_dispatch_quantity;
        crJson.actual_dispatch_weight = bomfilteredDataElement.actual_dispatch_weight;


        // if ((crJson.actual_dispatch_quantity === '' || crJson.actual_dispatch_quantity === '0' || crJson.actual_dispatch_quantity === undefined) && (dispatchStatus === 'A' || dispatchStatus === 'R')) {
        //     crJson.actual_dispatch_quantity = Math.max(bomfilteredDataElement.stock_quantity, bomfilteredDataElement.expected_dispatch_quantity);
        // } else {
        //     crJson.actual_dispatch_quantity = bomfilteredDataElement.actual_dispatch_quantity;
        // }

        // if ((crJson.actual_dispatch_weight === '' || crJson.actual_dispatch_weight === '0' || crJson.actual_dispatch_weight === undefined) && (dispatchStatus === 'A' || dispatchStatus === 'R')) {
        //     crJson.actual_dispatch_weight = Math.max(bomfilteredDataElement.stock_weight, bomfilteredDataElement.expected_dispatch_weight);
        // } else {
        //     crJson.actual_dispatch_weight = bomfilteredDataElement.actual_dispatch_weight;
        // }

        crJson['expected_dispatch_weight'] = bomfilteredDataElement.expected_dispatch_weight;
        crJson['dispatched_quantity'] = 0;
        crJson['dispatched_weight'] = 0;

        // crJson['dispatched_quantity'] = bomfilteredDataElement.prev_disp_note_qty;
        // crJson['dispatched_weight'] = bomfilteredDataElement.prev_disp_note_wt;

        crJson['dispatch_schedule_item_status'] = 'A';
        // Below Code Commented because Prashant Sir told there is no required the approval.
        // crJson['dispatch_schedule_item_status'] = (
        //     (bomfilteredDataElement.dispatch_schedule_item_status === 'P' || bomfilteredDataElement.dispatch_schedule_item_status === null) &&
        //     isApprove
        // ) ? dispatchStatus : bomfilteredDataElement.dispatch_schedule_item_status;

        crJson['dispatch_return_weight'] = 0;
        crJson['batch_no'] = bomfilteredDataElement.batch_no;
        crJson['pending_quantity'] = 0;
        crJson['pending_weight'] = 0;
        // crJson['delayed_days'] = 0;
        crJson['delayed_days'] = bomfilteredDataElement.delayed_days;
        crJson['invoice_quantity'] = 0;
        crJson['invoice_weight'] = 0;
        crJson.created_by = UserName;
        crJson.modified_by = dispatch_schedule_master_transaction_id !== 0 ? UserName : null
        return crJson;
    };


    const clearFormFields = () => {
        setDispScheduleNo('')
        setDispatchScheduleType('');
        setCustomerId('');
        setSupervisor('');
        setConsigneeState('');
        setConsigneeId('');
        setCustContactDetails('');
        // setCustContactDetailsCols('');
        setConsigneeCity('');
        setDispatchNoteStatus('');
        setRemark('');
        setNoteRemark('')
        setothercondition('')
        setCustomerState('');
        setCheckedCustomerOrderNoLength(0);
        setCustomerCity('');
        setIsActive(true);
        setDispatchDate(today);
        setGatePassNo("")
        setGatePassDate(today)
        setVehicaleNo("")
        setNetWeight("0")
        setContainerNo("")
        setSealNo("")
        setDriverName("")
        setDriverContactNo("")
        sessionStorage.removeItem('dispatch_note_no')

    }
    const validateFields = () => {
        validate.current.validateFieldsOnChange('dispatchnoteId')
    }


    const onImportClick = () => {
        importFile.current.click();
    };

    const ExportToExcel = () => {
        if (rb_dispatch_order_creation_type === 'S') {
            const checkedCount = $("input:checkbox[name=checkDispatch]:checked").length;
            if (parseInt(checkedCount) !== 0) {
                const checkedValues = $("input:checkbox[name=customerOrderNoCheckBox]:checked").map(function () {
                    return $(this).val();
                }).get();

                const resultString = checkedValues.join(', ');
                let jsonToExportExcel = { 'allData': {}, 'columns': [], 'filtrKeyValue': {}, 'headings': {}, 'key': 'bomExport', editable_cols: [] }
                jsonToExportExcel.filtrKeyValue['0'] = "Dispatch Schedule Type" + ' : ' + $('#cmb_dispatch_schedule_type').find(":selected").text() + "(" + cmb_dispatch_schedule_type + ")";
                jsonToExportExcel.filtrKeyValue['1'] = "Creation type : " + $("input[name='rb_dispatch_order_creation_type']:checked").attr("lbl") + "(" + rb_dispatch_order_creation_type + ")";
                jsonToExportExcel.filtrKeyValue['2'] = "Dispatch Schedule No" + ' : ' + txt_dispatch_schedule_no;
                jsonToExportExcel.filtrKeyValue['3'] = "Dispatch Version" + ' : ' + dispatch_schedule_version;
                jsonToExportExcel.filtrKeyValue['4'] = "Customer" + ' : ' + $('#cmb_customer_id').find(":selected").text() + "(" + cmb_customer_id + ")";
                jsonToExportExcel.filtrKeyValue['5'] = "Customer Order Nos." + ' : ' + '(' + resultString + ')';
                jsonToExportExcel.filtrKeyValue['6'] = "Customer State" + ' : ' + $('#cmb_customer_state_id').find(":selected").text() + "(" + cmb_customer_state_id + ")";
                jsonToExportExcel.filtrKeyValue['7'] = "Customer City" + ' : ' + $('#cmb_customer_city_id').find(":selected").text() + "(" + cmb_customer_city_id + ")";
                jsonToExportExcel.filtrKeyValue['8'] = "Consignee : " + (cmb_consignee_id === '0' || cmb_consignee_id === '' ? '' : $('#cmb_consignee_id').find(":selected").text() + "(" + cmb_consignee_id + ")")
                jsonToExportExcel.filtrKeyValue['9'] = "Consignee State : " + (cmb_consignee_state_id === '0' || cmb_consignee_state_id === '' ? '' : $('#cmb_consignee_state_id').find(":selected").text() + "(" + cmb_consignee_state_id + ")")
                jsonToExportExcel.filtrKeyValue['10'] = "Consignee City : " + (cmb_consignee_city_id === '0' || cmb_consignee_city_id === '' ? '' : $('#cmb_consignee_city_id').find(":selected").text() + "(" + cmb_consignee_city_id + ")")
                jsonToExportExcel.filtrKeyValue['11'] = "Dispatch Date" + ' : ' + txt_dispatch_date;
                jsonToExportExcel.filtrKeyValue['12'] = "Supervisor : " + (cmb_supervisor === '0' || cmb_supervisor === '' ? '' : $('#cmb_supervisor').find(":selected").text() + "(" + cmb_supervisor + ")")
                jsonToExportExcel.filtrKeyValue['13'] = "Status" + ' : ' + $('#cmb_dispatch_note_status').find(":selected").text() + "(" + cmb_dispatch_note_status + ")";
                jsonToExportExcel.filtrKeyValue['14'] = "Dispatch Note Remark" + ' : ' + txt_note_Remark;
                jsonToExportExcel.filtrKeyValue['15'] = "Other Terms & Conditions" + ' : ' + txt_other_terms_conditions;
                jsonToExportExcel.filtrKeyValue['16'] = "Remark" + ' : ' + txt_remark;
                jsonToExportExcel.filtrKeyValue['17'] = "Active" + ' : ' + rb_is_active;
                jsonToExportExcel.filtrKeyValue['18'] = "Total Qty." + ' : ' + txt_total_quantity;
                jsonToExportExcel.filtrKeyValue['19'] = "Total Weight" + ' : ' + txt_total_weight;
                jsonToExportExcel.filtrKeyValue['20'] = "Actual Qty." + ' : ' + txt_actual_quantity;
                jsonToExportExcel.filtrKeyValue['21'] = "Actual Weight" + ' : ' + txt_actual_weight;

                const editableColumnNames = ["expected_dispatch_quantity", "expected_dispatch_weight", "actual_dispatch_quantity", "actual_dispatch_weight"];
                for (let colIndex = 0; colIndex < colNames.length; colIndex++) {
                    jsonToExportExcel.columns.push({ "Headers": colNames[colIndex], "accessor": colNames[colIndex] })
                    if (editableColumnNames.includes(colNames[colIndex])) {
                        jsonToExportExcel.editable_cols.push(colIndex);
                    }
                }

                $("input:checkbox[name=checkDispatch]:checked").each(function (index, item) {
                    let material = dispatchDetailsData.find(element => element.sales_order_details_transaction_id === parseInt(item.value));
                    material.dispatched_quantity = material.prev_disp_note_qty;
                    material.dispatched_weight = material.prev_disp_note_wt;
                    material.sr_no = index + 1;
                    jsonToExportExcel['allData'][index] = material;
                })
                jsonToExportExcel['headings']['ReportName'] = "Trading Dispatch Advisory Details"
                jsonToExportExcel['headings']['CompanyName'] = COMPANY_NAME
                jsonToExportExcel['headings']['CompanyAddress'] = sessionStorage.getItem('companyAddress')
                exlsExp.current.excel(jsonToExportExcel, txt_dispatch_schedule_no + "@" + COMPANY_ID)
            } else {
                setErrMsg('Please select at least one material to dispatch!... ')
                setShowErrorMsgModal(true)
            }
        } else if (rb_dispatch_order_creation_type === 'M') {
            let requiredCols = ['sr_no', 'product_material_name', 'product_material_tech_spect', 'batch_no', 'product_material_std_weight',
                "stock_quantity", "stock_weight", "expected_dispatch_quantity", "expected_dispatch_weight",
                "actual_dispatch_quantity", "actual_dispatch_weight", "product_our_part_code",
                "dispatch_return_quantity", "dispatch_return_weight", "pending_quantity", "pending_weight",
                "delayed_days", "invoice_quantity", "invoice_weight", 'product_material_id', 'product_material_unit_id', 'product_material_packing_id']

            if (dispatchDetailsData.length > 0) {
                let jsonToExportExcel = { 'allData': {}, 'columns': [], 'filtrKeyValue': {}, 'headings': {}, 'key': 'bomExport', editable_cols: [] }
                jsonToExportExcel.filtrKeyValue['0'] = "Dispatch Schedule Type" + ' : ' + $('#cmb_dispatch_schedule_type').find(":selected").text() + "(" + cmb_dispatch_schedule_type + ")";
                jsonToExportExcel.filtrKeyValue['1'] = "Creation type : " + $("input[name='rb_dispatch_order_creation_type']:checked").attr("lbl") + "(" + rb_dispatch_order_creation_type + ")";
                jsonToExportExcel.filtrKeyValue['2'] = "Dispatch Schedule No" + ' : ' + txt_dispatch_schedule_no;
                jsonToExportExcel.filtrKeyValue['3'] = "Dispatch Version" + ' : ' + dispatch_schedule_version;
                jsonToExportExcel.filtrKeyValue['4'] = "Customer" + ' : ' + $('#cmb_customer_id').find(":selected").text() + "(" + cmb_customer_id + ")";
                jsonToExportExcel.filtrKeyValue['5'] = "Customer State" + ' : ' + $('#cmb_customer_state_id').find(":selected").text() + "(" + cmb_customer_state_id + ")";
                jsonToExportExcel.filtrKeyValue['6'] = "Customer City" + ' : ' + $('#cmb_customer_city_id').find(":selected").text() + "(" + cmb_customer_city_id + ")";
                jsonToExportExcel.filtrKeyValue['7'] = "Consignee : " + (cmb_consignee_id === '0' || cmb_consignee_id === '' ? '' : $('#cmb_consignee_id').find(":selected").text() + "(" + cmb_consignee_id + ")")
                jsonToExportExcel.filtrKeyValue['8'] = "Consignee State : " + (cmb_consignee_state_id === '0' || cmb_consignee_state_id === '' ? '' : $('#cmb_consignee_state_id').find(":selected").text() + "(" + cmb_consignee_state_id + ")")
                jsonToExportExcel.filtrKeyValue['9'] = "Consignee City : " + (cmb_consignee_city_id === '0' || cmb_consignee_city_id === '' ? '' : $('#cmb_consignee_city_id').find(":selected").text() + "(" + cmb_consignee_city_id + ")")
                jsonToExportExcel.filtrKeyValue['10'] = "Dispatch Date" + ' : ' + txt_dispatch_date;
                jsonToExportExcel.filtrKeyValue['11'] = "Supervisor : " + (cmb_supervisor === '0' || cmb_supervisor === '' ? '' : $('#cmb_supervisor').find(":selected").text() + "(" + cmb_supervisor + ")")
                jsonToExportExcel.filtrKeyValue['12'] = "Status" + ' : ' + $('#cmb_dispatch_note_status').find(":selected").text() + "(" + cmb_dispatch_note_status + ")";
                jsonToExportExcel.filtrKeyValue['13'] = "Dispatch Note Remark" + ' : ' + txt_note_Remark;
                jsonToExportExcel.filtrKeyValue['14'] = "Other Terms & Conditions" + ' : ' + txt_other_terms_conditions;
                jsonToExportExcel.filtrKeyValue['15'] = "Remark" + ' : ' + txt_remark;
                jsonToExportExcel.filtrKeyValue['16'] = "Active" + ' : ' + rb_is_active;
                jsonToExportExcel.filtrKeyValue['17'] = "Total Qty." + ' : ' + txt_total_quantity;
                jsonToExportExcel.filtrKeyValue['18'] = "Total Weight" + ' : ' + txt_total_weight;
                jsonToExportExcel.filtrKeyValue['19'] = "Actual Qty." + ' : ' + txt_actual_quantity;
                jsonToExportExcel.filtrKeyValue['20'] = "Actual Weight" + ' : ' + txt_actual_weight;

                const editableColumnNames = ["expected_dispatch_quantity", "expected_dispatch_weight", "actual_dispatch_quantity", "actual_dispatch_weight"];
                for (let colIndex = 0; colIndex < requiredCols.length; colIndex++) {
                    jsonToExportExcel.columns.push({ "Headers": requiredCols[colIndex], "accessor": requiredCols[colIndex] })
                    if (editableColumnNames.includes(requiredCols[colIndex])) {
                        jsonToExportExcel.editable_cols.push(colIndex);
                    }
                }

                // set table data.   // Create a new object with the updated key name
                dispatchDetailsData.map((dispatchDetail, Index) => {
                    dispatchDetail.sr_no = Index + 1;
                    jsonToExportExcel['allData'][Index] = dispatchDetail;
                });

                jsonToExportExcel['headings']['ReportName'] = "Trading Dispatch Advisory Details"
                jsonToExportExcel['headings']['CompanyName'] = COMPANY_NAME
                jsonToExportExcel['headings']['CompanyAddress'] = sessionStorage.getItem('companyAddress')
                exlsExp.current.excel(jsonToExportExcel, txt_dispatch_schedule_no + "@" + COMPANY_ID)
            } else {
                setErrMsg('Please add at least one material to dispatch!... ')
                setShowErrorMsgModal(true)
            }
        }


    }

    const removeMaterial = (materialId) => {
        if (!isApprove && keyForViewUpdate !== 'view') {
            const filterdedData = dispatchDetailsData.filter(item => item.product_material_id !== materialId);
            setDispatchDetailsData(filterdedData)
            sessionStorage.setItem('filteredMaterialData', JSON.stringify(filterdedData));
            if (productTypePropertiesData.length !== 0) {
                const updatedMaterialProperties = [...productTypePropertiesData].filter(item => item.product_id !== materialId);
                setProductTypePropertiesData(updatedMaterialProperties);
            }
        }
    }

    // Means if uncheck the checkbox then remove the it's related details.
    const materialSelection = (event, inspectionDtls, productTypePropsDtls, dispScheduleItem) => {
        if (!event.target.checked) {
            if (inspectionDtls.length > 0) {
                let updatedData = inspectionDtls.filter(item => item.product_material_id !== dispScheduleItem.product_material_id || item.customer_order_no !== dispScheduleItem.customer_order_no);
                setInspectionDetailsList(updatedData);
            }

            // Remove the Product Type Properties
            // if (productTypePropertiesData.length !== 0) {
            //     const updatedMaterialProperties = [...productTypePropertiesData].filter(item => item.product_id !== dispScheduleItem.product_material_id);
            //     setProductTypePropertiesData(updatedMaterialProperties);
            // }
        }
    }

    const renderDetailsTable = useMemo(() => {
        return <>
            <Table className="erp_table erp_table_scroll" id='dispatchDetailsTbl' responsive bordered striped>
                <thead className="erp_table_head">
                    <tr>
                        <th className={`erp_table_th`}>Action</th>
                        <th className={`erp_table_th`}>Sr. No.</th>
                        <th className={`erp_table_th ${rb_dispatch_order_creation_type === 'S' ? 'display' : 'd-none'}`}>Customer Order No</th>
                        <th className="erp_table_th" style={{ width: '100px', paddingRight: '100px' }}>Product Name</th>
                        <th className={`erp_table_th ${rb_dispatch_order_creation_type === 'S' ? 'display' : 'd-none'}`} style={{ width: '100px', paddingRight: '50px' }}>Set No.</th>
                        <th className={`erp_table_th ${rb_dispatch_order_creation_type === 'S' ? 'display' : 'd-none'}`} style={{ width: '100px', paddingRight: '50px' }}>Roll No.</th>
                        <th className={`erp_table_th ${rb_dispatch_order_creation_type === 'S' ? 'display' : 'd-none'}`}>Order Qty.</th>
                        <th className={`erp_table_th ${rb_dispatch_order_creation_type === 'S' ? 'display' : 'd-none'}`}>Prev. Disp. <br /> Advisory Qty.</th>
                        <th className="erp_table_th">Stock Qty.</th>
                        <th className="erp_table_th">Stock Wt.</th>
                        <th className="erp_table_th">Sch.  Disp. Qty.</th>
                        <th className="erp_table_th">Sch. Disp. Wt.</th>
                        <th className="erp_table_th">Actual Disp. Qty.</th>
                        <th className="erp_table_th">Actual Disp. Wt.</th>
                        <th className="erp_table_th" style={{ paddingRight: '60px' }}>Item Status</th>
                        <th className="erp_table_th">Disp. Sch. Remark</th>
                        <th className="erp_table_th" style={{ width: '100px', paddingRight: '10px' }}>Technical Specification</th>
                        <th className="erp_table_th">Material Batch No</th>


                        {/* <th className={`erp_table_th`}>Product Type</th> */}
                        <th className={`erp_table_th ${rb_dispatch_order_creation_type === 'S' ? 'display' : 'd-none'} d-none`}>SoSr No</th>
                        <th className="erp_table_th d-none">Material Our Part Code</th>
                        <th className="erp_table_th d-none">Disp. Return Qty.</th>
                        <th className="erp_table_th d-none">Disp. Return Wt.</th>
                        <th className="erp_table_th d-none">Pending Qty.</th>
                        <th className="erp_table_th d-none">Pending Wt.</th>
                        <th className="erp_table_th d-none">Invoice Qty.</th>
                        <th className="erp_table_th d-none">Invoice Wt.</th>
                        <th className={`erp_table_th ${rb_dispatch_order_creation_type === 'S' ? 'display' : 'd-none'} d-none`}>Sch. Date</th>
                        <th className="erp_table_th d-none">Delayed days</th>
                        <th className="erp_table_th d-none">Std. Wt.</th>
                        <th className="erp_table_th d-none">Stock Wt.</th>
                        <th className={`erp_table_th ${rb_dispatch_order_creation_type === 'S' ? 'display' : 'd-none'} d-none`}>Prev. Disp. Advisory Wt.</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        dispatchDetailsData.map((dispatchItem, Index) => {
                            return <tr rowindex={Index} materialIds={rb_dispatch_order_creation_type === 'S' ? dispatchItem.sales_order_details_transaction_id : dispatchItem.product_material_id}>
                                {rb_dispatch_order_creation_type === 'S' ? <>
                                    <td className='erp_table_td '>
                                        <div className="d-flex">
                                            <input type="checkbox" className="checkdispatchnoteDetails" name="checkDispatch"
                                                id={`checkdispatchnoteDetails_${dispatchItem.sales_order_details_transaction_id}`} value={dispatchItem.sales_order_details_transaction_id}
                                                custOrdNo={dispatchItem.customer_order_no} matId={dispatchItem.product_material_id}
                                                dispDtlId={dispatchItem.dispatch_schedule_details_transaction_id}
                                                onChange={(e) => { partiallySelectedDispatch(); totalDispatchQuantity(); materialSelection(e, inspectionDetailsList, productTypePropertiesData, dispatchItem) }} disabled={isApprove || keyForViewUpdate === 'view' ? true : false} />

                                            {/* <Tooltip title="Inspection Details" placement="top">
                                                <AiOutlineSchedule className="erp-schedule-btn" onClick={() => openInspectionModal(dispatchItem)} />
                                            </Tooltip> */}

                                            {!['approve', 'view'].includes(keyForViewUpdate) && dispatchItem.dispatch_schedule_details_transaction_id === 0 ?
                                                <Tooltip title="Inspection Details" placement="top">
                                                    <AiOutlineSchedule className="erp-schedule-btn" onClick={() => openInspectionModal(dispatchItem)} />
                                                </Tooltip>
                                                : null}
                                        </div>
                                    </td>
                                </>
                                    :
                                    <td className='erp_table_td'>
                                        <div className="d-flex"> <MdDelete className="erp-delete-btn" onClick={() => removeMaterial(dispatchItem.product_material_id)} /> </div>
                                    </td>
                                }

                                <td className="erp_table_td">{Index + 1}</td>
                                <td className={`erp_table_td ${rb_dispatch_order_creation_type === 'S' ? 'display' : 'd-none'}`}>{dispatchItem.customer_order_no}</td>
                                <td className={`erp_table_td`} style={{ width: '150px', display: 'table-cell', whiteSpace: 'break-spaces' }}>{dispatchItem.product_material_print_name}</td>
                                <td className={`erp_table_td ${rb_dispatch_order_creation_type === 'S' ? 'display' : 'd-none'} `} style={{ width: '150px', display: 'table-cell', whiteSpace: 'break-spaces' }}>{dispatchItem.set_no}</td>
                                <td className={`erp_table_td ${rb_dispatch_order_creation_type === 'S' ? 'display' : 'd-none'}`} style={{ width: '150px', display: 'table-cell', whiteSpace: 'break-spaces' }}>{dispatchItem.roll_no}</td>
                                <td className={`erp_table_td text-end ${rb_dispatch_order_creation_type === 'S' ? 'display' : 'd-none'}`}>{dispatchItem.material_quantity}</td>
                                <td className={`erp_table_td text-end ${rb_dispatch_order_creation_type === 'S' ? 'display' : 'd-none'}`}>{dispatchItem.prev_disp_note_qty}</td>
                                <td className="erp_table_td text-end">{dispatchItem.stock_quantity}</td>
                                <td className="erp_table_td text-end">{dispatchItem.stock_weight}</td>
                                <td className="erp_table_td text-end">
                                    {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? dispatchItem.expected_dispatch_quantity :
                                        <input type="text"
                                            id={`expected_dispatch_quantity_${rb_dispatch_order_creation_type === 'S' ? dispatchItem.sales_order_details_transaction_id : dispatchItem.product_material_id}`}
                                            Headers='expected_dispatch_quantity' className="erp_input_field mb-0 text-end" value={dispatchItem.expected_dispatch_quantity}
                                            onChange={(e) => {
                                                if (validateNumberDateInput.current.isInteger(e.target.value)) { updateDispatchDetailsTblRow(dispatchItem, e); }
                                            }}
                                            onBlur={(e) => { updateDispatchDetailsTblRow(dispatchItem, e); }}
                                            disabled={isApprove || keyForViewUpdate === 'view' ? true : false} />
                                    }
                                </td>
                                <td className="erp_table_td text-end">
                                    {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? dispatchItem.expected_dispatch_weight :
                                        <input type="text"
                                            id={`expected_dispatch_weight_${rb_dispatch_order_creation_type === 'S' ? dispatchItem.sales_order_details_transaction_id : dispatchItem.product_material_id}`}
                                            Headers='expected_dispatch_weight' className="erp_input_field mb-0 text-end" value={dispatchItem.expected_dispatch_weight}
                                            onChange={(e) => { updateDispatchDetailsTblRow(dispatchItem, e); }}
                                            onBlur={(e) => { updateDispatchDetailsTblRow(dispatchItem, e); }}
                                            disabled={isApprove || keyForViewUpdate === 'view' ? true : false} />
                                    }
                                </td>
                                <td className="erp_table_td text-end">
                                    {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? dispatchItem.actual_dispatch_quantity :
                                        <input type="text"
                                            id={`actual_dispatch_quantity_${rb_dispatch_order_creation_type === 'S' ? dispatchItem.sales_order_details_transaction_id : dispatchItem.product_material_id}`}
                                            Headers='actual_dispatch_quantity' className="erp_input_field mb-0 text-end" value={dispatchItem.actual_dispatch_quantity}
                                            onChange={(e) => {
                                                if (validateNumberDateInput.current.isInteger(e.target.value)) { updateDispatchDetailsTblRow(dispatchItem, e); }
                                            }}
                                            onBlur={(e) => { updateDispatchDetailsTblRow(dispatchItem, e); }}
                                            disabled={isApprove || keyForViewUpdate === 'view' ? true : false}
                                        />
                                    }
                                </td>
                                <td className="erp_table_td text-end">
                                    {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? dispatchItem.actual_dispatch_weight :
                                        <input type="text" id={`actual_dispatch_weight_${rb_dispatch_order_creation_type === 'S' ? dispatchItem.sales_order_details_transaction_id : dispatchItem.product_material_id}`}
                                            Headers='actual_dispatch_weight' className="erp_input_field mb-0 text-end" value={dispatchItem.actual_dispatch_weight}
                                            onChange={(e) => { updateDispatchDetailsTblRow(dispatchItem, e); }}
                                            onBlur={(e) => { updateDispatchDetailsTblRow(dispatchItem, e); }}
                                            disabled={isApprove || keyForViewUpdate === 'view' ? true : false}
                                        />
                                    }
                                </td>
                                <td className="erp_table_td">
                                    <select className="form-select form-select-sm mb-0" value={dispatchItem.dispatch_schedule_item_status}
                                        onChange={(e) => { updateDispatchDetailsTblRow(dispatchItem, e); }} disabled={isApprove ? false : true}
                                        Headers='dispatch_schedule_item_status'>
                                        <option value="P" >Pending</option>
                                        <option value="A" >Approved</option>
                                        <option value="R" >Rejected</option>
                                        <option value="I" >Partial Sales Issue</option>
                                        <option value="C" >Completed</option>
                                        <option value="X" >Canceled</option>
                                        <option value="D" >Dispath Challan Created</option>
                                    </select>
                                </td>
                                <td className="erp_table_td">
                                    {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                        ? dispatchItem.dispatch_schedule_remark
                                        : <input type="text" className="erp_input_field mb-0"
                                            Headers='dispatch_schedule_remark' value={dispatchItem.dispatch_schedule_remark}
                                            onChange={(e) => { updateDispatchDetailsTblRow(dispatchItem, e); }} />
                                    }
                                </td>
                                <td className="erp_table_td" style={{ width: '150px', display: 'table-cell', whiteSpace: 'break-spaces' }}>{dispatchItem.product_material_tech_spect}</td>
                                <td className="erp_table_td">
                                    {keyForViewUpdate === 'view' || keyForViewUpdate === 'approve'
                                        ? dispatchItem.batch_no
                                        : <input type="text"
                                            id={`batch_no_${rb_dispatch_order_creation_type === 'S' ? dispatchItem.sales_order_details_transaction_id : dispatchItem.product_material_id}`}
                                            Headers='batch_no' className="erp_input_field_table_txt  mb-0"
                                            value={dispatchItem.batch_no === undefined ? dispatchItem.batch_no = `${FINANCIAL_SHORT_NAME}/${txt_dispatch_schedule_no}/${today()} ` : dispatchItem.batch_no}
                                            onChange={(e) => { updateDispatchDetailsTblRow(dispatchItem, e); }} />
                                    }
                                </td>


                                {/* <td className="erp_table_td">{dispatchItem.product_type}</td> */}
                                <td className={`erp_table_td text-end ${rb_dispatch_order_creation_type === 'S' ? 'display' : 'd-none'} d-none`}>{rb_dispatch_order_creation_type === 'S' ? dispatchItem.so_sr_no : null}</td>
                                <td className="erp_table_td d-none">{dispatchItem.product_material_our_part_code}</td>
                                <td className="erp_table_td text-end d-none">{dispatchItem.dispatch_return_quantity}</td>
                                <td className="erp_table_td text-end d-none">{dispatchItem.dispatch_return_weight}</td>
                                <td className="erp_table_td text-end d-none">{dispatchItem.pending_quantity}</td>
                                <td className="erp_table_td text-end d-none">{dispatchItem.pending_weight}</td>
                                <td className="erp_table_td text-end d-none">{dispatchItem.invoice_quantity}</td>
                                <td className="erp_table_td text-end d-none">{dispatchItem.invoice_weight}</td>
                                <td className={`erp_table_td ${rb_dispatch_order_creation_type === 'S' ? 'display' : 'd-none'} d-none`}>{rb_dispatch_order_creation_type === 'S' ? validateNumberDateInput.current.formatDateToDDMMYYYY(dispatchItem.material_schedule_date) : null}</td>
                                <td className="erp_table_td text-end d-none">{dispatchItem.delayed_days}</td>


                                <td className="erp_table_td text-end d-none">{dispatchItem.product_material_std_weight}</td>
                                <td className="erp_table_td text-end d-none">{dispatchItem.stock_weight}</td>
                                <td className={`erp_table_td text-end ${rb_dispatch_order_creation_type === 'S' ? 'display' : 'd-none'} d-none`}>{dispatchItem.prev_disp_note_wt}</td>
                                {/* <td className={`erp_table_td text-end ${rb_dispatch_order_creation_type === 'S' ? 'display' : 'd-none'}`}>{dispatchItem.dispatched_weight}</td> */}
                            </tr>
                        }
                        )
                    }
                </tbody>
            </Table>
        </>
    }, [dispatchDetailsData, rb_dispatch_order_creation_type, isApprove, productTypePropertiesData, inspectionDetailsList, inspectionSummatryList])


    // ********************************************* Dispatch Schedule Document Upload Functionality Starts. *****************************************************
    function FnCheckBoxesSelection(key) {
        switch (key) {
            // For customer contact
            case 'selectAllCustContact':
                $('.selectCustContactPerson').prop('checked', $('#selectAllCustContact').is(":checked"));
                if ($('input:checkbox.selectCustContactPerson:checked').length === 0) {
                    $('#error_customer_contact_persons').text('Please select atleast one contact person.');
                    $('#error_customer_contact_persons').show();
                } else {
                    $('#error_customer_contact_persons').hide();
                }
                break;
            case 'PartiallyCustContactSelection':
                $('#selectAllCustContact').prop('checked', $('input:checkbox.selectCustContactPerson:checked').length === $('input:checkbox.selectCustContactPerson').length);
                if ($('input:checkbox.selectCustContactPerson:checked').length === 0) {
                    $('#error_customer_contact_persons').text('Please select atleast one contact person.');
                    $('#error_customer_contact_persons').show();
                } else {
                    $('#error_customer_contact_persons').hide();
                }
                break;
            case 'selectAllPackingRecords':
                $('.selectInspectionRecord').prop('checked', $('#selectAllPackingRecords').is(":checked"));
                break;
            case 'PartiallyselectPackingRecord':
                $('#selectAllPackingRecords').prop('checked', $('input:checkbox.selectInspectionRecord:checked').length === $('input:checkbox.selectInspectionRecord').length);
                break;
            default:
                break;
        }
    }

    const FnLoadAccordionData = async (eventKey) => {
        setIsLoading(true)
        let checkedLength = 0;
        switch (eventKey) {
            case 'documentList':
                await showDocumentRecords();
                break;

            default:
                break;
        }
        setIsLoading(false)
    }

    const excludedcustFields = ['is_delete', 'is_active', 'created_on', 'created_by', 'modified_on', 'modified_by', 'deleted_by',
        'deleted_on', 'document_id', 'company_id'];

    const showDocumentRecords = async () => {
        try {
            if (COMPANY_ID !== null) {
                const res = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/api/Documents/FnShowParticularRecord?document_group=${docGroup}&group_id=${txt_dispatch_schedule_no !== 0 ? txt_dispatch_schedule_no.replaceAll('/', '_') : null}`
                );
                const resp = await res.json();
                if (resp.content.length > 0) {
                    setUploadedDocData(resp.content);
                } else {
                    setUploadedDocData([]);
                }
            }
        } catch (error) {
            console.log("error: ", error)
        }
    }

    const renderDocumentTable = useMemo(() => {
        return <>
            <Table className="erp_table" id='document-table' bordered striped>
                <thead className="erp_table_head">
                    <tr>
                        <th className="erp_table_th">Group Id</th>
                        <th className="erp_table_th">Document Group</th>
                        <th className="erp_table_th">Document Name</th>
                        <th className="erp_table_th">Registration No</th>
                        <th className="erp_table_th">Registration Date</th>
                        <th className="erp_table_th">Renewal Date</th>
                        <th className="erp_table_th">Document Path</th>
                        <th className="erp_table_th">Remark</th>
                        <th className="erp_table_th">File Name</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        uploadedDocData.map((docItem, Index) =>
                            <tr rowIndex={Index}>
                                <td className="erp_table_td"> {docItem.group_id} </td>
                                <td className="erp_table_td">{docItem.document_group}</td>
                                <td className="erp_table_td">{docItem.document_name}</td>
                                <td className="erp_table_td">{docItem.document_registration_no}</td>
                                <td className="erp_table_td">{docItem.document_registration_date}</td>
                                <td className="erp_table_td">{docItem.document_renewal_date}</td>
                                <td className="erp_table_td">{docItem.document_path}</td>
                                <td className="erp_table_td">{docItem.remark}</td>
                                <td className="erp_table_td">
                                    <MDTypography component="label" className="erp-form-label" variant="button" id="logoFile" fontWeight="regular" color="info" >
                                        <Link to="#" onClick={() => { fetchDocument(docItem) }}> {docItem.file_name} </Link>
                                    </MDTypography>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </>
    }, [uploadedDocData]);

    const fetchDocument = async (docItem) => {
        try {
            const formData = new FormData()
            const data = {
                document_group: docItem.documentGroup,
                group_id: docItem.group_id,
                document_path: docItem.document_path
            }
            formData.append(`getFile`, JSON.stringify(data))
            const requestOptions = { method: 'POST', body: formData };
            const getDocApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/Documents/FnGetDocument`, requestOptions)
            const blob = await getDocApiCall.blob()

            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${docItem.file_name}`,);
            document.body.appendChild(link);

            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            console.log("error: ", error)
        }
    }
    // ********************************************* Dispatch Schedule Document Upload Functionality Ends. *****************************************************

    // --------------------------------------- Delete the transaction functionality starts.------------------------------------------------------------------
    const deleteRecords = async () => {
        try {
            const method = { method: 'DELETE' }
            const deleteApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtDispatchScheduleDetails/FnDeleteRecord/${idList.dispatch_schedule_version}/${COMPANY_ID}/${UserName}?dispatch_schedule_no=${idList.dispatch_schedule_no}`, method)
            const responce = await deleteApiCall.json();
            if (responce.success == '1') {
                setShowDeleteModal(false)
                moveToListing();
            }
        } catch (error) {
            console.log("error: ", error)
        }
    }
    // --------------------------------------- Delete the transaction functionality ends.------------------------------------------------------------------


    // Invoice Printing 
    const dispatchNoteContent = {
        company: {
            company: '',
            company_branch: '',
        },
        customerDetails: {
            customer: '',
            address: '',
            state: '',
            contact: '',
            customer_email: '',
            customer_city: ''
        },
        consigneeDetails: {
            consignee: '',
            address: '',
            state: '',
            contact: '',
            consignee_email: '',
            consignee_city: ''
        },
        dispatchDetails: {
            dispatch_schedule_no: '',
            dispatch_schedule_date: '',
            dispatch_schedule_version: '',
            dispatch_schedule_creation_type_desc: '',
            dispatch_schedule_type: '',
        },
        gatePassDetails: {
            gate_pass_no: '',
            gate_pass_date: '',
        },
        vehicalDetails: {
            vehicale_no: '',
            net_weight: '',
            seal_no: '',
            driver_name: '',
            driver_contact_no: '',
            count_no: '',
        },
        footer: {
            approved_by: '',
            supervisor: '',

            total_qty: '',
            total_weight: '',
            actual_qty: '',
            actual_weight: '',
            other_terms_conditions: ''
        },
        loadingDetails: {
            date: '',
            vehicleNo: '',
            vehicleReportingTime: '',
            vehicleLoadingStartTime: '',
            vehicleLoadingFinishTime: '',
            customerName: '',
            countNo: '',
            lotNo: '',
            driverName: '',
            driverLicenceName: '',
            gatePassNo: '',
            fromCarton: '',
            toCarton: '',
            grossWeight: '',
            tareWeight: '',
            netWeight: '',
        },

        items: [],
        contactDetails: [],
        packingDetailsForLoadingSlip: [],

    };

    const printInvoice = async (openPrintModal) => {
        setIsLoading(true);

        // Here this api is required because on every save it requires the print-out. 
        const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtDispatchScheduleDetails/FnShowAllDetailsAndMastermodelRecords/${dispatch_schedule_version}/${FINANCIAL_SHORT_NAME}/${COMPANY_ID}?dispatch_schedule_no=${txt_dispatch_schedule_no}`)
        // const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtDispatchScheduleDetails/FnShowAllDetailsAndMastermodelRecords/${idList.dispatch_schedule_version}/${idList.financial_year}/${COMPANY_ID}?dispatch_schedule_no=${idList.dispatch_schedule_no}`)
        const response = await apiCall.json();
        let dispatchMasterData = response.DispatchScheduleMasterRecord;
        let dispatchDetailsData = response.DispatchScheduleDetailsRecords;

        // DispatchMasterJson
        dispatchNoteContent.company.company = dispatchMasterData.company_name
        dispatchNoteContent.company.company_branch = dispatchMasterData.company_branch_name

        dispatchNoteContent.customerDetails.customer = dispatchMasterData.customer_name
        dispatchNoteContent.customerDetails.address = dispatchMasterData.cust_branch_address1
        dispatchNoteContent.customerDetails.state = dispatchMasterData.customer_state_name
        dispatchNoteContent.customerDetails.contact = dispatchMasterData.customer_phone
        dispatchNoteContent.customerDetails.customer_email = dispatchMasterData.customer_email
        dispatchNoteContent.customerDetails.customer_city = dispatchMasterData.customer_city_name

        dispatchNoteContent.consigneeDetails.consignee = dispatchMasterData.consignee_name
        dispatchNoteContent.consigneeDetails.address = dispatchMasterData.consignee_address
        dispatchNoteContent.consigneeDetails.state = dispatchMasterData.consignee_state_name
        dispatchNoteContent.consigneeDetails.consignee_email = dispatchMasterData.consignee_email
        dispatchNoteContent.consigneeDetails.consignee_city = dispatchMasterData.consignee_city_name

        dispatchNoteContent.dispatchDetails.dispatch_schedule_no = dispatchMasterData.dispatch_schedule_no
        dispatchNoteContent.dispatchDetails.dispatch_schedule_date = validateNumberDateInput.current.formatDateToDDMMYYYY(dispatchMasterData.dispatch_schedule_date)
        dispatchNoteContent.dispatchDetails.dispatch_schedule_version = dispatchMasterData.dispatch_schedule_version
        dispatchNoteContent.dispatchDetails.dispatch_schedule_creation_type_desc = dispatchMasterData.dispatch_schedule_creation_type_desc

        dispatchNoteContent.footer.approved_by = dispatchMasterData.approved_by_name
        dispatchNoteContent.footer.supervisor = dispatchMasterData.dispatch_supervisor_name
        dispatchNoteContent.footer.other_terms_conditions = dispatchMasterData.other_terms_conditions
        dispatchNoteContent.footer.dispatch_note_remark = dispatchMasterData.dispatch_note_remark

        dispatchNoteContent.footer.total_qty = dispatchMasterData.total_quantity
        dispatchNoteContent.footer.total_weight = dispatchMasterData.total_weight
        dispatchNoteContent.footer.actual_qty = dispatchMasterData.actual_quantity
        dispatchNoteContent.footer.actual_weight = dispatchMasterData.actual_weight


        for (let index = 0; index < dispatchDetailsData.length; index++) {
            const element = dispatchDetailsData[index];

            const detailsData = {
                // sr_no: element.sr_no,
                sr_no: index + 1,
                customer_order_no: element.customer_order_no,
                product_material_print_name: element.product_material_print_name,
                product_material_tech_spect: element.product_material_tech_spect,
                stock_quantity: element.stock_quantity,
                expected_dispatch_quantity: element.expected_dispatch_quantity,
                expected_dispatch_weight: element.expected_dispatch_weight,
                actual_dispatch_quantity: element.actual_dispatch_quantity,
                actual_dispatch_weight: element.actual_dispatch_weight,
                product_material_stock_unit_name: element.product_material_stock_unit_name,
                location: element.location,
                so_sr_no: element.so_sr_no,
                expected_schedule_date: validateNumberDateInput.current.formatDateToDDMMYYYY(element.expected_schedule_date)
            }
            dispatchNoteContent.items.push(detailsData)
        }

        const checkboxes = $('.selectCustContactPerson:checked');
        checkboxes.each(function (index) {
            if (index === 0) {
                let selectedContact_id = parseInt($(this).attr('contact_personId'));
                const customerContactDetails = custContactDetails.find(contactPerson => contactPerson.customer_contact_id === selectedContact_id);
                if (customerContactDetails) {
                    const contactdata = {
                        cust_contact_person: customerContactDetails.cust_contact_person,
                        cust_contact_no: customerContactDetails.cust_contact_no,
                    }
                    dispatchNoteContent.contactDetails.push(contactdata);
                }
            }
        });

        if (openPrintModal) {
            let redirectTo = compType === 'Register' ? '/Transactions/TDispatchNote/TFabricDispatchNoteEntry/reg' : `/Transactions/TDispatchNote/TFabricDispatchNoteEntry`
            navigate('/Invoice', { state: { invoiceData: dispatchNoteContent, navigationLink: redirectTo, idList: idList, keyForViewUpdate: keyForViewUpdate, invoiceType: 'DN', title: 'Trading Dispatch Advisory', compType: compType } });
        }
        setIsLoading(false);
    };

    //*****************************************************************************Start Get Pass slip Print*****************************8*********************************************/

    const printGatePassSlip = async (openGatePassSlip) => {
        setIsLoading(true);
        // Here this api is required because on every save it requires the print-out.
        const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtDispatchScheduleDetails/FnShowAllDetailsAndMastermodelRecords/${idList.dispatch_schedule_version}/${idList.financial_year}/${COMPANY_ID}?dispatch_schedule_no=${idList.dispatch_schedule_no}`)
        const response = await apiCall.json();
        let dispatchMasterData = response.DispatchScheduleMasterRecord;
        let dispatchDetailsData = response.DispatchScheduleDetailsRecords;
        // DispatchMasterJson
        dispatchNoteContent.company.company = dispatchMasterData.company_name
        dispatchNoteContent.company.company_branch = dispatchMasterData.company_branch_name

        // dispatchNoteContent.dispatchDetails.dispatch_schedule_type = requestTypeHeading();
        dispatchNoteContent.dispatchDetails.dispatch_schedule_type = '(Fabric)';

        dispatchNoteContent.customerDetails.customer = dispatchMasterData.customer_name
        dispatchNoteContent.customerDetails.address = dispatchMasterData.cust_branch_address1
        dispatchNoteContent.customerDetails.state = dispatchMasterData.customer_state_name
        dispatchNoteContent.customerDetails.contact = dispatchMasterData.customer_phone
        dispatchNoteContent.customerDetails.customer_email = dispatchMasterData.customer_email
        dispatchNoteContent.customerDetails.customer_city = dispatchMasterData.customer_city_name

        dispatchNoteContent.gatePassDetails.gate_pass_no = txt_gate_pass_no
        dispatchNoteContent.gatePassDetails.gate_pass_date = await validateNumberDateInput.current.formatDateToDDMMYYYY(dt_gate_pass_date)

        dispatchNoteContent.vehicalDetails.vehicale_no = txt_vehicale_no
        dispatchNoteContent.vehicalDetails.net_weight = txt_net_weight
        dispatchNoteContent.vehicalDetails.seal_no = txt_seal_no
        dispatchNoteContent.vehicalDetails.driver_name = txt_driver_name
        dispatchNoteContent.vehicalDetails.driver_contact_no = txt_driver_contact_no

        for (let index = 0; index < dispatchDetailsData.length; index++) {
            const element = dispatchDetailsData[index];
            const detailsData = {
                sr_no: index + 1,
                product_material_print_name: element.product_material_print_name,
                product_material_tech_spect: element.product_material_tech_spect,
                // lot_no: element.lot_no,
                // count_no: element.count_no,
                // sub_lot_no: element.sub_lot_no,
                // packing_quantity: element.packing_quantity,
                exp_qty: element.expected_dispatch_quantity,
                exp_wt: element.expected_dispatch_weight,
                set_no: element.set_no,
                roll_no: element.roll_no,
                unit_name: element.product_material_stock_unit_name,
            }
            dispatchNoteContent.items.push(detailsData)
        }

        const checkboxes = $('.selectCustContactPerson:checked');
        checkboxes.each(function (index) {
            if (index === 0) {
                let selectedContact_id = parseInt($(this).attr('contact_personId'));
                const customerContactDetails = custContactDetails.find(contactPerson => contactPerson.customer_contact_id === selectedContact_id);
                if (customerContactDetails) {
                    const contactdata = {
                        cust_contact_person: customerContactDetails.cust_contact_person,
                        cust_contact_no: customerContactDetails.cust_contact_no,
                    }
                    dispatchNoteContent.contactDetails.push(contactdata);
                }
            }
        });

        if (openGatePassSlip) {
            let redirectTo = compType === 'Register' ? '/Transactions/TDispatchNote/TFabricDispatchNoteEntry/reg' : `/Transactions/TDispatchNote/TFabricDispatchNoteEntry`
            navigate('/Invoice', { state: { invoiceData: dispatchNoteContent, navigationLink: redirectTo, idList: idList, keyForViewUpdate: keyForViewUpdate, invoiceType: 'FBGPS', title: 'Gate Pass Slip', compType: compType, } });
        }
        setIsLoading(false);
    };
    //*****************************************************************************END Get Pass slip Print*****************************8*********************************************/

    //*****************************************************************************Start Loading Slips Print**************************************************************************/
    // const FnFormatDateTime = (dateTimeString) => {
    //     const date = new Date(dateTimeString);
    //     const day = date.getDate().toString().padStart(2, "0");
    //     const month = (date.getMonth() + 1).toString().padStart(2, "0");
    //     const year = date.getFullYear();
    //     const hours = date.getHours() % 12 || 12;
    //     const ampm = date.getHours() >= 12 ? "PM" : "AM";
    //     const minutes = date.getMinutes().toString().padStart(2, "0");
    //     const outputDateString = `${day}/${month}/${year} ${hours}:${minutes} ${ampm}`;
    //     return outputDateString;
    // }

    function FnFormatDateTime(dateString) {
        if (dateString === null || dateString === undefined || dateString === '') { return ''; }

        let dateParts = dateString.split(' ');
        let date = dateParts[0];
        let time = dateParts[1];

        let dateComponents = date.split('-');
        let year = dateComponents[0];
        let month = dateComponents[1];
        let day = dateComponents[2];

        let timeComponents = time.split(':');
        let hour = parseInt(timeComponents[0]);
        let minute = timeComponents[1];
        let second = timeComponents[2];

        let amOrPm = (hour < 12) ? 'AM' : 'PM';
        if (hour == 0) {
            hour = 12;
        } else if (hour > 12) {
            hour -= 12;
        }
        let formattedDate = day + '/' + month + '/' + year;
        let formattedTime = hour + ':' + minute + ':' + second + ' ' + amOrPm;
        return formattedDate + ' ' + formattedTime;
    }

    const printLoadingSlips = async (openPrintModal) => {
        setIsLoading(true);
        try {
            // Here this api is required because on every save it requires the print-out.
            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/MtDispatchScheduleDetails/FnShowAllDetailsAndMastermodelRecords/${idList.dispatch_schedule_version}/${idList.financial_year}/${COMPANY_ID}?dispatch_schedule_no=${idList.dispatch_schedule_no}`)
            const response = await apiCall.json();
            let dispatchMasterData = response.DispatchScheduleMasterRecord;
            let dispatchDetailsData = response.DispatchScheduleDetailsRecords;
            let dispatchPackingDetails = response.DispatchSchedulePackingDetailsRecord;

            // DispatchMasterJson
            dispatchNoteContent.company.company = dispatchMasterData.company_name
            dispatchNoteContent.company.company_branch = dispatchMasterData.company_branch_name

            dispatchNoteContent.loadingDetails.date = await validateNumberDateInput.current.formatDateToDDMMYYYY(txt_dispatch_date);
            dispatchNoteContent.loadingDetails.vehicleNo = txt_vehicale_no;
            dispatchNoteContent.loadingDetails.vehicleReportingTime = FnFormatDateTime(dispatchMasterData.vehicle_reporting_time);
            dispatchNoteContent.loadingDetails.vehicleLoadingStartTime = FnFormatDateTime(dispatchMasterData.vehicle_loading_started_time);
            dispatchNoteContent.loadingDetails.vehicleLoadingFinishTime = FnFormatDateTime(dispatchMasterData.vehicle_loading_finish_time);
            dispatchNoteContent.loadingDetails.customerName = '';
            dispatchNoteContent.loadingDetails.driverName = txt_driver_name;
            dispatchNoteContent.loadingDetails.driverLicenceName = '';
            dispatchNoteContent.loadingDetails.gatePassNo = txt_gate_pass_no;

            dispatchDetailsData.forEach(material => {
                let selectedMaterialPkgDtl = dispatchPackingDetails.find((pkgMtrl) => pkgMtrl.sales_order_details_transaction_id === material.sales_order_details_transaction_id);
                dispatchNoteContent.packingDetailsForLoadingSlip.push({
                    pkgStartNo: selectedMaterialPkgDtl.packing_starting_no,
                    pkgEndNo: selectedMaterialPkgDtl.packing_ending_no,
                    lotNo: selectedMaterialPkgDtl.lot_no,
                    countNo: selectedMaterialPkgDtl.production_count_name,
                    grossWeight: selectedMaterialPkgDtl.packing_gross_weight,
                    netWeight: selectedMaterialPkgDtl.packing_net_weight,
                    tareWeight: 0,
                });
            });

            if (openPrintModal) {
                let redirectTo = compType === 'Register' ? '/Transactions/TDispatchNote/TFabricDispatchNoteEntry/reg' : `/Transactions/TDispatchNote/TFabricDispatchNoteEntry`
                navigate('/Invoice', { state: { invoiceData: dispatchNoteContent, navigationLink: redirectTo, idList: idList, keyForViewUpdate: keyForViewUpdate, invoiceType: 'YVLS', title: 'Vehicle Loading Slip', compType: compType, } });
            }
            setIsLoading(false);

        } catch (error) {
            console.log("error: ", error)
            navigate('/Error')
        }
    }
    //*****************************************************************************END Loading Slips Print**************************************************************************/

    const productPropertiesAccordian = () => {
        return <>
            <hr />
            <Accordion defaultActiveKey="0" >
                <Accordion.Item eventKey='1'>
                    <Accordion.Header className="erp-form-label-md">Product Based Properties</Accordion.Header>
                    <Accordion.Body className="">
                        {
                            productTypePropertiesData.length !== 0
                                ? <>
                                    <div className="row">
                                        <div className={`table-responsive ${productTypePropertiesData.length > 0 ? 'erp_table_scroll' : ''}`}>
                                            <>{renderProductPropertiesTbl}</>
                                        </div>
                                    </div>
                                </>
                                : <>
                                    <div className='row text-center'>
                                        <div className="col-12">
                                            <span className="erp_validation text-center" fontWeight="regular" color="error">
                                                No Records Found...!
                                            </span>
                                        </div>
                                    </div>
                                </>
                        }
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    }
    const renderInspectionDetailsAccordion = () => {
        return inspectionDetailsList.length !== 0
            ? <>
                <hr />
                <Accordion defaultActiveKey="0" >
                    <Accordion.Item eventKey={`0`}>
                        <Accordion.Header className="erp-form-label-md">Inspection Details</Accordion.Header>
                        <Accordion.Body className="">
                            <div className="row">
                                <div className={`table-responsive ${inspectionDetailsList.length > 0 ? 'erp_table_scroll' : ''}`}>
                                    <>{renderInspectionDetailsTbl}</>
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </>
            : null
    }
    const renderGatePassAccordian = () => {
        return <>
            <hr />
            <Accordion defaultActiveKey="1" >
                <Accordion.Item eventKey="0">
                    <Accordion.Header className="erp-form-label-md">Gate Pass Details</Accordion.Header>
                    <Accordion.Body className="p-0">
                        <div className='p-1'>
                            <form id="GatePassFrmId">
                                <div className="row p-1">
                                    {/* //first column */}
                                    <div className="col-sm-4 erp_form_col_div">
                                        <div className={`row`}>
                                            <div className="col-sm-4">
                                                <Form.Label className="erp-form-label">Gate Pass No</Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_gate_pass_no' className="erp_input_field" value={txt_gate_pass_no} onChange={e => { setGatePassNo(e.target.value); validateFields(); }} optional='optional' />
                                                <MDTypography variant="button" id="error_txt_gate_pass_no" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                        <div className={`row`}>
                                            <div className="col-sm-4">
                                                <Form.Label className="erp-form-label">Gate Pass Date</Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="date" id='dt_gate_pass_date' className="erp_input_field" value={dt_gate_pass_date} onChange={e => { setGatePassDate(e.target.value); validateFields(); }} min={currentDate} optional='optional' />
                                                <MDTypography variant="button" id="error_dt_gate_pass_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                        <div className={`row`}>
                                            <div className="col-sm-4">
                                                <Form.Label className="erp-form-label">Vehicle No </Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_vehicale_no' className="erp_input_field" value={txt_vehicale_no} onChange={e => { setVehicaleNo(e.target.value); validateFields(); }} optional='optional' />
                                                <MDTypography variant="button" id="error_txt_vehicale_no" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                        <div className={`row`}>
                                            <div className="col-sm-4">
                                                <Form.Label className="erp-form-label">Container No </Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_container_no' className="erp_input_field" value={txt_container_no} onChange={e => { setContainerNo(e.target.value); validateFields(); }} optional='optional' />
                                                <MDTypography variant="button" id="error_txt_container_no" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>

                                    </div>
                                    {/* //second column */}
                                    <div className="col-sm-4 erp_form_col_div">
                                        <div className={`row`}>
                                            <div className="col-sm-4">
                                                <Form.Label className="erp-form-label">Seal No </Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_seal_no' className="erp_input_field" value={txt_seal_no} onChange={e => { setSealNo(e.target.value); validateFields(); }} optional='optional' />
                                                <MDTypography variant="button" id="error_txt_seal_no" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                        <div className={`row d-none`}>
                                            <div className="col-sm-4">
                                                <Form.Label className="erp-form-label">Net Weight  (Kgs)</Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_net_weight' className="erp_input_field" value={txt_net_weight} onChange={e => { setNetWeight(e.target.value); validateFields(); }} optional='optional' />
                                                <MDTypography variant="button" id="error_txt_net_weight" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                        <div className={`row`}>
                                            <div className="col-sm-4">
                                                <Form.Label className="erp-form-label">Driver Name</Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_driver_name' className="erp_input_field" value={txt_driver_name} onChange={e => { setDriverName(e.target.value); validateFields(); }} optional='optional' />
                                                <MDTypography variant="button" id="error_txt_driver_name" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                        <div className={`row`}>
                                            <div className="col-sm-4">
                                                <Form.Label className="erp-form-label">Driver Contact No</Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="text" id='txt_driver_contact_no' className="erp_input_field" value={txt_driver_contact_no} onChange={e => { setDriverContactNo(e.target.value); validateFields(); }} optional='optional' maxlength={10} />
                                                <MDTypography variant="button" id="error_txt_driver_contact_no" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                    {/* //Third column */}
                                    <div className="col-sm-4 erp_form_col_div">
                                        <div className={`row`}>
                                            <div className="col-sm-4">
                                                <Form.Label className="erp-form-label">Vehicle Reporting Time </Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="datetime-local" id='dt_vehicle_reporting_time' className="erp_input_field" value={dt_vehicle_reporting_time} onChange={e => { setVehicleReportingTime(e.target.value); validateFields(); }} optional='optional' />
                                                <MDTypography variant="button" id="error_dt_vehicle_reporting_time" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>
                                        </div>
                                        <div className={`row`}>
                                            <div className="col-sm-4">
                                                <Form.Label className="erp-form-label">Loading Start Time </Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="datetime-local" id='dt_vehicle_loading_started_time' className="erp_input_field" value={dt_vehicle_loading_started_time} onChange={e => { setVehicleLoadingStartedTime(e.target.value); validateFields(); }} optional='optional' />
                                                <MDTypography variant="button" id="error_dt_vehicle_loading_started_time" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>
                                        </div>
                                        <div className={`row`}>
                                            <div className="col-sm-4">
                                                <Form.Label className="erp-form-label">Loading Finish Time </Form.Label>
                                            </div>
                                            <div className="col">
                                                <Form.Control type="datetime-local" id='dt_vehicle_loading_finish_time' className="erp_input_field" value={dt_vehicle_loading_finish_time} onChange={e => { setVehicleLoadingFinishTime(e.target.value); validateFields(); }} optional='optional' />
                                                <MDTypography variant="button" id="error_dt_vehicle_loading_finish_time" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    }

    const renderDispatchDetailsAccordian = () => {
        return <>
            <hr />
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header className="erp-form-label-md">Dispatch Advisory Details</Accordion.Header>
                    <Accordion.Body>
                        {rb_dispatch_order_creation_type === 'M' && keyForViewUpdate !== 'view' && keyForViewUpdate !== 'approve'
                            ?
                            <div className="row">
                                <div className="col-sm-3">
                                    <SearchItemFilter getSearchData={FnCloseBomFilter} formIdForValidate="dispatchnoteId" requiredCols={colNames} />
                                </div>

                                <div className="col-md-2 col-12">
                                    <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" onClick={viewBomFilterForm} >Add Materials</MDButton>
                                </div>
                            </div>
                            : null
                        }

                        {dispatchDetailsData.length !== 0 && (
                            <>
                                {rb_dispatch_order_creation_type === 'S' ?
                                    <div className="col pt-sm-1">
                                        <input type='checkbox' id="checkdispatchnoteDetails" onChange={() => toggleDispatchDetailsChkBoxes()} disabled={isApprove || keyForViewUpdate === 'view' ? true : false} />
                                        <label className="erp-form-label pb-1 ms-1"> Select All </label>
                                    </div> : null}
                                <div className='row mt-1'>
                                    {renderDetailsTable}
                                </div>

                            </>
                        )}
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion >
        </>
    }

    //Fn to render propertie details of material  table
    const renderProductPropertiesTbl = useMemo(() => {
        return <>
            <Table className="erp_table " id='productProperties-table-id' bordered striped>
                <thead className="erp_table_head">
                    <tr>
                        <th className='erp_table_th'>Sr. No.</th>
                        <th className='erp_table_th'>Material Name</th>
                        <th className="erp_table_th">Parameter Name</th>
                        <th className="erp_table_th">Parameter Value</th>
                        <th className="erp_table_th">Prefix</th>
                        <th className="erp_table_th">Sufix</th>
                        <th className="erp_table_th">Calculation Type</th>
                        <th className="erp_table_th">From Value</th>
                        <th className="erp_table_th">To Value</th>
                        <th className="erp_table_th">Actual Value</th>
                        <th className="erp_table_th">Remark</th>
                    </tr>
                </thead>
                <tbody>
                    {productTypePropertiesData.map((filter, index) => (
                        <tr rowIndex={index}>
                            <td className="erp_table_td ">{index + 1}</td>
                            <td className="erp_table_td ">{filter.product_material_name}</td>
                            <td className="erp_table_td ">{filter.product_parameter_name}</td>
                            <td className="erp_table_td ">
                                {filter.newFilter.type === 'C' || filter.newFilter.type === 'P' ? (
                                    <select id={`product_parameter_value_${index}`} className="form-select form-select-sm" value={filter.product_parameter_value} disabled>
                                        <option value=''>Select</option>
                                        {filter.newFilter.dataArray.map((item, index) => (
                                            <option key={index} value={item.field_id}>
                                                {item.field_name}
                                            </option>
                                        ))}
                                    </select>
                                ) : filter.newFilter.type === 'T' ? (<>
                                    {filter.product_parameter_value}
                                </>
                                ) : ""}
                            </td>
                            <td className="erp_table_td "> {filter.product_parameter_prefix}</td>
                            <td className="erp_table_td "> {filter.product_parameter_sufix}</td>
                            <td className="erp_table_td ">
                                <select id={`product_parameter_calculation_type_${index}`} className="form-select form-select-sm"
                                    value={filter.product_parameter_calculation_type} disabled>
                                    <option value=''>Select</option>
                                    <option value='Equals'>Equals</option>
                                    <option value='Less than'>Less than</option>
                                    <option value='Greater than'>Greater than</option>
                                    <option value='Between'>Between</option>
                                </select>
                            </td>
                            <td className="erp_table_td "> {filter.product_parameter_from_value}</td>
                            <td className="erp_table_td  "> {filter.product_parameter_to_value} </td>
                            <td className="erp_table_td  ">
                                <input type="text" className="erp_input_field mb-0 "
                                    Headers="product_parameter_actual_value" id={`product_parameter_actual_value_${index}`} value={filter.product_parameter_actual_value}
                                // onChange={(e) => { updatePropertiesTblRows(filter, e); }} 
                                />
                            </td>
                            <td className="erp_table_td  ">{filter.remark}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    }, [productTypePropertiesData]);

    const fnGetProductProperties = async (getProductProperties) => {
        if (getProductProperties !== null && getProductProperties.length !== 0) {
            const modifyProductPropertiesData = [];
            for (let i = 0; i < getProductProperties.length; i++) {
                const propertiesItem = getProductProperties[i];
                let masterList;
                const initializeKeys = {
                    product_id: propertiesItem.product_id,
                    product_parameter_value: propertiesItem.product_parameter_value,
                    product_parameter_calculation_type: propertiesItem.product_parameter_calculation_type,
                    product_parameter_from_value: propertiesItem.product_parameter_from_value,
                    product_parameter_id: propertiesItem.product_parameter_id,
                    product_parameter_prefix: propertiesItem.product_parameter_prefix,
                    product_parameter_sufix: propertiesItem.product_parameter_sufix,
                    product_parameter_to_value: propertiesItem.product_parameter_to_value,
                    customer_order_no: propertiesItem.customer_order_no,
                    remark: propertiesItem.remark
                }
                const newFilter = {
                    type: propertiesItem.control_type,
                    dataArray: []    // new array for if there is combo 
                };
                if (propertiesItem.control_type === "P") {
                    resetGlobalQuery();
                    globalQuery.columns = ["field_name", "field_id"];
                    // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    globalQuery.conditions.push({ field: "properties_master_name", operator: "=", value: propertiesItem.control_master });
                    globalQuery.orderBy = ["property_id"];
                    globalQuery.table = "amv_properties";
                    masterList = await comboDataFunc.current.fillFiltersCombo(globalQuery)
                    newFilter.dataArray = masterList;
                }
                if (propertiesItem.control_type === "C") {
                    // masterList = await comboDataFunc.current.fillMasterData(propertiesItem.control_master, "", "")|| []; // Ensure it's an array
                    // newFilter.dataArray = masterList;
                    resetGlobalQuery();
                    globalQuery.columns = ["field_name", "field_id"];
                    globalQuery.table = propertiesItem.control_master;
                    masterList = await comboDataFunc.current.fillFiltersCombo(globalQuery)
                    newFilter.dataArray = masterList;
                }
                modifyProductPropertiesData.push({ ...getProductProperties[i], ...initializeKeys, newFilter })
            }
            setProductTypePropertiesData(modifyProductPropertiesData)
            return modifyProductPropertiesData;
        } else {
            setProductTypePropertiesData([])
        }
    }

    // ***************************** Inspection Modal Table Functions starts. ************************************
    const openInspectionModal = (dispatchchallanObj) => {
        setCurrentInspectionMtrlForModal(dispatchchallanObj);
        loadInspectionList(dispatchchallanObj);
    }

    const handleCloseInspectionSummaryModal = () => {
        setShowInspectionDetailsModal(false)
        setInspectionSummaryList([]);
        setCurrentInspectionMtrlForModal(null);
    };

    // const loadInspectionList = async (dispatchchallanObj) => {
    //     setShowInspectionDetailsModal(true);

    //     let alreadySelectedInspectionDtls = [];
    //     let existingInspectionDtls = [...inspectionDetailsList]
    //     if (existingInspectionDtls.length !== 0) {
    //         alreadySelectedInspectionDtls = existingInspectionDtls.filter(item => {
    //             return item.product_material_id === dispatchchallanObj.product_material_id
    //                 && item.customer_order_no === dispatchchallanObj.customer_order_no
    //         });
    //     }

    //     resetGlobalQuery();
    //     globalQuery.columns = ['*'];
    //     globalQuery.table = "xtv_weaving_production_inspection_details";
    //     globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
    //     globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
    //     globalQuery.conditions.push({ field: "product_material_id", operator: "=", value: dispatchchallanObj.product_material_id });
    //     globalQuery.conditions.push({ field: "sales_order_no", operator: "=", value: dispatchchallanObj.sales_order_no });
    //     globalQuery.conditions.push({ field: "inspection_production_status", operator: "IN", values: ['A', 'PD'] });
    //     globalQuery.orderBy = ["weaving_production_inspection_details_id desc"];
    //     const getInspectionSummaryList = await comboDataFunc.current.removeCatcheFillCombo(globalQuery);
    //     
    //     if (getInspectionSummaryList.length !== 0) {
    //         if (dispatchchallanObj.expected_dispatch_quantity > 0) {
    //             let dispatchQuantity = dispatchchallanObj.expected_dispatch_quantity;

    //             const updatedDetailsData = getInspectionSummaryList.map((item) => {
    //                 if (!alreadySelectedInspectionDtls.some(storedItem => storedItem.weaving_production_inspection_details_id === item.weaving_production_inspection_details_id)) {
    //                     const newItem = {
    //                         ...item,
    //                         prev_dispatch_quantity: isNaN(!item.dispatch_quantity) ? item.dispatch_quantity : 0,
    //                         item_pending_quantity: 0,
    //                         dispatch_quantity: validateNumberDateInput.current.decimalNumber((item.inspection_mtr - item.dispatch_quantity).toString(), 4),
    //                         dispatch_challan_details_transaction_id: item.dispatch_challan_details_transaction_id,
    //                         customer_order_no: dispatchchallanObj.customer_order_no,
    //                     };
    //                     if (parseFloat(dispatchQuantity) !== 0) {
    //                         if (parseFloat(dispatchQuantity) > parseFloat(newItem.dispatch_quantity)) {
    //                             dispatchQuantity -= newItem.dispatch_quantity;
    //                             newItem.item_pending_quantity = 0;
    //                         } else {
    //                             newItem.item_pending_quantity = newItem.dispatch_quantity - dispatchQuantity;
    //                             newItem.dispatch_quantity = validateNumberDateInput.current.decimalNumber((dispatchQuantity).toString(), 4);
    //                             dispatchQuantity = 0;
    //                         }
    //                         return newItem; // Ensure you return the updated item in the map function
    //                     } else {
    //                         newItem.dispatch_quantity = 0;
    //                         newItem.item_pending_quantity = 0;
    //                         return newItem;
    //                     }

    //                 } else {
    //                     let storedItem = alreadySelectedInspectionDtls.find(storedItem => storedItem.weaving_production_inspection_details_id === item.weaving_production_inspection_details_id)
    //                     dispatchQuantity -= storedItem.dispatch_quantity;
    //                     return {
    //                         ...item,
    //                         ...storedItem,
    //                     }

    //                 }
    //             });
    //             setInspectionSummaryList(updatedDetailsData);

    //             setTimeout(() => {
    //                 $('#inspection_material_name_id').text(dispatchchallanObj.product_material_name);
    //                 $('#inspection_material_name_id').attr('product_id', dispatchchallanObj.product_material_id);
    //                 $('#inspection_material_name_id').attr('so_sr_no', dispatchchallanObj.so_sr_no);
    //                 $('#inspection_material_name_id').attr('customer_order_no', dispatchchallanObj.customer_order_no);
    //                 $('#inspection_material_name_id').attr('so_dtl_id', dispatchchallanObj.sales_order_details_transaction_id);

    //                 $('#inspection_dispatch_qty_id').text(dispatchchallanObj.expected_dispatch_quantity);
    //                 updatedDetailsData.forEach(item => {
    //                     if (item.dispatch_quantity > 0) {
    //                         $("input:checkbox[name='selectInspectionRecord'][value='" + item.weaving_production_inspection_details_id + "']").prop("checked", true);
    //                     } else {
    //                         $("input:checkbox[name='selectInspectionRecord'][value='" + item.weaving_production_inspection_details_id + "']").prop("checked", false);
    //                     }
    //                 })
    //                 $('#selectAllSetNosRecords').prop('checked', $('input:checkbox.selectInspectionRecord:checked').length === $('input:checkbox.selectInspectionRecord').length);
    //             }, 100);
    //         }

    //     } else {
    //         setInspectionSummaryList([])
    //     }
    // }

    const loadInspectionList = async (dispatchchallanObj) => {
        setShowInspectionDetailsModal(true);
        let alreadySelectedInspectionDtls = [];
        let alreadySelectedInspectionIds = [];
        let existingInspectionDtls = [...inspectionDetailsList]
        if (existingInspectionDtls.length !== 0) {
            alreadySelectedInspectionDtls = existingInspectionDtls.filter(item => {
                return item.product_material_id === dispatchchallanObj.product_material_id
                    && item.customer_order_no === dispatchchallanObj.customer_order_no
            });
            alreadySelectedInspectionIds = alreadySelectedInspectionDtls.map(item => item.weaving_production_inspection_details_id);
        }

        resetGlobalQuery();
        globalQuery.columns = ['*'];
        globalQuery.table = "xtv_weaving_production_inspection_details";
        globalQuery.conditions.push({ field: "is_delete", operator: "=", value: '0' });
        // globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
        globalQuery.conditions.push({ field: "product_material_id", operator: "=", value: dispatchchallanObj.product_material_id });
        globalQuery.conditions.push({ field: "sales_order_no", operator: "=", value: dispatchchallanObj.sales_order_no });
        // globalQuery.conditions.push({ field: "inspection_production_status", operator: "IN", values: ['A', 'PD'] });
        globalQuery.conditions.push({ field: "inspection_production_status", operator: "=", value: 'A' });
        globalQuery.conditions.push({ field: "stock_status", operator: "IN", values: ['A', 'PD'] });
        globalQuery.orderBy = ["weaving_production_inspection_details_id desc"];
        const getInspectionSummaryList = await comboDataFunc.current.removeCatcheFillCombo(globalQuery);

        if (getInspectionSummaryList.length !== 0) {
            if (dispatchchallanObj.expected_dispatch_quantity > 0) {
                let dispatchQuantity = dispatchchallanObj.expected_dispatch_quantity;

                alreadySelectedInspectionDtls?.map(item => {
                    dispatchQuantity -= item.dispatch_quantity;
                });

                existingInspectionDtls = getInspectionSummaryList
                    .filter(item => !alreadySelectedInspectionIds.includes(item.weaving_production_inspection_details_id))
                    ?.map((item) => {
                        if (!alreadySelectedInspectionDtls.some(storedItem => storedItem.weaving_production_inspection_details_id === item.weaving_production_inspection_details_id)) {
                            const newItem = {
                                ...item,
                                prev_dispatch_quantity: isNaN(!item.dispatch_quantity) ? item.dispatch_quantity : 0,
                                item_pending_quantity: 0,
                                dispatch_quantity: validateNumberDateInput.current.decimalNumber((item.inspection_mtr - item.dispatch_quantity).toString(), 4),
                                dispatch_challan_details_transaction_id: item.dispatch_challan_details_transaction_id,
                                customer_order_no: dispatchchallanObj.customer_order_no,
                            };
                            if (parseFloat(dispatchQuantity) !== 0) {
                                if (parseFloat(dispatchQuantity) > parseFloat(newItem.dispatch_quantity)) {
                                    dispatchQuantity -= newItem.dispatch_quantity;
                                    newItem.item_pending_quantity = 0;
                                } else {
                                    newItem.item_pending_quantity = newItem.dispatch_quantity - dispatchQuantity;
                                    newItem.dispatch_quantity = validateNumberDateInput.current.decimalNumber((dispatchQuantity).toString(), 4);
                                    dispatchQuantity = 0;
                                }
                                return newItem; // Ensure you return the updated item in the map function
                            } else {
                                newItem.dispatch_quantity = 0;
                                newItem.item_pending_quantity = 0;
                                return newItem;
                            }
                        } else {
                            let storedItem = alreadySelectedInspectionDtls.find(storedItem => storedItem.weaving_production_inspection_details_id === item.weaving_production_inspection_details_id)
                            dispatchQuantity -= storedItem.dispatch_quantity;
                            return {
                                ...item,
                                ...storedItem,
                            }
                        }
                    });
                setInspectionSummaryList([...alreadySelectedInspectionDtls, ...existingInspectionDtls])
                // setInspectionSummaryList(updatedDetailsData);
                setTimeout(() => {
                    $('#inspection_material_name_id').text(dispatchchallanObj.product_material_name);
                    $('#inspection_material_name_id').attr('product_id', dispatchchallanObj.product_material_id);
                    $('#inspection_material_name_id').attr('so_sr_no', dispatchchallanObj.so_sr_no);
                    $('#inspection_material_name_id').attr('customer_order_no', dispatchchallanObj.customer_order_no);
                    $('#inspection_material_name_id').attr('so_dtl_id', dispatchchallanObj.sales_order_details_transaction_id);

                    $('#inspection_dispatch_qty_id').text(dispatchchallanObj.expected_dispatch_quantity);
                    [...alreadySelectedInspectionDtls, ...existingInspectionDtls].forEach(item => {
                        if (item.dispatch_quantity > 0) {
                            $("input:checkbox[name='selectInspectionRecord'][value='" + item.weaving_production_inspection_details_id + "']").prop("checked", true);
                        } else {
                            $("input:checkbox[name='selectInspectionRecord'][value='" + item.weaving_production_inspection_details_id + "']").prop("checked", false);
                        }
                    })
                    $('#selectAllSetNosRecords').prop('checked', $('input:checkbox.selectInspectionRecord:checked').length === $('input:checkbox.selectInspectionRecord').length);
                }, 100);
            }

        } else {
            setInspectionSummaryList([])
        }
    }

    const fnShowInspectionDetails = (inspectionMaterial) => {
        const isvalidate = validateInspectionDispatch();
        if (isvalidate) {

            let materialId = $('#inspection_material_name_id').attr('product_id');
            let so_srno = $('#inspection_material_name_id').attr('so_sr_no');
            let customerOrderNo = $('#inspection_material_name_id').attr('customer_order_no');
            let soDtlId = $('#inspection_material_name_id').attr('so_dtl_id');

            let selectedSetNos = [];
            let selectedRollNos = [];
            let createInspectionDetailsList = [];
            $("input:checkbox[name=selectInspectionRecord]:checked").each(function () {
                const inspectionDetailsRow = inspectionSummatryList.find(item => item.weaving_production_inspection_details_id === parseInt($(this).val()));
                const inspectionRow = {
                    ...inspectionDetailsRow,
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    financial_year: FINANCIAL_SHORT_NAME,
                    dispatch_schedule_no: txt_dispatch_schedule_no,
                    dispatch_schedule_date: txt_dispatch_date,
                    dispatch_schedule_version: dispatch_schedule_version,
                    dispatch_schedule_inspection_details_transaction_id: inspectionDetailsRow?.dispatch_schedule_inspection_details_transaction_id || 0,
                    dispatch_schedule_master_transaction_id: dispatch_schedule_master_transaction_id,
                    so_sr_no: inspectionMaterial?.so_sr_no,
                    customer_order_no: inspectionMaterial?.customer_order_no,
                    product_material_id: inspectionMaterial?.product_material_id,
                    sales_order_details_transaction_id: inspectionMaterial?.sales_order_details_transaction_id || 0,
                    sales_order_Date: inspectionMaterial?.sales_order_date,
                    sales_order_version: inspectionMaterial?.sales_order_version,
                    customer_order_Date: inspectionMaterial?.customer_order_Date,
                };
                selectedSetNos.push(inspectionDetailsRow.inspection_production_set_no);
                selectedRollNos.push(inspectionDetailsRow.roll_no);
                createInspectionDetailsList.push(inspectionRow)
            })

            selectedSetNos = [...new Set(selectedSetNos)];  // For Only Unique values
            selectedRollNos = [...new Set(selectedRollNos)]; // For Only Unique values
            setDispatchDetailsData(prevItems => {
                return prevItems.map((item, i) => {
                    if (item.product_material_id == inspectionMaterial.product_material_id && item.customer_order_no == inspectionMaterial.customer_order_no) {
                        return { ...item, set_no: selectedSetNos.map(item => item).join(':'), roll_no: selectedRollNos.map(item => item).join(':'), };
                    }
                    return item;
                });
            });

            setInspectionDetailsList(createInspectionDetailsList)
            setShowInspectionDetailsModal(false)
        }
    }
    // ***************************** Inspection Modal Table Functions Ends. ************************************

    // ********************************************* Show inspection Summary Starts. *****************************************************************************************
    const renderInspectionSummaryTbl = useMemo(() => {
        return <>
            <Table className="erp_table " id='inspection-summary-table-id' responsive bordered striped erp_table_scroll>
                <thead className="erp_table_head">
                    <tr>
                        <th className='erp_table_th'></th>
                        <th className='erp_table_th'>Sr. No.</th>
                        <th className="erp_table_th">Sales Order No</th>
                        <th className="erp_table_th" style={{ width: '100px', paddingRight: '100px' }}>Material Name</th>
                        <th className="erp_table_th">Inspection Set No</th>
                        <th className="erp_table_th">Inspection Date</th>
                        <th className="erp_table_th">Roll No</th>
                        <th className="erp_table_th">Inspection Stock</th>
                        <th className="erp_table_th">Previous Dispatch Quantity</th>
                        <th className="erp_table_th">Dispatch Quantity</th>
                        <th className="erp_table_th">Godown Name</th>
                    </tr>
                </thead>
                <tbody>
                    {inspectionSummatryList.map((inspectionItem, index) => (
                        <tr rowIndex={index}>
                            <td>
                                <div style={{ display: "flex" }}>
                                    <input type='checkbox' class="selectInspectionRecord" name="selectInspectionRecord" id={'selectInspectionRecord_' + inspectionItem.weaving_production_inspection_details_id}
                                        value={inspectionItem.weaving_production_inspection_details_id} onClick={(e) => FnCheckBoxesSelection('PartiallySelectInspectionRecord')}>
                                    </input>
                                </div>
                            </td>
                            <td className="erp_table_td text-end">{index + 1}</td>
                            <td className="erp_table_td ">{inspectionItem.sales_order_no}</td>
                            <td className="erp_table_td " style={{ width: '150px', display: 'table-cell', whiteSpace: 'break-spaces' }}>{inspectionItem.product_material_name}</td>
                            <td className="erp_table_td ">{inspectionItem.inspection_production_set_no}</td>
                            <td className="erp_table_td ">{validateNumberDateInput.current.formatDateToDDMMYYYY(inspectionItem.inspection_production_date)}</td>
                            <td className="erp_table_td text-end">{inspectionItem.roll_no}</td>
                            <td className="erp_table_td text-end">{inspectionItem.inspection_mtr}</td>
                            <td className="erp_table_td text-end">{inspectionItem.prev_dispatch_quantity}</td>
                            <td className="erp_table_td ">
                                <input type="text" className="erp_input_field mb-0" id={`dispatch_quantity_${index}`} value={inspectionItem.dispatch_quantity}
                                    Headers='dispatch_quantity' onChange={(e) => { updateInspectionSummaryTblData(inspectionItem, e); }} onBlur={(e) => { updateInspectionSummaryTblData(inspectionItem, e); }} />
                            </td>
                            <td className="erp_table_td ">{inspectionItem.godown_name}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    }, [inspectionSummatryList])

    const updateInspectionSummaryTblData = (currentRowData, event) => {
        let clickedColName = event.target.getAttribute('Headers');
        const inputValue = event.target.value;
        // Remove error msgs on input change.
        if (event._reactName === 'onChange') { delete event.target.parentElement.dataset.tip; }

        switch (clickedColName) {
            case 'dispatch_quantity':
                if (inputValue !== "") {
                    const quantityForDispatch = currentRowData.inspection_mtr - currentRowData.prev_dispatch_quantity;
                    if (inputValue <= quantityForDispatch) {
                        currentRowData.dispatch_quantity = validateNumberDateInput.current.decimalNumber(inputValue.toString(), 4);
                        delete event.target.parentElement.dataset.tip;
                    } else {
                        event.target.parentElement.dataset.tip = 'Dispatch quantity not greater than Inspection quantity...!';
                    }
                } else {
                    currentRowData.dispatch_quantity = "";
                    event.target.parentElement.dataset.tip = 'Please fill this field...!';
                }
                break;
            default:
                break;
        }
        const updatedDetailsData = [...inspectionSummatryList]
        const itemArrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
        updatedDetailsData[itemArrayIndex] = currentRowData;
        setInspectionSummaryList(updatedDetailsData);
    }

    const validateInspectionDispatch = () => {
        let result = true;
        let addedQty = 0;
        $('#inspection-summary-table-id tbody tr').each(function () {
            let currentTblRow = $(this);
            let isChecked = currentTblRow.find("input:checkbox[name='selectInspectionRecord']:checked").length > 0;
            if (isChecked) {
                let dispatch_qty = parseFloat(currentTblRow.find('input[id^="dispatch_quantity_"]').val());
                let total_dispatch_qty = parseFloat(currentTblRow.find('input[id^="inspection_mtr_"]').val());
                addedQty += dispatch_qty;
                if (isNaN(dispatch_qty)) {
                    $(currentTblRow.find('input[id^="dispatch_quantity_"]'))[0].parentElement.dataset.tip = 'Dispatch Quantity should not be a zero or blank...!';
                    $(currentTblRow.find('input[id^="dispatch_quantity_"]'))[0].focus();
                    return result = false;
                }
            }
            result = true;
        });

        if (addedQty > $('#inspection_dispatch_qty_id').text()) {
            setErrMsg('Dispatch quantity not more than Total Quantity ' + parseFloat($('#inspection_dispatch_qty_id').text()) + ' ...!')
            setShowErrorMsgModal(true)
            return false;
        } else if (addedQty < $('#inspection_dispatch_qty_id').text()) {
            setErrMsg('Dispatch quantity not less than Total Quantity ' + parseFloat($('#inspection_dispatch_qty_id').text()) + ' ...!')
            setShowErrorMsgModal(true)
            return false;
        }
        return result;
    }
    // ********************************************* Show inspection Summary Ends. *****************************************************************************************

    //*********************************************** Inspection Details Starts Accordion Tbl *********************************** */
    const renderInspectionDetailsTbl = useMemo(() => {
        return <>
            <Table className="erp_table " id='inspection-details-table-id' bordered striped>
                <thead className="erp_table_head">
                    <tr>
                        <th className='erp_table_th'>Sr. No.</th>
                        <th className="erp_table_th">Sales Order No</th>
                        <th className="erp_table_th" style={{ width: '100px', paddingRight: '100px' }}>Material Name</th>
                        <th className='erp_table_th'>Inspection Order No.</th>
                        <th className="erp_table_th">Inspection Set No</th>
                        <th className="erp_table_th">Inspection Date</th>
                        <th className="erp_table_th">Roll No</th>
                        <th className="erp_table_th">Inspection Stock</th>
                        <th className="erp_table_th">Dispatch Quantity</th>
                        <th className="erp_table_th">Godown Name</th>
                    </tr>
                </thead>
                <tbody>
                    {inspectionDetailsList.map((inspectionItem, index) => (
                        <tr rowIndex={index}>
                            <td className="erp_table_td text-end">{index + 1}</td>
                            <td className="erp_table_td ">{inspectionItem.sales_order_no}</td>
                            <td className="erp_table_td " style={{ width: '150px', display: 'table-cell', whiteSpace: 'break-spaces' }}>{inspectionItem.product_material_name}</td>
                            <td className="erp_table_td ">{inspectionItem.inspection_production_code}</td>
                            <td className="erp_table_td ">{inspectionItem.inspection_production_set_no}</td>
                            <td className="erp_table_td ">{validateNumberDateInput.current.formatDateToDDMMYYYY(inspectionItem.inspection_production_date)}</td>
                            <td className="erp_table_td text-end">{inspectionItem.roll_no}</td>
                            <td className="erp_table_td text-end">{inspectionItem.inspection_mtr}</td>
                            <td className="erp_table_td text-end">{inspectionItem.dispatch_quantity}</td>
                            <td className="erp_table_td ">{inspectionItem.godown_name}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    }, [inspectionDetailsList])
    //*********************************************** Inspection Details Ends *********************************** */

    return (
        <>
            <ComboBox ref={comboDataFunc} />
            <ExcelExport ref={exlsExp} />
            <GenerateTAutoNo ref={generateAutoNoAPiCall} />
            <FrmValidations ref={validate} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />

            <DashboardLayout >
                {isLoading ?
                    <div className="spinner-overlay"  >
                        <div className="spinner-container">
                            <CircularProgress color="primary" />
                            <span>Loading...</span>
                        </div>
                    </div> :
                    ''}


                <div className='card mt-4 p-1'>
                    <div className='card-header text-center py-0 mb-2'>
                        {/* //changes by tushar */}
                        <label className='erp-form-label-lg text-center'>Dispatch Advisory (Fabric) {actionType} </label>
                    </div>

                    <form id="dispatchnoteId">
                        <div className="row">
                            <div className="col-sm-6 erp_form_col_div">

                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label">Dispatch Schedule Type<span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className='col'>
                                        <select id="cmb_dispatch_schedule_type" className="form-select form-select-sm" value={cmb_dispatch_schedule_type} onChange={() => { comboOnChange('dispatchScheduleType'); validateFields(); }} disabled={keyForViewUpdate === '' ? false : true}>
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>
                                            {dispatchScheduleTypeList.length !== 0 ? (
                                                <>
                                                    {dispatchScheduleTypeList.map(scheduleType => (
                                                        // changes by tushar
                                                        <option key={scheduleType.field_id} value={scheduleType.field_id} shortname={scheduleType.product_type_short_name} > {scheduleType.field_name} </option>
                                                    ))}
                                                </>
                                            ) : null}

                                        </select>
                                        <MDTypography variant="button" id="error_cmb_dispatch_schedule_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Creation Type <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col">
                                        {/* <div className="erp_form_radio">
                                            <div className="sCheck me-3"> <Form.Check className="erp_radio_button" label="So Based" type="radio" lbl="So Based" value="S" name="rb_dispatch_order_creation_type" checked={rb_dispatch_order_creation_type === "S"} onClick={(e) => { setDispOrdCreationType('S'); comboOnChange('CreationType'); }} disabled={(isApprove || keyForViewUpdate === 'view' || keyForViewUpdate === 'update' || dispatchDetailsData.length !== 0) } /> </div>
                                            <div className="fCheck me-2"> <Form.Check className="erp_radio_button" label="Mannual" type="radio" lbl="Mannual" value="M" name="rb_dispatch_order_creation_type" checked={rb_dispatch_order_creation_type === "M"} onClick={(e) => { setDispOrdCreationType('M'); comboOnChange('CreationType'); }} disabled={(isApprove || keyForViewUpdate === 'view' || keyForViewUpdate === 'update' || dispatchDetailsData.length !== 0 )} /> </div>
                                        </div> */}
                                        <div className="erp_form_radio">
                                            <div className="sCheck me-3"> <Form.Check className="erp_radio_button" label="So Based" type="radio" lbl="So Based" value="S" name="rb_dispatch_order_creation_type" checked={rb_dispatch_order_creation_type === "S"} onClick={(e) => { setDispOrdCreationType('S'); comboOnChange('CreationType'); }} disabled={true} /> </div>
                                            <div className="fCheck me-2"> <Form.Check className="erp_radio_button" label="Mannual" type="radio" lbl="Mannual" value="M" name="rb_dispatch_order_creation_type" checked={rb_dispatch_order_creation_type === "M"} onClick={(e) => { setDispOrdCreationType('M'); comboOnChange('CreationType'); }} disabled={true} /> </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-sm-3 col-12">
                                        <Form.Label className="erp-form-label">Schedule no., Ver. & Date <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className="col-sm-9 col-12">
                                        <div className="row">
                                            <div className="col-12 col-md-6 pe-md-0">
                                                <Form.Control type="text" id="txt_dispatch_schedule_no" className="erp_input_field" value={txt_dispatch_schedule_no} optional='optional' disabled />
                                                <MDTypography variant="button" id="error_txt_dispatch_schedule_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                            <div className="col-12 col-md-2 pt-md-0 pt-3 pe-md-0 ps-md-1">
                                                <Form.Control type="text" id='dispatch_schedule_version' className="erp_input_field text-end" value={dispatch_schedule_version} disabled />
                                                <MDTypography variant="button" id="error_dispatch_schedule_version" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                            </div>
                                            <div className="col-12 col-md-4 pt-md-0 pt-3 ps-md-1">
                                                <Form.Control type="date" id='txt_dispatch_date' className="erp_input_field" min={currentDate} value={txt_dispatch_date} onChange={e => { setDispatchDate(e.target.value); validateFields(); }} />
                                                <MDTypography variant="button" id="error_txt_indent_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label">Dispatch Note Status</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <select id="cmb_dispatch_note_status" name="dispatchNoteStatus" className="form-select form-select-sm" value={cmb_dispatch_note_status} onChange={(e) => { setDispatchNoteStatus(e.target.value); }} optional="optional" disabled={true} >
                                            <option value="P" lbl="Pending">Pending</option>
                                            <option value="A" lbl="Approved">Approved</option>
                                            <option value="R" lbl="Rejected">Rejected</option>
                                            <option value="I" lbl="Partial Issue">Partial Sales Issue</option>
                                            {/* <option value="PSI" lbl="Partial Issue">Partial Sales Issue</option> */}
                                            <option value="C" lbl="Completed">Completed</option>
                                            <option value="X" lbl="Canceled">Canceled</option>
                                            <option value="D" lbl="PreeClosed">Dispath Challan Created</option>
                                            <option value="I" lbl="PreeClosed">Invoice Created</option>
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_dispatch_note_status" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Customer <span className="required">*</span> </Form.Label>
                                    </div>
                                    <div className='col-sm-8 col-10'>
                                        <select id="cmb_customer_id" className="form-select form-select-sm" value={cmb_customer_id} onChange={(e) => { comboOnChange('Customer'); validateFields(); }}>
                                            <option value="" disabled selected>Select</option>
                                            <option value="0">Add New Record+</option>
                                            {customerList?.map(customer => (
                                                <option value={customer.field_id} lbl={customer.field_name}>{customer.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_customer_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                    <div className='col-sm-1 col-2 refreshIconCol'>
                                        <MdRefresh style={{ color: 'black' }} onClick={e => { FnRefreshComboData('UpdateCustomers'); }} />
                                    </div>
                                </div>

                                {
                                    rb_dispatch_order_creation_type === 'S' ?
                                        <>
                                            <div className='row'>
                                                <div className="col-sm-3">
                                                    <Form.Label className="erp-form-label">Customer Ord. No.<span className="required">*</span></Form.Label>
                                                </div>
                                                <div className="col-sm-9 col-12">
                                                    <div className="row">
                                                        <div className={`col-sm-6 col-lg-9 pe-md-0`}>
                                                            <div className="select-btn" onClick={() => showContsCheckboxes()} optional='optional'>
                                                                <span className="form-select form-select-sm" id="">{checkedCustomerOrderNoLength !== 0 ? checkedCustomerOrderNoLength + ' Selected' : 'Select'} </span>
                                                            </div>
                                                            <ul className="list-items" id="customer-order-ul">
                                                                {customerOrderNoList}
                                                            </ul>
                                                            <MDTypography variant="button" id="error_customer_orders_list" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                        </div>
                                                        <div className="col-12 col-md-6 col-lg-3 pt-md-0 pt-3 ps-md-1">
                                                            <MDButton type="button" id="show-btn" className="erp-gb-button float-end" variant="button" fontWeight="regular" onClick={(e) => { ShowDispatchNoteDetails(storedDispatchDetailsData); }} disabled={isApprove || keyForViewUpdate === 'view' | rb_dispatch_order_creation_type === 'M' ? true : false}> Show Materials </MDButton>
                                                            {/* <MDButton type="button" id="show-btn" className="erp-gb-button ms-2" variant="button" onClick={() => ShowDispatchNoteDetails(storedDispatchDetailsData)} fontWeight="regular" disabled={isApprove || keyForViewUpdate === 'view' || rb_dispatch_order_creation_type === 'M' ? true : false}>Show</MDButton> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </> : null
                                }
                                <div className='row'>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Customer State & City </Form.Label>
                                    </div>
                                    <div className='col'>
                                        <div className="row">
                                            <div className="col-12 col-md-6 pe-md-0">
                                                <select id="cmb_customer_state_id" className="form-select form-select-sm" value={cmb_customer_state_id} disabled optional="optional">
                                                    <option value="">Select</option>
                                                    <option value="0">Add New Record+</option>
                                                    {stateOpts?.map(state => (
                                                        <option value={state.field_id}>{state.field_name}</option>
                                                    ))}
                                                </select>
                                                <MDTypography variant="button" id="error_cmb_customer_state_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>
                                            <div className="col-12 col-md-6 pt-md-0 pt-3 ps-md-1">
                                                <select id="cmb_customer_city_id" className="form-select form-select-sm" value={cmb_customer_city_id} disabled optional="optional">
                                                    <option value="">Select</option>
                                                    <option value="0">Add New Record+</option>
                                                    {customerCityOpts?.map(city => (
                                                        <option value={city.field_id}>{city.field_name}</option>
                                                    ))}
                                                </select>
                                                <MDTypography variant="button" id="error_cmb_customer_city_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <Form.Label className="erp-form-label">Dispatch Supervisor<span className="required">*</span></Form.Label>
                                    </div>
                                    <div className='col-sm-8 col-10'>
                                        {/* <select id="cmb_supervisor" className="form-select form-select-sm" value={cmb_supervisor} onChange={() => { comboOnChange('cmb_supervisor'); validateFields(); }} >
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>
                                            {cmb_supervisorList?.map(cmb_supervisor => (
                                                <option value={cmb_supervisor.field_id}>{cmb_supervisor.field_name}</option>

                                            ))}
                                        </select> */}
                                        <Select ref={supervisorComboRef}
                                            options={cmb_supervisorList}
                                            isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                                            inputId="cmb_supervisor" // Provide the ID for the input box
                                            value={cmb_supervisorList.find(option => option.value === cmb_supervisor)}
                                            onChange={(selectedOpt) => {
                                                setSupervisor(selectedOpt.value);
                                                supervisorComboRef.current = selectedOpt;
                                                comboOnChange('cmb_supervisor');
                                                validateFields();
                                            }}
                                            placeholder="Search for a supplier..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select" // Add custom prefix for class names
                                            disabled={['view', 'approve'].includes(keyForViewUpdate)}
                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px' // Adjust the font size as per your requirement
                                                })
                                            }}
                                        />
                                        <MDTypography variant="button" id="error_cmb_supervisor" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                    <div className='col-sm-1 col-2 refreshIconCol'>
                                        <MdRefresh style={{ color: 'black' }} onClick={e => { FnRefreshComboData('UpdateEmployees'); }} />
                                    </div>
                                </div>

                            </div>

                            {/* //second column */}
                            <div className="col-sm-6 erp_form_col_div">
                                <div className='row'>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Consignee </Form.Label>
                                    </div>
                                    <div className='col-sm-8 col-10'>
                                        <select id="cmb_consignee_id" className="form-select form-select-sm" value={cmb_consignee_id} onChange={() => { comboOnChange('Consignee'); validateFields(); }} optional="optional">
                                            <option value="">Select</option>
                                            <option value="0">Add New Record+</option>
                                            {consigneeList?.map(consignee => (
                                                <option value={consignee.field_id}>{consignee.field_name}</option>
                                            ))}
                                        </select>
                                        <MDTypography variant="button" id="error_cmb_consignee_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                    </div>
                                    <div className='col-sm-1 col-2 refreshIconCol'>
                                        <MdRefresh style={{ color: 'black' }} onClick={e => { FnRefreshComboData('UpdateConsignees'); }} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Consignee State & City</Form.Label>
                                    </div>
                                    <div className='col'>
                                        <div className="row">
                                            <div className="col-12 col-md-6 pe-md-0">
                                                <select id="cmb_consignee_state_id" className="form-select form-select-sm" value={cmb_consignee_state_id} onChange={() => { comboOnChange('ConsigneeState'); validateFields(); }} optional="optional">
                                                    <option value="">Select</option>
                                                    {stateOpts?.map(state => (
                                                        <option value={state.field_id}>{state.field_name}</option>
                                                    ))}
                                                </select>
                                                <MDTypography variant="button" id="error_cmb_consignee_state_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>
                                            <div className="col-12 col-md-6 pt-md-0 pt-3 ps-md-1">
                                                <select id="cmb_consignee_city_id" className="form-select form-select-sm" value={cmb_consignee_city_id} onChange={() => { comboOnChange('ConsigneeCity'); validateFields(); }} optional="optional">
                                                    <option value="">Select</option>
                                                    <option value="0">Add New Record+</option>
                                                    {consigneeCityOpts?.map(city => (
                                                        <option value={city.field_id}>{city.field_name}</option>
                                                    ))}
                                                </select>
                                                <MDTypography variant="button" id="error_cmb_consignee_city_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    isApprove ?
                                        <div className='row'>
                                            <div className='col-sm-3'>
                                                <Form.Label className="erp-form-label">Apprv. By & Date</Form.Label>
                                            </div>
                                            <div className="col-sm-9 col-12">
                                                <div className="row">
                                                    <div className="col-12 col-md-6 pe-md-0">
                                                        <select id="cmb_approved_by_id" className="form-select form-select-sm" value={cmb_approved_by_id} disabled={true} >
                                                            <option value="">Select</option>
                                                            <option value="0">Add New Record+</option>
                                                            {approveByOpts?.map(employee => (
                                                                <option value={employee.field_id}>{employee.field_name}</option>
                                                            ))}
                                                        </select>
                                                        <MDTypography variant="button" id="error_cmb_approved_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                        </MDTypography>
                                                    </div>
                                                    <div className="col-12 col-md-6 pt-md-0 pt-3 ps-md-1">
                                                        <Form.Control type="date" id='dt_approved_date' className="erp_input_field" value={dt_approved_date} onChange={(e) => { setApproveDate(e.target.value); }} disabled={true} optional="optional" />
                                                        <MDTypography variant="button" id="error_dt_approved_date" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : null
                                }
                                <div className='row'>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Dispatch Note Remark</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control as="textarea" rows={1} id="txt_note_Remark" className="erp_txt_area" value={txt_note_Remark} onInput={e => { setNoteRemark(e.target.value); }} maxlength="1000" optional="optional" />
                                        <MDTypography variant="button" id="error_txt_note_Remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Other Terms Conditions</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control as="textarea" rows={1} id="txt_other_terms_conditions" className="erp_txt_area" value={txt_other_terms_conditions} onInput={e => { setothercondition(e.target.value); }} maxlength="1000" optional="optional" />
                                        <MDTypography variant="button" id="error_txt_other_terms_conditions" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Remark</Form.Label>
                                    </div>
                                    <div className="col">
                                        <Form.Control as="textarea" rows={1} id="txt_remark" className="erp_txt_area" value={txt_remark} onInput={e => { setRemark(e.target.value); }} maxlength="1000" optional="optional" />
                                        <MDTypography variant="button" id="error_txt_remark" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}></MDTypography>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-3">
                                        <Form.Label className="erp-form-label">Is Active</Form.Label>
                                    </div>
                                    <div className="col">
                                        <div className="erp_form_radio">
                                            <div className="fCheck"> <Form.Check className="erp_radio_button" label="Yes" type="radio" lbl="Yes" value="true" name="rb_is_active" checked={rb_is_active === true} onClick={() => setIsActive(true)} disabled={isApprove || keyForViewUpdate === 'view' ? true : false} /> </div>
                                            <div className="sCheck"> <Form.Check className="erp_radio_button" label="No" type="radio" lbl="No" value="false" name="rb_is_active" checked={rb_is_active === false} onClick={() => setIsActive(false)} disabled={isApprove || keyForViewUpdate === 'view' ? true : false} /> </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <Accordion defaultActiveKey="0" >
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header className="erp-form-label-md">Customer's Contact Details</Accordion.Header>
                                            <Accordion.Body className="p-0">
                                                {
                                                    cmb_customer_id !== '' && cmb_customer_id !== '0' && rb_dispatch_order_creation_type !== 'S'
                                                        ? <>
                                                            <div className={`row ps-3 py-1 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>
                                                                <MDButton type="button" className={`erp-gb-button float-start col-1`} variant="button" fontWeight="regular" onClick={() => {
                                                                    localStorage.setItem('dataAddedByCombo', 'dataAddedByCombo')
                                                                    localStorage.setItem('customerIDs', JSON.stringify({ customerID: cmb_customer_id, keyForViewUpdate: 'update', compType: 'Master' }))
                                                                }
                                                                }  ><Link className={'text-light'} to={{ pathname: "/Masters/Customer" }} target="_blank" >Add</Link></MDButton>
                                                                <MDButton type="button" className={`erp-gb-button float-end col-1 ms-2`} variant="button" fontWeight="regular" onClick={() => { FnGetCustomersContactPersonsDetails(parseInt(cmb_customer_id)) }}>Refresh</MDButton>
                                                            </div>
                                                        </>
                                                        : null
                                                }

                                                {
                                                    custContactDetails.length > 0
                                                        ? <>
                                                            <div className='row'>
                                                                <div className="col-12">
                                                                    <Table className="erp_table erp_table_scroll" id='customerContactPersonsTable' responsive bordered striped>
                                                                        <thead className="erp_table_head">
                                                                            <tr>
                                                                                <th className={`erp_table_th`}>
                                                                                    <div className="col-1">
                                                                                        <input type='checkbox' class="form-check-input me-1" name="selectAllCustContact" id="selectAllCustContact" onClick={(e) => { FnCheckBoxesSelection('selectAllCustContact'); }} {...(keyForViewUpdate === 'view' || rb_dispatch_order_creation_type === 'S' || isApprove ? { disabled: 'disabled' } : {})} />
                                                                                        &nbsp; Select
                                                                                    </div>
                                                                                </th>
                                                                                <th className='erp_table_th'>Customer Branch</th>
                                                                                <th className='erp_table_th'>Contact Person</th>
                                                                                <th className='erp_table_th'>Contact No</th>
                                                                                <th className={`erp_table_th `}>Email</th>
                                                                                <th className={`erp_table_th`}>Alternate Email</th>
                                                                                <th className={`erp_table_th`}>Alternate Contact No</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                custContactDetails.map((item, index) =>
                                                                                    <tr rowindex={index}>
                                                                                        <td className='erp_table_td'>
                                                                                            <input type='checkbox' className="form-check-input selectCustContactPerson" name="selectCustContactPerson"
                                                                                                id={'selectCustContactPerson_' + item.customer_contact_id} contact_personId={item.customer_contact_id}
                                                                                                value={item.customer_contact_id} onClick={() => FnCheckBoxesSelection('PartiallyCustContactSelection')}
                                                                                                {...(keyForViewUpdate === 'view' || isApprove || rb_dispatch_order_creation_type === 'S' ? { disabled: 'disabled' } : {})}
                                                                                            />
                                                                                        </td>
                                                                                        <td className="erp_table_td">{item.customer_branch_name}</td>
                                                                                        <td className="erp_table_td">{item.cust_contact_person}</td>
                                                                                        <td className="erp_table_td"> {item.cust_contact_no} </td>
                                                                                        <td className={`erp_table_td`}>{item.cust_email_id}</td>
                                                                                        <td className={`erp_table_td`}>{item.cust_alternate_EmailId}</td>
                                                                                        <td className={`erp_table_td`}>{item.cust_alternate_contact}</td>
                                                                                    </tr>
                                                                                )
                                                                            }
                                                                        </tbody>
                                                                    </Table>
                                                                </div>
                                                            </div>
                                                        </>
                                                        : ""
                                                }
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion >
                                    <MDTypography variant="button" id="error_customer_contact_persons" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}> </MDTypography>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>

                {renderDispatchDetailsAccordian()}
                {renderInspectionDetailsAccordion()}
                {productPropertiesAccordian()}
                {renderGatePassAccordian()}

                {dispatchDetailsData.length !== 0 && (
                    <>
                        <hr />
                        <div className='row mt-2'>
                            <div className="col-sm-3">
                                <div className="row">
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Total Qty.</Form.Label>
                                    </div>
                                    <div className='col-sm-8'>
                                        <Form.Control type="text" id="txt_total_quantity " className="erp_input_field" value={txt_total_quantity} optional='optional' disabled />
                                        <MDTypography variant="button" id="txt_total_quantity " className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="row">
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Total Weight</Form.Label>
                                    </div>
                                    <div className='col-sm-8'>
                                        <Form.Control type="text" id="txt_Total_Weight" className="erp_input_field" value={txt_total_weight} optional='optional' disabled />
                                        <MDTypography variant="button" id="error_txt_Total_Weight" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-3">
                                <div className="row">
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Actual Qty.</Form.Label>
                                    </div>
                                    <div className='col-sm-8'>
                                        <Form.Control type="text" id="txt_actual_quantity" className="erp_input_field" value={txt_actual_quantity} optional='optional' disabled />
                                        <MDTypography variant="button" id="error_txt_indent_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="row">
                                    <div className='col-sm-4'>
                                        <Form.Label className="erp-form-label">Actual Weight</Form.Label>
                                    </div>
                                    <div className='col-sm-8'>
                                        <Form.Control type="text" id="txt_actual_weight" className="erp_input_field" value={txt_actual_weight} optional='optional' disabled />
                                        <MDTypography variant="button" id="error_txt_indent_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {keyForViewUpdate !== '' && keyForViewUpdate !== 'copy' ?
                    <>
                        <Accordion defaultActiveKey="0" onSelect={FnLoadAccordionData}>
                            <Accordion.Item eventKey="documentList">
                                <Accordion.Header className="erp-form-label-md">Document List</Accordion.Header>
                                <Accordion.Body className="p-0">
                                    <div className={`table-responsive ${uploadedDocData.length > 4 ? 'erp_table_scroll' : ''}`}>
                                        {uploadedDocData.length !== 0 ? (
                                            renderDocumentTable
                                        ) : (
                                            <div className='row'>
                                                <div className="col-12">
                                                    <span className="erp_validation text-center" fontWeight="regular" color="error">
                                                        No Records Found...
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion> <hr /></> : null
                }


                <div className="text-center mb-5">
                    <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => moveToListing()}>Back</MDButton>
                    <MDButton type="button" className={`erp-gb-button ms-2 ${deletionKey === true ? 'display' : 'd-none'}`} variant="button" fontWeight="regular"
                        onClick={() => setShowDeleteModal(true)} >  Delete</MDButton>
                    {/* <input type="file" id="Excelfile" ref={importFile} onChange={importedExcelFile} style={{ display: "none" }} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" /> */}
                    <MDButton type="button" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button" onClick={() => createDispatchNote(!isApprove ? 'Add' : 'A')}
                        fontWeight="regular">{actionLabel}</MDButton>
                    <MDButton type="button" className={`erp-gb-button ms-2 ${isApprove ? 'display' : 'd-none'}`}
                        variant="button" onClick={() => createDispatchNote('R')}
                        fontWeight="regular">Reject</MDButton>
                    <MDButton type="button" id="export-btn" className={`erp-gb-button ms-2 ${dispatch_schedule_master_transaction_id === 0 ? 'display' : 'd-none'}`} variant="button" onClick={ExportToExcel} fontWeight="regular">Export</MDButton>
                    {/* <MDButton type="button" id="import-btn" className="erp-gb-button ms-2" variant="button" onClick={onImportClick}
                        fontWeight="regular">Import</MDButton> */}
                    <MDButton className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' ? 'd-none' : 'display'}`} variant="button" fontWeight="regular" id='viewdocument-id' onClick={viewDocumentForm} disabled={dispatch_schedule_master_transaction_id === 0 ? true : false}>Upload Document</MDButton>&nbsp;
                    <MDButton className={`erp-gb-button ${keyForViewUpdate === 'view' && cmb_dispatch_note_status === 'A' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" onClick={() => printInvoice(true)}>Print <FiPrinter className="erp-download-icon-btn" /></MDButton> &nbsp;
                    {/* <MDButton className={`erp-gb-button erp_MLeft_btn ${keyForViewUpdate === 'view' ? cmb_dispatch_note_status !== 'P' && cmb_dispatch_note_status !== 'R' ? 'display' : 'd-none' : 'd-none'}`} variant="button" fontWeight="regular" id='print-btn-id' onClick={() => {
                        isTaxableAccordion ? printInvoice(true) : printGatePassSlip(true);
                    }} disabled={cmb_dispatch_note_status !== "P" ? '' : 'disabled'} >Gate Pass Print <FiDownload className="erp-download-icon-btn" /></MDButton> */}

                    <MDButton className={`erp-gb-button ${keyForViewUpdate === 'view' && cmb_dispatch_note_status === 'A' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" onClick={() => printGatePassSlip(true)}>Print Gate Pass <FiPrinter className="erp-download-icon-btn" /></MDButton> &nbsp;
                    {/* <MDButton className={`erp-gb-button ${keyForViewUpdate === 'view' && cmb_dispatch_note_status === 'A' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" onClick={() => printLoadingSlips(true)}>Print Loading Slips <FiPrinter className="erp-download-icon-btn" /></MDButton> &nbsp; */}
                </div >

                {/* Document modal */}
                <Modal size="lg" className='erp_document_Form' show={showDocumentForm} onHide={FnCloseDocumentUploadModal} backdrop="static" keyboard={false} centered>
                    <Modal.Header>
                        <Modal.Title className='erp_modal_title'>Document For {txt_dispatch_schedule_no}</Modal.Title>
                        <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={FnCloseDocumentUploadModal}></button></span>
                    </Modal.Header>
                    <Modal.Body>
                        <DocumentF group_id={txt_dispatch_schedule_no !== 0 ? txt_dispatch_schedule_no.replaceAll('/', '_') : null} document_group={docGroup} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" className="btn erp-gb-button" onClick={FnCloseDocumentUploadModal}> Cancel </Button>
                    </Modal.Footer>
                </Modal>

                {
                    rb_dispatch_order_creation_type === 'M'
                        ? <>
                            <Modal size="lg" className="erp_document_Form" show={showBomFilterForm} onHide={FnCloseBomFilter} backdrop="static" keyboard={false} centered >
                                <Modal.Header>
                                    <Modal.Title className="erp_modal_title">Products Filter</Modal.Title>
                                    <span>
                                        <button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={FnCloseBomFilter} ></button>
                                    </span>
                                </Modal.Header>
                                <Modal.Body>
                                    <React.Fragment>
                                        {/* <FrmMBOMEntry closeModal={FnCloseBomFilter} ptSelected={{ product_type: cmb_dispatch_schedule_type, master_val: 'smv_product_type', }}  /> */}
                                        <ProductListProductTypeWise closeModal={FnCloseBomFilter} props={{ product_type_id: cmb_dispatch_schedule_type }} />
                                    </React.Fragment>
                                </Modal.Body>
                            </Modal>
                        </>
                        : null
                }

                {/* Add new Record Popup */}
                {showAddRecModal ?
                    <>
                        <Modal size="lg" show={showAddRecModal} onHide={handleCloseRecModal} backdrop="static" keyboard={false} centered >
                            <Modal.Body className='erp_city_modal_body'>
                                <div className='row'>
                                    <div className='col-12 align-self-end'>
                                        <button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseRecModal}></button>
                                    </div>
                                </div>
                                {displayRecordComponent()}
                            </Modal.Body>
                        </Modal >
                    </>
                    : null
                }

                {/* Generic Response Messaage Modals */}
                <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg, modalOrderDetails]} />
                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />
                <InfoModal closeModal={() => handleCloseInfoModal()} show={[showInfoMsgModal, infoMsg]} />

                {/* This Modal is used to print the dispatch-note */}
                <Modal show={showConfirmationModal} onHide={(e) => { FnCloseConfirmationModal(); }} backdrop="static" keyboard={false} centered>
                    <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={(e) => { FnCloseConfirmationModal(); }}></button></span>
                    <Modal.Body className='erp_modal_body'>
                        <span className='erp_modal_success_icon'><RxCheckCircled /></span>
                        <h6>{succMsg}</h6>
                        <h6>{modalOrderDetails}</h6>
                        <h6>Do you wish to download dispatch-note Print-Out...?</h6>
                        <Modal.Footer className='erp-modal-footer'>
                            <Button variant="success" className="erp-gb-button" onClick={(e) => { printInvoice(true); }}> Print </Button>
                            <Button variant="success" className="erp-gb-button ms-2" onClick={(e) => { FnCloseConfirmationModal(); }}> NO </Button>
                        </Modal.Footer>
                    </Modal.Body>
                </Modal >

                <Modal show={showDeleteModal} onHide={FnCloseDeleteModal} backdrop="static" keyboard={false} centered>
                    <span><button type="button" class="erp-modal-close btn-close" aria-label="Close" onClick={FnCloseDeleteModal}></button></span>
                    <Modal.Body className='erp_modal_body'>
                        <span className='erp_modal_delete_icon'><RxCrossCircled /></span>
                        <h6>Are you sure?</h6>
                        <div className="erp-form-label">Do you wish to delete this record ?</div>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-center'>
                        <Button variant="success" className='erp-gb-button' onClick={FnCloseDeleteModal}>
                            Cancel
                        </Button>&nbsp;
                        <Button variant="danger" className='erp-gb-button' onClick={deleteRecords}>Delete</Button>
                    </Modal.Footer>
                </Modal>

                {/* </div> */}

                <Modal size="xl" className='erp_document_Form' show={showInspectionDetailsModal} onHide={handleCloseInspectionSummaryModal} backdrop="static" keyboard={false} centered>
                    <Modal.Header>
                        <Modal.Title className='erp_modal_title text-center'>Inspection Dispatch Stock</Modal.Title>
                        <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseInspectionSummaryModal}></button></span>
                    </Modal.Header>
                    <Modal.Body>
                        <React.Fragment>
                            <div className='row p-2'>
                                <div className="col-12">
                                    {inspectionSummatryList.length !== 0 ?
                                        <> <dl className="row mb-0">
                                            <div className="col-md-8 col-sm-12">
                                                <dl className="row mb-0">
                                                    <dt className="col-sm-12 col-md-3 erp-form-label-md-lg">Material Name:</dt>
                                                    <dd className="col-sm-12 col-md-9 erp-form-label" id='inspection_material_name_id'></dd>
                                                </dl>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <dl className="row mb-0">
                                                    <dt className="col-sm-12 col-md-6 erp-form-label-md-lg"> Total Quantity:</dt>
                                                    <dd className="col-sm-12 col-md-6 erp-form-label" id='inspection_dispatch_qty_id'></dd>
                                                </dl>
                                            </div>

                                        </dl>
                                            <div class="col  pl-2">
                                                <input type='checkbox' class="me-1" name="selectAllSetNosRecords" id="selectAllSetNosRecords" onClick={(e) => { FnCheckBoxesSelection('selectAllSetNosRecords'); }} /> <label class="erp-form-label pb-1"> Select All </label>
                                            </div>
                                            {renderInspectionSummaryTbl}
                                        </> :
                                        <div className='row text-center'>
                                            <div className="col-12">
                                                <span className="erp_validation text-center" fontWeight="regular" color="error"> No Records Found... </span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            {inspectionSummatryList.length !== 0 ?
                                <>
                                    <div className="erp_frm_Btns ">
                                        <MDButton type="button" onClick={() => fnShowInspectionDetails(currentInspectionMtrlForModal)} className="btn erp-gb-button" variant="button"
                                            fontWeight="regular">Add</MDButton>
                                    </div>
                                </> : null
                            }
                        </React.Fragment>
                    </Modal.Body>
                </Modal>



            </DashboardLayout>
        </>
    )
}