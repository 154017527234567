import React, { useEffect, useRef, useMemo, useLayoutEffect } from 'react'
import { useState } from 'react'
import $ from 'jquery';
import ErrorModal from "components/Modals/ErrorModal";
import SuccessModal from "components/Modals/SuccessModal";
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { Form, Tab, Table, Tabs, Card } from 'react-bootstrap';
import ComboBox from 'Features/ComboBox';
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ConfigConstants from "assets/Constants/config-constant";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import ValidateNumberDateInput from 'FrmGeneric/ValidateNumberDateInput';
import FrmValidations from 'FrmGeneric/FrmValidations';
import { useLocation, useNavigate } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import GenerateMaterialId from 'FrmGeneric/GenerateMaterialId/GenerateMaterialId';
// import ErrorModal from "components/Modals/ErrorModal";
// import SuccessModal from "components/Modals/SuccessModal";
// import FrmWPDetailsEntry from './component/FrmWPDetailsEntry';
// import FrmWPMaterialMovementEntry from './component/FrmWPMaterialMovementEntry';
import { IoAddCircleOutline, IoRemoveCircleOutline } from 'react-icons/io5';



function FrmStoppageEntry() {
    const configConstants = ConfigConstants();
    const { COMPANY_ID, COMPANY_BRANCH_ID, UserName, FINANCIAL_SHORT_NAME } = configConstants;

    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const { state } = useLocation();
    const { productionSetNo = '', weaving_production_stoppage_id, keyForViewUpdate = 'Add', compType, requestfor } = state || {};


    ///Shiftwise Machine Stoppage Entry Hooks
    const [cmb_set_no, setProductionSetNo] = useState(productionSetNo);
    const [weaving_stoppage_id, setWeavingStoppageID] = useState();
    const [cmb_section_id, setProdSection] = useState();
    const [cmb_sub_section_id, setProdSubSection] = useState();

    const [labelForStoppage, setLabelForStopage] = useState(keyForViewUpdate === 'update' ? 'Update' : 'Save');
    const [actionType, setActionType] = useState(keyForViewUpdate === 'view' ? '(View)' : keyForViewUpdate === 'update' ? '(Update)' : '');


    const [machineNameOption, setMachineNameOption] = useState([]);


    ///Options for ComboBoxes
    const [shiftOptions, setShiftOptions] = useState([]);
    const [setNoOptions, setSetNoOptions] = useState([]);
    const [prodsectionOptions, setProdSectionOptions] = useState([]);
    const [prodsubsectionOptions, setProdSubSectionOptions] = useState([]);

    //Empty JSON Object for stoppage
    const stoppageEmptyObj = {
        machine_id: 0,
        production_date: today(),
        // from_time: 0,
        // to_time: 0,
        total_time: 0,
        production_stoppage_reasons_id: 0,
        production_stoppage_reasons_id: '',
        shift: '',
        loss_type: '',
        // std_stoppage_loss_kg: '',
        // actual_production_loss_kg: '',
        // std_stoppage_loss_per_hour: '',
        // stoppage_production_loss_kg: '',
    }

    ///Shiftwise Machine Stoppage Entry Comboboxes
    const [stoppagereasonOptions, setProdStoppageReasonOptions] = useState([]);
    const [stoppageDetails, setStoppageDetails] = useState([{ ...stoppageEmptyObj }]);

    //Ref hooks
    const comboDataAPiCall = useRef();
    const generateAutoNoAPiCall = useRef();
    const validate = useRef();
    const navigate = useNavigate();

    //Loader
    const [isLoading, setIsLoading] = useState(false);

    //Error Msg
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    // Success Msg HANDLING
    const handleCloseSuccessModal = () => {
        setShowSuccessMsgModal(false);
        let Path = `/Transactions/TWeavingStoppageWastageProduction/FrmStoppageListing`
        navigate(`${Path}/${requestfor}`)

    }

    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');

    useEffect(() => {
        const loadDataOnload = async () => {
            setIsLoading(true)
            await fillComboBox();
            await FnCheckUpdateRes();
            setIsLoading(false)
        }
        loadDataOnload();
    }, [])

    const fillComboBox = async () => {
        try {
            //Shift Options
            const shiftsApiCall = await comboDataAPiCall.current.fillComboBox('ProductionShifts')
            setShiftOptions(shiftsApiCall);
            let shift_Names = [];
            shiftsApiCall.map((shift) => {
                shift_Names.push(shift.property_name)
            })


            //Set No's Approved in Warping Production
            resetGlobalQuery();
            globalQuery.columns.push('set_no');
            globalQuery.table = "xtv_warping_production_order";
            globalQuery.conditions.push({ field: "warping_order_status", operator: "=", value: 'A', });
            globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID, });
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0, });
            globalQuery.orderBy = ['set_no DESC']
            const SetNoAPICall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            setSetNoOptions(SetNoAPICall);

            ////Production Section
            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name'];
            globalQuery.table = "xmv_production_section"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            comboDataAPiCall.current.fillFiltersCombo(globalQuery).then((getWarpingProdSecCall) => {
                setProdSectionOptions(getWarpingProdSecCall);
                setProdSection('18');
                comboOnChange('cmb_section_id');
            })

            // let C = $('#cmb_section_id').val();
            // globalQuery.columns.push("field_id", 'field_name', 'production_sub_section_short_name');
            // globalQuery.table = "xmv_production_sub_section"
            // globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            // globalQuery.conditions.push({ field: "production_section_id", operator: "=", value: C });
            // let getProdSubSectionApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
            // setProdSubSectionOptions(getProdSubSectionApiCall);
            // setProdSubSection('22');

            resetGlobalQuery();
            let sectionId = $('#cmb_section_id').val();
            globalQuery.columns.push("field_id", 'field_name', 'production_sub_section_short_name');
            globalQuery.table = "xmv_production_sub_section"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "production_section_id", operator: "=", value: sectionId });
            let getProdSubSectionApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
            setProdSubSectionOptions(getProdSubSectionApiCall);
            if (requestfor !== "") {
                if (getProdSubSectionApiCall.length !== 0) {
                    const subSectionType = getProdSubSectionApiCall.find((item) => item.production_sub_section_short_name === requestfor)?.field_id;
                    if (subSectionType) {
                        setProdSubSection(subSectionType)
                    }
                }
            }


            //Stoppage Reasons......
            resetGlobalQuery();
            globalQuery.columns = ['*'];
            globalQuery.table = "xmv_production_stoppage_reasons";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            // globalQuery.conditions.push({ field: "production_stoppage_reasons_name", operator: "=", value: 0 });
            //   globalQuery.conditions.push({ field: "sub_section_id", operator: "=", value: prodsubSectionId });
            let getProdStoppageReasonsApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
            setProdStoppageReasonOptions(getProdStoppageReasonsApiCall);
            // setProdStoppageReasonOp(getProdStoppageReasonsApiCall);

        } catch (error) {
            console.log('error: ', error);
            navigate('/Error')

        } finally {
            setIsLoading(false);
        }
    }

    const comboOnChange = async (key, LossType) => {
        try {
            debugger
            switch (key) {

                case 'cmb_section_id':
                    const prod_section_Id = $('#cmb_section_id').val();
                    resetGlobalQuery();
                    globalQuery.columns.push("field_id", 'field_name', 'production_sub_section_short_name');
                    globalQuery.table = "xmv_production_sub_section"
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    globalQuery.conditions.push({ field: "production_section_id", operator: "=", value: prod_section_Id });
                    let getProdSubSectionApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
                    setProdSubSectionOptions(getProdSubSectionApiCall);

                    // const reqSubSecId = getProdSubSectionApiCall.find(section => section['production_sub_section_short_name'] === requestfor);
                    // if (reqSubSecId) {
                    //     setProdSubSection(reqSubSecId['field_id']);
                    // }
                    let subsectionId;
                    if (requestfor === "WVWP") {
                        setProdSubSection('22');
                        subsectionId = '22';
                    } else if (requestfor === "WVSZ") {
                        setProdSubSection('23');
                        subsectionId = '23';
                    }
                    else if (requestfor === "WVWV") {
                        setProdSubSection('24');
                        subsectionId = '24';
                    }
                    else if (requestfor === "WVIN") {
                        setProdSubSection('27');
                        subsectionId = '27';
                    }

                    resetGlobalQuery();
                    globalQuery.columns.push("field_id", 'field_name');
                    globalQuery.table = "cmv_machine"
                    globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
                    globalQuery.conditions.push({ field: "company_id", operator: "=", value: COMPANY_ID });
                    globalQuery.conditions.push({ field: "section_id", operator: "=", value: prod_section_Id });
                    globalQuery.conditions.push({ field: "sub_section_id", operator: "=", value: subsectionId });

                    // globalQuery.conditions.push({ field: "sub_section_id", operator: "=", value: reqSubSecId['field_id'] });
                    let getMachineApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
                    setMachineNameOption(getMachineApiCall)
                    break;

            }
        } catch (error) {
            console.log('error: ', error);
            navigate('/Error')

        } finally {
        }
    }

    const FnCheckUpdateRes = async () => {
        debugger
        let sub_section_id;
        if (requestfor === "WVWP") {
            setProdSubSection("22");  // Pass integer directly to setProdSubSection
            sub_section_id = 22;      // Assign integer value
        } else if (requestfor === "WVSZ") {
            setProdSubSection("23");
            sub_section_id = 23;
        } else if (requestfor === "WVWV") {
            setProdSubSection("24");
            sub_section_id = 24;
        } else if (requestfor === "WVIN") {
            setProdSubSection("27");
            sub_section_id = 27;
        }
        
        try {
            let apiCall = '';
            if (requestfor === "WVWV") {
                apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtWeavingProductionStoppage/FnShowParticularRecordForUpdateWVWV/${weaving_production_stoppage_id}/${sub_section_id}/${COMPANY_ID}`);
            } else {
                apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtWeavingProductionStoppage/FnShowParticularRecordForUpdate/${cmb_set_no}/${parseInt(sub_section_id)}/${parseInt(COMPANY_ID)}`);
            }
            const responce = await apiCall.json();
            const WeavingProductionStoppageRecords = responce.WeavingProductionStoppageRecords;

            setProductionSetNo(WeavingProductionStoppageRecords[0]['production_set_no']);
            setProdSection(WeavingProductionStoppageRecords[0]['section_id'])
            await comboOnChange('cmb_section_id');
            setProdSubSection(WeavingProductionStoppageRecords[0]['sub_section_id']);
            await comboOnChange('cmb_sub_section_id');

            console.log('Required Data :' + WeavingProductionStoppageRecords);
            setStoppageDetails(WeavingProductionStoppageRecords);
        } catch (error) {

        } finally {

        }
    }


    var validateStoppageTbl = () => {
        try {
            let stoppageDetailsData = [...stoppageDetails];
            let validation = true;

            for (let i = 0; i < stoppageDetailsData.length; i++) {
                let data = stoppageDetailsData[i];

                for (let key in data) {
                    let tabledataId = $("#" + key + `_${i}`);

                    if (tabledataId.is(':visible') && (['', 0, '0', null].includes(tabledataId.val()))) {
                        if (tabledataId.attr('type') === 'text') {
                            tabledataId.parent().attr('data-tip', 'Please Enter proper data...!');
                        } else if (tabledataId.is('select')) {
                            tabledataId.parent().attr('data-tip', 'Please Select atleast one...!');
                        } else if (tabledataId.attr('type') === 'time') {
                            tabledataId.parent().attr('data-tip', 'Please Select Time...!');
                        }

                        tabledataId.focus();
                        validation = false;
                        break;
                    }
                }

                if (!validation) {
                    break; // Exit outer loop if validation failed
                }
            }

            return validation; // Return the overall validation status
        } catch (error) {
            console.error("Validation error:", error); // Log any errors
            return false; // Return false if an error occurs
        }
    }


    const FnManageBottomDetailsTbl = (action, rowIndex) => {
        try {
            let stoppageDetailsData = [...stoppageDetails];

            if (action === 'add') {
                if (validateStoppageTbl() === true) {
                    // Add a new empty object at the beginning of the array
                    setStoppageDetails([...stoppageDetailsData, stoppageEmptyObj]);
                }
            } else {
                // Remove the specified row
                stoppageDetailsData.splice(rowIndex, 1);
                if (stoppageDetailsData.length > 0) {
                    setStoppageDetails(stoppageDetailsData);
                } else {
                    // If the last row is removed, add an empty object to keep the array non-empty
                    setStoppageDetails([stoppageEmptyObj]);
                }
            }

        } catch (error) {
            console.log(error)
        }
    }

    const updateStoppageDetails = async (propertyName, currentMaterial, event) => {
        debugger
        try {
            let stoppageDetailsData = [...stoppageDetails]; // Create a shallow copy of the array
            const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'));

            switch (propertyName) {
                // case 'from_time':
                //     $("#" + event.target.id).parent().removeAttr('data-tip');
                //     currentMaterial[propertyName] = event.target.value;
                //     currentMaterial['total_time'] = FnManageTotalTime(currentMaterial['from_time'], currentMaterial['to_time'])
                //     break;

                // case 'to_time':
                //     $("#" + event.target.id).parent().removeAttr('data-tip');
                //     currentMaterial[propertyName] = event.target.value;
                //     currentMaterial['total_time'] = FnManageTotalTime(currentMaterial['from_time'], currentMaterial['to_time'])
                //     break;
                case 'loss_type':
                    currentMaterial[propertyName] = event.target.value;
                    break;
                case 'production_stoppage_reasons_id':
                    currentMaterial[propertyName] = event.target.value;
                case 'production_date':
                case 'machine_id':
                case 'shift':
                // case 'std_stoppage_loss_kg':
                // case 'actual_production_loss_kg':
                // case 'std_stoppage_loss_per_hour':
                // case 'stoppage_production_loss_kg':
                case 'total_time':
                    $("#" + event.target.id).parent().removeAttr('data-tip');
                    currentMaterial[propertyName] = event.target.value;
                    break;
                default:
                    break;
            }

            stoppageDetailsData[arrayIndex] = currentMaterial;
            setStoppageDetails(stoppageDetailsData);
        } catch (error) {

        }

    }

    ///Calculate Difference between From time & To Time
    const FnManageTotalTime = (from_time, to_time) => {
        if (from_time && to_time) {
            const [fromHours, fromMinutes] = from_time.split(':').map(Number);
            const [toHours, toMinutes] = to_time.split(':').map(Number);

            // Convert times to minutes
            const fromTimeInMinutes = fromHours * 60 + fromMinutes;
            const toTimeInMinutes = toHours * 60 + toMinutes;

            if (toTimeInMinutes > fromTimeInMinutes) {
                const totalMinutes = toTimeInMinutes - fromTimeInMinutes;
                const totalHours = Math.floor(totalMinutes / 60);
                const remainingMinutes = totalMinutes % 60;
                return `${totalHours}h ${remainingMinutes}m`;
            }
            else if (toTimeInMinutes < fromTimeInMinutes) {
                const totalMinutes = 1440 - (fromTimeInMinutes - toTimeInMinutes); // 1440 = 24 hrs
                const totalHours = Math.floor(totalMinutes / 60);
                const remainingMinutes = totalMinutes % 60;
                return `${totalHours}h ${remainingMinutes}m`;
            }
            else {
                return 'Invalid Time';
            }
        } else return ''
    };


    const renderMachineStoppageTable = useMemo(() => {
        return <Table className="erp_table" responsive bordered striped>
            <thead className="erp_table_head">
                <tr>
                    <th className={`erp_table_th ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}> Action</th>
                    <td className="erp_table_th" style={{ width: "auto" }} >Production Date</td>
                    <td className="erp_table_th"  >Machine No</td>
                    <td className="erp_table_th"  >Shift</td>
                    {/* <td className="erp_table_th" style={{ width: "auto" }} >From Time</td>
                    <td className="erp_table_th" style={{ width: "auto" }} >To Time</td> */}
                    <td className="erp_table_th" >Stoppage Time (Mins)</td>
                    <td className="erp_table_th"  >Stoppage Reason</td>
                    <td className="erp_table_th"  >Loss Type</td>
                    {/* <td className="erp_table_th" style={{ width: "auto" }} rowSpan={2} >Standard <br></br> Prod. Loss</td>
                    <td className="erp_table_th" style={{ width: "auto" }} rowSpan={2} >Actual <br></br> Prod. Loss</td>
                    <td className="erp_table_th" style={{ width: "auto" }} rowSpan={2} >Stoppage <br></br> Prod.Loss Kg</td>
                    <td className="erp_table_th" style={{ width: "auto" }} rowSpan={2} >Standard <br></br> Stopp. Loss/KG</td> */}

                </tr>
            </thead>
            <tbody>

                {
                    stoppageDetails.length > 0 ? (
                        stoppageDetails?.map((item, index) => (
                            <tr rowIndex={index}>
                                <td className={`erp_table_th ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`}>
                                    <IoAddCircleOutline className='erp_trAdd_icon' onClick={() => FnManageBottomDetailsTbl('add', index)} />
                                    <IoRemoveCircleOutline className='erp_trRemove_icon' onClick={() => FnManageBottomDetailsTbl('remove', index)} />
                                </td>
                                <td className="erp_table_td">
                                    <input type="date" className="erp_input_field mb-0" disabled={keyForViewUpdate === 'view'}
                                        Headers="production_date" id={`production_date_${index}`}
                                        value={item.production_date} onChange={(e) => updateStoppageDetails("production_date", item, e)} />

                                </td>
                                <td key={index} className="erp_table_td" id={`${item}{index}`}>
                                    <select id={`machine_id_${index}`} value={item.machine_id} style={{ width: 'auto' }}
                                        onChange={(e) => updateStoppageDetails("machine_id", item, e)} className="erp_input_field_table_txt mb-0"
                                        Headers="machine_id" disabled={keyForViewUpdate === 'view'}
                                    >
                                        <option value="">Select</option>
                                        {machineNameOption?.map(machine => (
                                            <option value={machine.field_id}>{machine.machine_id}{machine.field_name}</option>
                                        ))}
                                    </select>
                                </td>

                                <td key={index} className="erp_table_td" id={`${item}{index}`}>
                                    <select id={`shift_${index}`} value={item.shift} disabled={keyForViewUpdate === 'view'} onChange={(e) => updateStoppageDetails("shift", item, e)} className="erp_input_field_table_txt mb-0"
                                        Headers="shift"  >
                                        <option value="">Select</option>
                                        {shiftOptions?.map(shift => (
                                            <option value={shift.field_name}>{shift.field_name}</option>
                                        ))}
                                    </select>
                                </td>

                                {/* <td key={index} className="erp_table_td" id={`${item}{index}`}>
                                    <input
                                        type="time" disabled={keyForViewUpdate === 'view'}
                                        className="erp_input_field_table_txt remove0 mb-0 text-end"
                                        id={`from_time_${index}`}
                                        style={{ backgroundColor: '#AFE1AF', width: '100px' }}

                                        value={item.from_time}
                                        onChange={(e) => updateStoppageDetails("from_time", item, e)}
                                    />
                                </td>
                                <td key={index} className="erp_table_td" id={`${item}{index}`}>
                                    <input
                                        type="time" disabled={keyForViewUpdate === 'view'}
                                        className="erp_input_field_table_txt remove0 mb-0 text-end"
                                        id={`to_time_${index}`}
                                        style={{ backgroundColor: '#AFE1AF', width: '100px' }}

                                        value={item.to_time}
                                        onChange={(e) => updateStoppageDetails("to_time", item, e)}
                                    />
                                </td> */}
                                <td key={index} className="erp_table_td" id={`${item}{index}`}>
                                    <input
                                        type="number"
                                        onChange={(e) => updateStoppageDetails("total_time", item, e)}
                                        className="erp_input_field_table_txt remove0 mb-0 text-end input-numeric"
                                        id={`total_time_${index}`}
                                        style={{ width: '100px' }}
                                        Headers={'total_time'}
                                        value={item.total_time}
                                        disabled={keyForViewUpdate === "view"}
                                    />

                                </td>
                                <td key={index} className="erp_table_td" id={`${item}{index}`}>

                                    <select id={`loss_type_${index}`} className="form-select form-select-sm" disabled={keyForViewUpdate === 'view'} value={item.loss_type} onChange={(e) => updateStoppageDetails("loss_type", item, e)}>
                                        <option value="">Select</option>
                                        {/* {stoppagereasonOptions.length !== 0 ? (
                                            <>
                                                {stoppagereasonOptions?.map(stoppage => (
                                                    <option value={stoppage.field_id}>{stoppage.field_name}</option>
                                                ))}
                                            </>
                                        ) : null

                                        } */}
                                        <option value="Utilization">Utilization</option>
                                        <option value="Efficiency">Efficiency</option>
                                        <option value="Others">Others</option>
                                    </select>

                                </td>

                                <td key={index} className="erp_table_td" id={`${item}{index}`}>
                                    <select id={`production_stoppage_reasons_id_${index}`} disabled={keyForViewUpdate === 'view'} className="form-select form-select-sm" value={item.production_stoppage_reasons_id} onChange={(e) => updateStoppageDetails("production_stoppage_reasons_id", item, e)}  >
                                        <option value="">Select</option>
                                        {/* <option value="Utilization">Utilization</option>
                                        <option value="Efficiency">Efficiency</option>
                                        <option value="Others">Others</option> */}

                                        {stoppagereasonOptions.length !== 0 ? (
                                            <>
                                                {stoppagereasonOptions?.map(stoppage => (
                                                    <option value={stoppage.production_stoppage_reasons_id} stoppageId={stoppage.production_stoppage_reasons_id}>{stoppage.loss_type}</option>
                                                ))}
                                            </>
                                        ) : null

                                        }
                                    </select>
                                </td>

                                {/* <td key={index} className="erp_table_td" id={`${item}{index}`}>
                                    <input
                                        type="text"
                                        className="erp_input_field_table_txt remove0 mb-0 text-end"
                                        id={`std_stoppage_loss_kg_${index}`}
                                        style={{ backgroundColor: '#AFE1AF', width: '70px' }}

                                        value={item.std_stoppage_loss_kg}
                                        onChange={(e) => updateStoppageDetails("std_stoppage_loss_kg", item, e)}
                                    />
                                </td>
                                <td key={index} className="erp_table_td" id={`${item}{index}`}>
                                    <input
                                        type="text"
                                        className="erp_input_field_table_txt remove0 mb-0 text-end"
                                        id={`actual_production_loss_kg_${index}`}
                                        style={{ backgroundColor: '#AFE1AF', width: '70px' }}

                                        value={item.actual_production_loss_kg}
                                        onChange={(e) => updateStoppageDetails("actual_production_loss_kg", item, e)}
                                    />
                                </td>
                                <td key={index} className="erp_table_td" id={`${item}{index}`}>
                                    <input
                                        type="text"
                                        className="erp_input_field_table_txt remove0 mb-0 text-end"
                                        id={`std_stoppage_loss_per_hour_${index}`}
                                        style={{ backgroundColor: '#AFE1AF', width: '70px' }}

                                        value={item.std_stoppage_loss_per_hour}
                                        onChange={(e) => updateStoppageDetails("std_stoppage_loss_per_hour", item, e)}
                                    />
                                </td>
                                <td key={index} className="erp_table_td" id={`${item}{index}`}>
                                    <input
                                        type="text"
                                        className="erp_input_field_table_txt remove0 mb-0 text-end"
                                        id={`stoppage_production_loss_kg_${index}`}
                                        style={{ backgroundColor: '#AFE1AF', width: '70px' }}

                                        value={item.stoppage_production_loss_kg}
                                        onChange={(e) => updateStoppageDetails("stoppage_production_loss_kg", item, e)}
                                    />
                                </td> */}
                            </tr>
                        ))) : (
                        <tr>
                            <td className="erp_table_td" colSpan="14">
                                No records found...
                            </td>
                        </tr>
                    )}
            </tbody>
        </Table>

    }, [stoppageDetails, machineNameOption]);


    const FnSaveStoppageEntry = async () => {
        debugger
        try {
            setIsLoading(true);
            let validateFrom = await validate.current.validateForm('stoppageFormId');
            if (validateFrom === true) {
                if (validateStoppageTbl() === true) {

                    const json = { 'TransWVProdStoppageData': [], 'commonIds': { 'company_id': COMPANY_ID, 'production_set_no': cmb_set_no ?? '','cmb_sub_section_id':cmb_sub_section_id } }

                    for (let index = 0; index < stoppageDetails.length; index++) {

                        let stoppageElement = stoppageDetails[index];
                        const stoppageObj = {
                            company_id: COMPANY_ID,
                            company_branch_id: COMPANY_BRANCH_ID,
                            financial_year: FINANCIAL_SHORT_NAME,
                            section_id: cmb_section_id,
                            sub_section_id: cmb_sub_section_id,
                            production_set_no: cmb_set_no ?? '',
                            production_date: stoppageElement.production_date,
                            machine_id: stoppageElement.machine_id,
                            from_time: stoppageElement.from_time,
                            to_time: stoppageElement.to_time,
                            total_time: stoppageElement.total_time,
                            production_stoppage_reasons_id: stoppageElement.production_stoppage_reasons_id,
                            weaving_production_stoppage_id: stoppageElement.weaving_production_stoppage_id,
                            shift: stoppageElement.shift,
                            loss_type: stoppageElement.loss_type,
                            std_stoppage_loss_kg: stoppageElement.std_stoppage_loss_kg,
                            actual_production_loss_kg: stoppageElement.actual_production_loss_kg,
                            std_stoppage_loss_per_hour: stoppageElement.std_stoppage_loss_per_hour,
                            stoppage_production_loss_kg: stoppageElement.stoppage_production_loss_kg,
                            created_by: UserName,
                            modified_by: UserName
                        }
                        json.TransWVProdStoppageData.push(stoppageObj);
                    }

                    const formData = new FormData()
                    formData.append('WeavingProductionStoppageData', JSON.stringify(json))
                    const forwardData = {
                        method: 'POST',
                        body: formData,
                    }
                    const getWarpingDetails = await fetch(`${process.env.REACT_APP_BASE_URL}/api/XtWeavingProductionStoppage/FnAddUpdateRecord`, forwardData)
                    const response = await getWarpingDetails.json();
                    if (response.success === 0) {
                        setErrMsg(response.error)
                        setShowErrorMsgModal(true)
                    } else {
                        setSuccMsg(response.message)
                        setShowSuccessMsgModal(true)
                    }
                }
            }
        } catch {

        } finally {
            setIsLoading(false);
        }
    }

    const validateFieldsonChange = async () => {
        await validate.current.validateFieldsOnChange('stoppageFormId');
    }

    const requestTypeHeading = () => {
        switch (requestfor) {
            case 'WVWP':
                return '(Warping)';
            case 'WVSZ':
                return '(Sizing)';
            case 'WVWV':
                return '(Weaving)';

        }
    }



    return (
        <>
            <DashboardLayout>
                <ComboBox ref={comboDataAPiCall} />
                <ValidateNumberDateInput ref={ValidateNumberDateInput} />
                <GenerateMaterialId ref={generateAutoNoAPiCall} />
                <FrmValidations ref={validate} />

                {isLoading ?
                    <div className="spinner-overlay"  >
                        <div className="spinner-container">
                            <CircularProgress color="primary" />
                            <span>Loading...</span>
                        </div>
                    </div> :
                    ''}

                {/*.........................Shiftwise Machine Stoppage Entry............................... */}
                <div className="col-lg-12 col-12 erp_form_col_div">
                    <div className="card">
                        <div className='card-header text-center py-0'>
                            <label className='erp-form-label-lg text-center'>Stoppage Entry  {requestTypeHeading()}{actionType}</label>
                        </div>

                        {/* Card's Body Starts*/}
                        <div className="card-body p-0">
                            <div className='row p-1'>
                                <form id='stoppageFormId'>
                                    <div className='row'>
                                        {requestfor === 'WVWV' ? '' :
                                            <div className='col-sm-4 erp_form_col_div'>
                                                <div className='row'>
                                                    <div className='col-sm-4'>
                                                        <Form.Label className="erp-form-label">Set No.<span className="required">*</span> </Form.Label>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <select id="cmb_machine_no" className="form-select form-select-sm" value={cmb_set_no} onChange={(e) => { setProductionSetNo(e.target.value); validateFieldsonChange() }} disabled={keyForViewUpdate === 'view'}>
                                                            <option value="">Select</option>
                                                            {setNoOptions.length !== 0 ? (
                                                                <>
                                                                    {setNoOptions?.map(production => (
                                                                        <option value={production.set_no}>{production.set_no}</option>
                                                                    ))}
                                                                </>
                                                            ) : null}
                                                        </select>
                                                        <MDTypography variant="button" id="error_cmb_machine_no" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                        </MDTypography>
                                                    </div>
                                                </div>

                                            </div>
                                        }


                                        <div className='col-sm-4 erp_form_col_div'>
                                            <div className='row'>
                                                <div className='col-sm-4'>
                                                    <Form.Label className="erp-form-label">Section <span className="required">*</span> </Form.Label>
                                                </div>
                                                <div className="col-sm-6">
                                                    <select id="cmb_section_id" className="form-select form-select-sm" value={cmb_section_id} onChange={(e) => { setProdSection(e.target.value); }} disabled>
                                                        <option value="">Select</option>
                                                        {prodsectionOptions.length !== 0 ? (
                                                            <>
                                                                {prodsectionOptions?.map(production => (
                                                                    <option value={production.field_id}>{production.field_name}</option>
                                                                ))}
                                                            </>
                                                        ) : null}
                                                    </select>
                                                    <MDTypography variant="button" id="error_cmb_section_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-sm-4 erp_form_col_div'>
                                            <div className='row'>
                                                <div className='col-sm-4'>
                                                    <Form.Label className="erp-form-label">Sub Section <span className="required">*</span> </Form.Label>
                                                </div>
                                                <div className="col-sm-6">
                                                    <select id="cmb_sub_section_id" className="form-select form-select-sm" value={cmb_sub_section_id} onChange={(e) => { comboOnChange('cmb_sub_section_id'); setProdSubSection(e.target.value); validateFieldsonChange(); }} disabled>
                                                        <option value="">Select</option>
                                                        {prodsubsectionOptions.length !== 0 ? (
                                                            <>
                                                                {prodsubsectionOptions?.map(subproduction => (
                                                                    <option key={subproduction.field_id} shortName={subproduction.production_sub_section_short_name} value={subproduction.field_id}>
                                                                        {subproduction.field_name}
                                                                    </option>
                                                                ))}
                                                            </>
                                                        ) : null}
                                                    </select>
                                                    <MDTypography variant="button" id="error_cmb_sub_section_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                {/* Machine Stoppage Table */}
                                <div className="row">
                                    <div className='col erp_form_col_div'>
                                        {renderMachineStoppageTable}
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col'>
                                        <div className={`erp_frm_Btns  mt-2 mb-2 `}>
                                            <MDButton type="button" id="action_Button" className="erp-gb-button ms-2" onClick={() => handleCloseSuccessModal()} variant="button" fontWeight="regular">Back</MDButton>
                                            <MDButton type="button" id="action_Button" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'view' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`} onClick={() => FnSaveStoppageEntry()} variant="button" fontWeight="regular">{labelForStoppage}</MDButton>
                                        </div >
                                    </div >
                                </div >

                            </div>

                        </div>
                    </div>
                </div>

                <SuccessModal handleCloseSuccessModal={() => handleCloseSuccessModal()} show={[showSuccessMsgModal, succMsg]} />
                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg]} />
            </DashboardLayout>
        </>
    )
}

export default FrmStoppageEntry;