import React, { useEffect, useRef, useMemo } from 'react'
import { useState } from 'react'
import ReactDOMServer from 'react-dom/server';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import $ from 'jquery';
import { useNavigate } from "react-router-dom";
import MDTypography from 'components/MDTypography';
import ComboBox from 'Features/ComboBox';
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ConfigConstants from "assets/Constants/config-constant";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import ValidateNumberDateInput from 'FrmGeneric/ValidateNumberDateInput';
import FrmValidations from 'FrmGeneric/FrmValidations';
// Imports React bootstrap & stylling required
import { CircularProgress } from "@material-ui/core";
import { Form, Table, Card } from "react-bootstrap";
import MDButton from "components/MDButton";
import DakshabhiLogo from 'assets/images/DakshabhiLogo.png'
import ExcelExport from "Features/Exports/ExcelExport";
import Select from 'react-select';
import MaterialSearchBar from "FrmGeneric/MaterialSearchBar";
import ReactPaginate from "react-paginate";


function OutstandingMaterialsForPOReports() {
    const configConstants = ConfigConstants();
    const { COMPANY_NAME, COMPANY_ID, COMPANY_BRANCH_ID, UserName, FINANCIAL_SHORT_NAME } = configConstants;

    const navigate = useNavigate();

    //Current date
    const today = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const [dt_from_date, setFromDt] = useState(today);
    const [dt_to_date, setToDt] = useState(today);
    const [cmb_supplier_branch_id, setSupplier_branch_id] = useState("All");
    const [cmb_product_type_id, setProduct_type_id] = useState("0");
    const [supplierBranchOptions, setsupplierBranchOptions] = useState([]);
    const [productTypeOptions, setProductTypeOptions] = useState([]);
    let supplierComboRef = useRef(null);
    let productTypeComboRef = useRef(null);

    const [by_material, setByMaterial] = useState(false);
    const [search_material_id, setSearchMaterialId] = useState('');

    //Ref Hooks
    const validate = useRef();
    const comboDataAPiCall = useRef();
    const validateNumberDateInput = useRef();

    // Helping hooks;
    const [isLoading, setIsLoading] = useState(false);
    // Ref Hooks For Print-Out.    
    const printComponentRef = useRef();
    const [isPrinting, setIsPrinting] = useState(false);
    const promiseResolveRef = useRef(null);
    const exlsExp = useRef();

    //data hook
    const [outstandingMaterialsData, setOutstandingMaterialsData] = useState([])
   
    useEffect(() => {
        const loadFiltersCombo = async () => {
            setIsLoading(true)
            await loadCombos();
            setIsLoading(false)
        }
        loadFiltersCombo();
    }, []);


    // Pagination Variables 
    const pageEntriesOptions = [
        { label: "5", value: 5 },
        { label: "10", value: 10 },
        { label: "50", value: 50 },
        { label: "100", value: 100 },
        { label: "500", value: 500 },
        // { label: "All", value: 0 },
    ]
    const [entriesPerPage, setEntriesPerPage] = useState(pageEntriesOptions[2].value);
    const [pageCount, setpageCount] = useState(1);
    const [PageCurrent, setcurrentPage] = useState(1);


    // ****-------Get Cost Center Data Function--------------***
    const loadCombos = async () => {
        try {
            resetGlobalQuery();
            globalQuery.columns = ['supp_branch_id', 'supp_branch_name'];
            globalQuery.table = "cm_supplier_branch";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const supplierBranchList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            const supplierBranchOpts = [
                { value: 'All', label: 'All' },
                ...supplierBranchList.map((supplier) => ({ ...supplier, value: supplier.supp_branch_id, label: `${supplier.supp_branch_name}` })),
            ];
            setsupplierBranchOptions(supplierBranchOpts);

            resetGlobalQuery();
            globalQuery.columns = ['product_type_id', 'product_type_name'];
            globalQuery.table = "sm_product_type";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            const productTypeList = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            const productTypeOpts = [
                { value: '0', label: 'All' },
                ...productTypeList.map((productType) => ({ ...productType, value: productType.product_type_id, label: `${productType.product_type_name}` })),
            ];
            setProductTypeOptions(productTypeOpts);
        } catch (error) {
            console.log("error : ", error)
        }
    }

    // ***----------- Helping Functions.
    const goBack = () => { navigate("/Dashboard") }


    // Get Current DateTime
    const FnGetCurrentDateTime = () => {
        let currentDate = new Date(); // Get the current date and time
        // Format the current date and time as "DD-MM-YYYY HH:mm:ss"
        let formattedDateTime = `${String(currentDate.getDate()).padStart(2, '0')}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${currentDate.getFullYear()} ${String(currentDate.getHours()).padStart(2, '0')}:${String(currentDate.getMinutes()).padStart(2, '0')}:${String(currentDate.getSeconds()).padStart(2, '0')}`;
        return formattedDateTime;
    }
    // ****-------Validation Functions Starts--------------***
    const FnChkIsValidFilters = () => {
        const fromDate = new Date($("#dt_from_date").val());
        const toDate = new Date($("#dt_to_date").val());

        let isValid = true;

        if (!dt_from_date) {
            $('#error_dt_from_date').text('From Date is required.').show();
            isValid = false;
        } else {
            $('#error_dt_from_date').hide();
        }
        if (!dt_to_date) {
            $('#error_dt_to_date').text('To Date is required.').show();
            isValid = false;
        } else {
            $('#error_dt_to_date').hide();
        }
        if (dt_from_date && dt_to_date && fromDate > toDate) {
            $('#error_dt_from_date').text('From Date cannot be greater than To Date.');
            $('#error_dt_from_date').show();
            isValid = false;
        } else if (dt_from_date && dt_to_date) {
            $('#error_dt_from_date').hide();
        }
        return isValid;
    };

    // ****-------Validation Functions Ends--------------***

    // ****-------show data Function --------------***
    const FnShowStoreStockData = async (page, pageSize, product_id = '') => {
        debugger
        try {
            let isValidFilters = FnChkIsValidFilters();
            if (!isValidFilters) { return false; }
            setIsLoading(true)
            let commonIds = {};
            // Common Ids 
            commonIds.company_id = COMPANY_ID
            commonIds.from_date = dt_from_date
            commonIds.to_date = dt_to_date
            commonIds.supplier_branch_id = cmb_supplier_branch_id.toString();
            commonIds.product_id = product_id === '' ? search_material_id : product_id;
            commonIds.product_type_id = cmb_product_type_id.toString();

            console.log(commonIds);
            const formData = new FormData();
            formData.append(`commonIds`, JSON.stringify(commonIds))
            const requestOptions = {
                method: 'POST',
                body: formData
            };

            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/StockReport/getPurchaseOutstandingMaterials/${COMPANY_ID}/${page}/${pageSize}`, requestOptions)
            const responce = await apiCall.json();
            console.log("outstandingMaterials Report Data : ", responce);
            debugger
            if (responce.outstandingMaterialsReportData.length > 0) {
                // Get the stockData
                // During data fetch (e.g., in FnShowStoreStockData)
                const total = responce.outstandingMaterialsReportData[0]['totalCount'];
                setpageCount(entriesPerPage !== 0 ? Math.ceil(total / entriesPerPage) : 1);
                setOutstandingMaterialsData(responce.outstandingMaterialsReportData);
            } else {
                setOutstandingMaterialsData([])
            }

        } catch (error) {
            console.log(error);
            setIsLoading(false)
        } finally {
            setIsLoading(false)
        }
    }

    // ********* PrintOut Functionalities Starts. ****************************************
    useEffect(() => {
        if (isPrinting && promiseResolveRef.current) {
            promiseResolveRef.current();
        }
    }, [isPrinting]);

    const handlePrint = async () => {
        try {
            // Fetch the data before printing
            await FnShowStoreStockData(0, 10000);

            // Trigger the print after the data has been fetched
            FnPrintIssueReportSheet();
        } catch (error) {
            console.error('Error fetching data for print:', error);
        }
    };

    let printTimeout;  // Store timeout reference
    const FnPrintIssueReportSheet = useReactToPrint({

        content: () => {
            setIsLoading(true);
            debugger
            // Hide pagination during print
            $('.erp_pagination').hide();
            const headerHtml = printOutHeader(); // Call function to get header HTML

            const contentWrapper = document.createElement('div');
            contentWrapper.className = 'printable-content';
            contentWrapper.innerHTML = headerHtml; // Add generated header HTML

            // Append the main content to the wrapper
            const mainContentClone = printComponentRef.current.cloneNode(true);
            contentWrapper.appendChild(mainContentClone);

            return contentWrapper;
        },
        onBeforePrint: () => {
            // Apply the desired font size before printing
            if (printComponentRef.current) {

                const table = printComponentRef.current.querySelector('.erp_table');
                if (table) {
                    table.style.fontSize = '7px'; // Set your desired font size
                }
            }

            printTimeout = setTimeout(() => {
                handlePrintCancel();  // Handle cancel
            }, 500);
        },
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                promiseResolveRef.current = resolve;
                setIsPrinting(true);
            });
        },
        onAfterPrint: () => {
            // Show pagination again and restore original table styles
            $('.erp_pagination').show();
            // Reset the Promise resolve so we can print again
            promiseResolveRef.current = null;
            setIsPrinting(false);
            setIsLoading(false);
        },
        documentTitle: `Outstanding_Materials_(${dt_from_date}_to_${dt_to_date})`
    });

    const handlePrintCancel = async () => {
        console.log('Print was canceled.');

        try {
            let count = document.getElementById("page_entries_id").value;
            // Reload paginated data or trigger any necessary actions
            await FnShowStoreStockData(PageCurrent, entriesPerPage);
            console.log('Data reloaded after print cancel.');
        } catch (error) {
            console.error('Error fetching data after print cancel:', error);
        }
    };


    const printOutHeader = () => {
        return ReactDOMServer.renderToString(
            <>
                <div className='px-0 border border-dark'> {/* border border-dark */}
                    <div className='row mb-0 p-1'>
                        <div className="col-sm-1">
                            <img src={DakshabhiLogo} alt="master card" width="210" height="80" mt={1} />
                        </div>
                        <div className="col-sm-11">
                            <div className='erp-invoice-print-label text-center'>
                                <span className='erp-invoice-print-label-lg'>{COMPANY_NAME}</span><br />
                            </div>
                            <div className='erp-invoice-print-label-lg text-center'>
                                Purchase Outstanding Materials
                                {
                                    `(${validateNumberDateInput.current.formatDateToDDMMYYYY(dt_from_date)} to ${validateNumberDateInput.current.formatDateToDDMMYYYY(dt_to_date)})`
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };
    // ********* PrintOut Functionalities Ends. ****************************************

    // ********* ExportToExcel Functionalities Starts. ****************************************
    const addExcelFilter = (label, key, value, existingFilters) => {
        if (label !== '' && label !== 'All') { existingFilters[key] = `${value}: ${label}`; }
    };

     const [outstandingMaterialsDataExcel, setOutstandingMaterialsDataExcel] = useState([])
    const FnShowStoreStockDataExcel = async (page, pageSize, product_id = '') => {
        debugger
        try {
            let isValidFilters = FnChkIsValidFilters();
            if (!isValidFilters) { return false; }
            setIsLoading(true)
            let commonIds = {};
            // Common Ids 
            commonIds.company_id = COMPANY_ID
            commonIds.from_date = dt_from_date
            commonIds.to_date = dt_to_date
            commonIds.supplier_branch_id = cmb_supplier_branch_id.toString();
            commonIds.product_id = product_id === '' ? search_material_id : product_id;
            commonIds.product_type_id = cmb_product_type_id.toString();
            console.log("page: " + page);
            console.log("pageSize: " + pageSize);

            console.log(commonIds);
            const formData = new FormData();
            formData.append(`commonIds`, JSON.stringify(commonIds))
            const requestOptions = {
                method: 'POST',
                body: formData
            };

            const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/StockReport/getPurchaseOutstandingMaterials/${COMPANY_ID}/${page}/${pageSize}`, requestOptions)
            const responce = await apiCall.json();
            console.log("outstandingMaterials excel Report Data : ", responce);
            debugger
            if (responce.outstandingMaterialsReportData.length > 0) {
                // Get the stockData
                // During data fetch (e.g., in FnShowStoreStockData)
                // const total = responce.outstandingMaterialsReportDataExcel[0]['totalCount'];
                // setpageCount(entriesPerPage !== 0 ? Math.ceil(total / entriesPerPage) : 1);
                setOutstandingMaterialsDataExcel(responce.outstandingMaterialsReportData);
            } else {
                setOutstandingMaterialsDataExcel([])
            }

        } catch (error) {
            console.log(error);
            setIsLoading(false)
        } finally {
            setIsLoading(false)
        }
    }
    const FnExportToExcel = async () => {
        await FnShowStoreStockDataExcel(0, 10000);
        if (outstandingMaterialsDataExcel.length === 0) { return null; }
        try {
            setIsLoading(true);
            const fromDate = validateNumberDateInput.current.formatDateToDDMMYYYY(dt_from_date);
            const toDate = validateNumberDateInput.current.formatDateToDDMMYYYY(dt_to_date);
            // Preparing Data For ExportFile.
            let jsonToExportExcel = { 'allData': {}, 'columns': [], 'filtrKeyValue': {}, 'headings': {}, 'key': 'bomExport', 'editable_cols': [] };

            // Excel Headers;
            jsonToExportExcel['headings'] = {
                'ReportName': `Purchase Outstanding Materials (${fromDate} to ${toDate})`,
                'CompanyName': COMPANY_NAME,
                'CompanyAddress': sessionStorage.getItem('companyAddress'),
            }

            let appliedFilters = {
                '0': `From-Date: ${fromDate || ""}`,
                '1': `To-Date: ${toDate || ""}`,
            };
            addExcelFilter(supplierComboRef.current?.label || "", Object.keys(appliedFilters).length, 'Supplier', appliedFilters);
            addExcelFilter(productTypeComboRef.current?.label || "", Object.keys(appliedFilters).length, 'Product Type', appliedFilters);
            appliedFilters[Object.keys(appliedFilters).length] = `Generated On: ${FnGetCurrentDateTime()}`
            jsonToExportExcel['filtrKeyValue'] = appliedFilters;


            // Details Table Columns and Accessors.
            const detailsColumns = [
                { "Headers": 'Sr. No.', "accessor": 'sr_no' },
                { "Headers": 'PO Approved Date', "accessor": 'po_approved_date' },
                { "Headers": 'Purchase Order No', "accessor": 'purchase_order_no' },
                { "Headers": 'Supplier', "accessor": 'supp_branch_name' },
                { "Headers": 'Category1 Name', "accessor": 'product_category1_name' },
                { "Headers": 'Category2 Name', "accessor": 'product_category2_name' },
                { "Headers": 'Material Code', "accessor": 'material_code' },
                { "Headers": 'Material Name', "accessor": 'material_name' },
                { "Headers": 'PO Approved Quantity', "accessor": 'product_material_po_approved_quantity' },
                { "Headers": 'Pending Quantity', "accessor": 'pending_quantity' },
                { "Headers": 'Material Rate', "accessor": 'material_rate' }
            ];
            jsonToExportExcel['columns'] = detailsColumns;

            outstandingMaterialsDataExcel.map((orderDetail, Index) => {
                const keysConvertedOrderDetail = {
                    ...orderDetail,
                    sr_no: Index + 1,
                };
                jsonToExportExcel['allData'][Index] = keysConvertedOrderDetail;
            });
            // Call Common function for ExportToExcel
            await exlsExp.current.excel(jsonToExportExcel, `Purchase_Outstanding_Materials_Report_${fromDate}_to_${toDate}`);
        } catch (error) {
            console.log(error);

        } finally {
            setIsLoading(false);
        }

    }
    // ********* ExportToExcel Functionalities Ends. ****************************************

    // product filter columns
    let requiredCols = ['product_id', 'product_name', 'product_code'];

    const getMaterialBySearch = async (selectedData) => {
        if (selectedData !== null) {
            try {
                setIsLoading(true)
                let { product_id, product_name, product_code } = selectedData[0];
                let material_name = product_name;
                let material_code = product_code;
                let material_id = product_id;
                console.log("searched Material:  ", material_name, ' : ', material_code, ' : ', material_id);
                debugger
                if (by_material) {
                    setSearchMaterialId(product_id)
                    await FnShowStoreStockData(PageCurrent, entriesPerPage, product_id);
                    $('#error_selectMaterialRecords_id').hide();
                } else {
                    $('#error_selectMaterialRecords_id').text('Please fill checkbox first').show();
                }
            } catch (error) {
                console.error("Error in Search Material: ", error);
                setSearchMaterialId('')
                setIsLoading(false)
            } finally {
                setIsLoading(false)
            }
        } else {
            setOutstandingMaterialsData([]);
        }
    }

    const handlePageCountClick = async () => {
        let count = document.getElementById("page_entries_id").value;
        setEntriesPerPage(count)
        setcurrentPage(0)
        await FnShowStoreStockData(0, count);
        // await fetchFilteredData(0, count, selectedColumns.length !== 0 ? selectedColumns : availableColumns);
    }

    const handlePageClick = async (pageNo) => {
        debugger
        let currentPage = pageNo.selected;
        setcurrentPage(currentPage);
        let offset = currentPage * entriesPerPage;
        await FnShowStoreStockData(currentPage, entriesPerPage);
    }


    return (
        <>
            <DashboardLayout>
                <ComboBox ref={comboDataAPiCall} />
                <FrmValidations ref={validate} />
                <ValidateNumberDateInput ref={validateNumberDateInput} />
                <ExcelExport ref={exlsExp} />

                {isLoading ?
                    <div className="spinner-overlay"  >
                        <div className="spinner-container">
                            <CircularProgress color="primary" />
                            <span>Loading...</span>
                        </div>
                    </div> :
                    ''}

                <div className='card mt-4'>
                    <div className='card-header text-center py-0'>
                        <label className='erp-form-label-lg text-center'>Purchase Outstanding Materials</label>
                    </div>

                    {/* Card's Body Starts*/}
                    <div className="card-body">

                        <div className='row p-1'>
                            <div className='col-sm-4 erp_form_col_div'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Supplier :</Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Select
                                            ref={supplierComboRef}
                                            inputId="cmb_supplier_branch_id"
                                            value={supplierBranchOptions.find(option => option.value === cmb_supplier_branch_id) || null}
                                            options={supplierBranchOptions}
                                            onChange={(selectedOption) => {
                                                setSupplier_branch_id(selectedOption.value)
                                                supplierComboRef.current = selectedOption;
                                            }
                                            }
                                            placeholder="Search for Supplier..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"

                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                })
                                            }}
                                        />

                                        <MDTypography variant="button" id="error_cmb_supplier_branch_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>

                                </div>
                            </div>

                            <div className='col-sm-4 erp_form_col_div'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">From Date <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Form.Control type="date" id='dt_from_date' className="erp_input_field" value={dt_from_date} onChange={(e) => { setFromDt(e.target.value); FnChkIsValidFilters(); }} />
                                        <MDTypography variant="button" id="error_dt_from_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>

                            </div>
                            <div className='col-sm-4 erp_form_col_div'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">To Date <span className="required">*</span></Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Form.Control type="date" id='dt_to_date' className="erp_input_field" value={dt_to_date} onChange={(e) => { setToDt(e.target.value); FnChkIsValidFilters(); }} />
                                        <MDTypography variant="button" id="error_dt_to_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row p-1'>
                            <div className='col-sm-4 erp_form_col_div'>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <Form.Label className="erp-form-label">Product Type :</Form.Label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Select
                                            ref={productTypeComboRef}
                                            inputId="cmb_product_type_id"
                                            value={productTypeOptions.find(option => option.value === cmb_product_type_id) || null}
                                            options={productTypeOptions}
                                            onChange={(selectedOption) => {
                                                setProduct_type_id(selectedOption.value)
                                                productTypeComboRef.current = selectedOption;
                                            }
                                            }
                                            placeholder="Search for Product Type..."
                                            className="form-search-custom"
                                            classNamePrefix="custom-select"

                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                }),
                                                singleValue: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                }),
                                                input: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: '12px'
                                                })
                                            }}
                                        />

                                        <MDTypography variant="button" id="error_cmb_product_type_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-4 erp_form_col_div'>
                                <div className={`row`}>
                                    <div className="col-sm-4">
                                        <input type='checkbox' class="me-1" name="selectMaterialRecords" id="selectMaterialRecords" onChange={(e) => { e.target.checked ? setByMaterial(true) : setByMaterial(false); setSearchMaterialId('') }} /> <label class="erp-form-label pb-1">Search By Material </label>
                                    </div>
                                    <div className="col-sm-6">
                                        <MaterialSearchBar getSearchData={getMaterialBySearch} formIdForValidate="" requiredCols={requiredCols} product_type_id={cmb_product_type_id} />
                                        <MDTypography variant="button" id="error_selectMaterialRecords_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                        </MDTypography>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center mt-2">
                                <div className="col text-center">
                                    <MDButton type="button" id="show-data-btn" className="erp-gb-button" onClick={(e) => { FnShowStoreStockData(0, entriesPerPage); }} variant="button" fontWeight="regular">show Data</MDButton>
                                </div>
                            </div>
                        </div>
                        {
                            outstandingMaterialsData.length === 0
                                ? <> <hr /> <Card id="NoRcrdId" > <Card.Body>No records found...</Card.Body> </Card> </>
                                : <>
                                    <hr />
                                    <div className="row py-1">
                                        <div className="col-sm-6 page_entries">
                                            <MDTypography component="label" className="erp-form-label-md" variant="button" fontWeight="regular" color="dark" textTransform="capitalize">Entries per page</MDTypography>
                                            <Form.Select onChange={handlePageCountClick} value={entriesPerPage} className="erp_page_select erp_form_control" id="page_entries_id" >
                                                {pageEntriesOptions.map(pageEntriesOptions => (
                                                    <option value={pageEntriesOptions.value}>{pageEntriesOptions.label}</option>

                                                ))}
                                            </Form.Select>
                                        </div>
                                        <div className="col-sm-6 text-end">
                                            <MDButton type="button" variant="button" fontWeight="regular" className="erp-gb-button"
                                                onClick={(e) => { FnExportToExcel(); }}
                                            >Export Excel</MDButton>

                                            <MDButton type="button" variant="button" fontWeight="regular" className="erp-gb-button ms-1"
                                                onClick={(e) => { handlePrint(); }}
                                            >Print</MDButton>
                                        </div>
                                    </div>
                                    <div className="row px-lg-2 d-block">
                                        <div className="card p-0">
                                            {/* Card's Body Starts*/}
                                            <div className="card-body p-0 print-content" ref={printComponentRef}>
                                                <OutstandingMaterialsTbl
                                                    outstandingMaterialsData={outstandingMaterialsData}
                                                    validateNumberDateInput={validateNumberDateInput}
                                                />

                                                <ReactPaginate
                                                    className='erp_pagination'
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={3}
                                                    pageCount={pageCount}
                                                    onPageChange={handlePageClick}
                                                    containerClassName={"pagination justify-content-center"}
                                                    pageClassName={"page-item"}
                                                    pageLinkClassName={"page-link erp-gb-button"}
                                                    previousClassName={"page-item"}
                                                    previousLinkClassName={"page-link erp-gb-button"}
                                                    nextClassName={"page-item"}
                                                    nextLinkClassName={"page-link erp-gb-button"}
                                                    breakClassName={"page-item"}
                                                    breakLinkClassName={"page-link"}
                                                    activeClassName={"active"} />
                                            </div>
                                        </div>
                                    </div>
                                </>
                        }

                        <hr />
                        <div className="row text-center mt-3">
                            <div className=''>
                                <MDButton type="button" id="back-btn" variant="button" fontWeight="regular"
                                    className={`erp-gb-button float-center`}
                                    onClick={(e) => { goBack(); }}  > Back </MDButton>

                                {
                                    outstandingMaterialsData.length === 0
                                        ? null
                                        : <>
                                            <MDButton type="button" variant="button" fontWeight="regular"
                                                className="erp-gb-button ms-2"
                                                onClick={(e) => { handlePrint(); }} >Print</MDButton>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                    {/* Card's Body Ends*/}
                </div>
                {/* Card Ends*/}


            </DashboardLayout>
        </>
    )
}

export default OutstandingMaterialsForPOReports




export const OutstandingMaterialsTbl = ({
    outstandingMaterialsData,
    validateNumberDateInput,
}) => {

    return (
        <>
            <style>
                {`
                    @media print {
                        .print-content {
                            padding: 0;
                            box-sizing: border-box;
                        }
                        @page {
                            margin: 0;
                            size: legal landscape;
                            margin: 1%;
                            border: 2px solid black;
                        }
                        html, body {
                            width: 100%;
                            height: 100%;
                        }
                        body {
                            margin: 0;
                            padding: 0;
                        }
                    }
                    .erp_table {
                        font-size: 3px !important; /* Default font size */
                    }
                    .erp_table_th, .erp_table_td {
                        border: 1px solid black;
                        padding-top: 2px !important;
                        padding-bottom: 2px !important;
                    }
                `}
            </style>

            <Table id="issueReportTableId" className="erp_table" style={{ whiteSpace: 'normal' }}>
                <thead className="erp_table_head table-secondary text-center">
                    <tr style={{ whiteSpace: 'normal' }}>
                        <th className="erp_table_th align-middle" style={{ width: "50px" }} >Sr. No</th>
                        <th className="erp_table_th align-middle" >Purchase Order Date</th>
                        <th className="erp_table_th align-middle" style={{ width: "150px" }}>Purchase Order No</th>
                        <th className={`erp_table_th align-middle `} style={{ width: "200px" }}>Supplier</th>
                        <th className={`erp_table_th align-middle `} style={{ width: "150px" }} >Category1 Name</th>
                        <th className={`erp_table_th align-middle `} style={{ width: "100px" }} >Category2 Name</th>
                        <th className={`erp_table_th align-middle `} style={{ width: "100px" }} >Material Code</th>
                        <th className={`erp_table_th align-middle `} style={{ width: '360px' }} >Material Name</th>
                        <th className="erp_table_th align-middle" >PO Approved Quantity</th>
                        <th className="erp_table_th align-middle" >Pending Quantity</th>
                        <th className="erp_table_th align-middle" >Material Rate</th>
                    </tr>

                </thead>
                <tbody>
                    <React.Fragment>
                        {outstandingMaterialsData.map((row, index) => (
                            <tr rowindex={index}>
                                <td className="erp_table_td text-end" > {index + 1} </td>
                                <td className={`erp_table_td `}> {validateNumberDateInput.current.formatDateToDDMMYYYY(row.po_approved_date)} </td>
                                <td className={`erp_table_td `} > {row.purchase_order_no} </td>
                                <td className={`erp_table_td `} style={{ wordWrap: 'normal' }}> {row.supp_branch_name} </td>
                                <td className={`erp_table_td `} style={{ whiteSpace: 'normal' }}> {row.product_category1_name} </td>
                                <td className={`erp_table_td `} style={{ whiteSpace: 'normal' }}> {row.product_category2_name} </td>
                                <td className={`erp_table_td `}> {row.material_code} </td>
                                <td className={`erp_table_td `} style={{ whiteSpace: 'normal' }}> {row.material_name} </td>
                                <td className="erp_table_td text-end"> {row.product_material_po_approved_quantity} </td>
                                <td className="erp_table_td text-end"> {row.pending_quantity} </td>
                                <td className="erp_table_td text-end"> {row.material_rate} </td>
                            </tr>
                        ))}
                    </React.Fragment>
                </tbody>
            </Table>
        </>
    );
};