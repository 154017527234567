import React, { useState, useRef, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import $ from 'jquery';

// Imports React bootstrap
import Tooltip from "@mui/material/Tooltip";
import Form from 'react-bootstrap/Form';
import { Accordion, Modal, Table, Button } from "react-bootstrap";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// React icons
import { MdDelete } from "react-icons/md";
import { RxCrossCircled } from "react-icons/rx";


//File Imports
import FrmValidations from "FrmGeneric/FrmValidations";
import SuccessModal from "components/Modals/SuccessModal";
import ErrorModal from "components/Modals/ErrorModal"
import ComboBox from "Features/ComboBox";
import { globalQuery, resetGlobalQuery } from "assets/Constants/config-constant"
import ConfigConstants from "assets/Constants/config-constant";
import GenerateTAutoNo from "FrmGeneric/GenerateTAutoNo";
import ExcelExport from "Features/Exports/ExcelExport";
import { CircularProgress } from "@material-ui/core";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ValidateNumberDateInput from "FrmGeneric/ValidateNumberDateInput";

import SearchItemFilter from "FrmGeneric/SearchItemFilter";

// Import for the searchable combo box.
import Select from 'react-select';

function FrmMaterialTransferRequisitionEntry() {
    const configConstants = ConfigConstants();
    const { FINANCIAL_SHORT_NAME, COMPANY_ID, COMPANY_BRANCH_ID, UserId, UserName } = configConstants;
    

    const { state } = useLocation();
    const { idList, keyForViewUpdate, compType } = state || {};
    const [isLoading, setIsLoading] = useState(false);
    const [openIssueDetailsAccord, setOpenIssueDetailsAccord] = useState(true);
    let docGroup = "Inter-Unit Transfer"

    // For navigate
    const navigate = useNavigate();

    // UseRefs
    const validate = useRef();
    const comboDataAPiCall = useRef();
    const generateAutoNoAPiCall = useRef();
    const exlsExp = useRef();
    const validateNumberDateInput = useRef();

    // for today date formatted
    const todayDate = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    const currentDate = new Date().toISOString().split('T')[0];

    //form combo options
    const [materialtypeOptions, setmaterialtypeOptions] = useState([])
    const [companyOptions, setcompanyOptions] = useState([])
    const [toCompanyOptions, setToCompanyOptions] = useState([])
    const [employeeOptions, setEmployeeOptions] = useState([])
    const [cmb_transfered_by_id, setTransferedById] = useState(UserId)

    //Form Fields
    const [cmb_material_type, setmaterialType] = useState('');
    const [materialId, setMaterialId] = useState('')
    const [txt_transfer_no, setTransferNo] = useState('')
    const [txt_transfer_version, setTransferVersion] = useState('1')
    const [dt_transfer_date, setTransferDate] = useState(todayDate)
    const [transferIssue_master_transaction_id, setTransferIssueMasterTransaction_id] = useState(0);
    const [cmb_from_company_id, setFromCompanyId] = useState();
    const [cmb_to_comapany_id, setToCompanyId] = useState('');
    const [materialTransferDetailsData, setMaterialTransferDetailsData] = useState([])

    useEffect(async () => {
        setIsLoading(true);
        try {
            FnSetFieldUserAccess();
            removeSessions('onload');
            await fillCombos();
            if (idList === '' || idList === undefined) {
                await GenerateTransferNo();
            } else {
                await FnCheckUpdateResponse();
            }
            FnSetFieldUserAccess();
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }, [])
    // delete popup
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    //Error Msg
    const handleCloseErrModal = () => setShowErrorMsgModal(false);
    const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [showSuccessMsgModal, setShowSuccessMsgModal] = useState(false);
    const [succMsg, setSuccMsg] = useState('');
    const [modalOrderDetails, setModalOrderDetails] = useState('')

    const closeConfirmationModal = async () => {
        setShowSuccessMsgModal(false)
        moveToListing();
    };

    // Document Form
    const [showDocumentForm, setShowDocumentForm] = useState(false);
    const handleCloseDocumentForm = async () => {
        await showDocumentRecords();
        setShowDocumentForm(false)
    };
    const viewDocumentForm = () => {
        if (keyForViewUpdate !== 'approve' && keyForViewUpdate !== 'view') {
            setShowDocumentForm(true);
        }
    }

    // doc list
    const [docData, setDocumentData] = useState([]);

    // -------------------------------End Common terms -------------------------------------------------------------------------

    const fillCombos = async () => {
        try {
            console.log(`COMPANYID:${COMPANY_ID}`)
            setFromCompanyId(parseInt(COMPANY_ID))

            resetGlobalQuery();
            globalQuery.columns = ['field_id', 'field_name'];
            globalQuery.table = "cmv_company";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            let getCompanies = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
            setcompanyOptions(getCompanies)
            setToCompanyOptions(getCompanies.filter((company) => company.field_id !== Number(COMPANY_ID)))

            resetGlobalQuery();
            globalQuery.columns.push("field_id");
            globalQuery.columns.push("field_name");
            globalQuery.table = "smv_product_type"
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            // globalQuery.conditions.push({ field: "product_type_group", operator: "IN", values: ["RM", "FG"] });
            const prodTypeOpts = await comboDataAPiCall.current.fillFiltersCombo(globalQuery)
            console.log(prodTypeOpts)
            const options = [
                { value: '', label: 'Select', },
                ...prodTypeOpts.map((prodType) => ({ ...prodType, value: prodType.field_id, label: prodType.field_name })),
            ];
            setmaterialtypeOptions(options)

            resetGlobalQuery();
            globalQuery.columns.push("employee_id");
            globalQuery.columns.push("employee_name");
            globalQuery.table = "cm_employee";
            globalQuery.conditions.push({ field: "is_delete", operator: "=", value: 0 });
            globalQuery.conditions.push({ field: "is_active", operator: "=", value: 1 });
            // globalQuery.conditions.push({ field: "employee_type_group", operator: "!=", value: "Workers" });
            const requestedbyIdApiCall = await comboDataAPiCall.current.fillFiltersCombo(globalQuery);
            const employeeOptions = [
                { value: '', label: 'Select', },
                ...requestedbyIdApiCall.map((empl) => ({ ...empl, value: empl.employee_id, label: empl.employee_name })),
            ];
            setEmployeeOptions(employeeOptions);
            setIsLoading(false)

        }
        catch (error) {
            console.log("error: ", error)
        }
    }
    const FnSetFieldUserAccess = () => {
        if (keyForViewUpdate != '') {
            switch (keyForViewUpdate) {
                case 'view':
                    $('input[type="checkbox"]').prop('readonly', true);
                    $("input[type=radio]").attr('disabled', true);
                    $("form").find("input,textarea,select").attr("disabled", "disabled");
                    $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                    $("table").find('input[id^="indent_quantity_"]').attr("disabled", "disabled");
                    break;
                    
                case 'delete':
                    $('input[type="checkbox"]').prop('readonly', true);
                    $("input[type=radio]").attr('disabled', true);
                    $("form").find("input,textarea,select").attr("disabled", "disabled");
                    $("table").find("input,button,textarea,select").attr("disabled", "disabled");
                    $('#viewdocument-id').hide();
                    $("table").find('input[id^="indent_quantity_"]').attr("disabled", "disabled");
                    break;

                default:
                    break;
            }
        }
    }

    //fn to get po auto no
    const GenerateTransferNo = async () => {
        const autoNoApiCall = await generateAutoNoAPiCall.current.generateTAutoNo("pt_material_transfer_master", "transfer_no", "", 'MT', "5");
        if (keyForViewUpdate === '') {
            setTransferNo(autoNoApiCall);
        }
        return autoNoApiCall;
    };
    //-------------------------------------- Combo changes ------------------------------------------------------------
    const comboOnChange = async (key) => {
        switch (key) {
            case 'to_company':
                var toCompanyValue = document.getElementById('cmb_to_comapany_id').value;
                setToCompanyId(toCompanyValue)
                break;


            default:
                break;
        }
    }


    //--------------------------------------------------------Start Details section----------------------------------------------------------------------

    const removeSessions = (reloadData) => {
        sessionStorage.removeItem(`comboFilterData`)
        sessionStorage.removeItem('isComboFilterExist')
        sessionStorage.removeItem('smv_product_type');
        sessionStorage.removeItem('smv_product_material_type');
        sessionStorage.removeItem('smv_product_material_grade');
        sessionStorage.removeItem('smv_product_material_shape');
        if (reloadData === 'onload') {
            sessionStorage.removeItem('filteredMaterialData')
        }
        for (let removeItemIndex = 1; removeItemIndex <= 5; removeItemIndex++) {
            sessionStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex);
            localStorage.removeItem('smv_product_category' + removeItemIndex + 'Name');
        }

        localStorage.removeItem('smv_product_material_type');
        localStorage.removeItem('smv_product_material_grade');
        localStorage.removeItem('smv_product_material_shape');
        localStorage.removeItem('smv_product_type');

        localStorage.removeItem('smv_product_material_typeName');
        localStorage.removeItem('smv_product_material_gradeName');
        localStorage.removeItem('smv_product_material_shapeName');
        localStorage.removeItem('smv_product_typeName');
    }

    const categoryDisplay = () => {

        const catCount = sessionStorage.getItem('compRMCatCount')
        for (let catCountIndex = 1; catCountIndex <= catCount; catCountIndex++) {
            $('.cat' + catCountIndex + 'Id').show();
        }
    }

    const FnClearFormFields = async () => {

        sessionStorage.removeItem('filteredMaterialData')
        setmaterialType("")
        setToCompanyId("")
        setMaterialTransferDetailsData([])
    }

    const moveToListing = () => {
        const Path = compType === 'Register' ? '/Transactions/TPurchaseOrder/MaterialTransferRequisition/FrmMaterialTransferRequisitionListing/reg' : `/Transactions/TPurchaseOrder/MaterialTransferRequisition/FrmMaterialTransferRequisitionListing`;
        navigate(Path);
    }


    $(document).on('click', '.remove_input_zero', function () {
        if ($(this).val() === "0") {
            $(this).val("")
        }
    });
    $(document).on('mouseup mousedown', function (e) {
        let inputBoxes = $(".remove_input_zero");
        inputBoxes.each(function () {
            if ($(this).val() === "") {
                $(this).val("0");
            }
        });
    });



    //---------------------------------------------------- Accordian data load starts --------------------------------------------------------------------------------
    const FnLoadAccordionData = async (eventKey) => {
        switch (eventKey) {
            case 'documentList':
                await showDocumentRecords();
                break;
            default:
                break;
        }
    }
    //---------------------------------------------------- Accordian data load Ends --------------------------------------------------------------------------------
    // Fn for get document list
    const showDocumentRecords = async () => {
        try {
            if (COMPANY_ID !== null) {
                const res = await fetch(
                    // `${process.env.REACT_APP_BASE_URL}/api/Documents/FnShowParticularRecord?document_group=${docGroup}&group_id=${txt_material_issue_no !== 0 ? txt_material_issue_no.replaceAll('/', '_') : null}`
                );
                const resp = await res.json();
                if (resp.content.length > 0) {
                    setDocumentData(resp.content);
                }
            }

        } catch (error) {
            console.log("error: ", error)
        }
    }

    // Fn for get document table
    const renderDocumentTable = useMemo(() => {
        return <>
            <Table className="erp_table " id='document-table' responsive bordered striped>
                <thead className="erp_table_head">
                    <tr>
                        <th className="erp_table_th">Group Id</th>
                        <th className="erp_table_th">Document Group</th>
                        <th className="erp_table_th">Document Name</th>
                        <th className="erp_table_th">Registration No</th>
                        <th className="erp_table_th">Registration Date</th>
                        <th className="erp_table_th">Renewal Date</th>
                        <th className="erp_table_th">Document Path</th>
                        <th className="erp_table_th">Remark</th>
                        <th className="erp_table_th">File Name</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        docData.map((docItem, Index) =>
                            <tr rowIndex={Index}>
                                <td className="erp_table_td"> {docItem.group_id} </td>
                                <td className="erp_table_td">{docItem.document_group}</td>
                                <td className="erp_table_td">{docItem.document_name}</td>
                                <td className="erp_table_td ">{docItem.document_registration_no}</td>
                                <td className="erp_table_td ">{validateNumberDateInput.current.formatDateToDDMMYYYY(docItem.document_registration_date)}</td>
                                <td className="erp_table_td ">{validateNumberDateInput.current.formatDateToDDMMYYYY(docItem.document_renewal_date)}</td>
                                <td className="erp_table_td ">{docItem.document_path}</td>
                                <td className="erp_table_td ">{docItem.remark}</td>
                                <td className="erp_table_td">
                                    <MDTypography
                                        component="label"
                                        className="erp-form-label"
                                        variant="button"
                                        id="logoFile"
                                        fontWeight="regular"
                                        color="info"
                                        onClick={() => fetchDocument(docItem)}
                                        style={{ cursor: 'pointer' }} // Change cursor to pointer to indicate clickable
                                        onMouseEnter={(e) => e.target.style.color = 'info'} // Change color on hover
                                        onMouseLeave={(e) => e.target.style.color = 'initial'} // Restore original color when not hovering
                                    >
                                        {docItem.file_name}
                                    </MDTypography>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </>
    }, [docData]);

    // Fn for get document download
    const fetchDocument = async (docItem) => {
        try {
            const formData = new FormData()
            const data = {
                document_group: docItem.documentGroup,
                group_id: docItem.group_id,
                document_path: docItem.document_path
            }
            formData.append(`getFile`, JSON.stringify(data))
            const requestOptions = {
                method: 'POST',
                body: formData
            };

            const getDocApiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/Documents/FnGetDocument`, requestOptions)
            const blob = await getDocApiCall.blob()

            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${docItem.file_name}`,);
            document.body.appendChild(link);

            link.click();
            link.parentNode.removeChild(link);


        } catch (error) {
            console.log("error: ", error)
        }
    }

    //key for get opration
    const ActionType = () => {
        switch (keyForViewUpdate) {
            case 'view':
                return '(View)';
            default:
                return '(Creation)';
        }
    }

    let requiredCols = ['product_material_name',
        'product_material_id',
        'product_code',
        'product_type_group',
        'stock_quantity',
        'stock_weight',
        'product_unit_name',
        'product_material_std_weight',
        'godown_name',
        'godown_section_name',
        'godown_section_beans_name',
        'godown_id',
        'godown_section_id',
        'godown_section_beans_id',
        'product_material_unit_id',
        "product_unit_name",
        'product_unit_id',
    ];

    const fnSearchMaterialData = async () => {
        try {
            debugger
            setIsLoading(true)
            const getData = JSON.parse(sessionStorage.getItem('filteredMaterialData')) || [];
            if (getData.length !== 0) {
                let material_id = getData[0].product_material_id;
                setMaterialId(material_id)
                setMaterialTransferDetailsData((prev) => [
                    ...prev,
                    ...getData.map((item) => ({ ...item, transfer_quantity: 0 }))
                  ]);
                sessionStorage.removeItem('filteredMaterialData');
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error);
        }
    }


    const handleIssueDetailsInputChange = (currentRowData, event) => {
        const arrayIndex = parseInt(event.target.parentElement.parentElement.getAttribute('rowIndex'))
        let focusedInputField = document.querySelector('#' + event.target.id);
        let clickedColName = event.target.getAttribute('Headers');
        let { stock_quantity, product_material_std_weight, product_material_issue_quantity, prev_product_material_return_quantity, material_rate } = currentRowData;
        let enteredValue = event.target.value;
        if (isNaN(enteredValue)) {
            enteredValue = 0;
        } else if (enteredValue != 0 || enteredValue != "") {
            delete focusedInputField.parentElement.dataset.tip;
        }
        switch (clickedColName) {
            case 'transfer_quantity':
                debugger;
                if (enteredValue !== "") {
                    const parsedValue = parseInt(enteredValue, 10);
                    const parsedStockQuantity = parseInt(stock_quantity, 10);

                    if (parsedValue === 0) {
                        focusedInputField.parentElement.dataset.tip = `Cannot be 0...!`;
                        return false;
                    } else if (parsedValue > parsedStockQuantity) {
                        focusedInputField.parentElement.dataset.tip = `Cannot be greater than ${stock_quantity}...!`;
                        return false;
                    } else {
                        currentRowData[clickedColName] = enteredValue;
                        currentRowData.closing_balance_quantity = parsedStockQuantity - parsedValue;
                        if (currentRowData.stock_weight !== 0) {
                            currentRowData.closing_balance_weight = currentRowData.stock_weight * currentRowData.transfer_weight;
                            currentRowData.transfer_weight = currentRowData.product_material_std_weight * parsedValue;
                            currentRowData.closing_balance_weight = currentRowData.stock_weight - currentRowData.transfer_weight;
                        } else {
                            currentRowData.closing_balance_weight = 0;
                            currentRowData.transfer_weight = 0;
                            currentRowData.closing_balance_weight = 0;
                        }

                        // Remove tooltip if no errors
                        delete focusedInputField.parentElement.dataset.tip;
                    }
                } else {
                    currentRowData[clickedColName] = enteredValue;
                    focusedInputField.parentElement.dataset.tip = `Please Enter Quantity...!`;
                }

                // Update the material transfer details data
                setMaterialTransferDetailsData((prevArr) => [
                    ...prevArr.filter(
                        (material) =>
                            material.material_transfer_master_id !== currentRowData.material_transfer_master_id
                    ),
                    currentRowData,
                ]);
                break;
            default:
                break;
        }
        const detailData = [...materialTransferDetailsData]
        detailData[arrayIndex] = currentRowData;
        setMaterialTransferDetailsData(detailData);
    }

    const deleteRecord = (Index, action) => {
        setMaterialTransferDetailsData((prevDetailsData) => prevDetailsData.filter((data, i) => i !== Index))
    }

    //details table
    const renderDetailsTable = useMemo(() => {
        return <>
            <Table className="erp_table erp_table_scroll" id='issue-details-tbl' responsive bordered striped>
                <thead className="erp_table_head">
                    <tr>

                        {keyForViewUpdate === "" && <th className="erp_table_th">Action</th>}
                        <th className="erp_table_th">Sr.no</th>
                        <th className="erp_table_th">Material Code</th>
                        <th className="erp_table_th">Material Name</th>
                        <th className="erp_table_th">Stock Quantity</th>
                        <th className="erp_table_th">Transfer Quantity</th>
                        <th className="erp_table_th">Closing Quantity</th>
                        <th className={`erp_table_th ${keyForViewUpdate === "return" ? 'display' : 'd-none'}`}>Prev Return Quantity</th>
                        <th className="erp_table_th">Godown name</th>
                        <th className="erp_table_th">Godown Section</th>
                        <th className="erp_table_th">Godown Beans</th>
                        <th className="erp_table_th">Stock Weight</th>
                        <th className="erp_table_th">Transfer Weight</th>
                        <th className="erp_table_th">Closing Weight</th>
                        <th className="erp_table_th">Material Unit</th>
                        <th className="erp_table_th">Std. Weight</th>
                    </tr>
                </thead>
                <tbody>
                    {materialTransferDetailsData.map((issueItem, Index) =>
                        <tr rowindex={Index} className="sticky-column">
                            {keyForViewUpdate === "" && 
                            <td className="erp_table_td" style={{verticalAlign:"middle" }} ><Tooltip title="Delete" placement="top">
                                <MDTypography  className="erp-view-btn" >
                                    <MdDelete sty className={`erp-delete-btn`} style={{ display: "flex", margin: "auto"}} onClick={() => deleteRecord(Index)} />
                                </MDTypography></Tooltip>
                            </td>}

                            <td className="erp_table_td text-end sticky-column">{Index + 1}</td>
                            <td className="erp_table_td "> {issueItem.product_code}</td>
                            <td className="erp_table_td "> {keyForViewUpdate === "" ? issueItem.product_material_name : issueItem.product_rm_name} </td>
                            <td className={`erp_table_td text-end sticky-column`}>{issueItem.stock_quantity}</td>
                            <td className="erp_table_td text-end">
                                {keyForViewUpdate === '' ?
                                    <input type="text" id={`transfer_quantity_${issueItem.product_material_id}`} className="remove_input_zero erp_input_field text-end remove0 mb-0 issueField"
                                        value={issueItem.transfer_quantity} onChange={(e) => { handleIssueDetailsInputChange(issueItem, e); }} Headers='transfer_quantity' />
                                    :
                                    issueItem.transfer_quantity
                                }
                            </td>
                            <td className={`erp_table_td text-end sticky-column`}>{issueItem.closing_balance_quantity}</td>
                            <td className="erp_table_td "> {issueItem.godown_name} </td>
                            <td className="erp_table_td "> {issueItem.godown_section_name} </td>
                            <td className="erp_table_td "> {issueItem.godown_section_beans_name} </td>
                            <td className="erp_table_td text-end" id={`stock_weight_${issueItem.product_material_id}`}>{issueItem.stock_weight}</td>
                            <td className="erp_table_td text-end" id={`transfer_weight_${issueItem.product_material_id}`}>{issueItem.transfer_weight}</td>
                            <td className={`erp_table_td text-end `} id={`closing_balance_weight_${issueItem.product_material_id}`}>{issueItem.closing_balance_weight}</td>
                            <td className={`erp_table_td text-end `}>{issueItem.product_unit_name}</td>
                            <td className={`erp_table_td text-end `}>{issueItem.product_material_std_weight}</td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </>

    }, [materialTransferDetailsData])

    //-------------------------------- validate before save ------------------------------------------------------

    const FnValidateTransferIssue = async () => {
        debugger
        let headerDataIsValid = await validate.current.validateForm("transfer_issue_form_id");
        if (!headerDataIsValid) { return false; }
        let issueDetailsIsValid = true;
        let addedIssuematerial = $('#issue-details-tbl tbody tr').length;
        if (addedIssuematerial <= 0 && keyForViewUpdate !== 'view') {
            setErrMsg('Please Add Atleast One Material Detail.');
            setShowErrorMsgModal(true);
            return issueDetailsIsValid = false;
        }
        else {
            const tableRows = $('#issue-details-tbl tbody tr');
            issueDetailsIsValid = true;
            tableRows.each(function () {
                if (keyForViewUpdate === "") {

                    const issueQty = parseFloat($(this).find('input[id^="transfer_quantity_"]').val()) || 0;

                    if (isNaN(issueQty) || issueQty === "" || issueQty === "0" || issueQty === 0) {
                        $(this).find(`input[id^='transfer_quantity_']`).parent().attr('data-tip', 'Quantity cannot be 0...!');
                        $(this).find(`input[id^='transfer_quantity_']`).focus();
                        setOpenIssueDetailsAccord(true)
                        issueDetailsIsValid = false;
                        return false;
                    }
                }
            });
            return issueDetailsIsValid;
        }
    }

    //-------------------------- Save Json ------------------------------------------------------------------

    const addMaterialTransfer = async (status) => {
        try {
            debugger
            let checkIsValidate = true;
            if (keyForViewUpdate !== "view" && keyForViewUpdate !== "delete") {
                // let validateSearchableCombos = await FnChkIsValidFilters();
                let validateSelectBoxes = await FnValidateTransferIssue();
                checkIsValidate = validateSelectBoxes
            }
            if (checkIsValidate === true) {
                setIsLoading(true)
                let lastTransferIssueNo = txt_transfer_no;
                if (keyForViewUpdate === "") {
                    lastTransferIssueNo = await GenerateTransferNo();
                }
                let json = { 'TransMasterData': {}, 'TransDetailData': [], 'commonIds': {} }

                const transferMasterFormData = {
                    material_transfer_master_id: keyForViewUpdate !== "" ? transferIssue_master_transaction_id : 0,
                    material_transfer_type_id: cmb_material_type,
                    company_id: COMPANY_ID,
                    company_branch_id: COMPANY_BRANCH_ID,
                    transfer_no: lastTransferIssueNo,
                    transfer_by_id: cmb_transfered_by_id,
                    financial_year: FINANCIAL_SHORT_NAME,
                    transfer_date: dt_transfer_date,
                    transfer_version: txt_transfer_version,
                    from_company_id: cmb_from_company_id,
                    from_company_branch_id: COMPANY_BRANCH_ID,
                    to_company_id: cmb_to_comapany_id,
                    to_company_branch_id: 0,
                    created_by: UserName,
                    created_on: todayDate(),
                    modified_by: UserName,
                    modified_on: todayDate(),
                    modified_by: idList !== "" ? UserName : null,
                }
                json.TransMasterData = transferMasterFormData;

                for (let issueItem = 0; issueItem < materialTransferDetailsData.length; issueItem++) {
                    const transferDetailsRow = materialTransferDetailsData[issueItem];

                    const MaterialtransferRow = {};
                    MaterialtransferRow['material_transfer_details_id'] = keyForViewUpdate !== "" ? transferDetailsRow.material_transfer_details_id : 0
                    MaterialtransferRow['material_transfer_master_id'] = keyForViewUpdate !== "" ? transferIssue_master_transaction_id : 0
                    MaterialtransferRow['material_transfer_type_id'] = cmb_material_type
                    MaterialtransferRow['company_id'] = COMPANY_ID
                    MaterialtransferRow['company_branch_id'] = COMPANY_BRANCH_ID
                    MaterialtransferRow['financial_year'] = FINANCIAL_SHORT_NAME
                    MaterialtransferRow['transfer_no'] = lastTransferIssueNo
                    MaterialtransferRow['transfer_date'] = dt_transfer_date
                    MaterialtransferRow['transfer_version'] = txt_transfer_version
                    MaterialtransferRow['product_material_id'] = transferDetailsRow.product_material_id
                    MaterialtransferRow['product_code'] = transferDetailsRow.product_code

                    MaterialtransferRow['stock_quantity'] = transferDetailsRow.stock_quantity
                    MaterialtransferRow['stock_weight'] = transferDetailsRow.stock_weight
                    MaterialtransferRow['transfer_quantity'] = transferDetailsRow.transfer_quantity
                    MaterialtransferRow['transfer_weight'] = transferDetailsRow.transfer_weight
                    MaterialtransferRow['closing_balance_quantity'] = transferDetailsRow.closing_balance_quantity;;
                    MaterialtransferRow['closing_balance_weight'] = transferDetailsRow.closing_balance_weight
                    MaterialtransferRow['godown_id'] = transferDetailsRow.godown_id
                    MaterialtransferRow['godown_section_id'] = transferDetailsRow.godown_section_id
                    MaterialtransferRow['godown_section_beans_id'] = transferDetailsRow.godown_section_beans_id
                    MaterialtransferRow['product_unit_id'] = transferDetailsRow.product_unit_id
                    MaterialtransferRow['product_material_std_weight'] = transferDetailsRow.product_material_std_weight
                    MaterialtransferRow['created_by'] = UserName
                    MaterialtransferRow['created_on'] = todayDate()
                    MaterialtransferRow['modified_by'] = UserName
                    MaterialtransferRow['modified_on'] = todayDate()

                    json.TransDetailData.push(MaterialtransferRow);
                }
                // Common Ids
                debugger
                json.commonIds.company_id = COMPANY_ID
                json.commonIds.transfer_no = keyForViewUpdate === '' ? lastTransferIssueNo : txt_transfer_no
                json.commonIds.financial_year = FINANCIAL_SHORT_NAME
                const formData = new FormData();
                formData.append(`PtMaterialTransferData`, JSON.stringify(json))
                const requestOptions = {
                    method: 'POST',
                    body: formData
                };


                const apicall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/materialTransfer/FnAddUpdateRecord/${COMPANY_ID}`, requestOptions);
                const responce = await apicall.json();

                if (responce.success === "0") {
                    $('#save-btn').prop("disable", true);
                    $('#issue-btn').prop("disable", true);
                    setErrMsg(responce.error)
                    setShowErrorMsgModal(true)

                } else {
                    if (responce) {
                        console.log(responce)
                        setModalOrderDetails(`Transfer No: ${responce.body.responseMaterialTransferMaster.transfer_no} and Version: ${responce.body.responseMaterialTransferMaster.transfer_version}`)
                    }
                    setSuccMsg(responce.message)
                    setShowSuccessMsgModal(true);
                }
                setIsLoading(false)
            }
        } catch (error) {
            console.log("error: ", error)
        } finally {
            setIsLoading(false)
        }

    }
    // ------------------------------------------- set update data ----------------------------------------------------


    const FnCheckUpdateResponse = async () => {

        try {
            if (idList !== '') {
                debugger
                setTransferNo(idList.transfer_no)
                const apiCall = await fetch(`${process.env.REACT_APP_BASE_URL}/api/materialTransfer/FnShowParticularRecord/${parseInt(idList.company_id)}?transfer_no=${idList.transfer_no}`);
                const responce = await apiCall.json();
                console.log('Material transfer data: ', responce);

                let transferMasterdata = responce.TransferMasterData;
                if (transferMasterdata !== null && transferMasterdata !== "") {
                    setTransferNo(transferMasterdata.transfer_no)
                    setTransferVersion(transferMasterdata.transfer_version)
                    setTransferDate(transferMasterdata.transfer_date)
                    setmaterialType(transferMasterdata.material_transfer_type_id)
                    setFromCompanyId(transferMasterdata.from_company_id)
                    setToCompanyId(transferMasterdata.to_company_id)
                    setTransferedById(transferMasterdata.transfer_by_id)
                }
                if (responce.TransferDetailsData !== null && responce.TransferDetailsData !== "") {
                    setMaterialTransferDetailsData(responce.TransferDetailsData)
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    const validateFields = async () => {
        validate.current.validateFieldsOnChange('transfer_issue_form_id')
    }


    // ------------------------ Changes for the Searchable Combos. --------------------------

    let transferedByComboRef = useRef(null);
    const materialTypeComboRef = useRef(null);

    return (
        <>
            <ComboBox ref={comboDataAPiCall} />
            <ExcelExport ref={exlsExp} />
            <GenerateTAutoNo ref={generateAutoNoAPiCall} />
            <FrmValidations ref={validate} />
            <ValidateNumberDateInput ref={validateNumberDateInput} />
            <DashboardLayout>
                <>
                    {isLoading ?
                        <div className="spinner-overlay"  >
                            <div className="spinner-container">
                                <CircularProgress color="primary" />
                                <span>Loading...</span>
                            </div>
                        </div> :
                        ''}

                    <div className='card p-1'>
                        <div className='card-header text-center py-0'>
                            <label className='erp-form-label-lg main_heding'> Inter-Unit Transfer {ActionType()}</label>
                        </div>
                        <form id="transfer_issue_form_id">
                            <div className="row p-1">
                                {/* //first column */}
                                <div className="col-sm-6 erp_form_col_div">
                                    <div className='row'>
                                        <div className='col-sm-3 col-12'>
                                            <Form.Label className="erp-form-label">Transfer No,Version & Date</Form.Label>
                                        </div>
                                        <div className='col-sm-9 col-12'>
                                            <div className="row">
                                                <div className='col-12 col-md-6 pe-md-0'>
                                                    <Form.Control type="text" id="txt_transfer_no" className="erp_input_field" value={txt_transfer_no} optional='optional' disabled />
                                                </div>
                                                <div className="col-12 col-md-2 pt-md-0 pt-3">
                                                    <Form.Control type="text" id='txt_transfer_version' className="erp_input_field" value={txt_transfer_version} optional='optional' disabled />
                                                </div>
                                                <div className="col-12 col-md-4 pt-md-0 pt-3">
                                                    <Form.Control type="date" id='dt_transfer_date' className="erp_input_field" value={dt_transfer_date} onChange={e => { setTransferDate(e.target.value); validateFields(); }} min={currentDate} disabled />
                                                    <MDTypography variant="button" id="error_dt_transfer_date" className="erp_validation error-msg" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                    </MDTypography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-3 col-12'>
                                            <Form.Label className="erp-form-label">Material Type<span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col-sm-9 col-12'>

                                            <Select
                                                ref={materialTypeComboRef}
                                                options={materialtypeOptions}
                                                isDisabled={['view', 'approve'].includes(keyForViewUpdate)}
                                                inputId="cmb_material_type" // Provide the ID for the input box
                                                value={materialtypeOptions.find(option => option.value === cmb_material_type)}
                                                onChange={(selectedOpt) => {
                                                    setmaterialType(selectedOpt.value)
                                                    materialTypeComboRef.current = selectedOpt;
                                                    comboOnChange('cmb_material_type')
                                                    // FnChkIsValidFilters()
                                                    validateFields()
                                                }}
                                                placeholder="Search for a Material Type..."
                                                className="form-search-custom"
                                                classNamePrefix="custom-select" // Add custom prefix for class names
                                                disabled={['view', 'approve'].includes(keyForViewUpdate)}
                                                styles={{
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided,
                                                        fontSize: '12px' // Adjust the font size as per your requirement
                                                    })
                                                }}
                                            />
                                            <MDTypography variant="button" id="error_cmb_material_type" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-3 col-12'>
                                            <Form.Label className="erp-form-label">From Company<span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col-sm-9 col-12'>
                                            <select id="cmb_from_company_id" className="form-select form-select-sm" value={cmb_from_company_id} onChange={() => { comboOnChange('from_company'); validateFields(); }} disabled >
                                                <option value="">Select</option>
                                                {companyOptions.length !== 0 ? (
                                                    <>
                                                        {companyOptions.map(companyItem => (
                                                            <option key={companyItem.field_id} value={companyItem.field_id}>  {companyItem.field_name}  </option>
                                                        ))}
                                                    </>
                                                ) : null}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_from_company_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>
                                </div>

                                {/* //second column */}
                                <div className="col-sm-6 erp_form_col_div">

                                    <div className='row'>
                                        <div className='col-sm-3 col-12'>
                                            <Form.Label className="erp-form-label">To Company<span className="required">*</span> </Form.Label>
                                        </div>
                                        <div className='col-sm-9 col-12'>
                                            <select id="cmb_to_comapany_id" className="form-select form-select-sm" value={cmb_to_comapany_id} onChange={() => { comboOnChange('to_company'); validateFields(); }} >
                                                <option value="">Select</option>
                                                {toCompanyOptions.length !== 0 ? (
                                                    <>
                                                        {toCompanyOptions.map((companyItem) => <option key={companyItem.field_id} value={companyItem.field_id}>  {companyItem.field_name}</option>
                                                        )}
                                                    </>
                                                ) : null}
                                            </select>
                                            <MDTypography variant="button" id="error_cmb_to_comapany_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-sm-3 col-12'>
                                            <Form.Label className="erp-form-label">Transfered By<span className="required">*</span></Form.Label>
                                        </div>
                                        <div className='col-sm-9 col-12'>
                                            <Select ref={transferedByComboRef}
                                                options={employeeOptions}
                                                isDisabled={true}
                                                inputId="cmb_transfered_by_id" // Provide the ID for the input box
                                                value={employeeOptions.find(option => option.value == cmb_transfered_by_id)}
                                                placeholder="Search for Employee..."
                                                className="form-search-custom"
                                                classNamePrefix="custom-select" // Add custom prefix for class names
                                                styles={{
                                                    option: (provided, state) => ({
                                                        ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                    }),
                                                    input: (provided, state) => ({
                                                        ...provided, fontSize: '12px' // Adjust the font size as per your requirement
                                                    })
                                                }}
                                            />
                                            <MDTypography variant="button" id="error_cmb_transfered_by_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                            </MDTypography>
                                        </div>

                                        {/* <div className="col-sm-1 col-2">
                                            <Tooltip title="Refresh" placement="top">
                                                <MDTypography className={` ${keyForViewUpdate === 'view' || keyForViewUpdate === 'delete' || keyForViewUpdate === 'approve' ? 'd-none' : 'display'}`} >
                                                    <MdRefresh style={{ color: 'black' }} onClick={() => { if (keyForViewUpdate !== 'approve' || keyForViewUpdate !== 'view') { refreshData('get_employee') } }} />
                                                </MDTypography>
                                            </Tooltip>
                                        </div> */}

                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <hr />

                    <Accordion defaultActiveKey="0" activeKey={openIssueDetailsAccord ? '0' : null}>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header onClick={(e) => setOpenIssueDetailsAccord(!openIssueDetailsAccord)} className="erp-form-label-md"> Material Details</Accordion.Header>
                            <Accordion.Body>
                                <div className={`row ${keyForViewUpdate === "" ? 'display' : 'd-none'}`}>
                                    <div className="col-sm-4 col-12">
                                        <div className={`row`}>
                                            <div className="col-md-8 col-12">
                                                <SearchItemFilter getSearchData={fnSearchMaterialData} onChange={validateFields} formIdForValidate="transfer_issue_form_id" requiredCols={requiredCols} company_id={cmb_to_comapany_id} product_type_id={cmb_material_type} godownId= {"2"}/>
                                                <MDTypography variant="button" id="error_txt_product_material_id" className="erp_validation" fontWeight="regular" color="error" style={{ display: "none" }}>
                                                </MDTypography>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="erp-Mt-10">
                                    {materialTransferDetailsData.length !== 0 ?
                                        <>
                                            {renderDetailsTable}
                                        </> :
                                        <div className='row  text-center'>
                                            <div className="col-12 ">
                                                <span className="erp_validation text-center" fontWeight="regular" color="error"> No Records Found... </span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion >

                    {keyForViewUpdate === 'view' ?
                        <>
                            <hr />
                            <Accordion defaultActiveKey="0" onSelect={FnLoadAccordionData}>
                                <Accordion.Item eventKey="documentList">
                                    <Accordion.Header className="erp-form-label-md p-0">Document List</Accordion.Header>
                                    <Accordion.Body>
                                        {docData.length !== 0 ? (
                                            renderDocumentTable
                                        ) : (
                                            <div className='row text-center'>
                                                <div className="col-12">
                                                    <span className="erp_validation text-center" fontWeight="regular" color="error">
                                                        No Records Found...
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </>
                        : null
                    }
                </>

                <div className="text-center mb-5">
                    <MDButton type="button" className="erp-gb-button" variant="button" fontWeight="regular" onClick={() => moveToListing()}>Back</MDButton>
                    <MDButton type="button" id="save-btn" className={`erp-gb-button ms-2 ${keyForViewUpdate === '' ? 'display' : 'd-none'}`} variant="button" onClick={() => { addMaterialTransfer('P') }}
                        fontWeight="regular">Save</MDButton>
                    {/* <MDButton className={`erp-gb-button erp_MLeft_btn ${keyForViewUpdate === 'view' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" id='viewdocument-id' onClick={viewDocumentForm} >Upload Document</MDButton>&nbsp; */}
                    <MDButton type="button" className={`erp-gb-button ms-2 ${keyForViewUpdate === '' ? 'display' : 'd-none'}`} variant="button" id='clearbutton-id' onClick={FnClearFormFields} fontWeight="regular" disabled={keyForViewUpdate === 'view' || keyForViewUpdate === 'update' || keyForViewUpdate === 'approve' ? 'disabled' : ''}>Clear</MDButton>
                    {/* <MDButton type="button" className={`erp-gb-button ms-2 ${keyForViewUpdate === 'delete' ? 'display' : 'd-none'}`} variant="button" fontWeight="regular" onClick={() => setShow(true)}>Delete</MDButton> */}
                    <MDButton type="button" variant="button" onClick={() => { addMaterialTransfer('MI') }} id="issue-btn"
                        className={`erp-gb-button erp_MLeft_btn ${keyForViewUpdate === 'issue' ? 'display' : 'd-none'}`} fontWeight="regular" >Issue</MDButton>
                    <MDButton type="button" variant="button" onClick={() => { addMaterialTransfer('R') }} id="return-btn"
                        className={`erp-gb-button erp_MLeft_btn ${keyForViewUpdate === 'return' ? 'display' : 'd-none'}`} fontWeight="regular" >Return</MDButton>
                    {/* <MDButton className={`erp-gb-button erp_MLeft_btn ${keyForViewUpdate === 'view' ? cmb_transfer_status !== 'P' ? 'display' : 'd-none' : 'd-none'}`} variant="button" fontWeight="regular" id="printbtn_id"
                        onClick={() => { printIssueSlip(true); }} disabled={cmb_transfer_status !== "P" ? '' : 'disabled'} >Print<FiDownload className="erp-download-icon-btn" /></MDButton> */}
                </div >

                {/* Document modal */}
                <Modal size="lg" className='erp_document_Form' show={showDocumentForm} onHide={handleCloseDocumentForm} backdrop="static" keyboard={false} centered>
                    <Modal.Header>
                        <Modal.Title className='erp_modal_title'>Document Form</Modal.Title>
                        <span><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={handleCloseDocumentForm}></button></span>
                    </Modal.Header>
                    <Modal.Body>
                        {/* <DocumentF group_id={txt_material_issue_no !== 0 ? txt_material_issue_no.replaceAll('/', '_') : null} document_group={docGroup} /> */}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" className="btn erp-gb-button" onClick={handleCloseDocumentForm}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
                <SuccessModal handleCloseSuccessModal={() => closeConfirmationModal()} show={[showSuccessMsgModal, succMsg, modalOrderDetails]} />
                <ErrorModal handleCloseErrModal={() => handleCloseErrModal()} show={[showErrorMsgModal, errMsg, modalOrderDetails]} />

                {/* <Modal size="lg" show={showAddRecIndentIssueModal} onHide={closeRecordAddIndentIssueModal} backdrop="static" keyboard={false} centered >
                    <Modal.Body className='erp_city_modal_body'>
                        <div className='row'>
                            <div className='col-12 align-self-end'><button type="button" className="erp-modal-close btn-close" aria-label="Close" onClick={closeRecordAddIndentIssueModal}></button></div>
                        </div>
                        {displayRecordIndentIssueComponent()}

                    </Modal.Body>
                </Modal > */}

                {/* Delete Modal */}
                {/* <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
                    <span><button type="button" class="erp-modal-close btn-close" aria-label="Close" onClick={handleClose}></button></span>
                    <Modal.Body className='erp_modal_body'>
                        <span className='erp_modal_delete_icon'><RxCrossCircled /></span>
                        <h6>Are you sure?</h6>
                        <div className="erp-form-label">Do you wish to delete this record ?</div>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-center'>
                        <Button variant="success" className='erp-gb-button' onClick={handleClose}>
                            Cancel
                        </Button>&nbsp;
                        <Button variant="danger" className='erp-gb-button' onClick={deleteRecords}>Delete</Button>
                    </Modal.Footer>
                </Modal> */}

            </DashboardLayout >
        </>
    )
}

export default FrmMaterialTransferRequisitionEntry;