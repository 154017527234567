import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import SalesOrderInvoice from 'FrmGeneric/Invoice/SalesOrderInvoice';
import MDButton from 'components/MDButton';
import PurchaseOrderInvoice from 'FrmGeneric/Invoice/PurchaseOrderInvoice';
import Enquiry from 'FrmGeneric/Invoice/EnquiryInvoice';
import QuotationInvoice from 'FrmGeneric/Invoice/QuotationInvoice';
import DispatchNote from 'FrmGeneric/Invoice/DispatchNote';
import GoodReceiptNote from 'FrmGeneric/Invoice/GRNInvoice';
import PurchaseRequisition from 'FrmGeneric/Invoice/PurchaseRequisition';
import SupplierPurchasePaymentInvoice from 'FrmGeneric/Invoice/SupplierPurchasePayment';
import CustomerSalesReciptInvoice from 'FrmGeneric/Invoice/CustomerSalesReciptInvoice';
import EnquiryService from 'FrmGeneric/Invoice/EnquiryService';
import RawCottonPurchaseSleep from 'FrmGeneric/Invoice/RawCottonPurchaseSleep';
import QuotationService from 'FrmGeneric/Invoice/QuotationService';
import DispatchChallan from 'FrmGeneric/Invoice/DispatchChallan';
import GatePassSlip from 'FrmGeneric/Invoice/GatePassSlip';
import SalesInvoice from 'FrmGeneric/Invoice/SalesInvoice';
import SalesOrderService from 'FrmGeneric/Invoice/SalesOrderService';
import YarnLoadingSlip from 'FrmGeneric/Invoice/YarnLoadingSlip';
import FabricGatePassSlip from 'FrmGeneric/Invoice/FabricGatePassSlip';
import ShortLeave from 'FrmGeneric/Invoice/ShortLeave';
import MaterialIssueSlip from 'FrmGeneric/Invoice/MaterialIssueSlip';
import RawMaterialIssueSlip from 'FrmGeneric/Invoice/RawMaterialIssueSlip';
import RawMaterialIssueReturnSlip from 'FrmGeneric/Invoice/RawMaterialIssueReturnSlip';
import CustomerMaterialIssueSlip from 'FrmGeneric/Invoice/CustomerMaterialIssueSlip';
import SizedYarnDispatch from "FrmGeneric/Invoice/SizedYarnDispatch";
import SizingProduction from "FrmGeneric/Invoice/SizingProduction";
import BeamIssueSlip from "FrmGeneric/Invoice/BeamIssueSlip";


const InvoiceTemplate = () => {
  debugger
  const { state } = useLocation();
  const { navigationLink, invoiceData, invoiceType, title, idList, keyForViewUpdate, compType, requestfor, modules_forms_id } = state || {};;

  const navigate = useNavigate();
  const [isPrinting, setIsPrinting] = useState(false);
  const printRef = useRef(null);
  const buttonsRef = useRef(null);
  const copyType = ['Original For Buyer', 'Duplicate Copy', 'Triplicate for Transporter', 'Extra Copy']

  // We store the resolve Promise being used in `onBeforeGetContent` here
  const promiseResolveRef = useRef(null);

  useEffect(() => {
    debugger
    if (isPrinting && promiseResolveRef.current) {
      // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
      promiseResolveRef.current();
    }
    // printLoadingSlips()
  }, [isPrinting]);

  useEffect(() => {
    // Print the invoice when the component loads
    if (invoiceType === "YVLS") {
      printLoadingSlips();
    } else {
      printInvoice();
    }
  }, []);

  const printInvoice = useReactToPrint({
    content: () => {
      debugger

      let printContentRef = printRef.current

      // For Multiple Components comming for print.
      printRef.current.childNodes.forEach((node, index) => {
        if (node.nodeType === Node.ELEMENT_NODE) {
          if (index === 0) {
            printContentRef = node;
          }
        }
      });

      // Access the existing footer tr
      const footerTr = printContentRef.querySelector('#footerTr');

      if (!['RCP', 'GPS', 'FBGPS', 'SL', 'IR', 'MI', 'IPR', 'CMIX'].includes(invoiceType)) {
        // let tbody = printContentRef.querySelector('#detailstableId');
        // let firstRowCells = tbody.querySelector('tr').querySelectorAll('td');
        // let rowTds = '';

        // // Get all <tr> elements within the tbody
        // const trs = tbody.getElementsByTagName('tr');

        // // Calculate the total height
        // let tbodyTotalHeight = 0;

        // console.log('tbodyTotalHeight: ', tbodyTotalHeight);

        // // Iterate over the cells of the first row
        // firstRowCells.forEach(() => {
        //   rowTds += `<td></td>`
        // });

        // // Array to keep track of added rows
        // const addedRows = [];
        // let tempContentHeight = printContentRef.offsetHeight
        // const totalpages = Math.ceil(tempContentHeight / 1062);
        // const pageHeightNeeded = totalpages * 1062

        // console.log("height :", printContentRef.offsetHeight);
        // // if (!(tempContentHeight < 1067) || tempContentHeight < 1050) {
        //   // const rowsToAdd = Math.round(pageHeightNeeded - tempContentHeight)
        //   // const tolerance = 10;
        //   // for (let index = 0; index < Math.round(rowsToAdd / 20); index++) {
        //   //   tempContentHeight += 20;
        //   //   if (Math.abs(tempContentHeight - pageHeightNeeded) <= tolerance) {
        //   //     break;
        //   //   }

        //   //   const newRow = document.createElement('tr');
        //   //   newRow.className = `addedTr-${index}`
        //   //   newRow.style.border = 'none'; // Remove border lines
        //   //   newRow.style.height = '20px'

        //   //   // Optionally, add a class to the new row to apply borderless styling
        //   //   // newRow.classList.add('table-borderless');

        //   //   newRow.innerHTML = rowTds;
        //   //   // newRow.style.borderTop = ''; // Remove top border
        //   //   // newRow.style.borderBottom = ''; // Remove bottom border

        //   //   // Append the new row to the current page
        //   //   footerTr.parentNode.insertBefore(newRow, footerTr);

        //   //   addedRows.push(newRow);
        //   // }
        // // }

        // let afterTbodyHt = 0
        // // Check which row has more than 25pcx height
        // for (let i = 0; i < trs.length; i++) {
        //   const className = trs[i].className;

        //   // Check if className contains "tblBodyTrHt"
        //   if (className.includes('tblBodyTrHt') && trs[i].offsetHeight > 25) {
        //     console.log(`trs height ${i}:`, trs[i].offsetHeight);

        //     afterTbodyHt += trs[i].offsetHeight - 20;
        //   }
        // }


        // if (!(printContentRef.offsetHeight < 1050)) {
        //   let removeRows = Math.ceil((afterTbodyHt) / 20)
        //   let removeCount = 0
        //   for (let i = addedRows.length - 1; i >= 0; i--) {
        //     if (removeRows === removeCount || printContentRef.offsetHeight < pageHeightNeeded - 150) {
        //       break;
        //     }
        //     addedRows[i].parentNode.removeChild(addedRows[i]);
        //     removeCount += 1
        //   }
        // }
      } else if (invoiceType === 'IR' || invoiceType === "IPR" || invoiceType === "CMIX") {
        // Handle specific logic for invoiceType 'IR'
        const tbodys = printContentRef.querySelectorAll('#detailstableId');

        tbodys.forEach(tbody => {
          const trs = tbody.getElementsByTagName('tr');
          const footerTr = tbody.querySelector('#footerTr'); // Make sure to select footerTr within the current tbody

          // Limit table to exactly 14 rows
          if (trs.length > 12) {
            for (let i = trs.length - 1; i >= 12; i--) {
              if (trs[i] !== footerTr) {
                tbody.removeChild(trs[i]);
              }
            }
          } else if (trs.length < 12) {
            let firstRowCells = tbody.querySelector('tr').querySelectorAll('td');
            let rowTds = '';

            // Iterate over the cells of the first row
            firstRowCells.forEach(() => {
              rowTds += `<td></td>`;
            });

            // // Add rows until there are exactly 14
            // for (let i = trs.length; i < 12; i++) {
            //   const newRow = document.createElement('tr');
            //   newRow.className = `addedTr-${i}`;
            //   newRow.style.border = 'none'; // Remove border lines
            //   newRow.style.height = '20px';

            //   newRow.innerHTML = rowTds;

            //   // Insert the new row before the footer row
            //   tbody.insertBefore(newRow, footerTr);
            // }
          }
        });
      } else if (invoiceType === 'MI' || invoiceType === 'CMR') {
        // Handle specific logic for invoiceType 'IR'
        const tbodys = printContentRef.querySelectorAll('#detailstableId');

        tbodys.forEach(tbody => {
          const trs = tbody.getElementsByTagName('tr');
          const footerTr = tbody.querySelector('#footerTr'); // Make sure to select footerTr within the current tbody

          // Limit table to exactly 14 rows
          if (trs.length > 38) {
            for (let i = trs.length - 1; i >= 38; i--) {
              if (trs[i] !== footerTr) {
                tbody.removeChild(trs[i]);
              }
            }
          } else if (trs.length < 38) {
            let firstRowCells = tbody.querySelector('tr').querySelectorAll('td');
            let rowTds = '';

            // Iterate over the cells of the first row
            firstRowCells.forEach(() => {
              rowTds += `<td></td>`;
            });

            // // Add rows until there are exactly 14
            // for (let i = trs.length; i < 38; i++) {
            //   const newRow = document.createElement('tr');
            //   newRow.className = `addedTr-${i}`;
            //   newRow.style.border = 'none'; // Remove border lines
            //   newRow.style.height = '20px';

            //   newRow.innerHTML = rowTds;

            //   // Insert the new row before the footer row
            //   tbody.insertBefore(newRow, footerTr);
            // }
          }
        });
      }




      // Clone the content node
      const printContentClone = printContentRef.cloneNode(true);
      const wrapperDiv = document.createElement('div');
      // wrapperDiv.classList.add('content');
      wrapperDiv.style.pageBreakAfter = 'always';
      wrapperDiv.appendChild(printContentClone);
      const contentWrapper = document.createElement('div');

      for (let index = 0; index < 4; index++) {
        contentWrapper.appendChild(wrapperDiv.cloneNode(true));

        if (invoiceType !== "SI") {
          break;
        }
        // Create a div for the copy type label
        // const copyTypeLabelDiv = document.createElement('dt');
        // copyTypeLabelDiv.className = 'erp-invoice-print-label-md-lg text-end me-2';
        // copyTypeLabelDiv.textContent = copyType[i];

        // Append the copy type label to the wrapper
        // contentWrapper.appendChild(copyTypeLabelDiv);
        // contentWrapper.appendChild(wrapperDiv.cloneNode(true));
      }
      return contentWrapper;

    },
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        promiseResolveRef.current = resolve;
        setIsPrinting(true);
      });
    },
    onAfterPrint: () => {
      // Reset the Promise resolve so we can print again
      promiseResolveRef.current = null;
      setIsPrinting(false);
      buttonsRef.current.focus();
    },
    documentTitle: title
  });

  const printLoadingSlips = useReactToPrint({
    content: () => {
      const contentWrapper = document.createElement('div');
      printRef.current.childNodes.forEach((node, index) => {
        if (node.nodeType === Node.ELEMENT_NODE) {
          let anotherPrintContentRef = node;
          if (anotherPrintContentRef !== null) {
            const printContentClone = anotherPrintContentRef.cloneNode(true);
            // Remove elements with class 'hideOnPrint' from printContentClone
            const elementsToRemove = printContentClone.querySelectorAll('.hideOnPrint');
            elementsToRemove.forEach(element => element.remove());

            const wrapperDiv = document.createElement('div');
            wrapperDiv.style.pageBreakAfter = 'always';
            wrapperDiv.appendChild(printContentClone);
            contentWrapper.appendChild(wrapperDiv.cloneNode(true));
          }
        }
      });
      return contentWrapper;
    },
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        promiseResolveRef.current = resolve;
        setIsPrinting(true);
      });
    },
    onAfterPrint: () => {
      // Reset the Promise resolve so we can print again
      promiseResolveRef.current = null;
      setIsPrinting(false);
      buttonsRef.current.focus();
    },
    documentTitle: title
  });

  const invoiceComponent = () => {
    debugger
    switch (invoiceType) {
      case 'SO':
        return <SalesOrderInvoice invoiceContent={invoiceData} ref={printRef} />;
      case 'PO':
        return <PurchaseOrderInvoice invoiceContent={invoiceData} isWaterMark={false} ref={printRef} />;
      case 'EN':
        return <Enquiry invoiceContent={invoiceData} ref={printRef} />;
      case 'QU':
        return <QuotationInvoice invoiceContent={invoiceData} ref={printRef} />;
      case 'DN':
        return <DispatchNote dispatchNoteContent={invoiceData} ref={printRef} />;
      case 'GRN':
        return <GoodReceiptNote invoiceContent={invoiceData} ref={printRef} />;
      case 'PR':
        return <PurchaseRequisition invoiceContent={invoiceData} ref={printRef} />;
      // indent purchase requision
      case "IPR":
        return <PurchaseRequisition invoiceContent={invoiceData} ref={printRef} />;
      case 'SPP':
        return <SupplierPurchasePaymentInvoice invoiceContent={invoiceData} ref={printRef} />;
      case 'CSR':
        return <CustomerSalesReciptInvoice invoiceContent={invoiceData} ref={printRef} />;
      case 'ENSR':
        return <EnquiryService invoiceContent={invoiceData} ref={printRef} />;
      case 'RCP':
        return <RawCottonPurchaseSleep invoiceContent={invoiceData} ref={printRef} />
      case 'GPS':
        return <GatePassSlip dispatchNoteContent={invoiceData} ref={printRef} />;
      case 'FBGPS':
        return <FabricGatePassSlip dispatchNoteContent={invoiceData} ref={printRef} />;
      case 'DC':
        return <DispatchChallan dispatchChallanContent={invoiceData} ref={printRef} />;
      case 'QS':
        return <QuotationService quotationContent={invoiceData} ref={printRef} />;
      case 'SOSR':
        return <SalesOrderService SalesOrderServiceContent={invoiceData} ref={printRef} />
      case 'SI':
        return <SalesInvoice invoiceContent={invoiceData} ref={printRef} />;
      case 'SL':
        return <ShortLeave invoiceContent={invoiceData} ref={printRef} />;
      case 'IR':
        return <MaterialIssueSlip invoiceContent={invoiceData} ref={printRef} />;
      case 'MI':
        return <MaterialIssueSlip invoiceContent={invoiceData} ref={printRef} />;
      case 'RMR':
        return <RawMaterialIssueSlip invoiceContent={invoiceData} ref={printRef} />;
      case 'RMI':
        return <RawMaterialIssueSlip invoiceContent={invoiceData} ref={printRef} />;
      case 'RMIR':
        return <RawMaterialIssueReturnSlip invoiceContent={invoiceData} ref={printRef} />;
      case 'CMR':
        return <CustomerMaterialIssueSlip invoiceContent={invoiceData} ref={printRef} />;
      case 'CMIX':
        return <CustomerMaterialIssueSlip invoiceContent={invoiceData} ref={printRef} />;
      case 'DSY':
        return <SizedYarnDispatch invoiceContent={invoiceData} ref={printRef} />;
      case 'SP':
        return <SizingProduction invoiceContent={invoiceData} ref={printRef} />;
      case 'BIS':
        return <BeamIssueSlip invoiceContent={invoiceData} ref={printRef} />;
      case 'YVLS':
        // return <YarnLoadingSlip invoiceContent={invoiceData} ref={printRef} loadingSlipIndex={0} />;
        // // For multiple printouts
        return (
          <>
            {invoiceData.packingDetailsForLoadingSlip.map((item, index) => (
              <YarnLoadingSlip key={index} invoiceContent={invoiceData} ref={printRef} loadingSlipIndex={index} />
            ))}
          </>
        );

      default:
        return null;
    }
  };


  return (
    <>
      <ReactToPrint
        content={() => printRef.current}
      />
      <div ref={printRef}>
        {invoiceComponent()}
      </div>

      <div className='text-center m-25'>
        <MDButton type="button" ref={buttonsRef} className="erp-gb-button" variant="button" onClick={(e) => navigate(navigationLink, { state: { idList: idList, keyForViewUpdate: keyForViewUpdate, requestfor: requestfor, modules_forms_id: modules_forms_id } })}
          fontWeight="regular">Back</MDButton>
        {/* <MDButton type="button" ref={buttonsRef} className="erp-gb-button ms-2" variant="button" onClick={printLoadingSlips}
          fontWeight="regular">Print Invoice</MDButton> */}
      </div>
    </>
  );
};

export default InvoiceTemplate;
