import React, { useState, useEffect, useRef } from "react";
import {
    List,
    ListItem,
    ListItemText,
    Divider,
    Typography,
} from "@mui/material";

function ScrollDialog({ isOpen, bdayEmployees = [], handleCloseDialog, setSelEmployeeData, handleOpenBdayModal }) {
    const descriptionElementRef = useRef(null);
    const dialogRef = useRef(null);

    useEffect(() => {
        if (isOpen) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [isOpen]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dialogRef.current && !dialogRef.current.contains(event.target)) {
                handleCloseDialog();
            }
        };

        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen, handleCloseDialog]);

    return (
        isOpen && (
            <div
                ref={dialogRef}
                style={{
                    position: "absolute",
                    top: "35px",
                    right: "30px",
                    backgroundColor: "white",
                    width: "270px",
                    overflowY: "scroll",
                    maxHeight: "250px",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    zIndex: 1000,
                    borderRadius: "5px",
                    scrollbarWidth: "thin",
                }}
            >
                {/* Close button */}
                <button
                    type="button"
                    className="erp-modal-close btn-close"
                    aria-label="Close"
                    onClick={handleCloseDialog}
                    style={{ position: "absolute", top: "10px", right: "10px" }}
                ></button>

                {/* Title */}
                <h6 style={{ margin: "15px 16px", color: '#831657', fontWeight: "700", fontSize: "12px" }}>
                    Activity
                </h6>
                <hr style={{ margin: "8px 16px" }} />

                {/* Employee Birthday List */}
                <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                    {bdayEmployees.map((emp, i) => (
                        <React.Fragment key={i}>
                            <ListItem
                                alignItems="flex-start"
                                style={{
                                    lineHeight:"5px",
                                    cursor: "pointer",
                                    padding: "10px 15px",
                                    borderRadius: "0.5rem",
                                    transition: "background-color 0.3s ease",
                                }}
                                onClick={() => {
                                    setSelEmployeeData(emp);
                                    handleOpenBdayModal();
                                    handleCloseDialog();
                                }}
                                onMouseEnter={(e) => {
                                    e.currentTarget.style.backgroundColor = "rgba(67, 67, 67, 0.1)";
                                }}
                                onMouseLeave={(e) => {
                                    e.currentTarget.style.backgroundColor = "transparent";
                                }}
                            >
                                <ListItemText
                                    primary={emp.employee_name}
                                    secondary={
                                        <Typography
                                            component="span"
                                            variant="body2"
                                            sx={{
                                                color: "#831657",
                                                fontWeight: "400",
                                                fontSize: "11px",
                                            }}
                                        >
                                            It's their Birthday! Wish them happy birthday!
                                        </Typography>
                                    }
                                    primaryTypographyProps={{
                                        sx: { color: "#333333", fontSize: "12px", fontWeight: "500" },
                                    }}
                                />
                            </ListItem>
                            {i < bdayEmployees.length - 1 && <Divider style={{margin:"2px"}} variant="inset" component="li" />}
                        </React.Fragment>
                    ))}
                </List>
            </div>
        )
    );
}

export default ScrollDialog;
